import { useState, useContext, useEffect } from "react";
import { ServicoForm } from "./ServicoForm";
import { ServicoCard } from "./ServicoCard";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { UserContext } from "../../../contexts/UserProvider";
import ApiUsers from "../../../ApiUsers";
import "./style.css";
import { PesquisaServicos } from "../Pesquisa/PesquisaServicos";

export const Servico = () => {
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);
  const userContext = useContext(UserContext);
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [selecionado, setSelecionado] = useState(null);
  const [editando, setEditando] = useState(false);
  const [bots, setBots] = useState([]);

  const [servicosFiltrados, setServicosFiltrados] = useState([]);

  useEffect(() => {
    if (userContext.pref.services.voxbot.ativo) {
      let newBots = userContext.pref.services.voxbot.bots
        ? [...userContext.pref.services.voxbot.bots]
        : [];
      newBots.filter((bot) => bot.status === "ativo");
      setBots(newBots);
    }
  }, [userContext.pref.services.voxbot]);

  useEffect(() => {
    const novosProfissionais = [...professionalContext.profissionais];
    setProfissionais(novosProfissionais);

    const novosServicos = [...serviceContext.servicos];
    setServicos(novosServicos);

    const novasEspecialidades = [...specialtyContext.especialidades];
    setEspecialidades(novasEspecialidades);

    const novosLocais = [...locationContext.locais];
    setLocais(novosLocais);
  }, [
    locationContext.locais,
    professionalContext.profissionais,
    serviceContext.servicos,
    specialtyContext.especialidades,
  ]);

  const servicosFiltradosUnicos = servicosFiltrados.reduce((acc, servico) => {
    const servicoJaExiste = acc.find(
      (servicoUnico) => servicoUnico._id === servico._id
    );
    if (!servicoJaExiste) {
      return [...acc, servico];
    }
    return acc;
  }, []);

  const editServico = (servico) => {
    setSelecionado(servico);
    setEditando(true);
  };

  const deleteServico = (service) => {
    ApiUsers.post("/servicos/delete", { service });
  };

  const updateServico = (service) => {
    ApiUsers.post("/servicos/update", {
      service,
    });
    setSelecionado(null);
    setEditando(false);
  };

  return (
    <div className="servico-container">
      {selecionado && <div className="bg-modal-servico-form"></div>}
      <div className="agenda-header-container">
        <div className="servico-title">SERVIÇOS</div>
        <button
          className="btn-add"
          onClick={() =>
            setSelecionado({
              _id: "",
              nome: "",
              locais: [],
              avisos: [],
              confirms: [],
              tempoConfirm: "",
              bot: {},
              msgConfirmacao: {},
              mensagemConfirmacao: "",
              mensagemConfirmado: "",
              mensagemWarning: "",
              mensagemMarcado: "",
              mensagemCancelado: "",
              mensagemDesmarcado: "",
              envioMsg: {},
            })
          }
        >
          NOVO SERVIÇO
        </button>
      </div>
      <PesquisaServicos
        servicos={servicos}
        setServicosFiltrados={setServicosFiltrados}
        profissionais={profissionais}
        especialidades={especialidades}
        locais={locais}
      />
      {servicosFiltradosUnicos.length > 0 ? (
        <div className="servico-content">
          <div className="card-container">
            {servicosFiltradosUnicos.map((servico, index) => {
              return (
                <ServicoCard
                  key={index}
                  servico={servico}
                  editServico={editServico}
                  deleteServico={deleteServico}
                />
              );
            })}
            <div className="card-servico flex-dummy"></div>
            <div className="card-servico flex-dummy"></div>
            <div className="card-servico flex-dummy"></div>
          </div>
        </div>
      ) : (
        <div className="servicos-vazio">SEM SERVIÇOS</div>
      )}
      {selecionado && (
        <ServicoForm
          servico={selecionado}
          setSelecionado={setSelecionado}
          updateServico={updateServico}
          setEditando={setEditando}
          editando={editando}
          bots={bots}
        />
      )}
    </div>
  );
};
