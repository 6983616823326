import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { BsCaretDownFill } from "react-icons/bs";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <BsCaretDownFill color='#9ac31c' />
        </components.DropdownIndicator>
    );
};

const AtendimentoSelectFlags = ({ flags, setFlags }) => {
    const handleCreate = (inputValue) => {
        setFlags(prevFlags => [...prevFlags, inputValue]);
    };

    return (
        <div>
            <CreatableSelect
                id="flags"
                name="flags"
                options={flags.map((flag) => ({ value: flag, label: flag }))}
                onChange={(selectedOption) => setFlags(selectedOption ? selectedOption.map(option => option.value) : [])}
                onCreateOption={handleCreate}
                value={flags.map(flag => ({ value: flag, label: flag }))}
                placeholder="Crie uma flag"
                closeMenuOnSelect={true}
                isMulti
                components={{ DropdownIndicator }}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                        width: '280px',
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                    }),
                }}
            />
        </div>
    );
};

export default AtendimentoSelectFlags;
