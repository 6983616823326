import React from 'react';

export default function Action (props) {

    return <>
    
        <div className="action-flow" title={props.action.tipo} style={{background: `${props.color}`}} draggable onDrag={(e) => props.handleDrag(e)} >
            <span> {props.action.tipo} </span>
        </div>
    
    </>
}