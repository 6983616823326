import { useState, useEffect, useContext } from 'react'
import { PrefContext } from '../../../../../../contexts/ChatBotProvider/PrefProvider'
import ApiUsers from '../../../../../../ApiUsers'

const AddListUsers = ({ setType, usuarios, grupo, setGrupo, createAlert }) => {
    const { users } = useContext(PrefContext)
    const [usersList, setUsersList] = useState([])
    const [search, setSearch] = useState('')
    const [searchList, setSearchList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    useEffect(() => {
        if (users) {
            let newList = users.filter((user) => !usuarios?.includes(user._id))
            setUsersList(newList)
        }
    }, [usuarios])

    useEffect(() => {
        setSearchList(usersList)
    }, [usersList])

    useEffect(() => {
        if (search.length > 0) {
            let newList = usersList.filter((user) => {
                return user.nome.toLowerCase().includes(search.toLowerCase())
            })
            setSearchList(newList)
        } else {
            setSearchList(usersList)
        }
    }, [search])

    const handleAddUser = (user) => {
        if (selectedUsers.some((u) => u._id === user._id)) {
            let newList = selectedUsers.filter((u) => u._id !== user._id)
            setSelectedUsers(newList)
        } else {
            setSelectedUsers([...selectedUsers, user])
        }
    }

    const handleAddUsers = () => {
        let usersId = selectedUsers.map((u) => u._id);
        ApiUsers.post("/grupos/adicionarVarios", { users: usersId, grupo: grupo }).then((res) => {
            let newGrupo = { ...grupo }
            newGrupo.usuarios = [...newGrupo.usuarios, ...selectedUsers]
            setGrupo(newGrupo)
            setSelectedUsers([])
            setType('lista')
            createAlert("normal", { title: "Sucesso!", placeholder: "Usuários adicionados no grupo com sucesso!" });
        }).catch((err) => {
            createAlert("normal", { title: "Erro!", placeholder: "Erro ao adicionar usuários no grupo!" });
        })
    }

    return (
        <div className='contact-info-list-add-users'>
            <div className="contact-info-add-users-header">
                <span>Adicionar membros</span>
                <div className="contact-info-list-users-search">
                    <input type="text" placeholder='Pesquisar' value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>

            <div className="contact-info-add-users-body">
                {searchList.length > 0 && searchList.map((user, index) => {
                    return (
                        <div key={index} className={`contact-info-list-users-card ${selectedUsers.find((usuario) => { return usuario._id === user._id }) ? 'selected' : ''}`} onClick={() => handleAddUser(user)} style={{cursor:'pointer'}} >
                            <div className="contact-info-list-users-avatar">
                                <img style={{ width: 45, height: 45 }} className="title-contact-img" src={user.foto ? user.foto : "/imgs/avatar2.png"} alt="Foto Contato" />
                            </div>

                            <div className="contact-info-list-users-info" onClick={() => handleAddUser(user)}>
                                {user.nome.length > 15 ?
                                    <p>{user.nome.substring(0, 15) + "..."}</p> : <p>{user.nome}</p>}
                                {
                                    user.departamentoNome ? user.departamentoNome.length > 15 ?
                                        <span>{user.departamentoNome.substring(0, 15) + "..."}</span> : <span>{user.departamentoNome}</span> : <span>{"Sem departamento"}</span>
                                }
                            </div>
                        </div>
                    )
                })
                }
            </div>
            {
                selectedUsers.length > 0 && <div className="contact-info-group-btn" style={{ margin: '0 auto' }}>
                    <button onClick={() => handleAddUsers()}>Adicionar membros</button>
                </div>
            }
        </div>
    )

}

export default AddListUsers
