import Select from "react-select";

export const ReactSelectLabel = ({ label, options, value, setValue, inputKey, config }) => {
  const onChange = (selectedOption) => {
    setValue((prev) => ({ ...prev, [inputKey]: selectedOption }));
  };

  return (
    <div className="qsg-agenda-form-input-container">
      <label htmlFor={inputKey}>{label}</label>
      <Select
        options={options}
        value={value || []}
        onChange={onChange}
        id={inputKey}
        name={inputKey}
        className="qsg-agenda-form-select"
        {...config}
      />
    </div>
  );
};
