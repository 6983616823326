import React, { useState, useEffect } from 'react';
import './LoginRefatored.css';
import { Link } from 'react-router-dom';
import {  Button, Form } from 'react-bootstrap';
import ApiUsers from '../../ApiUsers';
import Conta from './Inputs/conta';
import Senha from './Inputs/senha';
import ConfirmarSenha from './Inputs/confirmarSenha';
import { BiErrorCircle } from "react-icons/bi";
import MiniLoading from '../Configurações/OptionsScreen/inputs/MiniLoading'

const FirstAccess = () => {
    const [confirmar, setConfirmar] = useState({
        senha: '',
        conta: '',
        confirmarSenha: ''
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");
    const [success, setSuccess] = useState("");
    const [ hash, setHash] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (confirmar.senha !== confirmar.confirmarSenha) {
            setError('senhas não conferem');
            setLoading(false);
            return
        } 
        let conta = confirmar.conta;
        let senha = confirmar.senha;
        ApiUsers.post('/firstAccess', {conta,senha,hash})
            .then(res => {
                if (res.data.error) {
                    setError(res.data.error);
                    setLoading(false);
                    return
                }
                setLoading(false);
                setSuccess(true);
            })
            .catch(err => {
                setLoading(false);
                setError('problemas no envio');
            });
            setTimeout(() => {
                setLoading(false);
            }, 1000);
    }

    function setInputs(campo, valor) {
        setConfirmar({ ...confirmar, [campo]: valor });
        setError("");
    }

    useEffect(() => {
        const hash = window.location.pathname.split('/firstAccess/')[1];
        console.log(hash);
        setHash(hash);
    }, []);

    return (
        <>
            <div className="login-wrapper">
                <Form className="login-container" onSubmit={handleSubmit}>
                    <div className="login-header">
                    <img
                        src="https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
                        className="form-header-img"
                        alt="logo"
                    />
                    </div>
                    {error !== "" ?
                        <div className="error-container">
                            <BiErrorCircle /> <span className="error-msg"> {error} </span>
                        </div>
                    : null}

                    <Conta setInputs={setInputs} />
                    <Senha setInputs={setInputs} />
                    <ConfirmarSenha setInputs={setInputs} />    
                    <br />
                        {
                            success ?
                                <Button as={Link} to="/login" variant='light' className="login-button">
                                    Senha Alterada Com Sucesso
                                </Button>
                                :
                                <Button type="submit" style={{backgroundColor: "#142a4c"}} title="Esqueceu a Senha" className="login-button" >
                                    {loading ? <MiniLoading style={{display: "flex",alignItems:"center",justifyContent:"center"}}  /> : "Enviar"}
                                </Button>
                        } 
                    <Button style={{marginBottom:'10px'}} as={Link} to="/login" variant='light' className="login-button">Voltar</Button>               
                </Form>
            </div>
        </>
    );
}

export default FirstAccess;
