import Select from "react-select";
import { useState } from "react";

export const ReactSelectList = ({ label, options, inputKey, config, setSelectedOptions, optionsList, setFormValues, setCurrentListKey, nextListKey }) => {
  const [value, setValue] = useState(null);

  const onChange = (selectedOption) => {
    setValue(selectedOption);
  };

  const addToList = () => {
    const selectedOption = optionsList.find((option) => option._id === value.value);
    setSelectedOptions((prevOptions) => [...prevOptions, selectedOption]);
    const newSelectedOptions = [{ _id: selectedOption._id, nome: selectedOption.nome, [nextListKey]: [] }];
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        [inputKey]: [...prevValues[inputKey], ...newSelectedOptions],
      };
    });
    setCurrentListKey(inputKey);
    setValue(null);
  };

  return (
    <div className="qsg-agenda-form-input-container">
      <label htmlFor={inputKey}>{label}</label>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        id={inputKey}
        name={inputKey}
        className="qsg-agenda-form-select"
        {...config}
      />
      <button
        type="button"
        className="qsg-agenda-form-button-add-to-list"
        onClick={addToList}>
        <span>Adicionar</span>
      </button>
    </div>
  );
};
