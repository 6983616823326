import React from "react";
import "./componentServices.css";

const ServiceHours = ({ props }) => {
  const { bot } = props;
  const dayWeek = [
    { index: 0, name: "Domingo" },
    { index: 1, name: "Segunda" },
    { index: 2, name: "Terça" },
    { index: 3, name: "Quarta" },
    { index: 4, name: "Quinta" },
    { index: 5, name: "Sexta" },
    { index: 6, name: "Sábado" },
  ];

  function changeDays(checked, day) {
    let newBot = { ...bot };
    if (checked) {
      newBot.config.dias = [...bot.config.dias, day].sort();
    } else {
      newBot.config.dias = bot.config.dias.filter((d) => d !== day).sort();
    }
    props.setBot(newBot);
  }

  function changeHour(e, period) {
    let newBot = { ...bot };
    if (period === "start") newBot.config.horario.inicio = e.target.value;
    if (period === "finish") newBot.config.horario.fim = e.target.value;
    props.setBot(newBot);
  }

  return (
    <>
      <div className="container-align-titles-serviceHours">
        <p>Horário de funcionamento</p>
        <p className="required">Dias da semana</p>
      </div>

      <div className="container-serviceHours-space">
        <div className="container-hours ">
          <h5 className="required">Horário</h5>
          <div className="bot-form-input-cont-sv time-cont-input-sv">
            <input
              type="time"
              className="select-hours"
              placeholder="Horário de Início"
              value={bot.config.horario.inicio}
              onChange={(e) => changeHour(e, "start")}
            />
            <h5> às </h5>
            <input
              type="time"
              className="select-hours"
              placeholder="Horário de Término"
              value={bot.config.horario.fim}
              onChange={(e) => changeHour(e, "finish")}
            />
          </div>
        </div>

        <br />
        <div className="bot-form-daysWeek">
          <div className="days-align">
            {dayWeek.map((day, index) => (
              <div className="days-width" key={index}>
                <div className="bot-form-checkbox-container-sv">
                  <input
                    className="bot-form-checkbox-sv"
                    type="checkbox"
                    checked={bot.config.dias.some((d) => d === day.index)}
                    value={day.index}
                    onChange={(e) => changeDays(e.target.checked, day.index)}
                  />
                  <span className="bot-form-checkbox-span-sv">{day.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <br />
      <hr className="line" />
    </>
  );
};

export default ServiceHours;
