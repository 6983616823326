import { useEffect, useCallback, useRef, useState } from "react";
import { Participantes } from "./participantes";
import { Conversa } from "./conversa";

export const ModalHistorico = ({ isModalOpen, setIsModalOpen, reuniaoSelecionada, modalContent }) => {
  const modalRef = useRef(null);
  const [reuniao, setReuniao] = useState({});
  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    },
    [setIsModalOpen]
  );

  const modalContentTypes = {
    Participantes: <Participantes reuniao={reuniao} />,
    Conversa: <Conversa reuniao={reuniao} />,
  };

  useEffect(() => {
    const novaReuniao = reuniaoSelecionada ? reuniaoSelecionada : {};
    setReuniao(novaReuniao);
  }, [reuniaoSelecionada]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen, handleClickOutside]);

  return (
    <div
      className="historico-cards-modal"
      ref={modalRef}>
      {modalContentTypes[modalContent]}
    </div>
  );
};
