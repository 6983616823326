import { useContext, useState } from "react";
import { VideoContext } from "../../../contexts/VideoProvider";
import { UserContext } from "../../../contexts/UserProvider";
import { EnvioConvite } from "./EnvioConvite";
import { HorarioReuniao } from "./HorarioReuniao";
import { InfoReuniao } from "./InfoReuniao";
import Alert from "../../Configurações/OptionsScreen/inputs/Alerts/Alert";

export const ConviteSala = () => {
  const { alert, setAlert, linkMinhaSala } = useContext(VideoContext);
  const { user } = useContext(UserContext);
  const [isEditMsg, setIsEditMsg] = useState(false);
  const [conviteSala, setConviteSala] = useState({
    data: new Date(),
    duracao: "60",
    titulo: `Reunião ${user.nome}`,
    mensagem: "",
    link: linkMinhaSala,
  });

  const alertType = {
    sucess: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    error: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  const dataInicio = conviteSala?.data?.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const horaInicio = conviteSala?.data?.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const mensagemConvitePadrao = `Convite para participar de uma reunião
  Título da reunião: ${conviteSala.titulo}
  Data de início: ${dataInicio} às ${horaInicio}h
  Duração: ${duracao()}
  Link da reunião: ${linkMinhaSala}
  Caso tenha alguma dúvida, entre em contato diretamente com ${user.email}.
  Para acessar, basta clicar no link da sala no dia e hora marcados.
  O Tele Atendimento Voxcity é uma ferramenta online e não precisa de nenhuma instalação. Basta ter uma conexão com a internet e terá acesso de qualquer lugar e de qualquer dispositivo.`;

  function duracao() {
    const duracaoMinutos = parseInt(conviteSala.duracao);
    const horas = Math.floor(duracaoMinutos / 60);
    const minutos = duracaoMinutos % 60;
    let result = "";
    if (horas === 1) {
      result += "1 hora";
    } else if (horas !== 0) {
      result += `${horas} horas`;
    }
    if (minutos !== 0) {
      if (result !== "") {
        result += " e ";
      }
      result += `${minutos} minutos`;
    }
    return result;
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  return (
    <div className="sala-container">
      {alert.visible && alertType[alert.type]}
      <form className="convite-sala-form">
        <div className="convite-sala-form-header">
          <h2>Convite para reunião</h2>
          <p>
            Transformemos cada encontro em uma experiência única e impactante!
          </p>
        </div>
        <HorarioReuniao
          conviteSala={conviteSala}
          setConviteSala={setConviteSala}
          mensagemConvitePadrao={mensagemConvitePadrao}
          isEditMsg={isEditMsg}
        />
        <InfoReuniao
          conviteSala={conviteSala}
          setConviteSala={setConviteSala}
          mensagemConvitePadrao={mensagemConvitePadrao}
          isEditMsg={isEditMsg}
          setIsEditMsg={setIsEditMsg}
        />
        <EnvioConvite conviteSala={conviteSala} isEditMsg={isEditMsg} />
      </form>
    </div>
  );
};
