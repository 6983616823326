export const Desmarcar = (props) => {
  const {
    mensagemDesmarcado,
    onChangeMensagem,
    handleCancelServicoConfig,
    setView,
    view,
    salvarServicoConfig,
  } = props;

  return (
    <>
      <legend>Mensagem Desmarcado</legend>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="mensagemMarcado">Mensagem Desmarcado</label>
          <textarea
            className="servico-form-textarea"
            name="mensagemDesmarcado"
            id="mensagemDesmarcado"
            cols="100"
            rows="5"
            value={mensagemDesmarcado}
            onChange={onChangeMensagem}
          />
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="prev" onClick={() => setView(view - 1)}>
          Anterior
        </button>
        <button type="button" id="next" onClick={() => setView(view + 1)}>
          Próximo
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
};
