import env from "react-dotenv";
import axios from "axios";

let url = env ? env.REACT_APP_BASE_URL : "https://mensageria.voxcity.com.br"
// let url = "http://localhost:4000";
// linha extra

const ApiUsers = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": url,
  },
});

axios.defaults.withCredentials = true;

export default ApiUsers;
