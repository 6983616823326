import { Profissionais } from "./Steps/Profissionais";
import { Especialidades } from "./Steps/Especialidades";
import { Locais } from "./Steps/Locais";
import { Servicos } from "./Steps/servicos";
import { Resumo } from "./Steps/resumo";

export const QsgAgenda = ({ step, setIsStepCompleted }) => {
  const components = {
    0: <Profissionais setIsStepCompleted={setIsStepCompleted} />,
    1: <Especialidades setIsStepCompleted={setIsStepCompleted} />,
    2: <Locais />,
    3: <Servicos />,
    4: <Resumo />,
  };

  return <>{components[step]}</>;
};
