import { useEffect, useState, useContext } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import ApiUsers from "../../../../../../ApiUsers";
import AtendimentoSelectBot from "./atendimentoSelectBot";
import AtendimentoInput from "./atendimentoInput";
import AtendimentoSelectFideliza from "./atendimentoSelectFideliza";
import AtendimentoSelectFlags from "./atendimentoSelectFlags";
import { BsPen } from "react-icons/bs";

const AtendimentoBoxInputs = ({ atendimento, createAlert }) => {
  const { bots, variables } = useContext(FlowBotContext);
  const [allBots, setAllBots] = useState([]);
  const [fidelity, setFidelity] = useState("");
  const [bot, setBot] = useState("");
  const [inputsDatas, setInputsDatas] = useState([]);
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    let newInputsDatas = [];
    variables.forEach((v) => {
      let value = atendimento[v.nome] ? atendimento[v.nome] : "";
      newInputsDatas.push({
        label: v.nome,
        value: value,
        nome: v.nome,
        type: "text",
        _id: v._id,
      });
    });
    setInputsDatas(newInputsDatas);
  }, [atendimento, variables]);

  useEffect(() => {
    if (atendimento) {
      setBot(atendimento.bot);
    }
  }, [atendimento]);

  useEffect(() => {
    if (bots && allBots.length === 0) {
      let newBots = bots ? [...bots] : [];
      newBots = newBots.filter((bot) => bot.status === "ativo");
      setAllBots(newBots);
    }
  }, [bots]);

  useEffect(() => {
    if(atendimento){
      let nFlags = atendimento.flags ? [...atendimento.flags] : [];
      setFlags(nFlags);
    }
  }, [atendimento]);

  const changeFlags = () => {
    let copyContact = JSON.parse(JSON.stringify(atendimento));
    copyContact.flags = flags;
    delete copyContact.historico;
    delete copyContact.allMessages;

    ApiUsers.post("/atendimentos/updateFlags", { contact: copyContact })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Flags alteradas com sucesso",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeBot = () => {
    let copyContact = JSON.parse(JSON.stringify(atendimento));
    delete copyContact.allMessages;
    ApiUsers.post("/whats/changeBot", { contact: copyContact, bot: bot })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Bot alterado com sucesso",
        });
      })
      .catch((err) => {
        console.log(err);
        createAlert("normal", {
          title: "Erro",
          placeholder: "Erro ao alterar bot",
        });
      });
  };

  const changeFideliza = (e) => {
    let copyContact = JSON.parse(JSON.stringify(atendimento));
    copyContact.fidelity = fidelity;
    delete copyContact.historico;
    delete copyContact.allMessages;

    ApiUsers.post("/atendimentos/updateFidelity", { contact: copyContact })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Fidelidade alterada com sucesso",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {atendimento?.atendente && (
        <div className="atendimento-body-footer-item">
          <span>Bot</span>
          <div className="atendimento-body-footer-item-options">
            <AtendimentoSelectBot allBots={allBots} setBot={setBot} bot={bot} />
            <div
              className="atendimento-body-footer-item-options-iconBox"
              onClick={() => changeBot()}
              title="Salvar alteração do Bot"
            >
              <BsPen color={"#FFF"} style={{ cursor: "pointer" }} />
            </div>
          </div>
        </div>
      )}

      {
        <div className="atendimento-body-footer-item">
          <span>Fidelidade</span>
          <div className="atendimento-body-footer-item-options">
            <AtendimentoSelectFideliza
              contato={atendimento}
              fidelity={fidelity}
              setFidelity={setFidelity}
            />
            <div
              className="atendimento-body-footer-item-options-iconBox"
              onClick={() => changeFideliza()}
              title="Salvar alteração da fidelidade"
            >
              <BsPen color={"#FFF"} style={{ cursor: "pointer" }} />
            </div>
          </div>
        </div>
      }

      {
        <div className="atendimento-body-footer-item">
          <span>Flags</span>
          <div className="atendimento-body-footer-item-options">
            <AtendimentoSelectFlags flags={flags} setFlags={setFlags} />
          <div className="atendimento-body-footer-item-options-iconBox" title="Salvar Alteração nas flags" onClick={changeFlags}>
            <BsPen color={"#FFF"} style={{ cursor: "pointer" }} />
          </div>
          </div>
        </div>
      }

      {inputsDatas.map((data, i) => (
        <AtendimentoInput
          data={data}
          key={i}
          createAlert={createAlert}
          contato={atendimento}
        />
      ))}
    </>
  );
};

export default AtendimentoBoxInputs;
