import { useState, useEffect, useContext } from "react";
import { CardsHistorico } from "./cardsHistorico";
import { VideoContext } from "../../../contexts/VideoProvider";

export const Historico = () => {
  const { sala, getSala } = useContext(VideoContext);
  const [reunioes, setReunioes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const historicoPerPage = 5;

  useEffect(() => {
    getSala();
  }, []);

  useEffect(() => {
    const novasReunioes = sala?.reunioes ? sala.reunioes : [];
    const reunioesEncerradas = [];
    for (const reuniao of novasReunioes) {
      if (!reuniao.ativo) {
        reunioesEncerradas.push(reuniao);
      }
    }
    setReunioes(reunioesEncerradas);
  }, [sala?.reunioes]);

  return (
    <div className="sala-container">
      <CardsHistorico
        reunioes={reunioes}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        historicoPerPage={historicoPerPage}
      />
      <div className="pagination-att" style={{ marginTop: "50px" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span style={{ color: "black" }}>
          {currentPage} ... {totalPages}
        </span>
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage + 1);
          }}
          disabled={currentPage === Math.ceil(totalPages) || totalPages === 0}
        >
          Próximo
        </button>
      </div>
    </div>
  );
};
