import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import './reply.css'
export default function Reply(props) {
    const { replyMessage, setReplyMessage } = props
    const [reply, setReply] = useState(null)

    useEffect(() => {
        if (replyMessage) {
            setReply(replyMessage)
        }
        else setReply(null)
    }, [replyMessage])

    if (reply) {
        return (
            <div className="reply">
                <div className="reply-content">
                    <h6 className="reply-message">{
                        reply.message.length > 30 ?
                            reply.message.slice(0, 25) + "..."
                            :
                            reply.message
                    }</h6>
                    <h6 className="reply-author">{reply.author}</h6>
                    <BiTrash className="trash" onClick={() => setReplyMessage(null)} color='#9ac31c' size={25} />
                </div>

            </div>
        )
    }
    else return <></>
}
