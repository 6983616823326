import { useState, useEffect } from "react";

export default function GroupCard(props) {
  const [grupo, setGrupo] = useState(props.grupo);

  useEffect(() => {
    setGrupo(props.grupo);
  }, [props.grupo]);
  return (
    <div className="group-card">
      <div className="group-card-header">
        <h3>{grupo.nome ? grupo.nome : "--"}</h3>
        <h4>Contatos:{grupo.contatos?.length}</h4>
      </div>
      <div className="group-card-body">
        <button
          className="edit-button"
          onClick={(e) => props.setSelectedGroup(grupo)}
        >
          Editar
        </button>
        <button
          className="delete-button"
          onClick={(e) => props.deleteGroup(grupo)}
        >
          Excluir
        </button>
      </div>
    </div>
  );
}
