// import { useState } from "react";
import MainView from "../main/MainView";
import BigView from "../main/BigView";
import { Agenda } from "./Agenda";

export const Profissional = () => {
  // const [onShow, setOnShow] = useState("agenda");

  // const components = {
  //   agenda: <Agenda />,
  // };

  return (
    <MainView title="Profissional">
      <BigView>
        <div className="button-view-container">
          <h3 style={{ color: "white", padding: "30px" }}>
            Agenda do profissional
          </h3>
          {/* {Object.keys(components).map((key, index) => {
            return (
              <button
                key={index}
                className={"button-view"}
                style={
                  onShow === key
                    ? { backgroundColor: "#1958b6", borderColor: "#1958b6" }
                    : null
                }
                onClick={() => setOnShow(key)}
              >
                {key}
              </button>
            );
          })} */}
        </div>
        <Agenda />
        {/* {components[onShow]} */}
      </BigView>
    </MainView>
  );
};
