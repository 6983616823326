import { useState, useEffect } from 'react'
import ListItem from './listItem'
import InputSearch from '../../../Campanhas/campanhas/CampanhaForm/AddContatos/Table/InputSearch'
import { BsInfoCircleFill } from 'react-icons/bs'

const TableRight = (props) => {
    const { selectedFromRight, setSelectedFromRight, myContatosOnShow, setMyContatosOnShow, isModalTableOpen } = props
    const [filteredMyContatos, setFilteredMyContatos] = useState([]);
    const [searchRigth, setSearchRigth] = useState("");

    function FilteredAttendancesRigth() {
        const newFilteredAttendancesRigth = myContatosOnShow.filter(
            (attendance) => {
                return (
                    attendance.nome?.toLowerCase().includes(searchRigth.toLowerCase()) ||
                    attendance.telefone
                        ?.toLowerCase()
                        .includes(searchRigth.toLowerCase()) ||
                    attendance.pushname?.toLowerCase().includes(searchRigth.toLowerCase())
                );
            }
        );
        setFilteredMyContatos(newFilteredAttendancesRigth);
    }

    const handleSelectAllRight = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const newSelectedFromRight = myContatosOnShow;
            setSelectedFromRight(newSelectedFromRight);
        } else {
            setSelectedFromRight([]);
        }
    };

    const handleSelectRight = (contato) => {
        const newSelectedFromRight = contato;
        if (selectedFromRight.includes(newSelectedFromRight)) {
            setSelectedFromRight(
                selectedFromRight.filter((item) => item !== newSelectedFromRight)
            );
        } else {
            setSelectedFromRight([...selectedFromRight, newSelectedFromRight]);
        }
    };

    useEffect(() => {
        FilteredAttendancesRigth();
    }, [searchRigth, myContatosOnShow]);

    return (
        <div className="table-right">
            <div className="table-right-container-multi-select">
                <input
                    type="checkbox"
                    checked={selectedFromRight.length && selectedFromRight.length === myContatosOnShow.length}
                    onChange={handleSelectAllRight}
                    style={{ width: "18px", height: "18px" }}
                />
                <p>Selecionar todos</p>
            </div>
            <div className="container-list-table">
                <InputSearch onChange={(e) => setSearchRigth(e.target.value)} />
                {
                    isModalTableOpen.type === 'members' && (
                        <div style={{ display: "flex", gap: "10px", padding: "20px 0px 0px 10px", marginBottom: "0x" }}>
                            <img src={"imgs/iconCoroa.svg"} alt="coroa" style={{ width: "25px", height: "25px" }} />
                            <p>Clique e transforme o contato em ADM.</p>
                        </div>
                    )
                }
                <div className="table-list">
                    {myContatosOnShow.length === 0 ? (
                        <div className="table-information-not-default">
                            <BsInfoCircleFill size={25} color="#9AC31C" />
                            <p>Lista vazia!</p>
                        </div>
                    ) : filteredMyContatos.length === 0 ? (
                        <div className="table-information-not-default">
                            <BsInfoCircleFill size={25} color="#9AC31C" />
                            <p>Nada encontrado!</p>
                        </div>
                    ) : (
                        filteredMyContatos.map((contato, i) => {
                            return (
                                <ListItem
                                    key={i}
                                    contato={contato}
                                    onChange={() => handleSelectRight(contato)}
                                    checked={selectedFromRight?.find(
                                        (selected) => selected.telefone === contato.telefone
                                    )}
                                    showIcon={isModalTableOpen.type === 'members' ? true : false}
                                    myContatosOnShow={myContatosOnShow}
                                    setMyContatosOnShow={setMyContatosOnShow}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

export default TableRight
