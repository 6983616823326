import React, { useContext } from 'react'
import { ReplyForwardingContext } from '../../../../../contexts/ReplyForwardingProvider'
import { FaReply, FaShare } from 'react-icons/fa'
import './hdnbtns.css'

export default function HiddenButtons(props) {
  const { setReplyMessage, setForwardingMessage } = useContext(ReplyForwardingContext)
  const { data, onSetHiddenButtons, hidden } = props

  const reply = () => {
    setReplyMessage(data)
    onSetHiddenButtons(false)
  }

  const forward = () => {
    let newFMessage = { ...data }
    if(data.forwarding) newFMessage = data.forwarding
    setForwardingMessage(newFMessage)
    onSetHiddenButtons(false)
  }
  if (!hidden) return ""
  else return (
    <div style={{width: "70%"}}>
      <div className="hidden-buttons">
      <button onClick={reply} className="hidden-button">
        <div >
        <FaReply /> &nbsp;  Responder
        </div>
      </button>
      <div>
        <button onClick={forward} className="hidden-button">
        <FaShare /> &nbsp; Encaminhar
        </button>    
      </div>
    </div>
    </div>
  )
}
