import React, { useState, useContext } from "react";
import { UserContext } from "../../../../contexts/UserProvider";
import CreateFormButtons from "./CreateFormButtons";
import HeaderTitle from "./HeaderTitle";
import FormInputName from "./FormInputName";
import FormInputCheckBox from "./FormInputCheckBox";
import "./createForm.css";

export default function CreateForm(props) {
  const context = useContext(UserContext);
  const botSchema = {
    nome: "",
    flow: "",
    primeiroFlow: "",
    status: "inativo",
    config: {
      horario: { inicio: "", fim: "" },
      dias: [],
      defaultMessage: [
        { title: "pós voto", messages: ["Agradecemos pelo seu voto!"] },
        { title: "não entendido", messages: ["Desculpe, não entendi."] },
        {
          title: "encaminhamento",
          messages: [
            "Por favor aguarde, estamos lhe encaminhando para um atendente.",
          ],
        },
        {
          title: "fora de hora",
          messages: ["Olá estamos fora do horários de atendimento."],
        },
        {
          title: "finalização",
          messages: ["Este atendimento foi finalizado."],
        },
        {
          title: "novo protocolo",
          messages: ["Seu Protocolo de Atendimento é ${ultimo protocolo}."],
        },
        {
          title: "pickup",
          messages: [
            "Bem-vindo ao atendimento digital da " +
              context.pref.nome.toString() +
              "\n meu nome é ${atendente_pickup}. Como posso Ajudar ?",
          ],
        },
        { title: "variaveis", messages: ["Seu numero foi salvo com sucesso."] },
        {
          title: "midía",
          messages: ["Aguarde a solicitação para enviar midías."],
        },
      ],
      comandosGerais: [{ action: "", next: "", valor: "" }],
      gruposEspecificos: [{ action: "", next: "", valor: "" }],
    },
    oficial: false,
    phoneId: "",
  };

  const [newBot, setNewBot] = useState(botSchema);

  function changeName(e) {
    let bot = { ...newBot };
    bot.nome = e.target.value;
    setNewBot(bot);
  }

  function saveBot(e) {
    e.preventDefault();
    if (newBot.nome.trim() !== "") {
      props.createBot(newBot);
    } else {
      props.alert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha o campo vazio!",
      });
    }
  }

  const setoficial = (e) => {
    let bot = { ...newBot };
    bot.oficial = e.target.checked;
    setNewBot(bot);
  };

  return (
    <>
      <form
        className="bot-form"
        onSubmit={props.loading ? null : (e) => saveBot(e)}
      >
        <div className="cont-align-text-buttons">
          <HeaderTitle title="Criar Bot" />
          <CreateFormButtons />
        </div>
        <FormInputName newBot={newBot} changeName={changeName} />
        <FormInputCheckBox newBot={newBot} setOficial={setoficial} />
      </form>
    </>
  );
}
