import React from "react";
import {
  BsEye,
  BsPencilSquare,
  BsTrash3,
  BsSlashCircle,
  BsDashCircle,
} from "react-icons/bs";
import "./styles.css";

const iconData = {
  BsEye: {
    component: BsEye,
    title: "Visualizar Chat",
    size: 20,
    color: "#9ac31c",
  },
  BsPencilSquare: {
    component: BsPencilSquare,
    title: "Editar Contato",
    size: 18,
    color: "#9ac31c",
  },
  BsTrash3: {
    component: BsTrash3,
    title: "Excluir Contato",
    size: 20,
    color: "#af1616",
  },
  BsSlashCircle: {
    component: BsSlashCircle,
    title: "Desbloquear Contato",
    size: 20,
    color: "#af1616",
  },
  BsDashCircle: {
    component: BsDashCircle,
    title: "Bloquear Contato",
    size: 20,
    color: "#9ac31c",
  },
};

const ButtonAction = ({ iconName, onClick, buttonText }) => {
  const { component: Icon, title, size, color } = iconData[iconName];

  return (
    <button className="icon-contacts" onClick={onClick} title={title}>
      <Icon size={size} color={color} />
      {buttonText && <span>{buttonText}</span>}
    </button>
  );
};

export default ButtonAction;
