import { useState, useContext, useEffect } from 'react'
import CreatableSelect from "react-select/creatable";
import { UserContext } from '../../../../../contexts/UserProvider';

const FromUserName = (props) => {
    const { tipo, editData, setEditData } = props
    const { pref, user } = useContext(UserContext);
    const [inputValue, setInputValue] = useState("");
    const [nomeSelecionado, setNomeSelecionado] = useState('');
    const [options, setOptions] = useState([]);


    useEffect(() => {
        if(!editData) return
        if (editData) {
            let newNome = { value: editData.fromUserName, label: editData.fromUserName }
            setNomeSelecionado(newNome)
        }
    }, [])

    useEffect(() => {
        if (nomeSelecionado && !editData) {
            let fromUserName = nomeSelecionado.value
            props.setData({ ...props.data, fromUserName })
        } else if (editData && nomeSelecionado) {
            let fromUserName = nomeSelecionado.value
            setEditData({ ...editData, fromUserName })
        }
    }, [nomeSelecionado])

    useEffect(() => {
        if (options.length === 0) {
            if (tipo === 'Atendimento') {
                let newOptions = pref?.users?.filter((user) => user.atendente).map((user) => {
                    return { value: user.nome, label: user.nome }
                })
                setOptions(newOptions)
            }else if(editData?.fromUserName){
                setNomeSelecionado({ value: editData.fromUserName, label: editData.fromUserName })

            } else {
                setNomeSelecionado({ value: user.nome, label: user.nome })
            }
        }
    }, [inputValue, tipo])

    const components = {
        DropdownIndicator: null,
        MultiValueRemove: (data) => {
            const removeConvidado = () => {
                console.log(data)
            };
            return (
                <button
                    className="css-12a83d4-MultiValueRemove"
                    type="button"
                    onClick={removeConvidado}
                >
                    ×
                </button>
            );
        },
    };

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                setInputValue("");
                event.preventDefault();
                break;
            default: {
                break;
            }
        }
    };

    const handleInputChange = (input) => {
        setInputValue(input);
    };

    return (
        <div style={{ minWidth: '300px' }}>
            {
                tipo === 'Atendimento' ? (
                    <CreatableSelect
                        isClearable
                        onChange={(newValue) => setNomeSelecionado(newValue)}
                        onInputChange={handleInputChange}
                        options={options}
                        onKeyDown={handleKeyDown}
                        inputValue={inputValue}
                        value={nomeSelecionado}
                        components={components}
                        placeholder="Digite o nome que irá enviar a mensagem"
                    />
                ) : (
                    <p>{editData ? editData?.fromUserName : user?.nome}</p>
                )
            }
        </div>
    )
}

export default FromUserName
