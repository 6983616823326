import React, { useState, useEffect, useContext } from 'react';
import { FlowBotContext } from '../../../contexts/ChatBotProvider/FlowBotProvider';
import ApiUsers from '../../../ApiUsers';
import VariablesHelper from './VariablesHelper';
import VariablesForm from './components/VariablesForm';
import Alert from '../../Configurações/OptionsScreen/inputs/Alerts/Alert';
import './variables.css';
import Painel from './components/painel';
import { BsPlusCircleFill } from 'react-icons/bs';

export default function Variables() {

    const masks = ["Nome", "Email", "CPF", "CNPJ", "Números", "CEP", "Sem Limite"];
    const context = useContext(FlowBotContext)
    const [variable, setVariable] = useState({ nome: "", mascara: "", reatribuir: false });
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [variables, setVariables] = useState([]);
    const [loading, setLoading] = useState(false);

    const [view, setView] = useState("painel");


    const views = {
        painel: <Painel
            variables={variables}
            deleteVariable={(v) => deleteVariable(v)}
            loading={loading}
            editVariable={openEditVariable}
        />,
        form: <VariablesForm
            masks={masks}
            variable={variable}
            loading={loading}
            changeVariable={(e, check) => changeVariable(e, check)}
            saveVariable={() => saveVariable()}
            closeForm={() => setView("painel")}
        />
    }

    const alertType = {
        normal: (
            <Alert
                title={alert.props.title}
                placeholder={alert.props.placeholder}
                quit={() => quitAlert()}
            />
        ),
    };

    useEffect(() => {
        setVariables(context.variables);
    }, [context.variables]);

    function changeVariable(e, check) {
        if (check) setVariable({ ...variable, [e.target.name]: e.target.checked });
        else setVariable({ ...variable, [e.target.name]: e.target.value });
    }

    function saveVariable() {
        if (variable.nome.trim().length > 0) {
            setLoading(true);
            ApiUsers.post("/whats/novaVariavel", { variavel: variable }).then(res => {
                createAlert("normal", { title: "Sucesso!", placeholder: "Variável criada com sucesso!" });
                setLoading(false);
            }).catch(err => console.log(err));
        } else {
            createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha o nome da variável!" });
        }
    }

    function deleteVariable(v) {
        setLoading(true);
        ApiUsers.post("/whats/deleteVariavel", { variavel: v }).then(res => {
            createAlert("normal", { title: "Sucesso!", placeholder: "Variável deletada com sucesso!" });
            setLoading(false);
        }).catch(err => console.log(err));
    }

    function quitAlert() {
        setAlert({ visible: false, type: "", props: {} });
    }

    function createAlert(type, props) {
        setAlert({ visible: true, type, props });
    }
    function openEditVariable(v) {
        setVariable(v);
        setView("form");
    }

    return <>
        {alert.visible && alertType[alert.type]}
        <div className="variables-container">
            {/* <VariablesHelper /> */}
            <div className="variables-header">
                <button className="add-button" onClick={() => setView("form")}>
                    <BsPlusCircleFill size={20} color="white" />
                    Adicionar Variável
                </button>
            </div>
            {views[view]}
        </div>
    </>

}
