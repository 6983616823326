import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "../../../../contexts/ServiceProvider";

function Agendamento(props) {
  const [command, setCommand] = useState(props.command);
  const [selectedFlow, setSelectedFlow] = useState(props.selectedFlow);
  const [defaultMessage, setDefaultMessage] = useState(
    props.command.defaultMessage
  );
  const state = useContext(ServiceContext);
  const [services, setServices] = useState(state.servicos);
  const [selectedService, setSelectedService] = useState("");

  console.log(command);

  useEffect(() => {
    setCommand(props.command);
    setSelectedFlow(props.selectedFlow);
    setDefaultMessage(props.command.defaultMessage);
    setSelectedService(state);
  }, [props.command, props.selectedFlow, state]);

  function setMySimpleAction(event) {
    let newCommand = { ...command };
    newCommand.action = event.target.value;
    props.setCommand(newCommand);
  }

  function setMySimpleNext(event) {
    let newCommand = { ...command };
    newCommand.next = event.target.value;
    props.setCommand(newCommand);
  }

  const handleMessageChange = (index, value) => {
    let newCommand = { ...command };
    const newDefaultMessage = [...defaultMessage];
    newDefaultMessage[index].message = value;
    newCommand.defaultMessage = newDefaultMessage;
    setDefaultMessage(newDefaultMessage);
    props.setCommand(newCommand);
  };

  const handleMessageSave = (index) => {
    let newCommand = { ...command };
    const newDefaultMessage = [...defaultMessage];
    defaultMessage[index].message = newDefaultMessage[index].message;
    newCommand.defaultMessage = newDefaultMessage;
    setDefaultMessage(newDefaultMessage);
    props.setCommand(newCommand);
  };

  const changeServices = (e) => {
    let newCommand = { ...command };
    const newService = [...services];
    newCommand.servicos = e.target.value;
    setSelectedService(newService);
    props.setCommand(newCommand);
  };

  return (
    <div>
      <div className="flow-form-alternative-container">
        <h5 style={{ margin: "15px 0px" }}> Agendamento </h5>
        <div className="flow-form-select-item">
          <select
            value={command.servicos}
            onChange={(e) => changeServices(e)}
            className="flow-form-select"
            style={{ flex: 1, marginLeft: 0 }}
          >
            <option value="">Selecione um serviço</option>
            {services.map((servicos, i) => (
              <option key={i} value={servicos._id}>
                {servicos.nome}
              </option>
            ))}
          </select>
        </div>

        <div className="flow-form-select-item">
          <select
            className="flow-form-select-actions"
            style={{ flex: 1, marginLeft: 0 }}
            value={command.action}
            onChange={(e) => setMySimpleAction(e)}
          >
            <option value={""}>Selecione uma ação</option>
            {props.selectActions.map((action, index3) => {
              return (
                <option key={index3} value={action.value}>
                  {" "}
                  {action.name}{" "}
                </option>
              );
            })}
          </select>

          <select
            className="flow-form-select-actions"
            style={{ flex: 1, marginRight: 0 }}
            value={command.next}
            onChange={(e) => setMySimpleNext(e)}
          >
            {command.action === "keep" ? (
              <>
                <option value={""}> Escolha uma ação </option>

                <option value={"keep"}> Seguir p/ Próximo comando </option>
                {selectedFlow.comandos.map((c, i) => {
                  return (
                    <option key={i} value={i}>
                      {" "}
                      {i + 1}º {c.nome}{" "}
                    </option>
                  );
                })}
              </>
            ) : (
              <>
                <option value={""}> Selecione uma ação </option>
                {command.action === "send-to-flow" ? (
                  <>
                    {props.flows.map((f, i) => {
                      return (
                        <option key={i} value={f._id}>
                          {" "}
                          {f.nome}{" "}
                        </option>
                      );
                    })}
                  </>
                ) : command.action === "send-to-chat" ? (
                  <>
                    {props.groups.map((g, i) => {
                      return (
                        <option key={i} value={g._id}>
                          {" "}
                          {g.nome}{" "}
                        </option>
                      );
                    })}
                  </>
                ) : null}
              </>
            )}
          </select>
        </div>

        <div className="flow-form-select-container">
          <h5 style={{ margin: "15px 0px" }}> Mensagens padrão </h5>
          {defaultMessage?.map((item, index) => (
            <div key={index} className="flow-form-select-card">
              <h5>{item.type}</h5>
              <textarea
                type="text"
                className="bot-form-input"
                placeholder={item.message}
                value={item.message}
                onChange={(e) => handleMessageChange(index, e.target.value)}
              />
              <button
                className="flow-card-button"
                title="Clique para salvar a mensagem"
                onClick={() => handleMessageSave(index)}
              >
                Alterar
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Agendamento;
