import React from "react";
import { BsRobot } from "react-icons/bs";

export default function HeaderTitle({ title, hideIcon }) {
  return (
    <div className="container-align-title">
      <BsRobot
        className={`robot-icon ${hideIcon ? "hidden" : ""}`}
        size={35}
        color="#9AC31C"
      />
      <h2 className="title">{title}</h2>
    </div>
  );
}
