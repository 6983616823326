import { useState, useEffect, useContext } from "react";
import { PesqPrContext } from "../../../../../contexts/ChatBotProvider/PesqPrProvider";
import { CalendarioContext } from "../../../../../contexts/CalendarioProvider";
import { AttendanceContext } from "../../../../../contexts/ChatBotProvider/AttendanceProvider";
import { BsDiagram3Fill, BsJournalBookmarkFill,BsCalendar3Week, BsMegaphoneFill,BsPatchCheckFill, BsPersonVcard } from "react-icons/bs";
import { LoadingSpinner } from "../../../../miscelaneous/Loader/load";
import "../gauge-message.css";

export default function CardData({ pref }) {
  const { pesquisas } = useContext(PesqPrContext);
  const { agendas } = useContext(CalendarioContext);
  const { attendances, loadingAttsMessages } = useContext(AttendanceContext);
  const [quantidadeContatos, setQuantidadeContatos] = useState({});
  const [quantidadePesquisas, setQuantidadePesquisas] = useState({});
  const [quantidadeMsgs, setQuantidadeMsgs] = useState({});
  const [agendaCardData, setAgendaCardData] = useState(null);
  const [quantSetores, setQuantSetores] = useState(0);

  useEffect(() => {
    let newPref = pref ? { ...pref } : {};
    let quantidadeSetores = 0;
    newPref?.departamentos?.forEach(departamento => {
        quantidadeSetores += departamento?.setores.length;
    });
    setQuantSetores(quantidadeSetores);
}, [pref]);


  const quantidadeDepartamentos = pref?.departamentos?.length || 0;

  useEffect(() => {
    let newPesquisas = pesquisas ? [...pesquisas] : [];
    let quantidadePesquisas = newPesquisas?.length;
    let quantidadeVotos = 0;
    newPesquisas?.forEach((pesquisa) => {
      if (pesquisa.votos) {
        quantidadeVotos += pesquisa.votos;
      }
    });
    setQuantidadePesquisas({ quantidadePesquisas, quantidadeVotos });
  }, [pesquisas]);

  useEffect(() => {
    let newContacts = pref?.services?.voxbot?.contatos
      ? [...pref?.services?.voxbot?.contatos]
      : [];
    let quantidadeContatos = newContacts?.length;
    let quantidadeContatosPotencial = newContacts?.filter((att) => {
      return (
        !att.waiting &&
        !att.atendente &&
        att.historico[att.historico.length - 1]?.action !== "Finalizado" &&
        new Date(att.lastMessage?.createdAt).getTime() >
          new Date(Date.now() - 1000 * 60 * 60 * 24).getTime()
      );
    }).length;
    setQuantidadeContatos({ quantidadeContatos, quantidadeContatosPotencial });
  }, [pref?.services?.voxbot?.contatos]);

  useEffect(() => {
    if (!pref?.services?.agenda?.ativo) {
      setAgendaCardData(null);
      return;
    }

    const newAgendas = agendas || [];
    const quantidadeAgendas = newAgendas.length;
    const quantidadeDatasMarcadas = newAgendas?.flatMap(
      (agenda) => agenda.datasMarcadas || []
    ).length;

    const quantidadeProfissionais = pref.services.agenda.especialidades
      ?.flatMap((especialidade) => especialidade.profissionais || [])
      .filter(
        (profissional, index, self) => self.indexOf(profissional) === index
      ).length;

    const quantidadesAgendamentosFuturos = newAgendas?.flatMap((agenda) =>
      (agenda.datasMarcadas || []).filter(
        (data) => new Date(data.data) > new Date()
      )
    ).length;

    const agendaCard = {
      icon: <BsCalendar3Week size={30} />,
      title: "Agendas",
      count: quantidadeAgendas,
      quantity: quantidadeDatasMarcadas,
      plural:
        quantidadeDatasMarcadas !== 1
          ? "Datas marcadas no total"
          : "data marcada na Agenda",
      loading: loadingAttsMessages,
    };

    const profissionaisCard = {
      icon: <BsPersonVcard size={30} />,
      title: "Profissionais",
      count: quantidadeProfissionais,
      quantity: quantidadesAgendamentosFuturos,
      plural:
        quantidadesAgendamentosFuturos !== 1
          ? "Agendamentos futuros"
          : "Agendamento futuro",

      loading: loadingAttsMessages,
    };

    setAgendaCardData([agendaCard, profissionaisCard]);
  }, [pref, agendas]);

    useEffect(() => {
        let newAtts = attendances ? [...attendances] : [];
        let allMessages = 0;
        let allMessagesRecebidas = 0;
        let allMessagesEnviadas = 0;
        newAtts.forEach((att) => {
            if (att.allMessages && att.allMessages.length > 0) {
                att.allMessages.forEach((msg) => {
                    if (!msg.createdAt) return;
                    allMessages++;
                    let users = pref?.users;
                    if (msg.from && !users?.some(user => user._id === msg.from)) {
                        allMessagesRecebidas++;
                    }
                    else{
                        allMessagesEnviadas++;
                    }
                });
            }
        });
        setQuantidadeMsgs({ allMessages, allMessagesRecebidas , allMessagesEnviadas });
    }, [attendances]);

    const cardsData = [
          {
            icon: <BsDiagram3Fill  size={30} />,
            title: "Departamentos",
            count: quantidadeDepartamentos,
            quantity: quantSetores,
            plural: quantSetores !== 1 ? 'Setores' : 'Setor'
          },
        {
            icon: <BsPatchCheckFill size={30} />,
            title: "Mensagens enviadas",
            count: quantidadeMsgs?.allMessagesEnviadas || 0,
            quantity: quantidadeMsgs?.allMessagesRecebidas || 0,
            plural: quantidadeMsgs?.allMessagesRecebidas !== 1 ? 'Mensagens recebidas' : 'Mensagem recebida',
            loading: loadingAttsMessages
        },
        {
            icon: <BsJournalBookmarkFill size={30} />,
            title: "Contatos",
            count: quantidadeContatos?.quantidadeContatos || 0,
            quantity: quantidadeContatos?.quantidadeContatosPotencial || 0,
            plural: quantidadeContatos?.quantidadeContatosPotencial !== 1 ? 'Contatos em potencial' : 'Contato em potencial',
            loading: loadingAttsMessages
        },
        {
            icon: <BsMegaphoneFill size={30} />,
            title: "Pesquisas",
            count: quantidadePesquisas?.quantidadePesquisas || 0,
            quantity: quantidadePesquisas?.quantidadeVotos || 0,
            plural: quantidadePesquisas?.quantidadeVotos !== 1 ? 'Votos nas pesquisas' : 'Voto na pesquisa',
            loading: loadingAttsMessages
        },
    ];

  if (agendaCardData) {
    agendaCardData.forEach((card) => {
      cardsData.push(card);
    });
    delete cardsData[2];
    delete cardsData[3];
  }

  return (
    <div className="cards-data">
      {cardsData.map((card, index) => {
        if (card.loading) {
          return (
            <div className="gauge-header-card" key={index}>
              <LoadingSpinner showModal="none" />
            </div>
          );
        }
        return (
          <div key={index} className="gauge-header-card">
            <div className="gauge-card-wrapper">
              <div
                className="gauge-icon"
                style={{
                  backgroundColor:
                    card.title === "Mensagens enviadas" ||
                    card.title === "Contatos" ||
                    card.title === "Agendas"
                      ? "#9ac31c"
                      : null,
                }}
              >
                {card.icon}
              </div>
              <div className="gauge-info">
                <h4>{card.count}</h4>
                <span>{card.title}</span>
              </div>
              <p>
                <span style={{ color: "#9ac31c" }}>{card.quantity}</span>{" "}
                {card.plural}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
