import React from "react";
import Accordion from "../inputs/Accordion/Accordion";
import "./Suport.css";
import { RiWhatsappFill } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";

export default function SuportShow(props) {
  const accordionDescs = {
    dúvidas: (
      <div className="accordion-desc">
        <span>
          Para o caso de dúvidas, sugestões, reclamações ou elogios, pedimos que
          abra um chamado através do Help Desk, informando todos os detalhes
          possíveis.
        </span>
      </div>
    ),
    chamado: (
      <div className="accordion-desc">
        <span>
          Para abrir um chamado, acesse o Help Desk do portal Voxcity clicando{" "}
          <a href="https://voxcity.tomticket.com/helpdesk" target="_blank">
            aqui
          </a>
          , e faça o acesso com suas credenciais.
        </span>
      </div>
    ),
    como_abrir_chamado: (
      <div className="accordion-desc">
        <span>
          Caso tenha dúvidas de como abrir um chamado, clique{" "}
          <a
            href="https://voxcity.tomticket.com/kb/manual-usuario/como-abrir-chamado"
            target="_blank"
          >
            aqui
          </a>{" "}
          para visualizar um tutorial detalhado de como realizar esse
          procedimento.
        </span>
      </div>
    ),
    contato: (
      <div className="accordion-desc">
        <span>
          Nosso atendimento está disponível de segunda a sexta, das 08:00H às
          12:00H e das 13:30H às 18:00H.
        </span>
        <br />
        <span style={{ textAlign: "center" }}>
          <RiWhatsappFill style={{ color: "green", fontSize: 20 }} /> (48)
          3478-2300
        </span>
        <span style={{ textAlign: "center" }}>
          <BsFillTelephoneFill style={{ fontSize: 20 }} /> (48) 3478-2300
        </span>
      </div>
    ),
    manual: (
      <div className="accordion-desc">
        <span>
          Preparamos para você um manual do usuário, com todas as
          funcionalidades do sistema explicadas de maneira detalhada para caso
          surgir alguma dúvida. Para realizar o download deste manual, clique{" "}
          <a href="exemplo/manual.pdf" download>
            aqui
          </a>
          .
        </span>
      </div>
    ),

    termos_de_uso: (
      <div className="accordion-desc">
        <span>
          Faça o download dos termos de uso , clique{" "}
          <a href="exemplo/termosDeUso.pdf" download>
            aqui
          </a>
          .
        </span>
      </div>
    ),
  };

  const accordion = [
    { name: "dúvidas", title: "Dúvidas", desc: accordionDescs["dúvidas"] },
    {
      name: "chamado",
      title: "Abrir Chamado",
      desc: accordionDescs["chamado"],
    },
    {
      name: "como_abrir_chamado",
      title: "Como abrir Chamado",
      desc: accordionDescs["como_abrir_chamado"],
    },
    {
      name: "contato",
      title: "Entrar em Contato",
      desc: accordionDescs["contato"],
    },
    {
      name: "manual",
      title: "Manual do Usuário",
      desc: accordionDescs["manual"],
    },

    {
      name: "termosDeUso",
      title: "Termos de Uso",
      desc: accordionDescs["termos_de_uso"],
    },
  ];

  return (
    <>
      <div className="suport-container">
        <h2 style={{ marginBottom: 20 }}> FAQ </h2>

        <Accordion accordion={accordion} />
      </div>
    </>
  );
}
