import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
export default function RightList(props) {
    const { grupo, setGrupo, contacts, setContacts, variables } = props
    return (
        <div className="group-form-body-right">
            <div className="group-form-body-right-header">
                <h2>Adicionar contatos</h2>
                <input type="text"
                    placeholder="Pesquisar"
                    className="group-form-body-input"
                    onChange={e => {
                        let newContacts = [...contacts]
                        newContacts = props.contacts.filter((c) => {
                            //PROCURAR POR VARIAVEIS DO CONTATO
                            let dispNameSearch = [c.nome, c.pushname, c.telefone]
                            variables.forEach((v) => {
                                let varname = v.nome
                                dispNameSearch.push(c[varname])
                            })
                            let found = false
                            dispNameSearch.forEach((d) => {
                                if (d) {
                                    if (d.toLowerCase().includes(e.target.value.toLowerCase())) {
                                        found = true
                                    }
                                }
                            })
                            return found
                        })
                        setContacts(newContacts)
                    }} />

                <button className="group-form-body-add-all-button"
                    onClick={e => {
                        let newGrupo = { ...grupo }
                        contacts.forEach((c) => {
                            delete c.allMessages
                            delete c.lastMessage
                            delete c.historico
                            newGrupo.contatos.push(c)
                        })
                        setGrupo(newGrupo)

                    }}>
                    Adicionar todos listados
                </button>
            </div>
            <div className="group-form-body-right-contacts">
                {
                    contacts.map((c, i) => {
                        let isAlreadyInList = false
                        grupo.contatos?.forEach((cont) => {
                            if (cont._id == c._id) {
                                isAlreadyInList = true
                            }
                        })
                        if (isAlreadyInList) {
                            return ""
                        }
                        return <h4 key={i}>{c.nome ? c.nome : c.pushname ? c.pushname : c.telefone}
                            &nbsp;
                            <AiOutlinePlusCircle color="#9ac31c" onClick={e => {
                                let newGrupo = { ...grupo }
                                let newContact = c
                                delete newContact.allMessages
                                delete newContact.historico
                                delete newContact.lastMessage
                                newGrupo.contatos.push(c)
                                setGrupo({ ...newGrupo })
                            }} />
                        </h4>
                    })
                }

            </div>

        </div>
    )
}
