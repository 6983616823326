import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { BiErrorCircle } from "react-icons/bi";
import Conta from './Inputs/conta';
import ConfirmarSenha from './Inputs/confirmarSenha';
import NovaSenha from './Inputs/novaSenha';
import { Link } from 'react-router-dom';
import ApiUsers from '../../ApiUsers';
import MiniLoading from '../Configurações/OptionsScreen/inputs/MiniLoading'

const ChangeForgotPassword = () => {
    const [hash, setHash] = useState('');
    const [dados, setDados] = useState({
        conta: '',
        novaSenha: '',
        confirmarSenha: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);


    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let conta = dados.conta;
        let senha = dados.novaSenha;
        let passwordConfirm = dados.confirmarSenha;
        if (senha !== passwordConfirm) {
            setError('senhas não conferem');
            setLoading(false);
            return
        }
        ApiUsers.post('/changeForgotPassword/', { hash, senha, conta })
            .then(res => {
                console.log(res.data);
                if (res.data.error) {
                    setError(res.data.error);
                    setLoading(false);
                    return
                }
                setLoading(false);
                setSuccess(true);
            })
            .catch(err => {
                setLoading(false);
                setError('problemas no envio');
                console.log(err);
            });
    }

    function setInputs(campo, valor) {
        setDados({ ...dados, [campo]: valor });
        setError("");
    }

    useEffect(() => {
        const hash = window.location.pathname.split('/changeforgot/')[1];
        setHash(hash);
    }, []);
    return (
        <>
            <div className="login-wrapper">
                <Form className="login-container" onSubmit={handleSubmit}>
                    <div className="login-header">
                        <img
                            src="https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
                            className="form-header-img"
                            alt="logo"
                        />
                    </div>
                    {error !== "" ?
                        <div className="error-container">
                            <BiErrorCircle /> <span className="error-msg"> {error} </span>
                        </div>

                        : null}

                    <Conta setInputs={setInputs} />
                    <NovaSenha setInputs={setInputs} />
                    <ConfirmarSenha setInputs={setInputs} />

                    <br />
                    {
                        success ?
                            <Button id="succes-btn" as={Link} to="/login" className="btn-login">
                                Sua senha foi alterada com sucesso!
                            </Button>
                            :
                            <Button style={{ backgroundColor: "#142a4c" }} title="Esqueceu a Senha" className="login-button" onClick={handleSubmit}>
                                {loading ? <MiniLoading /> : "Alterar Senha"}
                            </Button>
                    }
                    <Button style={{ marginBottom: '10px' }} as={Link} to="/login" variant='light' className="login-button">Voltar</Button>

                </Form>
            </div>
        </>

    );
}

export default ChangeForgotPassword;
