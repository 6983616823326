import React from 'react'
import { AiOutlineMinusCircle } from 'react-icons/ai'

export default function LeftList(props) {
    const { grupo, setGrupo, onSearch, setOnSearch, contactsOnSearch, setContactsOnSearch, variables } = props
    return (
        <div className="group-form-body-left">
            <div className="group-form-body-left-header">
                <h2>Contatos</h2>
                <input type="text" placeholder="Pesquisar"
                    className="group-form-body-input"
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            setOnSearch(e.target.value)
                        } else {
                            setOnSearch("")
                        }
                        let newcontactsOnSearch = grupo.contatos.filter((c) => {
                            let dispNameSearch = [c.nome, c.pushname, c.telefone]
                            //////PROCURAR VARIAVEIS DO CONTATO 
                            let found = false
                            variables?.forEach((v) => {
                                let varname = v.nome
                                dispNameSearch.push(c[varname])
                            })
                            dispNameSearch.forEach((d) => {
                                if (d) {
                                    if (d.toLowerCase().includes(e.target.value.toLowerCase())) {
                                        found = true
                                    }
                                }
                            })
                            return found
                        })
                        setContactsOnSearch([...newcontactsOnSearch])
                    }
                    } />


                <button className="group-form-body-left-remove-all-button" onClick={e => {
                    let newGrupo = { ...grupo }
                    if (onSearch) {
                        newGrupo.contatos = newGrupo.contatos.filter((c) => {
                            let shouldRemove = false
                            contactsOnSearch.forEach((cOnSearch) => {
                                if (cOnSearch._id === c._id) {
                                    shouldRemove = true
                                }
                            })
                            return !shouldRemove
                        })
                        setGrupo(newGrupo)
                    }
                    else {
                        newGrupo.contatos = []
                        setGrupo(newGrupo)
                    }
                }}>Remover Todos Listados</button>


            </div>

            <div className="group-form-body-left-contacts">
                {
                    onSearch ?
                        contactsOnSearch.map((c, i) => {
                            let dispName = c.nome ? c.nome : c.pushname ? c.pushname : c.telefone
                            return <h4 key={i}>{dispName}
                                &nbsp;
                                <AiOutlineMinusCircle color="red"
                                    onClick={e => {
                                        let newGrupo = { ...grupo }
                                        newGrupo.contatos = newGrupo.contatos.filter((cont) => {
                                            return cont.telefone != c.telefone
                                        })
                                        setGrupo(newGrupo)
                                    }}
                                />
                            </h4>
                        })
                        : grupo.contatos?.map((c, i) => {
                            let dispName = c.nome ? c.nome : c.pushname ? c.pushname : c.telefone
                            return <h4 key={i}>{dispName}
                                &nbsp;
                                <AiOutlineMinusCircle color="red"
                                    onClick={e => {
                                        let newGrupo = { ...grupo }
                                        newGrupo.contatos = newGrupo.contatos.filter((cont) => {
                                            return cont.telefone != c.telefone
                                        })
                                        setGrupo(newGrupo)
                                    }}
                                />
                            </h4>
                        })
                }
            </div>
        </div>
    )
}
