import React from "react";
import { BiLock } from "react-icons/bi";

const NovaSenha = (props) => {
  return (
    <div className="login-label">
      <label htmlFor="novaSenha">Digite sua nova senha</label>
      <div className="login-input-content">
        <input
          name="novaSenha"
          className="login-input"
          type="password"
          onChange={(event) => props.setInputs("novaSenha", event.target.value)}
        />
        <BiLock className="login-icon" />
      </div>
    </div>
  );
};

export default NovaSenha;
