import { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import { LocationProvider } from "./LocationProvider";
import { SpecialtyProvider } from "./SpecialtyProvider";
import { ProfessionalProvider } from "./ProfessionalProvider";
import { ServiceProvider } from "./ServiceProvider";
import { CalendarioContext } from "./CalendarioProvider";
import ApiCidadao from "../ApiCidadao";

export const AgendaContext = createContext();

export const AgendaProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const calendarioContext = useContext(CalendarioContext);
  const [agenda, setAgenda] = useState({});
  const [prefeitura, setPrefeitura] = useState({});
  const [isLoadingAgenda, setIsLoadingAgenda] = useState(false);
  const [selecionados, setSelecionados] = useState([]);

  useEffect(() => {
    const novaPrefeitura = userContext.pref ? { ...userContext.pref } : {};
    setPrefeitura(novaPrefeitura);

    const novaAgenda = novaPrefeitura.services.agenda
      ? { ...novaPrefeitura.services.agenda }
      : {};
    setAgenda(novaAgenda);
  }, [userContext.pref]);

  useEffect(() => {
    const agendas = [];

    agenda?.serviços?.forEach((servico) => {
      const { _id: servicoId, locais: servicoLocais } = servico;

      servicoLocais.forEach((localId) => {
        const local = agenda?.locais?.find((l) => l._id === localId);

        local?.especialidades?.forEach((especialidade) => {
          const { _id: especialidadeId } = especialidade;

          agendas.push({
            local: localId,
            servico: servicoId,
            especialidade: especialidadeId,
            prefeitura: prefeitura._id,
          });
        });
      });
    });

    const promises = agendas.map((agenda) =>
      ApiCidadao.post("/agendamento/getAgenda", agenda)
    );
    if (agendas.length > 0) {
      setIsLoadingAgenda(true);
      console.log("recebendo agendas");
      Promise.all(promises)
        .then(() => {
          calendarioContext.receberAgendas(prefeitura._id);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingAgenda(false);
          console.log("agendas recebidas");
        });
    }
  }, [agenda, prefeitura]);

  return (
    <LocationProvider>
      <SpecialtyProvider>
        <ProfessionalProvider>
          <ServiceProvider>
            <AgendaContext.Provider
              value={{
                isLoadingAgenda,
                selecionados,
                setSelecionados,
              }}
            >
              {children}
            </AgendaContext.Provider>
          </ServiceProvider>
        </ProfessionalProvider>
      </SpecialtyProvider>
    </LocationProvider>
  );
};
