import { useEffect, useRef } from "react";
import "./styles.css";

export const Menu = ({ item, itemConfig, isMenuOpen, onToggleMenu, callback }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target) && e.target.tagName !== "svg" && e.target.tagName !== "path") {
        onToggleMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onToggleMenu]);

  return (
    isMenuOpen && (
      <ul
        ref={menuRef}
        className="accordion-section-menu">
        {itemConfig.actions &&
          itemConfig.actions.map((actionItem, actionIndex) => (
            <li
              key={actionIndex}
              onClick={(e) => {
                e.stopPropagation();
                actionItem.action(item, callback);
                onToggleMenu();
              }}
              title={`${actionItem.label} ${item.nome}`}
              className="accordion-section-menu-item">
              {actionItem.icon && actionItem.icon}
              {actionItem.label}
            </li>
          ))}
      </ul>
    )
  );
};
