import React, { useState, useEffect } from "react";
import Graph from "./Types/grafic";
import Cards from "./Types/cards";
import { LoadingSpinner } from "../../../miscelaneous/Loader/load";

const GraficoCarousel = ({ pref, zeroState }) => {
  const [data, setData] = useState({});
  const [showGraph, setShowGraph] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowGraph((prevShowGraph) => {
        setIsTransitioning(true);
        setTimeout(() => setIsTransitioning(false), 300);
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? 1 : 0));
        return !prevShowGraph;
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getDadosGraficos = async () => {
    if (!pref.users) return;
    let newPref = { ...pref };
    newPref?.users?.forEach((user) => {
      if (user.allMessages) {
        delete user.allMessages;
      }
    });

    let atts = newPref.services?.voxbot?.contatos;
    let attsPendentes = 0;
    let attsFinalizados = 0;
    let attsOn = 0;

    newPref?.users?.forEach((user) => {
      atts?.forEach((att) => {
        if (
          user._id === att.atendente &&
          att.historico[att.historico.length - 1]?.action !== "Finalizado"
        ) {
          attsOn++;
        } else if (
          att.historico[att.historico.length - 1]?.action === "Finalizado" &&
          att.historico[att.historico.length - 1]?.user === user._id
        ) {
          attsFinalizados++;
        } else if (att.isWaiting) {
          attsPendentes++;
        }
      });
    });

    setData({
      attsPendentes,
      attsFinalizados,
      attsOn,
    });
  };

  const fetchData = async () => {
    await getDadosGraficos();
    setLoad(false);
  };

  useEffect(() => {
    fetchData();
  }, [pref]);

  const totalSlides = 2;

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    setShowGraph(index === 0);
  };

  return (
    <div
      className={`grafic-carousel${isTransitioning ? " transitioning" : ""}`}
    >
      {load ? (
        <div className="container-loader">
          <LoadingSpinner showModal="none" />
        </div>
      ) : (
        <>
          <div className="grafic-box-header">
            <h3>Resumo dos atendimentos</h3>
          </div>
          {showGraph ? <Graph data={data} /> : <Cards data={data} />}
          <div className="dots-container">
            {Array.from({ length: totalSlides }).map((_, index) => (
              <div
                key={index}
                className={`dot${currentSlide === index ? " active" : ""}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default GraficoCarousel;
