import { FaUser } from "react-icons/fa";
import { translateDate } from "../../../../miscelaneous/utils";

export const HorarioMarcado = (props) => {
  const { dataAgendada } = props;

  if (dataAgendada.status === "bloqueio") {
    return (
      <p className="bloqueado">
        <span className="data-marcada">
          {translateDate(props.dataAgendada.data)}
        </span>
        <span> - HORÁRIO BLOQUEADO</span>
        <input
          id="inputBloqueado"
          type="checkbox"
          value={props.dataAgendada}
          onChange={(e) => props.handleCheckboxChange(e, props.dataAgendada)}
          className="bloqueado-checkbox"
        />
      </p>
    );
  }

  return (
    <p className="marcado">
      <span className="data-marcada">
        {translateDate(props.dataAgendada.data)}
      </span>
      <span className="marcado-info-cidadao">
        <FaUser
          className="cidadao-icon"
          onClick={() => props.getCidadao(props.dataAgendada.usuario)}
        />
        <span className="nome-cidadao">
          Nome:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.nome
          }
        </span>
        <span className="telefone-cidadao">
          Telefone:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.telefone
          }
        </span>
        {new Date() <= new Date(props.dataAgendada.data) && (
          <button
            className="btn-confirmar-agendamento"
            onClick={() => props.submitConfirmarAgendamento(props.dataAgendada)}
          >
            CONFIRMAR
          </button>
        )}
        {new Date() >= new Date(props.dataAgendada.data) && (
          <button
            className="btn-nao-compareceu"
            onClick={() => props.submitNaoCompareceu(props.dataAgendada.hash)}
          >
            Não compareceu
          </button>
        )}
      </span>
      {new Date(props.dataAgendada.data) >= new Date() && (
        <input
          id="inputMarcado"
          type="checkbox"
          value={props.dataAgendada}
          onChange={(e) => props.handleCheckboxChange(e, props.dataAgendada)}
          className="marcado-checkbox"
        />
      )}
    </p>
  );
};
