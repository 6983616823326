import React from 'react'
import { AiFillAudio, AiFillPauseCircle } from 'react-icons/ai'
const MicRecorder = require('mic-recorder-to-mp3');
const recorder = new MicRecorder({
    bitRate: 128
});


export default function Recorder(props) {

    const { audio, setAudio, recording, setRecord, contact } = props

    function startAudio() {
        setRecord(true)
        recorder.start().then(() => {
            //começoiu a gravar
            //add socket gravando audio 
            // props.socket.emit("start recording", { to: props.contact._id })
        });
    }

    function stopAudio() {
        recorder.stop().getMp3().then(([buffer, blob]) => {
            let newAudio = blob//buffer
            setAudio(newAudio)
            setRecord(false)
            // props.socket.emit("stop recording", { to: props.contact._id })
        });
    }

    return (
        <div>   {
            recording ?
                <AiFillPauseCircle onClick={stopAudio} className="chat-messenger-icon" title="Pausar áudio" />
                :
                audio ? null : <AiFillAudio
                    onClick={!contact ? null : startAudio}
                    className='chat-messenger-icon' title="Gravar áudio" />
        }</div>
    )
}
