import { useState, useEffect, useContext } from "react";
import ApiUsers from "../../../../ApiUsers";
import { LocationContext } from "../../../../contexts/LocationProvider";
import Select from "react-select";
import "./style.css";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import { ConfigForm } from "./ConfigForm";

export const ServicoForm = (props) => {
  const [servico, setServico] = useState({
    _id: "",
    nome: "",
    locais: [],
    avisos: [],
    confirms: [],
    tempoConfirm: "",
    bot: {},
    msgConfirmacao: {},
    mensagemConfirmacao: "",
    mensagemConfirmado: "",
    mensagemWarning: "",
    mensagemMarcado: "",
    mensagemCancelado: "",
    mensagemDesmarcado: "",
    envioMsg: {},
  });

  const [locaisSelecionados, setLocaisSelecionados] = useState([]);
  const [configServico, setConfigServico] = useState(false);

  const locationContext = useContext(LocationContext);
  const options = locationContext.locais.map((local) => ({
    value: local._id,
    label: local.nome,
  }));

  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  const validateLocal = () => {
    let qtdLocal = locaisSelecionados.map((local) => local);

    if (!qtdLocal.length) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, adicione pelo menos um local.",
      });
      return false;
    }
    return true;
  };

  const validateServico = () => {
    if (!servico.nome) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, preencha todos os campos.",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    let novoServico = { ...props.servico };
    const locaisFiltrados = locationContext.locais.filter((local) =>
      props.servico.locais.includes(local._id)
    );
    setServico(novoServico);
    setLocaisSelecionados(
      locaisFiltrados.map((locais) => ({
        value: locais._id,
        label: locais.nome,
      }))
    );
  }, [locationContext.locais, props.servico]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateLocal()) {
      return;
    }
    if (!validateServico()) {
      return;
    }
    const idLocal = locaisSelecionados.map((local) => local.value);
    ApiUsers.post("/servicos/novo", {
      service: {
        ...servico,
        locais: [...idLocal],
      },
    }).catch((err) => console.log(err));
    props.setSelecionado(null);
  };

  const onSubmitUpdate = (e) => {
    const idLocal = locaisSelecionados.map((local) => local.value);
    e.preventDefault();
    if (!validateServico()) {
      return;
    }
    if (!validateLocal()) {
      return;
    }
    servico.locais = [...idLocal];
    props.updateServico(servico);
  };

  const onChange = ({ target: { name, value } }) => {
    setServico((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeSelect = (locaisOptions) => {
    const locaisFiltrados = locationContext.locais.filter((local) =>
      props.servico.locais.includes(local._id)
    );
    for (let i = 0; i < locaisFiltrados.length; i++) {
      let index = locaisOptions.indexOf(locaisFiltrados[i]);
      if (servico.locais.indexOf(locaisOptions[i]) === -1) {
        locaisFiltrados.splice(index, 1);
        setLocaisSelecionados(locaisFiltrados);
      }
    }
    setLocaisSelecionados(locaisOptions);
  };

  const handleBack = () => {
    props.setSelecionado(null);
    props.setEditando(false);
  };

  return (
    <>
      {configServico && <div className="bg-modal-servico"></div>}
      <form
        className="servico-form"
        onSubmit={props.editando ? onSubmitUpdate : onSubmit}
      >
        {alert.visible && alertType[alert.type]}
        <div className="servico-form-title">SERVIÇO</div>
        <div className="servico-form-content">
          <div className="servico-form-input-container">
            <label htmlFor="nome" className="required-prof">
              Nome
            </label>
            <input
              type="text"
              id="nome"
              value={servico.nome}
              onChange={onChange}
              name="nome"
            />
          </div>
        </div>
        <div className="servico-form-content">
          <div className="servico-form-input-container">
            <label htmlFor="local" className="required-prof">
              Locais
            </label>
            <Select
              options={options}
              isMulti
              value={locaisSelecionados}
              onChange={onChangeSelect}
              noOptionsMessage={() => "Sem Local"}
              placeholder={"Selecione..."}
            />
          </div>
        </div>
        <div className="servico-form-content">
          <p className="config-servico-btn" type="button">
            <span onClick={() => setConfigServico(true)}>
              Configurar Envio de Mensagens
            </span>
          </p>
        </div>
        <div className="button-container">
          <button id="back" type="button" onClick={handleBack}>
            Voltar
          </button>
          <button type="submit" id="add">
            Salvar
          </button>
        </div>
      </form>
      {configServico && (
        <ConfigForm
          servico={servico}
          setServico={setServico}
          bots={props.bots}
          onChange={onChange}
          configServico={configServico}
          setConfigServico={setConfigServico}
        />
      )}
    </>
  );
};
