import React, { useState } from 'react';
import '../Section.css';
import SimplyForm from '../../inputs/SimplyForm';
import SelectUserForm from '../../inputs/SelectUserForm';
import ApiUsers from '../../../../../ApiUsers';

export default function SectionEdit(props) {

    const [setor, setSetor] = useState(structuredClone(props.setor))
    const [loading, setLoading] = useState(false);

    function changeName(e) {
        let newSetor = { ...setor };
        newSetor.nome = e.target.value;
        setSetor(newSetor);
    }

    function changeConfRoom(e) {
        let newSetor = { ...setor };
        newSetor.sala_de_conferencia = e.target.value;
        setSetor(newSetor);
    }

    function changeDepartment(e) {
        let newSetor = { ...setor };
        newSetor.departamento = e.target.value;
        setSetor(newSetor);
    }

    function editSection(e) {
        e.preventDefault();
        if (setor.nome.trim() !== "" && setor.departamento !== "" && setor.sala_de_conferencia.trim() !== "") {          
                setLoading(true);
                let newSector = setor;
                newSector.nome = setor.nome.trim();
                newSector.departamento = setor.departamento.trim();
                newSector.sala_de_conferencia = setor.sala_de_conferencia.trim();
                ApiUsers.put(`/setores/${setor._id}`, { setor: newSector, lastName: props.setor.nome, lastDepId: props.setor.depId}).then((res) => {
                    if (res.data.acknowledged) {
                        setLoading(false);
                        console.log({setor:newSector, lastDepId:props.setor.depId})
                        props.alert("normal", { title: "Sucesso!", placeholder: "Setor editado com sucesso!" });
                        props.goBack();
                    }
                }).catch((err) => console.log(err));
            } else {
                props.alert("normal", { title: "Ops!", placeholder: "Não foram identificadas alterações!" });
            }

    }

    return <>

        <div className='section-edit-container'>

            <SimplyForm
                title="Editar Setor"
                id="section-form"
                field="nome"
                field2="sala_de_conferencia"
                placeholder="Digite aqui o nome do setor"
                placeholder2="Digite aqui a sala de conferência"
                value={setor.nome}
                value2={setor.sala_de_conferencia}
                handleChange={(e) => changeName(e)}
                handleChange2={(e) => changeConfRoom(e)}
                handleSubmit={(e) => editSection(e)}
                goBack={() => props.goBack()}
                loadingButton={loading}
            >

                <SelectUserForm
                    title="Adicionar Usuários"
                    id="section-form"
                    field="departamento"
                    value={setor.departamento}
                    handleChange={(e) => changeDepartment(e)}
                />

            </SimplyForm>

        </div>

    </>
}