import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap'
import { BiErrorCircle } from 'react-icons/bi'
import Alert from '../Configurações/OptionsScreen/inputs/Alerts/Alert';
import MiniLoading from '../Configurações/OptionsScreen/inputs/MiniLoading'
import ApiUsers from '../../ApiUsers'
import Ramal from './Inputs/ramal'
import Nome from './Inputs/nome'
import Email from './Inputs/email'
import Telefone from './Inputs/telefone'
import './LoginRefatored.css'

export default function Registers() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [prefHash, setPrefHash] = useState("")
    const [hashLink, setHashLink] = useState("")
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [user, setUser] = useState({
        nome: "",
        email: "",
        telefone: "",
        ramal: "",
    })

    let { param1, param2 } = useParams()

    const alertType = {
        normal: (
            <Alert
                title={alert.props.title}
                placeholder={alert.props.placeholder}
                quit={() => quitAlert()}
            />
        )
    }

    useEffect(() => {
        if (param1) {
            setPrefHash(param1)
        }
        if (param2) {
            setHashLink(param2)
        }
        console.log(param1, param2)
    }, [param1, param2])

    const setValue = (name, value) => {
        setUser({ ...user, [name]: value });
    }


    function quitAlert() {
        setAlert({ visible: false, type: "", props: {} });
    }
    const save = async () => {
        if (user.nome === "" || user.email === "" || user.telefone === "" || user.ramal === "") {
            setError("Preencha todos os campos")
            return
        }
        await ApiUsers.post('/register/newUser', { user, prefHash, hashLink }).then((res) => {
            if (res.data.error) {
                setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: res.data.error } })
                return
            }
            setError("")
            setUser({
                nome: "",
                email: "",
                telefone: "",
                ramal: "",
            })
            setSuccess(true)
        }
        ).catch((err) => { console.log(err) })
        setLoading(false)
    }


    return (
        <>
            <div className="login-wrapper">
                {alert.visible && alertType[alert.type]}
                <Form className="login-container">
                    <div className="login-header">
                        <img
                            src="https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
                            className="form-header-img"
                            alt="logo"
                        />
                    </div>

                    <div className="login-form"  >

                        <Nome setInputs={setValue} />
                        <Email setInputs={setValue} />
                        <Telefone setInputs={setValue} />
                        <Ramal setInputs={setValue} />

                        {error !== "" ? (
                            <div className="error-container-new">
                                <BiErrorCircle />{" "}
                                <span className="error-msg-new"> {error} </span>
                            </div>
                        ) : null}

                        {
                            !success ? <Button className="login-button"
                                onClick={save}
                                disabled={loading}
                                style={{ backgroundColor: "#142a4c", margin: "40px" }}>
                                {
                                    loading ? <MiniLoading /> : "Salvar"
                                }
                            </Button>
                                : <Button className="login-button" disabled style={{ backgroundColor: "#142a4c", margin: "40px" }}>Cadastrado com sucesso!</Button>
                        }

                    </div>
                </Form>
            </div>
        </>
    );
}
