import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiUsers from "../ApiUsers";
import tinycolor from "tinycolor2";
import { io } from "socket.io-client";
import env from "react-dotenv";
const UserContext = createContext();

function UserProvider({ children }) {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(false);
  const [pref, setPref] = useState(false);
  const [token, setToken] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [talking, setTalking] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [disconnected, setDisconnected] = useState(false);
  const [attendance, seAttendance] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [reconnectAlert, setReconnectAlert] = useState(false);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [screenSocket, setScreenSocket] = useState(false);
  const [cidadaoSocket, setCidadaoSocket] = useState(null);
  const [msgsRapidasUsuario, setMsgsRapidasUsuario] = useState([]);

  function logIn(user) {
    setLoading(true);
    ApiUsers.post("/login", user)
      .then(async (resp) => {
        //destroy cookie
        document.cookie = `VoxchatRT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        if (resp.data.firstAccess) {
          setPref(resp.data.pref);
          setUser(user);
          setLoading(false);
          navigate("/firstAccess");
          return;
        }
        if (!resp.data.accessToken) setError(resp.data.error);
        else await setMyContext(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  async function setMyContext(resp) {
    let accessToken = resp.data.accessToken;
    let respUser = resp.data.user;
    let respPref = resp.data.pref;
    ApiUsers.defaults.headers["authorization"] =
      "Bearer " + resp.data.accessToken;
    setAuth(true);
    setUser(respUser);
    setToken(accessToken);
    await loadAllUsersColors(respUser, respPref);
    setPref(respPref);
    if (Object.keys(respPref.services).includes("whatsapp"))
      if (respUser.admin || respUser.atendente) loadAttendance(respPref);
    document.cookie = `VoxchatRT=${resp.data.refreshToken}; expires= ${new Date(
      Date.now() + 7200000
    ).toUTCString()};path=/;`;
    document.cookie = `userId=${respUser._id}; path=/; domain=voxcity.com.br; samesite=None; secure`;
    // document.cookie = `peerId=${respUser._id};`;
    navigate("/home");
  }

  function logout() {
    setAuth(false);
    setUser(false);
    setToken(false);
    setPref(false);
    ApiUsers.defaults.headers["authorization"] = "";
    document.cookie = `VoxchatRT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    window.location.reload();
  }

  function navigateToChat() {
    navigate("chat");
  }
  function setChat(chat) {
    setTalking(chat);
  }
  function isMyId(id) {
    return id === user._id;
  }

  function loadAttendance() {
    ApiUsers.post("/atendimentos")
      .then((resp) => {
        seAttendance(resp.data);
      })
      .catch((err) => {});
  }

  async function loadAllUsersColors(respUser, respPref) {
    let users = respPref.users;
    await loadMessagesAndSetContacts(users, respUser);
    users.forEach((user) => {
      let randomColor = `#${Math.floor(Math.random() * 0x1000000)
        .toString(16)
        .padStart(6, "0")}`;
      const color = tinycolor(randomColor);
      if (color.isLight()) {
        while (color.isLight()) {
          let newRandomColor = `#${Math.floor(Math.random() * 0x1000000)
            .toString(16)
            .padStart(6, "0")}`;
          const newColor = tinycolor(newRandomColor);
          if (newColor.isDark()) {
            user.color = newRandomColor;
            break;
          }
        }
      } else user.color = randomColor;
    });
    setAllUsers(users);
  }

  async function loadMessagesAndSetContacts(repscontacts, respUser) {
    return await ApiUsers.get("/user/lastmessages")
      .then((resp) => {
        let messages = resp.data;
        let contacx = [];
        if (messages.length === 0) {
          messages = [];
          contacx = repscontacts.map((contact) => {
            contact.allMessages = [];
            contact.lastMessage = "";
            return contact;
          });
        } else {
          contacx = repscontacts.map((contact) => {
            for (let i = 0; i < messages.length; i++) {
              let lastMessage =
                messages[i].messagesRoll[messages[i].messagesRoll.length - 1];
              if (messages[i].between.includes(contact._id)) {
                let counter = getNotRead(messages[i].messagesRoll, respUser);
                contact.unseen = counter;
                contact.allMessages = messages[i].messagesRoll;
                contact.lastMessage = {
                  message: lastMessage.message,
                  createdAt: lastMessage.createdAt,
                };
              }
            }
            contact.allMessages = contact.allMessages
              ? contact.allMessages
              : [];
            return contact;
          });
          contacx.sort((a, b) => {
            if (!a.lastMessage) {
              return 1;
            }
            if (!b.lastMessage) {
              return -1;
            }
            let dateA = Date.parse(a.lastMessage.createdAt);
            let dateB = Date.parse(b.lastMessage.createdAt);
            if (dateA > dateB) {
              return -1;
            }
            if (dateA < dateB) {
              return 1;
            }
            return 0;
          });
        }
        contacx = contacx.filter((contact) => contact.ativo);
        setContacts(contacx);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function reload() {
    window.location.reload();
  }

  function getNotRead(arrMsgs, respUser) {
    let count = [];
    arrMsgs.forEach((msg) => {
      if (!msg.seenBy.includes(respUser._id)) count.push(msg);
    });
    return count;
  }
  function isGroup(contact) {
    return Object.keys(contact).includes("usuarios") ? "Grupo" : "Privado";
  }
  function includesMyId(array) {
    return array.includes(user._id);
  }
  function amITalkingTo(id) {
    return talking === id;
  }

  const salvarMsgRapida = async (msgRapida) => {
    await ApiUsers.post(`/msgsRapidas/salvar`, { msgRapida })
      .then((resp) => {
        const msg = resp.data;
        setMsgsRapidasUsuario([...msgsRapidasUsuario, msg]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apagarMsgRapida = async (msgRapida) => {
    await ApiUsers.post(`/msgsRapidas/apagar`, { msgRapida })
      .then(() => {
        const msgsAtualizadas = msgsRapidasUsuario.filter(
          (m) => m._id !== msgRapida._id
        );
        setMsgsRapidasUsuario(msgsAtualizadas);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editarMsgRapida = async (msgRapida) => {
    await ApiUsers.post(`/msgsRapidas/editar`, { msgRapida })
      .then(() => {
        setMsgsRapidasUsuario(
          msgsRapidasUsuario.map((m) =>
            m._id === msgRapida._id ? msgRapida : m
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (token && socket === null) {
      let url = env
        ? env.REACT_APP_SOCKET_URL
        : "wss://mensageria.voxcity.com.br";
      const newSocket = io.connect(url, {
        query: {
          token: token,
        },
      });
      newSocket.on('jwt expired', () => {
        console.log('jwt expired')
        logout()
      })
      setSocket(newSocket);

    }
    return () => {};
  }, [token, setSocket]);

  useEffect(() => {
    let url = env
      ? env.REACT_CIDADAO_SOCKET_URL
      : "wss://cidadao-api.voxcity.com.br";
    // let url = "http://localhost:4001";
    if (cidadaoSocket === null && pref?._id) {
      const newSocket = io.connect(url, {
        query: {
          token: env.AGENDA_SECRET_KEY + "-" + pref._id,
        },
      });
      setCidadaoSocket(newSocket);
    }
  }, [pref, setCidadaoSocket]);

  useEffect(() => {
    let token = document.cookie.split("VoxchatRT=")[1];
    localStorage.removeItem("selectedContact");
    // if (!tokens) return
    console.log("tokens", token);
    // let token = tokens.find(token => token.includes("VoxchatRT"))?.split(";")[0]
    token = token && token.includes(";") ? token.split(";")[0] : token;
    localStorage.removeItem("sel");
    if (token && !auth) {
      setLoading(true);
      let location = window.location.pathname;
      if (location.includes("register")) {
        setLoading(false);
        return;
      }
      console.log("token", token);
      ApiUsers.post("/refreshToken", { token })
        .then(async (resp) => {
          if (!resp.data.accessToken) {
            //remove cookkies
            document.cookie = `VoxchatRT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            setLoading(false);
            return;
          } else await setMyContext(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [auth, token, user]);

  useEffect(() => {
    const novasMsgsRapidas = user.config?.msgsRapidas
      ? user.config?.msgsRapidas
      : [];
    setMsgsRapidasUsuario(novasMsgsRapidas);
  }, [user.config?.msgsRapidas]);

  return (
    <UserContext.Provider
      value={{
        auth,
        theme: "none",
        logIn,
        logout,
        isMyId,
        isGroup,
        includesMyId,
        setToken,
        setUser,
        setPref,
        user,
        pref,
        contacts,
        setContacts,
        messages,
        setMessages,
        allUsers,
        attendance,
        token,
        error,
        setError,
        loading,
        setChat,
        socket,
        amITalkingTo,
        disconnected,
        navigateToChat,
        setDisconnected,
        screenSocket,
        setScreenSocket,
        reconnectAlert,
        setReconnectAlert,
        reload,
        cidadaoSocket,
        msgsRapidasUsuario,
        setMsgsRapidasUsuario,
        salvarMsgRapida,
        apagarMsgRapida,
        editarMsgRapida,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
