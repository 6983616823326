import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { useEffect } from "react";
import { UserContext } from "../UserProvider";
import ApiUsers from "../../ApiUsers";
import { PrefContext } from "./PrefProvider";
const AttendanceContext = createContext();

function AttendanceProvider({ children }) {
  const context = useContext(UserContext);
  const prefContext = useContext(PrefContext);
  const [attendances, setAttendances] = useState([
    ...context.pref.services.voxbot.contatos,
  ]);
  const [myHistoric, setMyHistoric] = useState([]);
  const [waitingAttendances, setWaitingAttendances] = useState([]);
  const [loadingAttsMessages, setLoadingAttsMessages] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const userSocket = context.socket;
  const [myWaitingAtt, setMyWaitingAtt] = useState([]);
  const [reinit, setReinit] = useState(false);

  const countUnreadMessages = (messages) => {
    let count = [];
    if (!messages) return count;
    messages.forEach((msg) => {
      if (!msg.seenBy?.includes(context.user._id)) {
        count.push(msg);
      }
    });
    return count;
  };

  useEffect(() => {
    let newAtt = [...attendances];
    newAtt.forEach((att) => {
      att.lastMessage = att.lastMessage || '';
      att.allMessages = [];
    });
  }, []);

  useMemo(() => {
    loadAttsAndMessages();
  }, []);

  useEffect(() => {
    let counter = 0;
    attendances?.forEach((att) => {
      if (att.atendente === context.user._id && att.unseen?.length > 0) {
        counter += att.unseen.length;
      }
    });
    setUnreadMessages(counter);
  }, [attendances]);

  useEffect(() => {
    let newWaitingAtt = [];
    prefContext.atendentes.forEach((grp) => {
      attendances.forEach((att) => {
        if (att.grupo === grp._id && att.waiting) {
          newWaitingAtt.push({ grupo: grp._id, contato: att });
        }
      });
    });
    setWaitingAttendances([...newWaitingAtt]);
  }, [attendances]);

  useEffect(() => {
    console.log("attendances socket");
    initSockEvents();
    return () => {
      setOff();
    };
  }, []);

  function getMyWaitingAtt() {
    if (!context.user.admin) {
      let mygroups = prefContext.atendentes.filter((grp) =>
        grp.usuarios.includes(context.user._id)
      );
      let myattswaitin = attendances.filter(
        (att) => mygroups.some((grp) => grp._id === att.grupo) && att.waiting
      );
      setMyWaitingAtt(myattswaitin.length);
    } else {
      let myattswaitin = attendances.filter(
        (att) => att.waiting && !att.atendente
      );
      setMyWaitingAtt(myattswaitin.length);
    }
  }
  useEffect(() => {
    getMyWaitingAtt();
  }, [attendances, prefContext, context]);

  useEffect(() => {
    if (reinit) {
      setOff();
      initSockEvents();
      setReinit(false);
    }
  }, [reinit]);

  function initSockEvents() {
    console.log("attendances socket init");
    userSocket.on("new contact att", (contact) => newContactAtt(contact));
    userSocket.on("update att", (contact) => updateAtt(contact));
    userSocket.on("delete att", (contact) => deleteAtt(contact));
    userSocket.on("contacts", (contact) => updateAttList(contact));
    userSocket.on("novos contatos", (contacts) => addNewContacts(contacts));

    userSocket.on("bot new message", (data) => receiveMessage(data));
    userSocket.on("bot new contact att", (contact) => newContactAtt(contact));

    userSocket.on("bot notify", () => notifyAtt());
    userSocket.on("read whats", (data) => readAllMessages(data));
  }
  function setOff() {
    userSocket.off("new contact att"); //config
    userSocket.off("update att"); //config
    userSocket.off("delete att"); //config
    userSocket.off("contacts"); //config

    userSocket.off("bot new message");
    userSocket.off("bot new contact att");
    userSocket.off("bot update att");
    userSocket.off("bot delete att");
    userSocket.off("bot notify");
    userSocket.off("read whats");
  }
  async function loadAttsAndMessages() {
    try {
      const resp = await ApiUsers.post("/user/attendancesLastMessages");
      const messages = resp.data || [];
      // const messagesWithError = [];
      //   const keys = ["textMessage", "imageMessage", "audioMessage", "videoMessage", "documentMessage", "contactMessage", "locationMessage"]
      //   let newMessagesWithoutObj = messages.map((msg) => {
      //     if (msg.messagesRoll.length > 0) {
      //       msg.messagesRoll = msg.messagesRoll.map((msgRoll) => {
      //         if(!msgRoll.message && msgRoll.msgTypo === "text"){
      //           msgRoll.message = "Mensagem não encontrada";
      //         }
      //         else if (msgRoll.message && typeof msgRoll.message === "object" && Object.keys(msgRoll.message).some(key => keys.includes(key))) {
      //           msgRoll.message = 'Mensagem não encontrada'
      //           messagesWithError.push({ _id: msgRoll._id, between: msg.between});
      //         }
      //         return msgRoll;
      //       });
      //     }
      //     return msg;
      //   });
      //   if(messagesWithError.length > 0){
      //     ApiUsers.post('/messages/updateMessage', {messages: messagesWithError});
      //  }
      //   console.log("messagesWithError", messagesWithError);
      const newAttendances = updateAttendances(messages);
      setAttendances(newAttendances);
    } catch (error) {
      console.error("Error loading attendances and messages:", error);
    }
    leftAttsMessage();
  }

  async function leftAttsMessage() {
    try {
      const resp = await ApiUsers.post("/user/attendancesLastMessages", {
        leftUsers: true,
      });
      const messages = resp.data || [];
      // const keys = ["textMessage", "imageMessage", "audioMessage", "videoMessage", "documentMessage", "contactMessage", "locationMessage"]
      // let newMessagesWithoutObj = messages.map((msg) => {
      //   if (msg.messagesRoll.length > 0) {
      //     msg.messagesRoll = msg.messagesRoll.map((msgRoll) => {
      //       if(!msgRoll.message && msgRoll.msgTypo === "text"){
      //         msgRoll.message = "Mensagem não encontrada";
      //       }
      //       else if(msgRoll.message && typeof msgRoll.message === "object" && Object.keys(msgRoll.message).some(key => keys.includes(key))) {
      //         msgRoll.message = 'Mensagem não encontrada'
      //       }
      //       return msgRoll;
      //     });
      //   }
      //   return msg;
      // });
      const newAttendances = updateAttendances(messages);
      setAttendances(newAttendances);
    } catch (error) {
      console.error("Error loading left attendances messages:", error);
    }
    setLoadingAttsMessages(false);
  }

  function compareMessages(messageA, messageB) {
    if (!messageA?.createdAt) return 1;
    if (!messageB?.createdAt) return -1;

    const dateA = new Date(messageA.createdAt);
    const dateB = new Date(messageB.createdAt);

    return dateB - dateA;
  }

  function updateAttendances(messages) {
    return attendances
      .map((att) => {
        const docMessages = messages.find((msg) => {
          return att.telefone === msg.between;
        });
        if (docMessages) {
          const lastMessage =
            docMessages.messagesRoll[docMessages.messagesRoll.length - 1];
          att.lastMessage = {
            message: lastMessage.message,
            createdAt: lastMessage.createdAt,
          };
          att.allMessages = docMessages.messagesRoll;
        }
        //  else {
        //   console.log("aqui");
        //   console.log("attedances", attendances);
        //   console.log(messages);
        //   att.lastMessage = "";
        //   att.allMessages = [];
        // }
        att.unseen = countUnreadMessages(att.allMessages);
        return att;
      })
      .sort((a, b) => compareMessages(a.lastMessage, b.lastMessage));
  }

  const notifyAtt = useCallback(() => {
    let notify = document.getElementById("new-att-sound");
    notify.play();
  }, []);

  // ALTERAR DELETE DO USECALBACK PARA DE USEMEMO
  const deleteAtt = useCallback(
    (contact) => {
      setAttendances((prev) =>
        prev.filter((att) => att.telefone !== contact.telefone)
      );
    },
    [attendances]
  );

  const receiveMessage = useCallback(
    (data) => {
      setAttendances((prev) => {
        let newAttendances = [...prev];
        let newContact = newAttendances.find(
          (att) => att.telefone === data.contact.telefone
        );
        if (newContact) {
          newContact.lastMessage = {
            message: data.msg.message,
            createdAt: data.msg.createdAt,
          };
          if (!newContact.allMessages) newContact.allMessages = [data.msg];
          else {
            newContact.allMessages.push(data.msg);
          }
          newContact.unseen = countUnreadMessages(newContact.allMessages);
        } else {
          data.contact.lastMessage = {
            message: data.msg.message,
            createdAt: data.msg.createdAt,
          };
          data.contact.allMessages = [data.msg];
          newAttendances.push(data.contact);
        }
        newAttendances.sort((a, b) =>
          compareMessages(a.lastMessage, b.lastMessage)
        );
        return newAttendances;
      });
      if (
        data.contact &&
        data.contact.atendente == context.user._id &&
        data.msg.from !== context.user._id
      ) {
        let notify = document.getElementById("noti-sound");
        notify.play();
      }
    },
    [attendances, context]
  );

  const newContactAtt = useCallback((contact) => {
    setAttendances((prevState) => {
      let newAttendances = [...prevState];
      if (newAttendances.some((att) => att.telefone === contact.telefone)) {
        newAttendances.forEach((att) => {
          if (att.telefone === contact.telefone) {
            att.potencial = true;
          }
        });
      } else {
        contact.allMessages = [];
        contact.lastMessage = "";
        contact.historico = contact.historico || [];
        newAttendances.push(contact);
      }
      return newAttendances;
    });
  }, []);

  const addNewContacts = useCallback(
    (contacts) => {
      for (let contact of contacts) {
        newContactAtt(contact);
      }
    },
    [attendances]
  );

  const readAllMessages = useCallback(
    (data) => {
      const { contact, userId } = data;
      setAttendances((prev) => {
        let newAttendances = [...prev];
        let newContact = newAttendances.find((att) => att.telefone === contact);
        if (newContact) {
          newContact.allMessages = newContact.allMessages.map((msg) => {
            if (!msg.seenBy?.includes(userId)) {
              msg.seenBy.push(userId);
            }
            return msg;
          });
          newContact.unseen = countUnreadMessages(newContact.allMessages);
        }
        return newAttendances;
      });
    },
    [attendances, prefContext, context]
  );

  const updateAtt = useCallback(
    (contact) => {
      let newAttendances = [...attendances];
      let mygroups = prefContext.atendentes.filter((grp) =>
        grp.usuarios.includes(context.user._id)
      );
      let oldWaiting = attendances.filter(
        (att) => mygroups.some((grp) => grp._id === att.grupo) && att.waiting
      );

      newAttendances.forEach((att) => {
        if (att.telefone === contact.telefone) {
          att = Object.assign(att, contact);
        }
      });

      setAttendances(newAttendances);
      let newWaiting = newAttendances.filter(
        (att) => mygroups.some((grp) => grp._id === att.grupo) && att.waiting
      );
      if (newWaiting.length > oldWaiting.length) {
        let notify = document.getElementById("new-att-sound");
        notify.play();
      }
    },
    [attendances, prefContext, context]
  );

  //  atualiza o estado da lista de contatos, após um contato ser bloqueado ou desbloqueado
  const updateAttBlock = useCallback(
    (attId) => {
      const att = [...attendances];

      const newAttList = att.map((attendance) => {
        if (attendance._id === attId) {
          const blocked = !attendance?.bloqueado;
          return {
            ...attendance,
            bloqueado: blocked,
          };
        }
        return attendance;
      });

      setAttendances(newAttList);
    },
    [attendances]
  );

  // função de atualizar  lista de contatos
  const updateAttList = useCallback(
    (contact) => {
      newContactAtt(contact);
    },
    [attendances]
  );

  return (
    <AttendanceContext.Provider
      value={{
        attendances,
        socket: userSocket,
        myHistoric,
        waitingAttendances,
        myWaitingAtt,
        loadingAttsMessages,
        unreadMessages,
        updateAttBlock,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
}

export { AttendanceContext, AttendanceProvider };

// useEffect(() => {
// let historyAtt = [];
//     for (let attIndex in attendances) {
//         let att = attendances[attIndex];

//         if(Array.isArray(att.historico)) {

//             for (let indexHist in att.historico) {
//                 let hist = JSON.parse(JSON.stringify(att.historico[indexHist]))
//                 if(!hist ){
//                     // console.log("hist.user", hist,att);
//                     return
//                 }
//                 if((hist.user && context.user) &&   hist.user === context.user._id){
//                     hist.contact = JSON.parse(JSON.stringify(att))
//                     hist.contact.dontRender = true
//                     hist.contact.allMessages = hist.contact?.allMessages?.filter((objMessage, index) => {
//                         let dataHistBefore = att.historico[indexHist-1]?.data;
//                         return objMessage.createdAt > dataHistBefore && objMessage.createdAt < hist.data
//                     })
//                     historyAtt.push(hist);
//                 }
//             }
//         }
//     }
//     setMyHistoric(historyAtt.reverse());
// }, []);

// function loadAttsAndMessages() {
//   ApiUsers.post("/user/attendancesLastMessages").then((resp) => {
//     let messages = resp.data || [];
//     let newAttendances = [...attendances];
//     if (resp.data.length === 0) {
//       newAttendances = attendances.map((att) => {
//         att.lastMessage = "";
//         att.allMessages = "";
//         return att;
//       });
//     } else {
//       newAttendances = [...attendances].map((att) => {
//         let docMessages = messages.find(
//           (msg) => att.telefone === msg.between
//         );
//         if (docMessages) {
//           let lastMessage =
//             docMessages.messagesRoll[docMessages.messagesRoll.length - 1];
//           att.lastMessage = {
//             message: lastMessage.message,
//             createdAt: lastMessage.createdAt,
//           };
//           att.allMessages = docMessages.messagesRoll;
//         } else {
//           att.lastMessage = "";
//           att.allMessages = [];
//         }
//         att.unseen = countUnreadMessages(att.allMessages);
//         return att;
//       });
//     }
//     newAttendances.sort((a, b) => {
//       if (!a.lastMessage) {
//         return 1;
//       }
//       if (!b.lastMessage) {
//         return -1;
//       }
//       let dateA = Date.parse(a.lastMessage.createdAt);
//       let dateB = Date.parse(b.lastMessage.createdAt);
//       if (dateA > dateB) {
//         return -1;
//       }
//       if (dateA < dateB) {
//         return 1;
//       }
//       return 0;
//     });
//     setAttendances(newAttendances);
//   });
//   leftAttsMessage();
// }

// async function leftAttsMessage() {
//   await ApiUsers.post("/user/attendancesLastMessages", {
//     leftUsers: true,
//   }).then((resp) => {
//     let messages = resp.data || [];
//     let newAttendances = [...attendances];
//     if (resp.data.length === 0) {
//       newAttendances = attendances.map((att) => {
//         att.lastMessage = "";
//         att.allMessages = "";
//         return att;
//       });
//     } else {
//       newAttendances = attendances.map((att) => {
//         let docMessages = messages.find(
//           (msg) => att.telefone === msg.between
//         );
//         if (docMessages) {
//           let lastMessage =
//             docMessages.messagesRoll[docMessages.messagesRoll.length - 1];
//           att.lastMessage = {
//             message: lastMessage.message,
//             createdAt: lastMessage.createdAt,
//           };
//           att.allMessages = docMessages.messagesRoll;
//         } else {
//           att.lastMessage = "";
//           att.allMessages = [];
//         }
//         return att;
//       });
//     }
//     newAttendances.sort((a, b) => {
//       if (!a.lastMessage) {
//         return 1;
//       }
//       if (!b.lastMessage) {
//         return -1;
//       }
//       let dateA = Date.parse(a.lastMessage.createdAt);
//       let dateB = Date.parse(b.lastMessage.createdAt);
//       if (dateA > dateB) {
//         return -1;
//       }
//       if (dateA < dateB) {
//         return 1;
//       }
//       return 0;
//     });
//     setAttendances(newAttendances);
//   });
//   setLoadingAttsMessages(false);
// }

// const receiveMessage = useCallback(
//   (data) => {
// let objMessage = data.msg;
// let newContatct = true;
// let newAttendances = [...attendances].map((att) => {
//   if (att.telefone === data.contact.telefone) {
//     att.lastMessage = {
//       message: objMessage.message,
//       createdAt: objMessage.createdAt,
//     };
//     if (!att.allMessages) att.allMessages = [objMessage];
//     else {
//       att.allMessages.push(objMessage);
//     }
//     newContatct = false;
//   }
//   att.unseen = countUnreadMessages(att.allMessages);
//   return att;
// });

// if (newContatct) {
//   data.contact.lastMessage = {
//     message: objMessage.message,
//     createdAt: objMessage.createdAt,
//   };
//   data.contact.allMessages = [objMessage];
//   newAttendances.push(data.contact);
// }
// newAttendances.sort((a, b) => {
//   if (!a.lastMessage) {
//     return 1;
//   }
//   if (!b.lastMessage) {
//     return -1;
//   }
//   let dateA = Date.parse(a.lastMessage.createdAt);
//   let dateB = Date.parse(b.lastMessage.createdAt);
//   if (dateA > dateB) {
//     return -1;
//   }
//   if (dateA < dateB) {
//     return 1;
//   }
//   return 0;
// });
// setAttendances(newAttendances);
// if (
//   data.contact &&
//   data.contact.atendente == context.user._id &&
//   data.msg.from !== context.user._id
// ) {
//   let notify = document.getElementById("noti-sound");
//   notify.play();
// }
// },[attendances, context]);

// const readAllMessages = useCallback(
//   (data) => {
//     const { contact, userId } = data;
//     let newAttendances = [...attendances].map((att) => {
//       if (att.telefone === contact) {
//         att.unseen = [];
//         att.allMessages = att.allMessages.map((msg) => {
//           if (!msg.seenBy?.includes(context.user._id)) {
//             msg.seenBy.push(context.user._id);
//           }
//           return msg;
//         });
//       }
//       return att;
//     });
//     newAttendances.sort((a, b) => {
//       if (!a.lastMessage) {
//         return 1;
//       }
//       if (!b.lastMessage) {
//         return -1;
//       }
//       let dateA = Date.parse(a.lastMessage.createdAt);
//       let dateB = Date.parse(b.lastMessage.createdAt);
//       if (dateA > dateB) {
//         return -1;
//       }
//       if (dateA < dateB) {
//         return 1;
//       }
//       return 0;
//     });
//     setAttendances(newAttendances);
//   },[attendances, prefContext, context]);
