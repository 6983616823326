import React from "react";
import { BsGearWideConnected } from "react-icons/bs";

const ListMembers = ({ membersList }) => {
  return (
    <div className="contact-info-list-users-contander" style={{ marginTop: '10px' }}>
      <div className="contact-info-list-users-header">
        <span>Membros</span>
      </div>

      <div className="contact-info-list-users-body">
        {membersList?.map((user, index) => {
          return (
            <div key={index} className="contact-info-list-users-card">
              <div className="contact-info-list-users-avatar">
                <img
                  style={{ width: 45, height: 45 }}
                  className="title-contact-img"
                  src={user.foto ? user.foto : "/imgs/avatar2.png"}
                  alt="Foto Contato"
                />
              </div>

              <div className="contact-info-list-users-info">
                {user.nome.length > 15 ? (
                  <p>{user.nome.substring(0, 15) + "..."} </p>
                ) : (
                  <p>{user.nome || user.telefone}</p>
                )}
              </div>

              {user.admin ? (
                <div className="contact-info-list-iconBox">
                  <BsGearWideConnected
                    color={"#9ac31c"}
                    size={20}
                    title="Você é o administrador do grupo"
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListMembers;
