import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  createRef,
} from "react";
import { UserContext } from "../../../contexts/UserProvider";
import Message from "./Message/index";
import { ReplyForwardingContext } from "../../../contexts/ReplyForwardingProvider";
import { MessageContext } from "../../../contexts/ChatBotProvider/MessageProvider";
const compRef = createRef();
const msgRef = createRef();

const MessageScreen = (props) => {
  const { user } = useContext(UserContext);
  const { replyMessage } = useContext(ReplyForwardingContext);
  const { lookingMessage, setLookingMessage } = useContext(MessageContext);
  const [usuario, setUsuario] = useState();
  const { contact } = props;

  const [msgType, setMsgType] = useState();
  const [showMessages, setShowMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [lastIdMsg, setLastIdMsg] = useState(null);
  const [contactId, setContactId] = useState(null);

  const [copyContact, setCopyContact] = useState();

  useEffect(() => {
    let newuser = JSON.parse(JSON.stringify(user));
    setUsuario(newuser);
    setMsgType(JSON.parse(JSON.stringify(props.msgType)));
  }, []);

  useEffect(() => {
    if (replyMessage) {
      scrollToBottom();
    }
  }, [replyMessage]);

  useEffect(() => {
    if (contact) {
      let newMessages = [];
      if (contact.allMessages && contact.allMessages.length > 0) {
        newMessages = JSON.parse(JSON.stringify(contact.allMessages));
        let unreadIndex = null;
        let messageOnShow = [];
        let newLastId =
          contact.allMessages[contact.allMessages.length - 1]?._id;
        if (!props.showAll) {
          messageOnShow =
            unreadIndex === null
              ? newMessages.reverse().slice(0, 15).reverse()
              : [...newMessages].slice(0, newMessages.length + 10);
        } else messageOnShow = [...newMessages];
        setContactId(JSON.parse(JSON.stringify(contact._id)));
        setShowMessages(messageOnShow);
        setMessages(newMessages);
        setLastIdMsg(newLastId);
        let newContact = JSON.parse(JSON.stringify(contact));
        setCopyContact(newContact);
      }
      // else setMessages(newMessages)
    }
    return () => {
      setContactId(null);
      setLastIdMsg(null);
    };
  }, [contact]);

  useEffect(() => {
    if (showMessages.length > 0 && !props.showAll) {
      scrollToBottom();
    }
  }, [messages]);

  function scrollToBottom() {
    console.log("scrolling to bottom");
    if (compRef.current) {
      setTimeout(() => {
        compRef.current?.scrollIntoView({ behavior: "instant" });
      }, 100);
    }
  }

  function moreMessagesToShow(e) {
    if (messages.length === showMessages.length)
      return console.log("no more messages");
    let scrollTop = e.target.scrollTop;
    let scrollHeight = e.target.scrollHeight;
    if (scrollTop === 0) {
      let newMessageList = [...messages]
        .slice(0, parseInt(showMessages.length + 10))
        .reverse();
      setShowMessages(newMessageList);
      //setScroll back to postion only
      setScrollBack(e, scrollHeight);
    }
  }

  function setScrollBack(e, scrollHeight) {
    setTimeout(() => {
      let newScrollTop = e.target.scrollHeight - scrollHeight;
      e.target.scrollTop = newScrollTop;
    }, 1);
  }

  function scrollToLookingMessage() {
    console.log("scrolling to looking message", msgRef.current);

    if (msgRef.current) {
      setTimeout(() => {
        msgRef.current?.scrollIntoView({ behavior: "instant" });
        setTimeout(() => {
          setLookingMessage(null);
        }, 3000);
      }, 100);
    }
  }

  useEffect(() => {
    if (lookingMessage) {
      console.log("looking message", lookingMessage);
      showLookingMessage();
    }
  }, [lookingMessage]);

  useEffect(() => {
    if (lookingMessage) {
      scrollToLookingMessage();
    }
  }, [showMessages]);

  function showLookingMessage() {
    let index = [...messages].findIndex(
      (msg) => msg._id === lookingMessage._id
    );
    let newMessageList = [...messages].slice(0, index + 1).reverse();
    setShowMessages(newMessageList);
  }
  return (
    <div
      className="right-screen"
      onScroll={(e) => moreMessagesToShow(e)}
    >
      {showMessages.map((data, i) => {
        let father = "father-received";
        if (usuario._id === data.from) father = "father-sent";
        if (msgType === "att") {
          if (data.from === copyContact?.telefone) {
            father = "father-received";
          } else {
            father = "father-sent";
          }
        }
        if(msgType === 'whats-group'){
          if(data.from === usuario._id){
            father = "father-sent";
          } else {
            father = "father-received";
          }
        }
        let isLooking = lookingMessage?._id === data._id;
        return (
          <div
            key={i}
            className="message-container"
            ref={isLooking ? msgRef : null}
          >
            <div key={i * Math.random()} className={father}>
              <Message
                chatType={msgType}
                contact={copyContact}
                userId={usuario._id}
                messageTypo={father.split("-")[1]}
                data={data}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-start",
                }}
                isLooking={isLooking}
              />
            </div>
          </div>
        );
      })}
      <div className="bottom-message-ref" ref={compRef} />
    </div>
  );
};

export default MessageScreen;
