import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./UserProvider";
import ApiUsers from "../ApiUsers";
import ApiCidadao from "../ApiCidadao";
import Alert from "../components/Configurações/OptionsScreen/inputs/Alerts/Alert";

export const CidadaoContext = createContext();

export const CidadaoProvider = ({ children }) => {
  const [allCidadaos, setAllCidadaos] = useState([]);
  const userContext = useContext(UserContext);
  const prefeitura = userContext.pref._id;
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    success: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    error: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  const getCidadaoById = async (id) => {
    await ApiUsers.post("/agendas/getCidadaoById", { id })
      .then((response) => {
        let newCidadaos = [...allCidadaos];
        for (let i = 0; i < newCidadaos.length; i++) {
          if (newCidadaos[i]._id === response.data.cidadao._id) {
            newCidadaos[i] = response.data.cidadao;
          }
        }
        setAllCidadaos(newCidadaos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCidadaos = async () => {
    await ApiUsers.get("/agendas/getAllCidadaos")
      .then((response) => {
        setAllCidadaos(response.data.cidadaos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addCidadao = async (usuario) => {
    usuario.prefeitura = prefeitura;
    await ApiCidadao.post("/", { usuario })
      .then((response) => {
        if (response?.data.msg) {
          setAlert({
            visible: true,
            type: "success",
            props: { title: "Sucesso", placeholder: response.data.msg },
          });
        } else {
          setAlert({
            visible: true,
            type: "error",
            props: {
              title: "Erro!",
              placeholder: response.data.error,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCidadaos();
  }, []);

  return (
    <CidadaoContext.Provider
      value={{
        getAllCidadaos,
        allCidadaos,
        addCidadao,
        alert,
        alertType,
        getCidadaoById,
      }}
    >
      {children}
    </CidadaoContext.Provider>
  );
};
