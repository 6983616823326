import React from "react";
import "./buttonMembros.css";

export default function ButtonMembros({ onClick, membersCount }) {
  return (
    <div className="container-button-adicionar-membros">
      <div className="container-align-button">
        <button className="button-adicionar-membros" onClick={onClick}>
          Adicionar Membros
        </button>
        <p> {`${membersCount} contatos anexados`}</p>
      </div>
    </div>
  );
}
