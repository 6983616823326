import React from "react";

export default function Contact(props) {
  const { data, message, justi, contactColor, contact } = props;

  return (
    <div
      key={btoa(data?._id)}
      style={{
        display: "flex",
        justifyContent: justi,
        width: "100%",
        wordBreak: "break-word",
        margin: "2px 0 0 8px",
        flexDirection: "column",
      }}
    >
      <p>
        <b>Contato:</b>
        {message}
      </p>
      <p>
        <b>Telefone:</b>
        {data?.contact}
      </p>
    </div>
  );
}
