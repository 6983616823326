import React from "react";
import ButtonsCrud from "../../inputs/ButtonsCrud";
import { IoPersonAdd } from "react-icons/io5";
import { Form } from "react-bootstrap";
import { MdModeEdit } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { ImBlocked } from "react-icons/im";
import Empty from "../../inputs/EmptyLength";
import "../Departament.css";

export default function DepartmentList(props) {
  const { departamentos } = props;

  return (
    <>

      <Form.Control
        placeholder="Digite aqui para pesquisar um departamento"
        onChange={(e) => {
          props.search(e);
        }}
        style={{ width: "80%", margin: "0 auto" }}
      />

      <br />

      <div>
        {departamentos.length > 0 ? (
          <>
            {departamentos.map((dept, index) => {
              let userCol = {
                Nome: dept.nome,
                Setores: dept.setores.length,
                Usuarios: dept.usuarios.length,
              };

              return (
                <div key={index} className="dept-card">
                  <div className="dept-infos">
                    {Object.entries(userCol).map(([k, v]) => {
                      return (
                        <span key={k}>
                          <b>{k}: </b>
                          {v}
                        </span>
                      );
                    })}
                  </div>

                  <div className="dept-buttons">
                    <ButtonsCrud
                      buttons={[
                        {
                          action: "listarUsuario",
                          title: "Listar Usuários",
                          class: "dept-list-users-button",
                          icon: <HiUsers className="button-icon" />,
                        },
                        {
                          action: "adicionarUsuario",
                          title: "Adicionar Usuário",
                          class: "dept-add-user-button",
                          icon: <IoPersonAdd className="button-icon" />,
                        },
                        {
                          action: "editar",
                          title: "Editar Departamento",
                          class: "dept-edit-button",
                          icon: <MdModeEdit className="button-icon" />,
                        },
                        {
                          action: "excluir",
                          title: "Excluir Departamento",
                          class: "dept-del-button",
                          icon: <ImBlocked className="button-icon" />,
                        },
                      ]}
                      handle={{
                        listarUsuario: (e) => props.listUsers(dept),
                        editar: (e) => props.edit(dept),
                        excluir: (e) => props.delConfirm(dept),
                        adicionarUsuario: (e) => props.addUsers(dept),
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <Empty data="departamentos" />
          </>
        )}
      </div>
    </>
  );
}
