export const diasSemana = [
  { index: 0, nome: "Domingo" },
  { index: 1, nome: "Segunda" },
  { index: 2, nome: "Terça" },
  { index: 3, nome: "Quarta" },
  { index: 4, nome: "Quinta" },
  { index: 5, nome: "Sexta" },
  { index: 6, nome: "Sábado" },
];

export const nomesDosMeses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export function formatToBrDate(date) {
  const dateSplited = date.split('-');
  return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
}
export function withZero(num) {
  return num < 10 ? `0${num}` : num;
}

export function getHMtoMs(hm) {
  let [h, m] = hm.split(":");
  return h * 60 * 60 * 1000 + m * 60 * 1000;
};

export function translateDiaSemana(dataString) {
  dataString = new Date(dataString);
  const diaSemana = diasSemana[dataString?.getDay()]?.nome;
  return diaSemana;
};

export function translateDate(dataString) {
  dataString = new Date(dataString);
  const hora = dataString?.getHours();
  const minutos = dataString?.getMinutes();
  const horaFormatada = hora < 10 ? "0" + hora : hora;
  const minutosFormatados = minutos < 10 ? "0" + minutos : minutos;
  const novaData = `${horaFormatada}:${minutosFormatados}`;
  return novaData;
};

export function getDate(item) {
  if (typeof item === "object") {
    return item.data;
  }
  return item;
};

export function compareDates(a, b) {
  const dateA = new Date(getDate(a));
  const dateB = new Date(getDate(b));
  return dateA - dateB;
};