import React, { useState, useEffect, useContext } from "react";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import CardEspera from "../components/CardEspera/card";
import { generatePDFsingleTE, generatePDFtableTE } from "../components/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Index(props) {
  const { attendances } = useContext(AttendanceContext);
  const { atendentes } = useContext(PrefContext);
  const { pref, contacts } = useContext(UserContext);
  const [gruposDeAtt, setGruposDeAtt] = useState([]);
  const [date, setDate] = useState({ ...props.date });
  const [checkedIds, setCheckedIds] = useState([]);

  const checkSelectId = (id) => {
    const isChecked = checkedIds.includes(id);
    let newIds = [...checkedIds];
    if (isChecked) newIds = newIds.filter((checkedId) => checkedId !== id);
    else newIds = [...checkedIds, id];
    setCheckedIds(newIds);
  };

  const dateToYYMMDD = (date) => {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const treatWithZero = (number) => {
      if (number < 10) {
        return `0${number}`;
      }
      return number;
    };
    let dateFormated = `${year}-${treatWithZero(month)}-${treatWithZero(day)}`;
    return dateFormated;
  };

  const isBiggerDate = (data1, data2) => {
    var d1 = new Date(data1);
    var d2 = new Date(data2);

    if (d1 > d2) {
      return true;
    } else if (d1 < d2) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDate({ ...props.date });
  }, [props.date]);

  const newObjDate = (data) => {
    if (!data) return false;
    var partes = data.split("-");
    return new Date(partes[0], partes[1] - 1, partes[2]);
  };

  useEffect(() => {
    let myGrpAtts = [...atendentes];
    if (date && date.start && date.end) {
      let newAtts = [...attendances];
      let data = [];
      newAtts.forEach((att, attI) => {
        att.historico.forEach((hist, hI) => {
          if (
            hist.action === "Transferido para espera" &&
            hist.data &&
            isBiggerDate(hist.data, newObjDate(date.start))
          ) {
            let nextHist = att.historico[hI + 1];

            if (
              nextHist &&
              (nextHist.action === "Iniciado pelo Atendente" ||
                nextHist.action === "Atendido") &&
              nextHist.data &&
              isBiggerDate(newObjDate(date.end), dateToYYMMDD(nextHist.data))
            ) {
              // console.log(Math.abs(new Date(hist.data) - new Date(nextHist.data)))
              // console.log(hist)
              let time =
                Math.abs(new Date(hist.data) - new Date(nextHist.data)) / 1000;
              let grp = hist.group ? hist.group : hist.grupo ? hist.grupo : "";
              data.push({ time, grp, hist, nextHist });
            }
          }
        });
      });
      myGrpAtts.forEach((att, attI) => {
        let grpData = data.filter((d) => {
          return d.grp == att._id;
        });
        att.espera = calculateAverageAwait(grpData);
        att.data = grpData;
      });
    }

    setGruposDeAtt(myGrpAtts);
    // console.log('myData', myGrpAtts)
  }, [date]);

  const calculateAverageAwait = (data) => {
    let sum = 0;
    data.forEach((d) => {
      // console.log(d.time)
      sum += parseInt(d.time);
    });
    let average = sum / data.length;
    // console.log(average, sum, data.length)
    return isNaN(average) ? "00:00:00:00" : transforToDDHHMMSS(average);
  };

  const transforToDDHHMMSS = (seconds) => {
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    let mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    seconds = Math.floor(seconds);

    return `${days ? days + "D" : ""} ${hrs ? hrs + "h" : ""} ${
      mnts ? mnts + "m" : ""
    } ${seconds ? seconds : ""}s`;
  };

  return (
    <div>
      <h5 className="text-h5">
        Tempo de espera até o contato com um atendente, sempre que cai na fila
        de espera.
      </h5>
      <div className="painel-relatorio-espera">
        {gruposDeAtt.length > 0 && gruposDeAtt?.map((grp, grpI) => {
          return (
            <CardEspera
              key={grp._id}
              grp={grp}
              grpI={grpI}
              date={date}
              handCheckSelectId={checkSelectId}
              generatePdf={() =>
                generatePDFsingleTE(grp._id, gruposDeAtt, pref, date, contacts)
              }
            />
          );
        })}
      </div>
      {checkedIds.length > 0 && (
        <button
          className="button-download-gerar-resumo"
          onClick={() =>
            generatePDFtableTE(gruposDeAtt, checkedIds, pref, date, contacts)
          }
        >
          GERAR RESUMO
        </button>
      )}
    </div>
  );
}
