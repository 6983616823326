import { useState, useEffect } from "react";

export const PeriodoAtendimento = (props) => {
  const [periodoAtendimento, setPeriodoAtendimento] = useState({
    inicio: "",
    fim: "",
  });
  const [profissional, setProfissional] = useState({});

  useEffect(() => {
    const novoProfissional = { ...props.profissional };
    setProfissional(novoProfissional);
    const novoPeriodoAtendimento = { ...novoProfissional.periodoAtendimento };
    if (novoPeriodoAtendimento) {
      setPeriodoAtendimento(novoPeriodoAtendimento);
    }
  }, [props.profissional]);

  const onChangeHorario = (e, periodo) => {
    const novoProfissional = { ...profissional };
    const novoPeriodoAtendimento = { ...periodoAtendimento };
    if (periodo === "inicio") novoPeriodoAtendimento.inicio = e.target.value;
    if (periodo === "fim") novoPeriodoAtendimento.fim = e.target.value;
    novoProfissional.periodoAtendimento = novoPeriodoAtendimento;
    props.setProfissional(novoProfissional);
  };

  return (
    <>
      <legend>Período de atendimento</legend>
      <div className="input-container">
        <label htmlFor="data-inicio">Início:</label>
        <input
          type="date"
          id="data-inicio"
          value={periodoAtendimento.inicio}
          min={new Date().toISOString().substring(0, 10)}
          onChange={(e) => onChangeHorario(e, "inicio")}
        />

        <label htmlFor="data-fim">Fim:</label>
        <input
          type="date"
          id="data-fim"
          value={periodoAtendimento.fim}
          min={periodoAtendimento.inicio && new Date().toISOString().substring(0, 10)}
          onChange={(e) => onChangeHorario(e, "fim")}
        />
      </div>
    </>
  );
};
