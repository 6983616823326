import { useEffect, useState, useContext } from "react";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { ProfissionalCard } from "./ProfissionalCard";
import { PesquisaProfissionais } from "../Pesquisa/PesquisaProfissionais";
import "./style.css";

export const Profissional = () => {
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);

  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [profissionaisFiltrados, setProfissionaisFiltrados] = useState([]);

  useEffect(() => {
    const novosProfissionais = [...professionalContext.profissionais];
    setProfissionais(novosProfissionais);

    const novosServicos = [...serviceContext.servicos];
    setServicos(novosServicos);

    const novasEspecialidades = [...specialtyContext.especialidades];
    setEspecialidades(novasEspecialidades);

    const novosLocais = [...locationContext.locais];
    setLocais(novosLocais);
  }, [
    locationContext.locais,
    professionalContext.profissionais,
    serviceContext.servicos,
    specialtyContext.especialidades,
  ]);

  const profissionaisFiltradosUnicos = profissionaisFiltrados.reduce(
    (acc, profissional) => {
      const profissionalJaExiste = acc.find(
        (profissionalUnico) => profissionalUnico._id === profissional._id
      );
      if (!profissionalJaExiste) {
        return [...acc, profissional];
      }
      return acc;
    },
    []
  );

  return (
    <div className="profissional-container">
      <div className="agenda-header-container">
        <div className="profissional-title">PROFISSIONAIS</div>
      </div>
      <PesquisaProfissionais
        profissionais={profissionais}
        especialidades={especialidades}
        servicos={servicos}
        locais={locais}
        setProfissionaisFiltrados={setProfissionaisFiltrados}
      />
      {profissionaisFiltradosUnicos.length > 0 ? (
        <div className="profissional-content">
          <div className="card-container">
            {profissionaisFiltradosUnicos.map((profissional, index) => (
              <ProfissionalCard key={index} profissional={profissional} />
            ))}
          </div>
        </div>
      ) : (
        <div className="profissional-content">
          <div className="card-container">
            <div className="profissionais-vazio">SEM PROFISSIONAIS</div>
          </div>
        </div>
      )}
    </div>
  );
};
