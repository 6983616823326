import { useEffect, useState, useContext } from 'react'
import { BsAlarmFill, BsX } from "react-icons/bs";
import { TemporizadorContext } from '../../../../contexts/ChatBotProvider/TemporizadorProvider';
import BodyInfo from './components/bodyInfo';
import File from './components/file';
import Message from './components/message';
import Alert from '../../../Configurações/OptionsScreen/inputs/Alerts/Alert'
import MiniLoading from '../../../Configurações/OptionsScreen/inputs/MiniLoading';
import './temporizador.css'

const Temporizador = (props) => {
    const { setShowTemporizador, contact, type, editData, setEditData } = props
    const { createTemporizador, alert, loading, setAlert,updateTemporizador } = useContext(TemporizadorContext)
    const [contato, setContato] = useState({})
    const [tipo, setTipo] = useState('')
    const [data, setData] = useState({ data: '', hora: '', message: '', files: '', audio: '', bot:'' })
    const [view, setView] = useState('Message')

    useEffect(() => {
        if (!contact) return
        let newContact = JSON.parse(JSON.stringify(contact))
        setContato(newContact)
    }, [contact])


    useEffect(() => {
        const typeMap = { 'private': 'Privado', 'group': 'Grupo', 'att': 'Atendimento', 'whats-group': 'Atendimento em Grupo' };
        if (typeMap.hasOwnProperty(type)) {
            setTipo(typeMap[type]);
        }
    }, [type]);


    const closeTempModal = () => {
        setData({})
        if (editData) setEditData({})
        setShowTemporizador(false)
    }

    const Views = {
        Message: <Message data={data} setData={setData} editData={editData} setEditData={setEditData} />,
        File: <File data={data} setData={setData} editData={editData} setEditData={setEditData} />,
    }

    const handleCreateButton = () => {
        createTemporizador(data, contato, type)
        closeTempModal()
    }

    const handleUpdateButton = () => {
        updateTemporizador(editData)
        closeTempModal()
    }


    return (
        <div className='temporizador-modal'>
            <div className='temporizador-container'>
                {alert?.visible ? <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => setAlert({ visible: false, type: "", props: {} })} /> : null}
                <header className="temporizador-header">
                    <div className="temporizador-header-info">
                        <BsAlarmFill color={'#9ac31c'} />
                        <h2>{editData?._id ? 'Editar mensagem agendada':'Agendar mensagem'}</h2>
                    </div>
                    <BsX color={'#9ac31c'} size={30} onClick={closeTempModal} style={{ marginRight: 10 }} />
                </header>

                <div className="temporizador-body">
                    <BodyInfo contato={contato} data={data} setData={setData} tipo={tipo} editData={editData} setEditData={setEditData} />

                    <div className="temporizador-body-view-options">
                        <button onClick={() => setView('Message')} style={view === 'Message' ? { borderBottom: '1px solid #9ac31c' } : null}>Mensagem</button>
                        <button onClick={() => setView('File')} style={view === 'File' ? { borderBottom: '1px solid #9ac31c' } : null}>Arquivo</button>
                    </div>
                    <div className="temporizador-body-view-content">
                        {Views[view]}
                    </div>

                    <footer className="temporizador-footer">
                        <button onClick={closeTempModal}>Cancelar</button>
                        {editData ? (
                            <button onClick={handleUpdateButton} disabled={loading}>
                                {loading ? <MiniLoading /> : 'Salvar'}
                            </button>
                        ) : (
                            <button onClick={handleCreateButton} disabled={loading}>
                                {loading ? <MiniLoading /> : 'Agendar'}
                            </button>
                        
                        )}
                    </footer>
                </div>
            </div>
        </div >
    )
}

export default Temporizador
