import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../../../../contexts/UserProvider";

export const InicioRapido = () => {
  const { user, pref } = useContext(UserContext);
  const isAdmin = user.admin;
  const isAgenda = pref.services?.agenda?.ativo;
  const isChat = pref.services?.voxchat.ativo;
  const isBot = pref.services?.voxbot.ativo;
  const navigate = useNavigate();
  const [onShow, setOnShow] = useState("Agenda");

  const components = {
    ...(isAdmin &&
      isAgenda && {
      Agenda: (
        <div>
          <h1>Início Rápido Agenda</h1>
          <p>
            Clique{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#142a4c",
                fontWeight: "bold",
              }}
              onClick={() => navigate("/qsg/agenda")}
            >
              aqui
            </span>{" "}
            e crie uma agenda de maneira rápida e simples.
          </p>
        </div>
      ),
    }),
    ...(isAdmin &&
      isChat && {
      Chat: (
        <div>
          <h1>Início Rápido Chat</h1>
          <p>
            Clique{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#142a4c",
                fontWeight: "bold",
              }}
              onClick={() => { }}
            >
              aqui{" "}
            </span>
            e configure o chat de maneira rápida e simples.
          </p>
        </div>
      ),
    }),
    ...(isAdmin &&
      isBot && {
      Bot: (
        <div>
          <h1>Início Rápido Bot</h1>
          <p>
            Clique{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#142a4c",
                fontWeight: "bold",
              }}
              onClick={() => { }}
            >
              aqui{" "}
            </span>
            e configure o bot de maneira rápida e simples.
          </p>
        </div>
      ),
    }),
  };

  return (
    <div className="config-list-header">
      {Object.keys(components).map((key, index) => {
        return (
          <button
            key={index}
            className={"button-view"}
            style={
              onShow === key
                ? {
                  color: "#9ac31c",
                  fontWeight: "bold",
                }
                : null
            }
            onClick={() => setOnShow(key)}
          >
            {key}
          </button>
        );
      })}
      {onShow ? components[onShow] : ""}
    </div>
  );
};
