import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from 'date-fns/locale';
import './calendario.css';

const Calendario = ({ searchDate, setSearchDate, horizontal }) => {
  const [startDate, setStartDate] = useState(searchDate.inicio);
  const [endDate, setEndDate] = useState(searchDate.fim);

  useEffect(() => {
    setStartDate(searchDate.inicio);
    setEndDate(searchDate.fim);
  }, [searchDate]);

  const handleInputChangeStart = (e) => {
    setStartDate(e);
    setSearchDate({ inicio: e, fim: endDate });
  }

  const handleInputChangeEnd = (e) => {
    setEndDate(e);
    setSearchDate({ inicio: startDate, fim: e });
  }

  const styleCalendar = { backgroundColor: 'white', width: '100%', border: 'none', outline: 'none', boxShadow: 'none' }

  if (horizontal) {
    styleCalendar.marginRight = '20px';
    styleCalendar.border = '1px solid #e5e5e5';
    styleCalendar.borderRadius = '3px';
  }
  
  return (
    <div className={"box-calendar" + (horizontal ? " horizontal" : "")}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} >
        <DatePicker
          label="Início"
          format='dd/MM/yyyy'
          value={startDate}
          defaultValue={startDate}
          onChange={handleInputChangeStart}
          slotProps={{
            textField: { style: styleCalendar },
            openPickerButton: {
              style: { color: '#9ac31c' }
            },
          }}
        />

        <DatePicker
          label="Fim"
          format='dd/MM/yyyy'
          value={endDate}
          defaultValue={endDate}
          onChange={handleInputChangeEnd}
          slotProps={{
            textField: { style: styleCalendar },
            openPickerButton: {
              style: { color: '#9ac31c' }
            },
          }}
        />

      </LocalizationProvider>
    </div>
  );
}

export default Calendario;
