import React, { useEffect, useState } from "react";
import "./pagination.css";

function Pagination({ contacts, setContactsOnShow }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const paginate = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const filteredContacts = contacts.slice(
      indexOfFirstContact,
      indexOfLastContact
    );
    setTotalPages(Math.ceil(contacts.length / contactsPerPage));
    setContactsOnShow(filteredContacts);
  }, [currentPage, contacts, contactsPerPage, setContactsOnShow]);

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 8; // Define o número máximo de páginas a serem mostradas

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-list-contact">
      <div
        className="page-item-list-contact"
        onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
      >
        <button
          className="page-item-link-contact"
          style={{
            width: "120px",
            padding: "5px",
            marginRight: "20px",
            borderRadius: "5px",
          }}
        >
          <p>Anterior</p>
        </button>
      </div>
      {generatePageNumbers().map((number) => (
        <div key={number} className="page-item-list-contact">
          <button
            className={`page-item-link-contact ${
              number === currentPage ? "active-page" : ""
            }`}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        </div>
      ))}
      <div
        className="page-item-list-contact"
        onClick={() =>
          paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
        }
      >
        <button
          className="page-item-link-contact"
          style={{
            width: "120px",
            padding: "5px",
            marginLeft: "20px",
            borderRadius: "5px",
          }}
        >
          <p>Próxima</p>
        </button>
      </div>
    </div>
  );
}

export default Pagination;
