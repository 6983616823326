import React from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import "./buttonNewGroup.css";

export default function ButtonNewGroup({ title, onClick }) {
  return (
    <div className="container-button-novo-grupo">
      <button className="add-button-grupo" onClick={onClick}>
        <BsPlusCircleFill size={20} color="white" />
        {title}
      </button>
    </div>
  );
}
