import React, { useState } from "react";
import SectionCreate from "./SectionCreate";
import SectionsShow from "./SectionsShow";

function SectionConfig() {
  const [onShow, setOnShow] = useState("visualizar");
  const components = {
    criar: <SectionCreate />,
    visualizar: <SectionsShow />,
  };

  return (
    <div className="config-list-header">
      <button
        title="Visualizar setores"
        onClick={(e) => setOnShow("visualizar")}
        id="visualize-button-view"
        style={
          onShow === "visualizar"
            ? {
              color: "#9ac31c",
              fontWeight: "bold",
            }
            : null
        }
      >
        Visualizar
      </button>

      <button
        title="Criar novo setor"
        onClick={(e) => {
          setOnShow("criar");
        }}
        id="create-button-view"
        style={
          onShow === "criar"
            ? {
              color: "#9ac31c",
              fontWeight: "bold",
            }
            : null
        }
      >
        Criar
      </button>

      {onShow ? components[onShow] : ""}
    </div>
  );
}

export default SectionConfig;
