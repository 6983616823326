import React from "react";
import "./Suport.css";

export default function AboutShow(props) {
  return (
    <>
      <div className="about-system-container">
        <img src="imgs/VOXLOGOHorizintal.png" alt=" lotipo da empresa" />
        <span style={{ color: "#142a4c", fontFamily: "SourceCodePro-Bold" }}>
          Versão 1.0
        </span>
        <br />
        <span style={{ color: "#142a4c", fontFamily: "SourceCodePro-Bold" }}>
          © 2022 Voxcity Tecnologia <br /> Todos os direitos reservados.
        </span>
      </div>
    </>
  );
}
