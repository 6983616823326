import React, { useState, useEffect, useContext } from "react";
import { FaLink, FaUnlink, FaCopy } from "react-icons/fa";
import { PrefContext } from "../../../../../../contexts/ChatBotProvider/PrefProvider";

const List = (props) => {
  const { links, setLink, setLinkState, editLink, deleteLink } = props;
  const prefContext = useContext(PrefContext);
  const [list, setList] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const linkURL = "https://chat.voxcity.com.br/register/";

  const acharDepartamento = (id) => {
    const departamento = departamentos.find((dep) => dep._id === id);
    return departamento;
  };

  const copiarLink = (link) => {
    navigator.clipboard.writeText(`${linkURL}${link.link}`);
  };

  useEffect(() => {
    const novosDepartamentos = prefContext.departamentos
      ? [...prefContext.departamentos]
      : [];
    setDepartamentos(novosDepartamentos);
  }, [prefContext.departamentos]);

  useEffect(() => {
    setList(links);
  }, [links]);
  return (
    <div className="links-list">
      {links?.length > 0 ? (
        links.map((link, index) => {
          return (
            <div className="links-list-card" key={index}>
              <div className="links-list-link">
                <span>
                  <b>Link: </b>
                 <a href={linkURL + link.link}>{linkURL + link.link}</a>
                </span>
                <span>
                  <b>Limite de Cadastros: </b>
                  {link.limit}
                </span>
                <span>
                  <b>Usuários Cadastrados: </b>
                  {link.users?.length}
                </span>
                <span>
                  <b>Departamento: </b>
                  {acharDepartamento(link.dep)?.nome}
                </span>
                <span>
                  <b>Atendente: </b>
                  {link.atendente ? "Sim" : "Não"}
                </span>
                <span>
                  <b>Profissional: </b>
                  {link.profissional ? "Sim" : "Não"}
                </span>
              </div>
              <div className="links-list-btn-container">
                <button
                  className="btn-copy-link"
                  onClick={() => copiarLink(link)}
                >
                  <FaCopy />
                  <span>Copiar Link</span>
                </button>
                <button
                  className="btn-edit-link"
                  onClick={() => editLink(link)}
                >
                  <FaLink />
                  <span>Editar Link</span>
                </button>
                <button className="btn-delete-link"
                  onClick={() => deleteLink(link)} >
                  <FaUnlink />
                  <span>Apagar Link</span>
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="links-vazio">NENHUM LINK CRIADO</div>
      )}
    </div>
  );
};

export default List;
