import React, { useEffect, useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import InputSearch from "../InputSearch";

export default function TableRigth({
  MyContatosOnShow,
  searchRigth,
  setSearchRigth,
  handleSelectAllRight,
  handleSelectRight,
  selectedFromRight,
}) {
  const [filteredMyContatos, setFilteredMyContatos] = useState([]);

  const selectedAllChecked =
    selectedFromRight.length &&
    MyContatosOnShow.length === selectedFromRight.length;

  function FilteredMyContatos() {
    const newFilteredMyContatos = MyContatosOnShow.filter((contato) => {
      return (
        contato.nome?.toLowerCase().includes(searchRigth.toLowerCase()) ||
        contato.telefone?.toLowerCase().includes(searchRigth.toLowerCase()) ||
        contato.pushname?.toLowerCase().includes(searchRigth.toLowerCase())
      );
    });
    setFilteredMyContatos(newFilteredMyContatos);
  }

  useEffect(() => {
    FilteredMyContatos();
  }, [searchRigth, MyContatosOnShow]);

  const ListItem = ({ contato, onChange, checked }) => {
    return (
      <div className="table-list-item">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          style={{ width: "18px", height: "18px" }}
        />
        <p>
          {contato.nome || contato.pushname || ""} - {contato.telefone}
        </p>
      </div>
    );
  };

  return (
    <div className="table-right">
      <div className="table-right-container-multi-select">
        <input
          type="checkbox"
          checked={selectedAllChecked}
          onChange={handleSelectAllRight}
          style={{
            width: "18px",
            height: "18px",
          }}
        />
        <p>Selecionar todos</p>
      </div>
      <div className="container-list-table">
        <InputSearch onChange={(e) => setSearchRigth(e.target.value)} />
        <div className="table-list">
          {MyContatosOnShow.length === 0 ? (
            <div className="table-information-not-default">
              <BsInfoCircleFill size={25} color="#9AC31C" />
              <p>Lista vazia!</p>
            </div>
          ) : filteredMyContatos.length === 0 ? (
            <div className="table-information-not-default">
              <BsInfoCircleFill size={25} color="#9AC31C" />
              <p>Nada encontrado!</p>
            </div>
          ) : (
            filteredMyContatos.map((contato, i) => {
              return (
                <ListItem
                  key={i}
                  contato={contato}
                  onChange={() => handleSelectRight(contato)}
                  checked={selectedFromRight?.find(
                    (selected) => selected.telefone === contato.telefone
                  )}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
