import { useState, useEffect, useContext } from "react";
import { QSGAgendaContext } from "../../../../../contexts/QSGProvider/QSGAgendaProvider";
import { Selecao } from "../Selecao";
import { Accordion } from "../Accordion";
import { ModalInfo } from "./ModalInfo";
import { localFormInputs } from "./FormInputs/localFormInputs";
import { especFormInputs } from "./FormInputs/especFormInputs";
import { profiFormInputs } from "./FormInputs/profiFormInputs";
import { Form } from "../Form";
import { accordionSections } from "./accordionSections";
import "./styles.css";

export const Locais = () => {
  const { locais, setLocais, especialidades, profissionais, setItensRemovidos, accordionParentSection, accordionSubSection } = useContext(QSGAgendaContext);
  const [multForms, setMultForms] = useState(["local"]);
  const [locaisState, setLocaisState] = useState([]);
  const [localSelecionado, setLocalSelecionado] = useState(null);
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState([]);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState({});
  const [profissionalSelecionado, setProfissionalSelecionado] = useState({});
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState([]);
  const [horarioIntervalo, setHorarioIntervalo] = useState([]);
  const [options, setOptions] = useState({});
  const [editingStates, setEditingStates] = useState({
    local: false,
    especialidade: false,
    profissional: false,
  });
  const [editingSelected, setEditingSelected] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const formType = multForms[multForms.length - 1];

  const diasSemana = [
    { index: 0, nome: "Domingo" },
    { index: 1, nome: "Segunda" },
    { index: 2, nome: "Terça" },
    { index: 3, nome: "Quarta" },
    { index: 4, nome: "Quinta" },
    { index: 5, nome: "Sexta" },
    { index: 6, nome: "Sábado" },
  ];

  const formTypes = {
    local: (
      <Form
        formInputs={localFormInputs}
        options={options}
        data={locaisState}
        setData={setLocais}
        setEditingStates={setEditingStates}
        editingStates={editingStates.local}
        editingSelected={editingSelected}
        setEditingSelected={setEditingSelected}
        selected={localSelecionado}
        setSelected={setLocalSelecionado}
        selectedOptions={especialidadesSelecionadas}
        setSelectedOptions={setEspecialidadesSelecionadas}
        selectedItem={especialidadeSelecionada}
        setSelectedItem={setEspecialidadeSelecionada}
        nextForm={"config.especialidade"}
        currentForm={"local"}
        multForms={multForms}
        setMultForms={setMultForms}
        optionsList={especialidades}
        listKey={"especialidades"}
        nextListKey={"profissionais"}
        setInputList={setHorarioIntervalo}
      />
    ),
    config: {
      especialidade: (
        <Form
          formInputs={especFormInputs}
          options={options}
          data={locaisState}
          setData={setLocais}
          editingSelected={editingSelected}
          setEditingSelected={setEditingSelected}
          editingStates={editingStates.especialidade}
          setEditingStates={setEditingStates}
          selected={especialidadeSelecionada}
          setSelected={setEspecialidadeSelecionada}
          prevSelectedOptions={especialidadesSelecionadas}
          setPrevSelectedOptions={setEspecialidadesSelecionadas}
          selectedOptions={profissionaisSelecionados}
          setSelectedOptions={setProfissionaisSelecionados}
          selectedItem={profissionalSelecionado}
          setSelectedItem={setProfissionalSelecionado}
          prevForm={"local"}
          currentForm={"config.especialidade"}
          nextForm={"config.profissional"}
          checkBoxes={diasSemana}
          setMultForms={setMultForms}
          optionsList={profissionais}
          prevKey={"especialidades"}
          listKey={"profissionais"}
          multForms={multForms}
          setInputList={setHorarioIntervalo}
        />
      ),
      profissional: (
        <Form
          formInputs={profiFormInputs}
          data={locaisState}
          setData={setLocais}
          editingSelected={editingSelected}
          setEditingSelected={setEditingSelected}
          editingStates={editingStates.profissional}
          setEditingStates={setEditingStates}
          selected={profissionalSelecionado}
          setSelected={setProfissionalSelecionado}
          prevSelectedOptions={profissionaisSelecionados}
          setPrevSelectedOptions={setProfissionaisSelecionados}
          currentForm={"config.profissional"}
          prevForm={"config.especialidade"}
          multForms={multForms}
          setMultForms={setMultForms}
          inputList={horarioIntervalo}
          inputListKey={"horarioIntervalo"}
          setInputList={setHorarioIntervalo}
          checkBoxes={diasSemana}
          prevKey={"especialidades"}
          listKey={"profissionais"}
        />
      ),
    },
  };

  const editLocal = (local, form) => {
    setLocalSelecionado(local);
    setEditingSelected(true);
    setEditingStates((prevEditingStates) => ({ ...prevEditingStates, local: true }));
    setMultForms([form]);
  };

  const editEspecialidade = (especialidade, form) => {
    setEspecialidadeSelecionada(especialidade);
    setEditingStates((prevEditingStates) => ({ ...prevEditingStates, especialidade: true }));
    setMultForms([...multForms, form]);
  };

  const editProfissional = (profissional, form) => {
    setProfissionalSelecionado(profissional);
    setEditingStates((prevEditingStates) => ({ ...prevEditingStates, profissional: true }));

    setMultForms([...multForms, form]);
  };

  const deleteLocal = (local) => {
    const novosLocais = [...locais];
    const index = novosLocais.findIndex((item) => item._id === local._id);
    if (index > -1) {
      setItensRemovidos((prevItensRemovidos) => [...prevItensRemovidos, local._id]);
      novosLocais.splice(index, 1);
    }
    setLocais(novosLocais);
  };

  const deleteEspecialidade = (especialidade) => {
    const novosLocais = [...locais];
    const index = novosLocais.findIndex((item) => item._id === accordionParentSection._id);
    if (index !== -1) {
      const indexEspecialidade = novosLocais[index].especialidades.findIndex((item) => item._id === especialidade._id);
      if (indexEspecialidade !== -1) {
        novosLocais[index].especialidades.splice(indexEspecialidade, 1);
      }
    }
    setLocais(novosLocais);
  };

  const deleteProfissional = (profissional) => {
    const novosLocais = [...locais];
    novosLocais.forEach((local) => {
      local.especialidades.forEach((especialidade) => {
        if (especialidade._id === accordionSubSection._id) {
          especialidade.profissionais = especialidade.profissionais.filter((prof) => prof._id !== profissional._id);
        }
      });
    });
    setLocais(novosLocais);
  };

  const openModal = (item, modalType) => {
    setModalType(modalType);
    setModalContent(item);
    setIsModalOpen(true);
  };

  const callback = (item, action, form) => {
    const actions = {
      editar: {
        "local": editLocal,
        "config.especialidade": editEspecialidade,
        "config.profissional": editProfissional,
      },
      informacoes: {
        "config.especialidade": () => openModal(item, "especialidade"),
        "config.profissional": () => openModal(item, "profissional"),
        "local": () => openModal(item, "local"),
      },
      excluir: {
        "config.especialidade": deleteEspecialidade,
        "config.profissional": deleteProfissional,
        "local": deleteLocal,
      },
    };
    const actionMap = actions[action];
    if (actionMap && actionMap[form]) {
      actionMap[form](item, form);
    }
  };

  const getFormType = (formType) => {
    const keys = formType.split(".");
    let form = formTypes;
    keys.forEach((key) => {
      form = form[key];
    });
    return form;
  };

  useEffect(() => {
    const novosLocais = locais ? [...locais] : [];
    setLocaisState(novosLocais);
  }, [locais]);

  useEffect(() => {
    const optionsEspecialidades = especialidades
      .filter((especialidade) => !especialidadesSelecionadas.find((esp) => esp._id === especialidade._id))
      .map((especialidade) => ({
        value: especialidade._id,
        label: especialidade.nome,
      }));

    const optionsLimiteVagas = [
      { value: "0", label: "Sem limite" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
    ];

    const optionsProfissionais = especialidades
      .filter((esp) => esp._id === especialidadeSelecionada?._id)
      .flatMap((esp) => esp.profissionais)
      .filter((profissionalId) => !profissionaisSelecionados.find((prof) => prof._id === profissionalId))
      .map((profissionalId) => {
        const profissional = profissionais.find((prof) => prof._id === profissionalId);
        return {
          value: profissional._id,
          label: profissional.nome,
        };
      });

    setOptions((prevOptions) => ({
      ...prevOptions,
      especialidades: optionsEspecialidades,
      profissionais: optionsProfissionais,
      limiteVagas: optionsLimiteVagas,
    }));
  }, [especialidades, especialidadesSelecionadas, profissionais, profissionaisSelecionados, especialidadeSelecionada?._id, profissionalSelecionado?._id, editingStates]);

  return (
    <div className="qsg-agenda-locais-container">
      {isModalOpen && (
        <ModalInfo
          item={modalContent}
          modalType={modalType}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Selecao headerLabel="Novo local">{getFormType(formType)}</Selecao>
      <Selecao
        headerLabel="Locais"
        content={locaisState}>
        <Accordion
          data={locaisState}
          accordionSections={accordionSections}
          firstSectionKey={"locais"}
          callback={callback}
        />
      </Selecao>
    </div>
  );
};
