import { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import ApiUsers from "../../ApiUsers";

export const QSGAgendaContext = createContext();

export const QSGAgendaProvider = ({ children }) => {
  const navigate = useNavigate();
  const { allUsers, pref } = useContext(UserContext);
  const [initialState, setInitialState] = useState({
    usuarios: [],
    especialidades: [],
  });
  const [usuarios, setUsuarios] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [itensRemovidos, setItensRemovidos] = useState([]);
  const [accordionParentSection, setAccordionParentSection] = useState({});
  const [accordionSubSection, setAccordionSubSection] = useState({});

  const criarAgenda = async () => {
    await ApiUsers.post(`/qsg/criarAgenda`, { agenda: { usuarios, dbname: pref.dbname, especialidades, locais, itensRemovidos } })
      .then((res) => {
        console.log(res);
        setLocais(res.data.locais);
        setUsuarios(res.data.usuarios);
        setEspecialidades(res.data.especialidades);
        setInitialState({ ...initialState, usuarios: res.data.usuarios, especialidades: res.data.especialidades, locais: res.data.locais });
        setItensRemovidos([]);
        navigate("/config");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sairAgenda = () => {
    setUsuarios(initialState.usuarios);
    setEspecialidades(initialState.especialidades);
    setLocais(initialState.locais);
    navigate("/config");
  };

  useEffect(() => {
    const grupos = pref?.services?.voxchat.grupos ? pref?.services?.voxchat.grupos : [];

    const novosUsuarios = allUsers
      ? allUsers.map((usuario) => {
          const departamentoUsuario = grupos.find((grupo) => grupo._id === usuario.departamento);
          const setorUsuario = grupos.find((grupo) => grupo._id === usuario.setor);
          return {
            _id: usuario._id,
            nome: usuario.nome,
            email: usuario.email,
            profissional: usuario.profissional,
            departamento: departamentoUsuario?.nome ? departamentoUsuario?.nome : "Sem departamento",
            telefone: usuario.telefone,
            setor: setorUsuario?.nome ? setorUsuario?.nome : "Sem setor",
          };
        })
      : [];
    const removerPrimeiro = novosUsuarios.slice(1);
    setUsuarios(removerPrimeiro);
    setInitialState((prev) => ({ ...prev, usuarios: removerPrimeiro }));
  }, []);

  useEffect(() => {
    const isUsuarioProfissional = (usuario) => {
      return usuario.profissional;
    };
    const novosProfissionais = usuarios ? usuarios.filter(isUsuarioProfissional) : [];
    setProfissionais(novosProfissionais);
  }, [usuarios]);

  useEffect(() => {
    const novosEspecialidades = pref?.services?.agenda?.especialidades ? pref?.services?.agenda?.especialidades : [];
    setEspecialidades(novosEspecialidades);
    setInitialState((prev) => ({ ...prev, especialidades: novosEspecialidades }));
  }, []);

  useEffect(() => {
    const novosLocais = pref?.services?.agenda?.locais ? pref?.services?.agenda?.locais : [];
    setLocais(novosLocais);
    setInitialState((prev) => ({ ...prev, locais: novosLocais }));
  }, []);

  return (
    <QSGAgendaContext.Provider
      value={{
        usuarios,
        setUsuarios,
        profissionais,
        especialidades,
        setEspecialidades,
        locais,
        setLocais,
        criarAgenda,
        sairAgenda,
        setItensRemovidos,
        accordionParentSection,
        setAccordionParentSection,
        accordionSubSection,
        setAccordionSubSection,
      }}>
      {children}
    </QSGAgendaContext.Provider>
  );
};
