import { useState, useEffect } from 'react'
import InputSearch from '../../../Campanhas/campanhas/CampanhaForm/AddContatos/Table/InputSearch'
import ListItem from './listItem'
import { BsInfoCircleFill } from 'react-icons/bs'

const TableLeft = (props) => {
    const { selectedFromLeft, setSelectedFromLeft, attendancesOnShow } = props
    const [filteredAttendances, setFilteredAttendances] = useState([]);
    const [search, setSearch] = useState("");

    //funcao para filtrar os contatos da esquerda
    function FilteredAttendances() {
        const newFilteredAttendances = attendancesOnShow.filter((attendance) => {
            return (
                attendance.nome?.toLowerCase().includes(search.toLowerCase()) ||
                attendance.telefone?.toLowerCase().includes(search.toLowerCase()) ||
                attendance.pushname?.toLowerCase().includes(search.toLowerCase())
            );
        });
        setFilteredAttendances(newFilteredAttendances);
    }

    //funcao para selecionar todos os contatos da esquerda
    const handleSelectAllLeft = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const newSelectedFromLeft = attendancesOnShow;
            setSelectedFromLeft(newSelectedFromLeft);
        } else {
            setSelectedFromLeft([]);
        }
    };

    //funcao para selecionar contato da esquerda
    const handleSelectLeft = (contato) => {
        const newSelectedFromLeft = contato;
        if (selectedFromLeft.includes(newSelectedFromLeft)) {
            setSelectedFromLeft(
                selectedFromLeft.filter((item) => item !== newSelectedFromLeft)
            );
        } else {
            setSelectedFromLeft([...selectedFromLeft, newSelectedFromLeft]);
        }
    };

    useEffect(() => {
        FilteredAttendances();
    }, [search, attendancesOnShow]);

    return (
        <div className="table-left">
            <div className="table-left-container-multi-select">
                <input
                    type="checkbox"
                    onChange={handleSelectAllLeft}
                    checked={
                        selectedFromLeft.length &&
                        attendancesOnShow.length === selectedFromLeft.length
                    }
                    style={{
                        width: "18px",
                        height: "18px",
                    }}
                />
                <p>Selecionar todos</p>
            </div>

            <div className="container-list-table">
                <InputSearch onChange={(e) => setSearch(e.target.value)} />

                <div className="table-list">
                    {filteredAttendances.length === 0 ? (
                        <div className="table-information-add-not-found">
                            <BsInfoCircleFill size={25} color="#9AC31C" />
                            <p>Nenhum contato encontrado !</p>
                        </div>
                    ) : (
                        filteredAttendances?.map((contato, i) => {
                            return (
                                <ListItem
                                    key={i}
                                    contato={contato}
                                    checked={selectedFromLeft?.find(
                                        (selected) => selected.telefone === contato.telefone || selected._id === contato._id
                                    )}
                                    onChange={() => handleSelectLeft(contato)}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

export default TableLeft
