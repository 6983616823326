import { createContext, useContext, useState, useEffect } from "react";
import { UserContext } from "./UserProvider";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const socket = userContext.socket;
  const agenda = userContext.pref.services.agenda;
  const [locais, setLocais] = useState([]);

  const addLocal = (local) => {
    setLocais([...locais, local]);
  };

  const deleteLocal = (local) => {
    setLocais(
      locais.filter((localSelecionado) => localSelecionado._id !== local._id)
    );
  };

  const updateLocal = (local) => {
    setLocais(
      locais.map((localSelecionado) =>
        local._id === localSelecionado._id ? local : localSelecionado
      )
    );
  };

  function initSocket() {
    socket.on("novo local", (local) => {
      addLocal(local);
    });

    socket.on("delete local", (local) => {
      deleteLocal(local);
    });

    socket.on("update local", (local) => {
      updateLocal(local);
    });
  }

  function socketOff() {
    socket.off("novo local");
  }

  useEffect(() => {
    if (socket) initSocket();

    return () => {
      socketOff();
    };
  }, [socket, locais]);

  useEffect(() => {
    if (agenda) {
      let locais = agenda.locais ? [...agenda.locais] : [];
      setLocais([...locais]);
    }
  }, [agenda]);

  return (
    <LocationContext.Provider value={{ locais }}>
      {children}
    </LocationContext.Provider>
  );
};
