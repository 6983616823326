import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

function Map({ latitude, longitude, nome, telefone }) {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const toggleInfoWindow = () => {
    setInfoWindowOpen(!infoWindowOpen);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC4UsHry73QUitfmXNIg8ixd50D2CgesUs">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={13}
      >
        <Marker position={center} onClick={toggleInfoWindow}>
          {infoWindowOpen && (
            <InfoWindow onCloseClick={toggleInfoWindow}>
              <div>
                <p><b>Localização</b></p>
                {nome && <p><b>Nome:</b> {nome}</p>}
                {telefone && <p><b>Telefone:</b> {telefone}</p>}
              </div>
            </InfoWindow>
          )}
        </Marker>
      </GoogleMap>
    </LoadScript>
  );
}

export default function Location(props) {
  const { data, message, justi, contactColor } = props;

  return (
    <div key={btoa(data?._id)} style={{ display: 'flex', justifyContent: justi, width: '400px', wordBreak: 'break-word', margin: '2px 0 0 8px' }}>
      <Map latitude={data.location.latitude} longitude={data.location.longitude} nome={data.fromUsername} telefone={data.from} />
    </div>
  );
}
