import React from 'react';
import Select, { components } from 'react-select';
import { BsCaretDownFill } from "react-icons/bs";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <BsCaretDownFill color='#9ac31c' />
    </components.DropdownIndicator>
  );
};

const SelectN = ({ data, name, handleInputChange, id }) => {

  const options = [
    { value: 'todos', label: 'Todos' },
    ...(data?.map((item) => ({ value: item._id, label: item.nome })) || [])
  ];

  return (
    <div style={{ display: 'inline-block', position: 'relative', width: '22%' }} className='select-filtros-graficos'>
      <Select
        id={id}
        name={name}
        options={options}
        onChange={(e) => handleInputChange(e, name)}
        placeholder={id}
        closeMenuOnSelect={true}
        components={{ DropdownIndicator }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: 'none',
            borderRadius: '3px',
          }),
          menu: (provided, state) => ({
            ...provided,
            width: '100%',
            border: 'none',
            borderRadius: '3px',
            backgroundColor: 'white',
          }),
        }}
      />
    </div>
  )
}

export default SelectN;
