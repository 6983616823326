import React from "react";
import "../botAgendas.css";

const CardAgenda = ({
  agendaLogin,
  agendaReset,
  onLoginChange,
  onResetChange,
}) => {
  return (
    <div className="comando-card-alignRow">
      <div className="width-100">
        <label>
          <h5>Agenda Login</h5>
        </label>
        <select name="login" onChange={onLoginChange} value={agendaLogin}>
          <option value="">Nenhum</option>
          <option value="pre">Pre-cadastro</option>
        </select>
      </div>
      <div className="width-100">
        <label>
          <h5>Reset Login</h5>
        </label>
        <select name="reset" onChange={onResetChange} value={agendaReset}>
          <option value={false}>Não</option>
          <option value={true}>Sim</option>
        </select>
      </div>
    </div>
  );
};

export default CardAgenda;
