import { useState, useEffect, useContext } from 'react'
import { GrupoContext } from '../../../../../../contexts/ChatBotProvider/GrupoWhatsProvider'
import { UserContext } from '../../../../../../contexts/UserProvider'
import { ListGroup } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'

const MyGroupsAttendances = (props) => {
  const { onSelect, selected } = props
  const { user } = useContext(UserContext)
  const groupContext = useContext(GrupoContext)
  const [groups, setGroups] = useState([{}])
  const [groupsOnShow, setGroupsOnShow] = useState([])
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    let newGroups = groupContext.groups ? [...groupContext.groups] : []
    newGroups = newGroups.filter(group => {
      if (group.atendentes && group.atendentes.length > 0) {
        return group.atendentes.find(atendente => atendente._id === user._id)
      }
    })
    setGroupsOnShow(newGroups)
    setGroups(newGroups)
  }, [])

  const doSearch = (value) => {
    if (value === "") {
      setGroupsOnShow([...groups]);
    } else {
      let newGroups = groups.filter(
        (group) =>
          group.nome.toLowerCase().includes(value.toLowerCase())
      );
      setGroupsOnShow(newGroups);
    }
  };

  useEffect(() => {
    doSearch(searchInput);
  }, [searchInput, groups]);

  const countAndSlice = (message) => {
    return message ? message.slice(0, 20) + '...' : '';
  };

  const formatTime = (time) => {
    return time < 10 ? '0' + time : time;
  };


  return (
    <>
      <div className="container-search" style={{ width: '95%' }}>
        <input
          style={{ width: '100%' }}
          type="search"
          placeholder="Buscar Grupo..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      {(groupsOnShow.lenght === 0 && searchInput !== '') && (
        <div className="container-information-nothingfound-att">
          <BsInfoCircle size={50} color="#9ac31c" />
          <p>
            <strong>Nenhum grupo encontrado!</strong>
          </p>
          <p style={{ color: '#989898', fontSize: '14px', marginTop: '0px' }}>
            Verifique os grupos ou crie um novo.
          </p>
        </div>
      )}
      {groupsOnShow && groupsOnShow.map((group, index) => {
        const background = group._id === selected?._id ? '#F0FFF0' : 'inherit';
        const lastMsgTime = group.lastMessage && new Date(group.lastMessage?.createdAt);
        const formatedDate = lastMsgTime ? `${formatTime(lastMsgTime.getDate())}/${formatTime(lastMsgTime.getMonth() + 1)}/${lastMsgTime.getFullYear()}` : '';
        const formatedTime = lastMsgTime ? `${formatTime(lastMsgTime.getHours())}:${formatTime(lastMsgTime.getMinutes())}` : '';

        return (
          <div className="contact-wrapper" key={index} style={{ backgroundColor: background }} >
            <img className="contact-avatar" src={group.foto || '/imgs/avatar2.png'} alt="" onError={(e) => { e.target.src = '/imgs/avatar2.png'; }} />
            <ListGroup.Item
              className="contact-data"
              style={{ border: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              onClick={(e) => onSelect(e, group)}
            >
              <b>{group.nome}</b>
              <p style={{ margin: '4px 0px' }}>{countAndSlice(group.lastMessage?.message)}</p>
              <small className="small-title">{group.lastMessage ? `Última mensagem às ${formatedTime} de ${formatedDate}` : 'Não há mensagem disponível'}</small>
            </ListGroup.Item>
            {group.unseen && group.unseen.length > 0 && (
              <div className="not-seen">
                <b>{group.unseen.length}</b>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

export default MyGroupsAttendances
