import React from "react";
import { BsX } from "react-icons/bs";

const MessageCard = ({ schema, bot, deleteDefaultMessage }) => {
  return (
    <div className="container-msg-card-bot">
      <label>
        <h5>{schema.text}</h5>
      </label>

      <div className="card-msg">
        {bot.config.defaultMessage
          .find((msg) => msg.title === schema.title)
          ?.messages.map((msg, index) => {
            return (
              <div key={index} className="div-content-message">
                <div className="div-message-label">{msg}</div>
                <button
                  className="button-delete-message-msg"
                  title="Remover mensagem"
                  onClick={() => deleteDefaultMessage(msg, schema.title)}
                  variant="contained"
                  color="secondary"
                >
                  <BsX size={20} />
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MessageCard;
