import React, { useState, useEffect, useContext } from "react";
import ApiUsers from "../../../../ApiUsers";
import { BsPlusCircleFill } from "react-icons/bs";
import CampanhaCard from "./CampanhaForm/CampanhaCard/CampanhaCard";
import CampanhaForm from "./CampanhaForm/CampanhaForm";
import { FlowBotContext } from "../../../../contexts/ChatBotProvider/FlowBotProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import { LoadingSpinner } from "../../../miscelaneous/Loader/load";

import "../campanhas.css";

function Campanhas() {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [campanhas, setCampanhas] = useState([]);

  const { user } = useContext(UserContext);
  const flowBotContext = useContext(FlowBotContext);

  useEffect(() => {
    setLoading(true); // Ativar o estado de carregamento
    if (flowBotContext.campanhas) {
      let campaigns = flowBotContext.campanhas;
      if (!user.admin) {
        campaigns = campaigns.filter((camp) => camp.criador === user.nome);
      }
      if(!campaigns.removidos){
        campaigns.removidos = [];
      }
      setCampanhas(campaigns);
    }
    setLoading(false); // Desativar o estado de carregamento quando o carregamento estiver concluído
  }, [flowBotContext.campanhas]);

  function deleteCampanha(campanha) {
    setLoading(true);
    let command = window.confirm(
      "Deseja realmente excluir a campanha " + campanha.nome + "?"
    );
    if (command) {
      ApiUsers.post("/campanhas/delete", { campanha }).then((res) => {
        if (res.data) {
          alert("Campanha excluída com sucesso!");
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      return;
    }
  }

  function updateCampanhas(campanha) {
    setLoading(true);
    let command = window.confirm(
      "Deseja realmente atualizar a campanha " + campanha.nome + "?"
    );

    if (command) {
      ApiUsers.post("/campanhas/update", { campanha }).then((res) => {
        if (res.data) {
          alert("Campanha atualizada com sucesso!");
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      return;
    }
  }

  function editCampanha(campanha) {
    setSelected(campanha);
  }

  return (
    <div className="campanhas-container">
      <div className="campanhas-footer">
        <button
          className="add-button"
          onClick={(e) =>
            setSelected({
              nome: "",
              bot: "",
              criador: user.nome,
              responsavel: "",
              etapas: [],
              contatos: [],
              removidos: [],
            })
          }
        >
          <BsPlusCircleFill size={20} color="white" />
          Criar nova campanha
        </button>
      </div>
      <div className="campanhas-content">
        {loading ? (
          <div className="campanhas-container-loader">
            <LoadingSpinner showModal={"none"} />
          </div>
        ) : (
          <>
            {campanhas.length === 0 ? (
              <div className="container-message-not ">
                <p>Não há campanhas disponíveis.</p>
              </div>
            ) : (
              <div className="campanhas-panel">
                {campanhas.map((campanha, index) => {
                  return (
                    <CampanhaCard
                      key={index}
                      campanha={campanha}
                      deleteCampanha={deleteCampanha}
                      editCampanha={editCampanha}
                      loading={loading}
                    />
                  );
                })}
              </div>
            )}
            {selected && (
              <CampanhaForm
                campanha={selected}
                updateCampanhas={updateCampanhas}
                setSelected={setSelected}
                loading={loading}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Campanhas;
