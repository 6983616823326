import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import ProfileHeader from "./components/profileHeader";
import ProfileForm from "./components/profileForm";
import MensagensRapidas from "./components/MensagensRapidas.jsx";
import TemporizadorList from "./components/Temporizador/index.jsx";
import Alert from "../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../Configurações/OptionsScreen/inputs/DeleteAlert.jsx";
import MainView from "../main/MainView";
import "./profile.css";

const Profile = () => {
  const { user } = useContext(UserContext);
  const [usuario, setUsuario] = useState();
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    let newUser = user ? { ...user } : {};
    const { _id, nome, email, telefone, ramal, foto, admin } = newUser;
    setUsuario({ _id, nome, email, telefone, ramal, foto, admin });
  }, [user]);

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  return (
    <MainView title="Perfil">
      <div className="profile-wrapper">
        {alert.visible && alertType[alert.type]}
        <ProfileHeader usuario={usuario} createAlert={createAlert} />
        <ProfileForm usuario={usuario} createAlert={createAlert} />
        <MensagensRapidas createAlert={createAlert} quitAlert={quitAlert} />
        <TemporizadorList createAlert={createAlert} quitAlert={quitAlert} usuario={usuario} />
      </div>
    </MainView>
  );
};

export default Profile;
