import { Inputs } from "./Inputs";
import { ReactSelectLabel } from "./ReactSelect";
import { ReactSelectList } from "./ReactSelectList";
import { ReactSelectListArray } from "./ReactSelectListArray";
import { ItemList } from "./ItemList";
import { InputList } from "./InputList";
import { useForm } from "./useForm";
import { Button } from "./Button";
import { useEffect } from "react";
import "./styles.css";

export const Form = ({ formInputs, options, data, setData, editingSelected, setEditingSelected, editingStates, setEditingStates, selected, setSelected, selectedOptions, setSelectedOptions, selectedItem, setSelectedItem, currentForm, prevForm, checkBoxes, nextForm, multForms, setMultForms, optionsList, listKey, nextListKey, inputList, setInputList, inputListKey, prevKey, prevSelectedOptions, setPrevSelectedOptions }) => {
  const { formValues, setFormValues, handleCreate, handleEdit, handleCancel, setFormStates, handleBack, setFormType, handleSave, currentListKey, setCurrentListKey, setPrevListKey, selectedOption, setSelectedOption, actions, inputListKeys, setListKeys } = useForm({ formInputs, data, setData, selected, setSelected, editingSelected, setEditingSelected, editingStates, setEditingStates, options, selectedOptions, setSelectedOptions, currentForm, setMultForms, prevForm, selectedItem, listKey, optionsList, inputList, setInputList, inputListKey, multForms, setSelectedItem, prevKey, prevSelectedOptions, setPrevSelectedOptions });

  const inputComponents = {
    input: {
      component: Inputs,
      setValue: setFormValues,
      inputType: formInputs.inputType,
    },
    reactSelect: {
      component: ReactSelectLabel,
      setValue: setFormValues,
    },
    object: {
      component: Inputs,
      setValue: setFormValues,
      inputType: formInputs.inputType,
    },
    reactSelectList: {
      component: ReactSelectList,
      setValue: setFormValues,
    },
    reactSelectListArray: {
      component: ReactSelectListArray,
      setValue: setFormValues,
    },
  };

  const buttons = {
    selected: selected,
    formValues: formValues,
    onEdit: {
      component: Button,
      edit: {
        label: "Atualizar",
        onClick: handleEdit,
      },
      cancel: {
        label: "Cancelar",
        onClick: handleCancel,
      },
    },
    onCreate: {
      component: Button,
      create: {
        label: "Criar",
        onClick: handleCreate,
      },
    },
    onConfig: {
      component: Button,
      config: {
        label: "Salvar",
        onClick: () => {
          handleSave(currentListKey);
        },
      },
      back: {
        label: "Voltar",
        onClick: handleBack,
      },
    },
  };

  useEffect(() => {
    const newListkey = listKey;
    setCurrentListKey(newListkey);
  }, [listKey]);

  return (
    <form className="qsg-agenda-form">
      {selected?.nome && <h2>{selected?.nome}</h2>}
      {formInputs.map((input, index) => {
        const inputComponent = inputComponents[input.type];
        if (inputComponent) {
          return (
            <inputComponent.component
              key={input.key}
              label={input.label}
              options={options && options[input.key]}
              value={formValues[input.key]}
              setValue={inputComponent.setValue}
              inputKey={input.key}
              legend={input.legend}
              config={input.config}
              type={input.type}
              inputType={input.inputType}
              subKeys={input.subKeys}
              checkBoxes={checkBoxes}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              optionsList={optionsList}
              setFormValues={setFormValues}
              setCurrentListKey={setCurrentListKey}
              currentListKey={currentListKey}
              nextListKey={nextListKey}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          );
        }
        if (input.button) {
          return (
            <button
              type="button"
              key={index}
              className="qsg-agenda-form-button-add-to-list"
              onClick={() => actions[input.button.action](formValues, input.subKeys)}>
              <span>{input.button.label}</span>
            </button>
          );
        }
        return null;
      })}

      {formValues[currentListKey] && formValues[currentListKey].length > 0 && (
        <div className="qsg-agenda-itemList-container">
          <ItemList
            formValues={formValues}
            currentListKey={currentListKey}
            setFormType={setFormType}
            setFormStates={setFormStates}
            setSelectedItem={setSelectedItem}
            setPrevListKey={setPrevListKey}
            setListKeys={setListKeys}
            setEditingStates={setEditingStates}
            setFormValues={setFormValues}
            setSelectedOptions={setSelectedOptions}
            nextForm={nextForm}
            currentForm={currentForm}
          />
        </div>
      )}
      {inputList && inputList.length > 0 && (
        <div className="qsg-agenda-inputList-container">
          <InputList
            inputList={inputList}
            inputListKeys={inputListKeys}
            setInputList={setInputList}
            checkBoxes={checkBoxes}
          />
        </div>
      )}
      <Button
        editingStates={editingStates}
        isEditingSelected={editingSelected}
        buttons={buttons}
        formType={currentForm}
      />
    </form>
  );
};
