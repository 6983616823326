import React from "react";

const ProtCard = (props) => {
  function normalizeDateAndTime(date) {
    if (!date) return "";
    let d = new Date(date);
    return d.toLocaleDateString() + " " + removeSeconds(d.toLocaleTimeString());
  }
  function removeSeconds(time) {
    return time.substring(0, time.length - 3);
  }

  return (
    <div className="pr-card" onClick={props.showPdf}>
      <div className="pr-card-header">
        <MyRow label="Protocolo: " value={props.protocolo.numero} />
        <MyRow label="Contato: " value={props.protocolo.contato?.telefone} />
        <MyRow label="Status: " value={props.protocolo.status} />
        <MyRow
          label="Entrada: "
          value={normalizeDateAndTime(props.protocolo.entrada?.date)}
        />
        <MyRow
          label="Finalizado: "
          value={normalizeDateAndTime(props.protocolo.saida?.date)}
        />
        <MyRow label="Tempo: " value={props.protocolo.tempo} />
        {props.protocolo.atts?.map((att) => {
          return (
            <MyRow
              isInline={props.isInline}
              label={att.isbot ? "Bot: " : "Atendente: "}
              value={att.nome}
            />
          );
        })}
      </div>
    </div>
  );
};

const MyRow = (props) => {
  return (
    <div className={"pr-row"}>
      <h4> {props.label}&nbsp;</h4>
      <h4> {props.value}</h4>
    </div>
  );
};

export default ProtCard;
