import React, { useEffect, useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import InputSearch from "../InputSearch";

export default function TableContact({
  attendancesOnShow,
  search,
  setSearch,
  handleSelectAllLeft,
  handleSelectLeft,
  selectedFromLeft,
}) {
  const [filteredAttendances, setFilteredAttendances] = useState([]);

  const selectedAllChecked =
    selectedFromLeft.length &&
    attendancesOnShow.length === selectedFromLeft.length;

  function FilteredAttendances() {
    const newFilteredAttendances = attendancesOnShow.filter((attendance) => {
      return (
        attendance.nome?.toLowerCase().includes(search.toLowerCase()) ||
        attendance.telefone?.toLowerCase().includes(search.toLowerCase()) ||
        attendance.pushname?.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredAttendances(newFilteredAttendances);
  }

  // fazer filtro nos atendimentos selecionados e dai ver se ele foi pra outra lista e sai da lista atual
  useEffect(() => {
    FilteredAttendances();
  }, [search, attendancesOnShow]);

  const ListItem = ({ contato, onChange, checked }) => {
    return (
      <div className="table-list-item">
        <input
          type="checkbox"
          style={{
            width: "18px",
            height: "18px",
          }}
          checked={checked}
          onChange={onChange}
        />
        <p>
          {contato.nome || contato.pushname || ""} - {contato.telefone}
        </p>
      </div>
    );
  };

  return (
    <div className="table-left">
      <div className="table-left-container-multi-select">
        <input
          type="checkbox"
          onChange={handleSelectAllLeft}
          checked={selectedAllChecked}
          style={{
            width: "18px",
            height: "18px",
          }}
        />
        <p>Selecionar todos</p>
      </div>

      <div className="container-list-table">
        <InputSearch onChange={(e) => setSearch(e.target.value)} />
        <div className="table-list">
          {filteredAttendances.length === 0 ? (
            <div className="table-information-add-not-found">
              <BsInfoCircleFill size={25} color="#9AC31C" />
              <p>Nenhum contato encontrado !</p>
            </div>
          ) : (
            filteredAttendances?.map((contato, i) => {
              return (
                <ListItem
                  key={i}
                  contato={contato}
                  checked={selectedFromLeft?.find(
                    (selected) => selected.telefone === contato.telefone
                  )}
                  onChange={() => handleSelectLeft(contato)}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
