import React from "react";
import "./styles.css";

export default function ButtonsFooter({ onSubmit, setOpenContatosForm }) {
  return (
    <div className="add-footer-wtz">
      <button
        className="cancel-button"
        style={{marginBottom:0}}
        onClick={() => {
          setOpenContatosForm(false);
        }}
      >
        Cancelar
      </button>
      <button className="save-button" onClick={onSubmit}>
        Salvar
      </button>
    </div>
  );
}
