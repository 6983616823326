import { useContext, useEffect, useState } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { UserContext } from "../../../../../../contexts/UserProvider";

const QrCodeContainer = ({ bot, endpoint }) => {
  const botContext = useContext(FlowBotContext);
  const { user } = useContext(UserContext);
  const [random, setRandom] = useState(FlowBotContext.random);

  useEffect(() => {
    setRandom(botContext.random);
  }, [botContext.random]);

  return (
    <>
      {bot.status === "logando" && (
        <div className="qr-code-container">
          {random && random[bot?._id] && (
            <>
              <img
                src={
                  window.location.href.includes("localhost")
                    ? `http://localhost:4000/qrcodes/${user.organização
                    }/${bot._id}.png?${random[bot._id]}`
                    : `${endpoint()}/qrcodes/${user.organização}/${bot._id
                    }.png?${random[bot._id]}`
                }
                alt=""
                className="qr-code-img"
                title="Escannear QR Code"
                onError={(e) => {
                  e.target.src = `${endpoint()}/qrcodes/${user.organização
                    }/${bot._id}.png?${random[bot._id]}`;
                }}
              />
              <span className="bot-label"> Scanneie o QR Code para ativar</span>
            </>
          )}
        </div>
      )}
      {bot.status === "esperando" && (
        <div className="bot-content-info-align">
          <div className="bot-content-info">
            <span className="bot-label">Aguarde o carregamento do QR Code</span>
          </div>
        </div>
      )}
    </>
  );
};

export default QrCodeContainer;
