import React from 'react'
import Select, { components } from 'react-select';
import { BsCaretDownFill } from "react-icons/bs";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <BsCaretDownFill color='#9ac31c' />
        </components.DropdownIndicator>
    );
};

const AtendimentoSelectBot = ({ allBots, setBot, bot }) => {

    const autoChooseBot = () => {
        if (!bot) {
            let firstBot = allBots.find((bot) => bot.status === 'ativo')
            return firstBot?._id
        }else{
           return allBots.find((b) => b._id === bot)?.nome
        }
    }
    
    return (
        <div>
            <Select
                id="bot"
                name="bot"
                options={allBots.map((bot) => ({ value: bot?._id, label: bot?.nome }))}
                onChange={(e) => setBot(e.value)}
                value={autoChooseBot()}
                placeholder={autoChooseBot()}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                        width: '280px',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                    }),
                }}
            />
        </div>
    )
}

export default AtendimentoSelectBot
