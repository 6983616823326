import { useState, useContext, useEffect } from 'react'
import { PrefContext } from '../../../../../../contexts/ChatBotProvider/PrefProvider'
import ApiUsers from '../../../../../../ApiUsers';
import { BsGearWideConnected, BsPersonFillX  } from "react-icons/bs";

const ListUsers = ({ usuarios, imAdm , grupo, createAlert }) => {
    const { users } = useContext(PrefContext)
    const [usersList, setUsersList] = useState([])

    useEffect(() => {
        if (users) {
            let newList = users.filter((user) => usuarios?.includes(user._id))
            setUsersList(newList)
        }
    }, [usuarios])

    const handleDeleteUser = (user) => {
        let userId = [user._id]
        ApiUsers.post("/grupos/removerVarios", { users: userId, grupo: grupo }).then((res) => {
            let newGrupo = { ...grupo }
            let newUsers = newGrupo.usuarios.filter((u) => u !== user._id)
            newGrupo.usuarios = newUsers
            setUsersList(usersList.filter((u) => u._id !== user._id))
            createAlert("normal", { title: "Sucesso!", placeholder: "Usuário removido do grupo com sucesso!" });           
        }).catch((err) => {
            console.log(err)
            createAlert('normal', { title: 'Erro ao remover usuário' })
        })
    }


    return (
        <div className="contact-info-list-users-contander">
            <div className="contact-info-list-users-header">
                <span>Membros</span>
            </div>

            <div className="contact-info-list-users-body">
                {usersList.map((user, index) => {
                    return (
                        <div key={index} className="contact-info-list-users-card">
                            <div className="contact-info-list-users-avatar">
                                <img style={{ width: 45, height: 45 }} className="title-contact-img" src={user.foto ? user.foto : "/imgs/avatar2.png"} alt="Foto Contato" />
                            </div>

                            <div className="contact-info-list-users-info">
                                {user.nome.length > 15 ?
                                    <p>{user.nome.substring(0, 15) + "..."}</p>
                                    : <p>{user.nome}</p>}
                                {
                                    user.departamentoNome ? user.departamentoNome.length > 15 ?
                                        <span>{user.departamentoNome.substring(0, 15) + "..."}</span>
                                        : <span>{user.departamentoNome}</span> : <p>{"Sem departamento"}</p>
                                }
                                <span>{user.setorNome ? user.setorNome : "Sem setor"}</span>
                            </div>
                            {imAdm.ativo && imAdm._id === user._id ? (<div className='contact-info-list-iconBox'><BsGearWideConnected color={'#9ac31c'} size={20} title='Você é o administrador do grupo' /></div>) : null}
                            {imAdm.ativo && imAdm._id !== user._id ? (<div className='contact-info-list-iconBox' style={{cursor:'pointer'}} onClick={()=>handleDeleteUser(user)}><BsPersonFillX color={'#9ac31c'} size={20} title='Remover usuário' /></div>) : null}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ListUsers
