export const Button = ({ editingStates, buttons, formType }) => {
  let formTypePrefix;
  if (formType) {
    formTypePrefix = formType.split(".")[0];
  }

  if (formTypePrefix === "config" && editingStates) {
    return (
      <div className="qsg-agenda-form-button-container">
        <button
          type="button"
          className="qsg-agenda-form-button qsg-agenda-form-button-cancel"
          onClick={buttons.onEdit.cancel.onClick}>
          {buttons.onEdit.cancel.label}
        </button>
        <button
          type="button"
          className="qsg-agenda-form-button"
          onClick={buttons.onEdit.edit.onClick}>
          {buttons.onEdit.edit.label}
        </button>
      </div>
    );
  } else if (formTypePrefix === "config") {
    return (
      <div className="qsg-agenda-form-button-container">
        <button
          type="button"
          className="qsg-agenda-form-button"
          onClick={buttons.onConfig.back.onClick}>
          {buttons.onConfig.back.label}
        </button>
        <button
          type="button"
          className="qsg-agenda-form-button"
          onClick={buttons.onConfig.config.onClick}>
          {buttons.onConfig.config.label}
        </button>
      </div>
    );
  } else {
    return (
      <div className="qsg-agenda-form-button-container">
        {editingStates ? (
          <>
            <button
              type="button"
              className="qsg-agenda-form-button qsg-agenda-form-button-cancel"
              onClick={buttons.onEdit.cancel.onClick}>
              {buttons.onEdit.cancel.label}
            </button>
            <button
              type="button"
              className="qsg-agenda-form-button"
              onClick={buttons.onEdit.edit.onClick}>
              {buttons.onEdit.edit.label}
            </button>
          </>
        ) : (
          <button
            type="button"
            className="qsg-agenda-form-button"
            onClick={buttons.onCreate.create.onClick}>
            {buttons.onCreate.create.label}
          </button>
        )}
      </div>
    );
  }
};
