import React, { useState, useEffect, useContext } from "react";
import FlowActions from "./FlowActions";
import FlowArea from "./FlowArea";
import Modal from "./Modal";
import ActionForm from "./ActionForm";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import ApiUsers from "../../../ApiUsers";
import Alert from "../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../../Configurações/OptionsScreen/inputs/DeleteAlert";
import "./Flow.css";
import { FlowBotContext } from "../../../contexts/ChatBotProvider/FlowBotProvider";

export default function FlowCreation() {
  const [flows, setFlows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState({});
  const [selectedCommand, setSelectedCommand] = useState();
  const [editedFlow, setEditedFlow] = useState({});
  const [dragAction, setDragAction] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ visible: false, type: "", action: "" });
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [newFlow, setNewFlow] = useState({ nome: "", comandos: [] });
  const [ignore, setIgnore] = useState(false);
  const context = useContext(FlowBotContext);

  const actionColors = [
    { name: "Resposta Simples", color: "#1e6091" },
    { name: "Pergunta Alternativa", color: "#168aad" },
    { name: "Pergunta Descritiva", color: "#52b6ad" },
    { name: "Pergunta com Variável", color: "#26bb90" },
    { name: "Agendamento", color: "#52b6ad" },
    { name: "Listagem", color: "#1a9b77" },
    //{ name: "Enquete", color: "#1a9b77" },
  ];

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        loading={loading}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    if (selectedFlow.nome) {
      let flow = context.flows.find((flow) => flow._id === selectedFlow._id);
      setSelectedFlow(flow ? flow : {});
    }
    setFlows(context.flows);
  }, [context.flows]);

  function handleDrag(e, action) {
    e.preventDefault();
    setDragAction(action);
  }

  function onDragOver(e) {
    e.preventDefault();
  }

  function onDrop(e) {
    e.preventDefault();
    if (ignore) return;
    let list = [...flows];
    let flow = { ...selectedFlow };
    flow.comandos = [...flow.comandos, dragAction];
    list.forEach((f) => {
      if (f.nome === selectedFlow.nome) {
        f.comandos = [...f.comandos, dragAction];
      }
    });
    let index = flow.comandos.length - 1;
    let command = flow.comandos[index];
    command.index = index;
    setFlows(list);
    setSelectedFlow(flow);
    setSelectedCommand(command);
    showModal("newCommand", "edit");
    setDragAction();
  }

  function undoCommand() {
    let list = [...flows];
    let flow = { ...selectedFlow };
    flow.comandos = [...flow.comandos];
    flow.comandos.pop();
    list.forEach((f) => {
      if (f.nome === selectedFlow.nome) {
        f.comandos = [...f.comandos];
        f.comandos.pop();
      }
    });
    setFlows(list);
    setSelectedFlow(flow);
    setSelectedCommand();
  }

  function changeCmdIndex(oldIndex, newIndex) {
    let flow = { ...selectedFlow };
    let list = [...flows];
    let comand = flow.comandos[oldIndex];
    flow.comandos.splice(oldIndex, 1);
    flow.comandos.splice(newIndex, 0, comand);
    list.forEach((f) => {
      if (f.nome === flow.nome) f.comandos = flow.comandos;
    });
    setLoading(true);
    ApiUsers.put("/whats/flow", { flow })
      .then((res) => {
        setSelectedFlow(flow);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  function changeFlowName(e) {
    let flow = { ...newFlow };
    flow.nome = e.target.value;
    setNewFlow(flow);
  }

  function addNewFlow() {
    if (newFlow.nome.trim() !== "") {
      if (flows.some((f) => f.nome === newFlow.nome)) {
        createAlert("normal", {
          title: "Ops!",
          placeholder: "Nome já existente, por favor escolha outro nome!",
        });
      } else {
        setLoading(true);
        ApiUsers.post("/whats/flow", { flow: newFlow })
          .then((res) => {
            setSelectedFlow(newFlow);
            setLoading(false);
            createAlert("normal", {
              title: "Sucesso!",
              placeholder: "O flow foi criado com sucesso!",
            });
            setModal({ visible: false, type: "", action: "" });
          })
          .catch((err) => console.log(err));
      }
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha os campos vazios!",
      });
    }
  }

  function deleteFlow(flow) {
    setLoading(true);
    ApiUsers.post("/whats/deleteFlow", { flow })
      .then((res) => {
        if (selectedFlow.nome === flow.nome) setSelectedFlow({});
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "O flow foi deletado com sucesso!",
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  function editFlow(newFlow) {
    if (newFlow.nome.trim() !== "") {
      setLoading(true);
      ApiUsers.put("/whats/flow", { flow: newFlow })
        .then((res) => {
          createAlert("normal", {
            title: "Sucesso!",
            placeholder: "O flow foi editado com sucesso!",
          });
          setModal({ visible: false, type: "", action: "" });
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha os campos vazios!",
      });
    }
  }

  function selectFlow(name) {
    let list = [...flows];
    list.forEach((f) => {
      if (f.nome === name) setSelectedFlow(f);
    });
  }

  function selectCommand(index) {
    let flow = { ...selectedFlow };
    let command = flow.comandos[index];
    command.index = index;
    setSelectedCommand(command);
  }

  function saveCommand(flow) {
    setLoading(true);
    ApiUsers.put("/whats/flow", { flow })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "O comando foi salvo com sucesso!",
        });
        setModal({ visible: false, type: "", action: "" });
        setLoading(false);
        setSelectedFlow(flow);
      })
      .catch((err) => console.log(err));
  }

  function deleteCommand(index) {
    let flow = { ...selectedFlow };
    flow.comandos.splice(index, 1);
    setLoading(true);
    ApiUsers.put("/whats/flow", { flow })
      .then((res) => {
        setSelectedFlow(flow);
        setLoading(false);
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "O comando foi deletado com sucesso!",
        });
      })
      .catch((err) => console.log(err));
  }

  function showModal(type, action) {
    setModal({ visible: true, type, action });
  }

  function editModal(e, flow) {
    e.stopPropagation();
    setEditedFlow(flow);
    setModal({ visible: true, type: "flow", action: "edit" });
  }

  function deleteCmdModal(index, command) {
    createAlert("delete", {
      data: "comando",
      nome: command.tipo,
      action: () => deleteCommand(index),
    });
  }

  function deleteModal(e, flow) {
    e.stopPropagation();
    createAlert("delete", {
      data: "flow",
      nome: flow.nome,
      action: () => deleteFlow(flow),
    });
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  return (
    <div>
      {alert.visible && alertType[alert.type]}

      {modal.visible && (
        <>
          <Modal
            typeModal={modal.type}
            undoCommand={() => undoCommand()}
            closeModal={() =>
              setModal({ visible: false, type: "", action: "" })
            }
          >
            {modal.type === "flow" && modal.action === "create" ? (
              <>
                <CreateForm
                  newFlow={newFlow}
                  loading={loading}
                  changeFlowName={(e) => changeFlowName(e)}
                  addNewFlow={() => addNewFlow()}
                />
              </>
            ) : (modal.type === "newCommand" || modal.type === "command") &&
              modal.action === "edit" ? (
              <>
                <ActionForm
                  selectedCommand={selectedCommand}
                  loading={loading}
                  selectedFlow={selectedFlow}
                  flowList={flows}
                  saveCommand={(flow) => saveCommand(flow)}
                  undoCommand={() => undoCommand()}
                  closeModal={() =>
                    setModal({ visible: false, type: "", action: "" })
                  }
                  typeModal={modal.type}
                  createAlert={(type, props) => createAlert(type, props)}
                />
              </>
            ) : (
              <>
                <EditForm
                  flow={editedFlow}
                  loading={loading}
                  editFlow={(flow) => editFlow(flow)}
                />
              </>
            )}
          </Modal>
        </>
      )}

      <div className="flow-container">
        <FlowActions
          actionColors={actionColors}
          handleDrag={(e, action) => handleDrag(e, action)}
        />

        <FlowArea
          flows={flows}
          selectedFlow={selectedFlow}
          actionColors={actionColors}
          onDrop={(e) => {
            onDrop(e);
          }}
          onDragOver={(e) => onDragOver(e)}
          deleteModal={(e, flow) => deleteModal(e, flow)}
          editModal={(e, flow) => editModal(e, flow)}
          selectFlow={(name) => selectFlow(name)}
          deleteCmdModal={(index, command) => deleteCmdModal(index, command)}
          selectCommand={(index) => selectCommand(index)}
          changeCmdIndex={(oldIndex, newIndex) =>
            changeCmdIndex(oldIndex, newIndex)
          }
          setIgnore={(bollean) => setIgnore(bollean)}
          showModal={() => showModal("command", "edit")}
        />

        <div className="flow-buttons-container">
          <button
            className="flow-button flow-create-btn"
            disabled={loading}
            title={"Criar Flow"}
            style={{ backgroundColor: "#9ac31c", width: 150 }}
            onClick={() => showModal("flow", "create")}
          >
            {" "}
            Novo Flow{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
