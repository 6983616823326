import React, { useEffect, useState } from 'react'
import Contact from './types/Contact'
import Location from './types/Location'
import Text from './types/Text'
import File from './types/File'
import Audio from './types/Audio'
import Videos from './types/Video'
import Image from './types/Image'
import Undefined from './types/Undefined'
import Forwarding from './types/Forwarding'
import Reply from './types/Reply'
import From from './pieces/From'
import FooterMessage from './pieces/Footer'
import ForwardTo from './pieces/forward/ForwardTo'
import HiddenButtons from './pieces/HiddenButtons'
import { RiArrowDownSFill } from 'react-icons/ri'

import './style.css'

export default function Index(props) {
    const [type, setType] = useState('undefined')
    const [messageClass, setMessageClass] = useState('')
    const [justify, setJustify] = useState('')
    const [contactColor, setContactColor] = useState('')
    const [data, setData] = useState({})
    const [visualized, setVisualized] = useState(false)
    const [formatedDate, setFormatedDate] = useState('')
    const [time, setTime] = useState('')
    const [message, setMessage] = useState('')
    const [contact, setContact] = useState('')
    const [location, setLocation] = useState({})
    const [isFromMe, setIsFromMe] = useState(false)
    const [hiddenButtons, setHiddenButtons] = useState(false)
    const [preShow, setPreShow] = useState(false)
    let stylesMessage = {
        sent: {
            newMessageClass: "message-sent",
            justi: 'flex-end',
            contactColor: 'black'
        },
        received: {
            newMessageClass: "message-received",
            justi: 'flex-start',
            contactColor: 'black'
        }
    }
    const types = {
        text: <Text
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        file: <File
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        audio: <Audio
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        video: <Videos
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        image: <Image
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        document: <File
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        location: <Location
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
            location={location}
        />,
        contact: <Contact
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
            contact={contact}
        />,
        sticker: 'sticker',
        gif: 'gif',
        reply: <Reply
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        forwarding: <Forwarding
            justi={justify}
            contactColor={contactColor}
            data={data}
            visualized={visualized}
            message={message}
        />,
        delete: 'delete',
        undefined: <Undefined />
    }

    useEffect(() => {
        if (props.data) {
            let typo = props.data.msgTypo
            typo = typo === 'files' ? 'file' : typo
            setType(typo)
            setData(props.data)
            let newDate = newFormatedDate(props.data)
            if (newDate.formatedDate) setFormatedDate(newDate.formatedDate)
            if (newDate.time) setTime(newDate.time)
        }
    }, [props.data, props.messageTypo])

    useEffect(() => {
        if (props.data) {
            let messageTypo = props.messageTypo
            setMessageReceived(messageTypo)
        }
    }, [props.data, props.messageTypo])

    useEffect(() => {
        if (props.contact) setContact(props.contact)
    }, [props.contact])

    useEffect(() => {
        if (props.location) setLocation(props.location)
    }, [props.location])

    useEffect(() => {
        let messageDate = breakLineToBr(props.data.message)
        setMessage(messageDate)
    }, [props.data])

    useEffect(() => {
        if (data) {
            let visualized = data.seenBy?.includes(data.to)
            setVisualized(visualized)
            let newIsFromeMe = data.from === props.userId
            setIsFromMe(newIsFromeMe)
        }

    }, [data]);

    function setMessageReceived(type) {
        if (!type) return
        let newMessageClass = stylesMessage[type].newMessageClass
        let justi = stylesMessage[type].justi
        let contactColor = stylesMessage[type].contactColor
        setJustify(justi)
        setContactColor(contactColor)
        setMessageClass(newMessageClass)
    }

    return (
        <div className={"message-wrapper message-wrapper-" + messageClass}>

            <div className="dots-button-container" style={hiddenButtons ? { justifyContent: 'flex-start', marginBottom: '25px' } : { justifyContent: 'center' }}>
                <div className="dots-button" onClick={e => {
                    if (e.target.classList.contains('hidden-button')) return
                    let newShow = !hiddenButtons
                    setHiddenButtons(newShow)
                }}>
                    <p>...</p>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <From data={data} justi={justify} contactColor={contactColor} />

                <div key={data?._id} className={messageClass + (props.isLooking ? ' looking' : '')}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', minWidth: '300px', maxWidth: '350px' }}                >

                    {types[type]}
                    <FooterMessage
                        hidden={hiddenButtons} onSetHiddenButtons={setHiddenButtons} messageClass={messageClass + (props.isLooking ? ' looking' : '')}
                        visualized={visualized} time={time} formatedDate={formatedDate} isFromMe={isFromMe} data={data} />

                </div>

                <HiddenButtons data={data} hidden={hiddenButtons} onSetHiddenButtons={setHiddenButtons} />

            </div>

            <ForwardTo />
        </div>

    )
}


function newFormatedDate(data) {
    let datetime = new Date(data.createdAt)
    let hour = datetime.getHours()
    let minute = datetime.getMinutes()
    let second = datetime.getSeconds()
    let date = datetime.getDate()
    let month = datetime.getMonth() + 1
    let year = datetime.getFullYear()
    let time = `${isLowerThan10(hour)}:${isLowerThan10(minute)}:${isLowerThan10(second)}`
    let formatedDate = `${isLowerThan10(date)}/${isLowerThan10(month)}/${isLowerThan10(year)}`
    return { time, formatedDate }
}
function isLowerThan10(num) {
    return num < 10 ? "0" + num : num
}

function breakLineToBr(str) {
    if (!str) {
        return <div >{str}</div>
    }
    return str.split('\n').map((item, index) => {
        return item
    })
}

// function transformLinksTags(str) {
//     const linkRegex = /(\b(?:https?|ftp|file):\/\/|www\.)\S+/gi;

//     const transformedArray = str.split(' ').map((word, index) => {
//         if (word.match(linkRegex)) {
//             const url = word.startsWith('www.') ? `http://${word}` : word;
//             return <a href={url} key={index} target="_blank" rel="noopener noreferrer"> &nbsp;{word}</a>;
//         } else {
//             let newWord = word + ' '
//             return  newWord
//         }
//     });
//     return transformedArray;
// }