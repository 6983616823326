import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "./HeaderView";
import SearchMessagesScreen from "../Chats/general/SearchMessagesScreen";


const MainView = (props) => {
  const { headerOn } = props
  
  return (
    <div className="controller-view">
      <ToastContainer />
      {
        headerOn && <Header title={props.title} little={false} />

      }
      <div className="main-container-view">
        {props.children}
        <SearchMessagesScreen />

      </div>
    </div>
  );
};

export default MainView;
