import { useState, useEffect } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import DeleteAlert from "../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import "./style.css";

export const LocalCard = (props) => {
  const [local, setLocal] = useState({ ...props.local });
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    delete: (
      <DeleteAlert
        id={alert.props.id}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    const novoLocal = { ...props.local };
    setLocal(novoLocal);
  }, [props.local]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  function handleDelete() {
    props.deleteLocal(local);
    quitAlert();
  }

  return (
    <div className="card-local">
      {alert.visible && alertType[alert.type]}
      <div className="card-local-content">
        <div className="card-local-content-left">
          <h3>{local.nome}</h3>
          <p
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <b>Endereço:</b> {local.endereco.rua}
          </p>
          <p>
            <b>Telefone:</b> {local.fone}
          </p>
          <p>
            <b>Especialidades:</b>
          </p>
          <ol>
            {local.especialidades.map((especialidades, index) => (
              <li key={index}>{especialidades.nome}</li>
            ))}
          </ol>
        </div>

        <div className="card-content-right">
          <FaEdit
            onClick={() => props.editLocal(local)}
            className="edit-local"
            title="Editar Local"
            size={"2rem"}
            style={{ color: "#2a9d8f" }}
          />
          <FaTrash
            className="delete-local"
            size={"2rem"}
            title="Deletar Local"
            style={{ color: "rgb(255, 19, 19)" }}
            onClick={() =>
              createAlert("delete", {
                id: local._id,
                nome: local.nome,
                action: () => handleDelete(),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
