import { useState, useEffect } from "react";
import Intervalo from "./horarioIntervalo";
import Espacamento from "./horarioEspacamento";
import { PeriodoAtendimento } from "./periodoAtendimento";
// import Alert from "../../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import "./style.css";

export const ProfissionalForm = (props) => {
  const [profissional, setProfissional] = useState({
    nome: "",
    horarioIntervalo: [],
    horarioEspacamento: "",
    espacoAgendamento: "00:30",
    periodoAtendimento: {
      inicio: "",
      fim: "",
    },
  });

  // const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  // const alertType = {
  //   normal: (
  //     <Alert
  //       title={alert.props.title}
  //       placeholder={alert.props.placeholder}
  //       quit={() => quitAlert()}
  //     />
  //   ),
  // };

  // function quitAlert() {
  //   setAlert({ visible: false, type: "", props: {} });
  // }

  // function createAlert(type, props) {
  //   setAlert({ visible: true, type, props: { ...props } });
  // }

  // const validatePeriodoAtendimento = () => {
  //   const currentDate = new Date();
  //   const year = currentDate.getFullYear();
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  //   console.log(month);
  //   const day = currentDate.getDate();
  //   const formattedDate = `${year}-${month}-${day}`;

  //   if (
  //   profissional.periodoAtendimento.inicio >=
  //   profissional.periodoAtendimento.fim
  //   ) {
  //     createAlert("normal", {
  //       title: "Erro",
  //       placeholder:
  //         "Data do período de atendimento inválida. Data de início deve ser menor que a data de fim.",
  //     });
  //     return false;
  //   } else if (
  //     profissional.periodoAtendimento.inicio < formattedDate ||
  //     profissional.periodoAtendimento.fim < formattedDate
  //   ) {
  //     createAlert("normal", {
  //       title: "Erro",
  //       placeholder:
  //         "Data do período de atendimento inválida. Data de início e data de fim não podo ser menor que a data atual.",
  //     });
  //     return false;
  //   }

  //   return true;
  // };

  const handleBackProfissional = () => {
    props.setEditandoProfissional(false);
    props.setProfissionalSelecionado(null);
  };

  useEffect(() => {
    const novoProfissional = { ...props.profissionalSelecionado };
    setProfissional(novoProfissional);
  }, [props.profissionalSelecionado]);

  useEffect(() => {
    const novaEspecialidadeSelecionada = { ...props.especialidadeSelecionada };
    const profissionalSelecionado =
      novaEspecialidadeSelecionada.profissionais.find(
        (profissional) => profissional._id === props.profissionalSelecionado._id
      );

    if (profissionalSelecionado) {
      setProfissional(profissionalSelecionado);
    }
  }, [props.especialidadeSelecionada, props.profissionalSelecionado._id]);

  const salvarProfissional = (e) => {
    e.preventDefault();
    // if (!validatePeriodoAtendimento()) {
    //   return;
    // }

    const novoProfissional = { ...profissional };
    novoProfissional.horarioIntervalo.forEach((horario, index) => {
      novoProfissional.horarioIntervalo[index].dias = horario.dias;
      novoProfissional.horarioIntervalo[index].inicio = horario.inicio;
      novoProfissional.horarioIntervalo[index].fim = horario.fim;
    });
    novoProfissional.periodoAtendimento = {
      inicio: profissional.periodoAtendimento.inicio,
      fim: profissional.periodoAtendimento.fim,
    };

    props.atualizarProfissional(novoProfissional);
  };

  const createNewInterval = (e) => {
    e.preventDefault();
    let novoProfissional = { ...profissional };
    let novoHorarioIntervalo = [...profissional.horarioIntervalo];
    novoHorarioIntervalo.push({ dias: [], inicio: "", fim: "" });
    novoProfissional.horarioIntervalo = novoHorarioIntervalo;
    setProfissional(novoProfissional);
  };

  return (
    <form
      className="profissional-especialidade-local-form"
      style={
        profissional.horarioIntervalo.length > 0
          ? { height: "82%" }
          : { height: "50%" }
      }
    >
      {/* {alert.visible && alertType[alert.type]} */}

      <div className="profissional-especialidade-local-form-title">
        {profissional.nome}
      </div>
      <div className="profissional-especialidade-local-form-content">
        <div className="profissional-especialidade-local-form-input-container-horario">
          <PeriodoAtendimento
            profissional={profissional}
            setProfissional={setProfissional}
          />
          <Espacamento
            profissional={profissional}
            setProfissional={setProfissional}
          />
        </div>
        <div className="add-horarioIntervalo-btn">
          <span onClick={createNewInterval}>
            Adicionar Horário de Intervalo
          </span>
        </div>
      </div>
      <div className="button-container">
        <button id="back" type="button" onClick={handleBackProfissional}>
          Voltar
        </button>
        <button type="submit" id="add" onClick={salvarProfissional}>
          Salvar
        </button>
      </div>
      {profissional.horarioIntervalo.length > 0 && (
        <Intervalo
          profissional={profissional}
          setProfissional={setProfissional}
        />
      )}
    </form>
  );
};
