import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../../../contexts/UserProvider";
import { BsEyeFill } from "react-icons/bs";

const UsersModal = ({ grupo, close }) => {
  const { pref } = useContext(UserContext);
  const [usuarios, setUsuarios] = useState([]);
  const modalRef = useRef(null);

  useEffect(() => {
    let users = pref?.users
      ? [...pref.users].filter((user) => user.atendente && user.ativo)
      : [];
    let usersFiltered = users.filter((user) =>
      grupo.usuarios.includes(user._id)
    );
    setUsuarios(usersFiltered);
  }, [grupo, pref]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);

  return (
    <div className="group-users-modal">
      <div className="group-users-modal-container" ref={modalRef}>
        <div className="group-users-modal-header">
          <div className="group-users-modal-header-title">
            <BsEyeFill color={"#9ac31c"} size={20} />
            <h3>{grupo.nome}</h3>
          </div>
          <span onClick={close}>X</span>
        </div>
        <div className="group-users-modal-body">
          <div className="group-users-modal-list">
            {usuarios.map((user, index) => {
              return (
                <div key={index} className="group-users-modal-list-item">
                  <p>{user.nome}</p>
                  <span>{user.email}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersModal;
