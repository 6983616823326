import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserProvider";
import MainView from "../main/MainView";
import Header from "./components/dashHeader";
import FiltrosGraficos from "./components/FiltrosGraficos";
import GraficoCarousel from "./components/GraficoCarousel";
import UserList from "./components/ListaUsers";
import TabelasDeTempo from "./components/TabelasDeTempo";
import GaugeMessage from "./components/GaugeMessage";
import { LoadingSpinner } from "../miscelaneous/Loader/load";
import { AttendanceContext } from "../../contexts/ChatBotProvider/AttendanceProvider";
import Banner from "./components/Banner";
import "./home.css";

export default function Home() {
  const { pref, user } = useContext(UserContext);
  const [prefeitura, setPrefeitura] = useState({});
  const [dataShow, setDataShow] = useState({});

  const [loadPrefClone, setLoadPrefClone] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let newPref = pref ? await JSON.parse(JSON.stringify(pref)) : {};
    if (newPref.users.length > 0) {
      newPref.users = newPref.users.filter(
        (u) => u.ativo && u.email !== "voxcity.suporte@voxcity.com.br"
      );
      if (!user.admin) {
        newPref.users = newPref.users.filter((u) => u._id === user._id);
        newPref.services.voxbot.atendentes = newPref.services.voxbot?.atendentes.filter((g) => g.usuarios.includes(user._id))
      }
    }
    setLoadPrefClone(false);
    setPrefeitura(newPref);
    setDataShow(newPref);
  };

  return (
    <MainView title="Dashboard" headerOn={true}>
      <div className="dash-wrapper">
        {loadPrefClone ? (
          <div className="container-loader">
            <LoadingSpinner showModal="none" />
          </div>
        ) : (
          <>
            <Header pref={pref} />
            <FiltrosGraficos
              zeroState={prefeitura}
              pref={dataShow}
              setPref={setDataShow}
            />
            <div className="dash-grafic-division">

              <UserList pref={dataShow} />

              <GraficoCarousel pref={dataShow} zeroState={prefeitura} />
            </div>
            <TabelasDeTempo pref={dataShow} />
            <GaugeMessage pref={dataShow} />
            <Banner />
          </>
        )}
      </div>
    </MainView>
  );
}
