import React from 'react';
import{Form,Row,Col} from "react-bootstrap"

const SimpleInpuLabel = (props) => {

    return (

            <Row style={{marginTop:10}}>   

                <Col md={3} style={{display:'flex', alignItems:'center'}}>

                    <Form.Group style={{margin:0}} controlId={props.field}>
                        
                        <Form.Label className='required'style={{margin:0}}>{props.field}:</Form.Label>

                    </Form.Group>

                </Col>

                <Col md={9}>
                    <Form.Control onChange={props.handleChange} name={props.field} type={props.type} placeholder={props.placeholder} value={props.value}/>
                </Col>

            </Row>
        
    );
}

export default SimpleInpuLabel;
