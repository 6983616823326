import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Modal(props) {
  return (
    <>
      <div className="modal-background">
        <div className="modal-inside-content">
          <div className="modal-close-btn" onClick={() => props.close()}>
            {" "}
            <AiOutlineCloseCircle />{" "}
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
}
