import Select from "react-select";
import { useState, useEffect } from "react";

export const PesquisaServicos = ({
  profissionais,
  especialidades,
  servicos,
  locais,
  setServicosFiltrados,
}) => {
  const [opcao, setOpcao] = useState("servico");
  const [profissionalSelecionado, setProfissionalSelecionado] = useState("");
  const [servicoSelecionado, setServicoSelecionado] = useState("");
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState("");
  const [localSelecionado, setLocalSelecionado] = useState("");

  const optionsProfissionais = profissionais.map((profissional) => ({
    value: profissional._id,
    label: profissional.nome,
  }));

  const optionsServicos = servicos.map((servico) => ({
    value: servico._id,
    label: servico.nome,
  }));

  const optionsEspecialidades = especialidades.map((especialidade) => ({
    value: especialidade._id,
    label: especialidade.nome,
  }));

  const optionsLocais = locais.map((local) => ({
    value: local._id,
    label: local.nome,
  }));

  const handleSelectChangeLocal = (selectedOption) => {
    setLocalSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeServico = (selectedOption) => {
    setServicoSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeProfissional = (selectedOption) => {
    setProfissionalSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeEspecialidade = (selectedOption) => {
    setEspecialidadeSelecionada(selectedOption ? selectedOption.value : "");
  };

  const handleSetOpcao = (e) => {
    setOpcao(e.target.value);
  };

  useEffect(() => {
    let novosServicosFiltrados = [];
    switch (opcao) {
      case "profissional":
        if (profissionalSelecionado !== "") {
          const profissional = profissionais.find(
            (p) => p._id === profissionalSelecionado
          );
          if (profissional) {
            const especialidadeIds = new Set();
            especialidades.forEach((esp) => {
              if (esp.profissionais.includes(profissional._id)) {
                especialidadeIds.add(esp._id);
              }
            });
            novosServicosFiltrados = servicos.filter((s) =>
              s.locais.some((localId) =>
                locais
                  .find((l) => l._id === localId)
                  .especialidades.some((esp) =>
                    esp.profissionais.some(
                      (p) => p._id === profissionalSelecionado
                    )
                  )
              )
            );
          }
        } else {
          novosServicosFiltrados = servicos;
        }
        break;

      case "especialidade":
        if (especialidadeSelecionada !== "") {
          const especialidade = especialidades.find(
            (e) => e._id === especialidadeSelecionada
          );
          if (especialidade) {
            novosServicosFiltrados = servicos.filter((s) =>
              s.locais.some((localId) =>
                locais
                  .find((l) => l._id === localId)
                  .especialidades.some((esp) => esp._id === especialidade._id)
              )
            );
          }
        } else {
          novosServicosFiltrados = servicos;
        }
        break;

      case "servico":
        if (servicoSelecionado !== "") {
          const servico = servicos.find((s) => s._id === servicoSelecionado);
          if (servico) {
            novosServicosFiltrados = servicos.filter(
              (s) => s._id === servico._id
            );
          }
        } else {
          novosServicosFiltrados = servicos;
        }
        break;

      case "local":
        if (localSelecionado !== "") {
          const local = locais.find((l) => l._id === localSelecionado);
          if (local) {
            const especialidadeIds = new Set();
            local.especialidades.forEach((espId) => {
              especialidadeIds.add(espId);
            });
            novosServicosFiltrados = servicos.filter((l) =>
              l.locais.some((localId) =>
                locais
                  .find((l) => l._id === localId)
                  .especialidades.some((espId) => especialidadeIds.has(espId))
              )
            );
          }
        } else {
          novosServicosFiltrados = servicos;
        }
        break;
      default:
        break;
    }

    setServicosFiltrados(novosServicosFiltrados);
  }, [
    especialidadeSelecionada,
    especialidades,
    locais,
    localSelecionado,
    opcao,
    profissionais,
    profissionalSelecionado,
    servicoSelecionado,
    servicos,
    setServicosFiltrados,
  ]);

  return (
    <fieldset className="profissional-search-fieldset">
      <legend className="profissional-search-legend">
        <span>Pesquisar</span>
      </legend>
      <div className="profissional-search">
        <select
          value={opcao}
          onChange={(event) => handleSetOpcao(event)}
          className="profissional-search-select"
        >
          <option value="profissional">Profissional</option>
          <option value="especialidade">Especialidade</option>
          <option value="local">Local</option>
          <option value="servico">Serviço</option>
        </select>
        {opcao === "profissional" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsProfissionais]}
            value={optionsProfissionais.find(
              (option) => option.value === profissionalSelecionado
            )}
            onChange={handleSelectChangeProfissional}
            placeholder="Escolha um profissional"
            isClearable={true}
          />
        )}
        {opcao === "especialidade" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsEspecialidades]}
            value={optionsEspecialidades.find(
              (option) => option.value === especialidadeSelecionada
            )}
            onChange={handleSelectChangeEspecialidade}
            placeholder="Escolha uma especialidade"
            isClearable={true}
          />
        )}
        {opcao === "local" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsLocais]}
            value={optionsLocais.find(
              (option) => option.value === localSelecionado
            )}
            onChange={handleSelectChangeLocal}
            placeholder="Escolha um local"
            isClearable={true}
          />
        )}
        {opcao === "servico" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsServicos]}
            value={optionsServicos.find(
              (option) => option.value === servicoSelecionado
            )}
            onChange={handleSelectChangeServico}
            placeholder="Escolha um serviço"
            isClearable={true}
          />
        )}
      </div>
    </fieldset>
  );
};
