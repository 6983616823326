import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import TableShow from "./tableShow";
import ApiUsers from "../../../../ApiUsers";
import "./tabelas-de-tempo.css";  

export default function Index(props) {
  const { pref } = props;
  const [attData, setAttData] = useState([]);
  const [gruposAttData, setGruposAttData] = useState([]);
  const [load, setLoad] = useState(false);
  const [searchTermGrupos, setSearchTermGrupos] = useState("");
  const [searchTermAtts, setSearchTermAtts] = useState("");
  const [filteredDataGrupos, setFilteredDataGrupos] = useState([]);
  const [filteredDataAtts, setFilteredDataAtts] = useState([]);

  async function generateAttsData(atts, users, grps) {
    setLoad(true);
    try {
      const res = await ApiUsers.post("/calculate/attData", {
        atts,
        users,
        grps,
      });
      const data = res.data;
      setAttData(data.att);
      setGruposAttData(data.grp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    let atts = pref?.services?.voxbot?.contatos
      ? [...pref.services.voxbot.contatos]
      : [];
    let grps = pref?.services?.voxbot?.atendentes
      ? [...pref.services.voxbot.atendentes]
      : [];
    let users = pref?.users
      ? [...pref.users].filter((user) => user.atendente)
      : [];
    atts = atts.map((att) => {
      delete att.allMessages;
      return att;
    });
    users = users.map((user) => {
      delete user.allMessages;
      return user;
    });
    generateAttsData(atts, users, grps);
  }, [pref]);

  const handleSearchGrupos = () => {
    const filteredData = gruposAttData.filter((row) => {
      return Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTermGrupos.toLowerCase())
      );
    });
    setFilteredDataGrupos(filteredData);
  };

  const handleSearchAtts = () => {
    const filteredData = attData.filter((row) => {
      return Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTermAtts.toLowerCase())
      );
    });
    setFilteredDataAtts(filteredData);
  };

  const handleInputChangeAtts = (e) => {
    const value = e.target.value;
    if (value === "") {
      setFilteredDataAtts([]);
    }
    setSearchTermAtts(value);
  };

  const handleInputChangeGrupos = (e) => {
    const value = e.target.value;
    if (value === "") {
      setFilteredDataGrupos([]);
    }
    setSearchTermGrupos(value);
  };

  const handleKeyPress = (e, searchFunction) => {
    if (e.key === "Enter") {
      searchFunction();
    }
  };

  return (
    <div className="tabelas-de-tempo">
      <div className="table-show-wrapper" style={{ marginLeft: "20px" }}>
        <div className="table-show-Wrapper-header">
          <h3>Tempo de grupos em Espera</h3>
        </div>
        <div className="search-div">
          <input
            type="text"
            placeholder="Buscar"
            value={searchTermGrupos}
            onChange={handleInputChangeGrupos}
            onKeyPress={(e) => handleKeyPress(e, handleSearchGrupos)}
          />
          <div className="button-search-box" onClick={handleSearchGrupos}>
            <BsSearch size={15} />
          </div>
        </div>
        <TableShow
          colsName={colsGroups}
          data={
            filteredDataGrupos.length > 0 ? filteredDataGrupos : gruposAttData
          }
          loading={load}
          grupos={pref?.services?.voxbot?.atendentes}
        />
      </div>

      <div className="table-show-wrapper" style={{ marginRight: "16px" }}>
        <div className="table-show-Wrapper-header">
          <h3>Tempo de Finalização</h3>
        </div>
        <div className="search-div">
          <input
            type="text"
            placeholder="Buscar"
            value={searchTermAtts}
            onChange={handleInputChangeAtts}
            onKeyPress={(e) => handleKeyPress(e, handleSearchAtts)}
          />
          <div className="button-search-box" onClick={handleSearchAtts}>
            <BsSearch size={15} />
          </div>
        </div>
        <TableShow
          colsName={colsAtts}
          data={filteredDataAtts.length > 0 ? filteredDataAtts : attData}
          loading={load}
        />
      </div>
    </div>
  );
}

const colsGroups = [
  "Grupo",
  "Usuários",
  "Total de atendimentos",
  "Tempo médio de espera",
];
const colsAtts = ["Atendente", "Total de atendimentos", "Tempo Total"];
