import React from "react";

export default function NotBot() {
  return (
    <>
      <div className="empty-bot-area">
        <img
          alt="no bot"
          src="imgs/bot.png"
          style={{ width: 200, margin: 20 }}
        />
        <h3> Não há nenhum bot criado </h3>
      </div>
    </>
  );
}
