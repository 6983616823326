import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../../../contexts/UserProvider';
import FromUserName from './fromUserName'

const BodyInfo = ({ contato, data, setData, tipo, editData, setEditData }) => {
    const { pref } = useContext(UserContext)
    const currentDate = new Date().toISOString().split('T')[0];
    const [nome, setNome] = useState('')
    const [bots, setBots] = useState([])

    useEffect(() => {
        if (!pref) return
        let newBots = pref.services?.voxbot?.bots.filter(bot => bot.status === 'ativo')
        setBots(newBots)
        setData({ ...data, bot: newBots[0]?._id })
    }, [pref.services?.voxbot?.bots])

    useEffect(() => {
        if (tipo === 'Atendimento') {
            let newNome = contato?.pushname ? contato.pushname : contato?.telefone
            setNome(newNome)
        } else {
            let newNome = contato?.nome
            setNome(newNome)
        }
    }, [tipo])

    useEffect(() => {
        if (!editData) return
        if (editData && tipo === 'Atendimento') {
            let newNome = `${editData.toName} - ${editData.to}`
            setNome(newNome)
        } else {
            let newNome = editData.toName
            setNome(newNome)
        }
    }, [editData, tipo])

    const handleDataChange = (e) => {
        let newDate = new Date(e.target.value).toISOString()
        if (editData) {
            setEditData({ ...editData, data: newDate })
        } else {
            setData({ ...data, data: newDate })
        }
    }

    const handleTimeChange = (e) => {
        if (editData) {
            setEditData({ ...editData, hora: e.target.value })
        } else {
            setData({ ...data, hora: e.target.value })
        }
    }
    return (
        <>
            {tipo === 'Atendimento' && <div className="temporizador-body-info">
                <span>Bot:</span>
                <select onChange={(e) => setData({ ...data, bot: e.target.value })} value={data?.bot}>
                    <option value=''>Selecione um bot</option>
                    {bots.map((bot, index) => {
                        return <option key={index} value={bot._id}>{bot.nome}</option>
                    })}
                </select>
            </div>}

            <div className="temporizador-body-info">
                <span>Para:</span>
                <p>{nome}</p>
            </div>

            <div className="temporizador-body-info">
                <span>Tipo:</span>
                <p>{tipo}</p>
            </div>

            <div className="temporizador-body-info" >
                <span>De:</span>
                <FromUserName tipo={tipo} setData={setData} data={data} editData={editData} setEditData={setEditData} />
            </div>

            <div className="temporizador-body-box-date">
                <div className="temporizador-body-date">
                    <span>Data:</span>
                    <input type="date" onChange={handleDataChange} min={currentDate} value={
                        editData ? editData.data.split('T')[0] : data?.data.split('T')[0]
                    } />
                </div>

                <div className="temporizador-body-time" style={{ marginLeft: '20px' }}>
                    <span>Hora:</span>
                    <input type="time" onChange={handleTimeChange} value={
                        editData ? editData.hora : data.hora
                    } />
                </div>
            </div>
        </>
    )
}

export default BodyInfo
