import React, { useState, useContext,useEffect } from 'react'
import { UserContext } from '../../contexts/UserProvider'
import './css/reconnect.css'
export default function ReconnectAlert(props) {

    const [timeout, setTimeout] = useState(4)
    const context = useContext(UserContext)
    const reloadPage = () => {
        context.reload()
    }

    useEffect(() => {
        let time = timeout
        let interval = setInterval(() => {
            time--
            setTimeout(time)
        }, 1000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (timeout === 0) {
            reloadPage()
        }
    }, [timeout])


    return (
    
    <div className="reconnect-alert">
        <div className="reconnect-alert-content">
            <h3>Você foi desconectado...</h3>
            <button className="btn btn-primary" onClick={reloadPage}>Recarregando em {timeout} segundos</button>
            <p>Se o problema persistir, verifique sua internet.</p>

        </div>
    </div>
  )
}
