import { useContext, useState, useEffect } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { MdInfoOutline } from "react-icons/md";
import { BsSearch } from "react-icons/bs";

export const ModalConviteBotHeader = ({
  setPesquisa,
  handleSelectBot,
  tipoContato,
  setTipoContato,
  botSelecionado,
}) => {
  const { bots } = useContext(FlowBotContext);
  const [botsPref, setBotsPref] = useState([]);
  const [searchInputContatos, setSearchInputContatos] = useState("");
  const [searchInputGrupos, setSearchInputGrupos] = useState("");

  const handleSearchContatos = () => {
    const searchTerm = searchInputContatos.trim();
    setPesquisa(searchTerm || "");
  };

  const handleSearchGrupos = () => {
    const searchTerm = searchInputGrupos.trim();
    setPesquisa(searchTerm || "");
  };

  useEffect(() => {
    const novosBots = bots ? bots : [];
    setBotsPref([...novosBots]);
    setBotsPref(bots);
  }, [bots]);

  useEffect(() => {
    setSearchInputContatos("");
    setSearchInputGrupos("");
    setPesquisa("");
  }, [tipoContato, setPesquisa]);

  return (
    <>
      <ul className="convite-bot-modal-header">
        <li
          className={
            tipoContato === "contatos" ? "convite-bot-tipo-contato-ativo" : ""
          }
          onClick={() => setTipoContato("contatos")}
        >
          Contatos
        </li>
        <li
          className={
            tipoContato === "grupo" ? "convite-bot-tipo-contato-ativo" : ""
          }
          onClick={() => setTipoContato("grupo")}
        >
          Grupos
        </li>
      </ul>
      <div className="convite-bot-select">
        <p>Selecionar Bot</p>
        {
          <select onChange={handleSelectBot} value={botSelecionado}>
            <option value="" disabled>
              Selecione um bot
            </option>
            {botsPref?.map((bot, index) => {
              if (bot.status !== "ativo") return null;
              return (
                <option value={bot._id} key={index}>
                  {bot.nome}
                </option>
              );
            })}
          </select>
        }
      </div>
      <div className="convite-bot-search">
        {tipoContato === "contatos" ? (
          <input
            type="text"
            placeholder="Buscar contatos"
            value={searchInputContatos}
            onChange={(e) => setSearchInputContatos(e.target.value)}
          />
        ) : (
          <input
            type="text"
            placeholder="Buscar grupos"
            value={searchInputGrupos}
            onChange={(e) => setSearchInputGrupos(e.target.value)}
          />
        )}
        <MdInfoOutline
          className="fa-Info"
          title="Digite o nome ou telefone do contato, ou o nome do grupo. Em seguida, selecione o(s) contato(s) desejado(s) para adicionar à lista. Para enviar o convite, selecione o BOT."
          alt="Digite o nome ou telefone do contato, ou o nome do grupo. Em seguida, selecione o(s) contato(s) desejado(s) para adicionar à lista. Para enviar o convite, selecione o BOT."
        />
        <div
          className="button-search-box"
          onClick={
            tipoContato === "contatos"
              ? handleSearchContatos
              : handleSearchGrupos
          }
        >
          <BsSearch size={18} />
        </div>
      </div>
    </>
  );
};
