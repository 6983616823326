import React, { useState, useEffect, useContext } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { Spinner } from "react-bootstrap";
import { BsTrash3 } from "react-icons/bs";
import "./cardcampanha.css";

function CampanhaCard(props) {
  const [campanha, setCampanha] = useState({ ...props.campanha });
  const { bots } = useContext(FlowBotContext);

  useEffect(() => {
    setCampanha({ ...props.campanha });
  }, [props.campanha]);

  return (
    <div className="campanha-card">
      <div className="campanha-space-text-card">
        <div className="campanha-card-header">
          <h3>{campanha.nome}</h3>
          <BsTrash3
            className="delete-icon"
            onClick={(e) => props.deleteCampanha(campanha)}
          />
        </div>
        <div className="campanha-card-body">
          <p>
            Bot: {""}
            <strong>
              {campanha.bot
                ? bots.find((b) => b._id == campanha.bot)
                  ? bots.find((b) => b._id == campanha.bot).nome
                  : "Nenhum"
                : "Nenhum"}
            </strong>
          </p>
          <p>
            Criador: {""}
            <strong>{campanha.criador ? campanha.criador : "--"}</strong>
          </p>
          <p>
            Responsável: {""}
            <strong>
              {campanha.responsavel ? campanha.responsavel : "--"}
            </strong>
          </p>
          <p>
            Contatos: {""}
            <strong>
              {campanha.contatos?.length ? campanha.contatos?.length : 0}
            </strong>
          </p>
          <p>
            Etapas: {""}
            <strong>
              {campanha.etapas?.length ? campanha.etapas?.length : 0}
            </strong>
          </p>
        </div>
        {props.loading ? (
          <Spinner variant="gray" animation="border" />
        ) : (
          <div className="campanha-card-footer">
            <button
              className="edit-button"
              onClick={(e) => props.editCampanha(campanha)}
            >
              Editar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CampanhaCard;
