import React from "react";

export default function HeaderXLS() {
  return (
    <>
      <div className="header-edit-modal">
        <h4>Importar contatos por XLS</h4>
        <p>Confira a lista de contatos importados e inclua-os na sua lista.</p>
      </div>
    </>
  );
}
