import { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../../../../../contexts/UserProvider'
import Select, { components } from 'react-select';
import { BsCaretDownFill } from "react-icons/bs";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <BsCaretDownFill color='#9ac31c' />
        </components.DropdownIndicator>
    );
};

const AtendimentoSelectFideliza = ({ contato, fidelity, setFidelity }) => {
    const { pref } = useContext(UserContext)
    const [usersAtt, setUsersAtt] = useState([])
    const [fidelityValueName, setFidelityValueName] = useState('')
    const options = usersAtt.map((user) => ({ value: user._id, label: user.nome }))
    options.unshift({ value: '', label: 'Nenhum' })

    useEffect(() => {
        let newUsersAtt = pref.users ? [...pref.users] : []
        setUsersAtt(newUsersAtt)
    }, [])

    useEffect(() => {
        if(contato.fidelity){
            let name = usersAtt.find((u) => u._id === contato.fidelity)
            setFidelityValueName(name.nome)
        }
    }, [contato])

    useEffect(() => {
        if(fidelity && fidelity !== ''){
            let name = usersAtt.find((u) => u._id === fidelity)
            setFidelityValueName(name.nome)
        }
    }, [fidelity])



    return (
        <div>
            <Select
                id="bot"
                name="bot"
                options={options}
                onChange={(e) => setFidelity(e.value)}
                value={contato?.fidelity}
                placeholder={fidelity !== '' ? fidelityValueName : 'Selecione um atendente'}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                        width: '280px',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#f5f5f5',
                    }),
                }}
            />
        </div>
    )
}

export default AtendimentoSelectFideliza
