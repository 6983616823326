import { useEffect, useState, useContext } from "react";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { FaUser } from "react-icons/fa";
import "./style.css";
import { AgendaFunctions } from "../../miscelaneous/utils/AgendaFunctions";
import { CalendarioContext } from "../../../contexts/CalendarioProvider";
import { LoadingSpinner } from "../../miscelaneous/Loader/load";

export const TabelaAgenda = (props) => {
  const { acharProfissional, acharEspecialidade, acharLocal, acharServico } =
    AgendaFunctions();
  const calendarioContext = useContext(CalendarioContext);
  const { isLoadingCalendario } = calendarioContext;
  const [sortOrder, setSortOrder] = useState("asc");
  const [agendas, setAgendas] = useState([]);
  const [fraseAtualIndex, setFraseAtualIndex] = useState(0);

  const frasesCarregando = [
    "Um momento, estamos montando os calendários.",
    "Aguarde, carregando informações.",
    "Carregando dados importantes...",
    "Quase lá, aguarde um pouco mais.",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFraseAtualIndex(
        (prevIndex) => (prevIndex + 1) % frasesCarregando.length
      );
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let novasAgendas = props.agendas ? [...props.agendas] : [];

    if (props.filtroProfissional)
      novasAgendas = novasAgendas.filter(
        (agenda) => agenda.profissional === props.filtroProfissional
      );

    if (props.filtroEspecialidade)
      novasAgendas = novasAgendas.filter(
        (agenda) => agenda.especialidade === props.filtroEspecialidade
      );

    if (props.filtroLocal)
      novasAgendas = novasAgendas.filter(
        (agenda) => agenda.local === props.filtroLocal
      );

    if (props.filtroServico)
      novasAgendas = novasAgendas.filter(
        (agenda) => agenda.serviço === props.filtroServico
      );

    setAgendas(novasAgendas);
  }, [
    props.filtroProfissional,
    props.filtroEspecialidade,
    props.filtroLocal,
    props.filtroServico,
    props.agendas,
  ]);

  const handleSort = () => {
    const sortedAgendas =
      sortOrder === "asc"
        ? [...agendas].sort(
            (a, b) =>
              new Date(a.datasMarcadas.data) - new Date(b.datasMarcadas.data)
          )
        : [...agendas].sort(
            (a, b) =>
              new Date(b.datasMarcadas.data) - new Date(a.datasMarcadas.data)
          );
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setAgendas(sortedAgendas);
  };

  const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return (
    <>
      {isLoadingCalendario ? (
        <div className="spinner-container-agenda">
          <LoadingSpinner showModal={"none"} />
          <p>{frasesCarregando[fraseAtualIndex]}</p>
        </div>
      ) : props.agendas.length === 0 ? (
        <div className="agendamento-vazio">NENHUM AGENDAMENTO MARCADO</div>
      ) : (
        <table className="table-agenda">
          <thead>
            <tr>
              <th onClick={() => handleSort()}>
                Data marcada{" "}
                {sortOrder === "asc" ? (
                  <TiArrowSortedUp color="#9ac31c" />
                ) : (
                  <TiArrowSortedDown color="#9ac31c" />
                )}
              </th>
              <th>Especialidade</th>
              <th>Local</th>
              <th>Serviço</th>
              <th>Profissional</th>
              <th>Cidadão</th>
            </tr>
          </thead>
          <tbody>
            {paginate(agendas, props.itemsPerPage, props.currentPage).map(
              (agenda, index) => {
                const novaData = new Date(agenda.datasMarcadas.data);
                const dataHoraFormatada =
                  novaData.toLocaleDateString("pt-BR") +
                  " | " +
                  novaData.toISOString("pt-BR").slice(11, 16);
                return agenda.datasMarcadas.status === "bloqueio" ? null : (
                  <tr key={index}>
                    <td>{dataHoraFormatada}</td>
                    <td>{acharEspecialidade(agenda.especialidade)?.nome}</td>
                    <td>{acharLocal(agenda.local)?.nome}</td>
                    <td>{acharServico(agenda.serviço)?.nome}</td>
                    <td>{acharProfissional(agenda.profissional)?.nome}</td>
                    <td>
                      <FaUser
                        className="cidadao-icon"
                        onClick={() =>
                          props.getCidadao(agenda.datasMarcadas.usuario)
                        }
                      />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      )}
    </>
  );
};
