import React from 'react'

export default function From(props) {
    const { data, justi, contactColor } = props
    
    return (
        <div style={{ display: 'flex', justifyContent: justi, width: '100%', zIndex: 2, color: contactColor }}>
            <b>{data.fromUsername}</b>
        </div>
    )
}
