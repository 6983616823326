import React from "react";
import { useState } from "react";
import Disparator from "./campanhas/DisparoRapido/Disparator";
import Campanhas from "./campanhas/Campanhas";

const Disp = (props) => {
  const [screen, setScreen] = useState("campanhas");

  const selectScreen = (scrn) => {
    setScreen(scrn);
  };

  const screens = {
    campanhas: <Campanhas />,
    "disparo rápido": <Disparator />,
  };

  return (
    <div>
      <div style={{ margin: 8, height: "100%" }}>
        {Object.keys(screens).map((key, index) => {
          return (
            <button
              key={index}
              className={
                screen === key
                  ? "second-button-view btn-normal"
                  : "second-button-view btn-selected"
              }
              onClick={() => selectScreen(key)}
            >
              {key}
            </button>
          );
        })}
      </div>
      {screens[screen]}
    </div>
  );
};

export default Disp;
