import React, { useState, useEffect, useContext } from "react";
import "./style.css";

export default function DatePicker(props) {
  function normalizeNewDate() {
    let newDate = new Date();
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const treatWithZero = (number) => {
      if (number < 10) {
        return `0${number}`;
      }
      return number;
    };
    let date = `${year}-${treatWithZero(month)}-${treatWithZero(day)}`;
    return date.toString();
  }

  const today = normalizeNewDate();

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    setStart(today);
    setEnd(today);
  }, []);

  return (
    <div className="date-panel">
      <div className="date">
        <div className="date-title">
          {" "}
          {props.endDateLabel || "Iniciados até:"} &nbsp; &nbsp;
        </div>
        <div className="date-input">
          <input
            type="date"
            value={start}
            onChange={(e) => {
              setStart(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="date">
        <div className="date-title">
          {props.startDateLabel || "Atendidos até: "}&nbsp; &nbsp;
        </div>
        <div className="date-input">
          <input
            type="date"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>
      </div>
      <div className="date-buttons">
        <button
          className="date-button"
          onClick={() => props.setDate({ start, end })}
        >
          Aplicar
        </button>
        <button
          className="date-button"
          onClick={() => props.setDate({ start: today, end: today })}
        >
          Hoje
        </button>
      </div>
      <div></div>
    </div>
  );
}
