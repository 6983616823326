import { useState, useEffect, useContext } from "react";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { UserContext } from "../../../contexts/UserProvider";

export const AgendaFunctions = () => {
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);
  const userContext = useContext(UserContext);
  const prefAgenda = userContext.pref?.services?.agenda;
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    const novosProfissionais = professionalContext.profissionais
      ? [...professionalContext.profissionais]
      : [];
    setProfissionais(novosProfissionais);
  }, [professionalContext.profissionais]);

  useEffect(() => {
    const novasEspecialidades = specialtyContext.especialidades
      ? [...specialtyContext.especialidades]
      : [];
    setEspecialidades(novasEspecialidades);
  }, [specialtyContext.especialidades]);

  useEffect(() => {
    const novosLocais = locationContext.locais
      ? [...locationContext.locais]
      : [];
    setLocais(novosLocais);
  }, [locationContext.locais]);

  useEffect(() => {
    const novosServicos = serviceContext?.servicos
      ? [...serviceContext?.servicos]
      : [];
    setServicos(novosServicos);
  }, [serviceContext?.servicos]);

  const acharProfissional = (id) => {
    const profissional = profissionais.find(
      (profissional) => profissional._id === id
    );
    return profissional;
  };

  const acharEspecialidade = (id) => {
    const especialidade = especialidades.find(
      (especialidade) => especialidade._id === id
    );
    return especialidade;
  };

  const acharLocal = (id) => {
    const local = locais.find((local) => local._id === id);
    return local;
  };

  const acharServico = (id) => {
    const servico = servicos.find((serviço) => serviço._id === id);
    return servico;
  };

  const acharHorarioFuncionamento = (idLocal, idEspecialidade) => {
    const local = prefAgenda.locais.find((l) => l._id === idLocal);
    if (local) {
      const especialidade = local.especialidades.find(
        (e) => e._id === idEspecialidade
      );
      return especialidade.horarioFuncionamento;
    }
  };

  return {
    acharProfissional,
    acharEspecialidade,
    acharLocal,
    acharServico,
    acharHorarioFuncionamento,
  };
};
