import React, { useState } from "react";
import FileInput from "../FileInput/FileInput";
import ModalXLS from "../../../ModalXLS/ModalXLS";
import * as XLSX from "xlsx";

const ButtonImport = ({ text, title, contacts, createAlert }) => {
  const [importedNumbers, setImportedNumbers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  async function importXLSFile(e) {
    e.preventDefault();
    const file = e.target.files[0];
    // Verifica se o arquivo  do tipo  (XLS ou XLSX)
    if (file && (file.name.endsWith(".xls") || file.name.endsWith(".xlsx"))) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        // Verifica se a coluna "Telefone" está presente
        if (data.length > 0 && "Telefone" in data[0]) {
          let contactXls = data.map((item) => {
            if (item["Telefone"]) {
              return {
                ...item,
                telefone: "55" + item["Telefone"],
              };
            }
          });

          setImportedNumbers([...contactXls]);
          setShowModal(true);
        } else {
          createAlert("normal", {
            title: "Coluna 'Telefone' não encontrada",
            placeholder:
              "Para o correto funcionamento, o arquivo deve conter uma coluna com o nome 'Telefone' na posição A1 e os números abaixo.",
          });
        }
      };
      reader.readAsBinaryString(file);
    } else {
      // Arquivo não é do tipo esperado (XLS ou XLSX)
      createAlert("normal", {
        title: "Arquivo inválido",
        placeholder: "Selecione um arquivo XLS ou XLSX",
      });
    }
  }

  return (
    <div className="container-buttons-contact-header">
      <button
        onClick={() =>
          document.getElementById("fileInput-import-contacts").click()
        }
        title={title}
      >
        {text}
      </button>
      <FileInput
        id="fileInput-import-contacts"
        onChange={importXLSFile}
        onClick={(e) => (e.target.value = null)}
      />
      {showModal && (
        <ModalXLS
          contacts={contacts}
          importedNumbers={importedNumbers}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default ButtonImport;
