import React from "react";
import "../../Group.css";
import { FaUserAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { BiCrown } from "react-icons/bi";
import { FaBuilding, FaWarehouse } from "react-icons/fa";

export default function CheckboxUsersList(props) {
  const noUsers = props.noUsers;
  console.log("lista", props.usersOnShow);

  return (
    <>
      <div className="admin-checkbox-users-container-column">
        {noUsers ? (
          <>
            <button
              className="admin-checkbox-users-add-btn"
              title="Adicionar usuários"
              onClick={(e) => props.addUser(e)}
            >
              {" "}
              <IoAddCircleOutline className="admin-checkbox-users-icon" />{" "}
              Adicionar{" "}
            </button>
          </>
        ) : (
          <>
            <button
              className="admin-checkbox-users-del-btn"
              title="Remover usuários"
              onClick={(e) => props.delUser(e)}
            >
              {" "}
              <IoIosRemoveCircleOutline className="admin-checkbox-users-icon" />{" "}
              Remover{" "}
            </button>
          </>
        )}

        <div style={{ display: "flex" }}>
          <small className="admin-checkbox-small">
            Total:{" "}
            {noUsers ? props.usersOnShow.length : props.grupo.usuarios.length}
          </small>

          <small className="admin-checkbox-small">
            Selecionados:{" "}
            {noUsers
              ? props.selectedUsers.length
              : props.unselectedUsers.length}
          </small>

          {noUsers ? (
            <>
              <small className="admin-checkbox-small">
                Selecionar todos
                <input
                  className="admin-checkbox-users-input-check"
                  type="checkbox"
                  checked={props.selectedUsers.length === 0 ? false : null}
                  onChange={(e) => props.selectAll(e.target.checked)}
                />
              </small>
            </>
          ) : (
            <>
              <small className="admin-checkbox-small">
                Selecionar todos
                <input
                  className="admin-checkbox-users-input-check"
                  type="checkbox"
                  checked={props.unselectedUsers.length === 0 ? false : null}
                  onChange={(e) => props.unselectAll(e.target.checked)}
                />
              </small>
            </>
          )}
        </div>

        <div className="admin-checkbox-users-content-column">
          {noUsers ? (
            <>
              {props.usersOnShow.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: 100 }}
                      src="/imgs/emptyusers.png"
                      alt="empty"
                    />
                    <span style={{ padding: 10 }}> Sem usuários </span>
                  </div>
                </>
              ) : (
                <>
                  {props.usersOnShow.map((user, index) => {
                    let userCol = {
                      nome: user.nome,
                      email: user.email,
                      departamento: user.departamentoNome,
                      setor: user.setorNome,
                    };

                    if (user.ativo === false || user.nome === "Voxcity") return;

                    return (
                      <div className="admin-checkbox-users-card" key={index}>
                        <div
                          className="admin-checkbox-users-card-column"
                          style={
                            props.selectedUsers.includes(user)
                              ? {
                                  backgroundColor: "rgb(183 227 189)",
                                  transition: "all 0.2s linear",
                                }
                              : null
                          }
                        >
                          <input
                            className="admin-checkbox-users-input-check"
                            type="checkbox"
                            checked={
                              props.selectedUsers.includes(user) ? true : false
                            }
                            onChange={(e) =>
                              props.selectUser(e.target.checked, user)
                            }
                          />

                          <img
                            className="contact-avatar"
                            src={user.foto ? user.foto : "/imgs/avatar2.png"}
                            alt="avatar"
                            style={{ margin: 5 }}
                          />

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {Object.entries(userCol).map(([k, v]) => {
                              const Icon = {
                                nome: (
                                  <FaUserAlt className="admin-checkbox-users-icon" />
                                ),
                                email: (
                                  <MdAlternateEmail className="admin-checkbox-users-icon" />
                                ),
                                setor: (
                                  <FaWarehouse className="admin-checkbox-users-icon" />
                                ),
                                departamento: (
                                  <FaBuilding className="admin-checkbox-users-icon" />
                                ),
                              };

                              return (
                                <span
                                  key={k}
                                  className="admin-checkbox-users-span"
                                >
                                  {Icon[k]}
                                  {v !== "" ? v : `Sem ${k}`}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <>
              {props.grupo.usuarios.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: 100 }}
                      src="/imgs/emptyusers.png"
                      alt="empty"
                    />
                    <span style={{ padding: 10 }}> Sem usuários </span>
                  </div>
                </>
              ) : (
                <>
                  {props.grupo.usuarios.map((user, index) => {
                    let userCol = {
                      nome: user.nome,
                      email: user.email,
                      departamento: user.departamentoNome,
                      setor: user.setorNome,
                    };

                    return (
                      <div className="admin-checkbox-users-card" key={index}>
                        <div
                          className="admin-checkbox-users-card-column"
                          style={
                            props.unselectedUsers.includes(user)
                              ? {
                                  backgroundColor: "rgb(255 195 195)",
                                  transition: "all 0.2s linear",
                                }
                              : props.grupo.master.some((u) => u === user)
                              ? {
                                  backgroundColor: "rgb(243 230 169)",
                                  transition: "all 0.2s linear",
                                }
                              : null
                          }
                        >
                          <input
                            className="admin-checkbox-users-input-check"
                            type="checkbox"
                            checked={
                              props.unselectedUsers.includes(user)
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              props.unselectUser(e.target.checked, user)
                            }
                          />

                          <img
                            className="contact-avatar"
                            src={user.foto ? user.foto : "/imgs/avatar2.png"}
                            alt="avatar"
                            style={{ margin: 5 }}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 3,
                            }}
                          >
                            {Object.entries(userCol).map(([k, v]) => {
                              const Icon = {
                                nome: (
                                  <FaUserAlt className="admin-checkbox-users-icon" />
                                ),
                                email: (
                                  <MdAlternateEmail className="admin-checkbox-users-icon" />
                                ),
                                setor: (
                                  <FaWarehouse className="admin-checkbox-users-icon" />
                                ),
                                departamento: (
                                  <FaBuilding className="admin-checkbox-users-icon" />
                                ),
                              };

                              return (
                                <span
                                  key={k}
                                  className="admin-checkbox-users-span"
                                >
                                  {Icon[k]}
                                  {v !== "" ? v : `Sem ${k}`}
                                </span>
                              );
                            })}
                          </div>

                          <div
                            className="admin-checkbox-users-adm-selector-cont"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              {props.grupo.master.some((u) => u === user) ? (
                                <BiCrown style={{ color: "#e2a90d" }} />
                              ) : null}
                            </div>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span className="admin-checkbox-users-span">
                                {" "}
                                Admin:{" "}
                              </span>
                            </div>

                            <input
                              type="checkbox"
                              checked={
                                props.grupo.master.some((u) => u === user)
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                props.addAdmin(e.target.checked, user)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
