import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "./buttons.css";

export default function ButtonsTransfer({
  handleLeftToRight,
  handleRightToLeft,
}) {
  return (
    <div className="table-container-buttons">
      <button type="button" onClick={handleLeftToRight}>
        <BsChevronRight size={22} />
      </button>
      <button type="button" onClick={handleRightToLeft}>
        <BsChevronLeft size={22} />
      </button>
    </div>
  );
}
