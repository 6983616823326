import React, { useEffect, useState } from 'react';
import { BsPlus, BsTrash } from 'react-icons/bs';
import './style.css';

const UserCreatRestriction = (props) => {
    const { user, changeUserIntervalo, changeUserFuncionamento, restricao } = props;
    const [intervalos, setIntervalos] = useState([]);
    const [horarioFuncionamento, setHorarioFuncionamento] = useState({ inicio: "", fim: "", dias: [] });
    const diasSemana = [
        { index: 0, nome: "Domingo" },
        { index: 1, nome: "Segunda" },
        { index: 2, nome: "Terça" },
        { index: 3, nome: "Quarta" },
        { index: 4, nome: "Quinta" },
        { index: 5, nome: "Sexta" },
        { index: 6, nome: "Sábado" },
    ];

    useEffect(() => {
        changeUserIntervalo(intervalos)
    }, [intervalos]);

    useEffect(() => {
        changeUserFuncionamento(horarioFuncionamento.inicio, horarioFuncionamento.fim, horarioFuncionamento.dias);
    }, [horarioFuncionamento]);

    useEffect(() => {
        if (user?.restricao?.intervalos) {
            setIntervalos(user?.restricao?.intervalos);
        }
        if (user?.restricao?.horarioFuncionamento) {
            let newHorarioFuncionamento = user?.restricao?.horarioFuncionamento?.dias ? { ...user?.restricao?.horarioFuncionamento } : { dias: [], inicio: "", fim: "" }
            setHorarioFuncionamento(newHorarioFuncionamento);
        }
    }, []);

    useEffect(() => {
        if(restricao){
            setHorarioFuncionamento(restricao?.horarioFuncionamento)
            setIntervalos(restricao?.intervalos)
        }
    }, []);


    const addIntervalo = () => {
        const novoIntervalo = {
            dias: [],
            inicio: "",
            fim: "",
        }
        setIntervalos([...intervalos, novoIntervalo]);
    }

    const deleteIntervalo = (index) => {
        const novoIntervalos = intervalos.filter((intervalo, i) => i !== index);
        setIntervalos(novoIntervalos);
    }

    const changeIntervalo = (e, index, field) => {
        const novoIntervalos = [...intervalos];
        if (field === 'dia') {
            if (e.target.checked) {
                novoIntervalos[index].dias.push(parseInt(e.target.value));
            } else {
                novoIntervalos[index].dias = novoIntervalos[index].dias.filter(dia => dia !== parseInt(e.target.value));
            }
        } else if (field === 'inicio') {
            novoIntervalos[index].inicio = e.target.value;
        } else if (field === 'fim') {
            novoIntervalos[index].fim = e.target.value;
        }
        setIntervalos(novoIntervalos);
    }

    const changeHorarioFuncionamento = (e, field) => {
        const novoHorarioFuncionamento = { ...horarioFuncionamento };
        if (field === 'inicio') {
            novoHorarioFuncionamento.inicio = e.target.value;
        }
        if (field === 'fim') {
            novoHorarioFuncionamento.fim = e.target.value;
        }
        if (field === 'dia') {
            if (e.target.checked) {
                novoHorarioFuncionamento.dias.push(parseInt(e.target.value));
            } else {
                novoHorarioFuncionamento.dias = novoHorarioFuncionamento.dias.filter(dia => dia !== parseInt(e.target.value));
            }
        }
        setHorarioFuncionamento(novoHorarioFuncionamento);
    }

    return (
        <div className="user-creat-restriction-container">
            <div className="horario-funcionamento-wrapper">
                <h5 style={{margin:0}}>Horário de funcionamento:</h5>
                <div className="horario-funcionamento-box">
                    <div className="dias-funcionamento">
                        <p>Dias:</p>
                        {diasSemana.map((dia, i) => (
                            <div key={i} className="dia-funcionamento">
                                <input type="checkbox" name={dia.nome} value={dia.index} checked={horarioFuncionamento?.dias?.includes(dia.index)} onChange={(e) => changeHorarioFuncionamento(e, 'dia')} />
                                <p>{dia.nome}</p>
                            </div>
                        ))}
                    </div>
                    <div className="horario-funcionamento">
                        <input type="time" value={horarioFuncionamento.inicio} onChange={(e) => changeHorarioFuncionamento(e, 'inicio')} />
                        <p>às</p>
                        <input type="time" value={horarioFuncionamento.fim} onChange={(e) => changeHorarioFuncionamento(e, 'fim')} />
                    </div>
                </div>
            </div>
            <div className="intervalos-wrapper">
                <div className="add-intervalo">
                    <h5 style={{margin:0}}>Intervalos:</h5>
                    <BsPlus className='add-intervalo-icon' onClick={addIntervalo} title='Adicionar intervalo' />
                </div>
                <div className="intervalo-list">
                    {intervalos && intervalos.map((intervalo, i) => (
                        <div className="intervalo" key={i}>
                            <BsTrash className='delete-intervalo' onClick={() => deleteIntervalo(i)} title='Deletar intervalo' />
                            <div className="dias-restricao">
                                <p>Dias:</p>
                                {diasSemana.map((dia, j) => (
                                    <div key={j} className="dia-restricao">
                                        <input type="checkbox" name={dia.nome} value={dia.index} checked={intervalo.dias.includes(dia.index)} onChange={(e) => changeIntervalo(e, i, 'dia')} />
                                        <p>{dia.nome}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="horario-restricao">
                                <input type="time" value={intervalo.inicio} onChange={(e) => changeIntervalo(e, i, 'inicio')} />
                                <p>às</p>
                                <input type="time" value={intervalo.fim} onChange={(e) => changeIntervalo(e, i, 'fim')} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default UserCreatRestriction;
