import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export const Paginacao = ({
  items,
  itemsPerPage,
  setCurrentPage,
  activePage,
  setActivePage,
}) => {
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const maxDisplayedPages = 4;

  let startPage = Math.max(activePage - Math.floor(maxDisplayedPages / 2), 1);
  if (
    totalPages > maxDisplayedPages &&
    startPage + maxDisplayedPages - 1 > totalPages
  ) {
    startPage = totalPages - maxDisplayedPages + 1;
  }

  const endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

  const pagesToDisplay = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const handlePagination = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };

  const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return (
    <div>
      <ul className="pagination">
        {activePage > 1 && (
          <li
            className="page-item"
            onClick={(e) => handlePagination(e, activePage - 1)}
          >
            <AiOutlineLeft />
          </li>
        )}
        {startPage > 1 && (
          <li
            className="page-item"
            onClick={(e) => handlePagination(e, startPage - 1)}
          >
            ...
          </li>
        )}
        {pagesToDisplay.map((page) => (
          <li
            key={page}
            className={`page-item ${activePage === page ? "activePage" : ""}`}
            onClick={(e) => handlePagination(e, page)}
          >
            {page}
          </li>
        ))}
        {endPage < totalPages && (
          <li
            className="page-item"
            onClick={(e) => handlePagination(e, endPage + 1)}
          >
            ...
          </li>
        )}
        {activePage < totalPages && (
          <li
            className="page-item"
            onClick={(e) => handlePagination(e, activePage + 1)}
          >
            <AiOutlineRight />
          </li>
        )}
      </ul>

    </div>
  );
};
