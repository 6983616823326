import React from 'react';
import { Col, Row, Form } from "react-bootstrap"

const CheckUserForm = (props) => {

    return (

        <Row style={{ paddingTop: "18px" }} xs={3} >

            <Col md={3} style={{display:"flex"}}>
                <Form.Label>{props.field === 'atendenteGrupo' ? 'Atendente grupo': props.field}:</Form.Label>
            </Col>

            <Col md={9}>
                <Form.Check style={{ fontSize: "25px" }} checked={props.checked} name={props.field} value={props.value} onChange={props.handleChange} type={props.type} placeholder={props.placeholder} />
            </Col>

        </Row>

    );
}

export default CheckUserForm;