import { createContext, useContext, useEffect, useState, useCallback, useMemo } from "react";
import ApiUsers from "../../ApiUsers";
import { UserContext } from "../UserProvider";

export const GrupoContext = createContext();

export function GrupoWhatsProvider({ children }) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [error, setError] = useState(null);
  const { pref, socket, user } = useContext(UserContext);

  const countUnreadMessages = (messages) => {
    let count = [];
    if (!messages) return count;
    messages.forEach((msg) => {
      if (!msg.seenBy?.includes(user._id)) {
        count.push(msg);
      }
    });
    return count;
  };

  function compareMessages(messageA, messageB) {
    if (!messageA?.createdAt) return 1;
    if (!messageB?.createdAt) return -1;

    const dateA = new Date(messageA.createdAt);
    const dateB = new Date(messageB.createdAt);

    return dateB - dateA;
  }

  const loadMessageGroup = useCallback((groupId, messages) => {
    setGroups((prevGroups) => {
      const newGroup = prevGroups.find((g) => g.id_whatsapp === groupId);
      if (newGroup) {
        let newMessages = [...messages];
        let lastMessage = newMessages[newMessages.length - 1];
        newGroup.unseen = countUnreadMessages(newMessages);
        newGroup.allMessages = newMessages;
        newGroup.lastMessage = {
          message: lastMessage.message,
          createdAt: lastMessage.createdAt,
        };
      }
      const organizedGroups = prevGroups.sort((a, b) => compareMessages(a.lastMessage, b.lastMessage));
      return [...organizedGroups];
    });
  }, []);

  const memoizedLoadMessageGroup = useMemo(() => loadMessageGroup, []);

  const loadAllGroupsMessages = async () => {
    try {
      const response = await ApiUsers.post("/user/messagesWhatsGroup");
      if (response.data?.error) {
        setError(response.data.error);
      } else {
        response.data.forEach((group) => {
          memoizedLoadMessageGroup(group.between, group.messagesRoll);
        });
      }
    } catch (error) {
      console.error("Error carregando todas mensagens de grupos_whats:", error);
      throw error;
    }
  };

  const handleNewMessage = useCallback((data) => {
    const { group , msg } = data;
    setGroups((prevGroups) => {
      const newGroup = prevGroups.find((g) => g.id_whatsapp === group);
      if (newGroup) {
        let newMessages = newGroup.allMessages || [];
        newMessages = [...newMessages, msg];
        let lastMessage = newMessages[newMessages.length - 1];
        newGroup.unseen = countUnreadMessages(newMessages);
        newGroup.allMessages = newMessages;
        newGroup.lastMessage = {
          message: lastMessage.message,
          createdAt: lastMessage.createdAt,
        };
        let isNotify = newGroup?.atendentes?.find((atendente) => atendente._id === user._id);
        if(isNotify && msg.from !== user._id){
          let notify = document.getElementById("noti-sound");
          notify.play();
        }
      }
      const organizedGroups = prevGroups.sort((a, b) => compareMessages(a.lastMessage, b.lastMessage));
      return [...organizedGroups];
    });
  }, []);

  const initSocket = () => {
    console.log("initSocket GrupoWhatsProvider");
    socket.on("new grupo-whats", (group) => addNewGroup(group));
    socket.on("update grupo-whats", (groupId) => editGroupSocket(groupId));
    socket.on("delete grupo-whats", (groupId) => deleteGroupSocket(groupId));
    socket.on("bot new message group", (data) => handleNewMessage(data));
    socket.on("read whats", (data) => readAllMessages(data));
  };

  const offSocket = () => {
    socket.off("new grupo-whats");
    socket.off("update grupo-whats");
    socket.off("delete grupo-whats");
    socket.off("read whats");
  };

  const addNewGroup = useCallback(
    (group) => {
      if (group) {
        setGroups((prevGroups) => {
          return [...prevGroups, group];
        });
      }
    },
    [groups]
  );

  const editGroupSocket = useCallback(
    (group) => {
      if (group) {
        setGroups((prevGroups) => {
          const updatedGroups = prevGroups.map((g) => {
            if (g._id === group._id) {
              return group;
            }
            return g;
          });
          return updatedGroups;
        });
      }
    },
    [groups]
  );

  const deleteGroupSocket = useCallback(
    (groupId) => {
      if (groupId) {
        let newGroups = [...groups].filter((group) => group._id !== groupId);
        setGroups(newGroups);
      }
    },
    [groups]
  );

  const readAllMessages = useCallback((data) => {
    if (data) {
      const { contact, userId } = data;
      setGroups((prevGroups) => {
        const updatedGroups = prevGroups.map((g) => {
          if (g.id_whatsapp === contact) {
            let newMessages = g.allMessages.map((msg) => {
              if (!msg.seenBy?.includes(userId)) {
                msg.seenBy.push(userId);
              }
              return msg;
            });
            let lastMessage = newMessages[newMessages.length - 1];
            g.unseen = countUnreadMessages(newMessages);
            g.allMessages = newMessages;
            g.lastMessage = {
              message: lastMessage.message,
              createdAt: lastMessage.createdAt,
            };
          }
          return g;
        });
        return updatedGroups;
      });
    }
  }, []);

  const closeAlertModal = () => {
    setIsShowAlert(false);
    setAlertMessage("");
    setError(null);
  };

  // criar grupo
  const newGroup = async (groupData) => {
    setLoading(true);
    try {
      const response = await ApiUsers.post("/gruposWhats/create", {
        ...groupData,
      });
      if (response.data?.error) {
        setError(response.data.error);
        setIsShowAlert(true);
      } else {
        setAlertMessage("Grupo criado com sucesso");
        setIsShowAlert(true);
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error creating group:", error);
      throw error;
    }
  };

  //editar grupo
  const editGroup = async (groupData) => {
    setLoading(true);
    try {
      const response = await ApiUsers.post("/gruposWhats/update", {
        grupo: groupData,
      });
      if (response.data?.error) {
        setError(response.data.error);
        setIsShowAlert(true);
      } else {
        setAlertMessage("Grupo atualizado com sucesso");
        setIsShowAlert(true);
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error updating group:", error);
      throw error;
    }
  };

  //deletar grupo
  const deleteGroup = async (groupId) => {
    setLoading(true);
    try {
      const response = await ApiUsers.post("/gruposWhats/delete/", {
        grupo: { _id: groupId },
      });
      if (response.data?.error) {
        setError(response.data.error);
        setIsShowAlert(true);
      } else {
        setAlertMessage("Grupo deletado com sucesso");
        setIsShowAlert(true);
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error deleting group:", error);
      throw error;
    }
  };

  useEffect(() => {
    let voxbot = pref?.services?.voxbot;
    if (voxbot && voxbot.grupos_whats) {
      let nGroups = JSON.parse(JSON.stringify(voxbot.grupos_whats));
      setGroups(nGroups);
    }
  }, []);

  useEffect(() => {
    if (socket) initSocket();
    return () => {
      if (socket) offSocket();
    };
  }, [socket]);

  useEffect(() => {
    loadAllGroupsMessages();
  }, []);

  return (
    <>
      <GrupoContext.Provider
        value={{
          groups,
          setGroups,
          newGroup,
          editGroup,
          deleteGroup,
          isShowAlert,
          alertMessage,
          closeAlertModal,
          error,
        }}
      >
        {children}
      </GrupoContext.Provider>
    </>
  );
}
