import { useState, useEffect } from "react";
import { useContext } from "react";
import { PesqPrContext } from "../../../contexts/ChatBotProvider/PesqPrProvider";
import { UserContext } from "../../../contexts/UserProvider";
import { FaWindowClose } from "react-icons/fa";
import ButtonsFile from "../../miscelaneous/ButtonsFile/pesquisa/buttonsFile";
const Pesquisa = (props) => {
  const pesqPrContext = useContext(PesqPrContext);
  const context = useContext(UserContext);
  const votes = JSON.parse(JSON.stringify(pesqPrContext.votos));
  const [allVotes, setAllVotes] = useState(votes);
  const [totalVotes, setTotalVotes] = useState(0);
  const [atendentes, setAtendentes] = useState([]);
  const [options, setOptions] = useState([]);
  function close(e) {
    e.preventDefault();
    props.close(e);
  }
  useEffect(() => {
    let opts = [];
    props.pesquisa.alternativas.forEach((alternativa) => {
      alternativa.votos = [];
      opts.push(alternativa);
    });
    allVotes.forEach((vote) => {
      vote.att = context.pref.services.voxbot.contatos.find(
        (user) => user.telefone === vote.att
      );
      vote.att = vote.att ? vote.att.nome : vote.att;
      vote.participantes = vote.participantes?.map((uId) => {
        return (
          context.contacts.find((user) => user._id === uId)?.nome ||
          context.pref.services.voxbot.bots.find((bot) => bot._id === uId)?.nome
        );
      });
      vote.participantes = vote.participantes.filter((u) => u !== undefined);
      if (vote.pesquisa === props.pesquisa._id) {
        let opt = opts.find((opt) => opt.valor === vote.valor);
        opt?.votos.push(vote);
      }
    });
    let total = 0;
    opts.forEach((opt) => {
      total += opt.votos.length;
    });
    setTotalVotes(total);
    setOptions(opts);
    console.log("opts", opts);
  }, [allVotes]);

  useEffect(() => {
    let votes = JSON.parse(JSON.stringify(pesqPrContext.votos));
    setAllVotes(votes);
  }, [pesqPrContext.votos]);

  useEffect(() => {
    let atendentes = context.contacts.filter((u) => u.atendentes);
    setAtendentes(atendentes);
  }, []);

  const breakLines = (text) => {
    let newText = text.split("\n").map((item, i) => {
      return <p key={i}>{item}</p>;
    });
    return newText;
  };

  return (
    <div className="pesquisa-show">
      <FaWindowClose className="button-close" onClick={close} size={40} />
      <div className="pesquisa-show-header">
        <h1>{props.pesquisa.nome}</h1>
        <u style={{ fontSize: "2.2em", margin: 30 }}>
          Resultado da pesquisa: {props.pesquisa.nome}
        </u>
        <u style={{ fontSize: "2.2em", margin: 30 }}>
          Total de votos: {totalVotes}
        </u>
        <u style={{ fontSize: "2.2em", margin: 30 }}>Pesquisa:</u>
        <div className="pesquisa-show-question">
          <h2>{breakLines(props.pesquisa.mensagem)}</h2>
        </div>
      </div>
      <div>
        <ButtonsFile
          title={props.pesquisa.nome}
          mensagem={props.pesquisa.mensagem}
          data={options}
        />
      </div>

      <table className="table-votes">
        <thead>
          <tr className="th-option">
            <th style={{ width: "30%" }}>Valor da Opção</th>
            <th style={{ width: "30%" }}>Quantidade de Votos</th>
            <th style={{ width: "30%" }}>Percentual</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, i) => (
            <MyOption
              key={i}
              option={option}
              goToProtocols={props.goToProtocols}
              totalVotes={totalVotes}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

function MyOption(props) {
  const [detailsOnShow, setDetailsOnShow] = useState(false);
  return (
    <>
      <tr
        onClick={(e) => setDetailsOnShow(!detailsOnShow)}
        className="tr-option"
      >
        <td className="td-vote">{props.option.valor}</td>
        <td className="td-vote">{props.option.votos.length}</td>
        <td className="td-vote">
          {((props.option.votos.length / props.totalVotes) * 100).toFixed(2)}%
        </td>
      </tr>
      {detailsOnShow && (
        <Details goToProtocols={props.goToProtocols} option={props.option} />
      )}
    </>
  );
}
function Details(props) {
  function moveToProtocolosAndSetStorage(pr) {
    props.goToProtocols(pr);
  }
  return (
    <tr className="tr-details">
      <td>
        <div className="pesquisa-show-body">
          <table className="table-details">
            <thead style={{ width: "100%" }}>
              <tr className="th-details">
                <th style={{ width: "50%" }}>Participantes de Atendimento</th>
                <th style={{ width: "50%" }}>Protocolo</th>
              </tr>
            </thead>
            <tbody>
              {props.option.votos.map((voto, i) => {
                return (
                  <tr key={i}>
                    <td>{voto.participantes?.join(", ")}</td>
                    <td
                      onClick={(e) => {
                        moveToProtocolosAndSetStorage(voto.protocolo);
                      }}
                      style={{ cursor: "pointer", fontSize: "1.6rem" }}
                    >
                      {voto.protocolo}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
}

export default Pesquisa;
