import React from "react";
import { BsPlus } from "react-icons/bs";
import "./componentMessages.css";

function MessageDefault({ props, defaultSchema, addDefaultMessage }) {
  const { defaultMsg, setDefaultMsg } = props;

  return (
    <div className="bot-form-input-default-message-msg">
      <div className="container-content-msg">
        <label>
          <h5>Mensagem</h5>
        </label>
        <div className="container-align-textarea-button">
          <textarea
            type="text"
            className="bot-form-input-msg"
            placeholder="Digite aqui a mensagem padrão"
            value={defaultMsg.message}
            onChange={(e) =>
              setDefaultMsg({
                title: defaultMsg.title,
                message: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="container-content-msg-select">
        <label>
          <h5>Selecione o tipo</h5>
        </label>
        <select
          className="bot-form-select-msg"
          value={defaultMsg.title}
          onChange={(e) =>
            setDefaultMsg({
              title: e.target.value,
              message: defaultMsg.message,
            })
          }
        >
          <option value="">Escolha o tipo</option>
          {defaultSchema.map((msg, index) => (
            <option key={index} value={msg.title}>
              {msg.text.replace("Mensagens de", "")}
            </option>
          ))}
        </select>
        <button
          title="Clique e adicione a nova mensagem padrão"
          onClick={(e) => addDefaultMessage(e)}
        >
          Adicionar
        </button>
      </div>
    </div>
  );
}

export default MessageDefault;
