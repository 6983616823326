import { useEffect, useState } from "react";
import { ConviteBot } from "./types/ConviteBot/index";
import { ConviteEmail } from "./types/conviteEmail";

export const EnvioConvite = ({ conviteSala, isEditMsg }) => {
  const [opcaoSelecionada, setOpcaoSelecionada] = useState("email");
  const [conviteReuniao, setConviteReuniao] = useState({});

  const tipoConvite = {
    email: (
      <ConviteEmail
        conviteReuniao={conviteReuniao}
        isEditMsg={isEditMsg}
      />
    ),
    bot: (
      <ConviteBot
        conviteReuniao={conviteReuniao}
        isEditMsg={isEditMsg}
      />
    ),
  };

  const handleOpcaoChange = (e) => {
    setOpcaoSelecionada(e.target.id);
  };

  useEffect(() => {
    const novoConviteReuniao = conviteSala ? conviteSala : {};
    setConviteReuniao(novoConviteReuniao);
  }, [conviteSala]);

  return (
    <>
      <div className="convite-sala-form-tipo-convite-header">
        <ul className="convite-sala-form-tipo-convite-menu">
          <li
            id="email"
            className={opcaoSelecionada === "email" ? "tipo-convite-ativo" : ""}
            onClick={handleOpcaoChange}>
            Email
          </li>
          <li
            id="bot"
            className={opcaoSelecionada === "bot" ? "tipo-convite-ativo" : ""}
            onClick={handleOpcaoChange}>
            Bot
          </li>
        </ul>
        <h2>Envio de convite</h2>
      </div>
      {opcaoSelecionada && <>{tipoConvite[opcaoSelecionada]}</>}
    </>
  );
};
