import { useState, useContext, useEffect } from "react";
import { Section } from "./Section";
import { QSGAgendaContext } from "../../../../../contexts/QSGProvider/QSGAgendaProvider";
import "./styles.css";

export const Accordion = ({ data, accordionSections, firstSectionKey, callback, itemFunction }) => {
  const { setAccordionParentSection, setAccordionSubSection } = useContext(QSGAgendaContext);
  const [activeIndexes, setActiveIndexes] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [sectionKey, setSectionKey] = useState("");
  const [parentIndexState, setParentIndexState] = useState(null);
  const [indexState, setIndexState] = useState(null);

  const toggleSection = (parentIndex, index, key) => {
    const firstIndex = `${parentIndex}${index}`;
    const dataFirstIndex = data[firstIndex];
    const dataParentIndex = data[parentIndex];
    if (dataFirstIndex) setAccordionParentSection(dataFirstIndex);
    if (dataParentIndex) setAccordionParentSection(dataParentIndex);
    if (key) setSectionKey(key);
    setActiveIndexes({
      ...activeIndexes,
      [parentIndex]: activeIndexes[parentIndex] === index ? null : index,
    });
    setParentIndexState(parentIndex);
    setIndexState(index);
    setActiveMenu(null);
  };

  useEffect(() => {
    if (data[parentIndexState] && data[parentIndexState][sectionKey]) setAccordionSubSection(data[parentIndexState][sectionKey][indexState]);
  }, [parentIndexState, indexState]);

  const renderAccordionItems = (items, itemConfig, parentIndex = "") => {
    return items.map((item, index) => {
      const currentIndex = `${parentIndex}${index}`;
      const isActive = activeIndexes[parentIndex] === index;
      const isMenuOpen = activeMenu === currentIndex;
      const hasSubItems = Object.keys(accordionSections).some((key) => item[key]) || (itemConfig.items && itemConfig.items.some((itemKey) => item[itemKey.key]));
      return (
        <Section
          key={currentIndex}
          itemFunction={itemFunction}
          item={item}
          itemConfig={itemConfig}
          isActive={isActive}
          isMenuOpen={isMenuOpen}
          callback={callback}
          index={index}
          sectionName={parentIndex === "" ? "" : itemConfig.label}
          onToggle={() => hasSubItems && toggleSection(parentIndex, index, itemConfig.key)}
          onToggleMenu={() => setActiveMenu(isMenuOpen ? null : currentIndex)}
          hasSubItems={hasSubItems}>
          {isActive &&
            Object.keys(accordionSections).map((key) => {
              if (item[key]) {
                return renderAccordionItems(item[key], accordionSections[key], `${currentIndex}`);
              }
              return null;
            })}
        </Section>
      );
    });
  };

  return <div className="accordion-container">{renderAccordionItems(data, accordionSections[firstSectionKey])}</div>;
};
