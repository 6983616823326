import { FaTrash, FaEdit } from "react-icons/fa";

export const accordionSections = {
  especialidades: {
    label: "Especialidades",
    actions: [
      {
        label: "Editar",
        action: (item, callback) => {
          callback(item, "editar");
        },
        icon: <FaEdit />,
      },
      {
        label: "Excluir",
        action: (item, callback) => {
          callback(item, "excluir");
        },
        icon: <FaTrash />,
      },
    ],
    keys: [
      {
        label: "Nome",
        key: "nome",
      },
    ],
    items: [
      {
        label: "Profissionais",
        key: "profissionais",
        splitArray: false,
        function: true,
      },
    ],
  },
};
