import { createContext, useState, useEffect, useContext, useCallback } from "react";
import { UserContext } from "../UserProvider";
import ApiUsers from "../../ApiUsers";

export const TemporizadorContext = createContext();

export const TemporizadorProvider = ({ children }) => {
    const [temporizadores, setTemporizadores] = useState([]);
    const userContext = useContext(UserContext);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [loading, setLoading] = useState(false);
    const socket = userContext.socket;

    const checkTemporizador = (temp) => {
        const isAdm = userContext.user?.admin;
        let newToName = null;
        if (isAdm || temp.from === userContext.user?._id) {
            if (temp.type === 'private') {
                newToName = userContext.pref?.users.find(user => user._id === temp.to)?.nome;
            } else if (temp.type === 'group') {
                newToName = userContext?.pref?.services?.voxchat?.grupos.find(grupo => grupo._id === temp.to)?.nome;
            } else if (temp.type === 'att'){
                newToName = userContext.pref?.services?.voxbot?.contatos.find(contato => contato.telefone === temp.to)?.Nome || temp.pushname || temp.nome;
            }else{
                newToName = userContext.pref?.services?.voxbot?.grupos_whats?.find(grupo => grupo._id === temp.to)?.nome;
            }
            temp.toName = newToName;
            temp.createdBy = userContext.pref?.users.find(user => user._id === temp.from)?.nome;
            setTemporizadores(prevTemporizadores => {
                if (prevTemporizadores.find(t => t._id === temp._id)) {
                    return prevTemporizadores.map(t => {
                        if (t._id === temp._id) {
                            return temp
                        }
                        return t
                    })
                } else {
                    return [...prevTemporizadores, temp]
                }
            });

        }
    }


    useEffect(() => {
        if (userContext.pref) {
            let temps = userContext.pref?.services?.voxbot?.temporizador
            if (temps) {
                temps.forEach(temp => {
                    checkTemporizador(temp)
                });
            }
        }
    }, [])

    const initSocket = () => {
        console.log("init Socket Temporizador");
        socket.on("new timer", (temp) => addNewTemp(temp));
        socket.on("update timer", (temp) => updateTemp(temp));
        socket.on("delete timer", (temp) => deleteTemp(temp));
    };

    const offSocket = () => {
        socket.off("new timer");
        socket.off("update timer");
        socket.off("delete timer");
    };

    const addNewTemp = useCallback((temp) => {
        checkTemporizador(temp)
    }, [temporizadores]);

    const updateTemp = useCallback((temp) => {
        checkTemporizador(temp)
    }, []);

    const deleteTemp = useCallback((temp) => {
        setTemporizadores(prevTemporizadores => prevTemporizadores?.filter(t => t._id !== temp))
    }, []);

    useEffect(() => {
        if (socket) initSocket();
        return () => {
            if (socket) offSocket();
        };
    }, [socket]);

    const deleteTemporizador = (temp) => {
        let newTemp = { ...temp }
        ApiUsers.post('/temporizador/delete', { temporizador: newTemp }).then((resp) => {
            if (resp.data.error) {
                setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: resp.data.error } })
                return
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const createTemporizador = async (data, contato, type) => {
        setLoading(true)
        if (data.files.length > 0) {
            let newFiles = await Promise.all(data.files.map(async (file) => {
                return await handleUrlFiles(file);
            }));
            data.files = newFiles;
        }
        if (data.audio) {
            let newAudio = handleUrlFiles(data.audio)
            data.audio = await newAudio
        }
        let newTemporizador = {
            to: type === 'att' ? contato.telefone : contato._id,
            type: type,
            ...data
        }

        if (type === 'att' && newTemporizador.bot === '') {
            setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: "Selecione um bot" } })
            setLoading(false)
            return
        }

        if (newTemporizador.data === '' || newTemporizador.hora === '') {
            setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: "Preencha data e hora" } })
            setLoading(false)
            return
        } else if (newTemporizador.message === '' && newTemporizador.file === '' && newTemporizador.audio === '') {
            setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: "Escreva uma mensagem ou envie um arquivo" } })
            setLoading(false)
            return
        } else {
            ApiUsers.post('/temporizador/create', { temporizador: newTemporizador }).then((res) => {
                setLoading(false)
                if (res.data.error) {
                    console.log(res.data.error)
                    setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: res.data.error } })
                    return
                }
            }).catch((err) => { console.log(err) })
        }

    }


    const updateTemporizador = async (temp) => {
        let newTemp = { ...temp }

        if (!newTemp?.audio?.url) {
            let newAudio = await handleUrlFiles(newTemp.audio)
            newTemp.audio = newAudio
        }

        if (newTemp.files.length > 0) {
            let newFiles = await Promise.all(newTemp.files.map(async (file) => {
                console.log(file)
                if (file.updated === true) {
                    return file
                } else {
                    return await handleUrlFiles(file);
                }
            }));
            newTemp.files = newFiles;
        }
        if (!newTemp.audio) newTemp.audio = ''
        if (!newTemp.files) newTemp.files = ''
        if (!newTemp.message) newTemp.message = ''
        ApiUsers.post('/temporizador/update', { temporizador: newTemp }).then((resp) => {
            if (resp.data.error) {
                setAlert({ visible: true, type: "normal", props: { title: "Erro", placeholder: resp.data.error } })
                return
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleUrlFiles = async (midia) => {
        if (!midia) return
        let formData = new FormData()
        formData.append('file', midia)
        let res = await ApiUsers.post('/upload', formData).catch(err => {
            console.log(err)
        })
        if (res.data.error) {
            console.log(res.data.error)
            return
        } else {
            return res.data
        }
    }



    return (
        <TemporizadorContext.Provider
            value={{
                temporizadores,
                setTemporizadores,
                alert,
                setAlert,
                deleteTemporizador,
                createTemporizador,
                updateTemporizador,
                loading,
            }}
        >
            {children}
        </TemporizadorContext.Provider>
    );
};
