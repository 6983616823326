import { useState, useEffect } from "react";
import Select from "react-select";
import { ProfissionalForm } from "../ProfissionalForm";
import { Funcionamento } from "./horarioFuncionamento";
import { ProfissionalCard } from "../ProfissionalCard";
import "./style.css";

export const EspecialidadeForm = (props) => {
  const [profissionalSelecionado, setProfissionalSelecionado] = useState({});
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState(
    []
  );
  const [profissionais, setProfissionais] = useState([]);
  const [especialidade, setEspecialidade] = useState({
    _id: "",
    nome: "",
    horarioFuncionamento: {
      inicio: "",
      fim: "",
      dias: [],
    },
    profissionais: [],
    limiteVagas: "",
  });
  const [editandoProfissional, setEditandoProfissional] = useState(false);
  const [profissionaisEspecialidade, setProfissionaisEspecialidade] = useState(
    []
  );

  useEffect(() => {
    if (especialidade.profissionais) {
      // const profissionaisAlocadosIds = especialidade.profissionais.map(
      //   (profissional) => profissional._id
      // );
      // const profissionaisAlocados = profissionais.filter((profissional) =>
      //   profissionaisAlocadosIds.includes(profissional._id)
      // );
      setProfissionaisSelecionados(especialidade.profissionais);
    }
  }, [especialidade.profissionais, profissionais]);

  useEffect(() => {
    const novaEspecialidade = { ...props.especialidadeSelecionada };
    setEspecialidade(novaEspecialidade);
  }, [props.profissionaisEspecialidade]);

  useEffect(() => {
    const novosProfissionais = [...props.profissionais];
    setProfissionais(novosProfissionais);
  }, [props.profissionais]);

  useEffect(() => {
    const novosProfissionaisEspecialidade = [
      ...props.profissionaisEspecialidade,
    ];
    setProfissionaisEspecialidade(novosProfissionaisEspecialidade);
  }, [props.profissionaisEspecialidade]);

  const onChangeProfissional = (selectedOption) => {
    setProfissionalSelecionado(selectedOption);
  };

  useEffect(() => {
    const profEspecialidade = profissionais.filter((prof) =>
      profissionaisEspecialidade.includes(prof._id)
    );

    const profissionalAlocado = especialidade.profissionais?.map(
      (prof) => prof._id
    );

    if (profissionalAlocado) {
      const profAlocadoOptions = profEspecialidade.filter(
        (prof) => !profissionalAlocado.includes(prof._id)
      );

      props.setOptionsProfissionais(
        profAlocadoOptions.map((prof) => ({
          value: prof._id,
          label: prof.nome,
        }))
      );
    }
  }, [
    profissionais,
    profissionaisEspecialidade,
    props.setOptionsProfissionais,
    especialidade.profissionais,
  ]);

  const addProfissional = () => {
    const prof = profissionais.find(
      (p) => p._id === profissionalSelecionado?.value
    );
    if (!prof) return;
    let { _id, nome, credenciais } = { ...prof };
    let newProf = { _id, nome, credenciais, label: nome, value: _id };
    newProf.horarioIntervalo = [];
    newProf.espacoAgendamento = "00:30";
    newProf.periodoAtendimento = {
      inicio: "",
      fim: "",
    };
    const updatedOptions = props.optionsProfissionais.filter(
      (option) => option.value !== profissionalSelecionado.value
    );
    setProfissionalSelecionado(null);
    props.setOptionsProfissionais(updatedOptions);
    setProfissionaisSelecionados([...profissionaisSelecionados, newProf]);
  };

  const handleBackEspecialidade = () => {
    props.setEditandoEspecialidade(false);
    props.setEspecialidadeSelecionada(null);
    setProfissionalSelecionado(null);
    setProfissionaisSelecionados([]);
  };

  const salvarEspecialidade = (e) => {
    e.preventDefault();
    const novaEspecialidade = { ...especialidade };
    novaEspecialidade.profissionais = [...profissionaisSelecionados];
    setEspecialidade(novaEspecialidade);

    props.atualizarEspecialidade(novaEspecialidade);
    atualizarProfissional(novaEspecialidade.profissionais);

    props.setEditandoEspecialidade(false);

    setProfissionalSelecionado(null);
    props.setEspecialidadeSelecionada(null);
  };

  const atualizarProfissional = (novoProfissional) => {
    let prevProfissionais = [...profissionaisSelecionados];
    let newProf = true;
    prevProfissionais.map((prof) => {
      if (prof._id === novoProfissional._id) {
        prof.horarioIntervalo = novoProfissional.horarioIntervalo;
        prof.espacoAgendamento = novoProfissional.espacoAgendamento;
        prof.periodoAtendimento = novoProfissional.periodoAtendimento;
        newProf = false;
      }
      return prof;
    });
    if (newProf) prevProfissionais.push(novoProfissional);
    setProfissionaisSelecionados(prevProfissionais);

    setEditandoProfissional(false);
    setProfissionalSelecionado(null);
  };

  const onChangeLimiteVagas = (selectedOption) => {
    setEspecialidade((prevState) => ({
      ...prevState,
      limiteVagas: selectedOption.value,
    }));
  };

  const optionsLimiteVagas = [
    { value: "0", label: "Sem limite" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  return (
    <>
      {editandoProfissional && (
        <div className="bg-modal-local-especialidade"></div>
      )}

      <form
        className="especialidade-local-form"
        style={
          profissionaisSelecionados?.length > 0
            ? { height: "95%" }
            : { height: "65%" }
        }
      >
        <div className="especialidade-local-form-title">
          {especialidade.nome}
        </div>
        <div className="especialidade-local-form-content">
          <div className="especialidade-local-form-input-wrapper-horario">
            <Funcionamento
              especialidade={especialidade}
              setEspecialidade={setEspecialidade}
            />
          </div>
          <div className="especialidade-local-form-input-container">
            <label htmlFor="limiteVagas" className="required-prof">
              Limite de vagas por pessoa
            </label>
            <Select
              id="limiteVagas"
              name="limiteVagas"
              value={
                especialidade.limiteVagas
                  ? optionsLimiteVagas.find(
                      (option) => option.value == especialidade.limiteVagas
                    )
                  : null
              }
              onChange={onChangeLimiteVagas}
              options={optionsLimiteVagas}
              placeholder="Selecione..."
              isSearchable={false}
            />
          </div>
          <div className="especialidade-local-form-input-container">
            <label className="required-prof">Profissionais</label>
            <div className="especialidade-local-form-input-container select">
              <Select
                className={"especialidade-react-select"}
                options={props.optionsProfissionais}
                value={
                  profissionalSelecionado
                    ? props.optionsProfissionais.find(
                        (option) => option.value === profissionalSelecionado
                      )
                    : null
                }
                onChange={onChangeProfissional}
                placeholder={
                  props.optionsProfissionais.length === 0
                    ? "Sem Profissionais"
                    : "Selecione..."
                }
                noOptionsMessage={() => null}
              />
              <button
                className={
                  profissionalSelecionado == null ||
                  Object.keys(profissionalSelecionado).length == 0
                    ? "add-profissional-btn-disabled"
                    : "add-profissional-btn"
                }
                onClick={addProfissional}
                type="button"
              >
                <span>Adicionar</span>
              </button>
            </div>
          </div>
          <div className="button-container">
            <button id="back" type="button" onClick={handleBackEspecialidade}>
              Voltar
            </button>
            <button type="submit" id="add" onClick={salvarEspecialidade}>
              Salvar
            </button>
          </div>
        </div>
        {profissionaisSelecionados?.length > 0 && (
          <>
            <div className="profissionais-adicionados-title">
              Profissionais Adicionados
            </div>
            <div className="cards-profissionais-container">
              <ProfissionalCard
                profissionaisSelecionados={profissionaisSelecionados}
                setProfissionaisSelecionados={setProfissionaisSelecionados}
                profissionalSelecionado={profissionalSelecionado}
                setProfissionalSelecionado={setProfissionalSelecionado}
                setEditandoProfissional={setEditandoProfissional}
                optionsProfissionais={props.optionsProfissionais}
                setOptionsProfissionais={props.setOptionsProfissionais}
              />
            </div>
          </>
        )}
      </form>

      {editandoProfissional && (
        <ProfissionalForm
          atualizarProfissional={atualizarProfissional}
          profissionalSelecionado={profissionalSelecionado}
          setEditandoProfissional={setEditandoProfissional}
          setProfissionalSelecionado={setProfissionalSelecionado}
          especialidadeSelecionada={especialidade}
        />
      )}
    </>
  );
};
