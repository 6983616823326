import React, { useState, useEffect } from 'react'
// import { FiMinimize2 } from 'react-icons/fi';

export default function Image(props) {

    const { data, message, justi, contactColor, } = props
    const [files, setFiles] = useState([])
    const [file, setFile] = useState({})
    const [maximize, setMaximize] = useState(false)
    const [selectedImg, setSelectedImg] = useState(null)

    useEffect(() => {
        let files = data.files
        setFiles(files)
    }, [data])

    const maxi = (url) => {
        setMaximize(true)
        setSelectedImg(url)
    }

    const minimizeImg = (e) => {
        if (e.target.classList.contains('image-modal')) {
            setMaximize(false)
        }
    }

    const openNewTab = (url) => {
        window.open(url, '_blank')
    }

    return (
        <>
            {
                files.map((file, index) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',margin: 5 }} key={index}>
                            <img
                                src={file.url}
                                style={{ width: 270, borderRadius: 7, cursor: 'pointer', marginBottom: 5 }}
                                alt="sent"
                                onClick={() => openNewTab(file.url)} />
                        </div>
                    )
                })
            }
            {maximize ? <>
                <div className="image-modal" onClick={e => minimizeImg(e)}>
                    <img src={selectedImg} className="image-modal-img" alt="" />
                </div>
            </> : null}
        </>

    )
}
