import React from "react";
import ContactItem from "../../ContactItem/ContactItem";

export default function RenderBody({ importedNumbers }) {
  return (
    <div className="container-contacts-xls">
      <h5 className="title-xls">Contatos importados</h5>
      <div className="container-unique-scroll">
        {importedNumbers?.map((contact, index) => (
          <ContactItem key={index} contact={contact} />
        ))}
      </div>
    </div>
  );
}
