import { FaTrash } from "react-icons/fa";
import BotStatus from "./BotStatus";
import BotDetails from "./BotDetails";
import QrCodeContainer from "./QrCodeContainer";
import BotButtons from "./BotButtons";
import "../componentsBotArea.css";

const BotItem = ({
  bot,
  flows,
  endpoint,
  deleteModal,
  selectBot,
  showModal,
  changeBotStatus,
  loading,
}) => {
  const flow = flows.find((f) => f._id === bot.flow)?.nome;
  const primeiroFlow = flows.find((f) => f._id === bot.primeiroFlow)?.nome;

  return (
    <div className="bot-item">
      {(bot.status === "inativo" || bot.status === "esperando") && (
        <div className="bot-item-trash-icon">
          <FaTrash
            className="trash-icon"
            title="Excluir"
            size={20}
            onClick={(e) => deleteModal(e, bot)}
          />
        </div>
      )}
      <div className="container-bot-align-title">
        <h4 className="bot-title">{bot.nome}</h4>
        <BotStatus status={bot.status} />
      </div>
      <BotDetails bot={bot} flow={flow} primeiroFlow={primeiroFlow} />
      <QrCodeContainer bot={bot} endpoint={endpoint} />
      <BotButtons
        bot={bot}
        loading={loading}
        selectBot={selectBot}
        showModal={showModal}
        changeBotStatus={changeBotStatus}
      />
    </div>
  );
};

export default BotItem;
