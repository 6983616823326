import { useContext } from "react";
import { VideoContext } from "../../../contexts/VideoProvider";
import { UserContext } from "../../../contexts/UserProvider";
import "../styles.css";

export const MinhaSala = () => {
  const { linkMinhaSala } = useContext(VideoContext);
  const { user } = useContext(UserContext);

  return (
    <div className="sala-container">
      <h1>
        Olá,<strong> {user.nome} !</strong>
      </h1>
      <p>Compartilhe o link da sua sala e converse com outros participantes</p>
      <div className="link-container">
        <strong>Link da sala :</strong>
        <a href={linkMinhaSala} target="_blank" rel="noreferrer">
          {linkMinhaSala}
        </a>
      </div>
      <img src={"./imgs/video_conf_icon2.svg"} alt="video-conferencia" />
    </div>
  );
};
