import React, { useContext, useEffect, useState } from "react";
import MainView from "../main/MainView";
import LeftCol from "../main/LeftCol";
import RightCol from "../main/RightCol";
import Groups from "./general/Groups";
import Messagersender from "./general/Messagersender";
import MessageTitle from "./general/MessageTitle";
import { UserContext } from "../../contexts/UserProvider";
import MessageScreen from "./general/MessageScreen";
import { GroupContext } from "../../contexts/ChatBotProvider/GroupProvider";
import { ReplyForwardingContext } from "../../contexts/ReplyForwardingProvider";
import { MessageContext } from "../../contexts/ChatBotProvider/MessageProvider";
import EmptyMessages from "./general/utils/EmptyMessages";
import "react-toastify/dist/ReactToastify.css";
import "./chat.css";

const Chatgroup = () => {
  const [selectedGroup, setSelectedGroup] = useState(false);
  const { groups, socket } = useContext(GroupContext);
  const { user } = useContext(UserContext);
  const { replyMessage, setReplyMessage } = useContext(ReplyForwardingContext);
  const { setLookingMessage,setOnSearch } = useContext(MessageContext);

  useEffect(() => {
    if (selectedGroup) {
      let newSel = groups.find((att) => att._id === selectedGroup._id);
      setSelectedGroup({ ...newSel });
    }
  }, [groups]);

  const handleGroupSelect = (e, group) => {
    setSelectedGroup(group);
    setReplyMessage(null);
    setLookingMessage(null);
    setOnSearch(false);
  };
  useEffect(() => {
    setReplyMessage(null);
  }, []);

  return (
    <MainView>
      <LeftCol title="Grupos">
        <Groups
          socket={socket}
          selected={selectedGroup}
          onSelect={handleGroupSelect}
        />
      </LeftCol>
      <RightCol>
        {!selectedGroup ? (
          <EmptyMessages
            nome={user?.nome}
            imgClass="welcome-img-empty-chat"
            image="/imgs/group-welcome.png"
            message="Selecione um grupo ao lado para iniciar uma conversa"
          />
        ) : (
          <React.Fragment>
            <MessageTitle
              msgType="group"
              socket={socket}
              contact={selectedGroup}
            />
            <MessageScreen msgType="group" contact={selectedGroup} />
            <Messagersender msgType="group" contact={selectedGroup} />
          </React.Fragment>
        )}
      </RightCol>
    </MainView>
  );
};

export default Chatgroup;
