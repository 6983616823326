import { useState, useEffect } from "react";
import { SelectDate } from "./selectDate";

export const HorarioReuniao = ({
  conviteSala,
  setConviteSala,
  mensagemConvitePadrao,
  isEditMsg,
}) => {
  const [horarioReuniao, setHorarioReuniao] = useState({
    data: new Date(),
    duracao: "60",
  });
  const [msgConvite, setMsgConvite] = useState("");

  const optionsDuracao = [
    { value: "5", label: "5 minutos" },
    { value: "10", label: "10 minutos" },
    { value: "15", label: "15 minutos" },
    { value: "30", label: "30 minutos" },
    { value: "45", label: "45 minutos" },
    { value: "60", label: "1 hora" },
    { value: "90", label: "1 hora e 30 minutos" },
    { value: "120", label: "2 horas" },
    { value: "180", label: "3 horas" },
    { value: "240", label: "4 horas" },
    { value: "300", label: "5 horas" },
    { value: "360", label: "6 horas" },
    { value: "720", label: "12 horas" },
  ];

  const updateMensagemDuracao = (novaDuracao) => {
    const { label } = optionsDuracao.find(
      (opcao) => opcao.value === novaDuracao
    );
    const novaMensagem = msgConvite.replace(
      /Duração: (.*)\n/,
      `Duração: ${label}\n`
    );
    setConviteSala({
      ...horarioReuniao,
      data: horarioReuniao.data,
      duracao: novaDuracao,
      mensagem: novaMensagem,
    });
  };

  const formatarData = (data) => {
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    return `${dia}/${mes}/${ano} às ${horas}:${minutos}h`;
  };

  const updateMensagemDate = (novoData) => {
    const dataFormatada = formatarData(new Date(novoData));
    const novaMensagem = msgConvite.replace(
      /Data de início: (.*)\n/,
      `Data de início: ${dataFormatada}\n`
    );
    setConviteSala({
      ...horarioReuniao,
      data: novoData,
      duracao: horarioReuniao.duracao,
      mensagem: novaMensagem,
    });
  };

  const onChangeData = (event) => {
    const { value } = event.target;
    const isMsgEqualDefault = conviteSala.mensagem === mensagemConvitePadrao;
    if (isMsgEqualDefault) {
      setConviteSala({
        ...horarioReuniao,
        data: value,
      });
      updateMensagemDate(value);
    } else {
      setHorarioReuniao({
        ...horarioReuniao,
        data: value,
      });
    }
  };

  const onChangeDuracao = (event) => {
    const { value } = event.target;
    const isMsgEqualDefault = conviteSala.mensagem === mensagemConvitePadrao;
    if (isMsgEqualDefault) {
      setConviteSala({
        ...horarioReuniao,
        duracao: value,
      });
      updateMensagemDuracao(value);
    } else {
      setHorarioReuniao({
        ...horarioReuniao,
        duracao: value,
      });
    }
  };

  useEffect(() => {
    const novaMsgConvite = mensagemConvitePadrao;
    setMsgConvite(novaMsgConvite);
  }, [mensagemConvitePadrao]);

  useEffect(() => {
    const { data, duracao } = conviteSala;
    const novoHorarioReuniao = {
      ...conviteSala,
      data,
      duracao,
    };
    setHorarioReuniao(novoHorarioReuniao);
  }, [conviteSala]);

  return (
    <div className="convite-sala-form-input-container-wrapper">
      <div className="convite-sala-form-input-container">
        <label id="data">Data</label>
        <SelectDate
          onChange={onChangeData}
          value={horarioReuniao.data}
          isEditMsg={isEditMsg}
        />
      </div>
      <div className="convite-sala-form-input-container">
        <label id="duracao" className="label-imput">
          Duração
        </label>
        <select
          name="duracao"
          id="duracao"
          className="convite-sala-form-select"
          disabled={isEditMsg}
          onChange={onChangeDuracao}
          value={horarioReuniao.duracao}
        >
          <option value="" disabled>
            Selecione a duração
          </option>
          {optionsDuracao.map((duracao, index) => (
            <option key={index} value={duracao.value}>
              {duracao.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
