import { useState, useEffect, useContext } from "react";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { LocalForm } from "./LocalForm";
import ApiUsers from "../../../ApiUsers";
import "./style.css";
import { LocalCard } from "./LocalCard";
import { PesquisaLocais } from "../Pesquisa/PesquisaLocais";

export const Local = () => {
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [selecionado, setSelecionado] = useState(null);
  const [editando, setEditando] = useState(false);

  const [locaisFiltrados, setLocaisFiltrados] = useState([]);

  const editLocal = (local) => {
    setSelecionado(local);
    setEditando(true);
  };

  const deleteLocal = (local) => {
    ApiUsers.post("/locais/delete", { local });
  };

  const updateLocal = (local) => {
    ApiUsers.post("/locais/update", {
      local,
    });
    setSelecionado(null);
    setEditando(false);
  };

  const locaisFiltradosUnicos = locaisFiltrados.reduce((acc, local) => {
    const localJaExiste = acc.find(
      (localUnico) => localUnico._id === local._id
    );
    if (!localJaExiste) {
      return [...acc, local];
    }
    return acc;
  }, []);

  useEffect(() => {
    const novosProfissionais = [...professionalContext.profissionais];
    setProfissionais(novosProfissionais);

    const novosServicos = [...serviceContext.servicos];
    setServicos(novosServicos);

    const novasEspecialidades = [...specialtyContext.especialidades];
    setEspecialidades(novasEspecialidades);

    const novosLocais = [...locationContext.locais];
    setLocais(novosLocais);
  }, [
    locationContext.locais,
    professionalContext.profissionais,
    serviceContext.servicos,
    specialtyContext.especialidades,
  ]);

  return (
    <div className="local-container">
      {selecionado && <div className="bg-modal"></div>}
      <div className="agenda-header-container">
        <div className="local-title">LOCAIS</div>
        <button
          className="btn-add"
          onClick={() =>
            setSelecionado({
              _id: "",
              nome: "",
              endereco: { rua: "" },
              fone: "",
              especialidades: [],
              timeToStart: "",
            })
          }
        >
          NOVO LOCAL
        </button>
      </div>
      <PesquisaLocais
        profissionais={profissionais}
        especialidades={especialidades}
        servicos={servicos}
        locais={locais}
        setLocaisFiltrados={setLocaisFiltrados}
      />
      {locaisFiltradosUnicos.length > 0 ? (
        <div className="local-content">
          <div className="card-container">
            {locaisFiltradosUnicos.map((local, index) => (
              <LocalCard
                key={index}
                local={local}
                editLocal={editLocal}
                deleteLocal={deleteLocal}
              />
            ))}
            <div className="card-local flex-dummy"></div>
            <div className="card-local flex-dummy"></div>
            <div className="card-local flex-dummy"></div>
          </div>
        </div>
      ) : (
        <div className="locais-vazio">SEM LOCAIS</div>
      )}
      {selecionado && (
        <LocalForm
          local={selecionado}
          setSelecionado={setSelecionado}
          updateLocal={updateLocal}
          setEditando={setEditando}
          editando={editando}
          especialidades={especialidades}
          profissionais={profissionais}
        />
      )}
    </div>
  );
};
