import React from "react";
import { BsCheckCircle, BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function ButtonsFormComands({ props }) {
  return (
    <div className="cont-buttons-bot-actions">
      <button
        title="Clique para voltar"
        disabled={props.currentComponent === "Messages"}
        onClick={() => props.changeComponent("Messages")}
      >
        <BsChevronLeft color="#142a4c" size={20} />
      </button>
      <button
        title="Clique para avançar"
        disabled={props.currentComponent === "Agenda"}
        onClick={() => props.changeComponent("Agenda")}
      >
        <BsChevronRight color="#142a4c" size={20} />
      </button>

      <button
        title="Clique para salvar"
        disabled={props.loading}
        onClick={(e) => props.saveBot(e)}
      >
        <BsCheckCircle color="#142a4c" size={20} />
      </button>
    </div>
  );
}
