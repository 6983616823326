import React, { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../contexts/ChatBotProvider/AttendanceProvider";
import { PrefContext } from "../../../../../../contexts/ChatBotProvider/PrefProvider";
import { UserContext } from "../../../../../../contexts/UserProvider";
import { BsInfoCircleFill } from "react-icons/bs";
import Table from "./Table";
import ButtonsFooter from "./Table/ButtonsFooter";
import "./styles.css";

import * as XLSX from "xlsx";

export default function Index(props) {
  const { contactWGroup } = useContext(PrefContext);
  const { attendances } = useContext(AttendanceContext);
  const { user } = useContext(UserContext);
  const [contatos, setContatos] = useState([...props.contatos]);
  const [attendancesList, setAttendancesList] = useState([]);
  const [myContatosList, setMyContatosList] = useState([]);
  const [myGroupList, setMyGroupList] = useState([]);
  const [fidelizadosList, setFidelizadosList] = useState([]);
  const [importedNumbers, setImportedNumbers] = useState([]);

  const [view, setView] = useState("contatos");

  useEffect(() => {
    const newAttendance = attendances ? [...attendances] : [];
    setAttendancesList(newAttendance);
  }, []);

  useEffect(() => {
    const newMyContatosOnShow = contatos ? [...contatos] : [];
    setMyContatosList(newMyContatosOnShow);
  }, []);

  useEffect(() => {
    const newGroups = contactWGroup ? [...contactWGroup] : [];
    setMyGroupList(newGroups);
  }, []);

  useEffect(() => {
      const filteredList = attendances.filter((contato) => contato?.fidelity?.includes(user._id));
      setFidelizadosList(filteredList);
  },[])

  const handleSaveContacts = () => {
    props.setContatos(contatos);
    props.setOpenContatosForm(false);
  };

  const views = {
    contatos: (
      <Table
        attendancesList={attendancesList}
        myContatosList={myContatosList}
        setContatos={setContatos}
        setOpenContatosForm={props.setOpenContatosForm}
        view={view}
        importedNumbers={importedNumbers}
      />
    ),
    grupos: (
      <Table
        myContatosList={myContatosList}
        groupList={myGroupList}
        setContatos={setContatos}
        setOpenContatosForm={props.setOpenContatosForm}
        view={view}
        importedNumbers={importedNumbers}
      />
    ),
    fidelizados: (
      <Table
        user={user}
        myContatosList={myContatosList}
        fidelizadosList={fidelizadosList}
        setContatos={setContatos}
        setOpenContatosForm={props.setOpenContatosForm}
        view={view}
        importedNumbers={importedNumbers}
      />
    ),
  };

  async function importXLSFile(e) {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let contactXls = data.map((item) => {
        if (item["Numero"]) {
          return {
            ...item,
            Numero: "55" + item["Numero"],
          };
        }
      });
      setImportedNumbers([...contactXls]);
    };
    reader.readAsBinaryString(file);
  }

  return (
    <div className="container-modal-add">
      <div className="add-contatos-form-header-body">
        <p>Filtrar por</p>
        <select onChange={(e) => setView(e.target.value)}>
          <option value="contatos">Contatos</option>
          <option value="grupos">Grupos</option>
          <option value="fidelizados">Fidelizados</option>
        </select>
        <div className="add-contatos-container-text-information">
          <BsInfoCircleFill size={20} color="#9AC31C" />
          <p>
            Selecione e transfira os contatos para a outra tabela, depois salve
            a lista.
          </p>
        </div>
        <div style={{display:'flex', justifyContent:'flex-start'}}>
          <button
            className="import-btn"
            onClick={(e) => {
              document.getElementById("file").click();
            }}
            title="Para o correto funcionamento, o arquivo deve conter uma coluna com o nome 'Numero' na posição A1 e os números abaixo."
          >
            Importar XLS
          </button>
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile"
            style={{ display: "none" }}
            onChange={importXLSFile}
            onClick={(e) => (e.target.value = null)}
          />
        </div>
      </div>
      {views[view]}
      <ButtonsFooter
        onSubmit={handleSaveContacts}
        setOpenContatosForm={props.setOpenContatosForm}
      />
    </div>
  );
}