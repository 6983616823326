import React from "react";

const AlertDelete = (props) => {
  return (
    <div>
      <div className="alert-delete">
        <div className="alert-delete-text">
          <h4>Deseja excluir essa pesquisa?</h4>
          <p>Essa ação não poderá ser desfeita.</p>
          <div className="alert-delete-buttons">
            <button
              className="alert-delete-yes-button"
              onClick={props.onDelete}
            >
              Excluir
            </button>
            <button onClick={props.close} className="alert-delete-no-button">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertDelete;
