import React from 'react'

const FooterMessage = (props) => {
    const { hiddenButtons, onSetHiddenButtons,messageClass } = props
    return (
        <div key={props.data._id} className="footer-message">
            
            <div style={{ display: 'flex' }}>
                {props.formatedDate} - {props.time}
                {
                    props.isFromMe ?
                        (props.visualized) ?
                            <div style={{ marginLeft: "10px" }}>
                                Visualizada
                            </div>
                            : ""
                        : ""
                }
            </div>
        </div>
    )
}

export default FooterMessage
