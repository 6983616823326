import React, { useContext, useEffect, useState } from 'react'
import { FcDocument } from 'react-icons/fc'


export default function File(props) {
  // let files = data.files
  // if (!files) files = [data.file]
  const { data, message, justi } = props
  const [files, setFiles] = useState([])
  const [file, setFile] = useState({})
  const [maximize, setMaximize] = useState(false)
  const [selectedImg, setSelectedImg] = useState(null)
  // const { 
  //   files,
  //  } = data

  useEffect(() => {
    let files = data.files
    if (!files) files = [data.file]
    setFiles(files)
  }, [data])

  function transformedArray(array){
    let newArray =  array
    if(Array.isArray(newArray) && newArray.length > 0 && Array.isArray(newArray[0])){
        return newArray[0].join('')
    }else{
        return array
    }
}

  return (
    <>

      {files.map((file, j) => {
        //make break line for each file

        const word = "public"
        if (!file) return ""
        if (!file.url) return ""
        if (file.url.indexOf(word) > -1) {
          file.url = file.url.replace("public", "")
          // file.url = window.location.href.includes("localhost") ? "http://localhost:3500/" + file.url :  'https://voxbot.voxcity.com.br/' + file.url
        }
        return <React.Fragment key={data._id + 420}>

          {file.type.includes("image") || file.type.includes("sticker") &&
            <ImageFile file={file} />
          }

          <div key={j} className="file-message">
            <a href={file.url} target="_blank" download={file.name} rel="noopener noreferrer" style={{ wordBreak: "break-word", marginBottom: 5 }}>
              <FcDocument key={j + 1 * 1000} style={{ wordBreak: "break-word" }} />
              {file.name}
            </a>
          </div>
        </React.Fragment>
      })}

      {data?.message?.length > 0 &&
        <span style={{ wordBreak: "break-word", alignSelf: justi }}>
          {transformedArray(message)}
        </span>
      }
    </>
  )
}

const ImageFile = (props) => {
  const { file } = props
  const [maximize, setMaximize] = useState(false)
  const [selectedImg, setSelectedImg] = useState(null)
  const maxi = (url) => {
    setMaximize(true)
    setSelectedImg(url)
  }
  const minimizeImg = (e) => {
    if (e.target.classList.contains('image-modal')) {
      setMaximize(false)
    }
  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
      <img
        src={file.url}
        style={{ width: 270, borderRadius: 7, cursor: 'pointer', marginBottom: 5 }}
        alt="sent"
        onClick={() => maxi(file.url)} />
      {maximize ? <>
        <div className="image-modal" onClick={e => minimizeImg(e)}>
          <img src={selectedImg} className="image-modal-img" alt="" />
        </div>
      </> : null}
    </div>
  )
}