import React from "react";
import { BsX, BsCheckCircle, BsXCircle } from "react-icons/bs";
import "./alertmessage.css";

export default function AlertMessage({ error, message, onClose }) {
  return (
    <div className="overlay-message-alert">
      <div className="modal-content-group-message-alerts">
        <button className="fechar" onClick={onClose}>
          <BsX size={40} color="#9AC31C" />
        </button>
        <div className="container-header">
          <div className="container-align-text-modal-delete">
            {error ? (
              <BsXCircle size={40} color="red" />
            ) : (<BsCheckCircle size={40} color="#9AC31C" />)}
            <h3>{message || error}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
