import { useEffect, useState, useContext } from "react";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { EspecialidadeForm } from "../Especialidade/EspecialidadeForm";
import ApiUsers from "../../../ApiUsers";
import "./style.css";
import { EspecialidadeCard } from "../Especialidade/EspecialidadeCard";
import { PesquisaEspecialidades } from "../Pesquisa/PesquisaEspecialidades";

export const Especialidade = () => {
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [selecionado, setSelecionado] = useState(null);
  const [editando, setEditando] = useState(false);

  const [especialidadesFiltradas, setEspecialidadesFiltradas] = useState([]);

  const editEspecialidade = (especialidade) => {
    setSelecionado(especialidade);
    setEditando(true);
  };

  const deleteEspecialidade = (especialidade) => {
    ApiUsers.post("/especialidades/delete", {
      especialidade,
    });
  };

  const updateEspecialidade = (especialidade) => {
    ApiUsers.post("/especialidades/update", {
      especialidade,
    });
    setSelecionado(null);
    setEditando(false);
  };

  useEffect(() => {
    const novosProfissionais = [...professionalContext.profissionais];
    setProfissionais(novosProfissionais);

    const novosServicos = [...serviceContext.servicos];
    setServicos(novosServicos);

    const novasEspecialidades = [...specialtyContext.especialidades];
    setEspecialidades(novasEspecialidades);

    const novosLocais = [...locationContext.locais];
    setLocais(novosLocais);
  }, [
    locationContext.locais,
    professionalContext.profissionais,
    serviceContext.servicos,
    specialtyContext.especialidades,
  ]);

  const especialidadesFiltradasUnicas = especialidadesFiltradas.reduce(
    (acc, especialidade) => {
      const especialidadeJaExiste = acc.find(
        (especialidadeUnica) => especialidadeUnica._id === especialidade._id
      );
      if (!especialidadeJaExiste) {
        return [...acc, especialidade];
      }
      return acc;
    },
    []
  );

  return (
    <div className="especialidade-container">
      {selecionado && <div className="bg-modal"></div>}
      <div className="agenda-header-container">
        <div className="especialidade-title">ESPECIALIDADES</div>
        <button
          className="btn-add"
          onClick={() =>
            setSelecionado({
              _id: "",
              nome: "",
              profissionais: [],
            })
          }
        >
          NOVA ESPECIALIDADE
        </button>
      </div>
      <PesquisaEspecialidades
        profissionais={profissionais}
        especialidades={especialidades}
        servicos={servicos}
        locais={locais}
        setEspecialidadesFiltradas={setEspecialidadesFiltradas}
      />
      {especialidadesFiltradasUnicas.length > 0 ? (
        <div className="especialidade-content">
          <div className="card-container">
            {especialidadesFiltradasUnicas.map((especialidade, index) => {
              return (
                <EspecialidadeCard
                  key={index}
                  especialidade={especialidade}
                  editEspecialidade={editEspecialidade}
                  deleteEspecialidade={deleteEspecialidade}
                  updateEspecialidade={updateEspecialidade}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="especialidade-content">
          <div className="card-container">
            <div className="especialidades-vazio">SEM ESPECIALIDADES</div>
          </div>
        </div>
      )}
      {selecionado && (
        <EspecialidadeForm
          especialidade={selecionado}
          setSelecionado={setSelecionado}
          updateEspecialidade={updateEspecialidade}
          setEditando={setEditando}
          editando={editando}
        />
      )}
    </div>
  );
};
