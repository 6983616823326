import React from "react";
import { FaTrash } from "react-icons/fa";
import "./componentComands.css";

const CardCommands = ({
  index,
  comand,
  selectActions,
  flows,
  atendentes,
  deleteComando,
  changeAlternative,
}) => {
  return (
    <div key={index} className="comando-card-cd">
      <div className="remove-comand-div-cd">
        <FaTrash
          size={22}
          color="#A61515"
          title="Remover comando"
          className="btn-remove-comando-cd"
          onClick={() => deleteComando(index)}
        />
      </div>

      <div className="container-align-row-cd">
        <div className="container-content-msg">
          <label>
            <h5>Nome do comando</h5>
          </label>
          <input
            type="text"
            className="bot-form-input-cd"
            placeholder="Insira o nome do comando"
            value={comand.valor}
            onChange={(e) => changeAlternative(e, index, "valor")}
          />
        </div>

        <div className="container-content-msg">
          <label>
            <h5>Selecione uma ação</h5>
          </label>
          <select
            className="bot-form-input-cd"
            value={comand.action}
            onChange={(e) => changeAlternative(e, index, "action")}
          >
            <option value="">Selecione</option>
            {selectActions.map((action, index2) => (
              <option key={index2} value={action.value}>
                {action.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="container-content-msg">
        <label>
          <h5>Selecione o próximo passo</h5>
        </label>
        <select
          className="bot-form-input-cd-select"
          value={comand.next}
          onChange={(e) => changeAlternative(e, index, "next")}
        >
          {comand.action === "send-to-flow" ? (
            <>
              <option value="">Selecione um flow</option>
              {flows.map((flow, i) => (
                <option key={i} value={flow._id}>
                  {flow.nome}
                </option>
              ))}
            </>
          ) : comand.action === "send-to-chat" ? (
            <>
              <option value="">Selecione um atendente</option>
              {atendentes.map((atendente, j) => (
                <option key={j} value={atendente._id}>
                  {atendente.nome}
                </option>
              ))}
            </>
          ) : null}
        </select>
      </div>
    </div>
  );
};

export default CardCommands;
