import { useState, useEffect } from "react";
import { AgendaFunctions } from "../../../../miscelaneous/utils/AgendaFunctions";
import {
  translateDiaSemana,
  diasSemana,
  nomesDosMeses,
} from "../../../../miscelaneous/utils";

export const Header = (props) => {
  const { acharProfissional, acharEspecialidade, acharLocal } =
    AgendaFunctions();
  const [profissional, setProfissional] = useState({});
  const [especialidade, setEspecialidade] = useState({});
  const [local, setLocal] = useState({});
  const [agendaSelecionada, setAgendaSelecionada] = useState({});
  const [diaSelecionado, setDiaSelecionado] = useState("");
  const [diaDataSelecionada, setDiaDataSelecionada] = useState("");
  const [mesDiaSelecionado, setMesDiaSelecionado] = useState("");
  const [diaSemanaFuncionamento, setDiaSemanaFuncionamento] = useState("");
  const [nomeDiaSemana, setNomeDiaSemana] = useState("");
  const [isDesmarcarChecked, setIsDesmarcarChecked] = useState(false);
  const [isDesbloquearChecked, setIsDesbloquearChecked] = useState(false);
  const [isBloquearChecked, setIsBloquearChecked] = useState(false);

  useEffect(() => {
    const novoDiaSelecionado = props.diaSelecionado ? props.diaSelecionado : "";
    setDiaSelecionado(novoDiaSelecionado);
  }, [props.diaSelecionado]);

  useEffect(() => {
    const novaAgendaSelecionada = props.agendaSelecionada
      ? { ...props.agendaSelecionada }
      : {};
    setAgendaSelecionada(novaAgendaSelecionada);
  }, [props.agendaSelecionada]);

  useEffect(() => {
    const novoProfissional = acharProfissional(agendaSelecionada.profissional);
    setProfissional(novoProfissional);
  }, [acharProfissional, agendaSelecionada.profissional]);

  useEffect(() => {
    const novaEspecialidade = acharEspecialidade(
      agendaSelecionada.especialidade
    );
    setEspecialidade(novaEspecialidade);
  }, [acharEspecialidade, agendaSelecionada.especialidade]);

  useEffect(() => {
    const novoLocal = acharLocal(agendaSelecionada.local);
    setLocal(novoLocal);
  }, [acharLocal, agendaSelecionada.local]);

  useEffect(() => {
    const dataDiaSelecionado = new Date(diaSelecionado);
    const dia = dataDiaSelecionado.getDate().toString().padStart(2, "0");
    const mes = dataDiaSelecionado.getMonth();
    const mesSelecionado = nomesDosMeses[mes];
    setDiaDataSelecionada(dia);
    setMesDiaSelecionado(mesSelecionado);
  }, [diaSelecionado]);

  useEffect(() => {
    const novoDiaSemanaFuncionamento = props.horarioFuncionamento?.dias?.map(
      (dia) => {
        return diasSemana[dia].nome;
      }
    );
    setDiaSemanaFuncionamento(novoDiaSemanaFuncionamento?.join(", "));
  }, [props.horarioFuncionamento]);

  useEffect(() => {
    const novoNomeDiaSemana = translateDiaSemana(diaSelecionado);
    setNomeDiaSemana(novoNomeDiaSemana);
  }, [diaSelecionado]);

  useEffect(() => {
    if (props.datasSelecionadas.length >= 1) {
      props.datasSelecionadas.forEach((data) => {
        if (data.status === "bloqueio") {
          setIsDesbloquearChecked(true);
          setIsDesmarcarChecked(false);
          setIsBloquearChecked(false);
        } else if (!data.status) {
          setIsBloquearChecked(true);
          setIsDesbloquearChecked(false);
          setIsDesmarcarChecked(false);
        } else {
          setIsDesmarcarChecked(true);
          setIsDesbloquearChecked(false);
          setIsBloquearChecked(false);
        }
      });
    } else {
      setIsDesmarcarChecked(false);
      setIsDesbloquearChecked(false);
      setIsBloquearChecked(false);
    }
  }, [props.datasSelecionadas]);

  return (
    <div className="modal-calendario-header">
      <div className="modal-calendario-header-data">
        <h2>
          {diaDataSelecionada}
          <span>{mesDiaSelecionado}</span>
        </h2>
        {nomeDiaSemana}
      </div>
      <div className="modal-calendario-header-info">
        <h1>{especialidade?.nome}</h1>
        <h3>{profissional?.nome}</h3>
        <h5>{local?.nome}</h5>
      </div>
      <div className="modal-calendario-header-horarios">
        <b>Horário de Funcionamento</b>
        <span>
          {props.horarioFuncionamento?.inicio} -{" "}
          {props.horarioFuncionamento?.fim}
        </span>
        {diaSemanaFuncionamento}
        <div className="modal-calendario-header-horarios-buttons">
          {isBloquearChecked ? (
            <button
              className={
                isBloquearChecked ? "desmarcar-btn" : "desmarcar-btn-disabled"
              }
              onClick={() => props.submitBloquearAgendamento()}
            >
              Bloquear
            </button>
          ) : (
            <>
              <button
                className={
                  isDesmarcarChecked
                    ? "desmarcar-btn"
                    : "desmarcar-btn-disabled"
                }
                onClick={() => props.submitCancelarAgendamento()}
                disabled={!isDesmarcarChecked}
              >
                Desmarcar
              </button>
              <button
                className={
                  isDesbloquearChecked
                    ? "desmarcar-btn"
                    : "desmarcar-btn-disabled"
                }
                onClick={() => props.submitDesbloquarAgendamento()}
                disabled={!isDesbloquearChecked}
              >
                Desbloquear
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
