import React, { useContext, useState } from "react";
import { BsTrash3Fill, BsPencilSquare } from "react-icons/bs";
import { GrupoContext } from "../../../../contexts/ChatBotProvider/GrupoWhatsProvider";
import ModalDeleteGroup from "../ModalDeleteGroup";
import "./cardGrupo.css";

export default function CardGrupo({
  nome,
  membros,
  groupId,
  editGroupModal,
  atendentes,
}) {
  const { deleteGroup } = useContext(GrupoContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const adminCount = membros?.filter((membro) => membro.admin === true).length;

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="container-card-grupo">
      <div className="container-card-grupo-align">
        <div className="card-grupo">
          <div className="container-align-info">
            <div className="container-info-align-img-options">
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt=""
              />
              <div className="container-options">
                <button className="button-card-grupo" onClick={editGroupModal}>
                  <BsPencilSquare size={20} color="#9AC31C" />
                </button>
                <button className="button-card-grupo" onClick={openDeleteModal}>
                  <BsTrash3Fill size={20} color="#9AC31C" />
                </button>
              </div>
            </div>
            <div className="container-info">
              <div className="container-name">
                <p>
                  Nome : <strong> {nome}</strong>
                </p>
              </div>
              <div className="container-name-membros-row">
                <p>
                  Membros : <strong>{membros?.length}</strong>
                </p>
                <p>
                  Administradores : <strong>{adminCount}</strong>
                </p>
              </div>

              <div className="container-admin">
                {atendentes && (
                  <p>
                    Atendentes : <strong>{atendentes.length}</strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && (
        <ModalDeleteGroup
          TitleModal="Tem certeza de que deseja excluir o grupo?"
          SubtitleModal="Ao excluir o grupo, a ação será permanente e não poderá ser desfeita."
          onClose={closeDeleteModal}
          onClick={() => {
            deleteGroup(groupId);
            closeDeleteModal();
          }}
        />
      )}
    </div>
  );
}
