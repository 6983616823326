import React from "react";

const FormInputName = ({ newBot, changeName }) => {
  return (
    <div className="bot-form-one-create-form">
      <div className="bot-form-input-container-create-form">
        <div className="container-align-input-create-form">
          <h5 className="required">Nome:</h5>
        </div>
        <input
          type="text"
          className="bot-form-input-create-form"
          placeholder="Digite o nome do bot"
          value={newBot.nome}
          onChange={(e) => changeName(e)}
        />
      </div>
    </div>
  );
};

export default FormInputName;
