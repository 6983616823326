import React, {useState} from 'react';
import './Login.css';
import {Link} from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import Email from "./Inputs/email";
import Conta from "./Inputs/conta";
import ApiUsers from '../../ApiUsers';
import MiniLoading from '../Configurações/OptionsScreen/inputs/MiniLoading';
import { BiErrorCircle } from "react-icons/bi";

const Forgot = () => {
    const [forgot, setForgot] = useState({
        email: '',
        conta: ''
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [loading , setLoading] = useState(false);
    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let conta = forgot.conta;
        let email = forgot.email;
        ApiUsers.post('/forgotPassword',{email,conta})
            .then(res => {
                if(res.data.error){
                    setError(res.data.error);
                    setLoading(false);
                    return
                }
                setLoading(false);
                setSuccess(true);
            })
            .catch(err => {
                setLoading(false);
                setError('problemas no servidor');
            });   
    }

    function setInputs(campo, valor) {
        setForgot({ ...forgot, [campo]: valor });
        setError("");
    }

    return (
        <div className="login-wrapper">
            <Form className="login-container" onSubmit={handleSubmit}>
                <div className="login-header">
                <img
                    src="./imgs/VOXLOGOHorizintal.png"
                    className="form-header-img"
                    alt="logo"
                />
                </div>

                {error !== "" ?
                    <div className="error-container">
                        <BiErrorCircle/> <span className="error-msg"> {error} </span>
                    </div> 
                : null}

                <Conta setInputs={setInputs} />
                <Email setInputs={setInputs} />

                {
                    success ?
                        <Button id="succes-btn" as={Link} to="/login" variant='light' className="btn-login">
                            Enviado para o seu Email
                        </Button>
                    :
                        <Button type="submit" style={{backgroundColor: "#142a4c"}} title="Esqueceu a Senha" className="login-button" >
                            {loading ? <MiniLoading/> : "Enviar"}
                        </Button>
                }
                <Button style={{marginBottom:'10px'}} as={Link} to="/login" variant='light' className="login-button">Voltar</Button>
            </Form>

        </div>
    );
}

export default Forgot;
