import React, { useContext, useEffect, useState } from "react";
import { GrupoContext } from "../../../contexts/ChatBotProvider/GrupoWhatsProvider";
import ButtonNewGroup from "./ButtonNewGroup";
import InputSearch from "./InputSearch";
import CardGrupo from "./CardGrupo";
import ModalGroup from "./ModalGroup";
import ModalTable from "./ModalTable/ModalTable";
import AlertMessage from "./Alerts/AlertMessage";
import { BsInfoCircleFill } from "react-icons/bs";
import "./grupos.css";

const initialState = {
  nome: "",
  image: "https://www.w3schools.com/howto/img_avatar.png",
  descricao: "",
  bot: "",
  membros: [],
  atendentes: [],
};

export default function Grupos() {
  const {
    groups,
    newGroup,
    editGroup,
    isShowAlert,
    alertMessage,
    closeAlertModal,
    error,
  } = useContext(GrupoContext);
  const [gruposOnShow, setGruposOnShow] = useState([]);
  const [isModalOpen, setIsOpen] = useState(false);
  const [isModalTableOpen, setIsTableOpen] = useState({
    type: "",
    isOpen: false,
  });
  const [search, setSearch] = useState("");
  const [form, setForm] = useState(initialState);
  const isEditing = form._id;

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setForm(initialState);
  };

  const editGroupModal = (group) => {
    setIsOpen(true);
    setForm(group);
  };

  const onSubmitModal = () => {
    isEditing ? editGroup(form) : newGroup(form);
    closeModal();
  };

  const toggleModalTable = (type) => {
    setIsTableOpen({ type, isOpen: !isModalTableOpen.isOpen });
  };

  const handleAddMembers = (members) => {
    let newMembros = members.map((member) => {
      const { _id, nome, pushname, telefone, admin } = member;
      return { _id, nome, pushname, telefone, admin };
    });
    onChangeForm({ target: { name: "membros", value: newMembros } });
    toggleModalTable("members");
  };

  const handleAddAtendentes = (atendentes) => {
    let newAtendentes = atendentes.map((atendente) => {
      const { _id, nome } = atendente;
      return { _id, nome };
    });
    onChangeForm({ target: { name: "atendentes", value: newAtendentes } });
    toggleModalTable("atendentes");
  };

  function filteredGroups() {
    const newGroups = groups.filter((group) => {
      return group.nome.toLowerCase().includes(search.toLowerCase());
    });
    setGruposOnShow(newGroups);
  }

  useEffect(() => {
    filteredGroups();
  }, [search, groups]);

  useEffect(() => {
    const newGroups = groups ? [...groups] : [];
    setGruposOnShow(newGroups);
  }, [groups]);

  const modais = {
    isOpenGroup: (
      <ModalGroup
        TitleModal={isEditing ? "Editar Grupo" : "Criar Grupo"}
        onClose={closeModal}
        form={form}
        onChangeForm={onChangeForm}
        toggleModalTable={toggleModalTable}
        onClick={onSubmitModal}
      />
    ),
    isOpenTable: (
      <ModalTable
        toggleModalTable={toggleModalTable}
        handleAddAtendentes={handleAddAtendentes}
        handleAddMembers={handleAddMembers}
        members={form.membros}
        atendentes={form.atendentes}
        isModalTableOpen={isModalTableOpen}
      />
    ),
  };

  return (
    <div className="container-grupos">
      {isShowAlert && (
        <AlertMessage
          message={alertMessage}
          error={error}
          onClose={closeAlertModal}
        />
      )}
      <ButtonNewGroup title="Criar novo grupo" onClick={openModal} />
      <InputSearch
        title="Buscar grupos"
        onChange={(e) => setSearch(e.target.value)}
      />
      {gruposOnShow.length === 0 && (
        <div className="container-message-found">
          <BsInfoCircleFill size={30} style={{ color: "#9AC31C" }} />
          <h3>Não há grupos !</h3>
        </div>
      )}
      <div className="container-grupos-list">
        {gruposOnShow.map((grupo, index) => (
          <CardGrupo
            key={index}
            nome={grupo.nome}
            membros={grupo.membros}
            groupId={grupo._id}
            editGroupModal={() => editGroupModal(grupo)}
            atendentes={grupo.atendentes}
          />
        ))}
      </div>
      {isModalOpen && modais.isOpenGroup}
      {isModalTableOpen.isOpen && modais.isOpenTable}
    </div>
  );
}
