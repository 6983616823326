import React, { useState, useEffect } from "react";
import { BsX } from "react-icons/bs";
import HeaderEdit from "./components/HeaderEdit/HeaderEdit";
import ImageUser from "./components/ImageUser/ImageUser";
import RenderBody from "./components/RenderBody/RenderBody";
import "./modalEdit.css";

const ModalEdit = (props) => {
  const { contact, setContact, createAlert, close } = props;
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    if (contact) {
      let toRemove = [
        "allMessages",
        "historico",
        "selected",
        "_id",
        "lastMessage",
        "ultimo protocolo",
      ];
      let newContact = {};
      for (let key in contact) {
        if (!toRemove.includes(key)) {
          newContact[key] = contact[key];
        }
      }
      setContactInfo(newContact);
    }
  }, []);

  return (
    <div className="show-form">
      <div className="contact-card">
        <HeaderEdit />
        <ImageUser />
        <RenderBody
          contactInfo={contactInfo}
          contact={contact}
          setContact={setContact}
          createAlert={createAlert}
        />
        <BsX onClick={() => close()} className="close-icon" />
      </div>
    </div>
  );
};
export default ModalEdit;
