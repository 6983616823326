import React from "react";
import "../Group.css";
import { Form } from "react-bootstrap";
import ButtonsCrud from "../../inputs/ButtonsCrud";
import { MdModeEdit } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import { IoPersonAdd } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import Empty from "../../inputs/EmptyLength";

export default function GroupsList(props) {
  const { grupos } = props;

  return (
    <>
      <div>
        <Form.Control
          type="text"
          placeholder="Digite aqui para pesquisar um grupo"
          onChange={(e) => {
            props.search(e);
          }}
          style={{ width: "80%", margin: "0 auto" }}
        />

        <br />

        <div>
          {grupos.length > 0 ? (
            <>
              {grupos.map((grupo, index) => {
                let userCol = {
                  Nome: grupo.nome,
                  Usuarios: grupo.usuarios.length,
                };

                return (
                  <div className="groups-card" key={index}>
                    <div className="groups-infos">
                      {Object.entries(userCol).map(([k, v]) => {
                        return (
                          <span key={k}>
                            <b>{k}: </b>
                            {v}
                          </span>
                        );
                      })}
                    </div>

                    <div className="groups-buttons">
                      <ButtonsCrud
                        buttons={[
                          {
                            action: "listarUsuario",
                            title: "Listar Usuários",
                            class: "groups-list-button",
                            icon: <HiUsers className="button-icon" />,
                          },
                          {
                            action: "adicionarUsuario",
                            title: "Adicionar Usuário",
                            class: "groups-add-button",
                            icon: <IoPersonAdd className="button-icon" />,
                          },
                          {
                            action: "editar",
                            title: "Editar grupo",
                            class: "groups-edit-button",
                            icon: <MdModeEdit className="button-icon" />,
                          },
                          {
                            action: "excluir",
                            title: "Excluir grupo",
                            class: "groups-del-button",
                            icon: <ImBlocked className="button-icon" />,
                          },
                        ]}
                        handle={{
                          listarUsuario: (e) => props.listUsers(e, grupo),
                          adicionarUsuario: (e) => props.addUsers(e, grupo),
                          editar: (e) => props.edit(e, grupo),
                          excluir: (e) => props.delConfirm(e, grupo),
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Empty data="grupos" />
            </>
          )}
        </div>
      </div>
    </>
  );
}
