import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function formatarData(data) {
  const partes = data.split("-");
  return partes[2] + "/" + partes[1] + "/" + partes[0];
}

export const generatePDFsingle = async (
  quantidades,
  agendaSelecionada,
  contexto,
  intervalo
) => {
  const { profissionais, locais, especialidades, pref, servicos } = contexto;

  let dataInicio = formatarData(intervalo.start);
  let dataFim = formatarData(intervalo.end);

  const getProfessionalName = (id) => {
    const profissional = profissionais.filter((obj) => obj._id === id);
    return profissional[0].nome;
  };

  const getSpecialtyName = (id) => {
    const specialty = especialidades.filter((obj) => obj._id === id);
    return specialty[0].nome;
  };

  const getLocationName = (id) => {
    const local = locais.filter((obj) => obj._id === id);
    return local[0].nome;
  };

  const getServiceName = (id) => {
    const service = servicos.filter((obj) => obj._id === id);
    return service[0].nome;
  };

  async function imageUrlToBase64(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  const body = [
    {
      text: "Relatório das agendas",
      style: "titleHeader",
    },
    {
      image: image,
      width: 80,
      height: 80,
    },
    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },
    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        dataInicio,
        " - ",
        dataFim,
      ],
      margin: [0, 15, 0, 30],
    },
    {
      table: {
        widths: ["100%"],
        body: [
          [
            {
              text: getProfessionalName(agendaSelecionada?.profissional),
              style: "title",
              border: [false, false, false, false],
              margin: [10, 10, 0, 10],
            },
          ],
        ],
      },
    },
    {
      fillColor: "#F9F9F9",
      table: {
        margin: [0, 20, 20, 20],
        widths: ["50%", "50%"],
        body: [
          [
            {
              text: "Especialidade",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },
            {
              text: getSpecialtyName(agendaSelecionada?.especialidade),
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],
          [
            {
              text: "",
              colSpan: 2,
              fillColor: "#f0f0f0",
            },
          ],
          [
            {
              text: "Serviço",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },
            {
              text: getServiceName(agendaSelecionada?.serviço),
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],
          [
            {
              text: "",
              colSpan: 2,
              fillColor: "#f0f0f0",
            },
          ],
          [
            {
              text: "Local",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },
            {
              text: getLocationName(agendaSelecionada?.local),
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],
          [
            {
              text: "",
              colSpan: 2,
              fillColor: "#f0f0f0",
            },
          ],
          [
            {
              text: "Datas Marcadas",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
              colSpan: 2,
            },
          ],

          [
            {
              text: "Agendamentos Marcados",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },

            {
              text: quantidades.agendamentosMarcados,
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],

          [
            {
              text: "Agendamentos Cancelados",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },

            {
              text: quantidades.agendamentosCancelados,
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],

          [
            {
              text: "Agendamentos Desmarcados",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },

            {
              text: quantidades.agendamentosDesmarcardos,
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],

          [
            {
              text: "Agendamentos Confirmados",
              bold: true,
              alignment: "left",
              margin: [17, 20, 0, 15],
            },

            {
              text: quantidades.agendamentosConfirmados,
              alignment: "right",
              margin: [0, 20, 30, 15],
            },
          ],
        ],
      },
      layout: "noBorders",
    },
  ];
  const docDefinition = {
    content: [...body],
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      title: {
        alignment: "left",
        fillColor: "#9AC31D",
        bold: true,
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoUnico.pdf");
};

export const generatePDFtable = async (
  quantidades,
  agendas,
  contexto,
  date
) => {
  const { profissionais, locais, especialidades, pref, servicos } = contexto;

  async function imageUrlToBase64(url) {
    // função para converter a imagem em base64, mas imagem esta sem extensão;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  let agendaDetails = [];

  let dataInicio = formatarData(date.start);
  let dataFim = formatarData(date.end);

  const getAgendaDetails = () => {
    quantidades.map((quantidade, index) => {
      agendaDetails.push({
        profissional: profissionais.filter(
          (prof) => prof._id === agendas[index].profissional
        )[0].nome,

        especialidade: especialidades.filter(
          (esp) => esp._id === agendas[index].especialidade
        )[0].nome,

        local: locais.filter((loc) => loc._id === agendas[index].local)[0].nome,

        servico: servicos.filter(
          (serv) => serv._id === agendas[index].serviço
        )[0].nome,

        quantidade,
      });
    });
  };
  getAgendaDetails();

  const body = [
    {
      text: "Relatório das agendas",
      style: "titleHeader",
    },

    {
      image: image,
      width: 80,
      height: 80,
    },

    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },

    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        dataInicio,
        " - ",
        dataFim,
      ],
      margin: [0, 15, 0, 30],
    },

    {
      table: {
        widths: [80, "auto", "auto", "auto", "*", "*", 50, "auto"],
        body: [
          [
            {
              text: "Nome",
              style: "tableHeader",
              alignment: "center",
              fontSize: 10,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Especialidade",
              style: "tableHeader",
              alignment: "center",
              fontSize: 10,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Serviço",
              style: "tableHeader",
              alignment: "center",
              fontSize: 10,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Local",
              style: "tableHeader",
              alignment: "center",
              fontSize: 10,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Marcados",
              style: "tableHeader",
              alignment: "center",
              fontSize: 9,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Cancelados",
              style: "tableHeader",
              alignment: "center",
              fontSize: 9,
              border: [false, false, true, false],
              margin: [0, 10, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Desm",
              style: "tableHeader",
              alignment: "center",
              fontSize: 9,
              border: [false, false, true, false],
              margin: [0, 10, 0, 15],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Conf",
              style: "tableHeader",
              alignment: "center",
              fontSize: 9,
              border: [false, false, true, false],
              margin: [0, 10, 0, 15],
              borderColor: [null, null, "#f1f1f1", null],
            },
          ],

          ...agendaDetails.map((agenda) => [
            {
              text: agenda?.profissional,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },

            {
              text: agenda?.especialidade,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },

            {
              text: agenda?.servico,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },

            {
              text: agenda?.local,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },
            {
              text: agenda?.quantidade?.agendamentosMarcados,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },
            {
              text: agenda?.quantidade?.agendamentosCancelados,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },
            {
              text: agenda?.quantidade?.agendamentosDesmarcardos,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },
            {
              text: agenda?.quantidade?.agendamentosConfirmados,
              alignment: "center",
              style: "tableBody",
              border: [false, false, true, true],
              borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
            },
          ]),
        ],
      },
    },
  ];

  const docDefinition = {
    content: [...body],
    pageMargins: [10, 20, 10, 0],
    alignment: "center",
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      subtitleHeader: {
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        fillColor: "#9AC31D",
        color: "#212121",
        alignment: "center",
        margin: [5, 5, 5, 5],
      },
      tableBody: {
        fontSize: 12,
        fillColor: "#F9F9F9",
        color: "#212121",
        alignment: "center",
        margin: [0, 15, 0, 15],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoTabela.pdf");
};
