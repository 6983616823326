import React from "react";
import "./styles.css";

export default function ContactItem({ contact }) {
  return (
    <div className="contacts-unique-xls">
      <div className="contact-xls">
        <div className="contact-xls-info">
          <img
            className="img-avatar-xls"
            src={"https://www.w3schools.com/howto/img_avatar.png"}
            alt="Avatar"
          />
          {Object.entries(contact).map(([key, value]) => (
            <p key={key} className="contact-xls-field">
              <strong>{key}: </strong> {value}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
