import React, { useContext } from "react";
import detalhes from "./detalhes";
import resumo from "./resumo";
import { UserContext } from "../../../../contexts/UserProvider";
import "./style.css";

function ButtonsFile(props) {
  const context = useContext(UserContext);
  const user = context.user;
  const org = context.pref;
  return (
    <div className="buttons-files-div">
      <div className="button-file-div">
        <button
          className="button-file-pdf"
          onClick={() => {
            detalhes(props.title, [...props.data], props.mensagem, org, user);
          }}
        >
          Download Detalhes
        </button>
      </div>
      <div className="button-file-div">
        <button
          className="button-file-csv"
          onClick={() => {
            resumo(props.title, [...props.data], props.mensagem, org, user);
          }}
        >
          Download Resumo
        </button>
      </div>
    </div>
  );
}

export default ButtonsFile;
