import React, { useState, useEffect, useContext } from "react";
import { ContactContext } from "../../../contexts/ChatBotProvider/ContactProvider";
import { BsInfoCircle, BsSearch } from "react-icons/bs";

const Contacts = (props) => {
  const [onShow, setOnShow] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = useState(null);
  const context = useContext(ContactContext);
  const { contacts } = useContext(ContactContext);
  const countAndSlice = (message) => {
    if (message && message.length > 30) {
      return `${message.slice(0, 30)}...`;
    }
    return message;
  };

  const searchContacts = (value) => {
    if (value === "") {
      setOnShow([...contacts]);
    } else {
      let newContacts = contacts.filter((contact) =>
        contact.nome.toLowerCase().includes(value.toLowerCase())
      );
      setOnShow(newContacts);
    }
  };

  useEffect(() => {
    searchContacts(searchInput);
  }, [searchInput, contacts]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    setOnShow([...contacts]);
  }, [contacts]);

  return (
    <div className="talk-list">
      <div className="container-search">
        <input
          type="search"
          placeholder="Buscar Contato..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div className="button-search-box">
          <BsSearch size={20} />
        </div>
      </div>

      {onShow.length === 0 && searchInput !== "" && (
        <div className="container-information-nothingfound">
          <BsInfoCircle size={50} color="#9ac31c" />
          <p>
            <strong>Nenhum contato encontrado !</strong>
          </p>
          <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
            Tente novamente com outro nome.
          </p>
        </div>
      )}

      {onShow.length > 0 && (
        <>
          {onShow.map((contact, i) => {
            if (
              context.user._id === contact._id ||
              contact.nome === "Voxcity" ||
              !contact.ativo
            ) {
              return null;
            }

            let background =
              contact._id === props.selected?._id ? "#F0FFF0" : "inherit";

            return (
              <div
                key={contact._id}
                title={contact.nome}
              >
                <div
                  className="contact-wrapper"
                  style={{ backgroundColor: background }}
                  key={i}
                  onClick={(e) => {
                    props.onSelect(e, contact);
                    contacts.forEach((cont) => {
                      if (cont._id === contact._id) {
                        let messagesRead = cont.unseen;
                        cont.unseen = [];
                        props.socket.emit("read cont messages", {
                          contact: contact._id,
                          messages: messagesRead,
                        });
                      }
                      return cont;
                    });
                    if (
                      window.screen.width <= 900 ||
                      document.documentElement.clientWidth <= 900
                    )
                      context.toogleLeftCol();
                  }}
                >
                  <div>
                    <img
                      className="contact-avatar"
                      src={contact.foto ? contact.foto : "/imgs/avatar2.png"}
                      alt=""
                    />
                  </div>

                  <div
                    className="contact-data"
                    style={{ backgroundColor: background, border: "none" }}
                    key={i}
                  >
                    <div className="contact-row">
                      <b style={{ flex: 1 }}> {contact.nome}</b>
                      {contact.unseen && contact.unseen.length > 0 && (
                        <div className="not-seen">
                          <b style={{ fontSize: "11px" }}>
                            {contact.unseen.length}
                          </b>
                        </div>
                      )}
                    </div>
                    <div>
                      {contact.isTyping ? (
                        <div className="contact-row">
                          <b>{String.fromCodePoint("128172")} Digitando...</b>
                        </div>
                      ) : contact.isRecording ? (
                        <div className="contact-row">
                          <b>{String.fromCodePoint("127908")} Gravando...</b>
                        </div>
                      ) : (
                        <div className="contact-row-p">
                          <p>
                            {contact.lastMessage
                              ? countAndSlice(contact.lastMessage.message)
                              : ""}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <Card
                                    style={{ height: '5px', width: '101%', marginLeft: '-3px' }}
                                    key={(i + 10) * 20000}
                                    className="bg-bg"
                                /> */}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Contacts;
