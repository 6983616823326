import React, { useState, useContext, useEffect } from "react";
import Contacts from "./general/Contacts";
import { UserContext } from "../../contexts/UserProvider";
import { ContactContext } from "../../contexts/ChatBotProvider/ContactProvider";
import MessageTitle from "./general/MessageTitle";
import Messagersender from "./general/Messagersender";
import Rightcol from "../main/RightCol";
import MainView from "../main/MainView";
import LeftCol from "../main/LeftCol";
import "react-toastify/dist/ReactToastify.css";
import "./chat.css";
import MessageScreen from "./general/MessageScreen";
import EmptyMessages from "./general//utils/EmptyMessages";
import { ReplyForwardingContext } from "../../contexts/ReplyForwardingProvider";
import { MessageContext } from "../../contexts/ChatBotProvider/MessageProvider";


function Chat(props) {
  const context = useContext(UserContext);
  const { user } = context;
  const contactContext = useContext(ContactContext);
  const { contacts } = contactContext;
  const { replyMessage, setReplyMessage } = useContext(ReplyForwardingContext);

  const [socket, setSocket] = useState();
  const [choosenContact, setChoosenContact] = useState();

  const { setLookingMessage,setOnSearch } = useContext(MessageContext);


  useEffect(() => {
    setSocket(context.socket);
  }, [context.socket]);

  useEffect(() => {
    if (choosenContact) {
      let newContactSelected = contacts.find(
        (cont) => cont._id === choosenContact._id
      );
      setChoosenContact({ ...newContactSelected });
    }
  }, [contactContext.contacts]);

  useEffect(() => {
    setReplyMessage(null);
  }, []);
  return (
    <MainView >
      <React.Fragment>
        <LeftCol title="Chat Privado">
          <Contacts
            socket={context.socket}
            selected={choosenContact}
            onSelect={(e, contact) => {
              let newContactSelected = contacts.find(
                (cont) => cont._id === contact._id
              );
              setChoosenContact(newContactSelected);
              setReplyMessage(null);
              setLookingMessage(null);
              setOnSearch(false);
            }}
          />
        </LeftCol>
        <Rightcol>
          {!choosenContact ? (
            <EmptyMessages
              nome={user?.nome}
              imgClass="welcome-img-empty-chat"
              image="/imgs/chat-welcome.png"
              message="Selecione um contato ao lado para iniciar uma conversa"
            />
          ) : (
            <React.Fragment>
              <MessageTitle
                msgType="private"
                socket={context.socket}
                contact={choosenContact}
              />
              <MessageScreen
                msgType="private"
                contact={choosenContact}
                type="private"
              />

              <Messagersender msgType="private" contact={choosenContact} />
            </React.Fragment>
          )}
        </Rightcol>
      </React.Fragment>
    </MainView>
  );
}

export default Chat;
