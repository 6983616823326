import { useContext } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";

const BotButtons = ({ bot, loading, selectBot, showModal, changeBotStatus}) => {
  const { sincronizando, sincronyze, sincronyzeGroup, sincronizandoGrupos} = useContext(FlowBotContext);
  return (
    <div className="bot-content-buttons">
      <div className="container-align-buttons-actions-bot">
        <div className="div-align-buttons-actions-botArea">
          <button
            className={`bot-content-buttons-item ${
              bot.status === "inativo" ? "active-btn" : "inactive-btn"
            }`}
            title="Alterar Status"
            disabled={loading}
            onClick={() => changeBotStatus(bot)}
          >
            {bot.status !== "inativo" ? "Interromper" : "Ativar"}
          </button>
          <button
            className="bot-content-buttons-item edit-btn"
            title="Editar Bot"
            onClick={() => {
              selectBot(bot);
              showModal();
            }}
          >
            Editar
          </button>
        </div>

        <div className="div-align-buttons-actions-botArea">
          {/* // button de sincronizar grupos */}
          {bot.status === "ativo" && !sincronizando ? (
            <button
              className="bot-content-buttons-item sincronizar-btn "
              title="Importar contatos que tenham permitido a sua visualização ou adicionado este Número na sua lista de contatos"
              onClick={() => sincronyze(bot)}
            >
              {" "}
              Sincronizar Contatos{" "}
            </button>
          ) : bot.status === "ativo" && sincronizando ? (
            <button
              className="bot-content-buttons-item"
              title="Importar contatos que tenham permitido a sua visualização ou adicionado este Número na sua lista de contatos"
            >
              {" "}
              Sincronizando...{" "}
            </button>
          ) : null}

          {bot.status === "ativo" && !sincronizandoGrupos ? (
            <button
              className="bot-content-buttons-item sincronizar-btn "
              title="grupos"
              onClick={() => sincronyzeGroup(bot)}
            >
              Sincronizar Grupos
            </button>
          ) : bot.status === "ativo" && sincronizandoGrupos ? (
            <button
              className="bot-content-buttons-item"
              title="Grupos"
            >
              {" "}
              Sincronizando...{" "}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BotButtons;
