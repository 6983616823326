import { useEffect, useState, useContext, createContext } from "react";
import { UserContext } from "../UserProvider";
import { FlowBotContext } from "./FlowBotProvider";
import ApiUsers from "../../ApiUsers";
const PesqPrContext = createContext();
const PesqPrProvider = ({ children }) => {
  const context = useContext(UserContext);
  const flowBotContext = useContext(FlowBotContext);
  const apiSocket = context.socket;
  const botSocket = flowBotContext.socket;
  const [pesquisas, setPesquisas] = useState(
    context.pref.services.voxbot.pesquisas
      ? context.pref.services.voxbot.pesquisas.map((pesq) => pesq)
      : []
  );
  const [protocolos, setProtocolos] = useState([]);
  const [protocolosAtts, setProtocolosAtts] = useState([]);
  const [votos, setVotos] = useState([]);

  useEffect(() => {
    let newPesquisas = context.pref.services.voxbot.pesquisas
      ? [...context.pref.services.voxbot.pesquisas]
      : [];
    newPesquisas = newPesquisas.filter(
      (pesquisa) => pesquisa != null && pesquisa != undefined
    );
    setPesquisas(newPesquisas);
  }, [context.pref.services.voxbot.pesquisas, votos]);

  useEffect(() => {
    let votos = protocolos.filter((pr) => pr.more.voto);
    votos = votos.map((prot) => {
      prot.more.voto.protocolo = prot.numero;
      prot.more.voto.att = prot.entrada?.by;
      prot.more.voto.participantes = prot.more.participantes;
      return prot.more.voto;
    });

    votos = votos.filter((voto) => voto != null);
    setVotos(votos);
  }, [protocolos]);

  useEffect(() => loadProtocolos(), []);

  useEffect(() => {
    if (botSocket) initSockEvents();
    return () => {
      if (botSocket) setOff();
    };
  }, [pesquisas, protocolos]);
  useEffect(() => {
    if (apiSocket) initApiSockEvents();
    return () => {
      if (apiSocket) setOffApi();
    };
  }, [pesquisas, protocolos]);

  function initSockEvents() {
    botSocket.on("pesquisa", (pesquisa) => {
      setPesquisas([...pesquisas, pesquisa]);
    });

    botSocket.on("protocolo", (protocolo) => {
      setProtocolos([...protocolos, protocolo]);
    });
  }
  function setOff() {
    botSocket.off("pesquisa");
    botSocket.off("protocolo");
  }
  function initApiSockEvents() {
    apiSocket.on("update pesquisa", (pesquisa) => {
      updatePesquisa(pesquisa);
    });
    apiSocket.on("delete pesquisa", (pesquisaId) => {
      deletePesquisa(pesquisaId);
    });
    apiSocket.on("protocolo", (protocolo) => {
      setProtocolos([...protocolos, protocolo]);
    });
  }
  function setOffApi() {
    apiSocket.off("pesquisa");
    apiSocket.off("protocolo");
  }

  function loadProtocolos() {
    ApiUsers.get("/protocolos")
      .then((res) => {
        let protocols = res.data ? res.data.reverse() : [];
        setProtocolos(protocols);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deletePesquisa(pesquisaId) {
    let newPesquisas = pesquisas.filter(
      (pesquisa) => pesquisa._id !== pesquisaId
    );
    setPesquisas(newPesquisas);
  }

  function updatePesquisa(pesquisa) {
    let exist = pesquisas.find((pesq) => pesq._id === pesquisa._id);
    let newPesquisas = [];
    if (exist) {
      newPesquisas = pesquisas.map((pesq) => {
        if (pesq._id === pesquisa._id) {
          pesq = pesquisa;
        }
        return pesq;
      });
    } else newPesquisas = [...pesquisas, pesquisa];
    setPesquisas(newPesquisas);
  }

  function setAttOnPr(pr) {
    let atts = [];
    pr.more?.participantes?.map(part => {

      let atendente = context.pref.users.find(user => user._id === part) ? { ...context.pref.users.find(user => user._id === part) } : null;
      if (atendente) {
        atts.push(atendente)
      }
      else {
        let bot = context.pref.services.voxbot.bots.find(b => b._id === part) ? { ...context.pref.services.voxbot.bots.find(bot => bot._id === part) } : null;
        if (bot) {
          bot.isbot = true;
          atts.push(bot)
        }
      }

    })
    pr.atts = atts;
    return pr;
  }

  function sortByCreationDate(array) {
    return array.sort((a, b) => {
      let entradaA = a.entrada ? a.entrada.date : null;
      let entradaB = b.entrada ? b.entrada.date : null;
      if (entradaA && entradaB) {
        return new Date(entradaB) - new Date(entradaA)
      }
      else if (entradaA) {
        return -1
      }
      else if (entradaB) {
        return 1
      }
    })
  }

  useEffect(() => {
    if (protocolos.length === 0) return
    let ordenedPrs = sortByCreationDate([...protocolos])
    ordenedPrs = ordenedPrs?.map(pr => {
      return setAttOnPr(pr)
    })
    setProtocolosAtts(ordenedPrs)
  }, [protocolos]);

  return (
    <PesqPrContext.Provider value={{ pesquisas, protocolos, votos, protocolosAtts }}>
      {children}
    </PesqPrContext.Provider>
  );
};

export { PesqPrProvider, PesqPrContext };
