import React from "react";
import { MdInfoOutline } from "react-icons/md";

const FlowsSelects = ({
  flows,
  bot,
  changeMainFlow,
  changeFirstContactFlow,
  changeResearch,
  changeTempoFinalizacao,
  pesquisas,
}) => {
  return (
    <>
      <div className="bot-form-one">
        <div className="bot-form-input-container-flow">
          <div className="information">
            <label>
              <h5 className="title-flow">Flow principal</h5>
            </label>
            <MdInfoOutline
              className="icon-info"
              size={20}
              title="Este é o fluxo de diálogo que o bot inicia com qualquer contato que já tenha entrado em contato com a organização, pelo bot ou ao final do Flow Principal."
              alt="Este é o fluxo de diálogo que o bot inicia com qualquer contato que já tenha entrado em contato com a organização, pelo bot ou ao final do Flow Principal."
            />
          </div>

          <select
            className="bot-form-select"
            onChange={(e) => changeMainFlow(e)}
            value={bot.flow}
          >
            <option value="">Selecione o flow principal</option>
            {flows.map((f, index) => (
              <option key={index} value={f._id}>
                {f.nome}
              </option>
            ))}
          </select>
        </div>

        <div className="bot-form-input-container-flow">
          <div className="information">
            <label>
              <h5 className="title-flowInicial">Flow de contato inicial</h5>
            </label>
            <MdInfoOutline
              className="icon-info"
              size={20}
              title="Este será o fluxo de dialogo utilizado pelo bot para clientes que nunca entraram em contato com os bots da organização."
              alt="Este será o fluxo de dialogo utilizado pelo bot para clientes que nunca entraram em contato com os bots da organização."
            />
          </div>

          <select
            className="bot-form-select"
            onChange={(e) => changeFirstContactFlow(e)}
            value={bot.primeiroFlow}
          >
            <option value="">Selecione o flow de contato inicial</option>
            {flows.map((f, index) => (
              <option key={index} value={f._id}>
                {f.nome}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="bot-form-four">
        <div className="bot-form-four-div">
          <h5
            title="Pesquisa de Finalização de Atendimento"
            className="title-flowAtt"
          >
            P. de Finalização de Atendimento
          </h5>
          <select
            className="bot-form-select"
            onChange={(e) => changeResearch(e)}
            value={bot.pesquisa}
          >
            <option value={false}>Selecione Uma Pesquisa</option>
            {pesquisas.map((p, index) => (
              <option
                key={index}
                value={p._id}
                // selected={bot.pesquisa === p._id}
              >
                {p.nome}
              </option>
            ))}
          </select>
        </div>

        <div className="bot-form-four-div">
          <h5
            title="Tempo de finalização do Atendimento"
            className="title-tempoAtt"
          >
            T. de finalização do Atendimento
          </h5>
          <input
            type="time"
            className="input-timeAtt-bot"
            placeholder="Tempo de finalização do Atendimento"
            value={bot?.config?.tempoFinalizacao || "00:00"}
            onChange={(e) => changeTempoFinalizacao(e)}
          />
        </div>
      </div>
      <br />
    </>
  );
};

export default FlowsSelects;
