import React from "react";
import "./buttonsfooter.css";

export default function ButtonsFooter({ onClose, onClick, disabled }) {
  return (
    <div className="add-footer-wtz">
      <button className="cancel-button" onClick={onClose}>
        Cancelar
      </button>
      <button
        className={
          disabled ? "save-button-grupos-disabled" : "save-button-grupos"
        }
        onClick={onClick}
        disabled={disabled}
      >
        Salvar
      </button>
    </div>
  );
}
