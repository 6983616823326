import React, { useContext, useState } from "react";
import ApiCidadao from "../../../../../ApiCidadao";
import Modal from "../Modal";
import { generatePDFtable } from "../pdf";
import { UserContext } from "../../../../../contexts/UserProvider";
import { SpecialtyContext } from "../../../../../contexts/SpecialtyProvider";
import { ProfessionalContext } from "../../../../../contexts/ProfessionalProvider";
import { LocationContext } from "../../../../../contexts/LocationProvider";
import { ServiceContext } from "../../../../../contexts/ServiceProvider";
import "./styles.css";

export default function ButtonRelatorioAgenda(props) {
  const { pref } = useContext(UserContext);
  const { especialidades } = useContext(SpecialtyContext);
  const { profissionais } = useContext(ProfessionalContext);
  const { locais } = useContext(LocationContext);
  const { servicos } = useContext(ServiceContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [date, setDate] = useState({
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const handleChangeDate = (pickerName, value) => {
    setDate({
      ...date,
      [pickerName]: value,
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setDate({
      start: new Date().toISOString().split("T")[0],
      end: new Date().toISOString().split("T")[0],
    });
    setIsModalOpen(false);
  };

  const gerarRelatorio = async () => {
    await ApiCidadao.post("/agendamento/historicoAgendamentos", {
      prefeitura: pref._id,
      intervalo: date,
      agendas: props.agendaSelecionada,
    })
      .then((response) => {
        generatePDFtable(
          response.data.quantidades,
          props.agendaSelecionada,
          {
            especialidades,
            profissionais,
            locais,
            servicos,
            pref,
          },
          date
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <button className="button-resumo-agenda" onClick={openModal}>
        Resumo agenda
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        date={date}
        setDate={setDate}
        onConfirm={gerarRelatorio}
        onChangeDate={handleChangeDate}
      />
    </div>
  );
}
