import { useEffect, useState } from 'react'
import { BsChevronDown, BsTrash3, BsArrowRightShort, BsArrowLeftShort, BsChevronUp } from 'react-icons/bs';
import { AiFillFile } from 'react-icons/ai';


export default function Files(props) {
    const { files, setFiles, minimizeFiles, setMinimizeFiles } = props
    const [currentFile, setCurrentFile] = useState(0)

    function deleteFile(file) {
        let newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles)
    }

    useEffect(() => {
        if (currentFile < 0) setCurrentFile(files.length - 1)
        if (currentFile >= files.length) setCurrentFile(0)
    }, [files])


    return (
        <div>{files.length > 0 && <>

            <span className={minimizeFiles ? "maximize-files-icon" : "hidden"} title="Você possui arquivos anexados, clique para maximizar." onClick={() => setMinimizeFiles(false)}>
                <BsChevronUp style={{ fontSize: 20, color: "#11111" }} />
            </span>

            <div className="files-msg-container" style={minimizeFiles ? { display: 'none' } : null}>

                <div className='files-msg-container-box'>

                    <div className="files-msg-container-header">
                        <span style={{ fontSize: 18, color: "black", fontWeight: 600 }}>Visualização do arquivo </span>

                        <span onClick={() => setMinimizeFiles(true)} title="Clique para minimizar os arquivos anexados" style={{ marginLeft: '20px' }}>
                            <BsChevronDown style={{ fontSize: 30, cursor: 'pointer' }} color='#9ac31c' />
                        </span>
                    </div>

                    {files && Object.keys(files).map((file) => {

                        let thatFile = files[file]
                        let fileType = thatFile.type
                        let fileIndex = parseInt(file)

                        return (
                            <div key={file} className="files-msg-container-body" style={currentFile === fileIndex ? { display: 'flex' } : { display: 'none' }}>
                                {files.length > 1 && <BsArrowLeftShort className='left-file-icon' color='#9ac31c' size={30} onClick={() => setCurrentFile(fileIndex - 1)} style={currentFile === 0 ? { display: 'none' } : { cursor: 'pointer', display: 'flex' }} />}
                                {fileType.includes("image") ?
                                    <img className="img-send-msg-file" src={URL.createObjectURL(thatFile)} alt="" />
                                    :
                                    <AiFillFile style={{ fontSize: 170 }} color='#9ac31c' />
                                }

                                <div className="img-send-msg-file-desc">
                                    <b> Nome do anexo: </b> <span>{thatFile.name.length > 30 ? thatFile.name.slice(0, 25) + "..." : thatFile.name}</span>
                                    <b> Formato: </b> <span>{thatFile.type}</span>
                                </div>

                                <div className='msg-file-delete-box' style={currentFile === fileIndex ? { display: 'flex' } : { display: 'none' }}>
                                    <BsTrash3 className='msg-file-delete-icon' size={20} onClick={e => { deleteFile(thatFile) }} title="Clique para remover o arquivo selecionado" />
                                </div>
                                {files.length > 1 && <BsArrowRightShort className='right-file-icon' color='#9ac31c' size={30} onClick={() => setCurrentFile(currentFile + 1)} style={currentFile === files.length - 1 ? { display: 'none' } : { cursor: 'pointer' }} />}
                            </div>
                        )

                    })}
                    {files.length > 1 && <div className="files-msg-container-footer-carousel">
                        {files && Object.keys(files).map((file) => {
                            let fileIndex = parseInt(file)
                            return (
                                <div key={file} onClick={() => setCurrentFile(fileIndex)}>
                                    <p style={{ cursor: 'pointer' }}>.</p>
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </div>

        </>}</div >
    )
}
