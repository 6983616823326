import React from "react";

const InfoItemUnic = ({ label, value }) => {
  return (
    <div className="contacts-unique-solo">
      <p>{label}</p>
      <div className="contacts-unique-container">
        <p>{value || "--"}</p>
      </div>
    </div>
  );
};

export default InfoItemUnic;
