import React, {useContext} from 'react';
import Action from './Action';
import { UserContext } from '../../../contexts/UserProvider';

export default function FlowActions(props) {

    const defaultMessage = [
        { type: "Local", message : "Qual local deseja atendimento?" },
        { type: "Especialidade", message : "Qual especialidade deseja?" },
        { type: "Agenda", message : "Datas disponiveis" },
        { type: "Sucesso", message : "Agenda marcada com sucesso" },
        { type: "Indisponivel", message : "Não temos horários disponíveis"},
    ];

    const context = useContext(UserContext);
    let agendaAtivo =context.pref.services.agenda?.ativo;
    const sucessMessage = [
        { type: "Inscrição realizada", message : "Inscrição realizada com sucesso" },
        
    ];

    const actions = [
        {
            tipo: "Resposta Simples",
            nome: "",
            
            
        },
        {
            tipo: "Pergunta Alternativa",
            nome: "",
            mensagem: "",
            alternativas: []
        },
        {
            tipo: "Pergunta Descritiva",
            nome: "",
            mensagem: "",
            alternativas: []
        },
        {
            tipo: "Pergunta com Variável",
            nome: "",
            mensagem: "",
            alternativas: []
        },
        
        // {
        //     tipo: "Enquete",
        //     nome: "",
        //     mensagem: "",
        //     alternativas: []
        // },
    ];

    if(agendaAtivo){
        actions.push({
            tipo: "Agendamento",
            nome: "",
            mensagem: "",
            defaultMessage,
        },
        // {
        //     tipo: "Listagem",
        //     nome: "",
        //     mensagem: "",
        //     sucessMessage,
        //     local: "",
            
        // },

        );
    }
    return (

        <nav className="flow-actions">
            {actions.map((action, index) => {

                let color;

                props.actionColors.forEach((item) => {
                    if (item.name === action.tipo) color = item.color;
                })

                return <Action key={index} handleDrag={(e) => props.handleDrag(e, action)} action={action} color={color} />
            })}
        </nav>

    );
}

