import { useEffect, useState } from "react";

export const Conversa = ({ reuniao }) => {
  const [mensagens, setMensagens] = useState([]);

  useEffect(() => {
    const novasMensagens = reuniao.mensagens ? reuniao.mensagens : [];
    setMensagens(novasMensagens);
  }, [reuniao]);

  const formatarData = (reuniao) => {
    const data = new Date(reuniao.createdAt);
    const horario = data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    const dataFormatada = data.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return `${dataFormatada} - ${horario}`;
  };

  return (
    <>
      <h1>Conversa</h1>
      <div className="historico-cards-modal-conversa">
        {mensagens?.map((mensagem, index) => {
          if (!mensagem.text && !mensagem.event) return null;
          return (
            <div key={index}>
              <p className="mensagem">
                {mensagem.event ? (
                  <span>
                    <strong>
                      {mensagem.sender} {mensagem.event}.
                    </strong>
                  </span>
                ) : (
                  mensagem.text && (
                    <span>
                      <strong>{mensagem.sender}:</strong> {mensagem.text}
                    </span>
                  )
                )}
              </p>
              <p className="mensagem-footer">{formatarData(mensagem)}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};
