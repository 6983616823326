import React from "react";
import { BiLock } from "react-icons/bi";

const Senha = (props) => {
  return (
    <div className="login-label">
      <label htmlFor="senha">Senha</label>
      <div className="login-input-content">
        <input
          name="senha"
          className="login-input"
          type="password"
          onChange={(event) => props.setInputs("senha", event.target.value)}
        />
        <BiLock className="login-icon" />
      </div>
    </div>
  );
};

export default Senha;
