import { useEffect, useState } from "react";
import { ModalConviteBot } from "./modalConviteBot";
import "../../../../styles.css";

export const ConviteBot = ({ conviteReuniao, isEditMsg }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conviteReuniaoBot, setConviteReuniaoBot] = useState({});

  const handleAddConvidadoBot = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const isConviteSalaFilled = () => {
    return conviteReuniaoBot.mensagem;
  };

  useEffect(() => {
    const novoConviteReuniao = conviteReuniao ? conviteReuniao : {};
    setConviteReuniaoBot(novoConviteReuniao);
  }, [conviteReuniao]);

  return (
    <>
      <button
        className="convite-sala-form-button"
        type="button"
        disabled={!isConviteSalaFilled() || isEditMsg}
        onClick={handleAddConvidadoBot}
      >
        Adicionar convidados
      </button>
      {isModalOpen && (
        <div className="convite-bot-modal-bg">
          <ModalConviteBot
            setIsModalOpen={setIsModalOpen}
            conviteReuniaoBot={conviteReuniaoBot}
          />
        </div>
      )}
    </>
  );
};
