import React, { useState, useContext, useEffect } from "react";
import { FlowBotContext } from "../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { PrefContext } from "../../../../../contexts/ChatBotProvider/PrefProvider";
import HeaderTitle from "../../CreateForm/HeaderTitle";
import ButtonsFormComands from "./components/ButtonsFormComands";
import CardCommands from "./components/CardComands";
import ButtonNewAction from "../../components/ButtonNewAction/ButtonNewAction";
import "./botComands.css";

function Comands(props) {
  const FContext = useContext(FlowBotContext);
  const PContext = useContext(PrefContext);
  const [bot, setBot] = useState(props.bot);
  const [flows, setFlows] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const selectActions = [
    { name: "Redirecionar p/ Grupo de Atendentes", value: "send-to-chat" },
    { name: "Redirecionar p/ outro Flow", value: "send-to-flow" },
  ];

  useEffect(() => {
    let newFlows = [...FContext.flows];
    let newAtendentes = [...PContext.atendentes];
    setAtendentes(newAtendentes);
    setFlows(newFlows);
  }, [FContext.flows, PContext.atendentes]);

  function changeAlternative(e, index, op) {
    let newBot = { ...bot };
    newBot.config.comandosGerais[index][op] = e.target.value;
    setBot(newBot);
  }

  function addComando() {
    let newBot = { ...bot };
    let newComandos = newBot.config.comandosGerais
      ? [...newBot.config.comandosGerais, { valor: "", action: "", next: "" }]
      : [{ valor: "", action: "", next: "" }];
    newBot.config.comandosGerais = newComandos;
    setBot(newBot);
  }

  function deleteComando(index) {
    let newBot = { ...bot };
    let newComandos = newBot.config.comandosGerais
      ? [...newBot.config.comandosGerais]
      : [];
    newComandos.splice(index, 1);
    newBot.config.comandosGerais = newComandos;
    setBot(newBot);
  }

  return (
    <>
      <div className="bot-form-cd">
        <div className="cont-align-text-buttons-comands">
          <HeaderTitle title="Comandos Gerais" hideIcon={true} />

          <ButtonsFormComands props={props} />
        </div>
        <ButtonNewAction onClick={addComando} title="Adicionar novo comando" />
        <div className="comando-wrapper-cd">
          {bot.config.comandosGerais?.map((comand, index) => {
            return (
              <CardCommands
                key={index}
                index={index}
                comand={comand}
                selectActions={selectActions}
                flows={flows}
                atendentes={atendentes}
                deleteComando={deleteComando}
                changeAlternative={changeAlternative}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Comands;
