import React, { useEffect, useState } from "react";
import "./inputs.css";

export default function Inputs({ form, onChangeForm, bots }) {
  return (
    <div className="container-inputs-modal">
      <div className="container-input-align">
        <p>
          Nome<span style={{ color: "red" }}> *</span>
        </p>
        <input
          type="text"
          placeholder="ex: Tecnologia"
          name="nome"
          value={form?.nome}
          onChange={onChangeForm}
        />
      </div>

      <div className="container-input-align">
        <p>Descrição</p>
        <input
          name="descricao"
          value={form?.descricao}
          onChange={onChangeForm}
          type="text"
          placeholder="Escreva algo ..."
        />
      </div>

      <div className="container-input-align">
        <p>
          Bot <span style={{ color: "red" }}> *</span>
        </p>
        <select name="bot" value={form?.bot} onChange={onChangeForm}>
          <option value="">Selecione um bot</option>
          {bots?.map((bot, index) => (
            <option key={index} value={bot._id}>
              {bot?.nome}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
