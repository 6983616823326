import React, { useState, useContext, useEffect } from 'react';
import ApiUsers from '../../../../ApiUsers';
import { PrefContext } from '../../../../contexts/ChatBotProvider/PrefProvider';
import GroupsList from './GroupsShow/GroupsList';
import GroupsEdit from './GroupsShow/GroupsEdit';
import GroupsListUsers from './GroupsShow/GroupsListUsers';
import DeleteAlert from '../inputs/DeleteAlert';
import GroupsAddUsers from './GroupsShow/GroupsAddUsers';
import Alert from '../inputs/Alerts/Alert';
import './Group.css';

export default function GroupsShow() {

    const prefContext = useContext(PrefContext);
    const [grupos, setGrupos] = useState([]);
    const [gruposOnShow, setGruposOnShow] = useState([]);
    const [selectedGrupo, setSelectedGrupo] = useState({});
    const [listUsers, setListUsers] = useState(false);
    const [edit, setEdit] = useState(false);
    const [addUsers, setAddUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
        delete: <DeleteAlert data={alert.props.data} nome={alert.props.nome} action={() => alert.props.action()} quit={() => quitAlert()} />
    }

    useEffect(() => {
        setGrupos(prefContext.grupos);
        setGruposOnShow(prefContext.grupos);
        if (selectedGrupo) {
            let newGrupo = prefContext.grupos.find((g) => g._id === selectedGrupo._id);
            setSelectedGrupo(newGrupo);
        }
    }, [prefContext.grupos]);

    function quitAlert() {
        setAlert({ visible: false, type: "", props: {} });
    }

    function createAlert(type, props) {
        setAlert({ visible: true, type, props });
    }

    function delConfirm(grupo) {
        createAlert("delete", { data: "grupo", nome: grupo.nome, action: () => delGroup(grupo) });
    }

    function searchGroups(e) {
        let search = e.target.value;
        let newGroups = []
        if (search.trim() !== "") newGroups = grupos.filter((g) => g.nome.toLowerCase().includes(search.toLowerCase()));
        else newGroups = grupos;
        setGruposOnShow(newGroups);
    }

    function delGroup(grupo) {
        setLoading(true);
        ApiUsers.post("grupos/delete", { grupo }).then((res) => {
            setLoading(false);
            createAlert("normal", { title: "Sucesso!", placeholder: "Grupo deletado com sucesso!" });
        }).catch(err => console.log(err))
    }

    return (
        <div className="groups-container">
            {alert.visible && (alertType[alert.type])}
            {edit ? <>
                <GroupsEdit
                    grupo={selectedGrupo}
                    alert={(type, props) => createAlert(type, props)}
                    goBack={() => setEdit(false)}
                />
            </> : listUsers ? <>
                <GroupsListUsers
                    grupo={selectedGrupo}
                    alert={(type, props) => createAlert(type, props)}
                    goBack={() => setListUsers(false)}
                />
            </> : addUsers ? <>
                <GroupsAddUsers
                    grupo={selectedGrupo}
                    alert={(type, props) => createAlert(type, props)}
                    goBack={() => setAddUser(false)}
                />
            </> : <>

                <GroupsList
                    grupos={gruposOnShow}
                    loading={loading}
                    search={(e) => searchGroups(e)}
                    delConfirm={(e, grupo) => delConfirm(grupo)}
                    addUsers={(e, grupo) => { setSelectedGrupo(grupo); setAddUser(true) }}
                    listUsers={(e, grupo) => { setSelectedGrupo(grupo); setListUsers(true) }}
                    edit={(e, grupo) => { setSelectedGrupo(grupo); setEdit(true) }}
                />

            </>}
        </div>
    );
}



