import { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import {MdDeleteForever} from 'react-icons/md'
import {AiOutlineSmile} from 'react-icons/ai'
import Picker from  'emoji-picker-react';

let groupNames = {
    smileys_people: 'Mais Comuns',
    animals_nature: 'Animais e a Natureza',
    food_drink: 'Comilança e afins',
    travel_places: 'Viagens e Lugares',
    activities: 'Atividades',
    objects: 'Aleatorios',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    recently_used: 'Recentes',
}
function PesquisaForm  (props) {    
    const [pesquisa, setPesquisa] = useState({...props.pesquisa})
    const [pickersOn, setPickersOn] = useState([pesquisa.alternativas.forEach(map=> false)])
    function showPicker(indexAlternativa){
        let novaPickersOn = [...pickersOn]
        novaPickersOn[indexAlternativa] =novaPickersOn[indexAlternativa]? false : true
        setPickersOn(novaPickersOn)
    }
  
        return (        
        <div>
            <Form className='research-form'>
                <Form.Group >
                    <h4>Nome da Pesquisa:</h4>
                    <Form.Control value={pesquisa.nome} type="text" placeholder="Exemplo : Pesquisa de Satisfação" style={{ width:600 }}  onChange={e=>setPesquisa({...pesquisa, nome: e.target.value})} />
                </Form.Group>
                <Form.Group >
                    <h4>Mensagem:</h4>
                    <Form.Control as="textarea"  value={pesquisa.mensagem} type="text" placeholder="Sua Mensagem"  style= {{height: '90px' , width: 600}} 
                        onChange= {e=>{
                            let newPesquisa = {...pesquisa}
                            newPesquisa.mensagem = e.target.value
                            setPesquisa(newPesquisa)
                        }}
                    />
                </Form.Group>
                <hr style={{width: 600,backgroundColor:"#142a4c",height:"3px"}} />
                <Button variant="success" style={{color:"#142a4c",backgroundColor:"#9ac31c",border:"1px solid #9ac31c "}} onClick={props.newAlternative}>Adicionar Alternativa</Button>
                <div className='alternatives'>
                    {
                        pesquisa.alternativas.map((alternativa, index) => {
                            return <Form.Group key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin:10 }}>
                                <Form.Control value={alternativa.valor} type="text" placeholder="Alternativa"  style={{ width:560 }}
                                    onChange={e=>{
                                        let newPesquisa = {...pesquisa}
                                        pesquisa.alternativas[index].valor = e.target.value
                                        setPesquisa(newPesquisa)
                                    }
                                }/>

                                 
                                <Form.Check type="checkbox" label="Botão" checked={alternativa.botao} onChange={e=>{
                                    let newPesquisa = {...pesquisa}
                                    pesquisa.alternativas[index].botao = e.target.checked == false ?  false : true
                                    setPesquisa(newPesquisa)
                                    }} style={{ marginLeft:17 }}/>
                                <AiOutlineSmile onClick={()=>{showPicker(index)}} style={{ color: 'green', cursor: 'pointer', marginLeft:10,fontSize:"2rem" }}/>
                                {
                                    pickersOn[index] && <Picker groupNames={groupNames} onEmojiClick={ (event, emojiObject)=>{
                                        let newPesquisa = {...pesquisa}
                                        pesquisa.alternativas[index].valor += emojiObject.emoji
                                        setPesquisa(newPesquisa)
                                        setPickersOn([...pickersOn, false])
                                    }
                                }/>}
                                
                               <MdDeleteForever onClick={()=>{
                                    let newPesquisa = {...pesquisa}
                                    pesquisa.alternativas.splice(index, 1)
                                    setPesquisa(newPesquisa)
                                }
                                }
                                style={{ color: 'red', cursor: 'pointer', marginLeft:10,fontSize:"2rem" }}/>
                               
                                
                            </Form.Group>
                        }) 
                    }
                </div>
                <hr style={{width: 600,backgroundColor:"#142a4c",height:"3px"}} />

                <div style={ {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop:10 ,width:"100%"}}>
                <Button onClick={props.closeForm}variant="danger">Cancelar</Button>

                    <Button onClick={()=>{ 
                        if(props.editing) props.editResearch(pesquisa)
                        else props.saveForm(pesquisa)
                    }}>Salvar</Button>
                </div>
            </Form>
        </div>
    );
}

export default PesquisaForm;
