import React, { Component } from "react";
import { Form, Container } from "react-bootstrap";
import ApiUsers from "../../../../ApiUsers";
import GroupCheckBox from "./GroupsShow/GroupCheckbox/GroupCheckbox";
import Alert from "../inputs/Alerts/Alert";
import MiniLoading from "../inputs/MiniLoading";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

class GroupsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grupo: {
        nome: "",
        usuarios: [],
        master: [],
        sala_de_conferencia: "",
      },
      users: [],
      usersOnShow: [],
      selectedUsers: [],
      unselectedUsers: [],
      setores: [],
      departamentos: [],
      filters: {
        nome: "",
        departamento: "",
        setor: "",
      },
      backupUsers: [],
      alert: { visible: false, type: "", props: {} },
      loading: false,
    };
  }

  componentDidMount() {
    let users = this.context.users.filter((u) => u.nome !== "Voxcity");
    let departamentos = this.context.departamentos;
    let setores = [];
    departamentos.forEach((d) => (setores = [...setores, ...d.setores]));
    this.setState({
      users,
      usersOnShow: users,
      backupUsers: users,
      departamentos,
      setores,
    });
  }

  selectUser(checked, user) {
    if (checked) {
      let newState = this.state;
      if (!newState.selectedUsers.includes(user)) {
        newState.selectedUsers.push(user);
        this.setState(newState);
      }
    } else {
      let newState = this.state;
      newState.selectedUsers = this.state.selectedUsers.filter(
        (u) => u !== user
      );
      this.setState(newState);
    }
  }

  unselectUser(checked, user) {
    if (checked) {
      let newState = this.state;
      if (!newState.unselectedUsers.includes(user)) {
        newState.unselectedUsers.push(user);
        this.setState(newState);
      }
    } else {
      let newState = this.state;
      newState.unselectedUsers = this.state.unselectedUsers.filter(
        (u) => u !== user
      );
      this.setState(newState);
    }
  }

  searchUsers() {
    let userList = this.state.users;
    let novoUsers = userList;
    const { nome, departamento, setor } = this.state.filters;

    if (nome.trim() !== "" && !departamento && !setor) {
      novoUsers = userList.filter((user) => {
        if (user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
        return false;
      });
    }
    if (nome.trim() === "" && departamento) {
      novoUsers = userList.filter((user) => {
        if (user.departamentoNome === departamento) return user;
        return false;
      });
    }
    if (nome.trim() === "" && departamento && setor) {
      novoUsers = userList.filter((user) => {
        if (user.departamentoNome === departamento && user.setorNome === setor)
          return user;
        return false;
      });
    }
    if (nome.trim() !== "" && departamento) {
      novoUsers = userList.filter((user) => {
        if (
          user.departamentoNome === departamento &&
          user.nome.toLowerCase().includes(nome.toLowerCase())
        )
          return user;
        return false;
      });
    }
    if (nome.trim() !== "" && departamento && setor) {
      novoUsers = userList.filter((user) => {
        if (
          user.departamentoNome === departamento &&
          user.setorNome === setor &&
          user.nome.toLowerCase().includes(nome.toLowerCase())
        )
          return user;
        return false;
      });
    }
    this.setState({ usersOnShow: novoUsers });
  }

  addFilter(step, filter) {
    let newStateFilter = this.state.filters;
    if (step === "departamento") {
      newStateFilter.departamento = filter;
      newStateFilter.setor = "";
      this.setState({ filters: newStateFilter });
    } else if (step === "setor") {
      newStateFilter.setor = filter;
      this.setState({ filters: newStateFilter });
    } else {
      newStateFilter.nome = filter;
      this.setState({ filters: newStateFilter });
    }
    this.searchUsers();
  }

  selectAll(checked) {
    if (checked) {
      let selectAll = this.state.usersOnShow;
      this.setState({ selectedUsers: selectAll });
    } else {
      this.setState({ selectedUsers: [] });
    }
  }

  unselectAll(checked) {
    if (checked) {
      let unselectAll = this.state.grupo.usuarios;
      this.setState({ unselectedUsers: unselectAll });
    } else {
      this.setState({ unselectedUsers: [] });
    }
  }

  addUser(e) {
    e.preventDefault();
    let selectUsers = this.state.selectedUsers;
    let usersList = this.state.grupo.usuarios;
    if (selectUsers.length > 0) {
      let attUsers1 = this.state.users.filter((u) => {
        if (!selectUsers.includes(u)) return u;
        return false;
      });
      let attUsers2 = this.state.usersOnShow.filter((u) => {
        if (!selectUsers.includes(u)) return u;
        return false;
      });
      selectUsers.forEach((u) => {
        usersList.push(u);
      });
      let newState = this.state.grupo;
      newState.usuarios = usersList;
      this.setState({
        users: attUsers1,
        usersOnShow: attUsers2,
        grupo: newState,
        selectedUsers: [],
      });
    }
  }

  delUser(e) {
    e.preventDefault();
    let unselectUsers = this.state.unselectedUsers;
    let noUsersList = this.state.usersOnShow;
    if (unselectUsers.length > 0) {
      let newUsersList = this.state.grupo.usuarios.filter((u) => {
        if (!unselectUsers.includes(u)) return u;
        return false;
      });
      let newAdminList = this.state.grupo.master.filter((u) => {
        if (!unselectUsers.includes(u)) return u;
        return false;
      });
      unselectUsers.forEach((u) => {
        noUsersList.push(u);
      });
      let newState = this.state.grupo;
      newState.usuarios = newUsersList;
      newState.master = newAdminList;
      this.setState({
        users: noUsersList,
        usersOnShow: noUsersList,
        grupo: newState,
        unselectedUsers: [],
      });
    }
  }

  addAdmin(checked, user) {
    if (checked) {
      let adminList = this.state.grupo.master;
      if (!adminList.includes(user)) {
        adminList.push(user);
        let newState = this.state.grupo;
        newState.master = adminList;
        this.setState({ grupo: newState });
      }
    } else {
      let adminList = this.state.grupo.master;
      let newAdminList = adminList.filter((u) => u !== user);
      let newState = this.state.grupo;
      newState.master = newAdminList;
      this.setState({ grupo: newState });
    }
  }

  addName(name) {
    let newGrupo = this.state.grupo;
    newGrupo.nome = name;
    this.setState({ grupo: newGrupo });
  }

  addConferenceRoom(name) {
    let newGrupo = this.state.grupo;
    newGrupo.sala_de_conferencia = name;
    this.setState({ grupo: newGrupo });
  }

  addGrupo(e) {
    e.preventDefault();
    let newGrupo = this.state.grupo;
    let UserId = [];
    let AdminId = [];
    if (
      newGrupo.nome.trim() === "" ||
      newGrupo.sala_de_conferencia.trim() === ""
    ) {
      this.createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha os campos necessários!",
      });
      return;
    }
    for (let user in newGrupo.usuarios) {
      UserId.push(newGrupo.usuarios[user]._id);
    }
    for (let admin in newGrupo.master) {
      AdminId.push(newGrupo.master[admin]._id);
    }
    newGrupo.usuarios = UserId;
    newGrupo.master = AdminId;
    newGrupo.nome = newGrupo.nome.trim();
    newGrupo.sala_de_conferencia = newGrupo.sala_de_conferencia.trim();
    this.setState({ loading: true });
    ApiUsers.post("/grupos/novo", { group: newGrupo })
      .then((res) => {
        const { backupUsers, setores, departamentos } = this.state;
        let newState = {
          grupo: {
            nome: "",
            usuarios: [],
            master: [],
            sala_de_conferencia: "",
          },
          users: backupUsers,
          usersOnShow: backupUsers,
          selectedUsers: [],
          unselectedUsers: [],
          setores: setores,
          departamentos: departamentos,
          filters: {
            nome: "",
            departamento: "",
            setor: "",
          },
          backupUsers: [],
          alert: { visible: false, type: "", props: {} },
          loading: false,
        };
        this.setState(newState);
        this.createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Grupo criado com sucesso!",
        });
      })
      .catch((err) => console.log(err));
  }

  quitAlert() {
    this.setState({ alert: { visible: false, type: "", props: {} } });
  }

  createAlert(type, props) {
    this.setState({ alert: { visible: true, type, props } });
  }

  render() {
    const alertType = {
      normal: (
        <Alert
          title={this.state.alert.props.title}
          placeholder={this.state.alert.props.placeholder}
          quit={() => this.quitAlert()}
        />
      ),
    };

    return (
      <div className="group-create-container">
        {this.state.alert.visible && alertType[this.state.alert.type]}

        <Container style={{ padding: 0 }}>
          <Form className="group-gen-form">
            <h2
              style={{
                width: "100%",
                textAlign: "center",
                margin: "20px 0px 20px 0px",
              }}
            >
              {" "}
              Criação de Grupo de Conversa{" "}
            </h2>

            <Form.Group
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Form.Label style={{ fontSize: 20, width: "30%" }}>
                Nome do grupo:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome do grupo"
                value={this.state.grupo.nome}
                onChange={(e) => this.addName(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Form.Label style={{ fontSize: 20, width: "30%" }}>
                Sala de Conferência:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Sala de Conferência"
                value={this.state.grupo.sala_de_conferencia}
                onChange={(e) => this.addConferenceRoom(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <GroupCheckBox
                addFilter={(step, filter) => this.addFilter(step, filter)}
                filters={this.state.filters}
                setores={this.state.setores}
                departamentos={this.state.departamentos}
                usersOnShow={this.state.usersOnShow}
                selectedUsers={this.state.selectedUsers}
                selectAll={(e) => this.selectAll(e)}
                selectUser={(e, user) => this.selectUser(e, user)}
                unselectUser={(e, user) => this.unselectUser(e, user)}
                unselectedUsers={this.state.unselectedUsers}
                addUser={(e) => this.addUser(e)}
                delUser={(e) => this.delUser(e)}
                grupo={this.state.grupo}
                unselectAll={(e) => this.unselectAll(e)}
                addAdmin={(e, user) => this.addAdmin(e, user)}
                loading={this.state.loading}
              />
            </Form.Group>
          </Form>

          <div
            style={{
              marginRight: "20px",
              paddingBottom: 20,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button
              className="group-save-btn"
              onClick={(e) => this.addGrupo(e)}
              title="Salvar"
              disabled={this.state.loading}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 70,
              }}
            >
              {this.state.loading ? <MiniLoading /> : "Salvar"}
            </button>
          </div>
        </Container>
      </div>
    );
  }
}

GroupsCreate.contextType = PrefContext;
export default GroupsCreate;
