import { useContext, useState, useEffect } from "react";
import { VideoContext } from "../../../../contexts/VideoProvider";
import { FaLink } from "react-icons/fa";
import { MensagemConvite } from "./mensagemConvite";

export const InfoReuniao = ({
  conviteSala,
  setConviteSala,
  mensagemConvitePadrao,
  isEditMsg,
  setIsEditMsg,
}) => {
  const { linkMinhaSala } = useContext(VideoContext);
  const [infoReuniao, setInfoReuniao] = useState({
    titulo: "",
    mensagem: "",
  });
  const [msgConvite, setMsgConvite] = useState("");

  const copiarLink = () => {
    navigator.clipboard.writeText(linkMinhaSala);
  };

  const updateMensagem = (novoTitulo) => {
    const novaMensagem = msgConvite.replace(
      /Título da reunião: (.*)\n/,
      `Título da reunião: ${novoTitulo}\n`
    );
    setConviteSala({
      ...infoReuniao,
      titulo: novoTitulo,
      mensagem: novaMensagem,
    });
  };

  const changeConviteSala = () => {
    setConviteSala({
      ...infoReuniao,
      mensagem: msgConvite,
    });
  };

  const onChangeTitulo = (event) => {
    const { value } = event.target;
    const isMsgEqualDefault = msgConvite === mensagemConvitePadrao;
    if (isMsgEqualDefault) {
      setConviteSala({
        ...infoReuniao,
        titulo: value,
      });
      setInfoReuniao({
        ...infoReuniao,
        titulo: value,
      });
      updateMensagem(value);
    } else {
      setInfoReuniao({
        ...infoReuniao,
        titulo: value,
      });
    }
  };

  useEffect(() => {
    const novaMsgConvite = mensagemConvitePadrao;
    setMsgConvite(novaMsgConvite);
  }, [mensagemConvitePadrao]);

  useEffect(() => {
    setConviteSala({
      ...conviteSala,
      mensagem: msgConvite,
    });
  }, [msgConvite]);

  useEffect(() => {
    const { titulo } = conviteSala;
    const novaInfoReuniao = {
      ...conviteSala,
      titulo,
    };
    setInfoReuniao(novaInfoReuniao);
  }, [conviteSala]);

  return (
    <>
      <div className="convite-sala-form-input-container-space">
        <label htmlFor="titulo">Título:</label>
        <input
          type="text"
          value={infoReuniao.titulo}
          onChange={onChangeTitulo}
          name="titulo"
          disabled={isEditMsg}
        />
      </div>
      <MensagemConvite
        isEditMsg={isEditMsg}
        setIsEditMsg={setIsEditMsg}
        msgConvite={msgConvite}
        setMsgConvite={setMsgConvite}
        changeConviteSala={changeConviteSala}
        infoReuniao={infoReuniao}
      />
      <div
        className="convite-sala-form-input-container-space"
        id="link-convite-container"
      >
        <label htmlFor="">Link da reunião:</label>
        <input
          type="text"
          value={linkMinhaSala}
          name="link"
          readOnly
          id="link-convite"
        />
        <FaLink
          className="convite-sala-form-icon"
          title="Copiar link"
          onClick={copiarLink}
        />
      </div>
    </>
  );
};
