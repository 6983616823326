import React from "react";
import "./componentServices.css";

const BotFormServices = ({ props, changeName, changeToken, changePhoneId }) => {
  const { bot } = props;
  return (
    <div className="bot-form-input-container-sv">
      <div className="bot-form-one-create-form-services">
        <div className="bot-form-input-container-create-form-sv">
          <div className="container-align-input-create-form-sv">
            <h5 className="required">Nome:</h5>
          </div>
          <input
            type="text"
            className="bot-form-input-create-form-sv"
            placeholder="Digite o nome do bot"
            value={bot.nome || ""}
            onChange={(e) => changeName(e)}
          />
        </div>
      </div>
      {bot.oficial && (
        <div className="container-align-inputs-botservices">
          <div className="width-container">
            <div className="bot-form-input-container-sv">
              <div className="container-align-phoneID-token">
                <h5 className="required">Phone-ID</h5>
                <input
                  type="text"
                  className="input-phoneID-token"
                  placeholder="Phone-ID do do seu numero na Meta"
                  value={bot.phoneId || ""}
                  onChange={(e) => changePhoneId(e)}
                />
              </div>
            </div>
          </div>

          <div className="width-container">
            <div className="bot-form-input-container-sv">
              <div className="container-align-phoneID-token">
                <h5 className="required">Token</h5>
                <input
                  type="password"
                  className="input-phoneID-token"
                  placeholder="Token"
                  value={bot.token || ""}
                  onChange={(e) => changeToken(e)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BotFormServices;
