import React from "react";
import { GrDocumentPdf } from "react-icons/gr";
import { FaTrash, FaEdit } from "react-icons/fa";
import {MdPictureAsPdf} from "react-icons/md";
import { useState, useEffect, useContext } from "react";
import { PesqPrContext } from "../../../contexts/ChatBotProvider/PesqPrProvider";
import AlertDelete from "./AlertDelete";
function PesquisaCard(props) {
  const [votos, setVotos] = useState(props.pesquisa.votos);
  const [delOnShow, setDelOnShow] = useState(false);
  const allvotes = useContext(PesqPrContext).votos;

  useEffect(() => {
    let newVotes = allvotes.filter(
      (voto) => voto.pesquisa === props.pesquisa._id
    );
    setVotos(newVotes.length);
  }, []);
  return (
    <div className="pesquisa-card-wrapper-novo">
      <div className="rasgo-da-folha-pesquisa-card"></div>
      <div className="furo-da-folha-pesquisa-card"></div>
      <div className="pesquisa-card-novo">
        {delOnShow && (
          <AlertDelete
            onDelete={props.onDelete}
            close={(e) => setDelOnShow(false)}
          />
        )}
        <div className="research-journal-novo">
          <div className="pesquisa-card-nome-novo">
            <p>
              Nome: <span>{props.pesquisa.nome}</span>
            </p>
          </div>
          <div className="pesquisa-card-votos-novo">
            <p>
              Votos: <span>{votos}</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="box-icons-novo">
          <FaTrash
            onClick={(e) => setDelOnShow(true)}
            className="exclude-icon-novo"
          />
          <MdPictureAsPdf className="show-icon-novo" onClick={props.onLook} />
          <FaEdit onClick={props.onEdit} className="edit-icon-novo" />
        </div>
      </div>
    </div>
  );
}

export default PesquisaCard;
