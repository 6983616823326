import BotItem from "./components/BotItem/BotItem";
import NotBot from "./components/NotBot/NotBot";
import "./botArea.css";

export default function BotsArea(props) {
  const { bots, flows, loading } = props;

  let endpoint = () => {
    let endpoint =
      window.location.hostname === "localhost"
        ? "http://localhost:4000"
        : "https://mensageria.voxcity.com.br/";
    return endpoint;
  };


  return (
    <>
      <div className="bot-list-field">
        {bots.length > 0 ? (
          <>
            <div className="bot-area">
              {bots.map((bot, index) => (
                <BotItem
                  key={index}
                  bot={bot}
                  flows={flows}
                  endpoint={endpoint}
                  deleteModal={props.deleteModal}
                  selectBot={props.selectBot}
                  showModal={props.showModal}
                  changeBotStatus={props.changeBotStatus}
                  loading={loading}
                />
              ))}
            </div>
          </>
        ) : (
          <NotBot />
        )}
      </div>
    </>
  );
}
