import React from 'react';

export default function Modal(props) {

    const { typeModal } = props;
    let newCommand = typeModal === "newCommand";

    return <>

        <div className="flow-modal-bg">

            <div className="flow-modal-container">

                {props.children}

                {/* <button
                    className="flow-button flow-close-btn"
                    title={"Clique para voltar"}
                    style={{ margin: "10px 0px 0px 0px", background: "#b53434" }}
                    onClick={newCommand ? () => {props.undoCommand(); props.closeModal();} : () => {props.closeModal()}}>
                    Voltar
                </button> */}

            </div>

        </div>

    </>
}