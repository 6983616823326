import { useEffect, useState } from "react";
import { FaEdit, FaSave, FaCopy } from "react-icons/fa";

export const MensagemConvite = ({
  isEditMsg,
  setIsEditMsg,
  msgConvite,
  setMsgConvite,
  infoReuniao,
  changeConviteSala,
}) => {
  const [msgConviteReuniao, setMsgConviteReuniao] = useState("");
  const isConviteSalaFilled = () => {
    return infoReuniao.mensagem;
  };

  const copiarConvite = () => {
    const mensagemSemTags = infoReuniao.mensagem.replace(/<[^>]*>/g, "");
    navigator.clipboard.writeText(mensagemSemTags);
  };

  const onChangeMsg = (event) => {
    const { value } = event.target;
    setMsgConvite(value);
    changeConviteSala();
  };

  const editMsg = () => {
    const novaMsgConvite = msgConvite;
    setMsgConvite(novaMsgConvite);
    setIsEditMsg(!isEditMsg);
  };

  const salvarMsg = () => {
    const novaMsgConvite = msgConvite;
    setMsgConvite(novaMsgConvite);
    setIsEditMsg(!isEditMsg);
  };

  useEffect(() => {
    const novaMsgConvite = msgConvite;
    setMsgConviteReuniao(novaMsgConvite);
  }, [msgConvite]);

  return (
    <div className="convite-sala-form-input-container-space">
      <label className="convite-sala-form-required">Mensagem:</label>
      {isEditMsg ? (
        <textarea
          name="mensagem"
          cols="30"
          rows="10"
          value={infoReuniao.mensagem}
          onChange={onChangeMsg}
        />
      ) : (
        <div
          className="convite-sala-form-msg-container"
          dangerouslySetInnerHTML={{ __html: msgConviteReuniao }}
          style={{ whiteSpace: "pre-wrap" }}
        />
      )}
      <div className="convite-sala-form-icon-msg-container">
        {!isEditMsg ? (
          <FaEdit
            className="convite-sala-form-edit-icon"
            onClick={editMsg}
            id="edit"
            title="Editar mensagem de convite"
          />
        ) : (
          <FaSave
            className={
              !isConviteSalaFilled()
                ? "convite-sala-form-save-icon-disabled"
                : "convite-sala-form-save-icon"
            }
            onClick={!isConviteSalaFilled() ? null : salvarMsg}
            id="save"
            title="Salvar mensagem de convite"
          />
        )}
        <FaCopy
          className={
            !isConviteSalaFilled() || isEditMsg
              ? "convite-sala-form-copy-icon-disabled"
              : "convite-sala-form-copy-icon"
          }
          onClick={!isConviteSalaFilled() || isEditMsg ? null : copiarConvite}
          id="copiar"
          title="Copiar mensagem de convite"
        />
      </div>
    </div>
  );
};
