import React, { useEffect, useState } from "react";
import { BsFillPlusCircleFill, BsFillXCircleFill } from "react-icons/bs";
import ApiUsers from "../../../../../ApiUsers";

export default function RenderVariable({
  variable,
  variableValues,
  contact,
  setContact,
  createAlert,
}) {
  const [nValue, setNvalue] = useState("");
  const [value, setValue] = useState("");

  async function excludeVariable(contact, variable) {
    let newContact = JSON.parse(JSON.stringify(contact));
    delete newContact.historico;
    delete newContact.allMessages;
    let res = await ApiUsers.post("/atendimentos/removeVar", {
      contact: newContact,
      varName: variable.nome,
    }).catch((err) => {
      console.log(err);
    });
    if (res && res.data === "ok") {
      createAlert("normal", {
        title: "Sucesso!",
        placeholder: "Variável removida com sucesso!",
      });
      setValue("");
      setContact((prev) => {
        return {
          ...prev,
          [variable.nome]: "",
        };
      });
    }
  }

  async function addVariable(contact, variable) {
    let nwcontact = JSON.parse(JSON.stringify(contact));
    delete nwcontact.historico;
    delete nwcontact.allMessages;
    if (nValue !== "") {
      let res = await ApiUsers.post("/atendimentos/addVar", {
        contact: nwcontact,
        varName: variable.nome,
        value: nValue,
      }).catch((err) => {
        console.log(err);
      });
      if (res && res.data === "ok") {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Variável adicionada com sucesso!",
        });
        setValue(nValue);
        setContact((prev) => {
          return {
            ...prev,
            [variable.nome]: nValue,
          };
        });
      }
    } else {
      createAlert("normal", {
        title: "Erro!",
        placeholder: "Digite um valor para a variável!",
      });
    }
  }

  const handleChangeValue = (e) => {
    setNvalue(e.target.value);
  };

  useEffect(() => {
    setValue(variableValues);
  }, [variableValues]);

  return (
    <div className="contacts-unique" key={variable.nome}>
      <p>{variable.nome}</p>
      <div className="contacts-unique-container-variable">
        <div className="contact-info">
          {value ? (
            <>
              <p style={{ marginBottom: "0px" }}>{value}</p>
              <BsFillXCircleFill
                title="Remover variável"
                style={{
                  color: "#AF1616",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                size={20}
                onClick={() => excludeVariable(contact, variable)}
              />
            </>
          ) : (
            <>
              <input
                type="text"
                placeholder="Adicione"
                className="add-var"
                value={nValue || ""}
                onChange={(e) => handleChangeValue(e)}
              />
              <BsFillPlusCircleFill
                style={{
                  cursor: "pointer",
                  color: "#9AC31C",
                  marginLeft: "10px",
                }}
                size={20}
                onClick={() => addVariable(contact, variable)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
