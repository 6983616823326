import axios from "axios";

let url = "https://cronos.voxcity.com.br";
// let url = "http://cronos-env.eba-db4ehnbp.us-east-1.elasticbeanstalk.com";
// let url = "http://localhost:4002";

const ApiCronos = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": url,
    "Content-Type": "application/json",
  },
});

ApiCronos.defaults.withCredentials = true;

export default ApiCronos;
