import React, { useState, useContext, useEffect } from 'react';
import Alert from '../../../OptionsScreen/inputs/Alerts/Alert';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';
import ApiUsers from '../../../../../ApiUsers';
import UserCreateForm from './userCreateForm';

const UserCreate = (props) => {
    const { edit, user: initialUser, goBack } = props;
    const [user, setUser] = useState({
        email: "",
        senha: "",
        nome: "",
        permissões: [],
        config: {
            firstAccess: true,
        },
        telefone: "",
        foto: "",
        status: "offline",
        ramal: "",
        departamento: "",
        setor: "",
        atendente: false,
        admin: false,
        organização: "",
        ativo: true,
        profissional: false,
        credencial: "",
        disparador: false,
        atendenteGrupo: false,
        restricao: {
            ativo: false,
            horarioFuncionamento: {},
            intervalos: [],
        },
    });
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [loading, setLoading] = useState(false);
    const prefContext = useContext(PrefContext);

    const setup = {
        simple: ["email", "senha", "nome", "telefone", "ramal", "credencial"],
        select: ["departamento", "setor"],
        bool: ["atendente", "admin", "profissional", "disparador", "atendenteGrupo", "restricao"],
    };

    useEffect(() => {
        if (edit) {
            setUser(prevUser => ({ ...prevUser, ...initialUser }));
        }
    }, [edit, initialUser]);

    const quitAlert = () => {
        setAlert({ visible: false, type: "", props: {} });
    };

    const createAlert = (type, props) => {
        setAlert({ visible: true, type, props });
    };

    const createUser = (e) => {
        e.preventDefault();
        const newUser = { ...user };
        const requiredFields = ["email", "nome", "telefone", "ramal"];
        if (newUser.restricao.ativo) {
            requiredFields.push("restricao");
        }
        if (newUser.profissional) {
            requiredFields.push("credencial");
        }
        const isValid = requiredFields.every(field => newUser[field] || newUser[field] === false);

        if (isValid) {
            setLoading(true);
            ApiUsers.post('/usuarios/novo', { user: newUser })
                .then(() => {
                    setLoading(false);
                    createAlert("normal", { title: "Sucesso!", placeholder: "Usuário criado com sucesso!" });
                    document.getElementById("user-form-create").reset();
                })
                .catch(err => console.log(err));
        } else {
            createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
        }
    };

    const editUser = (e) => {
        e.preventDefault();
        const newUser = { ...user };
        const requiredFields = ["email", "nome", "telefone", "ramal"];
        if (newUser.restricao.ativo) {
            requiredFields.push("restricao");
        }
        if (newUser.profissional) {
            requiredFields.push("credencial");
        }
        const isValid = requiredFields.every(field => newUser[field] || newUser[field] === false);

        if (isValid) {
            const isModified = Object.keys(newUser).filter(key => initialUser[key] !== newUser[key]).length > 0;

            if (isModified) {
                setLoading(true);
                ApiUsers.put(`/usuarios/${initialUser._id}`, { user: newUser, lastUser: initialUser })
                    .then(() => {
                        setLoading(false);
                        createAlert("normal", { title: "Sucesso!", placeholder: "Usuário editado com sucesso!" });
                        goBack();
                    })
                    .catch(err => console.log(err));
            } else {
                createAlert("normal", { title: "Ops!", placeholder: "Não foram detectadas alterações!" });
            }
        } else {
            createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencher os campos necessários!" });
        }
    };

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={quitAlert} />,
    };

    return (
        <UserCreateForm edit={edit} user={user} setUser={setUser} setup={setup} prefContext={prefContext} createUser={createUser} editUser={editUser} loading={loading} alert={alert} goBack={goBack} alertType={alertType} />
    );
};

export default UserCreate;