import React from "react";
import MyDataConfig from "../miscelaneous/MyDataConfig";
import Header from "./HeaderView";

const LeftCol = (props) => {
  let {children} = props
  return (
    <div
      id="left-col"
      className="left-col"
      // style={{ margin: 5, flex: 0.36 }}
      style={{ flex: 0.36 }}
    >

      <Header title={props.title} little={true}/>
      {/* <MyDataConfig /> */}
      {children}
    </div>
  );
};

export default LeftCol;
