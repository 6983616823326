import React from "react";
import { useState, useEffect, useContext } from "react";
import { AttendanceContext } from "../../../contexts/ChatBotProvider/AttendanceProvider";
import MessageScreen from "../../Chats/general/MessageScreen";

const PrAtt = (props) => {
  const attendanceContext = useContext(AttendanceContext);
  const [attendance, setAttendance] = useState({});

  useEffect(() => {
    if (!props.pr) return;
    const att = attendanceContext.attendances.find(
      (att) => att.telefone === props.pr.contato?.telefone
    );
    const c = att
      ? { ...JSON.parse(JSON.stringify(att)) }
      : { ...props.pr.contato };

    if (!c.allMessages) c.allMessages = [];
    let out = props.pr.saida ? props.pr.saida.date : new Date().toISOString();
    let start = props.pr.entrada ? props.pr.entrada.date : null;
    let firstMessagesIndex = -1;
    c.allMessages = c.allMessages.filter((msg, i) => {
      if (msg.createdAt && msg.createdAt >= start && msg.createdAt <= out) {
        if (firstMessagesIndex === -1) firstMessagesIndex = i;
        return true;
      }
      return false;
    });
    let firstMessage = c.allMessages[firstMessagesIndex ? -1 : 0];
    if (firstMessage > -1) c.allMessages = [firstMessage, ...c.allMessages];
    setAttendance(c);
  }, [props.pr]);

  useEffect(() => {
    if (!props.att) return;
    const c = { ...props.att };
    if (!c.allMessages) c.allMessages = [];
    setAttendance(c);
  }, [props.att]);

  return (
    <div
      className="pdf-modal"
      onClick={(e) => {
        if (e.target.classList.contains("not-escape")) return;
        setAttendance(null);
        props.onClose();
      }}
    >
      <div className="pr-doc">
        <div className="pdf-modal-header">
          {props.pr && (
            <h1 className="not-escape">Protocolo: {props.pr.numero}</h1>
          )}
          <h1 className="not-escape">
            Contato:{" "}
            {attendance
              ? attendance.nome
                ? attendance.nome
                : attendance.pushname
              : ""}
          </h1>
          <h2 className="not-escape">Telefone: {attendance?.telefone}</h2>
        </div>

        {props.pr ? (
          <MessageScreen
            msgType={"att"}
            contact={attendance}
            type="att"
            showAll={true}
          />
        ) : (
          <MessageScreen msgType={"att"} contact={attendance} type="att" />
        )}
      </div>
    </div>
  );
};

export default PrAtt;
