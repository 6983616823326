import React, { useState, useEffect, useContext } from "react";
import MainView from "../main/MainView";
import LeftCol from "../main/LeftCol";
import RightCol from "../main/RightCol";
import ConfigList from "./ConfigList";
import OptionMenu from "./OptionMenu";
import { ContactContext } from "../../contexts/ChatBotProvider/ContactProvider";
import "./config.css";

export default function Config() {
  const context = useContext(ContactContext);
  const [selectedConfig, setSelectedConfig] = useState(
    "Gerenciamento de Usuários"
  );

  useEffect(() => {
    localStorage.setItem("type", "config");
    context.userContext.socket.on("lastMsg", (data) => {
      console.log("config last");
      context.userContext.lastMsgCont(data);
      context.userContext.pushNewMessageTo(data.msg, data.from);
      let counter = context.pvtNotRead;
      counter += 1;
      context.setPvtNotRead(counter);
    });

    return () => {
      context.userContext.socket.off("lastMsg");
    };
  }, [context]);

  return (
    <MainView>
      <LeftCol title="Configurações">
        <ConfigList setConfig={setSelectedConfig} opt={selectedConfig} />
      </LeftCol>
      <RightCol height={"112%"}>
        <OptionMenu opt={selectedConfig} />
      </RightCol>
    </MainView>
  );
}
