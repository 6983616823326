import React from "react";

export default function Header() {
  return (
    <thead>
      <tr className="tr-min">
        <th scope="col" className="th-min">
          Foto
        </th>
        <th scope="col" className="th-max">
          Nome de WhatsApp
        </th>
        <th scope="col" className="th-max">
          Telefone
        </th>
        <th scope="col" className="th-max">
          Email
        </th>
        <th scope="col" className="th-small">
          Ações
        </th>
      </tr>
    </thead>
  );
}
