export const Text = ({ label, value, setValue, inputKey, type, subKeys, legend = true }) => {
  const onChange = (e) => {
    setValue((prev) => ({ ...prev, [inputKey]: e.target.value }));
  };

  const onChangeObject = (e) => {
    setValue((prev) => ({ ...prev, [inputKey]: { ...prev[inputKey], [e.target.name]: e.target.value } }));
  };

  return (
    <>
      {type === "object" ? (
        <div className="qsg-agenda-form-input-container obj">
          {legend && <legend htmlFor={inputKey}>{label}</legend>}
          {subKeys.map(
            (subKey, index) =>
              subKey.inputType === "text" && (
                <div key={index}>
                  <label htmlFor={subKey.key}>{legend ? subKey.label : label}</label>
                  <input
                    type="text"
                    value={value && value[subKey.key] ? value[subKey.key] : ""}
                    onChange={onChangeObject}
                    id={subKey.key}
                    name={subKey.key}
                  />
                </div>
              )
          )}
        </div>
      ) : (
        <div className="qsg-agenda-form-input-container">
          <label htmlFor={inputKey}>{label}</label>
          <input
            type="text"
            value={value ? value : ""}
            onChange={onChange}
            id={inputKey}
            name={inputKey}
          />
        </div>
      )}
    </>
  );
};
