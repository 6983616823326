import React from 'react';
import { IoMdClose } from "react-icons/io";

export default function VariablesForm(props) {

    const { masks, variable, changeVariable, saveVariable, loading, closeForm } = props;

   

    return <div className="variables-form-container">
        <div className="variables-form">

            <div className="variables-form-header">
                <h3 > Nova Variável </h3>

                <IoMdClose className="variables-form-close" onClick={closeForm} />
            </div>
            <div className='variables-form-inputs-container'>

                <input className="variables-form-input-text" placeholder="Digite o nome da variável" type="text" value={variable.nome} name="nome" onChange={(e) => changeVariable(e)} />

                <select className="variables-form-input-select" name="mascara" value={variable.mascara} onChange={(e) => changeVariable(e)}>
                    <option value="">Selecione uma máscara</option>
                    {masks.map((mask, index) => <option key={index} value={mask}>{mask}</option>)}
                </select>
                
                <div className="variables-form-input-checkbox">
                    <small> Reatribuir </small>
                    <input type="checkbox" name="reatribuir" checked={variable.reatribuir} onChange={(e) => changeVariable(e, true)} />
                </div>           

                <button className="variables-form-input-button" disabled={loading} onClick={() => saveVariable()}>
                    Salvar Variável
                </button>

            </div>
        </div>

    </div>

}