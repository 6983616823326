import React from 'react';

export default function ContactBoxIcons(props) {

    const { buttons, handle } = props;

    return <>
    
        {
            buttons.length > 0 && <>
                {buttons.map((button, index) => {
                    return (
                        <div key={index} onClick={(e) => handle[button.action](e)} title={button.title}>
                            {button.icon}
                        </div>
                    )
                })}
            </>
        }

    </>
}