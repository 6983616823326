import React, { useState, useEffect, useContext } from "react";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { ContactContext } from "../../../../contexts/ChatBotProvider/ContactProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import CardAtendimento from "../components/CardAtendimento/card";
import { generatePDFsingle, generatePDFtable } from "../components/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function TempoAtt(props) {
  const { attendances } = useContext(AttendanceContext);
  const { contacts } = useContext(ContactContext);
  const { pref } = useContext(UserContext);
  const [atendentes, setAtendentes] = useState([]);
  const [date, setDate] = useState({ ...props.date });
  const [checkedIds, setCheckedIds] = useState([]);

  const checkSelectId = (id) => {
    const isChecked = checkedIds.includes(id);
    let newIds = [...checkedIds];
    if (isChecked) newIds = newIds.filter((checkedId) => checkedId !== id);
    else newIds = [...checkedIds, id];
    setCheckedIds(newIds);
  };

  const isBiggerDate = (data1, data2) => {
    var d1 = new Date(data1);
    var d2 = new Date(data2);

    if (d1 > d2) {
      return true;
    } else if (d1 < d2) {
      return false;
    } else {
      return true;
    }
  };

  const newObjDate = (data) => {
    if (!data) return false;
    var partes = data.split("-");
    return new Date(partes[0], partes[1] - 1, partes[2]);
  };

  const dateToYYMMDD = (date) => {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const treatWithZero = (number) => {
      if (number < 10) {
        return `0${number}`;
      }
      return number;
    };
    let dateFormated = `${year}-${treatWithZero(month)}-${treatWithZero(day)}`;
    return dateFormated;
  };

  useEffect(() => {
    setDate({ ...props.date });
  }, [props.date]);

  useEffect(() => {
    let myAtendentes = [...contacts].filter((contact) => contact.atendente);
    setAtendentes(myAtendentes);
  }, [contacts]);

  useEffect(() => {
    let myAtendentes = [...atendentes];
    if (
      date &&
      date.start &&
      date.end &&
      myAtendentes.length > 0 &&
      myAtendentes.length > 0
    ) {
      let data = [];
      let whtsAtt = [...attendances];
      whtsAtt = whtsAtt.forEach((att, attI) => {
        att.historico.forEach((hist, hI) => {
          if (
            hist.data &&
            (hist.action === "Iniciado pelo Atendente" ||
              hist.action === "Atendido") &&
            isBiggerDate(hist.data, newObjDate(date.start))
          ) {
            let nextHist = att.historico[hI + 1];
            if (
              hist.action === "Iniciado pelo Atendente" &&
              nextHist &&
              nextHist.action === "Novo Protocolo"
            ) {
              nextHist = att.historico[hI + 2];
            }
            if (
              nextHist &&
              (nextHist.action === "Finalizado" ||
                nextHist.action === "Transferido para espera") &&
              isBiggerDate(newObjDate(date.end), dateToYYMMDD(nextHist.data))
            ) {
              let atendente = myAtendentes.find(
                (atendente) => atendente._id === hist.user
              );
              if (atendente) {
                data.push({
                  atendente: atendente._id,
                  tempo: (new Date(nextHist.data) - new Date(hist.data)) / 1000,
                  date: hist.data,
                });
              }
            }
          }
        });
      });
      myAtendentes = myAtendentes.map((atendente) => {
        let myData = data.filter((d) => d.atendente === atendente._id);
        let total = 0;
        myData.forEach((d) => {
          total += d.tempo;
        });
        return {
          ...atendente,
          tempo: total / myData.length,
          data: myData,
        };
      });

      setAtendentes(myAtendentes);
    }
  }, [date]);

  return (
    <div className="attendance-painel">
      {atendentes.map((atendente) => {
        if (atendente.nome === "Voxcity") {
          return null;
        } else {
          return (
            <CardAtendimento
              key={atendente._id}
              atendente={atendente}
              date={date}
              handCheckSelectId={checkSelectId}
              generatePdf={() =>
                generatePDFsingle(atendente._id, atendentes, pref, date)
              }
            />
          );
        }
      })}
      {checkedIds.length > 0 && (
        <button
          className="button-download-gerar-resumo"
          onClick={() => generatePDFtable(atendentes, pref, checkedIds, date)}
        >
          GERAR RESUMO
        </button>
      )}
    </div>
  );
}
