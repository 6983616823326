import React from "react";
import "./DeleteAlert.css";

export default function DeleteAlert(props) {
  return (
    <>
      <div className="background-modal">
        <div className="alert-container">
          <div className="alert-content">
            <img
              src="/imgs/alerta.png"
              className="alert-content-img"
              alt="alert"
            />
            <h2 className="alert-title"> Atenção! </h2>
            <span className="alert-span">
              Deseja realmente{" "}
              {props.data === "usuário"
                ? "inativar"
                : props.data === "Finalizar"
                ? "finalizar"
                : "excluir"}{" "}
              <b>{props.nome}</b>? Não será possivel reverter após a ação.{" "}
            </span>
          </div>
          <div className="alert-container-button">
            <button className="back-button" onClick={(e) => props.quit(e)}>
              Voltar
            </button>
            <button
              className="alert-button"
              disabled={props.loading}
              onClick={(e) => props.action(e)}
            >
              {props.data === "Finalizar" ? "Finalizar" : "Excluir"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
