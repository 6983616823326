import React from "react";
import FlowsSelects from "../components/FlowsSelects";

const FlowSelectionForm = ({ props }) => {
  const { flows, bot, pesquisas } = props;

  function changeMainFlow(e) {
    let newBot = { ...bot };
    newBot.flow = e.target.value;
    props.setBot(newBot);
  }

  function changeFirstContactFlow(e) {
    let newBot = { ...bot };
    newBot.primeiroFlow = e.target.value;
    props.setBot(newBot);
  }

  function changeResearch(e) {
    let newBot = { ...bot };
    newBot.pesquisa = e.target.value;
    props.setBot(newBot);
  }

  function changeTempoFinalizacao(e) {
    let newBot = { ...bot };
    newBot.config.tempoFinalizacao = e.target.value;
    props.setBot(newBot);
  }

  return (
    <>
      <div className="container-align-title-flow ">
        <p>Flows (Fluxos de diálogo)</p>
      </div>
      <FlowsSelects
        flows={flows}
        bot={bot}
        changeMainFlow={changeMainFlow}
        changeFirstContactFlow={changeFirstContactFlow}
        changeResearch={changeResearch}
        changeTempoFinalizacao={changeTempoFinalizacao}
        pesquisas={pesquisas}
      />
    </>
  );
};

export default FlowSelectionForm;
