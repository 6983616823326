import React, { useContext } from "react";
import InfoItem from "../InfoItem/InfoItem";
import VariableList from "../../components/VariableList";
import InfoItemUnic from "../InfoItemUnic/InfoItemUnic";
import InfoItemSelect from "../InfoItemSelect/InfoItemSelect";
import SaveContainer from "../SaveContainer/SaveContainer";
import { UserContext } from "../../../../../../contexts/UserProvider";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import ApiUsers from "../../../../../../ApiUsers";
import ContainerTwo from "../ContainerTwo/ContainerTwo";

const RenderBody = ({ contactInfo, contact, setContact, createAlert }) => {
  const userContext = useContext(UserContext);
  const flowbotContext = useContext(FlowBotContext);

  const formatDate = (date) => {
    let d = new Date(date);
    let formattedDate = d.toLocaleString("pt-BR");
    return formattedDate !== "Invalid Date" ? formattedDate : "--";
  };

  const saveChanges = (contact) => {
    ApiUsers.post("/atendimentos/updateContact", { contact }).then((res) => {
      createAlert("normal", {
        title: "Sucesso!",
        placeholder: "Contato atualizado com sucesso!",
      });
      setContact(null);
    });
  };

  const handleOnChange = (value, key) => {
    setContact((prevContact) => ({
      ...prevContact,
      [key]: value,
    }));
  };

  return (
    <div className="container-contacts">
      <ContainerTwo>
        <InfoItem label="Nome de WhatsApp" value={contactInfo?.pushname} />
        <InfoItem label="Telefone" value={contactInfo?.telefone} />
      </ContainerTwo>

      <VariableList
        variables={flowbotContext.variables}
        contact={contact}
        setContact={setContact}
        createAlert={createAlert}
      />
      <ContainerTwo>
        <InfoItem label="Stage" value={contact?.stage} />
        <InfoItem label="Na espera" value={contact?.waiting ? "Sim" : "Não"} />
      </ContainerTwo>

      <ContainerTwo>
        <InfoItemUnic
          label="Ultima mensagem"
          value={formatDate(contact?.lastMessageDate)}
        />
      </ContainerTwo>

      <ContainerTwo>
        <InfoItemSelect
          label="Flow"
          value={contact.flow}
          options={flowbotContext.flows.map((f) => ({
            value: f._id,
            label: f.nome,
          }))}
          onChange={(value) => handleOnChange(value, "flow")}
        />
        <InfoItemSelect
          label="Estágio"
          value={contact.stage}
          options={(
            flowbotContext.flows.find((f) => f._id === contact.flow)
              ?.comandos || []
          ).map((c, i) => ({
            value: i,
            label: i + 1,
          }))}
          onChange={(value) => handleOnChange(value, "stage")}
        />
      </ContainerTwo>

      <ContainerTwo>
        <InfoItemSelect
          label="Bot"
          value={contact.bot}
          options={flowbotContext.bots.map((b, i) => ({
            key: `${b._id}-${i}`,
            value: b._id,
            label: b.nome,
          }))}
          onChange={(value) => handleOnChange(value, "bot")}
        />
        <InfoItemSelect
          label="Atendente"
          value={contact?.atendente}
          options={userContext.pref.users
            .filter((u) => u.atendente)
            .map((u) => ({
              key: u._id,
              value: u._id,
              label: u.nome,
            }))}
          onChange={(value) => handleOnChange(value, "atendente")}
        />
      </ContainerTwo>

      <SaveContainer onClick={() => saveChanges(contact)} />
    </div>
  );
};

export default RenderBody;
