import React from 'react'
import { BsX , BsClipboard2PulseFill} from 'react-icons/bs'
import { LoadingSpinner } from '../../../miscelaneous/Loader/load'
import UserProtocolosList from '../ListaUsers/components/userProtocolosList'
import './style.css'

const ProtocolosDia = ({ handleModal, protocolos }) => {

    const handleClickOutsideModal = (e) => {
        if (!e.target.closest('.protocolosDay-container')) {
            handleModal()
        }
    }

  return (
    <div className='protolosDay-modal' onClick={handleClickOutsideModal}>
        <div className="protocolosDay-container">
            <div className="protocolosDay-container-header">
                <div style={{display:'flex', gap:'5px'}}>
                    <BsClipboard2PulseFill color={'#142a4c'} size={30} />
                    <h3>Protocolos do dia</h3>
                </div>
                <BsX color={'#9ac31c'} size={35} onClick={() => handleModal()} style={{cursor:'pointer' }} />
            </div>

            <div className="protocolosDay-container-body">
             {protocolos.lenght === 0 ? <LoadingSpinner showModal='none' /> : (
                <UserProtocolosList protocolosUser={protocolos} day={true} />
             )}
            </div>

        </div>
    </div>
  )
}

export default ProtocolosDia