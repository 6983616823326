import { useEffect, useState } from "react";
import ListSelect from "./select";
import UserAtedimentoModal from "./components/userAtedimentoModal";
import { LoadingSpinner } from "../../../miscelaneous/Loader/load";
import "./lista.css";

export default function UserList(props) {
  const { pref } = props;
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("all");
  const [loadAtendentes, setLoadAtendentes] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const options = [
    { value: "all", label: "Todos" },
    { value: "online", label: "Online" },
    { value: "offline", label: "Offline" },
  ];

  useEffect(() => {
    if (pref.users) {
      let atendentes = pref?.users?.filter((u) => u.atendente);
      setUsers([...atendentes]);
    }
    setLoadAtendentes(false);
  }, [pref]);

  useEffect(() => {
    //zerando a pagina se a quantidade de usuarios mudar
    let usersTotal = users.length;
    let pages = Math.ceil(usersTotal / limit);
    if (page > pages) {
      setPage(1);
    }
  }, [users]);

  useEffect(() => {
    if (pref.users) {
      if (filter === "all") {
        let atendentes = pref.users.filter((u) => u.atendente);
        setUsers([...atendentes]);
      } else {
        let atendentes = pref.users.filter(
          (u) => u.atendente && u.status === filter
        );
        setUsers([...atendentes]);
      }
    }
  }, [filter]);

  const changeSelect = (e) => {
    let value = e.value;
    if (value === "all") {
      setUsers(pref.users.filter((u) => u.atendente));
    } else {
      let atendentes = pref.users.filter((u) => u.atendente);
      let newUsers = atendentes.filter((u) => u.status === value);
      setUsers(newUsers);
    }
  };

  const handleModal = (user) => {
    if (user) {
      setSelectedUser(user);
    }
    setShowModal(!showModal);
  }

  return (
    <div className="list-wrapper">
      {showModal && <UserAtedimentoModal handleModal={handleModal} user={selectedUser} />}
      <div className="list-header">
        <h3>Atendentes</h3>
        <ListSelect
          data={options}
          name="status"
          handleInputChange={changeSelect}
        />
      </div>
      {loadAtendentes ? (
        <div className="container-loader">
          <LoadingSpinner showModal="none" />
        </div>
      ) : (
        <>
          <div className="list-body">
            {sliceUsers(users, page, limit).map((user, index) => {
              return (
                <div key={index} className="list-item" onClick={() => handleModal(user)}>
                  <div className="list-item-avatar">
                    <img
                      src={user.foto ? user.foto : "/imgs/avatar2.png"}
                      className="avatar"
                      alt="avatar"
                    />
                  </div>
                  <div className="list-item-info">
                    <h4>{user.nome}</h4>
                    <p className={user.status}>{user.status}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      <Pagination
        users={users}
        page={page}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
      />
    </div>
  );
}

const sliceUsers = (users, page, limit) => {
  let start = (page - 1) * limit;
  let end = start + limit;
  return users.slice(start, end);
};

const Pagination = (props) => {
  const { users, page, limit, setLimit, setPage } = props;
  const pages = Math.ceil(users.length / limit);
  let pageArray = [];
  for (let i = 1; i <= pages; i++) {
    pageArray.push(i);
  }
  return (
    <div className="pagi-btns">
      {/* <button onClick={() => setPage(1)} disabled={page === 1}>Primeira</button>
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>Anterior</button> */}
      {pageArray.map((p, index) => {
        return (
          <button key={index} onClick={() => setPage(p)} disabled={page === p}>
            {p}
          </button>
        );
      })}
      {/* <button onClick={() => setPage(page + 1)} disabled={page === pages}>Próxima</button>
            <button onClick={() => setPage(pages)} disabled={page === pages}>Última</button> */}
    </div>
  );
};
