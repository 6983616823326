import {
  BsColumnsGap,
  BsChatRightText,
  BsCalendar4Week,
  BsCameraReels,
  BsLock,
  BsPeople,
  BsHeadset,
  BsWhatsapp,
  BsCalendarCheck,
  BsGear,
  BsBoxArrowRight,
} from "react-icons/bs";

const getSidebarItems = (user, pref) => {
  let sidebarItems = [
    {
      id: 1,
      label: "Home",
      link: "/home",
      titulo: "Home",
      icon: <BsColumnsGap />,
    },
    {
      id: 2,
      label: "Chat",
      links: [
        {
          id: 2.1,
          label: "Privado",
          link: "/chat",
          titulo: "Chat Privado",
          icon: <BsLock />,
        },
        {
          id: 2.2,
          label: "Grupos",
          link: "/grupos",
          titulo: "Chat de Grupos",
          icon: <BsPeople />,
        },
        {
          id: 2.3,
          label: "Atendimentos",
          link: "/atendimentos",
          titulo: "Atendimentos",
          icon: <BsHeadset />,
        },
      ],
      titulo: "Chat",
      icon: <BsChatRightText />,
    },
    {
      id: 3,
      label: "Agenda",
      link: "/profissional",
      titulo: "Agenda do Profissional",
      icon: <BsCalendar4Week />,
    },
    {
      id: 4,
      label: "Video conferência",
      link: "/videoConferencia",
      titulo: "Video Conferência",
      icon: <BsCameraReels />,
    },
    {
      id: 5,
      label: "Ferramentas",
      links: [
        {
          id: 5.1,
          label: "WhatsApp",
          link: "/voxtool",
          titulo: "Ferramenta WhatsApp",
          icon: <BsWhatsapp />,
        },
        {
          id: 5.2,
          label: "Agendamento",
          link: "/agenda",
          titulo: "Ferramenta Agendamento",
          icon: <BsCalendarCheck />,
        },
      ],
      titulo: "Ferramentas",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.472 2.98914C12.5114 2.8601 12.5149 2.72021 12.4819 2.58882C12.4489 2.45743 12.3812 2.34104 12.288 2.25576L12.0832 2.0856C11.3644 1.54096 10.5483 1.18804 9.69488 1.0528C8.14985 0.818015 6.18145 1.3021 4.26712 3.81353H3.05456C2.97889 3.81355 2.90397 3.83068 2.83408 3.86395C2.7642 3.89723 2.70073 3.94598 2.6473 4.00742L1.16784 5.70501C1.06036 5.82864 1 5.99607 1 6.17063C1 6.34519 1.06036 6.51262 1.16784 6.63625L4.12562 10.038C4.17905 10.0994 4.24253 10.1482 4.31241 10.1814C4.38229 10.2147 4.45721 10.2318 4.53287 10.2318C4.60853 10.2318 4.68345 10.2147 4.75333 10.1814C4.82322 10.1482 4.88669 10.0994 4.94013 10.038L6.41959 8.33648C6.52712 8.21287 6.58752 8.04544 6.58755 7.87086V7.028L16.2363 18.7317C16.2854 18.8082 16.3483 18.8715 16.4208 18.9175C16.4933 18.9635 16.5735 18.991 16.6561 18.9981C16.7387 19.0053 16.8216 18.9919 16.8993 18.9589C16.9769 18.9259 17.0474 18.874 17.106 18.8069L18.8317 16.8284C18.936 16.7087 18.9962 16.5476 18.9998 16.3785C19.0035 16.2093 18.9503 16.045 18.8512 15.9196L8.34887 4.53636C9.20825 3.93488 10.3874 3.42969 11.929 3.42969C12.0482 3.42979 12.1644 3.38745 12.2617 3.30854C12.359 3.22962 12.4325 3.11801 12.472 2.98914Z" />
        </svg>
      ),
    },
    {
      id: 6,
      label: "Configurações",
      link: "/config",
      titulo: "Configurações",
      icon: <BsGear />,
    },
    {
      id: 7,
      label: "Sair",
      link: "/",
      titulo: "Sair",
      icon: <BsBoxArrowRight />,
    },
  ];

  if (user && !user.admin && !user.disparador) {
    sidebarItems = sidebarItems.filter(
      (item) => item.label !== "Configurações" && item.label !== "Ferramentas"
    );
  } else if (user && user.disparador) {
    sidebarItems = sidebarItems.filter(
      (item) => item.label !== "Configurações"
    );
  }
  if (!pref.services.voxbot?.ativo) {
    sidebarItems = sidebarItems.filter(item => item.label !== "Ferramentas");

  }
  if (!pref.services.voxbot?.ativo && (!user.admin || !user.atendente)) {
    sidebarItems[1].links = sidebarItems[1].links.filter(
      (link) => link.label !== "Atendimentos"
    );
  }
  if (!pref.services.agenda?.ativo || user.disparador) {
    sidebarItems = sidebarItems.filter((item) => {
      if (item.label === "Agenda") {
        return false;
      }
      if (item.links) {
        item.links = item.links.filter((link) => link.label !== "Agendamento");
      }
      return true;
    });
  }
  if (!pref.services.videoconf?.ativo) {
    sidebarItems = sidebarItems.filter(
      (item) => item.label !== "Video conferência"
    );
  }
  if (!user.profissional) {
    sidebarItems = sidebarItems.filter((item) => item.label !== "Agenda");
  }

  return sidebarItems;
};

export default getSidebarItems;
