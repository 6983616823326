import { useEffect, useState } from "react";
import ApiUsers from "../../../ApiUsers";

const ProfileForm = ({ usuario, createAlert }) => {
  const [alteredData, setAlteredData] = useState({});

  useEffect(() => {
    if (usuario) {
      const { _id, nome, email, telefone, ramal } = usuario;
      setAlteredData({ _id, nome, email, telefone, ramal });
    }
  }, [usuario]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setAlteredData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const editUser = (e) => {
    e.preventDefault();
    let emptyFields = 0;
    Object.entries(alteredData).forEach(([key, field]) => {
      if (field === "") emptyFields = emptyFields + 1;
    });
    if (emptyFields === 0) {
      let wasModified = 0;
      Object.values(alteredData).forEach((value) => {
        if (!Object.values(usuario).some((value2) => value2 === value)) {
          wasModified = wasModified + 1;
        }
      });
      if (wasModified === 0) {
        createAlert("normal", {
          title: "Ops!",
          placeholder: "Nenhuma alteração detectada!",
        });
      } else {
        ApiUsers.post(`/usuarios/edit`, { user: alteredData })
          .then((resp) => {
            console.log(resp);
            createAlert("normal", {
              title: "Sucesso!",
              placeholder: "Seus dados foram atualizados com sucesso!",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Preencha todos os campos!",
      });
    }
  };

  return (
    <div className="profile-form">
      <div className="profile-form-group">
        <div className="profile-form-item">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            name="nome"
            placeholder={alteredData?.nome}
            onChange={handleChange}
          />
        </div>
        <div className="profile-form-item">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder={alteredData?.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="profile-form-group">
        <div className="profile-form-item">
          <label htmlFor="telefone">Telefone</label>
          <input
            type="text"
            id="telefone"
            name="telefone"
            placeholder={alteredData?.telefone}
            onChange={handleChange}
          />
        </div>
        <div className="profile-form-item">
          <label htmlFor="ramal">Ramal</label>
          <input
            type="text"
            id="ramal"
            name="ramal"
            placeholder={alteredData?.ramal}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="profile-box-button">
        <button onClick={editUser}>Salvar</button>
      </div>
    </div>
  );
};

export default ProfileForm;
