import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserProvider';

export default function ConfigList(props) {

    const context = useContext(UserContext);

    const btns = ["Gerenciamento de Usuários", "Gerenciamento de Setores",
        "Gerenciamento de Departamentos", "Gerenciamento de Grupos de Conversa",
        "Gerenciamento de Grupos de Atendimento", "Inicialização Rápida", "Suporte"]

    return (
        <div className='config-list'>
            {btns.map((btn, index) => (
                <div key={index} className='config-btn ' onClick={() => props.setConfig(btn)}
                    style={{ 
                        color:props.opt === btn ?"#9ac31c":"", 
                    }}
                >{btn}</div>
            ))}
        </div >
    );
}


