import React from 'react';
import './EmptyLength.css';

export default function Empty(props) {
    return <>
        <div className="empty-container">
            <img src="/imgs/empty-box.png" className="empty-img" alt="empty"/>
            <h2 className="empty-title"> Não há {props.data} disponíveis </h2>
        </div>
    </>
}