import "./style.css";
import { useState } from "react";

export const SwitchToggle = ({ label, toggled, onClick, isRightToLeft }) => {
  const [isToggled, setIsToggle] = useState(toggled);

  const onChange = () => {
    setIsToggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <label
      className={`switch-toggle-label ${isRightToLeft ? "right-to-left" : ""}`}
    >
      <input
        className="switch-toggle-input"
        type="checkbox"
        defaultChecked={isToggled}
        onClick={onChange}
      />
      <span className="switch-toggle-span" />
      <strong>{label}</strong>
    </label>
  );
};
