import React, { useRef, useState, useEffect, useContext } from "react";
import ApiUsers from "../../../../../../ApiUsers";
import { BsTrash3 } from "react-icons/bs";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { PrefContext } from "../../../../../../contexts/ChatBotProvider/PrefProvider";
import ButtonAnimation from "../../../../ButtonAnimation/button";

import "./etapaform.css";

function EtapaForm(props) {
  const [etapa, setEtapa] = useState({ ...props.etapa });

  const { atendentes } = useContext(PrefContext);
  const { flows } = useContext(FlowBotContext);

  const modalRef = useRef(null);

  const selectActions = [
    { name: "Redirecionar p/ Grupo de Atendentes", value: "send-to-chat" },
    { name: "Redirecionar p/ Flow", value: "send-to-flow" },
    { name: "Remover da Campanha", value: "remove-from-campaign" },
  ];

  useEffect(() => {
    setEtapa(props.etapa);
  }, [props.etapa]);

  function saveEtapa() {
    let valid = true;

    if (etapa.nome === "") {
      alert("Você precisa adicionar um nome");
      return (valid = false);
    }
    if (etapa.data === "") {
      alert("Você precisa adicionar uma data");
      return (valid = false);
    }
    if (etapa.mensagem === "") {
      alert("Você precisa adicionar uma mensagem");
      return (valid = false);
    }
    if (etapa.hora === "") {
      alert("Você precisa adicionar uma hora");
      return (valid = false);
    }

    if (etapa.alternativas.length > 0) {
      etapa.alternativas.forEach((alternativa) => {
        // if(alternativa.valor === ""){
        //     alert("Você precisa adicionar um nome para a alternativa")
        //     return valid = false
        // }
        // if(alternativa.action === ""){
        //     alert("Você precisa adicionar uma ação para a alternativa")
        //     return valid = false
        // }
        // if(alternativa.action === "remove-from-campaign"){
        //     if(alternativa.next === ""){
        //         alert("Você precisa adicionar uma etapa para a alternativa")
        //         return valid = false
        //     }
        // }
        // if(alternativa.action === "send-to-flow"){
        //     if(alternativa.next === ""){
        //         alert("Você precisa adicionar um flow para a alternativa")
        //         return valid = false
        //     }
        // }
        // if(alternativa.action === "send-to-chat"){
        //     if(alternativa.next === ""){
        //         alert("Você precisa adicionar um grupo de atendentes para a alternativa")
        //         return valid = false
        //     }
        // }
      });
    }
    if (valid) {
      props.saveEtapa(etapa);
    }
  }

  async function changeFile(file) {
    let formData = new FormData();
    formData.append("file", file);
    let resp = await ApiUsers.post("/upload", formData).catch((err) =>
      console.log(err)
    );
    if (resp.status === 200) {
      console.log(resp.data);
      setEtapa({ ...etapa, file: resp.data });
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.setEtapa(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const handlePaste = (e) => {
    if (e.clipboardData.files.length > 0) {
      const pastedFile = e.clipboardData.files[0];
      if (pastedFile.type.startsWith("image/")) {
        changeFile(pastedFile);
      }
    }
  };

  return (
    <div className="etapa-form" ref={modalRef}>
      <h3>Etapa</h3>
      <div className="etapa-form-body">
        <div className="etapa-form-body-space">
          <div className="etapa-form-body-item">
            <label className="required">Nome</label>
            <input
              type="text"
              placeholder="ex: Etapa 1"
              value={etapa.nome}
              onChange={(e) => setEtapa({ ...etapa, nome: e.target.value })}
            />
          </div>
          <div className="etapa-form-body-duo-item">
            <div className="etapa-form-body-item">
              <label className="required">Data</label>
              <input
                type="date"
                value={etapa.data}
                onChange={(e) => setEtapa({ ...etapa, data: e.target.value })}
              />
            </div>
            <div className="etapa-form-body-item">
              <label className="required">Hora</label>
              <input
                type="time"
                value={etapa.hora}
                onChange={(e) => setEtapa({ ...etapa, hora: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="etapa-form-body-item">
          <label className="required">Mensagem</label>
          <textarea
            placeholder="Escreva a mensagem aqui..."
            value={etapa.mensagem}
            onChange={(e) => setEtapa({ ...etapa, mensagem: e.target.value })}
            onPaste={handlePaste}
          />
        </div>
        <div className="etapa-form-body-item-upload">
          <label>{etapa.file?.name ? 'Arquivo Escolhido':'Adicionar Arquivo'}</label>
          <div className="etapa-form-body-arquivo">

            {etapa.file?.name ? (
              <>
              {console.log(etapa.file)}
                <div className="file-name">
                  <img src={etapa.file?.url} alt="file" style={{width:'100px', height:'100px', objectFit:'cover', marginRight:'10px'}} />
                  {etapa.file?.name}
                </div>
                <BsTrash3
                  size={22}
                  className="trash-icon"
                  onClick={(e) => setEtapa({ ...etapa, file: null })}
                />
              </>
            ) : (
                <input
                  type="file"
                  onChange={(e) => changeFile(e.target.files[0])}
                  onClick={(e) => (e.target.value = null)}
                />
            )}
          </div>
        </div>
        <br />
        <br />
        <div className="etapa-form-body-item-alternative">
          <div className="etapa-form-body-space-alternative">
            <ButtonAnimation
              title="Adicionar Alternativa"
              onClick={(e) => {
                let alternativas = etapa.alternativas;
                alternativas.push({ action: "", next: "", valor: "" });
                setEtapa({ ...etapa, alternativas: alternativas });
              }}
            />
          </div>
          <div className="alternativas">
            {etapa.alternativas.length > 0
              ? etapa.alternativas.map((alternativa, index) => {
                  return (
                    <div key={index} className="alternativa-item">
                      <input
                        style={{ width: "10%" }}
                        placeholder="Valor"
                        type="text"
                        value={alternativa.valor}
                        onChange={(e) => {
                          let alternativas = etapa.alternativas;
                          alternativas[index].valor = e.target.value;
                          setEtapa({ ...etapa, alternativas: alternativas });
                        }}
                      />
                      <select
                        className="flow-form-select"
                        value={alternativa.action}
                        onChange={(e) => {
                          let alternativas = etapa.alternativas;
                          alternativas[index].action = e.target.value;
                          setEtapa({ ...etapa, alternativas: alternativas });
                        }}
                      >
                        <option value={""}>Selecione uma ação</option>
                        {selectActions.map((action, index) => {
                          return (
                            <option key={index} value={action.value}>
                              {action.name}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className="flow-form-select-secundario"
                        value={alternativa.next}
                        onChange={(e) => {
                          let alternativas = etapa.alternativas;
                          alternativas[index].next = e.target.value;
                          setEtapa({ ...etapa, alternativas: alternativas });
                        }}
                      >
                        {alternativa.action === "send-to-chat" ? (
                          <option value={""}>
                            Selecione um Grupo de atendentes
                          </option>
                        ) : alternativa.action === "send-to-flow" ? (
                          <option value={""}>Selecione um fluxo</option>
                        ) : (
                          <option value={""}>Selecione uma ação</option>
                        )}
                        {alternativa.action === "send-to-chat" ? (
                          <>
                            {" "}
                            {atendentes.map((grupo, index) => {
                              return (
                                <option key={index} value={grupo.id}>
                                  {grupo.nome}
                                </option>
                              );
                            })}
                          </>
                        ) : alternativa.action === "send-to-flow" ? (
                          <>
                            {flows.map((fluxo, index) => {
                              return (
                                <option key={index} value={fluxo.id}>
                                  {fluxo.nome}
                                </option>
                              );
                            })}
                          </>
                        ) : alternativa.action === "remove-from-campaign" ? (
                          <>
                            <option key={index * 1000} value={index}>
                              Remover
                            </option>
                          </>
                        ) : (
                          <option value={""}>Selecione uma ação</option>
                        )}
                      </select>

                      <span className="flow-form-checkbox-container">
                        <input
                          type="checkbox"
                          className="flow-form-checkbox"
                          checked={alternativa.botao}
                          onChange={(e) => {
                            let alternativas = etapa.alternativas;
                            alternativas[index].botao = e.target.checked;
                            setEtapa({
                              ...etapa,
                              alternativas: alternativas,
                            });
                          }}
                        />
                        <label>Botão</label>

                        <BsTrash3
                          size={22}
                          className="trash-icon"
                          onClick={(e) => {
                            let alternativas = etapa.alternativas;
                            alternativas.splice(index, 1);
                            setEtapa({ ...etapa, alternativas: alternativas });
                          }}
                        />
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="etapa-form-footer">
        <button className="cancel-button" style={{marginBottom:0}} onClick={(e) => props.setEtapa(null)}>
          Cancelar
        </button>
        <button className="save-button" onClick={saveEtapa}>
          Salvar
        </button>
      </div>
    </div>
  );
}

export default EtapaForm;
