import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { HiMinus } from "react-icons/hi";
import "./Accordion.css";

export default function Accordion(props) {
  const { accordion } = props;
  const [accordionOpt, setAccordionOpt] = useState("");

  return (
    <div className="accordion">
      {accordion.map((opt, index) => {
        return (
          <div className="accordion-item" key={index}>
            <div
              className="accordion-item-title"
              onClick={
                accordionOpt === opt.name
                  ? () => setAccordionOpt("")
                  : () => setAccordionOpt(opt.name)
              }
            >
              <span>{opt.title}</span>
              {accordionOpt === opt.name ? (
                <HiMinus className="accordion-icon" />
              ) : (
                <BsPlusLg className="accordion-icon" />
              )}
            </div>
            {accordionOpt === opt.name && <>{opt.desc}</>}
          </div>
        );
      })}
    </div>
  );
}
