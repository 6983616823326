import React from "react";

const Button = ({ text, onClick, title }) => {
  return (
    <div className="container-buttons-contact-header">
      <button onClick={onClick} title={title}>
        {text}
      </button>
    </div>
  );
};

export default Button;
