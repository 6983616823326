import React from "react";

const FileInput = ({ onChange, onClick, id }) => {
  return (
    <input
      type="file"
      name="file"
      id={id ? id : "file"}
      className="inputfile"
      style={{ display: "none" }}
      onChange={onChange}
      onClick={(e) => {
        e.target.value = null;
        if (onClick) onClick(e);
      }}
    />
  );
};

export default FileInput;
