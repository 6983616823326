import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { AiFillCloseSquare } from 'react-icons/ai';
import MiniLoading from '../inputs/MiniLoading';

export default function SimplyForm(props) {

    const {
        multi,
        id,
        title,
        field,
        field2,
        value,
        value2,
        type,
        placeholder,
        placeholder2,
        indexDep
    } = props;

    return (

        <Form className="simply-form" id={id} style={{ fontSize: 20 }} onSubmit={(e) => props.handleSubmit(e)}>

            {!multi && <>
                <h2 style={{ textAlign: "center", margin: "25px 0px" }}>{title}</h2>
            </>}

            <Col>

                <Row>

                    <Form.Group style={{ padding: 0, display: 'flex', flexDirection: 'row' }}>

                        <Form.Label className='required' style={{ marginRight: "10px" }}>
                            {multi ? `Nome Departamento ${indexDep}:` : "Nome:"}
                        </Form.Label>

                        <Form.Control style={multi ? { flex: 1 } : null} name={field} value={value} onChange={props.handleChange} type={type} placeholder={placeholder} />
                        
                        {multi && <AiFillCloseSquare className='del-sing-icon' onClick={() => props.removeSingleDept()} title="Deletar departamento" /> }

                    </Form.Group>

                    <Form.Group style={{ marginTop: 15, padding: 0, display: 'flex', flexDirection: 'row' }}>

                        <Form.Label style={{ marginRight: "21px" }}>
                            {multi ? `Sala de conferência ${indexDep}:` : "Sala de conferência:"}
                        </Form.Label>

                        <Form.Control style={{ flex: 1 }} name={field2} value={value2} onChange={props.title === "Editar Setor" ? props.handleChange2 : props.handleChange} type={type} placeholder={placeholder2} />
                    
                    </Form.Group>

                </Row>

                {props.children &&
                    <Row>
                        {props.children}
                    </Row>
                }

            </Col>

            {multi ? null : <>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 20 }}>

                    {title.includes("Editar") ? <>

                        <button className='edit-section-goback-btn' onClick={() => props.goBack()} title="Voltar"> Voltar </button>
                        <button className="section-save-btn" onClick={(e) => props.handleSubmit(e)} disabled={props.loading} title="Salvar" style={{ minWidth: 70, display: 'flex', justifyContent: 'center', alignItems: "center" }}> {props.loading ? <MiniLoading /> : "Salvar"} </button>
                    
                    </> : <>

                        {id.includes("dep") && <>
                            <button onClick={() => props.increaseDep()} className="create-multi-depts-btn" title="Criar múltiplos">
                                Criar Múltiplos
                            </button>
                        </>}

                        <button className="section-save-btn" style={{ minWidth: 70, display: 'flex', justifyContent: 'center', alignItems: "center" }} disabled={props.loading} onClick={(e) => props.handleSubmit(e)} title="Salvar">
                            {props.loading ? <MiniLoading /> : "Salvar"}
                        </button>

                    </>}

                </div >

            </>}

        </Form>
    );
}
