import { useState, useEffect, useContext } from "react";
import { FlowBotContext } from "../../../../contexts/ChatBotProvider/FlowBotProvider";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import LeftList from "./GroupForm/LeftList";
import RightList from "./GroupForm/RightList";
import Header from "./GroupForm/Header";

export default function GroupForm(props) {
  const [grupo, setGrupo] = useState(JSON.parse(JSON.stringify(props.grupo)));
  const [contacts, setContacts] = useState([...props.contacts]);

  const [contactsOnSearch, setContactsOnSearch] = useState([]);
  const [onSearch, setOnSearch] = useState("");

  const { variables } = useContext(FlowBotContext);

  useEffect(() => {
    let cloneGroup = JSON.parse(JSON.stringify(props.grupo));
    setGrupo(cloneGroup);
  }, [props.grupo]);

  useEffect(() => {
    setContacts([...props.contacts]);
  }, [props.contacts]);

  useEffect(() => {
    if (grupo.contatos) {
      setContactsOnSearch([...grupo.contatos]);
      if (onSearch !== "") {
        let newContactsOnSearch = [];
        let newContacts = [...grupo.contatos];
        newContacts.forEach((c) => {
          //PROCURAR POR VARIAVEIS DO CONTATO
          let valuesTofind = [];
          let found = false;
          if (!c) return;
          variables.forEach((v) => {
            let varName = v.nome;
            if (c[varName]) {
              valuesTofind.push(c[varName]);
            }
          });
          valuesTofind.forEach((v) => {
            if (v.toLowerCase().includes(onSearch.toLowerCase())) {
              found = true;
            }
          });
          if (found) {
            newContactsOnSearch.push(c);
          }
        });
        setContactsOnSearch([...newContactsOnSearch]);
      }
    }
  }, [grupo.contatos]);

  return (
    <div className="group-form">
      <Header grupo={grupo} setGrupo={setGrupo} />
      <div className="group-form-body">
        <LeftList
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          contacts={contacts}
          setContacts={setContacts}
          grupo={grupo}
          setGrupo={setGrupo}
          contactsOnSearch={contactsOnSearch}
          setContactsOnSearch={setContactsOnSearch}
        />
        <RightList
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          contacts={contacts}
          setContacts={setContacts}
          grupo={grupo}
          setGrupo={setGrupo}
          contactsOnSearch={contactsOnSearch}
          setContactsOnSearch={setContactsOnSearch}
        />
      </div>
      <div className="group-form-footer">
        <button
          className="cancel-button"
          onClick={(e) => props.setSelectedGroup(null)}
        >
          Cancelar
        </button>

        <button
          className="save-button"
          onClick={(e) => {
            props.saveGroup(grupo);
          }}
        >
          Salvar
        </button>
      </div>
    </div>
  );
}
