import { useState, useEffect } from "react";
import SelectN from "./Types/select";
import Calendario from "./Types/calendario";
import "./filtrosGraficos.css";

const FiltrosGraficos = ({ zeroState, pref, setPref }) => {
  const [searchDate, setSearchDate] = useState({inicio: new Date(), fim: new Date()});
  const [prefeitura, setPrefeitura] = useState({});
  const [setores, setSetores] = useState([]);
  const [atendentes, setAtendentes] = useState([]);

  useEffect(() => {
    let inicio = new Date()
    let fim = new Date()
    inicio.setDate(inicio.getDate() - 31)
    let firstTimeSearch = {
      inicio,
      fim
    }
    setSearchDate(firstTimeSearch);
  }, []);

  useEffect(() => {
    filtrateAtts();
  }, [searchDate, pref?.users]);

  useEffect(() => {
    const newPref = zeroState ? { ...zeroState } : {};
    const newSetores = newPref.departamentos?.flatMap(dep => dep.setores) || [];
    const newAtendentes = newPref.users?.filter(user => user.atendente) || [];
    setPrefeitura(newPref);
    setSetores(newSetores);
    setAtendentes(newAtendentes);
  }, [zeroState]);

  const filtrateAtts = () => {
    let newDate = { ...searchDate };
    let inicio = newDate.inicio;
    let fim = newDate.fim;
    let newPref = { ...pref };
    let atts = zeroState?.services?.voxbot?.contatos ? [...zeroState?.services?.voxbot?.contatos] : [];
    if (inicio && fim && atts.length > 0) {
      atts = [...atts].filter((att) => {
        if (new Date(att?.historico[att?.historico?.length - 1]?.date) >= new Date(inicio) && new Date(att?.historico[att?.historico?.length - 1]?.date) <= new Date(fim)) {
          return true;
        } else if (new Date(att?.historico[att.historico?.length - 1]?.data) >= new Date(inicio) && new Date(att?.historico[att?.historico?.length - 1]?.data) <= new Date(fim)) {
          return true;
        }
        return false;
      });
      setPref({ ...newPref, 'services': { ...newPref.services, 'voxbot': { ...newPref.services?.voxbot, 'contatos': atts } } });
    }
  }

  const handleInputChangeDep = (e) => {
    let value = e.value;
    if(value === 'todos') {
      setPref({...zeroState})
      return;
    }
    let data = prefeitura?.departamentos.filter(
      (departamento) => departamento._id === value
    );
    let usuarios = data[0].usuarios;
    let atendentes = prefeitura?.users.filter((user) => usuarios.includes(user._id));

    if (data[0].setores) setSetores(data[0].setores);
    setPref({ ...pref, 'users': atendentes });
  };

  const handleInputChangeSet = (e, name) => {
    let value = e.value;
    if(value === 'todos') {
      setPref({...zeroState})
      return;
    }
    let data = setores.filter((setor) => setor._id === value);
    let usuarios = data[0].usuarios;
    let atendentes = prefeitura?.users.filter((user) => usuarios.includes(user._id));
    atendentes.forEach((atendente) => delete atendente.allMessages);
    setPref({ ...pref, 'users': atendentes });
  };

  const handleInputChangeAtend = (e, name) => {
    let value = e.value;
    if(value === 'todos') {
      setPref({...zeroState})
      return;
    }
    let data = atendentes.filter((atendente) => atendente._id === value);
    delete data[0].allMessages;
    setPref({ ...pref, 'users': data });
  };

  return (
    <div className="filtros-graficos-wrapper">
      <div className="filtros-graficos-header">
        <Calendario searchDate={searchDate} setSearchDate={setSearchDate} />
        <SelectN data={prefeitura?.departamentos} name="departamento" id='departamentos' handleInputChange={handleInputChangeDep} />
        <SelectN data={setores} name="setor" id='setores' handleInputChange={handleInputChangeSet} />
        <SelectN data={atendentes} name="atendente" id='atendentes' handleInputChange={handleInputChangeAtend} />
      </div>
    </div>
  );
};

export default FiltrosGraficos;
