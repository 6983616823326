import Select from "react-select";
import { useState, useEffect } from "react";

export const PesquisaProfissionais = ({
  profissionais,
  especialidades,
  servicos,
  locais,
  setProfissionaisFiltrados,
}) => {
  const [opcao, setOpcao] = useState("profissional");
  const [profissionalSelecionado, setProfissionalSelecionado] = useState("");
  const [servicoSelecionado, setServicoSelecionado] = useState("");
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState("");
  const [localSelecionado, setLocalSelecionado] = useState("");

  const optionsProfissionais = profissionais.map((profissional) => ({
    value: profissional._id,
    label: profissional.nome,
  }));

  const optionsServicos = servicos.map((servico) => ({
    value: servico._id,
    label: servico.nome,
  }));

  const optionsEspecialidades = especialidades.map((especialidade) => ({
    value: especialidade._id,
    label: especialidade.nome,
  }));

  const optionsLocais = locais.map((local) => ({
    value: local._id,
    label: local.nome,
  }));

  const handleSelectChangeLocal = (selectedOption) => {
    setLocalSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeServico = (selectedOption) => {
    setServicoSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeProfissional = (selectedOption) => {
    setProfissionalSelecionado(selectedOption ? selectedOption.value : "");
  };

  const handleSelectChangeEspecialidade = (selectedOption) => {
    setEspecialidadeSelecionada(selectedOption ? selectedOption.value : "");
  };

  const handleSetOpcao = (e) => {
    setOpcao(e.target.value);
  };

  useEffect(() => {
    let novosProfissionaisFiltrados = [];
    switch (opcao) {
      case "profissional":
        if (profissionalSelecionado !== "") {
          const profissional = profissionais.find(
            (p) => p._id === profissionalSelecionado
          );
          if (profissional) {
            novosProfissionaisFiltrados = profissionais.filter(
              (p) => p._id === profissional._id
            );
          }
        } else {
          novosProfissionaisFiltrados = profissionais;
        }
        break;

      case "especialidade":
        if (especialidadeSelecionada !== "") {
          const esp = especialidades.find(
            (e) => e._id === especialidadeSelecionada
          );
          if (esp) {
            novosProfissionaisFiltrados = profissionais.filter((p) =>
              esp.profissionais.includes(p._id)
            );
          }
        } else {
          novosProfissionaisFiltrados = profissionais;
        }
        break;

      case "servico":
        if (servicoSelecionado !== "") {
          const servico = servicos.find((s) => s._id === servicoSelecionado);
          if (servico) {
            const profissionalIds = new Set();
            locais.forEach((localAgenda) => {
              if (servico.locais.includes(localAgenda._id)) {
                localAgenda.especialidades.forEach((espId) => {
                  const esp = especialidades.find((e) => e._id === espId._id);
                  if (esp) {
                    esp.profissionais.forEach((pId) => {
                      profissionalIds.add(pId);
                    });
                  }
                });
              }
            });
            novosProfissionaisFiltrados = profissionais.filter((p) =>
              profissionalIds.has(p._id)
            );
          }
        } else {
          novosProfissionaisFiltrados = profissionais;
        }
        break;

      case "local":
        if (localSelecionado !== "") {
          const local = locais.find((l) => l._id === localSelecionado);
          if (local) {
            const profissionalIds = new Set();
            local.especialidades.forEach((esp) => {
              esp.profissionais.forEach((p) => {
                profissionalIds.add(p._id);
              });
            });
            novosProfissionaisFiltrados = profissionais.filter((p) =>
              profissionalIds.has(p._id)
            );
          }
        } else {
          novosProfissionaisFiltrados = profissionais;
        }
        break;

      default:
        break;
    }

    setProfissionaisFiltrados(novosProfissionaisFiltrados);
  }, [
    especialidadeSelecionada,
    especialidades,
    locais,
    localSelecionado,
    opcao,
    profissionais,
    profissionalSelecionado,
    servicoSelecionado,
    servicos,
    setProfissionaisFiltrados,
  ]);

  return (
    <fieldset className="profissional-search-fieldset">
      <legend className="profissional-search-legend">
        <span>Pesquisar</span>
      </legend>
      <div className="profissional-search">
        <select
          value={opcao}
          onChange={(event) => handleSetOpcao(event)}
          className="profissional-search-select"
        >
          <option value="profissional">Profissional</option>
          <option value="especialidade">Especialidade</option>
          <option value="local">Local</option>
          <option value="servico">Serviço</option>
        </select>
        {opcao === "profissional" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsProfissionais]}
            value={optionsProfissionais.find(
              (option) => option.value === profissionalSelecionado
            )}
            onChange={handleSelectChangeProfissional}
            placeholder="Escolha um profissional"
            isClearable={true}
          />
        )}
        {opcao === "especialidade" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsEspecialidades]}
            value={optionsEspecialidades.find(
              (option) => option.value === especialidadeSelecionada
            )}
            onChange={handleSelectChangeEspecialidade}
            placeholder="Escolha uma especialidade"
            isClearable={true}
          />
        )}
        {opcao === "local" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsLocais]}
            value={optionsLocais.find(
              (option) => option.value === localSelecionado
            )}
            onChange={handleSelectChangeLocal}
            placeholder="Escolha um local"
            isClearable={true}
          />
        )}
        {opcao === "servico" && (
          <Select
            className="profissional-search-react-select"
            options={[...optionsServicos]}
            value={optionsServicos.find(
              (option) => option.value === servicoSelecionado
            )}
            onChange={handleSelectChangeServico}
            placeholder="Escolha um serviço"
            isClearable={true}
          />
        )}
      </div>
    </fieldset>
  );
};
