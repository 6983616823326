import React from "react";
import "./Alert.css";

export default function Alert(props) {
  return (
    <>
      <div className="background-modal">
        <div className="alert-container">
          <div className="alert-content">
            <img
              src={
                props.title?.includes("Sucesso")
                  ? "/imgs/sucessalert.png"
                  : "/imgs/warningalert.png"
              }
              className="alert-content-img"
              alt="alert"
            />
            <h2 className="alert-title"> {props.title} </h2>
            <span className="alert-span"> {props.placeholder} </span>
          </div>
          <div className="alert-container-button">
            <button
              className={
                props.title?.includes("Sucesso")
                  ? "alert-success-ok-button"
                  : "alert-warning-ok-button"
              }
              onClick={props.quit}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
