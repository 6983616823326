import "./spinner.css";

export const LoadingSpinner = (props) => {
  return (
    <div
      className={`spinner-container${
        props.showModal === "none" && props.showModal
          ? ""
          : props.showModal
          ? "-modal-agenda"
          : "-modal-calendario"
      }`}
    >
      <div className="loading-spinner"></div>
    </div>
  );
};
