import React, { useState, useEffect, useContext } from "react";
import { ReplyForwardingContext } from "../../../../../../contexts/ReplyForwardingProvider";
import { AttendanceContext } from "../../../../../../contexts/ChatBotProvider/AttendanceProvider";
import { ContactContext } from "../../../../../../contexts/ChatBotProvider/ContactProvider";
import { GroupContext } from "../../../../../../contexts/ChatBotProvider/GroupProvider";
import { UserContext } from "../../../../../../contexts/UserProvider";
import Messagersender from "../../../Messagersender";
import { BsReplyFill } from "react-icons/bs";
import ViewList from "./ViewList";
import "./forwardto.css";

export default function ForwardTo(props) {
  const { setForwardingMessage, forwardingMessage } = useContext(
    ReplyForwardingContext
  );
  const { attendances } = useContext(AttendanceContext);
  const { contacts } = useContext(ContactContext);
  const { groups } = useContext(GroupContext);
  const { user } = useContext(UserContext);
  const [to, setTo] = useState([]);

  const [viewType, setViewType] = useState("private");

  const views = {
    private: {
      title: "Contatos",
      data: [...contacts],
      searchType: ["nome", "email"],
    },
    groups: {
      title: "Grupos",
      data: [...groups],
      searchType: ["nome", "email"],
    },
    att: {
      title: "Atendimentos",
      data: [...attendances].filter((att) => att?.atendente === user?._id),
      searchType: ["telefone", "nome", "pushname"],
    },
  };

  if (forwardingMessage)
    return (
      <div
        className="forward-modal"
        onClick={(e) => {
          if (e.target.className === "forward-modal")
            setForwardingMessage(null);
        }}
      >
        <div className="select-container">
          <div className="foward-header">
            <BsReplyFill color="#9ac31c" size={30} />
            <h4>Encaminhar mensagem</h4>
          </div>

          <div className="select-header">
            {Object.keys(views).map((view, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setViewType(view);
                  }}
                  className='select-title'
                >
                  <p style={viewType === view ? { backgroundColor: '#142a4c', color: 'white' } : null}>{views[view].title}</p>
                </div>
              );
            })}
          </div>
          <>
            <ViewList
              view={views[viewType]}
              to={to}
              setTo={setTo}
              viewType={viewType}
            />
        <div className="select-footer">
          <Messagersender msgType={viewType} contact={to} />
        </div>
          </>

        </div>
      </div>
    );
  else return "";
}
