import React from "react";
import ButtonsCrud from "../../inputs/ButtonsCrud";
import { MdModeEdit } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import Empty from "../../inputs/EmptyLength";

export default function AttendanceList(props) {
  const { groups } = props;

  return (
    <>
      <div className="attendance-list-container">
        <input
          type="text"
          className="attendance-input"
          style={{ margin: "10px 0px 20px 0px", width: "80%" }}
          placeholder="Pesquise aqui um grupo de atendimento"
          onChange={(e) => props.search(e.target.value)}
        />
        {groups.length > 0 ? (
          <>
            {groups.map((g, i) => {
              return (
                <div className="attendance-list-item" key={i}>
                  <div className="attendance-list-item-info">
                    <span>
                      {" "}
                      <b>Nome</b>: {g.nome}{" "}
                    </span>
                    <span>
                      {" "}
                      <b>Atendentes</b>: {g.usuarios.length}{" "}
                    </span>
                  </div>

                  <div className="attendance-list-item-buttons">
                    <ButtonsCrud
                      buttons={[
                        {
                          action: "editar",
                          title: "Editar grupo",
                          class:
                            "attendance-list-item-button attendance-list-item-button-edit",
                          icon: (
                            <MdModeEdit className="attendance-list-item-button-icon" />
                          ),
                        },
                        {
                          action: "excluir",
                          title: "Excluir grupo",
                          class:
                            "attendance-list-item-button attendance-list-item-button-del",
                          icon: (
                            <ImBlocked className="attendance-list-item-button-icon" />
                          ),
                        },
                      ]}
                      handle={{
                        editar: (e) => props.edit(e, g),
                        excluir: (e) => props.delConfirm(e, g),
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <Empty data="grupos de atendimento" />
          </>
        )}
      </div>
    </>
  );
}
