import React, { useState, useEffect, useContext } from "react";
import { ListGroup } from "react-bootstrap";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { BsInfoCircle, BsSearch } from "react-icons/bs";

export default function PottencialAttendance(props) {
  const context = useContext(AttendanceContext);
  const [attendance, setAttendance] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [attendanceOnShow, setAttendanceOnShow] = useState([]);

  const doSearch = (value) => {
    if (value === "") {
      setAttendanceOnShow([...attendance]);
    } else {
      let newAttendance = attendance.filter(
        (attendance) =>
          attendance.pushname.toLowerCase().includes(value.toLowerCase()) ||
          attendance.telefone.toLowerCase().includes(value.toLowerCase())
      );
      setAttendanceOnShow(newAttendance);
    }
  };

  useEffect(() => {
    doSearch(searchInput);
  }, [searchInput, attendance]);

  useEffect(() => {
    const twentyFourHoursAgo = new Date(Date.now() - 48 * 60 * 60 * 1000);
    let attendances = context.attendances.filter((att) => {  
      let lastMessageDate = new Date(att.lastMessageDate);  
      if(att.lastMessage && att.lastMessage.createdAt) {
        lastMessageDate = new Date(att.lastMessage.createdAt);
      }
      if(!lastMessageDate) return false;
      return (
        !att.waiting &&
        !att.atendente &&
        !att.onCampaign &&
        att.historico[att.historico.length - 1]?.action !== "Finalizado" &&
        lastMessageDate > twentyFourHoursAgo
      );
    });
    setAttendance(attendances);
    setAttendanceOnShow(attendances);
  }, [context.attendances]);

  function countAndSlice(message) {
    if (!message) return "";
    let count = message.length;
    if (count > 20) {
      message = message.slice(0, 20);
      message = message + "...";
    }
    return message;
  }

  return (
    <>
      <div className="container-search">
        <input
          type="search"
          placeholder="Buscar Contato..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div className="button-search-box">
          <BsSearch size={20} />
        </div>
      </div>

      <div className="attendance-list">
        {attendanceOnShow.length === 0 && searchInput !== "" && (
          <div className="container-information-nothingfound-att">
            <BsInfoCircle size={50} color="#9ac31c" />
            <p>
              <strong>Nenhum atendimento encontrado !</strong>
            </p>
            <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
              Verifique o contato ou tente novamente.
            </p>
          </div>
        )}

        {attendanceOnShow?.map((contact, i) => {
          let background = "inherit";
          if (contact === props.selected) {
            background = "white";
          }

          let lastMsgTime = new Date(contact.lastMessage?.createdAt);
          let formatedDate =
            timeHasZero(lastMsgTime.getDate()) +
            "/" +
            timeHasZero(lastMsgTime.getMonth() + 1) +
            "/" +
            timeHasZero(lastMsgTime.getFullYear());
          let formatedTime =
            timeHasZero(lastMsgTime.getHours()) +
            ":" +
            timeHasZero(lastMsgTime.getMinutes());

          return (
            <div key={i + 2 * 969403} >
              <div
                className="contact-wrapper"
                style={{
                  backgroundColor: background,
                  display: "flex",
                  alignItems: "center",
                }}
                key={i}
                onClick={(e) => {
                  props.onSelect(e, contact);
                }}
              >
                <img
                  className="contact-avatar"
                  src={contact.foto ? contact.foto : "/imgs/avatar2.png"}
                  alt=""
                  onError={(e) => {
                    e.target.src = "/imgs/avatar2.png";
                  }}
                />

                <ListGroup.Item
                  className="contact-data"
                  style={{
                    backgroundColor: background,
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  key={i}
                >
                  <b>
                    {contact.pushname
                      ? contact.pushname
                      : contact.telefone.replace("@c.us", " ").trim()}
                  </b>
                  <p style={{ margin: "4px 0px" }}>
                    {" "}
                    {countAndSlice(contact.lastMessage?.message)}{" "}
                  </p>
                  {contact.lastMessage?.createdAt ? (
                    <>
                      <small className="small-title">
                        {" "}
                        Ultima mensagem às {formatedTime} de {formatedDate}{" "}
                      </small>
                    </>
                  ) : (
                    <>
                      <small className="small-title">
                        {" "}
                        Não há mensagem disponível{" "}
                      </small>
                    </>
                  )}
                </ListGroup.Item>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
function timeHasZero(time) {
  if (time < 10) return "0" + time;
  return time;
}
