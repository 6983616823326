import React, { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../../../../contexts/ChatBotProvider/MessageProvider'
import { BiSearch } from 'react-icons/bi';
import { BsX } from 'react-icons/bs';
import './style.css'

export default function SearchMessagesScreen(props) {
    const { onSearch, setOnSearch, setLookingMessage, lookingMessage } = useContext(MessageContext);
    const [search, setSearch] = useState('')
    const [messages, setMessages] = useState([])

    const searching = () => {
        let searchMessages = [...onSearch]
        if (searchMessages.length > 0) {
            searchMessages = searchMessages.filter((msg) => {
                if (msg && msg.message?.toLowerCase().includes(search.toLowerCase())) {
                    return msg
                }
            })
            setMessages(searchMessages)
        }
    }
    useEffect(() => {
        if (search === '') {
            setMessages([])
        }
        else {
            searching()
        }
    }, [search])


    useEffect(() => {
        if(!onSearch){
            setMessages([])
            setSearch('')
        }   
    },[onSearch])

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - date?.getTime();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');

        if (timeDiff >= 48 * 60 * 60 * 1000) {
            // Já passou mais de 48 horas
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        } else if (timeDiff >= 24 * 60 * 60 * 1000) {
            // Já passou mais de 24 horas
            return `${"    "}Ontem`;
        } else {
            return `${"     "}${hours}:${minutes}`;
        }
    }

    if (onSearch) {
        return (
            <div className="right-message-container">
                <div className='search-bar'>
                    <BsX className='contact-title-icons' size={30} onClick={() => setOnSearch(false)} />
                    <input type="text"
                        placeholder="Pesquise mensagens"
                        onChange={(e) => setSearch(e.target.value)}
                        className='search-input'
                        value={search}
                    />
                    <BiSearch className='contact-title-icons' size={30} />
                </div>
                <div className='search-messages-screen'>
                    {messages.map((msg, i) => {
                        return (
                            <div className='message-box' key={i + 1} onClick={() => {
                                setLookingMessage(msg)
                                setOnSearch(false)
                                setSearch('')
                            }}>
                                <div className="message-box-header">
                                    <span>{formatTimestamp(msg?.createdAt)}</span> -
                                    <span>{msg?.fromUsername}</span>
                                </div>
                                <div className="message-box-body">
                                    <p>{msg.message}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return null;
    }
}
