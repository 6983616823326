import React from 'react'
import { Form } from "react-bootstrap";
import SimpleInputLabel from '../../inputs/SimpleInpuLabel';
import SelectUserForm from '../../inputs/SelectUserForm';
import CheckUserForm from '../../inputs/CheckUserForm';
import MiniLoading from '../../inputs/MiniLoading';
import UserCreatRestriction from './userCreatRestriction';

const UserCreateForm = (props) => {
    const { edit, user, setUser, setup, prefContext, createUser, editUser, loading, alert, goBack, alertType } = props;
    const changeInput = (e) => {
        const { name, value } = e.target;
        if (name === 'restricao') {
            setUser(prevUser => ({
                ...prevUser,
                restricao: {
                    ...prevUser.restricao,
                    ativo: !prevUser.restricao.ativo,
                }
            }));
        } else {
            setUser(prevUser => ({
                ...prevUser,
                [name]: name === "departamento" ? "" : value,
                [name]: (name === "admin" || name === "atendente" || name === "profissional") || name === "disparador" || name === 'atendenteGrupo' ? !prevUser[name] : value,
            }));
        }
    };

    const changeUserIntervalo = (intervalos) => {
        setUser(prevUser => ({
            ...prevUser,
            restricao: {
                ...prevUser.restricao,
                intervalos: intervalos,
            }
        }));
    };
    const changeUserFuncionamento = (inicio, fim, dias) => {
        setUser(prevUser => ({
            ...prevUser,
            restricao: {
                ...prevUser.restricao,
                horarioFuncionamento: {
                    inicio: inicio,
                    fim: fim,
                    dias: dias,
                }
            }
        }));
    }
    return (
        <div className="users-create-container">
            {alert.visible && (alertType[alert.type])}
            <Form id="user-form-create" style={{ padding: '10px 30px', textTransform: 'capitalize', fontSize: 20 }}>
                <h2 style={{ textAlign: "center", padding: 20 }}>{edit ? "Edição" : "Criação"} de Usuario</h2>
                {setup.simple.map((item, i) => {
                    const name = item
                    if (name === "senha") return null;
                    if (!user.profissional && name === "credencial") return null;
                    return <SimpleInputLabel placeholder={`Digite aqui ${name === "senha" ? `a ${name}` : `o ${name}`}`} handleChange={changeInput} key={i} id={name} field={name} value={user[setup.simple[i]]} />;
                })}
                {setup.select.map((item, i) => {
                    const name = item
                    return <SelectUserForm edit={edit} dep={user.departamento} handleChange={changeInput} key={i + "a"} field={name} id={name} value={user[setup.select[i]]} />;
                })}
                {setup.bool.map((item, i) => {
                    const name = item
                    if (name === "atendente" && !prefContext.voxBot) return null;
                    if (name === "profissional" && !prefContext.agenda && !user.profissional) return null;
                    if (name === "disparador" && !prefContext.voxBot) return null;
                    if (name === "atendenteGrupo" && !prefContext.voxBot) return null;
                    if (name === 'restricao' && !prefContext.voxBot) return null;
                    return (
                        <CheckUserForm
                            key={i + "b"}
                            checked={
                                name === "admin" ? user.admin :
                                    name === "atendente" ? user.atendente :
                                        name === "profissional" ? user.profissional :
                                            name === "disparador" ? user.disparador :
                                                name === "atendenteGrupo" ? user.atendenteGrupo :
                                                    name === "restricao" ? user.restricao.ativo :
                                                        null
                            }
                            handleChange={changeInput}
                            field={name}
                            id={name}
                        />
                    );
                })}
                {
                    user.restricao.ativo && <UserCreatRestriction user={user} changeUserIntervalo={changeUserIntervalo} changeUserFuncionamento={changeUserFuncionamento} />
                }
                <div style={{ width: "100%", display: 'flex', justifyContent: "flex-end", margin: "20px 0px" }}>
                    {edit &&
                        <button className="users-go-back-btn" onClick={goBack}>
                            Voltar
                        </button>
                    }
                    <button onClick={edit ? editUser : createUser} className="users-save-btn" style={{ display: 'flex', justifyContent: "center", alignItems: "center", minWidth: 70 }} disabled={loading}>
                        {loading ? <MiniLoading /> : "Salvar"}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default UserCreateForm
