import React, { useState, useEffect, useContext } from "react";
import CheckboxList from "../../inputs/CheckboxUsers/CheckboxList";
import { Form } from "react-bootstrap";
import { PrefContext } from "../../../../../contexts/ChatBotProvider/PrefProvider";
import { GroupContext } from "../../../../../contexts/ChatBotProvider/GroupProvider";
import ApiUsers from "../../../../../ApiUsers";

export default function GroupsListUsers(props) {
  const { grupo } = props;
  const prefContext = useContext(PrefContext);
  const groupContext = useContext(GroupContext);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosOnShow, setUsuariosOnShow] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let allUsers = prefContext.users.filter(
      (u) => u.nome !== "Voxcity" && u.ativo
    );
    let groupUsers = allUsers.filter((userObj) =>
      grupo.usuarios.some((userId) => userId === userObj._id)
    );
    setUsuarios(groupUsers);
    setUsuariosOnShow(groupUsers);
  }, [prefContext.grupos]);

  function searchUsers(e) {
    let search = e.target.value;
    let newUsers = [];
    if (search.trim() !== "")
      newUsers = usuarios.filter((g) =>
        g.nome.toLowerCase().includes(search.toLowerCase())
      );
    else newUsers = usuarios;
    setUsuariosOnShow(newUsers);
  }

  function selectUser(checked, user) {
    let users = [];
    if (checked) {
      users = [...selectedUsers, user];
    } else {
      users = selectedUsers.filter((u) => u._id !== user._id);
    }
    setSelectedUsers(users);
  }

  function selectAll(checked) {
    let users = [];
    if (checked) {
      users = [...usuariosOnShow];
    } else {
      users = [];
    }
    setSelectedUsers(users);
  }

  function delUsers() {
    if (selectedUsers.length > 0) {
      setLoading(true);
      let users = selectedUsers.map((u) => u._id);
      ApiUsers.post("/grupos/removerVarios", { users, grupo })
        .then((res) => {
          setSelectedUsers([]);
          setLoading(false);
          props.alert("normal", {
            title: "Sucesso!",
            placeholder: "Usuários deletados do grupo com sucesso!",
          });
        })
        .catch((err) => console.log(err));
    } else {
      props.alert("normal", {
        title: "Ops!",
        placeholder: "Seleciona ao menos um usuários para deletá-lo do grupo!",
      });
    }
  }

  return (
    <>
      <div className="section-list-users-container">
        <h2 style={{ textAlign: "center", margin: "20px 0px" }}>
          {" "}
          Usuários Grupo {grupo.nome}{" "}
        </h2>

        <Form.Control
          placeholder={
            "Digite aqui para pesquisar um usuário do grupo " + grupo.nome
          }
          onChange={(e) => searchUsers(e)}
          style={{ width: "100%" }}
        />

        <br />

        <CheckboxList
          usuarios={usuariosOnShow}
          selectedUsers={selectedUsers}
          action="del"
          delUsers={() => delUsers()}
          selectUser={(checked, user) => selectUser(checked, user)}
          selectAll={(checked) => selectAll(checked)}
          loading={loading}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "20px 0px",
            width: "100%",
          }}
        >
          <button
            className="section-list-users-goback-btn"
            onClick={() => props.goBack()}
            title="Voltar"
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  );
}
