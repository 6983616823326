import { translateDate } from "../../../../miscelaneous/utils";
import { FaUser } from "react-icons/fa";

export const HorarioConfirmado = (props) => {
  if (props.dataAgendada?.absente === true) {
    return (
      <p
        className={`${
          props.dataAgendada.status === "Não confirmado"
            ? "nao-compareceu-nao-confirmado"
            : "nao-compareceu"
        }`}
      >
        <span className="data-marcada">
          {translateDate(props.dataAgendada.data)}
        </span>
        <FaUser
          className="cidadao-icon"
          onClick={() => props.getCidadao(props.dataAgendada.usuario)}
        />
        <span className="nome-cidadao">
          Nome:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.nome
          }
        </span>
        <span className="telefone-cidadao">
          Telefone:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.telefone
          }
        </span>
        <span>Status: {props.dataAgendada.status}</span>
        <span className="nao-compareceu-info">Não compareceu</span>
      </p>
    );
  }

  return (
    <>
      <p className="marcado-confirmado">
        <span className="data-marcada">
          {translateDate(props.dataAgendada.data)}
        </span>
        <FaUser
          className="cidadao-icon"
          onClick={() => props.getCidadao(props.dataAgendada.usuario)}
        />
        <span className="nome-cidadao">
          Nome:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.nome
          }
        </span>
        <span className="telefone-cidadao">
          Telefone:{" "}
          {
            props.allCidadaos.find(
              (cidadao) => cidadao._id === props.dataAgendada.usuario
            )?.telefone
          }
        </span>
        {new Date() >= new Date(props.dataAgendada.data) && (
          <button
            className="btn-nao-compareceu"
            onClick={() => props.submitNaoCompareceu(props.dataAgendada.hash)}
          >
            Não compareceu
          </button>
        )}
      </p>
    </>
  );
};
