import React, { useEffect, useState } from "react";
import InputSearch from "../InputSearch";

export default function TableGroup({
  groupList,
  handleSelectGroup,
  selectedGroups,
  handleSelectAllGroup,
  searchGroup,
  setSearchGroup,
}) {
  const [filteredGroups, setFilteredGroups] = useState([]);

  const selectedGroupAllChecked =
    selectedGroups.length && selectedGroups.length === groupList.length;

  function FilteredGroups() {
    const newFilteredGroups = groupList.filter((group) => {
      return group.nome?.toLowerCase().includes(searchGroup.toLowerCase());
    });
    setFilteredGroups(newFilteredGroups);
  }

  // fazer filtro nos atendimentos selecionados e dai ver se ele foi pra outra lista e sai da lista atual
  useEffect(() => {
    FilteredGroups();
  }, [searchGroup, groupList]);

  const ListItem = ({ group, onChange, checked }) => {
    return (
      <div className="table-list-item">
        <input
          type="checkbox"
          style={{
            width: "18px",
            height: "18px",
          }}
          checked={checked}
          onChange={onChange}
        />
        <p>
          {group.nome}-{group.contatos.length} contatos
        </p>
      </div>
    );
  };

  return (
    <div className="table-left">
      <div className="table-left-container-multi-select">
        <input
          type="checkbox"
          checked={selectedGroupAllChecked}
          onChange={handleSelectAllGroup}
          style={{
            width: "18px",
            height: "18px",
          }}
        />
        <p>Selecionar todos</p>
      </div>

      <div className="container-list-table">
        <InputSearch onChange={(e) => setSearchGroup(e.target.value)} />
        <div className="table-list">
          {filteredGroups?.map((group, i) => {
            return (
              <ListItem
                key={i}
                group={group}
                checked={selectedGroups?.find(
                  (selectGroupId) => selectGroupId === group._id
                )}
                onChange={() => handleSelectGroup(group._id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
