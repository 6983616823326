import React, { useEffect, useState, useContext } from 'react';
import { FaUserAlt, FaBuilding, FaWarehouse } from 'react-icons/fa';
import { IoArrowForwardSharp } from 'react-icons/io5';
import { BiTransfer } from 'react-icons/bi';
import { PrefContext } from '../../../../contexts/ChatBotProvider/PrefProvider';
import { UserContext } from '../../../../contexts/UserProvider';
import { MdGroup } from 'react-icons/md';
import ApiUsers from '../../../../ApiUsers';
import Alert from '../../../Configurações/OptionsScreen/inputs/Alerts/Alert';

export default function AsideTransfer(props) {

    const { contact, back } = props;
    const context = useContext(PrefContext);
    const userContext = useContext(UserContext);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [attendances, setAttendances] = useState([]);
    const [groups, setGroups] = useState([]);
    const [show, setShow] = useState("atendentes");
    const [loading, setLoading] = useState(false);
    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
    }

    useEffect(() => {
        let attendanceList = context.users.filter((user) => user.atendente && (user._id !== userContext.user._id && user.nome !== "Voxcity"));
        setAttendances(attendanceList);
        setGroups(context.atendentes);
    }, [context.users, context.atendentes]);

    function transferContactToGroup(grupo) {
        let newContact = JSON.parse(JSON.stringify(contact))
        delete newContact.allMessages;
        setLoading(true);
        ApiUsers.post("/atendimentos/waiting", { contact: newContact, grupo }).then((res) => {
            createAlert("normal", { title: "Sucesso!", placeholder: `Contato transferido para a lista de espera do grupo ${grupo.nome} com sucesso!` });
            setLoading(false);
            props.cleanContact();
        }).catch(err => console.log(err));
    }

    function transferContactToAtt(atendente) {
        let newContact = JSON.parse(JSON.stringify(contact))
        delete newContact.allMessages;
        let newAtendente = JSON.parse(JSON.stringify(atendente));
        delete newAtendente.allMessages;
        setLoading(true);
        ApiUsers.post("/atendimentos/transfer", { contact: newContact, atendente:newAtendente }).then((res) => {
            createAlert("normal", { title: "Sucesso!", placeholder: `Contato transferido para o atendente ${atendente.nome} com sucesso!` });
            setLoading(false);
            props.cleanContact();
        }).catch(err => console.log(err));
    }

    function quitAlert() {
        setAlert({ visible: false, type: "", props: {} });
    }

    function createAlert(type, props) {
        setAlert({ visible: true, type, props })
    }

    return <>

        {alert.visible && alertType[alert.type]}

        <div className="aside-container">

            <IoArrowForwardSharp className="back-aside-icon" onClick={() => back()} />

            <h3> Transferir Atendimento </h3>

            <small>Selecione um atendente ou grupo para encaminhar {contact.nome}</small>

            <div className="aside-transfer-guides">
                <div className={`aside-transfer-view ${show === "atendentes" ? "selected-view" : ""}`} onClick={() => setShow("atendentes")}> Atendentes </div>
                <div className={`aside-transfer-view ${show === "grupos" ? "selected-view" : ""}`} onClick={() => setShow("grupos")}> Grupos</div>
            </div>

            {show === "atendentes" ? <>

                <div className="attendances-list">
                    {attendances.map((attendance, index) => {
                        return (
                            <div className="attendances-list-item" key={index}>
                                <div className="attendances-list-item-avatar-details">
                                    <div className="attendances-list-item-avatar">
                                        <img src={attendance.foto ? attendance.foto : "/imgs/avatar2.png"} className="avatar-contact" alt="avatar" />
                                    </div>
                                    <div className="attendances-list-item-details">
                                        <small> <FaUserAlt /> {attendance.nome} </small>
                                        <small> <FaBuilding /> {attendance.departamentoNome ? attendance.departamentoNome : "Sem departamento"} </small>
                                        <small> <FaWarehouse /> {attendance.setorNome ? attendance.setorNome : "Sem setor"} </small>
                                    </div>
                                </div>
                                <div className="attendances-list-item-buttons">
                                    <BiTransfer className="attendances-list-item-icon" onClick={loading ? null : () => transferContactToAtt(attendance)} />
                                </div>
                            </div>
                        )
                    })}
                </div>

            </> : <>

                <div className="attendances-list">
                    {groups.map((grp, index) => {
                        return (
                            <div className="attendances-list-item" key={index}>
                                <div className="attendances-list-item-avatar-details">
                                    <div className="attendances-list-item-avatar">
                                        <img src={grp.foto ? grp.foto : "/imgs/avatar2.png"} className="avatar-contact" alt="avatar" />
                                    </div>
                                    <div className="attendances-list-item-details">
                                        <p> <MdGroup /> {grp.nome} </p>
                                    </div>
                                </div>
                                <div className="attendances-list-item-buttons">
                                    <BiTransfer className="attendances-list-item-icon" onClick={loading ? null : () => transferContactToGroup(grp)} />
                                </div>
                            </div>
                        )
                    })}
                </div>

            </>}

        </div>

    </>
}