import React, { useState, useEffect, useContext } from 'react';
import Checkbox from '../AttendanceCheckBox/Checkbox';
import CheckboxList from '../AttendanceCheckBox/CheckboxList';
import CheckboxSearch from '../AttendanceCheckBox/CheckboxSearch';
import ApiUsers from '../../../../../ApiUsers';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';

export default function AttendanceEdit(props) {

    const prefContext = useContext(PrefContext);
    const [group, setGroup] = useState(structuredClone(props.group));
    const [users, setUsers] = useState([]);
    const [usersOnShow, setUsersOnShow] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [unselectedUsers, setUnselectedUsers] = useState([]);
    const [filters, setFilters] = useState({ nome: "", departamento: "", setor: "" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let allUsers = prefContext.users.filter((u) => u.atendente && u.nome !== "Voxcity");
        let filterNoUsers = allUsers.filter((userObj) => !group.usuarios.includes(userObj._id));
        let filterUsers = allUsers.filter((userObj) => group.usuarios.includes(userObj._id));
        let newGroup = { ...group };
        newGroup.usuarios = filterUsers;
        setUsers(filterNoUsers);
        setUsersOnShow(filterNoUsers);
        setGroup(newGroup);
    }, [])

    useEffect(() => {
        searchUsers();
    }, [filters, users]);

    function changeName(e) {
        let newGroup = { ...group }
        newGroup.nome = e.target.value;
        setGroup(newGroup);
    }

    function selectUser(checked, user) {
        let newUsers = [...selectedUsers]
        if (checked) {
            newUsers = [...newUsers, user];
        } else {
            newUsers = newUsers.filter((u) => u.nome !== user.nome);
        }
        setSelectedUsers(newUsers);
    }

    function unselectUser(checked, user) {
        let newUsers = [...unselectedUsers]
        if (checked) {
            newUsers = [...newUsers, user];
        } else {
            newUsers = newUsers.filter((u) => u.nome !== user.nome);
        }
        setUnselectedUsers(newUsers);
    }

    function selectAll(checked) {
        if (checked) {
            let newUsers = [...usersOnShow];
            setSelectedUsers(newUsers);
        } else {
            setSelectedUsers([]);
        }
    }

    function unselectAll(checked) {
        if (checked) {
            let newUsers = [...group.usuarios];
            setUnselectedUsers(newUsers);
        } else {
            setUnselectedUsers([]);
        }
    }

    function addUsers() {
        let newGroup = { ...group };
        if (selectedUsers.length > 0) {
            newGroup.usuarios = [...newGroup.usuarios, ...selectedUsers];
            let newList = users.filter((u) => {
                if (!selectedUsers.some((i) => i._id.toString() === u._id.toString())) {
                    return u;
                }
            })
            setGroup(newGroup);
            setUsers(newList);
            setUsersOnShow(newList);
            setSelectedUsers([]);
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Por favor, selecione ao menos um usuário!" });
        }
    }

    function delUsers() {
        let newGroup = { ...group };
        if (unselectedUsers.length > 0) {
            newGroup.usuarios = newGroup.usuarios.filter((u) => {
                if (!unselectedUsers.some((p) => u._id.toString() === p._id.toString())) return u;
            })
            let newList = [...users, ...unselectedUsers];
            setUsers(newList);
            setUsersOnShow(newList);
            setGroup(newGroup);
            setUnselectedUsers([]);
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Por favor, selecione ao menos um usuário!" });
        }
    }

    function editGroup() {
        if (group.nome.trim() !== "") {
            let copyGroup = JSON.parse(JSON.stringify(group));
            copyGroup.usuarios = copyGroup.usuarios.map((u) => u._id);
            ApiUsers.put("/atendimentos/grupos", { grupo: copyGroup }).then((res) => {
                props.alert("normal", { title: "Sucesso!", placeholder: "Grupo de atendimento editado com sucesso!" });
                props.back();
            }).catch(err => console.log(err));
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
        }
    }

    function searchUsers() {
        let userList = [...users]
        const { nome, departamento, setor } = filters;

        if (nome.trim() !== "" && !departamento && !setor) {
            userList = userList.filter(user => {
                if (user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
                return false;
            })
        }
        if (nome.trim() === "" && departamento) {
            userList = userList.filter(user => {
                if (user.departamentoNome === departamento) return user;
                return false;
            })
        }
        if (nome.trim() === "" && departamento && setor) {
            userList = userList.filter(user => {
                if (user.departamentoNome === departamento && user.setorNome === setor) return user;
                return false;
            })
        }
        if (nome.trim() !== "" && departamento) {
            userList = userList.filter(user => {
                if (user.departamentoNome === departamento && user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
                return false;
            })
        }
        if (nome.trim() !== "" && departamento && setor) {
            userList = userList.filter(user => {
                if (user.departamentoNome === departamento && user.setorNome === setor && user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
                return false;
            })
        }
        setUsersOnShow(userList);
    }

    function addFilter(step, filter) {
        let newFilter = { ...filters };
        if (step === "departamento") {
            newFilter.departamento = filter;
            newFilter.setor = "";
        }
        else if (step === "setor") {
            newFilter.setor = filter;
        }
        else {
            newFilter.nome = filter;
        }
        setFilters(newFilter);
    }

    return <>

        <div className="attendance-form-container">

            <div className="attendance-input-container">
                <span className="attendance-span"> Nome do Grupo: </span>
                <input type="text" className="attendance-input" placeholder="Digite aqui o nome do grupo" value={group.nome} onChange={(e) => changeName(e)} />
            </div>

            <Checkbox>
                <CheckboxSearch filter={(step, filter) => addFilter(step, filter)} />
                <div className='checkbox-list-container'>
                    <CheckboxList
                        itsUsers={false}
                        users={usersOnShow}
                        selectedUsers={selectedUsers}
                        selectUser={(checked, user) => selectUser(checked, user)}
                        selectAll={(checked) => selectAll(checked)}
                        save={() => addUsers()}
                    />
                    <CheckboxList
                        itsUsers={true}
                        users={group.usuarios}
                        selectedUsers={unselectedUsers}
                        selectUser={(checked, user) => unselectUser(checked, user)}
                        selectAll={(checked) => unselectAll(checked)}
                        save={() => delUsers()}
                    />
                </div>
            </Checkbox >

            <div className="attendance-buttons-container">
                <button className="attendance-buttons" style={{ backgroundColor: "#c23838", marginRight: 10 }} onClick={() => props.back()}>Voltar</button>
                <button className="attendance-buttons" style={{ backgroundColor: "#127e4c" }} onClick={() => editGroup()}>Salvar</button>
            </div>

        </div >

    </>
}