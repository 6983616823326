import { useState, useEffect, useContext } from "react";
import { CidadaoContext } from "../../../../contexts/CidadaoProvider";
import { CalendarioContext } from "../../../../contexts/CalendarioProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { CidadaoModal } from "../CidadaoModal";
import { MarcarAgendamento } from "./MarcarAgendamento";
import { Header } from "./Header";
import { Content } from "./Content";
import { compareDates } from "../../../miscelaneous/utils";
import "../calendarStyle.css";
import { Alert } from "../../../miscelaneous/Alert";

export const AgendaDia = (props) => {
  const cidadaoContext = useContext(CidadaoContext);
  const calendarioContext = useContext(CalendarioContext);
  const userContext = useContext(UserContext);
  const { cidadaoSocket } = userContext;
  const [markedDates, setMarkedDates] = useState([]);
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState("");
  const [horarioFuncionamento, setHorarioFuncionamento] = useState({});
  const [agendaSelecionada, setAgendaSelecionada] = useState({});
  const [allCidadaos, setAllCidadaos] = useState([]);
  const [showCidadaoModal, setShowCidadaoModal] = useState(false);
  const [cidadaoSelecionado, setCidadaoSelecionado] = useState({});
  const [showConfirmarAgendamento, setShowConfirmarAgendamento] =
    useState(false);
  const [dataSelecionada, setDataSelecionada] = useState("");
  const [agendaDia, setAgendaDia] = useState({});
  const [showAlertDesmarcar, setShowAlertDesmarcar] = useState(false);
  const [showAlertDesbloquear, setShowAlertDesbloquear] = useState(false);
  const [showAlertConfirmar, setShowAlertConfirmar] = useState(false);
  const [showAlertBloquear, setShowAlertBloquear] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [datasSelecionadas, setDatasSelecionadas] = useState([]);

  useEffect(() => {
    cidadaoContext.getAllCidadaos();
  }, []);

  useEffect(() => {
    if (cidadaoSocket) initSocket();

    return () => {
      if (cidadaoSocket) socketOff();
    };
  }, [cidadaoSocket, agendaDia]);

  useEffect(() => {
    const novosAllCidadaos = cidadaoContext.allCidadaos
      ? [...cidadaoContext.allCidadaos]
      : [];
    setAllCidadaos(novosAllCidadaos);
  }, [cidadaoContext.allCidadaos]);

  useEffect(() => {
    let newMarkedDates = props.markedDates ? [...props.markedDates] : [];
    newMarkedDates = newMarkedDates.map((data) => {
      const dataFormatada = new Date(data.data);
      const usuario = data.usuario;
      const status = data.status;
      const hash = data.hashConfirmacao;
      dataFormatada.setHours(dataFormatada.getHours() + 3);
      return {
        data: dataFormatada.toString(),
        usuario: usuario,
        status: status,
        hash: hash,
        absente: data?.absente,
      };
    });
    setMarkedDates(newMarkedDates);
  }, [props.markedDates]);

  useEffect(() => {
    const novosHorariosDisponiveis = props.horariosDisponiveis
      ? [...props.horariosDisponiveis]
      : [];
    setHorariosDisponiveis(novosHorariosDisponiveis);
  }, [props.horariosDisponiveis]);

  useEffect(() => {
    const novaAgendaSelecionada = props.agendaSelecionada
      ? { ...props.agendaSelecionada }
      : {};
    setAgendaSelecionada(novaAgendaSelecionada);
  }, [props.agendaSelecionada]);

  useEffect(() => {
    const novoHorarioFuncionamento = props.horarioFuncionamento
      ? { ...props.horarioFuncionamento }
      : {};
    setHorarioFuncionamento(novoHorarioFuncionamento);
  }, [props.horarioFuncionamento]);

  useEffect(() => {
    const novoDiaSelecionado = props.diaSelecionado ? props.diaSelecionado : "";
    setDiaSelecionado(novoDiaSelecionado);
  }, [props.diaSelecionado]);

  useEffect(() => {
    const novaAgendaDia = {
      funcionamento: [...markedDates, ...horariosDisponiveis],
      marcadas: [...markedDates],
      disponiveis: [...horariosDisponiveis],
    };
    setAgendaDia(novaAgendaDia);
  }, [markedDates, horariosDisponiveis]);

  useEffect(() => {
    if (agendaDia.funcionamento) {
      agendaDia.funcionamento.sort(compareDates);
    }
  }, [agendaDia.funcionamento]);

  function socketOff() {
    cidadaoSocket.off("desmarcar agenda");
    cidadaoSocket.off("update agenda");
    cidadaoSocket.off("desbloquear agenda");
    cidadaoSocket.off("bloquear agenda");
  }

  function initSocket() {
    cidadaoSocket.on("desmarcar agenda", (agenda) => {
      calendarioContext.setUnMarked(agenda);
      const novaDataMarcada = new Date(agenda.dataSelecionada.data);
      removeMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: agenda.usuario,
      });
    });

    cidadaoSocket.on("desbloquear agenda", (agenda) => {
      calendarioContext.setUnMarked(agenda);
      const novaDataMarcada = new Date(agenda.dataSelecionada.data);
      removeMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: agenda.usuario,
      });
    });

    cidadaoSocket.on("update agenda", (agenda) => {
      calendarioContext.setMarked(agenda);
      const novaDataMarcada = new Date(agenda.data);
      novaDataMarcada.setHours(novaDataMarcada.getHours() + 3);
      updateMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: agenda.usuario,
        status: agenda.status,
        hash: agenda.hash,
      });
    });

    cidadaoSocket.on("bloquear agenda", (agenda) => {
      calendarioContext.setMarked(agenda);
      const novaDataMarcada = new Date(agenda.dataSelecionada);
      addNewMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: agenda.usuario,
        status: agenda.status,
        hash: agenda.hash,
      });
    });
  }

  const closeModalOnOutsideClick = (e) => {
    const { className } = e.target;
    const bgModal = className === "bg-modal";
    const bgModalAgendaDia = className === "bg-modal-agenda-dia";

    if (bgModal) props.setShowModal(false);

    if (bgModalAgendaDia || bgModal) {
      setShowCidadaoModal(false);
      setShowConfirmarAgendamento(false);
      if (showCidadaoModal || showConfirmarAgendamento)
        props.setShowModal(true);
    }
  };

  const updateMarkedDate = (markedDate) => {
    const newMarkedDates = markedDates.map((data) =>
      data.data === markedDate.data ? markedDate : data
    );
    setMarkedDates(newMarkedDates);
    removeDisponivel(markedDate);
  };

  const addNewMarkedDate = (markedDate) => {
    const newMarkedDates = [...markedDates, markedDate];
    newMarkedDates.sort(compareDates);
    setMarkedDates(newMarkedDates);
    removeDisponivel(markedDate);
  };

  const removeDisponivel = (markedDate) => {
    const newDisponiveis = horariosDisponiveis.filter(
      (data) => data !== markedDate.data
    );
    setHorariosDisponiveis(newDisponiveis);
  };

  const addDisponivel = (markedDate) => {
    const newDisponiveis = [...horariosDisponiveis, markedDate.data];
    newDisponiveis.sort(compareDates);
    setHorariosDisponiveis(newDisponiveis);
  };

  const removeMarkedDate = (markedDate) => {
    const newMarkedDates = markedDates.filter(
      (data) => data.data !== markedDate.data
    );
    setMarkedDates(newMarkedDates);
    addDisponivel(markedDate);
  };

  const nextDate = (date) => {
    const nextDay = new Date(date);
    const currentDay = nextDay.getDate();
    nextDay.setDate(currentDay + 1);
    const newDayString = nextDay.toString();
    props.handleTileClick(newDayString);
  };

  const prevDate = (date) => {
    const prevDay = new Date(date);
    const currentDay = prevDay.getDate();
    prevDay.setDate(currentDay - 1);
    const newDayString = prevDay.toString();
    props.handleTileClick(newDayString);
  };

  const getCidadao = (id) => {
    const cidadao = allCidadaos.find((cidadao) => cidadao._id === id);
    setCidadaoSelecionado(cidadao);
    setShowCidadaoModal(true);
  };

  const submitMarcarAgendamento = (data) => {
    setShowConfirmarAgendamento(true);
    setDataSelecionada(data);
  };

  const submitCancelarAgendamento = () => {
    setAlertType("confirm");
    setShowAlertDesmarcar(true);
  };

  const submitDesbloquarAgendamento = async () => {
    setAlertType("confirm");
    setShowAlertDesbloquear(true);
  };

  const submitConfirmarAgendamento = (data) => {
    setAlertType("confirm");
    setShowAlertConfirmar(true);
    setDataSelecionada(data);
  };

  const confirmarAgendamento = async () => {
    const hash = dataSelecionada.hash;
    await calendarioContext
      .confirmarAgendamento(hash)
      .then((response) => {
        if (response.data.message === "Agendamento confirmado com sucesso!") {
          console.log(response.data.message);
        } else {
          setAlertType("error");
          setShowAlertDesmarcar(true);
        }
      })
      .catch((error) => {
        // setAlertType("error");
        // setShowAlertConfirmar(true);
        console.log(error);
      });
  };

  const handleConfirmationAction = async () => {
    setShowAlertDesmarcar(false);

    const novasDatasSelecionadas = datasSelecionadas.map((dataSelecionada) => {
      const novaDataSelecionada = new Date(dataSelecionada.data);
      novaDataSelecionada.setHours(novaDataSelecionada.getHours() - 3);
      return {
        prefeitura: calendarioContext.prefeituraId,
        especialidade: agendaSelecionada.especialidade,
        local: agendaSelecionada.local,
        servico: agendaSelecionada.serviço,
        profissional: agendaSelecionada.profissional,
        data: novaDataSelecionada.toISOString(),
        usuario: dataSelecionada.usuario,
        dataSelecionada: dataSelecionada,
      };
    });

    for (const data of novasDatasSelecionadas) {
      try {
        const response = await calendarioContext.desmarcarHorario(data);
        if (response.data.message === "Agendamento desmarcado com sucesso!") {
          console.log(response.data.message);
        } else {
          setAlertType("error");
          setShowAlertDesmarcar(true);
        }
      } catch (error) {
        setAlertType("error");
        setShowAlertDesmarcar(true);
        console.log(error);
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    setDatasSelecionadas([]);
  };

  const desbloquear = async () => {
    setShowAlertDesbloquear(false);
    const novasDatasSelecionadas = datasSelecionadas.map((dataSelecionada) => {
      const novaDataSelecionada = new Date(dataSelecionada.data);
      novaDataSelecionada.setHours(novaDataSelecionada.getHours() - 3);
      return {
        prefeitura: calendarioContext.prefeituraId,
        especialidade: agendaSelecionada.especialidade,
        local: agendaSelecionada.local,
        servico: agendaSelecionada.serviço,
        profissional: agendaSelecionada.profissional,
        data: novaDataSelecionada.toISOString(),
        usuario: dataSelecionada.usuario,
        dataSelecionada: dataSelecionada,
      };
    });

    for (const data of novasDatasSelecionadas) {
      try {
        const response = await calendarioContext.desbloquearHorario(data);
        if (response.data.message === "Agendamento desbloqueado com sucesso!") {
          console.log(response.data.message);
        } else {
          setAlertType("error");
          setShowAlertDesbloquear(true);
        }
      } catch (error) {
        setAlertType("error");
        setShowAlertDesbloquear(true);
        console.log(error);
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    setDatasSelecionadas([]);
  };

  const submitBloquearAgendamento = () => {
    setAlertType("confirm");
    setShowAlertBloquear(true);
  };

  const bloquear = async () => {
    const novasDatasSelecionadas = datasSelecionadas.map((data) => {
      const newData = new Date(data);
      newData.setHours(newData.getHours() - 3);
      return {
        especialidade: agendaSelecionada.especialidade,
        servico: agendaSelecionada.serviço,
        prefeitura: calendarioContext.prefeituraId,
        profissional: agendaSelecionada.profissional,
        local: agendaSelecionada.local,
        data: newData,
        dataSelecionada: data,
      };
    });

    for (const data of novasDatasSelecionadas) {
      try {
        const response = await calendarioContext.bloquearHorario(data);
        if (response.data.message === "Agendamento bloqueado com sucesso!") {
          console.log(response.data.message);
        } else {
          setAlertType("error");
          setShowAlertBloquear(true);
        }
      } catch (error) {
        console.log(error);
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    setShowConfirmarAgendamento(false);
    setShowAlertBloquear(false);
    setDatasSelecionadas([]);
  };

  const desmarcarType = {
    confirm: (
      <Alert
        title={`Desmarcar Agendamento!`}
        message="Deseja realmente desmarcar esse agendamento?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={handleConfirmationAction}
        quit={() => setShowAlertDesmarcar(false)}
      />
    ),
  };

  const desbloquearType = {
    confirm: (
      <Alert
        title={`Desbloquear Agendamento!`}
        message="Deseja realmente desbloquear esse agendamento?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={desbloquear}
        quit={() => setShowAlertDesbloquear(false)}
      />
    ),
  };

  const confirmarType = {
    confirm: (
      <Alert
        title={`Confirmar Agendamento!`}
        message="Deseja realmente confirmar esse agendamento?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={confirmarAgendamento}
        quit={() => setShowAlertConfirmar(false)}
      />
    ),
  };

  const bloquearType = {
    confirm: (
      <Alert
        title={`Bloquear Agendamento!`}
        message="Deseja realmente bloquear esse agendamento?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={bloquear}
        quit={() => setShowAlertBloquear(false)}
      />
    ),
  };

  return (
    <>
      {showAlertBloquear && alertType === "confirm" && bloquearType[alertType]}
      {showAlertDesmarcar &&
        alertType === "confirm" &&
        desmarcarType[alertType]}

      {showAlertDesbloquear &&
        alertType === "confirm" &&
        desbloquearType[alertType]}

      {showAlertConfirmar &&
        alertType === "confirm" &&
        confirmarType[alertType]}

      {props.showModal && (
        <div
          className="bg-modal"
          onClick={(e) => closeModalOnOutsideClick(e)}
        ></div>
      )}
      <div className="modal-calendario-wrapper">
        <div className="rasgo-da-folha"></div>
        <div className="furo-da-folha"></div>
        <div className="modal-calendario">
          <>
            <Header
              diaSelecionado={diaSelecionado}
              horarioFuncionamento={horarioFuncionamento}
              agendaSelecionada={agendaSelecionada}
              submitCancelarAgendamento={submitCancelarAgendamento}
              datasSelecionadas={datasSelecionadas}
              submitDesbloquarAgendamento={submitDesbloquarAgendamento}
              submitBloquearAgendamento={submitBloquearAgendamento}
            />
            <div className="modal-calendario-content">
              <Content
                agendaDia={agendaDia}
                allCidadaos={allCidadaos}
                getCidadao={getCidadao}
                submitMarcarAgendamento={submitMarcarAgendamento}
                setDatasSelecionadas={setDatasSelecionadas}
                submitConfirmarAgendamento={submitConfirmarAgendamento}
                updateMarkedDate={updateMarkedDate}
              />
            </div>
            {!showCidadaoModal &&
              !showConfirmarAgendamento &&
              !props.isLoading && (
                <div className="button-container">
                  <MdArrowBackIosNew
                    id="previous"
                    type="button"
                    onClick={() => prevDate(diaSelecionado)}
                  />
                  <MdArrowForwardIos
                    id="next"
                    type="button"
                    onClick={() => nextDate(diaSelecionado)}
                  />
                </div>
              )}
          </>
        </div>
      </div>
      {showCidadaoModal && (
        <CidadaoModal
          showCidadaoModal={showCidadaoModal}
          closeModalOnOutsideClick={closeModalOnOutsideClick}
          cidadaoSelecionado={cidadaoSelecionado}
          addMarkedDate={addNewMarkedDate}
          dataSelecionada={dataSelecionada}
        />
      )}
      {showConfirmarAgendamento && (
        <MarcarAgendamento
          showConfirmarAgendamento={showConfirmarAgendamento}
          setShowConfirmarAgendamento={setShowConfirmarAgendamento}
          agendaSelecionada={agendaSelecionada}
          closeModalOnOutsideClick={closeModalOnOutsideClick}
          dataSelecionada={dataSelecionada}
          markedDates={markedDates}
          addMarkedDate={addNewMarkedDate}
        />
      )}
    </>
  );
};
