import React, { useState, useEffect, useContext } from "react";
import { ListGroup } from "react-bootstrap";
import { UserContext } from "../../../../contexts/UserProvider.jsx";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider.jsx";
import Modal from "./Modal.jsx";
import NewAttendance from "./NewAttendance.jsx";
import { BsSearch, BsPlus, BsInfoCircle } from "react-icons/bs";

export default function Attendance(props) {
  const userContext = useContext(UserContext);
  const attendanceContext = useContext(AttendanceContext);
  const [attendance, setAttendance] = useState([]);
  const [attendanceOnShow, setAttendanceOnShow] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    let attendances = [...attendanceContext.attendances].filter(
      (att) => att.atendente === userContext.user._id
    );
    setAttendance(attendances);
    setAttendanceOnShow(attendances);
  }, [attendanceContext.attendances]);

  const doSearch = (value) => {
    if (value === "") {
      setAttendanceOnShow([...attendance]);
    } else {
      let newAttendance = attendance.filter(
        (attendance) =>
          attendance.pushname.toLowerCase().includes(value.toLowerCase()) ||
          attendance.telefone.toLowerCase().includes(value.toLowerCase())
      );
      setAttendanceOnShow(newAttendance);
    }
  };

  useEffect(() => {
    doSearch(searchInput);
  }, [searchInput, attendance]);

  function countAndSlice(message) {
    if (!message) return "";
    let count = message.length;
    if (count > 20) {
      message = message.slice(0, 20);
      message = message + "...";
    }
    return message;
  }
  function selectAtt(contact) {
    let attendance = [...attendanceContext.attendances].find(
      (att) => att.telefone === contact.telefone
    );
    props.onSelect(null, attendance);
  }

  return (
    <>
      {modal && (
        <>
          <Modal close={() => setModal(false)}>
            <NewAttendance
              close={() => setModal(false)}
              selectContact={(contact) => selectAtt(contact)}
            />
          </Modal>
        </>
      )}

      <div className="container-search">
        <input
          type="search"
          placeholder="Buscar Contato..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        {/* <div className="button-search-box" onClick={doSearch}>
          <BsSearch size={20} />
        </div> */}

        <div className="button-search-box" onClick={() => setModal(true)}>
          <BsPlus size={20} />
        </div>
      </div>

      <div className="attendance-list">
        {attendanceOnShow?.length === 0 && searchInput !== "" && (
          <div className="container-information-nothingfound-att">
            <BsInfoCircle size={50} color="#9ac31c" />
            <p>
              <strong>Nenhum atendimento encontrado !</strong>
            </p>
            <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
              Verifique o contato ou crie um novo atendimento.
            </p>
          </div>
        )}

        {attendanceOnShow?.map((contact, i) => {
          let background = "inherit";
          if (contact.telefone === props.selected?.telefone)
            background = "#F0FFF0";
          let lastMsgTime;
          let formatedDate;
          let formatedTime;
          if (contact.lastMessage) {
            lastMsgTime = new Date(contact.lastMessage.createdAt);
            formatedDate =
              timeHasZero(lastMsgTime.getDate()) +
              "/" +
              timeHasZero(lastMsgTime.getMonth() + 1) +
              "/" +
              timeHasZero(lastMsgTime.getFullYear());
            formatedTime =
              timeHasZero(lastMsgTime.getHours()) +
              ":" +
              timeHasZero(lastMsgTime.getMinutes());
          }
          return (
            <div key={i + 2 * 969403} >
              <div
                className="contact-wrapper"
                style={{
                  backgroundColor: background,
                  display: "flex",
                  alignItems: "center",
                }}
                key={i}
                onClick={(e) => {
                  props.onSelect(e, contact);
                }}
              >
                <img
                  className="contact-avatar"
                  src={contact.foto ? contact.foto : "/imgs/avatar2.png"}
                  alt=""
                  onError={(e) => {
                    e.target.src = "/imgs/avatar2.png";
                  }}
                />
                <ListGroup.Item
                  className="contact-data"
                  style={{
                    backgroundColor: background,
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  key={i}
                >
                  <b>
                    {contact.nome
                      ? contact.nome
                      : contact.pushname
                        ? contact.pushname
                        : contact.telefone
                          ?.toString()
                          .replace("@c.us", " ")
                          .trim()}
                  </b>
                  <p style={{ margin: "4px 0px" }}>
                    {" "}
                    {countAndSlice(contact.lastMessage?.message)}{" "}
                  </p>
                  {contact.lastMessage?.createdAt ? (
                    <>
                      <small className="small-title">
                        {" "}
                        Ultima mensagem às {formatedTime} de {formatedDate}{" "}
                      </small>
                    </>
                  ) : (
                    <>
                      <small className="small-title">
                        {" "}
                        Não há mensagem disponível{" "}
                      </small>
                    </>
                  )}
                </ListGroup.Item>
                  {contact.unseen && contact.unseen.length > 0 && (
                    <div className="not-seen">
                      <b >
                        {contact.unseen.length}
                      </b>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function timeHasZero(time) {
  if (time < 10) return "0" + time;
  return time;
}
