import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import { BsInfoCircleFill, BsX } from "react-icons/bs";
import TableLeft from "./components/tableLeft";
import TableRight from "./components/tableRight";
import ButtonsTransfer from "../../Campanhas/campanhas/CampanhaForm/AddContatos/Table/ButtonsTransfer";
import ButtonsFooter from "../ButtonsFooter";

export default function ModalTable({ isModalTableOpen, toggleModalTable, handleAddMembers, handleAddAtendentes, members, atendentes }) {
  const { attendances } = useContext(AttendanceContext);
  const { pref } = useContext(UserContext);
  const [attendancesOnShow, setAttendancesOnShow] = useState([]);
  const [myContatosOnShow, setMyContatosOnShow] = useState([]);
  const [selectedFromLeft, setSelectedFromLeft] = useState([]);
  const [selectedFromRight, setSelectedFromRight] = useState([]);

  const handleLeftToRight = () => {
    const newMyContatosOnShow = [...selectedFromLeft, ...myContatosOnShow];
    setMyContatosOnShow(newMyContatosOnShow);
    setAttendancesOnShow(
      attendancesOnShow.filter((item) => !selectedFromLeft.includes(item))
    );
    setSelectedFromLeft([]);
  };


  const handleRightToLeft = () => {
    const newAttendanceOnShow = [...selectedFromRight, ...attendancesOnShow];
    setAttendancesOnShow(newAttendanceOnShow);
    setMyContatosOnShow(
      myContatosOnShow.filter((item) => !selectedFromRight.includes(item))
    );
    setSelectedFromRight([]);
  };


  useEffect(() => {
    if (isModalTableOpen.type === 'atendentes') return
    const newAttendances = attendances.filter((contact) => {
      return !myContatosOnShow.some(
        (item) => item.telefone === contact.telefone
      );
    });
    const newAttendanceFiltered = newAttendances.map((att) => {
      return {
        nome: att.nome,
        telefone: att.telefone,
        pushname: att.pushname,
        _id: att._id,
        admin: false,
      };
    });
    setAttendancesOnShow(newAttendanceFiltered);
  }, [attendances, myContatosOnShow]);

  useEffect(() => {
    if (isModalTableOpen.type === 'members') return
    let prefAtts = pref?.users?.filter((user) => user.atendente);
    const newAttendances = prefAtts.filter((contact) => {
      return !myContatosOnShow.some(
        (item) => item._id === contact._id
      );
    });
    setAttendancesOnShow(newAttendances);
  }, [pref?.users, myContatosOnShow]);

  useEffect(() => {
    if (isModalTableOpen.type === 'members') {
      const newContacts = members ? [...members] : [];
      setMyContatosOnShow(newContacts);
    } else if (isModalTableOpen.type === 'atendentes') {
      let newContacts = atendentes ? [...atendentes] : [];
      if (newContacts.length > 0) {
        let prefAtts = pref?.users?.filter((user) => {
          if (user.atendente) {
            return newContacts.some((item) => item._id === user._id);
          }
        });
        newContacts = prefAtts;
      }
      setMyContatosOnShow(newContacts);
    }
  }, []);

  const handleSave = () => {
    if (isModalTableOpen.type === 'members') {
      handleAddMembers(myContatosOnShow);
    } else if (isModalTableOpen.type === 'atendentes') {
      handleAddAtendentes(myContatosOnShow);
    }
  }

  return (
    <div className="overlay">
      <div className="modal-content-group">
        <div className="container-header-modal">
          <div className="container-align-text-modal">
            <h3>{isModalTableOpen.type === 'members' ? 'Adicionar membros' : 'Adicionar atendentes'}</h3>
            <button className="fechar" onClick={() => toggleModalTable(isModalTableOpen.type)}>
              <BsX size={40} color="#9AC31C" />
            </button>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="add-contatos-container-text-information">
              <BsInfoCircleFill size={20} color="#9AC31C" />
              <p>
                {isModalTableOpen.type === 'members' ? 'Selecione para adicionar ou desmarque para remover membros do grupo, depois salve a lista.' : 'Selecione para adicionar ou desmarque para remover atendentes do grupo, depois salve a lista.'}
              </p>
            </div>
            <p style={{ color: "#989898", marginTop: "-8px" }}>
              {isModalTableOpen.type === 'members' ? 'Adicione pelo menos um membro ao grupo (OBRIGATÓRIO)' : ''}
            </p>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <TableLeft attendancesOnShow={attendancesOnShow} selectedFromLeft={selectedFromLeft} setSelectedFromLeft={setSelectedFromLeft} />

            <ButtonsTransfer handleLeftToRight={handleLeftToRight} handleRightToLeft={handleRightToLeft} />

            <TableRight myContatosOnShow={myContatosOnShow} setMyContatosOnShow={setMyContatosOnShow} selectedFromRight={selectedFromRight} setSelectedFromRight={setSelectedFromRight} isModalTableOpen={isModalTableOpen} />

          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <ButtonsFooter onClick={handleSave} onClose={() => toggleModalTable(isModalTableOpen.type)} />
          </div>
        </div>
      </div>
    </div>
  );
}
