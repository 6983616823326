import React, { useState, useEffect, useContext } from "react";
import MainView from "../main/MainView";
import LeftCol from "../main/LeftCol";
import RightCol from "../main/RightCol";
import Atendimentos from "./general/Atendimentos";
import Messagersender from "./general/Messagersender";
import MessageTitle from "./general/MessageTitle";
import AttendanceNavBar from "./general/attendances/AttendanceNavBar";
import { AttendanceContext } from "../../contexts/ChatBotProvider/AttendanceProvider";
import MessageScreen from "./general/MessageScreen";
import { UserContext } from "../../contexts/UserProvider";
import { GrupoContext } from "../../contexts/ChatBotProvider/GrupoWhatsProvider";
import { ReplyForwardingContext } from "../../contexts/ReplyForwardingProvider";
import EmptyMessages from "./general/utils/EmptyMessages";
import "react-toastify/dist/ReactToastify.css";
import "./chat.css";
import { MessageContext } from "../../contexts/ChatBotProvider/MessageProvider";

function ChatAtts(props) {
  const [selectedContact, setSelectedContact] = useState(false);
  const [navigation, setNavigation] = useState("atendimentos");
  const context = useContext(AttendanceContext);
  const gContext = useContext(GrupoContext);
  const { user } = useContext(UserContext);
  const { setReplyMessage } = useContext(ReplyForwardingContext);
  const { setLookingMessage, setOnSearch } = useContext(MessageContext);

  function changeNavigation(nav) {
    setNavigation(nav);
    setReplyMessage(null);
    setOnSearch(false);
    setLookingMessage(null);
  }

  useEffect(() => {
    if (selectedContact && !selectedContact.id_whatsapp) {
      let att = context.attendances.find((att) => att._id === selectedContact._id);
      let newSel = att;
      if (att) {
        newSel = JSON.parse(JSON.stringify(att));
        setSelectedContact(newSel);

      }
    } else if (selectedContact && selectedContact.id_whatsapp) {
      let group = gContext.groups.find((group) => group.id_whatsapp === selectedContact.id_whatsapp);
      let newSel = group;
      if (group) {
        newSel = JSON.parse(JSON.stringify(group));
        setSelectedContact(newSel);
      }
    }
  }, [context.attendances, gContext.groups]);

  useEffect(() => {
    setReplyMessage(null);
  }, []);

  const OnSelect = (e, contact) => {
    setSelectedContact(contact);
    setReplyMessage(null);
    setLookingMessage(null);
    setOnSearch(false);
    let messagesRead = contact?.unseen;
    let contactId = contact?.telefone || contact?.id_whatsapp;
    if (messagesRead?.length > 0) {
      if (messagesRead.length > 1000) {
        //send in parts
        let i = 0;
        let messagesReadPart = [];
        while (i < messagesRead.length) {
          messagesReadPart = messagesRead.slice(i, i + 1000);
          i += 1000;
          context.socket.emit("read cont messages", {
            contact: contactId,
            messages: messagesReadPart,
            type: "whats",
          });
        }
      } else {
        context.socket.emit("read cont messages", {
          contact: contactId,
          messages: messagesRead,
          type: "whats",
        });
      }
    }
  };

  return (
    <MainView>
      <LeftCol title="Chat de Atendimentos">
        <AttendanceNavBar
          changeNav={(nav) => changeNavigation(nav)}
          onSelect={(e, contact) => OnSelect(e, contact)}
        />
        <Atendimentos
          onShow={navigation}
          onSelect={(e, contact) => OnSelect(e, contact)}
          selected={selectedContact}
          user={user}
        />
      </LeftCol>

      <RightCol>
        {!selectedContact ? (
          <EmptyMessages
            nome={user?.nome}
            imgClass="welcome-att-img-empty-chat"
            image="/imgs/attendance.png"
            message="Selecione um contato ao lado para iniciar um atendimento"
          />
        ) : (
          <React.Fragment>
            <MessageTitle
              msgType={selectedContact?.id_whatsapp ? "whats-group" : "att"}
              navType={navigation}
              contact={selectedContact}
              cleanContact={() => setSelectedContact(false)}
            />
            <MessageScreen
              msgType={selectedContact?.id_whatsapp ? "whats-group" : "att"}
              contact={selectedContact}
              type="att"
            />
            <Messagersender
              msgType={selectedContact?.id_whatsapp ? "whats-group" : "att"}
              navType={navigation}
              contact={selectedContact}
            />
          </React.Fragment>
        )}
      </RightCol>
    </MainView>
  );
}
export default ChatAtts;
