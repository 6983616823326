import React from "react";
import { BsCheckCircle, BsChevronLeft } from "react-icons/bs";

export default function ButtonsFormAgendas({ props }) {
  return (
    <div className="cont-buttons-bot-actions">
      <button
        title="Clique para voltar"
        disabled={props.currentComponent === "Comands"}
        onClick={() => props.changeComponent("Comands")}
      >
        <BsChevronLeft color="#142a4c" size={20} />
      </button>

      <button
        title="Clique para salvar"
        disabled={props.loading}
        onClick={(e) => props.saveBot(e)}
      >
        <BsCheckCircle color="#142a4c" size={20} />
      </button>
    </div>
  );
}
