import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../../../contexts/UserProvider'
import AtendimentoBoxInputs from './components/atendimentoBoxInputs'
import Alert from '../../../../Configurações/OptionsScreen/inputs/Alerts/Alert'
import DeleteAlert from "../../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import ApiUsers from '../../../../../ApiUsers'

import './atendimento.css'

const Atendimento = ({ atendimento, closeContactInfo, setShowContact }) => {
    const { user, pref } = useContext(UserContext)
    const [imAdm, setImAdm] = useState(false)
    const [atendimentoInfo, setAtendimentoInfo] = useState({})
    const [atendente, setAtendente] = useState('')
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

    function createAlert(type, props) {
        setAlert({ visible: true, type, props });
    }

    const quitAlert = () => {
        setAlert({ visible: false, type: "", props: {} });
    }

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
        delete: <DeleteAlert nome={alert.props.nome} action={alert.props.action} data={alert.props.data} quit={() => quitAlert()} />
    }

    useEffect(() => {
        if (atendimento) {
            let newAtendimento = atendimento ? { ...atendimento } : {}
            setAtendimentoInfo(newAtendimento)
        }
    }, [atendimento])

    useEffect(() => {
        if (user?.admin) {
            setImAdm(true)
        }
    }, [])

    useEffect(() => {
        let nomeAtendente = pref.users.find((u) => u._id === atendimento.atendente)
        setAtendente(nomeAtendente?.nome)
    }, [atendimentoInfo])

    const finalize = (telefone) => {
        setAlert({ visible: true, type: 'delete', props: { data: 'Finalizar', nome: 'atendimento', action: () => finalizeAtendimento(telefone) } })
    }

    const finalizeAtendimento = (telefone) => {
        quitAlert()
        ApiUsers.post('/atendimentos/finalizeNoMessage', { telefone })
            .then(res => {
                if (res.data.msg) {
                    createAlert('normal', { title: "Sucesso!", placeholder: 'Atendimento finalizado com sucesso!' })
                } else {
                    createAlert('normal', { title: "Erro", placeholder: res.data.erro })
                }
                closeContactInfo()
                setShowContact()
            }).catch(err => {
                console.log(err);
                alert("Erro ao finalizar atendimento!")
            })
    }

    return (
        <div className='contact-info-modal'>
            {alert.visible && alertType[alert.type]}
            <div className="contact-info-atendimento-contander">
                <div className="contact-info-atendimento-body">
                    <AtendimentoBoxInputs atendimento={atendimentoInfo} createAlert={createAlert} />
                    <div className="atendimento-body-footer-item" style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                        <span>Atendente</span>
                        <p>{atendente ? atendente : 'Nenhum'}</p>
                    </div>
                </div>
                {imAdm && <div className="contact-info-atendimento-footer">
                    <button onClick={() => finalize(atendimento.telefone)} >Finalizar sem notificação</button>
                </div>
                }
            </div>
        </div>
    )
}

export default Atendimento
