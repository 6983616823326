import React, { useState, useEffect } from "react";

export const Funcionamento = (props) => {
  const diasSemana = [
    { index: 0, nome: "Domingo" },
    { index: 1, nome: "Segunda" },
    { index: 2, nome: "Terça" },
    { index: 3, nome: "Quarta" },
    { index: 4, nome: "Quinta" },
    { index: 5, nome: "Sexta" },
    { index: 6, nome: "Sábado" },
  ];

  const [especialidade, setEspecialidade] = useState({
    ...props.especialidade,
  });

  const [horarioSelecionado, setHorarioSelecionado] = useState({
    ...props.especialidade.horarioFuncionamento,
  });

  const [diasSelecionados, setDiasSelecionados] = useState([
    ...props.especialidade.horarioFuncionamento.dias,
  ]);

  useEffect(() => {
    let novaEspecialidade = { ...props.especialidade };
    let novoHorario = { ...props.especialidade.horarioFuncionamento };
    let novoDia = [...props.especialidade.horarioFuncionamento.dias];
    setEspecialidade(novaEspecialidade);
    setHorarioSelecionado(novoHorario);
    setDiasSelecionados(novoDia);
  }, [props.especialidade]);

  const onChangeDias = (checked, dia) => {
    let novaEspecialidade = { ...especialidade };
    let novosDiasSelecionados = [...diasSelecionados];
    if (checked) {
      novosDiasSelecionados = [...novosDiasSelecionados, dia];
    } else {
      novosDiasSelecionados = novosDiasSelecionados.filter((d) => d !== dia);
    }
    novaEspecialidade.horarioFuncionamento.dias = novosDiasSelecionados.sort();
    props.setEspecialidade(novaEspecialidade);
  };

  const onChangeHorario = (e, periodo) => {
    let novaEspecialidade = { ...especialidade };
    let novoHorarioSelecionado = { ...horarioSelecionado };
    if (periodo === "inicio") novoHorarioSelecionado.inicio = e.target.value;
    if (periodo === "fim") novoHorarioSelecionado.fim = e.target.value;
    novaEspecialidade.horarioFuncionamento = { ...novoHorarioSelecionado };
    props.setEspecialidade(novaEspecialidade);
  };

  return (
    <>
      <div className="especialidade-local-form-input-container-horario">
        <label htmlFor="horarioFuncionamento" className="required-prof">
          Horário de Funcionamento
        </label>
        <div className="input-container">
          <input
            type="time"
            id="nome"
            placeholder="Horário de Início"
            value={horarioSelecionado.inicio}
            onChange={(e) => onChangeHorario(e, "inicio")}
          />
          <h5 style={{ margin: "1rem 0.5rem" }}> às </h5>
          <input
            type="time"
            id="nome"
            placeholder="Horário de Término"
            value={horarioSelecionado.fim}
            onChange={(e) => onChangeHorario(e, "fim")}
          />
        </div>
      </div>

      <div className="especialidade-local-form-input-container-horario">
        <label htmlFor="diasSemana" className="required-prof">
          Dias da Semana
        </label>
        <div className="input-container">
          {diasSemana.map((dia, index) => {
            return (
              <div className="input-container-dias" key={index}>
                <input
                  type="checkbox"
                  checked={diasSelecionados.some((d) => d === dia.index)}
                  value={dia.index}
                  onChange={(e) => onChangeDias(e.target.checked, dia.index)}
                />
                <span>{dia.nome}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
