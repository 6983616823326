import React, { useState, useEffect } from "react";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

function PrTable(props) {
  const [orderedBy, setOrderedBy] = useState(props.orderedBy);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [procolosOnShow, setProtocolosOnShow] = useState([]);

  useEffect(() => {
    setOrderedBy(props.orderedBy);
  }, [props.orderedBy]);

  useEffect(() => {
    let newProtocolos = [...props.protocolos];
    const indexOfLastContact = currentPage * itemsPerPage;
    const indexOfFirstContact = indexOfLastContact - itemsPerPage;
    const filteredContacts = newProtocolos.slice(
      indexOfFirstContact,
      indexOfLastContact
    );
    setTotalPages(Math.ceil(newProtocolos.length / itemsPerPage));
    setProtocolosOnShow(filteredContacts);
  }, [itemsPerPage, currentPage, props.protocolos, props.orderedBy]);

  function normalizeDateAndTime(date) {
    if (!date) return "";
    let d = new Date(date);
    return d.toLocaleDateString() + " " + removeSeconds(d.toLocaleTimeString());
  }
  function removeSeconds(time) {
    return time.substring(0, time.length - 3);
  }

  return (
    <div className="pr-table-wrapper">
      <table className="pr-table">
        <thead className="pr-table-header">
          <tr>
            <th>Protocolo</th>
            <th>Contato (Telefone/Nome) </th>
            <th>Atendente</th>
            <th>
              Entrada
              {orderedBy === "sortByCreationDate" ? (
                <TiArrowSortedUp
                  onClick={(e) => {
                    props.orderByCreationDateReverse();
                  }}
                />
              ) : (
                <TiArrowSortedDown
                  onClick={(e) => {
                    props.orderByCreationDate();
                  }}
                />
              )}
            </th>
            <th>
              Encerramento
              {orderedBy === "sortBySaidaDate" ? (
                <TiArrowSortedUp
                  onClick={(e) => {
                    props.orderBySaidaDateReverse();
                  }}
                />
              ) : (
                <TiArrowSortedDown
                  onClick={(e) => {
                    props.orderBySaidaDate();
                  }}
                />
              )}
            </th>
            {/* <th>Tempo</th> */}
            {/* <th>Download</th> */}
          </tr>
        </thead>
        <tbody className="pr-table-body">
          {procolosOnShow?.map((protocolo) => {
            return (
              <tr
                key={protocolo._id}
                onClick={(e) => {
                  props.showPdf(protocolo);
                }}
                className="pr-table-row"
              >
                <td>{protocolo.numero}</td>
                <td>
                  {protocolo.contato?.nome +
                    " (" +
                    protocolo.contato?.telefone +
                    ")"}
                </td>
                <td>{protocolo.atts?.map((att) => att.nome).join(", ")}</td>
                <td>{normalizeDateAndTime(protocolo.entrada?.date)}</td>
                <td>{normalizeDateAndTime(protocolo.saida?.date)}</td>

                {/* <td>{protocolo.tempo}</td> */}
                {/* <td><MdFileDownload onClick={e=>{showPdf(protocolo)}}/></td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span style={{ color: "black" }}>
          {currentPage} ... {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(props.protocolos.length / itemsPerPage)
          }
        >
          Próximo
        </button>
      </div>
    </div>
  );
}

export default PrTable;
