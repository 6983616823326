import { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../../contexts/UserProvider';
import { PesqPrContext } from '../../../contexts/ChatBotProvider/PesqPrProvider';
import ProtCard from './ProtCard';
import { MdOutlineViewModule } from 'react-icons/md';
import { MdOutlineViewDay } from 'react-icons/md';
// import PrPDF from './PrPDF';
import PrAtt from './PrAtt';
import PrTable from './PrTable';
import "./protocolos.css";
const Protocolos = (props) => {
    const context = useContext(UserContext);
    const pesqPrContext = useContext(PesqPrContext);
    const [protocolos, setProtocolos] = useState([...pesqPrContext.protocolos]);
    const [prOnShow, setPrOnShow] = useState([...pesqPrContext.protocolos]);
    const [isInline, setIsInline] = useState(false);
    const [pdfOnShow, setPdfOnShow] = useState(false);
    const [prToDownload, setPrToDownload] = useState(null);
    const [orderedBy, setOrderedBy] = useState("sortByCreationDate");

    const search = (e) => {
        let value = e.target.value;
        if (value.length > 0) {
            let filtered = protocolos?.filter(proto => {
                let valuesToFind = []

                let found = false;

                //nome do atendente
                proto.atts?.forEach(att => {
                    valuesToFind.push(att.nome)
                })
                valuesToFind.push(proto.numero)

                //numero do contato
                let contNum = proto.contato?.telefone
                if (contNum) {
                    valuesToFind.push(contNum)
                }
                valuesToFind.forEach(valueToFind => {
                    if (valueToFind && valueToFind.toLowerCase().includes(value.toLowerCase())) {
                        found = true;
                    }
                })
                return found;
            })
            filtered = sortByCreationDate(filtered)
            setPrOnShow(filtered)
        } else {
            setPrOnShow(protocolos);
        }
    }
    function sortByCreationDate(array) {
        setOrderedBy("sortByCreationDate")
        return array.sort((a, b) => {
            let entradaA = a.entrada ? a.entrada.date : null;
            let entradaB = b.entrada ? b.entrada.date : null;
            if (entradaA && entradaB) {
                return new Date(entradaB) - new Date(entradaA)
            }
            else if (entradaA) {
                return -1
            }
            else if (entradaB) {
                return 1
            }
        })
    }

    function sortByCreationDateReverse(array) {
        let reverseArray = sortByCreationDate(array).reverse();
        setOrderedBy("sortByCreationDateReverse")
        return reverseArray;
    }

    function sortBySaidaDate(array) {
        setOrderedBy("sortBySaidaDate")
        return array.sort((a, b) => {
            let saidaA = a.saida ? a.saida.date : null;
            let saidaB = b.saida ? b.saida.date : null;
            if (saidaA && saidaB) {
                return new Date(saidaB) - new Date(saidaA)
            }
            else if (saidaA) {
                return -1
            }
            else if (saidaB) {
                return 1
            }
        })
    }

    function sortBySaidaDateReverse(array) {
        let reverseArray = sortBySaidaDate(array).reverse();
        setOrderedBy("sortBySaidaDateReverse")
        return reverseArray;
    }

    function showPdf(pr) {
        setPrToDownload(pr)
        setPdfOnShow(true)
    }

    function setAttOnPr(pr) {
        let atts = [];
        pr.more?.participantes?.map(part => {

            let atendente = context.pref.users.find(user => user._id === part) ? { ...context.pref.users.find(user => user._id === part) } : null;
            if (atendente) {
                atts.push(atendente)
            }
            else {
                let bot = context.pref.services.voxbot.bots.find(b => b._id === part) ? { ...context.pref.services.voxbot.bots.find(bot => bot._id === part) } : null;
                if (bot) {
                    bot.isbot = true;
                    atts.push(bot)
                }
            }

        })
        pr.atts = atts;
        return pr;
    }

    useEffect(() => {
        // get protocol numero from local storage
        let prNum = localStorage.getItem("protocolo");
        if (prNum) {
            let pr = protocolos?.find(pr => pr.numero === prNum);
            if (pr) {
                setPrToDownload(pr)
                setPdfOnShow(true)
                localStorage.removeItem("protocolo")
            }
        }
    }, [])
    useEffect(() => {
        let ordenedPrs = sortByCreationDate([...pesqPrContext.protocolos])
        setPrOnShow(ordenedPrs)
    }, [])
    useEffect(() => {
        let ordenedPrs = sortByCreationDate([...pesqPrContext.protocolos])
        ordenedPrs = ordenedPrs?.map(pr => {
            return setAttOnPr(pr)
        })
        setPrOnShow(ordenedPrs)
        setProtocolos(ordenedPrs);

    }, [pesqPrContext.protocolos]);

    return (
        <div style={{ backgroundColor: "white" }} >
            <>
                <div className="prs-title" >
                    <div className="search-bar-div">
                        <input className='search-inp' type="text" placeholder="Pesquisar" onChange={search} />
                    </div>
                        {!isInline ?
                            <MdOutlineViewDay onClick={() => { setIsInline(true) }} className="show-icons" />
                            : <MdOutlineViewModule onClick={() => { setIsInline(false) }} className="show-icons" />}
               
                </div>


                {isInline ? <div className={"pr-panel"} >
                    {
                        prOnShow.map(protocolo => (
                            <ProtCard key={protocolo.id} protocolo={protocolo} isInline={isInline} showPdf={e => { showPdf(protocolo) }} />
                        ))
                    }
                </div>
                    :
                    <PrTable
                        protocolos={prOnShow}
                        showPdf={showPdf}
                        orderByCreationDate={() => { setPrOnShow(sortByCreationDate(prOnShow)) }}
                        orderByCreationDateReverse={() => { setPrOnShow(sortByCreationDateReverse(prOnShow)) }}
                        orderBySaidaDate={() => { setPrOnShow(sortBySaidaDate(prOnShow)) }}
                        orderBySaidaDateReverse={() => { setPrOnShow(sortBySaidaDateReverse(prOnShow)) }}
                        orderedBy={orderedBy}
                    />
                }
            </>
            {
                pdfOnShow && <PrAtt
                    user={context.user}
                    pref={context.pref}
                    pr={prToDownload}
                    onClose={() => { setPdfOnShow(false) }} />
            }
        </div>
    );
}

export default Protocolos;
