import React from "react";

export const Cancelar = (props) => {
  const {
    setView,
    view,
    salvarServicoConfig,
    mensagemCancelado,
    onChangeMensagem,
    handleCancelServicoConfig,
  } = props;
  return (
    <>
      <legend>Mensagem Cancelado</legend>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="mensagemMarcado">Mensagem Cancelado</label>
          <textarea
            className="servico-form-textarea"
            name="mensagemCancelado"
            id="mensagemCancelado"
            cols="100"
            rows="5"
            value={mensagemCancelado}
            onChange={onChangeMensagem}
          />
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="prev" onClick={() => setView(view - 1)}>
          Anterior
        </button>
        <button type="button" id="next" onClick={() => setView(view + 1)}>
          Próximo
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
};
