import React, { useState, useEffect, useContext } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiPlayListAddFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import "../../chat.css";
import { ddd } from "../utils/dddJson.js";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { UserContext } from "../../../../contexts/UserProvider";
import { FlowBotContext } from "../../../../contexts/ChatBotProvider/FlowBotProvider";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import ApiUsers from "../../../../ApiUsers";
import { BsSearch } from "react-icons/bs";

export default function NewAttendance(props) {
  let dddList = Object.keys(ddd).map((ddd) => ddd);

  const userContext = useContext(UserContext);
  const flowBotContext = useContext(FlowBotContext);
  const attendanceContext = useContext(AttendanceContext);

  const [newContact, setNewContact] = useState({
    ddd: "",
    telefone: "",
    bot: "",
  });
  const [newContactList, setNewContactList] = useState([]);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [bots, setBots] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactsOnShow, setContactsOnShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage, setContactsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => (alert.props.quit ? alert.props.quit() : quitAlert())}
      />
    ),
  };

  useEffect(() => {
    let newBots = flowBotContext.bots.filter((bot) => bot.status === "ativo");
    setBots(newBots);
  }, [flowBotContext.bots]);

  useEffect(() => {
    let newContacts = [...attendanceContext.attendances];
    setContacts(newContacts);
    setContactsOnShow(newContacts);
  }, [attendanceContext.attendances]);

  useEffect(() => {
    let newContacts = [...contacts];
    if (search.trim() !== "") {
      newContacts = newContacts.filter(
        (contact) =>
          contact.nome?.toLowerCase().includes(search.toLowerCase()) ||
          contact.telefone?.toLowerCase().includes(search.toLowerCase()) ||
          contact.pushname?.toLowerCase().includes(search.toLowerCase()) ||
          contact?.flags?.map((flag) => flag.toLowerCase()).includes(search.toLowerCase())
      );
    }
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const filteredAttendances = newContacts.slice(
      indexOfFirstContact,
      indexOfLastContact
    );
    setContactsOnShow(filteredAttendances);
    setTotalPages(Math.ceil(newContacts.length / itemsPerPage));
  }, [search, itemsPerPage, contactsPerPage, currentPage, contacts]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, setCurrentPage]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function addWhatsAttList(contactList) {
    let newContacts = [...contactList];
    for (let i = 0; i < newContacts.length; i++) {
      let contact = newContacts[i];
      if (contact.bot.trim() !== "") {
        let isAlreadyContact = contacts.find(
          (cont) => cont.telefone === contact.telefone
        );
        if (isAlreadyContact) {
          isAlreadyContact.bot = contact.bot;
          isAlreadyContact.attendance = userContext.user._id;
          callExistentNumber(isAlreadyContact);
        } else {
          if (validateNumber(contact.telefone)) {
            let finalNumber = `${"55" + contact.ddd + contact.telefone}`;
            let alreadyExists = attendanceContext.attendances.find(
              (att) => att.telefone === finalNumber
            );
            if (!alreadyExists) callNewNumber(finalNumber, contact.bot);
            else callExistentNumber(alreadyExists);
          } else {
            createAlert("normal", {
              title: "Desculpe!",
              placeholder: "Número inválido! Digite um número válido.",
            });
          }
        }
      } else {
        createAlert("normal", {
          title: "Desculpe!",
          placeholder:
            "Por favor, selecione o bot a ser usado para iniciar a conversa antes de prosseguir.",
        });
      }
    }
  }

  function addWhatsAtt(contact) {
    let bot = newContact.bot;
    if (bot.trim() !== "") {
      let isAlreadyContact = contacts.find(
        (contact) => contact.telefone === newContact.telefone
      );
      if (contact) {
        contact.bot = bot;
        contact.attendance = userContext.user._id;
        callExistentNumber(contact);
      } else if (isAlreadyContact) {
        isAlreadyContact.bot = bot;
        isAlreadyContact.attendance = userContext.user._id;
        callExistentNumber(isAlreadyContact);
      } else {
        if (validateNumber()) {
          let finalNumber = `${"55" + newContact.ddd + newContact.telefone}`;
          let alreadyExists = attendanceContext.attendances.find(
            (att) => att.telefone === finalNumber
          );
          if (!alreadyExists) callNewNumber(finalNumber, bot);
          else callExistentNumber(alreadyExists);
        } else {
          createAlert("normal", {
            title: "Desculpe!",
            placeholder: "Número inválido! Digite um número válido.",
          });
        }
      }
    } else {
      createAlert("normal", {
        title: "Desculpe!",
        placeholder:
          "Por favor, selecione o bot a ser usado para iniciar a conversa antes de prosseguir.",
      });
    }
  }

  function callExistentNumber(contact) {
    if (notInAttendance(contact)) {
      let novCont = JSON.parse(JSON.stringify(contact));
      delete novCont.allMessages;
      novCont.bot = newContact.bot;
      setLoading(true);
      ApiUsers.post("/atendimentos/pickup", { contact: novCont, novo: true })
        .then((res) => {
          if (res.data.erro) {
            createAlert("normal", {
              title: "Desculpe!",
              placeholder: res.data.erro,
              quit: () => {
                setLoading(false);
                quitAlert();
              },
            });
          } else {
            createAlert("normal", {
              title: "Sucesso!",
              placeholder: "Contato Adicionado",
              quit: () => {
                setLoading(false);
                props.close();
                props.selectContact(contact);
                quitAlert();
              },
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      let withWho = userContext.contacts.find(
        (cont) => cont._id.toString() === contact.atendente
      )?.nome;
      withWho = withWho ? withWho : "Alguem";
      createAlert("normal", {
        title: "Ops!",
        placeholder:
          "O contato selecionado já se encontra em um atendimento com " +
          withWho,
      });
    }
  }

  function notInAttendance(contact) {
    let alreadyIn = attendanceContext.attendances.find(
      (att) => att.telefone === contact.telefone && !att.atendente
    );
    return alreadyIn;
  }

  function callNewNumber(finalNumber, bot) {
    let newContact = {
      telefone: finalNumber,
      pushname: "",
      stage: "",
      flow: "",
      foto: "",
      bot,
      _id: "",
      nome: "",
      grupo: "",
      historico: [],
      waiting: false,
      atendente: userContext.user._id,
    };
    setLoading(true);
    ApiUsers.post("/atendimentos/new", { contact: newContact })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Contato Adicionado",
          quit: () => {
            setLoading(false);
            props.close();
            props.selectContact(newContact);
            quitAlert();
          },
        });
      })
      .catch((err) => console.log(err));
  }

  function validateNumber() {
    let allClear = true;
    newContact.telefone.split("").forEach((char) => {
      if (isNaN(char)) allClear = false;
    });
    if (
      (newContact.telefone.trim().length < 8 &&
        newContact.telefone.trim().length < 9) ||
      newContact.ddd === ""
    )
      allClear = false;
    return allClear;
  }

  //   function searching(e) {
  //     let val = e.target.value;
  //     setSearch(val);
  //   }

  const generateList = () => {
    const contacts = [newContact];

    // cria uma cópia do estado atual da lista de contatos
    const currentContacts = [...newContactList];

    for (let i = 0; i < contacts.length; i++) {
      const newContact = contacts[i];

      // verifica se já existe um contato com o mesmo DDD, telefone e bot
      const duplicateContact = currentContacts.find((contact) => {
        return (
          contact.ddd === newContact.ddd &&
          contact.telefone === newContact.telefone &&
          contact.bot === newContact.bot
        );
      });

      // se já existir um contato, exibe uma mensagem de erro e retorna da função
      if (duplicateContact) {
        return createAlert("normal", {
          title: "Desculpe!",
          placeholder: "Este contato já foi adicionado à lista.",
        });
      } else if (
        newContact.ddd === "" ||
        newContact.telefone === "" ||
        newContact.bot === ""
      ) {
        // se algum dos campos estiver vazio, exibe uma mensagem de erro e retorna da função
        return createAlert("normal", {
          title: "Desculpe!",
          placeholder:
            "Por favor, preencha todos os campos antes de prosseguir.",
        });
      } else {
        // adiciona o novo contato à lista de contatos
        currentContacts.push(newContact);
      }
    }

    // atualiza o estado da lista de contatos com a nova lista que inclui o novo contato
    setNewContactList(currentContacts);
  };

  const removeContactList = (index) => {
    const contacts = [...newContactList];
    contacts.splice(index, 1);
    setNewContactList(contacts);
  };

  function handleSearch() {
    setSearch(searchInput);
  }

  return (
    <>
      {alert.visible && alertType[alert.type]}
      <h3 className="modal-inside-content-title">Novo Atendimento</h3>
      <div className="new-att-form-comb">
        <div
          className="new-att-form-bot"
          style={{ width: "100%", gap: "10px" }}
        >
          <span className="required">
            <b>Bot Selecionado</b>{" "}
          </span>
          <select
            className="new-att-form-input"
            style={{
              width: "80%",
              marginLeft: "10px",
            }}
            onChange={(e) =>
              setNewContact({
                ddd: newContact.ddd,
                telefone: newContact.telefone,
                bot: e.target.value,
              })
            }
          >
            <option value=""> Selecione o bot </option>
            {bots.map((bot, i) => {
              return (
                <option key={i} value={bot._id}>
                  {bot.nome}{" "}
                </option>
              );
            })}
          </select>
        </div>

        <div className="new-att-form-contact">
          <span>
            <b>Número WhatsApp:</b> +55
          </span>
          <div className="nu">
            <select
              className="new-att-form-input-ddd"
              value={newContact.ddd}
              onChange={(e) =>
                setNewContact({
                  ddd: e.target.value,
                  telefone: newContact.telefone,
                  bot: newContact.bot,
                })
              }
            >
              <option value=""> DDD </option>
              {dddList.map((ddd, i) => {
                return (
                  <option key={i} value={ddd}>
                    {ddd}{" "}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              placeholder="xxxx xxxx"
              maxLength={9}
              className="new-att-form-input-phone"
              value={newContact.telefone}
              onChange={(e) =>
                setNewContact({
                  ddd: newContact.ddd,
                  telefone: e.target.value,
                  bot: newContact.bot,
                })
              }
              onKeyPress={(e) => {
                if (e.key === "Enter") generateList();
              }
              }
            />
            <div
              className="attendances-list-item-icon"
              title="Clique para adicionar o contato à lista !"
              onClick={generateList}
            >Adicionar
            </div>
          </div>
        </div>
        <div className="new-att-form-list">
          {newContactList.map((contact, i) => {
            return (
              <div key={i} className="new-att-form-contact">
                <span>
                  <b>DDD:</b> {contact.ddd} <b>Telefone:</b> {contact.telefone}
                </span>
                <TiDelete
                  className="delete-list"
                  onClick={() => removeContactList(i)}
                />
              </div>
            );
          })}
        </div>
        <div className="container-button-att ">
          <button
            onClick={loading ? null : () => addWhatsAttList(newContactList)}
          >
            Iniciar atendimento
          </button>
        </div>
      </div>

      <div className="attendances-list">
        <div className="new-att-form-search">
          <input
            placeholder="Buscar contato..."
            className="new-att-form-input-contact"
            type="search"
            name=""
            id=""
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <MdInfoOutline
            className="fa-Info"
            title="Após digitar o nome ou telefone do contato, selecione o BOT e clique no botão (+) do contato desejado para iniciar o atendimento."
            alt="Após digitar o nome ou telefone do contato,selecione o BOT e clique no botão (+) do contato desejado para iniciar o atendimento."
          />
          <div className="button-search-box" onClick={handleSearch}>
            <BsSearch size={18} />
          </div>
        </div>
        {/* Renderizar a lista de atendimentos com base no estado atual. */}

        <ul className="attendences-list">
          {contactsOnShow?.map((contact) => (
            <li key={contact._id} className="attendances-list-item">
              <div className="attendances-list-item-avatar-details">
                <div className="attendances-list-item-avatar">
                  <div className="attendances-list-item-buttons">
                    <AiOutlinePlusCircle
                      title="Selecione o bot e inicie o Atendimento"
                      onClick={loading ? null : () => addWhatsAtt(contact)}
                      className="attendances-list-item-icon"
                    />
                  </div>
                  <img
                    src={contact.foto ? contact.foto : "/imgs/avatar2.png"}
                    className="avatar-contact"
                    alt="avatar"
                    onError={(e) => {
                      e.target.src = "/imgs/avatar2.png";
                    }}
                  />
                </div>
                <div className="attendances-list-item-details">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <FaUserAlt style={{ margin: 5 }} />{" "}
                    {contact.nome
                      ? contact.nome
                      : contact.pushname
                        ? contact.pushname
                        : contact.telefone.replace("@c.us", " ").trim()}{" "}
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <AiFillPhone style={{ margin: 5 }} />{" "}
                    {contact.telefone.replace("@c.us", " ").trim()}{" "}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <div className="pagination-att">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span style={{ color: "black" }}>
            {currentPage} ... {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(totalPages) || totalPages === 0}
          >
            Próximo
          </button>
        </div>
      </div>
    </>
  );
}
