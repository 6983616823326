import { useContext, useEffect, useState } from "react";
import { QSGAgendaContext } from "../../../../../contexts/QSGProvider/QSGAgendaProvider";
import { Selecao } from "../Selecao";
import { Tabela } from "./Tabela";
import { BotoesTroca } from "./BotoesTroca";
import "./styles.css";

export const Profissionais = ({ setIsStepCompleted }) => {
  const { usuarios, profissionais } = useContext(QSGAgendaContext);
  const [users, setUsers] = useState([]);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [profis, setProfis] = useState([]);
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState([]);
  const [profissionaisFiltrados, setProfissionaisFiltrados] = useState([]);
  const tableHeader = ["input", "nome", "email", "departamento", "setor"];

  const filterProfissionais = (data, searchTerm) => {
    const novosProfis = data ? data : [];
    return novosProfis.filter((item) => item.nome.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const filterUsers = (data, searchTerm) => {
    const novosUsers = data ? data : [];
    return novosUsers.filter((item) => item.nome.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  useEffect(() => {
    const novosUsuarios = usuarios ? usuarios : [];
    if (novosUsuarios) {
      const removerProfissionais = novosUsuarios.filter((usuario) => !usuario.profissional);
      setUsers(removerProfissionais);
    }
  }, [usuarios]);

  useEffect(() => {
    const novosProfis = profissionais ? profissionais : [];
    setProfis(novosProfis);
  }, [profissionais]);

  useEffect(() => {
    if (profissionais.length > 0) {
      setIsStepCompleted(true);
    } else {
      setIsStepCompleted(false);
    }
  }, [profissionais.length, setIsStepCompleted]);

  return (
    <div className="qsg-agenda-profissionais-container">
      <Selecao
        headerLabel="Usuários"
        content={users}
        selecteds={usuariosSelecionados}
        setFiltrados={setUsuariosFiltrados}
        filter={filterUsers}>
        <Tabela
          tableHeader={tableHeader}
          tableBody={usuariosFiltrados}
          selecteds={usuariosSelecionados}
          setSelected={setUsuariosSelecionados}
          content={users}
        />
      </Selecao>
      <BotoesTroca
        usuariosSelecionados={usuariosSelecionados}
        setUsuariosSelecionados={setUsuariosSelecionados}
        profissionaisSelecionados={profissionaisSelecionados}
        setProfissionaisSelecionados={setProfissionaisSelecionados}
      />
      <Selecao
        headerLabel="Profissionais"
        content={profis}
        selecteds={profissionaisSelecionados}
        filter={filterProfissionais}
        setFiltrados={setProfissionaisFiltrados}>
        <Tabela
          tableHeader={tableHeader}
          tableBody={profissionaisFiltrados}
          selecteds={profissionaisSelecionados}
          setSelected={setProfissionaisSelecionados}
          content={profis}
        />
      </Selecao>
    </div>
  );
};
