import React from "react";
import "../../Group.css";

export default function CheckboxUsersSearch(props) {
  const { departamentos, filters, setores } = props;
  console.log(setores);

  return (
    <>
      <input
        className="admin-checkbox-input"
        type="text"
        placeholder="Pesquisar usuário pelo nome"
        onChange={(e) => props.addFilter("nome", e.target.value)}
      />

      <div className="admin-checkbox-header-search-filter">
        <select
          className="admin-checkbox-input-filter"
          onChange={(e) => props.addFilter("departamento", e.target.value)}
        >
          <option value="">Selecionar Departamento</option>
          {departamentos &&
            departamentos.map((departamento, index) => {
              return (
                <option key={index} value={`${departamento.nome}`}>
                  {" "}
                  {departamento.nome}{" "}
                </option>
              );
            })}
        </select>

        <select
          className="admin-checkbox-input-filter"
          onChange={(e) => props.addFilter("setor", e.target.value)}
        >
          <option value="">Selecionar Setor</option>
          {setores &&
            setores.map((setor, index) => {
              return (
                <option key={index} value={`${setor.nome}`}>
                  {" "}
                  {setor.nome}{" "}
                </option>
              );
            })}

          {/* {filters.departamento &&
            setores.map((setor, index) => {
              if (setor.departamento === props.filters.departamento) {
                return (
                  <option key={index} value={`${setor.nome}`}>
                    {" "}
                    {setor.nome}{" "}
                  </option>
                );
              }
            })} */}
        </select>
      </div>
    </>
  );
}
