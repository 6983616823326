import React, { useEffect, useState, useContext } from "react";
import { GroupContext } from "../../../contexts/ChatBotProvider/GroupProvider";
import { ContactContext } from "../../../contexts/ChatBotProvider/ContactProvider";
import { BsSearch, BsInfoCircle } from "react-icons/bs";

function Groups(props) {
  const [groups, setGroups] = useState([]);
  const [onShow, setOnShow] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const context = useContext(GroupContext);
  const contactContext = useContext(ContactContext);

  function countAndSlice(message) {
    if (!message) return "";
    let count = message.length;
    if (count > 20) {
      message = message.slice(0, 20);
      message = message + "...";
    }
    return message;
  }

  const searchGroups = (value) => {
    if (value === "") {
      setOnShow([...groups]);
    } else {
      let newGroups = groups.filter((group) =>
        group.nome.toLowerCase().includes(value.toLowerCase())
      );
      setOnShow(newGroups);
    }
  };

  useEffect(() => {
    searchGroups(searchInput);
  }, [searchInput, groups]);

  useEffect(() => {
    setGroups([...context.groups]);
    setOnShow([...context.groups]);
    context.setSelected(props.selected);

    return () => {
      context.setSelected(null);
    };
  }, [context.groups, props]);

  function selectGroup(e, group) {
    props.onSelect(e, group);
    groups.forEach((grupx) => {
      if (grupx._id === group._id) {
        let messagesRead = group.unseen;
        props.socket.emit("read cont messages", {
          contact: group._id,
          messages: messagesRead,
          type: "group",
        });
        context.unseenByGroup(group._id);
        group.unseen = [];
      }
      return group;
    });
    if (
      window.screen.width <= 900 ||
      document.documentElement.clientWidth <= 900
    )
      contactContext.toogleLeftCol();
  }

  return (
    <div className="talk-list">
      <div className="container-search">
        <input
          type="search"
          placeholder="Buscar Grupo..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div className="button-search-box">
          <BsSearch size={20} />
        </div>
      </div>

      {onShow.length === 0 && searchInput !== "" && (
        <div className="container-information-nothingfound">
          <BsInfoCircle size={50} color="#9ac31c" />
          <p>
            <strong>Nenhum grupo encontrado !</strong>
          </p>
          <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
            Tente novamente com outro nome.
          </p>
        </div>
      )}

      {onShow.map((group, i) => {
        let background = "inherit";
        if (group._id === props.selected._id) {
          background = "#F0FFF0";
        }

        return (
          <div
            key={i + 2 * 969403}
            title={`${group.nome} - Departamento ${group.departamento}`}
          >
            <div
              className="contact-wrapper"
              style={{ backgroundColor: background }}
              key={i}
              onClick={(e) => selectGroup(e, group)}
            >
              <img
                className="contact-avatar"
                src="/imgs/groupavatar.png"
                alt=""
              />

              <div
                className="contact-data"
                style={{ backgroundColor: background, border: "none" }}
                key={i}
              >
                <div className="contact-row">
                  <b>
                    {" "}
                    {group.nome}{" "}
                    {group.departamento ? " - " + group.departamento : ""}{" "}
                  </b>
                  {group.unseen && group.unseen.length > 0 ? (
                    <div className="not-seen">
                      <b style={{ fontSize: "11px" }}>
                        {group.unseen?.length > 0 ? group.unseen.length : ""}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="contact-row-p">
                  <p>
                    {group.lastMessage
                      ? countAndSlice(group.lastMessage.message)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Groups;
