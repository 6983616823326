import React from "react";

export default function ChechboxSearch(props) {
  const { departamentos, setores } = props;
  return (
    <>
      <div className="checkbox-inputs-container">
        <h4 style={{ textAlign: "center" }}> Selecionar Usuários: </h4>
        <input
          type="text"
          className="attendance-input"
          placeholder="Digite aqui o nome do usuário"
          onChange={(e) => props.filter("nome", e.target.value)}
        />
        <div className="checkbox-selects-container">
          <select
            className="checkbox-select"
            onChange={(e) => props.filter("departamento", e.target.value)}
          >
            <option value="">Selecione o Departamento</option>
            {departamentos &&
              departamentos.map((departamento, index) => {
                return (
                  <option key={index} value={`${departamento.nome}`}>
                    {" "}
                    {departamento.nome}{" "}
                  </option>
                );
              })}
          </select>
          <select
            className="checkbox-select"
            style={{ marginLeft: 5 }}
            onChange={(e) => props.filter("setor", e.target.value)}
          >
            <option value="">Selecione o Setor</option>
            {setores &&
              setores.map((setor, index) => {
                return (
                  <option key={index} value={`${setor.nome}`}>
                    {" "}
                    {setor.nome}{" "}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </>
  );
}
