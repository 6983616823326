import React, { useContext } from "react";
import { BsX } from "react-icons/bs";
import ImageGroup from "./ImageGroup";
import Inputs from "./Inputs";
import ButtonMembros from "./ButtonMembros";
import ButtonAtendentes from "./ButtonAtendentes/buttonAtendentes";
import ButtonsFooter from "../ButtonsFooter";
import { FlowBotContext } from "../../../../contexts/ChatBotProvider/FlowBotProvider";
import "./modalGroup.css";

export default function ModalGroup({ TitleModal, onClose, form, onChangeForm, toggleModalTable, onClick }) {
  const { bots } = useContext(FlowBotContext);
  const isDisabledButton = !form.nome || !form.bot || !form.membros.length;

  return (
    <div className="overlay">
      <div className="modal-content-group">
        <div className="container-header-modal">
          <div className="container-align-text-modal">
            <h3>{TitleModal}</h3>
            <button className="fechar" onClick={onClose}>
              <BsX size={40} color="#9AC31C" />
            </button>
          </div>
          <ImageGroup form={form} onChangeForm={onChangeForm} />
          <Inputs form={form} onChangeForm={onChangeForm} bots={bots} />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ButtonMembros onClick={() => toggleModalTable('members')} membersCount={form?.membros.length} />
            <ButtonAtendentes onClick={() => toggleModalTable('atendentes')} attandancesCount={form?.atendentes?.length} />
          </div>
          <ButtonsFooter onClick={onClick} onClose={onClose} disabled={isDisabledButton} />
        </div>
      </div>
    </div>
  );
}
