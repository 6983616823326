import React, { useState, useEffect, useContext } from "react";
import Checkbox from "./AttendanceCheckBox/Checkbox";
import CheckboxList from "./AttendanceCheckBox/CheckboxList";
import CheckboxSearch from "./AttendanceCheckBox/CheckboxSearch";
import ApiUsers from "../../../../ApiUsers";
import Alert from "../inputs/Alerts/Alert";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

export default function AttendanceCreate() {
  const prefContext = useContext(PrefContext);
  const [group, setGroup] = useState({ nome: "", usuarios: [] });
  const [users, setUsers] = useState([]);
  const [usersOnShow, setUsersOnShow] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [unselectedUsers, setUnselectedUsers] = useState([]);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [filters, setFilters] = useState({
    nome: "",
    departamento: "",
    setor: "",
  });
  const [loading, setLoading] = useState(false);

  const alerts = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    let users = prefContext.users.filter(
      (u) => u.atendente === true && u.nome !== "Voxcity"
    );
    setUsers(users);
    setUsersOnShow(users);
  }, [prefContext.atendentes]);

  useEffect(() => {
    searchUsers();
  }, [filters, users]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function changeName(e) {
    let newGroup = { ...group };
    newGroup.nome = e.target.value;
    setGroup(newGroup);
  }

  function selectUser(checked, user) {
    let newUsers = [...selectedUsers];
    if (checked) {
      newUsers = [...newUsers, user];
    } else {
      newUsers = newUsers.filter((u) => u.nome !== user.nome);
    }
    setSelectedUsers(newUsers);
  }

  function unselectUser(checked, user) {
    let newUsers = [...unselectedUsers];
    if (checked) {
      newUsers = [...newUsers, user];
    } else {
      newUsers = newUsers.filter((u) => u.nome !== user.nome);
    }
    setUnselectedUsers(newUsers);
  }

  function selectAll(checked) {
    if (checked) {
      let newUsers = [...usersOnShow];
      setSelectedUsers(newUsers);
    } else {
      setSelectedUsers([]);
    }
  }

  function unselectAll(checked) {
    if (checked) {
      let newUsers = [...group.usuarios];
      setUnselectedUsers(newUsers);
    } else {
      setUnselectedUsers([]);
    }
  }

  function addUsers() {
    let newGroup = { ...group };
    if (selectedUsers.length > 0) {
      newGroup.usuarios = [...newGroup.usuarios, ...selectedUsers];
      let newList = users.filter((u) => {
        if (!selectedUsers.some((i) => i.nome === u.nome)) {
          return u;
        }
      });
      setGroup(newGroup);
      setUsers(newList);
      setUsersOnShow(newList);
      setSelectedUsers([]);
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, selecione ao menos um usuário!",
      });
    }
  }

  function delUsers() {
    let newGroup = { ...group };
    if (unselectedUsers.length > 0) {
      newGroup.usuarios = newGroup.usuarios.filter((u) => {
        if (!unselectedUsers.some((p) => u.nome === p.nome)) return u;
      });
      let newList = [...users, ...unselectedUsers];
      setUsers(newList);
      setUsersOnShow(newList);
      setGroup(newGroup);
      setUnselectedUsers([]);
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, selecione ao menos um usuário!",
      });
    }
  }

  function saveGroup() {
    if (group.nome.trim() !== "") {
      let newGroup = { ...group };
      let usersIds = group.usuarios.map((u) => u._id);
      newGroup.usuarios = usersIds;
      ApiUsers.post("/atendimentos/grupos", { grupo: newGroup })
        .then((res) => {
          setGroup({ nome: "", usuarios: [] });
          setSelectedUsers([]);
          setUnselectedUsers([]);
          createAlert("normal", {
            title: "Sucesso!",
            placeholder: "Grupo de atendimento criado com sucesso!",
          });
        })
        .catch((err) => console.log(err));
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha os campos necessários!",
      });
    }
  }

  function searchUsers() {
    let userList = [...users];
    let novoUsers = userList;
    const { nome, departamento, setor } = filters;

    if (nome.trim() !== "" && !departamento && !setor) {
      novoUsers = userList.filter((user) => {
        if (user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
        return false;
      });
    }
    if (nome.trim() === "" && departamento) {
      novoUsers = userList.filter((user) => {
        if (user.departamentoNome === departamento) return user;
        return false;
      });
    }
    if (nome.trim() === "" && departamento && setor) {
      novoUsers = userList.filter((user) => {
        if (user.departamentoNome === departamento && user.setorNome === setor)
          return user;
        return false;
      });
    }
    if (nome.trim() !== "" && departamento) {
      novoUsers = userList.filter((user) => {
        if (
          user.departamentoNome === departamento &&
          user.nome.toLowerCase().includes(nome.toLowerCase())
        )
          return user;
        return false;
      });
    }
    if (nome.trim() !== "" && departamento && setor) {
      novoUsers = userList.filter((user) => {
        if (
          user.departamentoNome === departamento &&
          user.setorNome === setor &&
          user.nome.toLowerCase().includes(nome.toLowerCase())
        )
          return user;
        return false;
      });
    }
    setUsersOnShow(novoUsers);
  }

  function addFilter(step, filter) {
    let newFilter = { ...filters };
    if (step === "departamento") {
      newFilter.departamento = filter;
      newFilter.setor = "";
    } else if (step === "setor") {
      newFilter.setor = filter;
    } else {
      newFilter.nome = filter;
    }
    setFilters(newFilter);
    searchUsers();
  }

  return (
    <>
      <div className="attendance-form-container">
        {alert.visible && alerts[alert.type]}

        <h2 style={{ textAlign: "center", margin: "20px 0px" }}>
          {" "}
          Criação de Grupo de Atendimento{" "}
        </h2>

        <div className="attendance-input-container">
          <span className="attendance-span"> Nome do Grupo: </span>
          <input
            type="text"
            className="attendance-input"
            placeholder="Digite aqui o nome do grupo"
            value={group.nome}
            onChange={(e) => changeName(e)}
          />
        </div>

        <Checkbox>
          <CheckboxSearch
            filter={(step, filter) => addFilter(step, filter)}
            departamentos={prefContext.departamentos}
            setores={prefContext.setores}
          />
          <div className="checkbox-list-container">
            <CheckboxList
              loading={loading}
              itsUsers={false}
              users={usersOnShow}
              selectedUsers={selectedUsers}
              selectUser={(checked, user) => selectUser(checked, user)}
              selectAll={(checked) => selectAll(checked)}
              save={() => addUsers()}
            />
            <CheckboxList
              loading={loading}
              itsUsers={true}
              users={group.usuarios}
              selectedUsers={unselectedUsers}
              selectUser={(checked, user) => unselectUser(checked, user)}
              selectAll={(checked) => unselectAll(checked)}
              save={() => delUsers()}
            />
          </div>
        </Checkbox>

        <div className="attendance-buttons-container">
          <button
            className="attendance-buttons"
            style={{ backgroundColor: "#127e4c" }}
            onClick={() => saveGroup()}
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  );
}
