import React, { useState } from "react";
import ButtonsFormAgendas from "./components/ButtonsFormAgendas";
import HeaderTitle from "../../CreateForm/HeaderTitle";
import CardAgenda from "./components/CardAgenda";
import "./botAgendas.css";

function Agenda(props) {
  const [bot, setBot] = useState(props.bot);

  function changeAlternative(e) {
    let name = e.target.name;
    let newBot = { ...bot };
    if (!newBot.config.agenda) {
      newBot.config.agenda = { [name]: e.target.value };
    } else {
      newBot.config.agenda[name] = e.target.value;
    }
    setBot(newBot);
  }

  return (
    <>
      <div className="bot-form-agenda">
        <div className="cont-align-text-buttons">
          <HeaderTitle title="Bot Agenda" hideIcon={true} />
          <ButtonsFormAgendas props={props} />
        </div>
        <div className="comando-wrapper-agenda">
          <div className="comando-card-agenda">
            <CardAgenda
              agendaLogin={bot.config.agenda ? bot.config.agenda.login : ""}
              agendaReset={bot.config.agenda ? bot.config.agenda.reset : false}
              onLoginChange={(e) => changeAlternative(e)}
              onResetChange={(e) => changeAlternative(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Agenda;
