export const modalInfoContent = {
  local: {
    keys: [
      {
        label: "Endereço",
        key: "endereco",
      },
      {
        label: "Telefone",
        key: "fone",
      },
      {
        label: "Exibir horário a partir",
        key: "timeToStart",
      },
    ],
  },
  especialidade: {
    keys: [
      {
        label: "Limite de vagas",
        key: "limiteVagas",
      },
      {
        label: "Horário de Funcionamento",
        key: "horarioFuncionamento",
      },
    ],
  },
  profissional: {
    keys: [
      {
        label: "Espaço Agendamento",
        key: "espacoAgendamento",
      },
      {
        label: "Período de Atendimento",
        key: "periodoAtendimento",
      },
      {
        label: "Horário de Intervalo",
        key: "horarioIntervalo",
      },
    ],
  },
};
