import React, { useState, useEffect, useContext } from "react";
import BotArea from "../BotArea/BotArea";
import Modal from "../Modal/Modal";
import CreateForm from "../CreateForm/CreateForm";
import BotForm from "../BotForm/BotForm";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import ApiUsers from "../../../../ApiUsers";
import { FlowBotContext } from "../../../../contexts/ChatBotProvider/FlowBotProvider";
import ButtonNewBot from "../components/ButtonNewBot/ButtonNewBot";
import "./botCreation.css";

export default function BotCreation() {
  const [bots, setBots] = useState([]);
  const [flows, setFlows] = useState([]);
  const [modal, setModal] = useState({ visible: false, type: "", action: "" });
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [selectedBot, setSelectedBot] = useState({});
  const [loading, setLoading] = useState(false);
  const botContext = useContext(FlowBotContext);

  useEffect(() => setFlows(botContext.flows), [botContext.flows]);
  useEffect(() => setBots(botContext.bots), [botContext.bots]);

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        loading={loading}
        quit={() => quitAlert()}
      />
    ),
  };

  function hasEmptyField(bot) {
    let empty = 0;
    Object.entries(bot).forEach(([k, v]) => {
      if (typeof v === "string" && v.trim() === "") empty++;
      if (k === "config") {
        Object.entries(bot[k]).forEach(([key, value]) => {
          if (typeof value === "string" && value.trim() === "") empty++;
          if (key === "horario" && (value.inicio === "" || value.fim === ""))
            empty++;
        });
      }
    });
    return empty;
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function showModal(type, action) {
    setModal({ visible: true, type, action });
  }

  function deleteModal(e, bot) {
    e.stopPropagation();
    createAlert("delete", {
      data: "bot",
      nome: bot.nome,
      action: () => deleteBot(bot),
    });
  }

  function createBot(bot) {
    let list = [...bots];
    if (list.some((b) => b.nome === bot.nome)) {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Nome já existente, por favor escolha outro.",
      });
    } else {
      setLoading(true);
      ApiUsers.post("/whats/createBot", { bot })
        .then((res) => {
          setModal({ visible: false, type: "", action: "" });
          setLoading(false);
          createAlert("normal", {
            title: "Sucesso!",
            placeholder:
              "Bot criado com sucesso! Edite as configurações antes de ativá-lo.",
          });
        })
        .catch((err) => console.log(err));
    }
  }

  function deleteBot(bot) {
    setLoading(true);
    ApiUsers.post("/whats/deleteBot", { bot })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "O bot foi deletado com sucesso!",
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  async function changeBotStatus(bot) {
    if (bot.status === "inativo") {
      if (hasEmptyField(bot) <= 1) {
        setLoading(true);
        await ApiUsers.post("/whats/startBot", { bot })
          .then(async (res) => {
            createAlert("normal", {
              title: "Sucesso!",
              placeholder:
                "Status alterado com sucesso! Escanneie o QR Code em seu celular para ativar o bot!",
            });
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        createAlert("normal", {
          title: "Atenção!",
          placeholder:
            "Bot com configurações inválidas, por favor edite as configurações necessárias para ativá-lo.",
        });
      }
    } else {
      setLoading(true);
      ApiUsers.post("/whats/stopBot", { bot })
        .then((res) => {
          setLoading(false);
          createAlert("normal", {
            title: "Sucesso!",
            placeholder: "O funcionamento do bot foi interrompido com sucesso!",
          });
        })
        .catch((err) => console.log(err));
    }
  }

  function saveBot(bot) {
    setLoading(true);
    ApiUsers.put("/whats/saveBot", { bot })
      .then((res) => {
        setModal({ visible: false, type: "", action: "" });
        setLoading(false);
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "As configurações foram alteradas com sucesso!",
        });
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {alert.visible && alertType[alert.type]}
      {modal.visible && (
        <>
          <Modal
            closeModal={() =>
              setModal({ visible: false, type: "", action: "" })
            }
          >
            {modal.type === "bot" && modal.action === "create" ? (
              <>
                <CreateForm
                  createBot={(bot) => createBot(bot)}
                  loading={loading}
                  alert={(type, props) => createAlert(type, props)}
                  flows={flows}
                  closeModal={() =>
                    setModal({ visible: false, type: "", action: "" })
                  }
                />
              </>
            ) : (
              <>
                <BotForm
                  bot={selectedBot}
                  flows={flows}
                  bots={bots}
                  loading={loading}
                  saveBot={(bot) => saveBot(bot)}
                  alert={(type, props) => createAlert(type, props)}
                  hasEmptyField={(bot) => hasEmptyField(bot)}
                  closeModal={() =>
                    setModal({ visible: false, type: "", action: "" })
                  }
                />
              </>
            )}
          </Modal>
        </>
      )}

      <div className="bot-container">
        <ButtonNewBot loading={loading} showModal={showModal} />
        <BotArea
          bots={bots}
          flows={flows}
          loading={loading}
          deleteModal={(e, bot) => deleteModal(e, bot)}
          changeBotStatus={(bot) => changeBotStatus(bot)}
          selectBot={(bot) => setSelectedBot(bot)}
          showModal={() => showModal("bot", "edit")}
        />
      </div>
    </>
  );
}
