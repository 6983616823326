import React from "react";

const SearchInput = ({ value, onChange, placeholder, className }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="search-input-contato"
    />
  );
};

export default SearchInput;
