import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatToBrDate, withZero } from "../../utils/index";

export default function detalhes(title, data, mensagem, pref, user) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  let detalhes = [];
  let totalVotes = 0;
  let todayDate = new Date();
  let today = `${withZero(todayDate.getDate())}/${withZero(
    todayDate.getMonth() + 1
  )}/${withZero(todayDate.getFullYear())}`;

  data.forEach((row) => {
    row.votos.forEach((voto) => {
      detalhes.push({ ...voto });
    });
    totalVotes += parseInt(row.votos.length);
  });
  detalhes = detalhes.filter((row) => {
    if (
      !row.date ||
      row.participantes.join(", ") == "" ||
      !row.protocolo ||
      !row.valor
    ) {
      return false;
    }
    return true;
  });

  detalhes = detalhes.filter((row) => row !== undefined);

  const table = {
    headerRows: 1,
    widths: ["auto", "*", "*", "auto"],
    body: [
      [
        {
          text: "Data",
          style: "tableHeader",
        },
        {
          text: "Participantes",
          style: "tableHeader",
        },
        {
          text: "Protocolo",
          style: "tableHeader",
        },
        {
          text: "Nota",
          style: "tableHeader",
        },
      ],

      ...detalhes.map((row) => {
        let participantes = row.participantes.filter((participante) => {
          return participante !== undefined;
        });

        let part = participantes.join(", ");
        if (!part) part = "Sem participantes";
        if (!row.protocolo) row.protocolo = "Sem protocolo";
        if (!row.valor) row.valor = "Sem nota";
        if (!row.date) row.date = "Sem data";
        else {
          let newDate = row.date.split("T");
          row.date = formatToBrDate(newDate[0]);
        }
        return [
          { text: row.date, style: "tableExample" },
          { text: part, style: "tableExample" },
          { text: row.protocolo, style: "tableExample" },
          { text: row.valor, style: "tableExample" },
        ];
      }),
    ],
  };

  const docDefinition = {
    content: [
      {
        text: [
          {
            text: "Organização: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: pref.nome,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          {
            text: "Data do Relatorio: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: today,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          {
            text: "Pesquisa: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: title,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },

      {
        text: [
          {
            text: "Total de votos: ",
            style: "header",
            alignment: "justify",
          },

          {
            text: totalVotes,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },

      {
        text: "Mensagem da Pesquisa",
        style: "header",
        margin: [0, 0, 0, 20],
        alignment: "center",
        decoration: "underline",
      },
      {
        text: mensagem,
        style: "headerMessage",
        margin: [0, 0, 0, 20],
        alignment: "center",
      },
      {
        text: "Detalhes da Pesquisa",
        style: "header",
        margin: [0, 0, 0, 20],
        alignment: "center",
        decoration: "underline",
      },
      {
        style: "tableExample",
        table,
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      headerMessage: {
        fontSize: 16,
      },
      tableExample: {
        margin: [0, 0, 0, 0],
        fontSize: 13,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
        color: "black",
      },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}
