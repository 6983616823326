import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { BorderBottom } from "@mui/icons-material";

const LinkGroup = ({
  label,
  links,
  icon,
  titulo,
  sideBarOpen,
  activeItem,
  onItemClick,
}) => {
  const [expandedId, setExpandedId] = useState(null);
  const isExpanded = expandedId === links.id;
  const isActive = activeItem === label;

  useEffect(() => {
    if (!isActive) {
      setExpandedId(null);
    }
  }, [isActive]);

  const handleClick = () => {
    onItemClick(label);
  };

  const handleGroupClick = () => {
    setExpandedId(isExpanded ? null : links.id);
  };

  return (
    <div onClick={handleClick} className={isActive ? "link-ativo-group" : null}>
      <div className="link-wrapper">
        <li
          className={`${isActive ? "link-ativo link-before-wrapper" : null} ${
            isExpanded ? "expanded" : ""
          }`}
          onClick={handleGroupClick}
          title={titulo}
        >
          <div className="link-group">
            {icon}
            {sideBarOpen && label}
          </div>
          {sideBarOpen ? (
            isExpanded ? (
              <BsChevronDown className="iconMg" />
            ) : (
              <BsChevronRight className="iconMg" />
            )
          ) : null}
        </li>

        {isExpanded && (
          <ul>
            {links.map((link) => {
              let ativo = window.location.pathname === link.link;
              const styleIcon = ativo
                ? {
                    borderRight: "3px solid  #142a4c",
                  }
                : null;

              return (
                <li
                  key={link.id}
                  title={link.titulo}
                  className={ativo ? "link-ativo-group" : null}
                >
                  <Link to={link.link} className={ativo ? "active-link" : null}>
                    {!sideBarOpen &&
                      React.cloneElement(link.icon, {
                        style: styleIcon,
                      })}
                    {sideBarOpen && link.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LinkGroup;
