import { useEffect, useState } from 'react';
import PrAtt from '../../../../WhatsAppTool/Protocolos/PrAtt';
import ApiUsers from '../../../../../ApiUsers';
import Alert from '../../../../Configurações/OptionsScreen/inputs/Alerts/Alert';
import DeleteAlert from '../../../../Configurações/OptionsScreen/inputs/DeleteAlert';
import { BsCheck2All } from "react-icons/bs";

const UserAttsList = (props) => {
    const { attendancesUser } = props;
    const [search, setSearch] = useState('');
    const [filteredAtts, setFilteredAtts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAttendance, setSelectedAttendance] = useState(null);
    const [itemsPerPage] = useState(5);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

    function createAlert(type, props) {
        setAlert({ visible: true, type, props });
    }

    const quitAlert = () => {
        setAlert({ visible: false, type: "", props: {} });
    }

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
        delete: <DeleteAlert nome={alert.props.nome} action={alert.props.action} data={alert.props.data} quit={() => quitAlert()} />
    }

    useEffect(() => {
        if (attendancesUser) {
            setFilteredAtts([...attendancesUser]);
        }
    }, [attendancesUser]);

    useEffect(() => {
        let atts = attendancesUser.filter((att) => {
            if (
                (att?.nome?.toLowerCase().includes(search.toLowerCase()) ||
                    att?.telefone?.toLowerCase().includes(search.toLowerCase()) ||
                    att?.pushname?.toLowerCase().includes(search.toLowerCase()))
            ) {
                return att;
            }
        });
        setFilteredAtts([...atts]);
    }, [search, attendancesUser]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredAtts.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredAtts.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const generatePageNumbers = () => {
        const maxPagesToShow = 5;
        const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        if (startPage > 1) pageNumbers.unshift('...');
        if (endPage < totalPages) pageNumbers.push('...');

        return pageNumbers;
    };

    const handleFinish = (att) => {
        createAlert('delete', { data: 'Finalizar', nome: 'atendimento', action: () => finishAttendance(att) });
    }

    const finishAttendance = (att) => {
        let newContact = JSON.parse(JSON.stringify(att));
        delete newContact.historico;
        delete newContact.allMessages;
        quitAlert();
        ApiUsers.post("/atendimentos/finish", { contact: newContact }).then(
            (res) => {
                setFilteredAtts(filteredAtts.filter((a) => a.telefone !== att.telefone));
                if (res.data.msg) {
                    createAlert("normal", {
                        title: "Sucesso!",
                        placeholder: "Atendimento finalizado com sucesso!",
                    });
                } else {
                    createAlert("normal", { title: "Erro", placeholder: res.data.erro });
                }
            }
        );
    };



    return (
        <>
            {alert.visible && alertType[alert.type]}
            {
                currentItems.length > 0 && (
                    <div className="list-user-container-body-header" style={{ marginTop: '20px' }}>
                        <input
                            type="text"
                            placeholder="Pesquisar"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                )
            }

            <div className="list-user-container-body-list">
                {currentItems.length > 0 ? currentItems.map((att, index) => {
                    return (
                        <div key={index} className="list-user-container-body-list-item">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} onClick={() => setSelectedAttendance(att)}>
                                {att.foto ? (
                                    <img src={att.foto} className="avatar" alt="avatar" onError={
                                        (e) => e.target.src = '/imgs/avatar2.png'
                                    } />
                                ) : (
                                    <img src="/imgs/avatar2.png" className="avatar" alt="avatar" />
                                )}
                                <p>{att.nome || att.pushname}</p> -
                                <p>{att.telefone}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} onClick={() => handleFinish(att)}>
                                <BsCheck2All color={'#9ac31c'} size={20} title='Finalizar Atendimento.' />
                            </div>

                        </div>
                    );
                }) : <p>Nenhum contato em atendimento</p>}
            </div>
            {filteredAtts.length > itemsPerPage && (
                <div className="pagination-list">
                    <div className="page-item-list" onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}>
                        <button className="page-item-link" style={{ width: '120px', padding: '5px', marginRight: '20px' }}>Anterior</button>
                    </div>
                    {generatePageNumbers().map((number, index) => (
                        <div key={index} className="page-item-list">
                            <button
                                className={`page-item-link ${number === currentPage ? 'active' : ''}`}
                                onClick={() => paginate(number)}>
                                {number}
                            </button>
                        </div>
                    ))}
                    <div className="page-item-list" onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}>
                        <button className="page-item-link" style={{ width: '120px', padding: '5px', marginLeft: '20px' }}>Próxima</button>
                    </div>
                </div>
            )}
            {selectedAttendance && (
                <PrAtt att={selectedAttendance} onClose={() => setSelectedAttendance(null)} />
            )}
        </>
    );
};

export default UserAttsList;