import React, { useState } from "react";
import AttendanceCreate from "./AttendanceCreate";
import AttendanceShow from "./AttendanceShow";

export default function AttendanceConfig(props) {
  const [onShow, setOnShow] = useState("visualizar");
  const components = {
    criar: <AttendanceCreate />,
    visualizar: <AttendanceShow />,
  };

  return (
    <div className="config-list-header">
      <button
        title="Visualizar grupos de atendimento"
        onClick={(e) => setOnShow("visualizar")}
        className="button-view"
        style={
          onShow === "visualizar"
            ? {
                color: "#9ac31c",
                fontWeight: "bold",
              }
            : null
        }
      >
        Visualizar
      </button>

      <button
        title="Criar novo grupo de atendimento"
        onClick={(e) => {
          setOnShow("criar");
        }}
        className="button-view"
        style={
          onShow === "criar"
            ? {
                color: "#9ac31c",
                fontWeight: "bold",
              }
            : null
        }
      >
        Criar
      </button>

      {onShow ? components[onShow] : ""}
    </div>
  );
}
