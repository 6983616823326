import React from "react";
import env from "react-dotenv";
import axios from "axios";

let url = "https://cidadao-api.voxcity.com.br";
// let url = "http://localhost:4001";

let key = "HOCUNUMSCIOMENIHILSCIRE";

const ApiCidadao = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": url,
    "x-mensageria-api-key": key,
    "x-client-type": "chat",
  },
});

axios.defaults.withCredentials = true;

export default ApiCidadao;
