import React, { useEffect, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'

import './ViewList.css'

export default function ViewList(props) {
    const { view, to, setTo, viewType } = props
    const { data, searchType, title } = view
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState([...data])

    useEffect(() => {
        if (search === '') setFilteredData([...data])
        else {
            const newData = data.filter(item => {
                for (let i = 0; i < searchType.length; i++) {
                    if (item[searchType[i]] && item[searchType[i]].toLowerCase().includes(search.toLowerCase())) return true
                }
                return false
            })
            setFilteredData([...newData])
        }
    }, [search, data, searchType])

    const select = (item) => {
        let who = { ...item }
        if (!who) return
        let newTo = [...to]
        let index = newTo.findIndex(el => viewType === "att" ? el?.telefone === who?.telefone : el?._id === who?._id)
        if (!index) {
            newTo = newTo.filter(id => viewType === "att" ? id?.telefone !== who?.telefone : id?._id !== who?._id)
        } else {
            newTo.push(who)
        }
        setTo(newTo)
    }

    return (
        <div className='view-list-container'>
            <div className='select-search'>
                <input
                    type='text'
                    placeholder={`Pesquisar ${title}`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />

            </div>
            <div className='select-list'>
                {
                    filteredData.length > 0 ? filteredData.map((item, index) => {
                        return (
                            <div key={index} className='select-item' onClick={() => select(item)}>
                                <div className='select-item-check'>
                                    <input
                                        type='checkbox'
                                        checked={to.findIndex(el => viewType === "att" ? el?.telefone === item?.telefone : el?._id === item?._id) !== -1}
                                        onChange={() => select(item)}
                                    />
                                </div>
                                <div className='select-item-avatar'>
                                    {item.foto ? <img src={item.foto} alt="" /> : <img src={"/imgs/avatar2.png"} alt="" />}
                                </div>
                                <div className='select-item-info'>
                                    <p>{item.nome ? item.nome : item.pushname ? item.pushname : item.telefone}</p>
                                    <span>{item.email}</span>
                                    <span>{item.telefone}</span>
                                </div>
                            </div>
                        )
                    }) : (
                        <div className="container-information-nothingfound-att">
                        <BsInfoCircle size={50} color="#9ac31c" />
                        <p>
                          <strong>Nenhum contato encontrado !</strong>
                        </p>
                        <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
                          Verifique o contato ou tente novamente.
                        </p>
                      </div>
                    )
                }

            </div>
        </div>

    )
}
