import "./style.css";
import { useState, useEffect,useContext } from "react";
import { BiSolidEdit } from "react-icons/bi";
import  ApiUsers  from '../../../ApiUsers';
import Alert from '../../Configurações/OptionsScreen/inputs/Alerts/Alert';
import { UserContext } from "../../../contexts/UserProvider";
import { cpf } from 'cpf-cnpj-validator';
import { CidadaoContext } from "../../../contexts/CidadaoProvider";

export const CidadaoModal = (props) => {
  const { socket } = useContext(UserContext);
  const {getCidadaoById} = useContext(CidadaoContext);
  const [cidadaoSelecionado, setCidadaoSelecionado] = useState({});
  const [cidadaoEditado, setCidadaoEditado] = useState({
    nome: "",
    endereco: "",
    telefone: "",
    email: "",
  });
  const [showEdit, setShowEdit] = useState(false);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    sucess: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }
  
  useEffect(() => {
    const novoCidadaoSelecionado = props.cidadaoSelecionado
      ? { ...props.cidadaoSelecionado }
      : {};
    setCidadaoSelecionado(novoCidadaoSelecionado);
  }, [props.cidadaoSelecionado]);

  function initSocket(){
    socket.on("update cidadao", (data) => {
      if (data._id === cidadaoSelecionado._id) {
        setCidadaoSelecionado(data);
        getCidadaoById(data._id);
      }
    });
  }

  function socketOff(){
    if(socket){
      socket.off("update cidadao");
    }
  }

  useEffect(() => {
    initSocket();
    return () => {
      socketOff();
    };
  }, [cidadaoSelecionado]);

  useEffect(() => {
    if(cidadaoSelecionado){
      let novoCidadaoEditado = { ...cidadaoSelecionado };
      setCidadaoEditado(novoCidadaoEditado);
    }
  }, [cidadaoSelecionado]);

  function onEdit() {
    setShowEdit(!showEdit);
  }

  function changeNome(e) {
    setCidadaoEditado({ ...cidadaoEditado, nome: e.target.value })
  }

  function changeEndereco(e) {
    setCidadaoEditado({ ...cidadaoEditado, endereco: e.target.value })
  }

  function changeTelefone(e) {
    setCidadaoEditado({ ...cidadaoEditado, telefone: e.target.value })
  }

  function changeEmail(e) {
    setCidadaoEditado({ ...cidadaoEditado, email: e.target.value })
  }

  async function submitEdit(cidadao) {
    await ApiUsers.post('/agendas/updateCidadao',{cidadao}).then((response) => {
      setAlert({ visible: true, type: "sucess", props: { title: "Sucesso", placeholder: "Cidadão atualizado com sucesso!" } })
      setShowEdit(false);
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <>
      {props.showCidadaoModal && (
        <div
          className={`${props.view === "tabela" ? "bg-modal" : "bg-modal-agenda-dia"
            }`}
          onClick={(e) => props.closeModalOnOutsideClick(e)}
        ></div>
      )}
      <div className="cidadao-modal">
        {
          alert.visible && alertType[alert.type]
        }
        <div className="cidadao-modal-header">
          <h5>Dados do Cidadão</h5>
          <BiSolidEdit
            className="edit-icon"
            onClick={() => onEdit()}
          />
        </div>
        <div className="cidadao-div-input">
          {
            !showEdit ? (
              <>
                <span>Nome:</span> {cidadaoSelecionado?.nome}
              </>
            ) : (
              <>
                <span>Nome:</span>
                <input type="text" value={cidadaoEditado.nome} className="input-cidadao" onChange={(e) => changeNome(e)} />
              </>
            )
          }
        </div>
        <div className="cidadao-div-input">
          <span>CPF:</span> {cpf.format(cidadaoSelecionado?.cpf)}
        </div>
        <div className="cidadao-div-input">
          {
            !showEdit ? (
              <>
                <span>Endereço:</span> {cidadaoSelecionado?.endereco}
              </>
            ) : (
              <>
                <span>Endereço:</span>
                <input type="text" value={cidadaoEditado.endereco} className="input-cidadao" onChange={(e) => changeEndereco(e)} />
              </>
            )
          }
        </div>
        <div className="cidadao-div-input">
          {
            !showEdit ? (
              <>
                <span>Telefone:</span> {cidadaoSelecionado?.telefone}
              </>
            ) : (
              <>
                <span>Telefone:</span>
                <input type="text" value={cidadaoEditado.telefone} className="input-cidadao" onChange={(e) => changeTelefone(e)} />
              </>
            )
          }
        </div>
        <div className="cidadao-div-input">
          {
            !showEdit ? (
              <>
                <span>E-mail:</span> {cidadaoSelecionado?.email}
              </>
            ) : (
              <>
                <span>E-mail:</span>
                <input type="text" className="input-cidadao" value={cidadaoEditado.email} onChange={(e) => changeEmail(e)} />
              </>
            )
          }
        </div>
        {
          showEdit ? (
            <div className="cidadao-modal-footer">
              <button className="btn btn-danger" onClick={() => onEdit()}>Cancelar</button>
              <button className="btn btn-primary" onClick={() => submitEdit(cidadaoEditado)}>Salvar</button>
            </div>
          ) : (
            <div className="cidadao-modal-footer">
            </div>
          )
        }
      </div>
    </>
  );
};
