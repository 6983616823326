import React from 'react'

export default function Text(props) {
    const { data, message, justi } = props

    function transformedArray(array) {
        if (Array.isArray(array) && array.length > 0) {
            const linkRegex = /(\b(?:https?|ftp|file):\/\/|www\.)\S+/gi;
            const transformed = array.map(item => {
                if (item.match(linkRegex)) {
                   let divisor = item.split(' ').map((word, index) => {
                        if (word.match(linkRegex)) {
                            let url = word.startsWith('www.') ? `http://${word}` : word;
                            return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
                        } else {
                            return word + ' '
                        }
                   })                    
                    return divisor.join(' ');
                } else {
                    return item + ' ';
                }
            });
    
            return transformed.join('<br>');
        } else {
            return array;
        }
    }

    return (
        <span dangerouslySetInnerHTML={{ __html: transformedArray(message) }} key={btoa(data?._id)} style={{ display: 'flex',flexDirection:"column", justifyContent: justi, width: '100%', wordBreak: "break-word", margin: "2px 0 0 2px" }}></span>
    )
    
}
