import { useState } from "react";
import MainView from "../main/MainView";
import BigView from "../main/BigView";
import { MinhaSala } from "./MinhaSala";
import { PersonalizarSala } from "./PersonalizarSala";
import { ConviteSala } from "./ConviteSala";
import { Historico } from "./Historico";

export default function VideoConferencia() {
  const [onShow, setOnShow] = useState("Minha Sala");

  const components = {
    "Minha Sala": <MinhaSala />,
    Personalizar: <PersonalizarSala />,
    Convite: <ConviteSala />,
    Histórico: <Historico />,
  };

  return (
    <MainView title="Video Conferência">
      <BigView>
        <div className="button-view-container">
          {Object.keys(components).map((key, index) => {
            return (
              <button
                key={index}
                className={"button-view"}
                style={
                  onShow === key
                    ? {
                        borderBottom: "1px solid #9ac31c",
                        color: "#9ac31c",
                        fontWeight: "bold",
                      }
                    : null
                }
                onClick={() => setOnShow(key)}
              >
                {key}
              </button>
            );
          })}
        </div>
        {components[onShow]}
      </BigView>
    </MainView>
  );
}
