import "./styles.css";
import { FaTrash, FaCog } from "react-icons/fa";

export const ItemList = ({ formValues, currentListKey, setFormType, setFormStates, setSelectedItem, setPrevListKey, setListKeys, setEditingStates, setFormValues, setSelectedOptions, nextForm, currentForm }) => (
  <ul>
    {formValues[currentListKey]?.map((item, index) => {
      return (
        <li key={index}>
          {item.nome}
          <div className="item-list-button-container">
            <FaCog
              type="button"
              title={`Configurar ${item.nome}`}
              style={{ color: "#2a9d8f" }}
              onClick={() => {
                setFormType(nextForm);
                setFormStates((prevState) => ({ ...prevState, [currentForm]: formValues }));
                setSelectedItem(item);
                setPrevListKey(currentListKey);
                setListKeys((prevState) => [...prevState, currentListKey]);
                setEditingStates((prevState) => ({ ...prevState, [currentForm]: false }));
              }}
            />

            <FaTrash
              title={`Deletar ${item.nome}`}
              style={{ color: "rgb(255, 19, 19)" }}
              type="button"
              onClick={() => {
                setFormValues((prevState) => ({
                  ...prevState,
                  [currentListKey]: prevState[currentListKey].filter((_e, i) => i !== index),
                }));
                setSelectedOptions((prevState) => prevState.filter((option) => option._id !== item._id));
              }}
            />
          </div>
        </li>
      );
    })}
  </ul>
);
