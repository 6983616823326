import React from 'react';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdAddBox } from 'react-icons/md';
import { TfiImport } from "react-icons/tfi";

import * as XLSX from "xlsx";

function Descritiva(props) {
    const [command, setCommand] = useState(props.command)
    const [selectedFlow, setSelectedFlow] = useState(props.selectedFlow)


    useEffect(() => {
        setCommand(props.command)
        setSelectedFlow(props.selectedFlow)
    }, [props.command, props.selectedFlow])

    function deleteAlternative(index) {
        let newCommand = { ...command };
        newCommand.alternativas.splice(index, 1);
        setCommand(newCommand);
    }

    function changeAlternative(e, index, op) {
        let newCommand = { ...command };
        newCommand.alternativas[index][op] = e.target.value;
        if (op === "action") newCommand.alternativas[index].next = "";
        props.setCommand(newCommand);
    }

    function importWords(e, index) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            let words = data.map((item) => {
                const keys = Object.keys(item);
                return keys.map((key) => item[key]);
            });
            let newCommand = { ...command };
            newCommand.alternativas[index].valor = [...newCommand.alternativas[index].valor, ...words.flat()];
            props.setCommand(newCommand);
        };
        reader.readAsBinaryString(file);
    }


    return (
        <div>
            <button className="flow-button insert-btn" title="Clique para adicionar uma alternativa" style={{ margin: "10px 0px", background: "#2a9d8f" }} onClick={() => props.insertOption(command.tipo)}> Inserir Novo Conjunto </button>

            <div className='flow-form-alternative-container'>
                {command.alternativas.map((alternative, index1) => {

                    return (
                        <div key={index1} className="flow-form-descritive-question-opt">
                            <div className="flow-form-descritive-question-input">
                                <input type="text" placeholder='Digite aqui as palavras reconhecidas...' style={{ flex: 1 }} className='flow-form-input' value={alternative.word} onChange={(e) => changeAlternative(e, index1, "word")} />
                                <MdAddBox className="del-flow-icon" title="Adicionar palavra reconhecida" style={{ margin: 5, fontSize: 30 }} onClick={() => props.addWord(index1)} />
                                <input type="file" style={{ display: "none" }} id={`file${index1}`} onClick={(e) => e.target.value = null} onChange={(e) => importWords(e, index1)} />
                                <TfiImport className="del-flow-icon" title="Importar palavras reconhecidas" style={{ margin: 5, fontSize: 30 }} onClick={() => document.getElementById(`file${index1}`).click()} />
                                <FaTrash className="del-flow-icon" title="Deletar Alternativa" style={{ margin: 5, fontSize: 25 }} onClick={() => deleteAlternative(index1)} />
                            </div>
                            <div className="flow-form-descritive-question-words">
                                {alternative.valor.length > 0 ? <>
                                    {alternative.valor.map((word, index2) => {
                                        return <span key={index2}> {word}; </span>
                                    })}
                                </> : <>
                                    <span> Não há palavras adicionadas </span>
                                </>}


                            </div>
                            <div className="flow-form-select-item">
                                <select className="flow-form-select" style={{ flex: 1, marginLeft: 0 }} value={command.alternativas[index1].action} onChange={(e) => changeAlternative(e, index1, "action")} >
                                    <option value={""}>Selecione uma ação</option>
                                    {props.selectActions.map((action, index3) => {
                                        return <option key={index3} value={action.value}> {action.name} </option>
                                    })}
                                </select>
                                <select className="flow-form-select" style={{ flex: 1, marginRight: 0 }} value={command.alternativas[index1].next} onChange={(e) => changeAlternative(e, index1, "next")} >
                                    {alternative.action === "keep" ? <>
                                        <option value={""}> Escolha uma ação </option>
                                        <option value={"keep"}> Seguir p/ Próximo comando </option>
                                        {selectedFlow.comandos.map((c, i) => {
                                            return <option key={i} value={i}> {i + 1}º {c.tipo} </option>
                                        })}
                                    </> : <>
                                        <option value={""}> Selecione uma ação </option>
                                        {alternative.action === "send-to-flow" ? <>
                                            {props.flows.map((f, i) => {
                                                return <option key={i} value={f._id}> {f.nome} </option>
                                            })}
                                        </> : alternative.action === "send-to-chat" ? <>
                                            {props.groups.map((g, i) => {
                                                return <option key={i} value={g._id}> {g.nome} </option>
                                            })}
                                        </> : null}
                                    </>}
                                </select>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
};

export default Descritiva;