import React, { useEffect, useState } from "react";
import SelectView from "./components/SelectView/SelectView";
import SearchInput from "./components/SearchInput/SearchInput";
import Button from "./components/Button/Button";
import ButtonImport from "./components/ButtonXLS/ButtonImport";
import ButtonExport from "./components/ButtonXLS/ButtonExport";
import "./headercontact.css";

export default function HeaderContact({
  view,
  setView,
  contacts,
  grupos,
  setContactsOnShow,
  setGruposOnShow,
  createAlert,
}) {
  const [search, setSearch] = useState("");
  const [searchGrupo, setSearchGrupo] = useState("");

  const handleSearch = (e, setSearchFunc) => {
    setSearchFunc(e.target.value);
  };

  useEffect(() => {
    if (search === "") {
      setContactsOnShow(contacts);
    } else {
      let filteredContacts = contacts.filter((contact) => {
        let valuesToFind = [contact.pushname, contact.nome, contact.telefone];
        let found = false;
        valuesToFind.forEach((value) => {
          if (value && value.toLowerCase().includes(search.toLowerCase())) {
            found = true;
          }
        });
        if(contact.flags){
          contact?.flags?.forEach((flag) => {
            if (flag && flag.toLowerCase().includes(search.toLowerCase())) {
              found = true;
            }
          });
        }
        return found;
      });
      setContactsOnShow(filteredContacts);
    }

    if (searchGrupo === "") {
      setGruposOnShow(grupos);
    } else {
      let filteredGrupos = grupos.filter((grupo) => {
        let valuesToFind = [grupo.nome];
        let found = false;
        valuesToFind.forEach((value) => {
          if (
            value &&
            value.toLowerCase().includes(searchGrupo.toLowerCase())
          ) {
            found = true;
          }
        });
        return found;
      });
      setGruposOnShow(filteredGrupos);
    }
  }, [search, searchGrupo, contacts, grupos]);

  return (
    <div className="container-header-contact">
      <div className="container-align">
        <SelectView view={view} setView={setView} />
        {view === "contatos" && (
          <>
            <SearchInput
              value={search}
              onChange={(e) => handleSearch(e, setSearch)}
              placeholder="Pesquisar contato.."
            />
            <Button text="Novo Contato" title="Criar  novo contato" />
            <ButtonImport
              text="Importar XLS"
              title="Para o correto funcionamento, o arquivo deve conter uma coluna com o nome 'Telefone' na posição A1 e os números abaixo."
              contacts={contacts}
              createAlert={createAlert}
            />
            <ButtonExport
              text="Exportar XLS"
              title="Exportar contatos para XLS"
              contacts={contacts}
            />
          </>
        )}
        {view === "grupos" && (
          <>
            <SearchInput
              value={searchGrupo}
              onChange={(e) => handleSearch(e, setSearchGrupo)}
              placeholder="Pesquisar grupo.."
            />
            <Button text="Novo Grupo" title="Criar um novo grupo" />
          </>
        )}
      </div>
    </div>
  );
}
