import { useState, useEffect } from "react";
import "./styles.css";

export const Alert = (props) => {
  const [state, setState] = useState({
    alertType: "",
    title: "",
    message: "",
    confirmLabel: "",
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      alertType: props.alertType,
      title: props.title,
      message: props.message,
      confirmLabel: props.alertType === "delete" || props.alertType === "confirm" ? props.confirmLabel : props.alertType === "notify" ? props.notifyLabel : props.alertType === "error" && props.errorLabel,
    }));
  }, [props]);

  const handleAction = (e) => {
    const { action, quit } = props;
    if (state.alertType === "delete" || state.alertType === "confirm") {
      action(e);
    }
    quit(e);
  };

  return (
    <div className="background-modal">
      <div className="alert-container">
        <div className="alert-content">
          <img
            src="/imgs/alerta.png"
            className="alert-content-img"
            alt="alert"
          />
          <h2 className="alert-title">{state.title}</h2>
          <span className="alert-span">{state.message}</span>
        </div>
        <div className="alert-container-button">
          <button
            className="back-button"
            onClick={props.quit}>
            Voltar
          </button>
          {(state.alertType === "confirm" || state.alertType === "delete") && (
            <button
              className="alert-button"
              onClick={handleAction}>
              {state.confirmLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
