import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Grafico(props) {
  const [labels, setLabels] = useState([]);
  const configTime = {
    Minuto: (val) => {
      return val / 1000 / 60;
    },
    Hora: (val) => {
      return val / 1000 / 60 / 60;
    },
    Dia: (val) => {
      return val / 1000 / 60 / 60 / 24;
    },
  };

  const [config, setConfig] = useState("Hora");

  useEffect(() => {
    let newLabels = getLabels();
    setLabels(newLabels);
  }, [props.date]);

  function getLabels() {
    let labels = [];
    let inicio = new Date(props.date.start);
    inicio.setDate(inicio.getDate() + 1);
    let fim = new Date(props.date.end);
    fim.setDate(fim.getDate() + 1);
    //gerar datas a partir do intervalo de inicio e fim
    for (let i = inicio; i <= fim; i.setDate(i.getDate() + 1)) {
      let date = new Date(i);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let dateFormated = `${day}/${month}/${year}`;
      labels.push(dateFormated);
    }
    return labels;
  }

  function getAtendidosByDate(labels, data) {
    let atendidos = labels.map((label) => {
      return {
        label,
        time: 0,
      };
    });
    data?.forEach((item) => {
      let date = new Date(item.hist.data);
      let day = date.getDate() + 1;
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let dateFormated = `${day}/${month}/${year}`;
      let index = atendidos.findIndex(
        (atendido) =>
          atendido.label === dateFormated && item.nextHist.action === "Atendido"
      );

      let time1 = new Date(item.hist.data).getTime();
      let time2 = new Date(item.nextHist.data).getTime();
      let totalTime = time2 - time1;
      if (index !== -1) {
        atendidos[index].time += totalTime;
      }
    });
    atendidos = atendidos.map((atendido) => {
      //transformar em minutos
      let time = configTime[config](atendido.time);
      //arredondar para 2 casas decimais
      time = time.toFixed(2);
      return time;
    });

    return atendidos;
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Tempo de espera até ser Atendido",
        data: getAtendidosByDate(labels, props.data),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: props.nome,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + " " + config;
          },
        },
      },
    },
  };

  if (!props.data) {
    return <></>;
  } else {
    return (
      <>
        <select
          onChange={(e) => {
            setConfig(e.target.value);
          }}
        >
          <option value="Hora">Horas</option>
          <option value="Minuto">Minutos</option>
          <option value="Dia">Dia</option>
        </select>
        <Bar options={options} data={data} id={props.id} />
      </>
    );
  }
}
