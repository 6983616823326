import { useState, useRef, useEffect } from "react";
import { ModalHistorico } from "./ModalHistorico";
import { BsThreeDotsVertical } from "react-icons/bs";

export const CardsHistorico = ({
  reunioes,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  historicoPerPage,
}) => {
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reuniaoSelecionada, setReuniaoSelecionada] = useState({});
  const [modalContent, setModalContent] = useState(null);
  const [minhasReunioes, setMinhasReunioes] = useState([]);
  const optionsMenu = ["Participantes", "Conversa"];

  const inicioReuniao = (reuniao) => {
    const data = new Date(reuniao.inicio);
    const horario = data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const dataFormatada = data.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return `${dataFormatada} - ${horario}`;
  };

  const duracaoReuniao = (reuniao) => {
    const dataInicio = new Date(reuniao.inicio);
    const dataFim = new Date(reuniao.fim);
    const duracao = dataFim - dataInicio;
    const duracaoMinutos = duracao / (1000 * 60);
    const duracaoHoras = duracaoMinutos / 60;
    const duracaoDias = duracaoHoras / 24;
    if (duracaoDias >= 1) {
      return `${Math.floor(duracaoDias)} dias`;
    } else if (duracaoHoras >= 1) {
      return `${Math.floor(duracaoHoras)} horas`;
    } else if (duracaoMinutos >= 2) {
      return `${Math.floor(duracaoMinutos)} minutos`;
    } else if (duracaoMinutos >= 1) {
      return `1 minuto`;
    } else {
      return `< 1 minuto`;
    }
  };

  const openMenu = (idReuniao) => {
    const reuniao = reunioes.find((reuniao) => reuniao._id === idReuniao);
    setReuniaoSelecionada(reuniao);
    setIsMenuOpen(!isMenuOpen);
  };

  const showModal = (reuniao, e) => {
    const { textContent } = e.target;
    const contentType = textContent;
    setReuniaoSelecionada(reuniao);
    setModalContent(contentType);
    setIsMenuOpen(false);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const novasReunioes = reunioes ? reunioes : [];
    console.log(novasReunioes);
    setMinhasReunioes(novasReunioes);
  }, [reunioes]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    let novosHistoricos = [...reunioes];
    const indexOfLastReuniao = currentPage * historicoPerPage;
    const indexOfFirstReuniao = indexOfLastReuniao - historicoPerPage;
    const currentReunioes = novosHistoricos.slice(
      indexOfFirstReuniao,
      indexOfLastReuniao
    );
    setMinhasReunioes([...currentReunioes]);
    setTotalPages(Math.ceil(novosHistoricos.length / historicoPerPage));
  }, [historicoPerPage, reunioes, currentPage, setTotalPages]);

  return (
    <div className="historico-cards-wrapper">
      {isModalOpen && (
        <div className="historico-cards-modal-bg">
          <ModalHistorico
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            reuniaoSelecionada={reuniaoSelecionada}
            modalContent={modalContent}
          />
        </div>
      )}
      {minhasReunioes.map((reuniao) => (
        <div className="historico-card-container" key={reuniao._id}>
          <p>
            Sala: <span>{reuniao.sala}</span>
          </p>
          <p>
            Início: <span>{inicioReuniao(reuniao)}</span>
          </p>
          <p>
            Duração: <span>{duracaoReuniao(reuniao)} </span>
          </p>
          <p>
            Total participantes: <span>{reuniao.participantes?.length}</span>
          </p>
          <BsThreeDotsVertical
            color="#9AC31C"
            className="historico-card-icon"
            onClick={() => openMenu(reuniao._id)}
          />
          {isMenuOpen && reuniao._id === reuniaoSelecionada._id && (
            <ul className="historico-card-menu" ref={menuRef}>
              {optionsMenu.map((option) => (
                <li key={option} onClick={(e) => showModal(reuniao, e)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};
