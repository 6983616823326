import { useState, useEffect } from "react";
import "./styles.css";

export const Selecao = ({
  headerLabel,
  content,
  selecteds,
  children,
  setFiltrados,
  filter,
}) => {
  const [items, setItems] = useState([]);
  const [selecionados, setSelecionados] = useState([]);
  const [filtroNome, setFiltroNome] = useState("");

  useEffect(() => {
    if (content) {
      const novosItems = content ? content : [];
      setItems(novosItems);
    }
  }, [content]);

  useEffect(() => {
    const novosSelecteds = selecteds ? selecteds : [];
    setSelecionados(novosSelecteds);
  }, [selecteds]);

  useEffect(() => {
    if (filter) {
      const novosFiltrados = filter(content, filtroNome);
      setFiltrados(novosFiltrados);
    }
  }, [content, filtroNome]);

  const handleNomeChange = (e) => {
    setFiltroNome(e.target.value);
  };

  return (
    <div className="qsg-agenda-select-container">
      <div
        className={
          filter
            ? "qsg-agenda-select-header"
            : "qsg-agenda-select-header-no-filter"
        }
      >
        <h4>{headerLabel}</h4>
        {filter && (
          <div className="qsg-agenda-select-search">
            <input
              type="text"
              placeholder="Pesquise do nome"
              value={filtroNome}
              onChange={handleNomeChange}
            />
          </div>
        )}
        <div className="qsg-agenda-select-header-info">
          {selecteds && <p>Selecionados: {selecionados.length}</p>}
          {content && <p>Total: {items.length}</p>}
        </div>
      </div>
      {children}
    </div>
  );
};
