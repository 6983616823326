import { useState } from "react";
import ApiUsers from "../../../../../../ApiUsers";
import { BsPen } from "react-icons/bs";

const AtendimentoInput = ({ data, contato, createAlert }) => {
  const [nValue, setNvalue] = useState("");

  async function excludeContactKey() {
    let contact = JSON.parse(JSON.stringify(contato));
    delete contact.historico;
    delete contact.allMessages;
    let res = await ApiUsers.post("/atendimentos/removeVar", {
      contact,
      varName: data.nome,
    }).catch((err) => {
      console.log(err);
    });
    if (res.data === "ok") {
      createAlert("normal", {
        title: "Sucesso",
        placeholder: "Variavel removida com sucesso",
      });
    }
  }

  async function addContactKey() {
    let contact = JSON.parse(JSON.stringify(contato));
    delete contact.historico;
    delete contact.allMessages;
    if (nValue !== "") {
      let res = await ApiUsers.post("/atendimentos/addVar", {
        contact,
        varName: data.nome,
        value: nValue,
      }).catch((err) => {
        console.log(err);
      });
      if (res && res.data === "ok") {
        createAlert("normal", {
          title: "Sucesso",
          placeholder: "Variavel adicionada com sucesso",
        });
      }
    } else {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Digite um valor para a variavel",
      });
    }
  }

  const handleChangeValue = (e) => {
    let value = e.target.value;
    setNvalue(value);
  };

  return (
    <div className="atendimento-body-footer-item">
      <span>{data.label}</span>
      <div className="atendimento-body-footer-item-options">
        {data.value === "" ? (
          <>
            <input
              style={{ width: "100%" }}
              type={data.type}
              value={nValue}
              onChange={(e) => handleChangeValue(e)}
            />
            <div
              className="atendimento-body-footer-item-options-iconBox"
              onClick={() => addContactKey()}
              title={`Adicionar variavel ${data.label}`}
            >
              <BsPen color={"#FFF"} style={{ cursor: "pointer" }} />
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#f5f5f5",
                display: "flex",
                alignItems: "center",
                cursor: "not-allowed",
              }}
            >
              {data.value}
            </p>
            <div
              className="atendimento-body-footer-item-options-iconBox"
              onClick={() => excludeContactKey(data.nome)}
              title={`Excluir variavel ${data.label}`}
            >
              <BsPen color={"#FFF"} style={{ cursor: "pointer" }} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AtendimentoInput;
