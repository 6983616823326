import { translateDate } from "../../../../miscelaneous/utils";

export const HorarioDisponivel = (props) => {
  return (
    <div className="disponivel">
      <p
        style={{
          width: "90%",
          cursor: "pointer",
          textAlign: "left",
          gap: "10px",
        }}
        onClick={() => props.submitMarcarAgendamento(props.dataAgendada)}
      >
        {translateDate(props.dataAgendada)} - Horário Disponível
        <span className="marcar-agendamento">Marcar Agendamento</span>
      </p>
      <input
        id="inputDisponivel"
        type="checkbox"
        value={props.dataAgendada}
        className="disponivel-checkbox"
        onChange={(e) => props.handleCheckboxChange(e, props.dataAgendada)}
      />
    </div>
  );
};
