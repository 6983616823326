import React from "react";
import { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../contexts/UserProvider";
import { PesqPrContext } from "../../../contexts/ChatBotProvider/PesqPrProvider";
import PesquisaCard from "./PesquisaCard";
import PesquisaForm from "./PesquisaForm";
import { GrDocumentPdf } from "react-icons/gr";
import ApiUsers from "../../../ApiUsers";
import PrAtt from "../Protocolos/PrAtt";

import Pesquisa from "./Pesquisa";
import "./Pesquisa.css";
function Pesquisas(props) {
  const context = useContext(UserContext);
  const pesqPrContext = useContext(PesqPrContext);
  const newMainMessage =
    "Olá ${nome}, a *" +
    context.pref.nome +
    "* pela sua colaboração. \n Como foi o seu atendimento? ";

  const newForm = {
    _id: "",
    nome: "",
    mensagem: newMainMessage,
    alternativas: [],
    votos: 0,
  };

  const [pesquisas, setPesquisas] = useState(
    pesqPrContext.pesquisas ? [...pesqPrContext.pesquisas] : []
  );
  const [onNewForm, setOnNewForm] = useState(false);
  const [newResearch, setNewResearch] = useState(newForm);

  const [selected, setSelected] = useState(null);
  const [selShow, setSelShow] = useState(false);
  const [prOnShow, setPrOnShow] = useState(false);

  useEffect(() => {
    if (pesqPrContext.pesquisas) {
      setPesquisas([...pesqPrContext.pesquisas]);
    }
  }, [pesqPrContext.pesquisas]);

  const novaPesquisa = (e) => {
    setOnNewForm(true);
  };
  const cancelChanges = (e) => {
    setOnNewForm(false);
  };
  function newAlternative(e) {
    let novaPesquisa = { ...newResearch };
    newResearch.alternativas.push({ valor: "", next: null });
    setNewResearch(novaPesquisa);
  }

  async function saveForm(pesquisa) {
    if (validFields(pesquisa)) {
      if (pesquisa._id === "")
        await ApiUsers.post("/pesquisas", { pesquisa })
          .then((res) => {
            if (res.data.acknowledged) {
              alert("Pesquisa salva com sucesso");
              setOnNewForm(false);
              setNewResearch(newForm);
            }
          })
          .catch((err) => console.log(err));
      else editResearch(pesquisa);
    } else alert("Preencha todos os campos");
  }
  async function editResearch(pesquisa) {
    if (validFields(pesquisa)) {
      await ApiUsers.post("/pesquisas/edit", { pesquisa })
        .then((res) => {
          if (res.data.acknowledged) {
            alert("Pesquisa salva com sucesso");
            setOnNewForm(false);
            setNewResearch(newForm);
          }
        })
        .catch((err) => console.log(err));
    } else alert("Preencha todos os campos");
  }
  function validFields(pesquisa) {
    let isValid = false;
    if (
      pesquisa.nome &&
      pesquisa.mensagem &&
      pesquisa.alternativas.length > 0
    ) {
      isValid = true;
      pesquisa.alternativas.forEach((alternativa) => {
        if (!alternativa.valor) isValid = false;
      });
    }
    return isValid;
  }
  function deletePesquisa(pesquisa) {
    ApiUsers.post("/pesquisas/delete", { pesquisa })
      .then((res) => {
        if (res.data.acknowledged) {
          alert("Pesquisa deletada com sucesso");
        }
      })
      .catch((err) => console.log(err));
  }
  function openPesquisa(p) {
    setSelected(p);
    setSelShow(true);
  }
  function showProtocol(pr) {
    let protocolo = pesqPrContext.protocolos.find((p) => p.numero === pr);
    setPrOnShow(protocolo);
  }

  return (
    <>
      <div className="pesquisas-container">

        {
          pesquisas.length > 0 && pesquisas.reverse().map((p, index) => {
            return (
              <PesquisaCard
                key={index}
                editing={true}
                pesquisa={p}
                onDelete={() => deletePesquisa(p)}
                onLook={() => {
                  openPesquisa(p);
                }}
                onEdit={() => {
                  setNewResearch(p);
                  setOnNewForm(true);
                }}
              />
            );
          }

          )
        }
        <button onClick={novaPesquisa} className="btn-add-pesquisa">
          Criar Pesquisa
        </button>
      </div>
      {onNewForm && (
        <div className="show-form">
          <PesquisaForm
            pesquisa={newResearch}
            closeForm={cancelChanges}
            newAlternative={newAlternative}
            setPesquisa={(pesquisa) => setNewResearch(pesquisa)}
            saveForm={saveForm}
          />
        </div>
      )}
      {selShow && selected && (
        <div className="show-form">
          <Pesquisa
            pesquisa={selected}
            show={selShow}
            close={() => setSelShow(false)}
            goToProtocols={showProtocol}
          />
        </div>
      )}
      {prOnShow && (
        <div className="show-form">
          <PrAtt
            user={context.user}
            pref={context.pref}
            pr={prOnShow}
            onClose={() => {
              setPrOnShow(false);
            }}
          />
        </div>
      )}
    </>
  );
}

export default Pesquisas;
