import "../../style.css";
import { useState, useEffect, useContext } from "react";
import { CidadaoContext } from "../../../../../contexts/CidadaoProvider";
import { CalendarioContext } from "../../../../../contexts/CalendarioProvider";
import { UserContext } from "../../../../../contexts/UserProvider";
import { CidadaoCard } from "./CidadaoCard";
import { Paginacao } from "../../../../miscelaneous/Paginacao";
import { MdInfoOutline } from "react-icons/md";
import { Alert } from "../../../../miscelaneous/Alert";
// import ApiCidadao from "../../../../../ApiCidadao";

export const MarcarAgendamento = (props) => {
  const [allCidadaos, setAllCidadaos] = useState([]);
  const [agendaSelecionada, setAgendaSelecionada] = useState({});
  const [cidadaoSelecionado, setCidadaoSelecionado] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [dataSelecionada, setDataSelecionada] = useState("");
  const [showAlertMarcar, setShowAlertMarcar] = useState(false);
  const [alertType, setAlertType] = useState("");
  const cidadaoContext = useContext(CidadaoContext);
  const calendarioContext = useContext(CalendarioContext);
  const userContext = useContext(UserContext);
  const { cidadaoSocket } = userContext;
  const itemsPerPage = 15;

  useEffect(() => {
    cidadaoContext.getAllCidadaos();
  }, []);

  useEffect(() => {
    const novosAllCidadaos = cidadaoContext.allCidadaos
      ? [...cidadaoContext.allCidadaos]
      : [];
    setAllCidadaos(novosAllCidadaos);

    if (search)
      setAllCidadaos(
        novosAllCidadaos.filter(
          (cidadao) =>
            cidadao.nome?.toLowerCase().includes(search.toLowerCase()) ||
            cidadao.email?.toLowerCase().includes(search.toLowerCase()) ||
            cidadao.cpf?.toLowerCase().includes(search.toLowerCase())
        )
      );
  }, [cidadaoContext.allCidadaos, search]);

  useEffect(() => {
    const novaAgendaSelecionada = props.agendaSelecionada
      ? { ...props.agendaSelecionada }
      : {};
    setAgendaSelecionada(novaAgendaSelecionada);
  }, [props.agendaSelecionada]);

  useEffect(() => {
    const novaDataSelecionada = props.dataSelecionada
      ? props.dataSelecionada
      : "";
    setDataSelecionada(novaDataSelecionada);
  }, [props.dataSelecionada]);

  useEffect(() => {
    if (cidadaoSocket) initSocket();

    return () => {
      if (cidadaoSocket) socketOff();
    };
  }, [cidadaoSocket]);

  function socketOff() {
    cidadaoSocket.off("marcar agenda");
  }

  function initSocket() {
    cidadaoSocket.on("marcar agenda", (agenda) => {
      calendarioContext.setMarked(agenda);
      const novaDataMarcada = new Date(agenda.dataSelecionada);
      props.addMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: agenda.usuario,
        status: agenda.status,
        hash: agenda.hash,
      });
    });
  }

  const searching = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const marcarAgendamento = () => {
    setAlertType("confirm");
    setShowAlertMarcar(true);
  };

  const handleConfirmationAction = async () => {
    setShowAlertMarcar(false);

    const novaDataSelecionada = new Date(dataSelecionada);
    const hours = novaDataSelecionada.getHours();
    novaDataSelecionada.setHours(hours - 3);

    const agenda = {
      prefeitura: calendarioContext.prefeituraId,
      especialidade: agendaSelecionada.especialidade,
      local: agendaSelecionada.local,
      servico: agendaSelecionada.serviço,
      profissional: agendaSelecionada.profissional,
      data: novaDataSelecionada.toISOString(),
      usuario: cidadaoSelecionado._id,
      dataSelecionada,
    };

    await calendarioContext
      .marcarHorario(agenda)
      .then((response) => {
        if (response.data.message === "Agendamento realizado com sucesso!") {
          console.log(response.data.message);
        } else {
          setAlertType("error");
          setShowAlertMarcar(true);
          console.log(response.data);
        }
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlertMarcar(true);
        console.log(error);
      });

    props.setShowConfirmarAgendamento(false);
  };

  const marcarType = {
    confirm: (
      <Alert
        title={`Marcar Agendamento!`}
        message="Deseja realmente marcar esse agendamento?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={handleConfirmationAction}
        quit={() => setShowAlertMarcar(false)}
      />
    ),
  };


  return (
    <>
      {showAlertMarcar && alertType === "confirm" && marcarType[alertType]}

      {props.showConfirmarAgendamento && (
        <div
          className="bg-modal-agenda-dia"
          onClick={(e) => props.closeModalOnOutsideClick(e)}
        ></div>
      )}
      <div className="confirmar-agendamento-modal">
        <div className="confirmar-agendamento-modal-header">
          <h3>Marcar Agendamento</h3>
        </div>
        <div className="confirmar-agendamento-modal-body">
          <div className="confirmar-agendamento-modal-form-search">
            <label htmlFor="cidadao">Buscar cidadão:</label>
            <MdInfoOutline
              className="confirmar-agendamento-modal-form-search-icon"
              title="Após digitar o nome, email ou cpf, clique no cidadão desejado para selecionar."
              alt="Após digitar o nome, email ou cpf do cidadão, clique no cidadão desejado para selecionar."
            />
            <input
              placeholder="🔎 Procurar"
              className="confirmar-agendamento-modal-form-search-input"
              type="search"
              id="cidadao"
              name="cidadao"
              onChange={(e) => {
                searching(e);
              }}
            />
          </div>
          <CidadaoCard
            cidadaos={allCidadaos}
            agendaSelecionada={agendaSelecionada}
            setAgendaSelecionada={setAgendaSelecionada}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCidadaoSelecionado={setCidadaoSelecionado}
            cidadaoSelecionado={cidadaoSelecionado}
          />
          <Paginacao
            items={allCidadaos}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          <button
            className="confirmar-agendamento-modal-btn"
            onClick={() => marcarAgendamento()}
          >
            Agendar
          </button>
        </div>
      </div>
    </>
  );
};
