export const especFormInputs = [
  {
    label: "Horário de Funcionamento",
    key: "horarioFuncionamento",
    type: "object",
    subKeys: [
      {
        label: "Início",
        key: "inicio",
        inputType: "time",
      },
      {
        label: "Fim",
        key: "fim",
        inputType: "time",
      },
      {
        label: "Dias da Semana",
        key: "dias",
        inputType: "checkbox",
      },
    ],
  },
  {
    label: "Limite de vagas por pessoa",
    key: "limiteVagas",
    type: "reactSelect",
    config: {
      isMulti: false,
      isSearchable: false,
      placeholder: "Selecione",
    },
  },
  {
    label: "Profissionais",
    key: "profissionais",
    type: "reactSelectListArray",
    config: {
      isMulti: false,
      placeholder: "Selecione",
      noOptionsMessage: () => "Sem profissionais",
    },
  },
];
