import React, { useContext, useEffect, useState } from 'react'
import File from './File'
import Text from './Text'
import Audio from './Audio'

export default function Reply(props) {
    const { data, message, justi, contactColor } = props
    const { reply } = data
    let types = {
        file: <File data={reply} message={message === 'reply' || !message ? 'Mensagem encaminhada' : reply.message}  contactColor={contactColor} />,
        text: <Text data={reply} message={message === 'reply' || !message ? 'Arquivo encaminhado' : reply.message}  contactColor={contactColor} />,
        audio: <Audio data={reply} message={message === 'reply' || !message ? 'Arquivo encaminhado' : reply.message}  contactColor={contactColor} />,
    }

    const [type, setType] = useState('')

    useEffect(() => {
        let newType = 'text'
        if (reply && reply.files && reply.files.length > 0) {
            newType = 'file'
        }
        if (reply && reply.audio) {
            newType = 'audio'
        }
        setType(newType)
    }, [reply])

    return (
        <>
            <div className="reply-message-container">
                <div className="reply-message-content">
                    <h6 >Resposta</h6>
                    {types[type]}
                </div>
                <span key={btoa(data._id)} style={{ display: 'flex',  width: '100%', wordBreak: "break-word", margin: "2px 0 0 8px" }}>
                    {message}
                </span>
            </div>

        </>

    )
}
