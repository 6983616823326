import React from 'react'
import { BiUser } from "react-icons/bi";

const Email = (props) => {
  return (
    <div className="login-label">
        <label htmlFor="email">E-mail</label>
        <div className="login-input-content">
            <input
                name="email"
                className="login-input"
                type="text"
                onChange={(event) => props.setInputs("email", event.target.value)}
            />
            <BiUser className="login-icon" />
        </div>
    </div>
  )
}

export default Email
