import React from "react";
import { Col } from "react-bootstrap";

const Rightcol = (props) => {
  return (
    <Col id="right-col" className="right-col">
      {props.children}
    </Col>
  );
};

export default Rightcol;
