import React, { useState, useContext, useEffect, createContext } from "react";
import { UserContext } from "../UserProvider";
import ApiUsers from "../../ApiUsers";
const FlowBotContext = createContext();

function FlowBotProvider({ children }) {
  const context = useContext(UserContext);
  let initalFlows = Object.keys(context.pref.services.voxbot.flows).map(
    (f) => context.pref.services.voxbot.flows[f]
  );
  let initialBots = Object.keys(context.pref.services.voxbot.bots).map(
    (f) => context.pref.services.voxbot.bots[f]
  );
  const [flows, setFlows] = useState(initalFlows);
  const [bots, setBots] = useState(initialBots);
  const [random, setRandom] = useState({});
  const [sincronizando, setSincronizando] = useState(false);
  const [sincronizandoGrupos, setSincronizandoGrupos] = useState(false);
  const [variables, setVariables] = useState(
    context.pref.services.voxbot.variaveis
      ? [...context.pref.services.voxbot.variaveis]
      : []
  );
  const [campanhas, setCampanhas] = useState(
    context.pref.services.voxbot.campanhas
      ? [context.pref.services.voxbot.campanhas]
      : []
  );
  const APISocket = context.socket;
  const socket = APISocket;

  useEffect(() => {
    if (context.pref.services.voxbot.campanhas)
      setCampanhas(context.pref.services.voxbot.campanhas);
  }, [context.pref.services.voxbot.campanhas]);

  useEffect(() => {
    if (context.pref.services.voxbot.variaveis)
      setVariables(context.pref.services.voxbot.variaveis);
  }, [context.pref.services.voxbot.variaveis]);

  useEffect(() => {
    APISocket.on("update bot", (bot) => updateBots(bot));
    APISocket.on("delete bot", (bot) => deleteBot(bot));
    APISocket.on("sincronizado", () => {
      setSincronizando(false);
    });
    return () => {
      APISocket.off("update bot");
      APISocket.off("delete bot");
    };
  }, [APISocket, bots, random]);

  useEffect(() => {
    let randoms = random;
    bots.forEach((bot) => {
      if (bot.status === "logando") randoms[bot._id] = new Date().getTime();
      else randoms[bot._id] = false;
    });
    setRandom(randoms);

    ///UPDATE BOT DA API
    APISocket.on("update bot", (bot) => updateBots(bot));

    return () => {
      APISocket.off("update bot");
    };
  }, [bots]);
  ///UPDATE DE VARIAVEIS
  useEffect(() => {
    APISocket.on("new variable", (variable) => newVariable(variable));
    APISocket.on("delete variable", (variable) => deleteVariable(variable));
    return () => {
      APISocket.off("new variable");
      APISocket.off("delete variable");
    };
  }, [variables]);

  useEffect(() => {
    //UPDATE BOT DA API
    APISocket.on("update flow", (newFlow) => updateFlows(newFlow));
    APISocket.on("delete flow", (flowId) => deleteFlow(flowId));

    return () => {
      APISocket.off("update flow");
      APISocket.off("delete flow");
    };
  }, [flows]);

  useEffect(() => {
    APISocket.on("update campanha", (campanha) => updateCampanha(campanha));
    APISocket.on("delete campanha", (campanha) => deleteCampanha(campanha));

    APISocket.on("remove from campaign", (contact) =>
      removeContactFromCampaign(contact)
    );

    return () => {
      APISocket.off("new campanha");
      APISocket.off("delete campanha");
      APISocket.off("remove from campaign");
    };
  }, [campanhas]);

  ///VARIABLES FUNCTIONS
  function newVariable(variable) {
    let newVariables = variables;
    newVariables.push(variable);
    setVariables(newVariables);
  }
  function deleteVariable(variable) {
    setVariables(variables.filter((v) => v._id !== variable._id));
  }

  //CAMPAIGN FUNCTIONS

  function updateCampanha(campanha) {
    let newCampanhas = [...campanhas];
    if (campanhas.some((c) => c._id === campanha._id)) {
      newCampanhas.forEach((c) => {
        if (c._id === campanha._id) c = Object.assign(c, campanha);
      });
    } else {
      newCampanhas = [...campanhas, campanha];
    }
    setCampanhas(newCampanhas);
  }

  function deleteCampanha(campanha) {
    setCampanhas(campanhas.filter((c) => c._id !== campanha._id));
  }

  // FLOWS ACTIONS ----------------------------------------------------------------------------------------------------------------------

  function deleteFlow(flowId) {
    let newFlows = flows.filter((f) => f._id !== flowId);
    setFlows(newFlows);
  }

  function updateFlows(flow) {
    let newFlows = [...flows];
    if (flows.some((f) => f._id === flow._id)) {
      newFlows.forEach((f) => {
        if (f._id === flow._id) f = Object.assign(f, flow);
      });
    } else {
      newFlows = [...flows, flow];
    }
    setFlows(newFlows);
  }

  // BOT ACTIONS ----------------------------------------------------------------------------------------------------------------------

  function updateBots(bot) {
    let newBots = [...bots];
    if (bots.some((b) => b._id === bot._id)) {
      newBots.forEach((f) => {
        if (f._id === bot._id) f = Object.assign(f, bot);
      });
    } else {
      newBots = [...bots, bot];
    }
    setBots(newBots);
  }

  function deleteBot(bot) {
    let newBots = bots.filter((b) => b._id !== bot._id);
    setBots(newBots);
  }

  function removeContactFromCampaign(contact) {
    let campanhasCopy = [...campanhas];
    campanhasCopy.forEach((camp) => {
      let campaign = contact.onCampaign.split("-")[0];
      if (camp._id === campaign) {
        console.log(camp);
        camp.contatos = camp.contatos.filter(
          (c) => c.telefone !== contact.telefone
        );
      }
    });
    setCampanhas(campanhasCopy);
  }
  function sincronyze(bot) {
    setSincronizando(true);
    ApiUsers.post("/whats/sincronize", { bot })
      .then((res) => {
        console.log(res);
        setSincronizando(false);
        alert("Sincronizado com sucesso");
      })
      .catch((err) => {
        console.log(err);
        setSincronizando(false);
      });
  }

  const sincronyzeGroup = (bot) => {
    setSincronizandoGrupos(true);
    ApiUsers.post("/gruposWhats/sincronizarGrupos", { bot })
      .then((res) => {
        console.log(res);
        setSincronizandoGrupos(false);
        alert("Grupos Sincronizados com sucesso");
      })
      .catch((err) => {
        console.log(err);
          setSincronizandoGrupos(false);
      });
  }

  return (
    <FlowBotContext.Provider
      value={{
        flows,
        bots,
        random,
        socket,
        variables,
        campanhas,
        sincronyze,
        sincronizando,
        sincronyzeGroup,
        sincronizandoGrupos,
      }}
    >
      {children}
    </FlowBotContext.Provider>
  );
}

export { FlowBotProvider, FlowBotContext };
