import React, { useState, useContext, useEffect } from 'react';
import { AiOutlinePaperClip, AiFillMinusCircle } from 'react-icons/ai'
import { BsAlarmFill } from "react-icons/bs";
import ApiUsers from '../../../../ApiUsers';
import { ReplyForwardingContext } from '../../../../contexts/ReplyForwardingProvider';
import Reply from './Pieces/Reply';
import '../../chat.css';
import EmjPick from './Pieces/EmjPick';
import Recorder from './Pieces/Recorder';
import Files from './Pieces/Files';
import Temporizador from '../Temporizador';
import { MenuMsgsRapidas } from './Pieces/menuMsgsRapidas';
import { UserContext } from '../../../../contexts/UserProvider';

// New instance

function Messagersender(props) {

    const [message, setMessage] = useState("")
    const [onEmoji, setOnEmoji] = useState(false)
    const [files, setFiles] = useState({})
    const [audio, setAudio] = useState(null)
    const [recording, setRecording] = useState(false)
    const [minimizeFiles, setMinimizeFiles] = useState(false);
    const [typing, setTyping] = useState(false);
    const [msgType, setMsgType] = useState(props.msgType)
    const [isMenuMsgsRapidasOpen, setIsMenuMsgsRapidasOpen] = useState(false);
    const [msgBoxIconsWidth, setMsgBoxIconsWidth] = useState(0);
    const [showTemporizador, setShowTemporizador] = useState(false);
    const disableForm = ["historico", "espera", "potenciais"];
    const { socket, msgsRapidasUsuario, user } = useContext(UserContext);

    const { forwardingMessage, reset, replyMessage, setReplyMessage } = useContext(ReplyForwardingContext)

    useEffect(() => {
        if (props.msgType) setMsgType(props.msgType)
    }, [props.msgType])

    useEffect(() => {
        const newMsgBoxIconsWidth = document.querySelector(".message-box-icons").clientWidth;
        setMsgBoxIconsWidth(newMsgBoxIconsWidth);
    }, []);

    function changeMessage(e) {
        let message = e.target.value
        let newMessage = message
        newMessage = message
        setMessage(newMessage)

        const firstChar = message.charAt(0);

        if (firstChar === "/" && !isMenuMsgsRapidasOpen) {
            // console.log("abrindo");
            toggleMenuMsgsAuto();
        } else if (firstChar !== "/" && isMenuMsgsRapidasOpen) {
            // console.log("fechando");
            toggleMenuMsgsAuto();
        }

        if (msgType === "private" && !typing) {
            socket.emit("typing", { to: props.contact._id })
            setTyping(true)
            setTimeout(() => {
                setTyping(false)
            }, 3000);

        }
    }

    function toggleMenuMsgsAuto() {
        // console.log("menu de msgs automaticas");
        setIsMenuMsgsRapidasOpen(!isMenuMsgsRapidasOpen);
    }

    function messageExist() {
        if (message.trim().length > 0) return true
        if (files.length > 0) return true
        if (audio) return true
        return false
    }

    function sendMessage(e) {
        e.preventDefault()
        if (!messageExist() && !forwardingMessage) return

        if (Array.isArray(props.contact)) {
            for (let i = 0; i < props.contact.length; i++) {
                let newTo = props.contact[i]
                send(newTo)
            }
        }
        else {
            send()
        }
    }


    const send = (newTo) => {
        let data = {}
        let toClone = newTo ? newTo : props.contact
        let deepCloneContact = JSON.parse(JSON.stringify(toClone))
        delete deepCloneContact.allMessages
        let expoToken = deepCloneContact.expoToken
        if (msgType === "private") newTo = deepCloneContact._id
        if (msgType === "group") newTo = deepCloneContact
        if (msgType === "att") newTo = deepCloneContact
        if (msgType === "whats-group"){
            delete deepCloneContact.membros
            newTo = deepCloneContact
        }
             
        data = { message, audio, files, to: newTo, forwarding: forwardingMessage, reply: replyMessage, expoToken, type: msgType }
        let formData = new FormData();
        if (msgType === "group") formData.append('users', deepCloneContact.usuarios)
        if(msgType === "whats-group") formData.append('isGroup', true)
        if (forwardingMessage) {
            formData.append('forwarding', forwardingMessage)
        }
        if (replyMessage) {
            formData.append('reply', replyMessage)
        }
        formData.append('to', newTo)
        formData.append('type', msgType)
        formData.append('message', message)
        //prepare to send message and files e audio

        if (audio) {
            formData.append('audio', audio);
            if (msgType === "att") {
                formData.append('telefone', deepCloneContact.telefone)
                formData.append('bot', deepCloneContact.bot)
            }
            if(msgType === "whats-group"){
                formData.append('id_whatsapp', deepCloneContact.id_whatsapp)
                formData.append('bot', deepCloneContact.bot)
                formData.append('toId', deepCloneContact._id)
            }
            msgType !== "att" && msgType !== 'whats-group' ? ApiUsers.post('/upload/audio', formData).then(resp => {
                setFiles({})
                setMessage("")
            })
                : ApiUsers.post('/whats/upload/audio', formData).then(resp => {
                    setFiles({})
                    setMessage("")
                })
        }
        else if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i])
            }
            if (msgType === "att") {
                formData.append('telefone', deepCloneContact.telefone)
                formData.append('bot', deepCloneContact.bot)
            }
            if(msgType === "whats-group"){
                formData.append('id_whatsapp', deepCloneContact.id_whatsapp)
                formData.append('bot', deepCloneContact.bot)
                formData.append('toId', deepCloneContact._id)
            }
            msgType !== "att" && msgType !== 'whats-group' ? ApiUsers.post('/upload/messageFiles', formData).then(resp => {
                setFiles({})
                setMessage("")
            })
                : ApiUsers.post('/whats/upload/files', formData).then(resp => {
                    setFiles({})
                    setMessage("")
                })

        }
        else {
            // console.log('data', data)
            // console.log('msgType', msgType)
            socket.emit("send " + msgType, data)
        }
        setMessage("")
        setFiles({})
        setAudio(null)
        reset()
    }

    function checkEnter(e) {
        if (e.key === "Enter" && e.shiftKey) {
            return;
        } else if (e.key === "Enter" && !isMenuMsgsRapidasOpen) {
            sendMessage(e);
            return;
        } else if (e.key === "Enter" && isMenuMsgsRapidasOpen) {
            e.preventDefault();
            let msg;
            const firstChar = message.charAt(0);
            if (firstChar === "/") {
                msg = message.slice(1);
                let msgRapida = msgsRapidasUsuario.find((m) => m.atalho.includes(msg));

                // metodo para voltar a mensagem exata do atalho
                //   let msgRapida = msgsRapidasUsuario.filter((m) => m.atalho === msg);

                setMessage(msgRapida.mensagem);
                setIsMenuMsgsRapidasOpen(false);
            }
        }
    }

    function pasteFiles(e) {
        let files = e.clipboardData.files
        let newFiles = []
        if (e.clipboardData.files[0]) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                newFiles.push(file)
            }
            setFiles(newFiles)
        }
    }

    function addFileToMessage(e) {
        e.preventDefault()
        let files = e.target.files
        let newFiles = files
        setFiles(newFiles)
    }

    return (
        <div className="message-sender-container">
            <Reply replyMessage={replyMessage} setReplyMessage={setReplyMessage} />
            {showTemporizador && <Temporizador setShowTemporizador={setShowTemporizador} contact={props.contact} type={msgType} />}
            <div className="form-sender" style={{ display: "flex", flexDirection: "row" }} >
                <Files files={files} setFiles={setFiles} minimizeFiles={minimizeFiles} setMinimizeFiles={setMinimizeFiles} />

                {
                    audio ? <audio className='audio-before-send' style={{ flex: 1, height: "80%", marginTop: 4 }} controls src={URL.createObjectURL(audio)}></audio>
                        :
                        <>
                            <img src='./imgs/smile-5145 (1).svg' alt="smile" style={{ width: 20, height: 20, margin: 'auto 10px' }} className="chat-messenger-icon" onClick={() => setOnEmoji(!onEmoji)} title="Emojis" />
                            <textarea
                                className="message-sender"
                                style={{ marginTop: 5, minHeight: 0 }}
                                placeholder="Digite uma mensagem"
                                onChange={e => changeMessage(e)}
                                onKeyPress={checkEnter}
                                onPaste={pasteFiles}
                                value={message}
                                disabled={!props.contact || (msgType === "att" && disableForm.includes(props.navType))}
                            />
                        </>
                }

                {
                    isMenuMsgsRapidasOpen &&
                    <MenuMsgsRapidas
                        msgBoxIconsWidth={msgBoxIconsWidth}
                        message={message}
                        setMessage={setMessage}
                        checkEnter={checkEnter}
                        isMenuMsgsRapidasOpen={isMenuMsgsRapidasOpen}
                        setIsMenuMsgsRapidasOpen={setIsMenuMsgsRapidasOpen}
                    />
                }

                <div className='message-box-icons'>

                    {!audio && <>
                        <BsAlarmFill size={20} className='chat-messenger-icon' onClick={() => setShowTemporizador(true)} title="Agendar mensagem" />
                        <AiOutlinePaperClip className='chat-messenger-icon' onClick={!props.contact ? null : (e) => { document.querySelector("#inp-append-files").click() }} title="Anexar arquivo" />
                    </>
                    }

                    {audio ? (<>
                        <AiFillMinusCircle
                            onClick={e => { setAudio(null) }} className="chat-messenger-icon" title="Deletar aúdio" />
                        <img src='./imgs/sendImg.svg' alt="send" className="chat-messenger-icon" onClick={(e) => { sendMessage(e) }} title="Enviar mensagem" />
                    </>)
                        :
                        null
                    }

                    {message.length > 0 || files.length > 0 ? (
                        <img src='./imgs/sendImg.svg' alt="send" className="chat-messenger-icon" onClick={(e) => { sendMessage(e) }} title="Enviar mensagem" />) : (
                        <Recorder
                            recording={recording}
                            setRecord={setRecording}
                            audio={audio}
                            setAudio={setAudio}
                            contact={props.contact} // para saber se deve ser exibido
                        />)}

                </div>

                <EmjPick
                    setOnEmoji={setOnEmoji}
                    onEmoji={onEmoji}
                    message={message}
                    setMessage={setMessage}
                />
                <input
                    onChange={addFileToMessage}
                    onClick={e => { e.target.value = "" }}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    id="inp-append-files"
                />

            </div>
        </div>
    );

}

export default Messagersender;