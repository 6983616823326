import { useState, useEffect, useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { BsPlus, BsInfoCircle } from 'react-icons/bs';
import { UserContext } from '../../../../../../contexts/UserProvider';
import { AttendanceContext } from '../../../../../../contexts/ChatBotProvider/AttendanceProvider';

const MyAttandances = ({ setModal, onSelect, selected }) => {
    const userContext = useContext(UserContext);
    const attendanceContext = useContext(AttendanceContext);
    const [attendance, setAttendance] = useState([]);
    const [attendanceOnShow, setAttendanceOnShow] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        let attendances = [...attendanceContext.attendances].filter(
            (att) => att.atendente === userContext.user._id
        );
        setAttendance(attendances);
        setAttendanceOnShow(attendances);
    }, [attendanceContext.attendances]);

    const doSearch = (value) => {
        if (value === "") {
            setAttendanceOnShow([...attendance]);
        } else {
            let newAttendance = attendance.filter(
                (attendance) =>
                    attendance.pushname.toLowerCase().includes(value.toLowerCase()) ||
                    attendance.telefone.toLowerCase().includes(value.toLowerCase()) ||
                    attendance?.flags?.map((flag) => flag.toLowerCase()).includes(value.toLowerCase())
            );
            setAttendanceOnShow(newAttendance);
        }
    };

    useEffect(() => {
        doSearch(searchInput);
    }, [searchInput, attendance]);

    const countAndSlice = (message) => {
        return message ? message.slice(0, 20) + '...' : '';
    };

    const formatTime = (time) => {
        return time < 10 ? '0' + time : time;
    };

    return (
        <>
            <div className="container-search">
                <input
                    type="search"
                    placeholder="Buscar Contato..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="button-search-box" onClick={() => setModal(true)}>
                    <BsPlus size={20} title='Adicionar novo atendimento.' />
                </div>
            </div>

            <div className="attendance-list">
                {(attendanceOnShow?.length === 0 && searchInput !== '') && (
                    <div className="container-information-nothingfound-att">
                        <BsInfoCircle size={50} color="#9ac31c" />
                        <p>
                            <strong>Nenhum atendimento encontrado!</strong>
                        </p>
                        <p style={{ color: '#989898', fontSize: '14px', marginTop: '0px' }}>
                            Verifique o contato ou crie um novo atendimento.
                        </p>
                    </div>
                )}

                {attendanceOnShow?.map((contact, index) => {
                    const background = contact.telefone === selected?.telefone ? '#F0FFF0' : 'inherit';
                    const lastMsgTime = contact.lastMessage && new Date(contact.lastMessage.createdAt);
                    const formatedDate = lastMsgTime ? `${formatTime(lastMsgTime.getDate())}/${formatTime(lastMsgTime.getMonth() + 1)}/${lastMsgTime.getFullYear()}` : '';
                    const formatedTime = lastMsgTime ? `${formatTime(lastMsgTime.getHours())}:${formatTime(lastMsgTime.getMinutes())}` : '';

                    return (
                        <div className="contact-wrapper" key={index} style={{ backgroundColor: background }}>
                            <img
                                className="contact-avatar"
                                src={contact.foto || '/imgs/avatar2.png'}
                                alt=""
                                onError={(e) => {
                                    e.target.src = '/imgs/avatar2.png';
                                }}
                            />
                            <ListGroup.Item
                                className="contact-data"
                                style={{ backgroundColor: background, border: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                onClick={(e) => onSelect(e, contact)}
                            >
                                <b>{contact.nome || contact.pushname || contact.telefone?.toString().replace('@c.us', ' ').trim()}</b>
                                <p style={{ margin: '4px 0px' }}>{countAndSlice(contact.lastMessage?.message)}</p>
                                <small className="small-title">{contact.lastMessage ? `Última mensagem às ${formatedTime} de ${formatedDate}` : 'Não há mensagem disponível'}</small>
                            </ListGroup.Item>
                            {contact.unseen && contact.unseen.length > 0 && (
                                <div className="not-seen">
                                    <b>{contact.unseen.length}</b>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default MyAttandances;