import React from "react";

export default function FormInputCheckBox({ newBot, setOficial }) {
  return (
    <div className="bot-form-input-container-create-form">
      <div className="container-align-input-create-form ">
        <h5 className="required">Selecione uma API</h5>
      </div>
      <div className="bot-form-input-create-form-alignRow">
        <input
          type="checkbox"
          className="bot-form-input-create-form-checkbox"
          checked={newBot.oficial}
          onChange={(e) => setOficial(e)}
        />
        <h5 className="required">API-Oficial</h5>
      </div>
    </div>
  );
}
