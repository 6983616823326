import React, { useState, useEffect, useContext } from 'react';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';
import CheckboxList from '../../inputs/CheckboxUsers/CheckboxList';
import { Form } from 'react-bootstrap';
import ApiUsers from '../../../../../ApiUsers';

export default function SectionListUsers(props) {

    const { setor } = props;
    const prefContext = useContext(PrefContext);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosOnShow, setUsuariosOnShow] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let allUsers = prefContext.users.filter((u) => u.nome !== "Voxcity" && u.ativo);
        let users = allUsers.filter((userObj) => setor.usuarios.some((userId) => userId === userObj._id));
        setUsuarios(users);
        setUsuariosOnShow(users);
    }, [prefContext.setores])

    function searchUsers(e) {
        let search = e.target.value;
        let newUsers = [];
        if (search.trim() !== "") {
            newUsers = usuarios.filter(user => user.nome.toLowerCase().indexOf(search.toLowerCase()) > -1);
        } else {
            newUsers = [...usuarios];
        }
        setUsuariosOnShow(newUsers);
    }

    function selectUser(checked, user) {
        let users = [];
        if (checked) {
            users = [...selectedUsers, user];
        } else {
            users = selectedUsers.filter(u => u._id !== user._id);
        }
        setSelectedUsers(users);
    }

    function selectAll(checked) {
        let users = [];
        if (checked) {
            users = [...usuarios];
        } else {
            users = []
        }
        setSelectedUsers(users);
    }

    function delUsers() {
        if (selectedUsers.length > 0) {
            setLoading(true);
            let users = selectedUsers.map((u) => u._id);
            ApiUsers.post("/setores/removerVarios", { setor, users }).then((res) => {
                setSelectedUsers([]);
                setLoading(false);
                props.alert("normal", { title: "Sucesso!", placeholder: "Usuários removidos do setor com sucesso!" });
            }).catch(err => console.log(err));
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Selecione ao menos um usuário para removê-lo do setor!" });
        }
    }

    return <>

        <div className="section-list-users-container">

            <h2 style={{ textAlign: "center", margin: "20px 0px" }}> Usuários Setor {setor.nome} </h2>

            <Form.Control placeholder={"Digite aqui para pesquisar um usuário do setor " + setor.nome} onChange={(e) => searchUsers(e)} />

            <br />

            <CheckboxList
                usuarios={usuariosOnShow}
                selectedUsers={selectedUsers}
                action="del"
                delUsers={() => delUsers()}
                selectUser={(checked, user) => selectUser(checked, user)}
                selectAll={(checked) => selectAll(checked)}
                loading={loading}
            />

            <div style={{ display: 'flex', justifyContent: "flex-end", margin: "20px 0px", width: '100%' }}>

                <button className="section-list-users-goback-btn" onClick={() => props.goBack()} title="Voltar">
                    Voltar
                </button>

            </div>

        </div>

    </>
}
