import { FaTrash, FaCog } from "react-icons/fa";
import "./style.css";

export const ProfissionalCard = (props) => {
  const editProfissional = (profissional) => {
    props.setEditandoProfissional(true);
    props.setProfissionalSelecionado(profissional);
  };

  const deleteProfissional = (profissional) => {
    const profissionalSelecionado = props.profissionaisSelecionados.find(
      (p) => p._id === profissional._id
    );
    if (profissionalSelecionado) {
      const updatedOptionsProfissionais = [
        ...props.optionsProfissionais,
        { value: profissional._id, label: profissional.nome },
      ];
      props.setOptionsProfissionais([...updatedOptionsProfissionais]);
    }

    props.setProfissionaisSelecionados(
      props.profissionaisSelecionados.filter((p) => p._id !== profissional._id)
    );
  };

  return (
    <>
      {props.profissionaisSelecionados.map((profi, index) => (
        <div key={index} className="card-profissional-especialidade-form">
          <h2>{profi.nome}</h2>
          <div className="buttons-container">
            <FaCog
              onClick={() => {
                editProfissional(profi);
              }}
              className="edit-local"
              title={`Configurar ${profi.nome}`}
              size={"2rem"}
              style={{ color: "#2a9d8f" }}
            />
            <FaTrash
              className="delete-local"
              size={"2rem"}
              title={`Deletar ${profi.nome}`}
              style={{ color: "rgb(255, 19, 19)" }}
              onClick={() => deleteProfissional(profi)}
            />
          </div>
        </div>
      ))}
    </>
  );
};
