import { useContext, useState } from 'react'
import { TemporizadorContext } from '../../../../contexts/ChatBotProvider/TemporizadorProvider';
import { BsAlarmFill, BsTrash3, BsPen } from "react-icons/bs";
import Temporizador from '../../../Chats/general/Temporizador';

const TemporizadorList = ({ createAlert, quitAlert }) => {
    const { temporizadores, deleteTemporizador } = useContext(TemporizadorContext)
    const [showTemporizador, setShowTemporizador] = useState(false)
    const [editData, setEditData] = useState({})

    const transformDate = (date) => {
        let newDate = new Date(date)
        let day = newDate.getDate() + 1
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()
        if (day < 10) {
            day = `0${day}`
        }
        if (month < 10) {
            month = `0${month}`
        }
        return `${day}/${month}/${year}`
    }

    const handleDeleteAlert = (temp) => {
        createAlert('delete', {
            data: temp,
            nome: `Mensagem para ${temp.toName}`,
            action: () => handleCloseAlert(temp)
        })
    }

    const handleCloseAlert = (temp) => {
        deleteTemporizador(temp)
        quitAlert()
    }

    const handleModifyType = (type) => {
        if (type === 'private') {
            return 'Privado'
        } else if (type === 'group') {
            return 'Grupo'
        } else if (type === 'att') {
            return 'Atendimento'
        } else {
            return 'Atendimento em Grupo'
        }
    }

    const handleOpenTemporizador = (temp) => {
        setEditData(temp)
        setShowTemporizador(true)
    }

    return (
        <div className='temporizador-list-container'>
            {showTemporizador && <Temporizador setShowTemporizador={setShowTemporizador} editData={editData} setEditData={setEditData} type={editData.type} />}
            <div className='temporizador-list-header'>
                <h5>Mensagens agendadas</h5>
                <BsAlarmFill color={'#9ac31c'} size={20} />
            </div>
            {
                temporizadores.length > 0 ?
                    <table className='temporizador-list-table'>
                        <thead>
                            <tr>
                                <th>Criado por:</th>
                                <th>De:</th>
                                <th>Para:</th>
                                <th>Tipo:</th>
                                <th>Data:</th>
                                <th>Hora:</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                temporizadores.map((temporizador, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{temporizador.createdBy}</td>
                                            <td>{temporizador.fromUserName}</td>
                                            <td>{temporizador.toName}</td>
                                            <td>{handleModifyType(temporizador.type)}</td>
                                            <td>{transformDate(temporizador.data)}</td>
                                            <td>{temporizador.hora}</td>
                                            <td style={{ display: 'flex', gap: 10, textAlign: 'center' }}>
                                                <BsPen color={'#9ac31c'} size={20} title='Editar mensagem agendada.' className='temporizador-list-icon' onClick={() => handleOpenTemporizador(temporizador)} />
                                                <BsTrash3 color={'#9ac31c'} size={20} title='Excluir mensagem agendada.' className='temporizador-list-icon' onClick={() => handleDeleteAlert(temporizador)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    :
                    <div className='temporizador-list-container'>
                        <h3>Nenhuma mensagem agendada cadastrada.</h3>
                    </div>
            }
        </div>
    )
}

export default TemporizadorList
