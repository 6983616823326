import React, { useContext, useState } from "react";
import { AttendanceContext } from "../../../../../../contexts/ChatBotProvider/AttendanceProvider";
import ButtonAction from "./ButtonsActions/ButtonAction";
import ApiUsers from "../../../../../../ApiUsers";
import PrAtt from "../../../../Protocolos/PrAtt";

const TdActions = ({
  contact,
  selectContact,
  createAlert,
  quitAlert,
  isBlocked,
}) => {
  const [chatOnShow, setChatOnShow] = useState(null);
  const { updateAttBlock } = useContext(AttendanceContext);

  const deleteContact = (contact) => {
    let newContact = JSON.parse(JSON.stringify(contact));
    delete newContact.allMessages;
    delete newContact.historico;
    delete newContact.selected;
    ApiUsers.post("/atendimentos/deleteContact", { contact: newContact }).then(
      (res) => {
        quitAlert();
        if (res.data.erro) {
          createAlert("normal", {
            title: "Erro!",
            placeholder: res.data.error,
          });
        } else {
          createAlert("normal", {
            title: "Sucesso!",
            placeholder: "Contato deletado com sucesso!",
          });
        }
      }
    );
  };

  const handleDelAlert = (contact) => {
    createAlert("delete", {
      data: "excluir",
      nome: contact.nome || contact.pushname,
      action: () => deleteContact(contact),
    });
  };

  const handleChangeBlock = (contact) => {
    if (!contact?.bot) {
      createAlert("normal", {
        title: "Erro!",
        placeholder: "Contato não possui bot!",
      });
      return;
    }
    const blockStatus = !!contact?.bloqueado ? "unlock" : "block";
    ApiUsers.post(`/gruposWhats/${blockStatus}`, {
      numero: contact.telefone,
      bot: contact.bot,
    }).then((res) => {
      updateAttBlock(contact._id);
      quitAlert();
      if (res.data.message) {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: res.data.message,
        });
      } else if (res.data.error) {
        createAlert("normal", {
          title: "Erro!",
          placeholder: res.data.error,
        });
      }
    });
  };

  return (
    <>
      <td className="td-common">
        <ButtonAction iconName="BsEye" onClick={() => setChatOnShow(contact)} />
        <ButtonAction
          iconName="BsPencilSquare"
          onClick={() => selectContact(contact)}
        />
        <ButtonAction
          iconName="BsTrash3"
          onClick={() => handleDelAlert(contact)}
        />
        {isBlocked ? (
          <ButtonAction
            iconName="BsSlashCircle"
            onClick={() => handleChangeBlock(contact)}
          />
        ) : (
          <ButtonAction
            iconName="BsDashCircle"
            onClick={() => handleChangeBlock(contact)}
          />
        )}
        {chatOnShow && (
          <PrAtt att={chatOnShow} onClose={() => setChatOnShow(null)} />
        )}
      </td>
    </>
  );
};

export default TdActions;
