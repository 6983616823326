import { useEffect, useState, useContext } from 'react'
import { PesqPrContext } from '../../../../../contexts/ChatBotProvider/PesqPrProvider';
import { AttendanceContext } from '../../../../../contexts/ChatBotProvider/AttendanceProvider';
import UserAttsList from './userAttsList';
import UserProtocolosList from './userProtocolosList';
import { BsX } from 'react-icons/bs';

const UserAtedimentoModal = (props) => {
    const { handleModal, user } = props;
    const { protocolosAtts } = useContext(PesqPrContext);
    const { attendances } = useContext(AttendanceContext);
    const [protocolosUser, setProtocolosUser] = useState([]);
    const [attendancesUser, setAttendancesUser] = useState([])
    const [view, setView] = useState('atts');

    useEffect(() => {
        if (protocolosAtts) {
            let prots = protocolosAtts.filter((pr) => {
                if (pr.atts && pr.status === 'encerrado' && pr.atts.length > 0) {
                    return pr.atts.find(att => att._id === user._id)
                }
            })
            setProtocolosUser([...prots]);
        }
    }, [protocolosAtts]);

    useEffect(() => {
        if (attendances) {
            let atts = attendances.filter((att) => att.atendente === user._id);
            setAttendancesUser([...atts]);
        }
    }, []);

    const handleView = (view) => {
        setView(view);
    }

    const views = {
        atts: <UserAttsList attendancesUser={attendancesUser} />,
        prots: <UserProtocolosList protocolosUser={protocolosUser} />
    }

    return (
        <div className='list-user-modal' onClick={e => {
            if (!e.target.closest('.list-user-container')) {
                setAttendancesUser([]);
                setProtocolosUser([]);
                handleModal();
            }
        }}>
            <div className="list-user-container">
                <div className="list-user-container-header">
                    <div className="list-user-container-header-info">
                        <div className="list-user-container-header-info-avatar">
                            <img
                                src={user.foto ? user.foto : "/imgs/avatar2.png"}
                                className="avatar"
                                alt="avatar"
                            />
                        </div>
                        <div className="list-user-container-header-info-text">
                            <h4>{user.nome}</h4>
                            <p>{user.status}</p>
                        </div>
                    </div>
                    <BsX color={'#9ac31c'} size={35} onClick={() => handleModal()} style={{ marginBottom: '20px', cursor:'pointer' }} />
                </div>

                <div className="list-user-container-body">
                    <div className="list-user-container-body-header">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '20px' }} >
                            <button className={`list-tab-header-buttons ${view !== 'atts' ? 'activeHeader' : ''}`} onClick={() => handleView('atts')}>Atendimentos</button>
                            <button className={`list-tab-header-buttons ${view !== 'prots' ? 'activeHeader' : ''}`} onClick={() => handleView('prots')}>Protocolos</button>
                        </div>
                    </div>
                    <div className="list-user-container-body-list">
                        {views[view]}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAtedimentoModal
