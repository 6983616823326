import { useState, useEffect } from "react";
import { FaTrash, FaCog } from "react-icons/fa";
import "./style.css";

export const EspecialidadeCard = (props) => {
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState(
    []
  );
  const [especialidades, setEspecialidades] = useState([]);

  useEffect(() => {
    const novasEspecialidadesSelecionadas = [
      ...props.especialidadesSelecionadas,
    ];
    setEspecialidadesSelecionadas(novasEspecialidadesSelecionadas);
    const novasEspecialidades = [...props.especialidades];
    setEspecialidades(novasEspecialidades);
  }, [props.especialidades, props.especialidadesSelecionadas]);

  const editEspecialidade = (especialidade) => {
    props.setEditandoEspecialidade(true);

    const espec = especialidades.find((esp) => esp._id === especialidade._id);

    props.setProfissionaisEspecialidade([...espec.profissionais]);
    props.setEspecialidadeSelecionada({
      ...especialidade,
    });
  };

  const deleteEspecialidade = (index) => {
    props.setEspecialidadesSelecionadas(
      especialidadesSelecionadas.filter((_e, i) => i !== index)
    );
  };

  return (
    <>
      {especialidadesSelecionadas.map((especialidade, index) => (
        <div key={index} className="card-especialidade-local-form">
          <h2>{especialidade.nome}</h2>
          <div className="buttons-container">
            <FaCog
              onClick={() => {
                editEspecialidade(especialidade);
              }}
              className="edit-local"
              title={`Configurar ${especialidade.nome}`}
              size={"2rem"}
              style={{ color: "#2a9d8f" }}
            />
            <FaTrash
              className="delete-local"
              size={"2rem"}
              title={`Deletar ${especialidade.nome}`}
              style={{ color: "rgb(255, 19, 19)" }}
              onClick={() => deleteEspecialidade(index)}
            />
          </div>
        </div>
      ))}
    </>
  );
};
