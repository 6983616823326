import { useEffect, useState } from "react";

export const Participantes = ({ reuniao }) => {
  const [participantes, setParticipantes] = useState([]);

  useEffect(() => {
    const novosParticipantes = reuniao.participantes
      ? reuniao.participantes
      : [];
    setParticipantes(novosParticipantes);
  }, [reuniao]);

  return (
    <>
      <h1>Todos os participantes</h1>
      <ul className="historico-cards-modal-participantes">
        {participantes?.map((participante, index) => (
          <li key={index}>{participante}</li>
        ))}
      </ul>
    </>
  );
};
