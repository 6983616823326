import { useState } from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'
import * as XLSX from 'xlsx'

const RemovidosModal = (props) => {
    const { removidos, setRemovidosModal } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const handleOutSideModalClick = (e) => {
        if (!e.target.closest('.removidos-container')) {
            setRemovidosModal(false);
        }
    };

    const exportToXLS = () => {
        const worksheet = XLSX.utils.json_to_sheet(removidos);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Removidos");
        XLSX.writeFile(workbook, "removidos.xlsx");
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = removidos.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(removidos.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const generatePageNumbers = () => {
        const maxPagesToShow = 5;
        const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        if (startPage > 1) pageNumbers.unshift('...');
        if (endPage < totalPages) pageNumbers.push('...');

        return pageNumbers;
    };

    return (
        <div className='removidos-modal' onClick={(e) => handleOutSideModalClick(e)}>
            <div className="removidos-container">
                <header className="removidos-header">
                    <h3>Removidos da campanha</h3>
                </header>
                <div className="removidos-body">
                    {currentItems.length === 0 ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: '10px' }}>
                            <BsInfoCircleFill size={30} color="#9ac31c" />
                            <p>Nenhum contato foi removido da campanha</p>
                        </div>
                    ) : (
                        currentItems.map((removido, index) => (
                            <div key={index} className="removidos-body-item">
                                <p>{removido.nome}</p>
                                <p>{removido.telefone}</p>
                            </div>
                        ))
                    )}
                </div>
                {removidos.length > itemsPerPage && (
                    <div className="pagination-list">
                        <div className="page-item-list" onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}>
                            <button className="page-item-link" style={{ width: '120px', padding: '5px', marginRight: '20px' }}>Anterior</button>
                        </div>
                        {generatePageNumbers().map((number, index) => (
                            <div key={index} className="page-item-list">
                                <button
                                    className={`page-item-link ${number === currentPage ? 'active' : ''}`}
                                    onClick={() => {
                                        if (number !== '...') paginate(number);
                                    }}
                                >
                                    {number}
                                </button>
                            </div>
                        ))}
                        <div className="page-item-list" onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}>
                            <button className="page-item-link" style={{ width: '120px', padding: '5px', marginLeft: '20px' }}>Próxima</button>
                        </div>
                    </div>
                )}

                <footer className="removidos-footer">
                    <button className="cancel-button" onClick={(e) => setRemovidosModal(false)}>Fechar</button>
                    <button className="save-button" onClick={() => exportToXLS()} disabled={removidos.length === 0 ? true : false}>Exportar XLS</button>
                </footer>
            </div>
        </div>
    );
};

export default RemovidosModal;
