import React, { useEffect, useState } from 'react';
import Papa from "papaparse";
import * as XLSX from 'xlsx';

export default function Header(props) {
    const { grupo, setGrupo } = props
    const [onLoad, setOnLoad] = useState(false)
    const handleImport = (e) => {
        let file = e.target.files[0]
        if (!file) return
        let reader = new FileReader();
        reader.onload = function (e) {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });


            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            //console.log(ws)           
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            Papa.parse(data, {
                complete: function (results) {
                    let arrays = results.data
                    let headers = arrays[0]
                    console.log(headers)
                    if (!headers.includes("telefone")) {
                        alert("O arquivo não possui a coluna 'telefone'")
                        return
                    }
                    let users = results.data.slice(1)
                    let newUsers = []
                    users.forEach((u) => {
                        let user = {}
                        headers.forEach((h, i) => {
                            user[h] = u[i]
                        })
                        newUsers.push(user)
                    })
                    newUsers = newUsers.filter((u) => {
                        if(u.nome) u.nome = u.nome.trim()
                        if (!u.telefone) return false
                        else if(u.telefone.length >= 10) {
                            u.telefone = u.telefone.replace(/[^0-9]/g, '')
                            u.telefone = removeNineDigit(u.telefone)   
                            console.log(u.telefone.length)
                            return true
                        }
                        else return false
                    })
                    let newGrupo = { ...grupo }
                    console.log(newUsers)
                    newGrupo.contatos.push(...newUsers)
                    console.log(newGrupo)
                    setGrupo(newGrupo)
                }
            }
            )
            // setGrupo(newGrupo)
        }
        reader.readAsBinaryString(file);
    }
    const removeNineDigit= (telefone)=>{
        telefone = telefone.replace(/\D/g, '')
        if (telefone.length === 11){
            let telefoneP1 = telefone.slice(0,2)
            let telefoneP2 = telefone.slice(3,11)
            telefone = '55'+telefoneP1 + telefoneP2
            
        }
        return telefone
    }
    return (<>
        <div className="group-form-header">
            <h1>Grupo</h1>
        </div>

        <div className="group-form-body-header-div">
            <input type="text" value={grupo.nome}
                placeholder="Nome do Grupo"
                className="group-form-header-input"
                onChange={(e) => {
                    let newGrupo = { ...grupo }
                    newGrupo.nome = e.target.value
                    setGrupo(newGrupo)
                }} />
            <button className="import-csv-button" onClick={e => {
                e.preventDefault()
                document.getElementById("csv-input").click()
            }}>Importar CSV</button>
            <input type="file" id="csv-input" style={{ display: "none" }}
                onClick={e => e.target.value = null}
                onChange={handleImport} />

        </div>
    </>

    )
}
