import React from "react";

export default function HeaderEdit() {
  return (
    <>
      <div className="header-edit-modal">
        <h4>Editar contato</h4>
        <p>Atualize os dados conforme necessário.</p>
      </div>
    </>
  );
}
