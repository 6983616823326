import { useEffect, useState, useContext } from "react";
import "./style.css";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import ApiUsers from "../../../../ApiUsers";
import { ProfessionalContext } from "../../../../contexts/ProfessionalProvider";
import Select from "react-select";

export const EspecialidadeForm = (props) => {
  const [especialidade, setEspecialidade] = useState({
    _id: "",
    nome: "",
    profissionais: [],
  });

  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState(
    []
  );

  const professionalContext = useContext(ProfessionalContext);
  const options = professionalContext.profissionais.map((profissional) => ({
    value: profissional._id,
    label: profissional.nome,
  }));

  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    let novaEspecialidade = { ...props.especialidade };
    const profissionaisFiltrados = professionalContext.profissionais.filter(
      (profissional) =>
        props.especialidade.profissionais.includes(profissional._id)
    );
    setEspecialidade(novaEspecialidade);
    setProfissionaisSelecionados(
      profissionaisFiltrados.map((profissional) => ({
        value: profissional._id,
        label: profissional.nome,
      }))
    );
  }, [professionalContext.profissionais, props.especialidade]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  const validateProfissional = () => {
    let qtdProfissional = profissionaisSelecionados.map(
      (profissional) => profissional
    );

    if (!qtdProfissional.length) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, adicione pelo menos um profissional.",
      });
      return false;
    }
    return true;
  };

  const validateEspecialidade = () => {
    if (!especialidade.nome) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, preencha todos os campos.",
      });
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateEspecialidade()) {
      return;
    }
    if (!validateProfissional()) {
      return;
    }

    const idProfissional = profissionaisSelecionados.map(
      (profissional) => profissional.value
    );

    ApiUsers.post("/especialidades/nova", {
      especialidade: {
        ...especialidade,
        profissionais: [...idProfissional],
      },
    }).catch((err) => {
      console.log(err);
    });
    props.setSelecionado(null);
  };

  const onSubmitUpdate = (e) => {
    const idProfissional = profissionaisSelecionados.map(
      (profissional) => profissional.value
    );
    e.preventDefault();
    if (!validateEspecialidade()) {
      return;
    }

    if (!validateProfissional()) {
      return;
    }

    especialidade.profissionais = [...idProfissional];

    props.updateEspecialidade(especialidade);
    props.setSelecionado(null);
    props.setEditando(false);
  };

  const onChange = ({ target: { name, value } }) => {
    setEspecialidade((prevState) => ({
      ...prevState,
      [name]: value,
      ...prevState.especialidade,
    }));
  };

  const onChangeSelect = (profissionaisOptions) => {
    const profissionaisFiltrados = professionalContext.profissionais.filter(
      (profissional) =>
        props.especialidade.profissionais.includes(profissional._id)
    );
    for (let i = 0; i < profissionaisFiltrados.length; i++) {
      let index = profissionaisOptions.indexOf(profissionaisFiltrados[i]);
      if (especialidade.profissionais.indexOf(profissionaisOptions[i]) === -1) {
        profissionaisFiltrados.splice(index, 1);
        setProfissionaisSelecionados(profissionaisFiltrados);
      }
    }
    setProfissionaisSelecionados(profissionaisOptions);
  };

  const handleBack = () => {
    props.setSelecionado(null);
    props.setEditando(false);
  };

  return (
    <form
      className="especialidade-form"
      onSubmit={props.editando ? onSubmitUpdate : onSubmit}
    >
      {alert.visible && alertType[alert.type]}
      <div className="especialidade-form-title">ESPECIALIDADE</div>
      <div className="especialidade-form-content">
        <div className="especialidade-form-input-container">
          <label htmlFor="nome" className="required-prof">
            Nome
          </label>
          <input
            type="text"
            id="nome"
            value={especialidade.nome}
            onChange={onChange}
            name="nome"
          />
        </div>
        <div className="especialidade-form-input-container">
          <label htmlFor="nome" className="required-prof">
            Profissionais
          </label>
          <Select
            options={options}
            isMulti
            value={profissionaisSelecionados}
            onChange={onChangeSelect}
            noOptionsMessage={() => "Sem Profissional"}
            placeholder={"Selecione..."}
          />
        </div>
        <div className="button-container">
          <button id="back" type="button" onClick={handleBack}>
            Voltar
          </button>
          <button type="submit" id="add">
            Salvar
          </button>
        </div>
      </div>
    </form>
  );
};
