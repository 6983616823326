import React, { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserProvider";
import { ChatBotProvider } from "../contexts/ChatBotProvider/ChatBotProvider";
import { AgendaProvider } from "../contexts/AgendaProvider";
import { CalendarioProvider } from "../contexts/CalendarioProvider";
import { CidadaoProvider } from "../contexts/CidadaoProvider";
import { VideoProvider } from "../contexts/VideoProvider";
import { QSGProvider } from "../contexts/QSGProvider/QSGProvider";
import { ReplyForwardingProvider } from "../contexts/ReplyForwardingProvider";
import ReconnectAlert from "../components/miscelaneous/ReconnectAlert";
import Sidebar from "../components/SideBar";

export default function Authenticator({ children }) {
  const context = useContext(UserContext);
  let authed = context.auth;
  let reconnectAlert = context.reconnectAlert;
  //let token = document.cookie.split("=")[1]
  let location = useLocation();
  //let path = location.pathname

  return (
    <>
      {authed === true ? (
        <>
          {reconnectAlert && <ReconnectAlert />}
          <ChatBotProvider>
            <CalendarioProvider>
              <AgendaProvider>
                <CidadaoProvider>
                  <ReplyForwardingProvider>
                    <VideoProvider>
                      <QSGProvider>
                        <Sidebar />
                        {children}
                      </QSGProvider>
                    </VideoProvider>
                  </ReplyForwardingProvider>
                </CidadaoProvider>
              </AgendaProvider>
            </CalendarioProvider>
          </ChatBotProvider>
        </>
      ) : (
        <>
          <Navigate to="/login" replace state={{ path: location.pathname }} />
        </>
      )}
    </>
  );
}
