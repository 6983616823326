export const Date = ({ label, value, setValue, inputKey, type, subKeys }) => {
  const onChange = (e) => {
    setValue((prev) => ({ ...prev, [inputKey]: e.target.value }));
  };

  const onChangeObject = (e) => {
    setValue((prev) => ({ ...prev, [inputKey]: { ...prev[inputKey], [e.target.name]: e.target.value } }));
  };

  return (
    <>
      {type === "object" ? (
        <div className="qsg-agenda-form-input-container obj">
          <legend htmlFor={inputKey}>{label}</legend>
          <div className="qsg-agenda-form-input-wrapper">
            {subKeys.map(
              (subKey, index) =>
                subKey.inputType === "date" && (
                  <div key={index} className="qsg-agenda-form-input-group">
                    <label htmlFor={subKey.key}>{subKey.label}</label>
                    <input
                      type="date"
                      value={value && value[subKey.key] ? value[subKey.key] : ""}
                      onChange={onChangeObject}
                      id={subKey.key}
                      name={subKey.key}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      ) : (
        <div className="qsg-agenda-form-input-container">
          <label htmlFor={inputKey}>{label}</label>
          <input
            type="date"
            value={value ? value : ""}
            onChange={onChange}
            id={inputKey}
            name={inputKey}
          />
        </div>
      )}
    </>
  );
};
