import React from 'react'
import GaugeMessage from './components/gaugeMessage'
import CardData from './components/cardData'
import './gauge-message.css'


export default function index(props) {
  return (
    <div className="gauge-pattern">
        <GaugeMessage {...props} />
        <CardData {...props} />
      
    </div>
  )
}
