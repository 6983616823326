import React, { useState, useEffect, useContext } from "react";
import { QSGAgendaContext } from "../../contexts/QSGProvider/QSGAgendaProvider";
import { QsgAgenda } from "./Agenda";
import { BsCheck2 } from "react-icons/bs";
import { Alert } from "../miscelaneous/Alert";
import "./styles.css";

export const QSG = () => {
  const { criarAgenda, sairAgenda } = useContext(QSGAgendaContext);
  const url = window.location.href;
  const qsg = url.split("/qsg/")[1];
  const [headerHeightState, setHeaderHeightState] = useState(0);
  const [step, setStep] = useState(0);
  const [isStepCompleted, setIsStepCompleted] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertExit, setShowAlertExit] = useState(false);

  const exitType = {
    confirm: (
      <Alert
        title="Sair"
        message="Deseja realmente sair? Todas as alterações serão perdidas."
        confirmLabel="Confirmar"
        alertType={alertType}
        action={sairAgenda}
        quit={() => setShowAlertExit(false)}
      />
    ),
  };

  const handleSair = () => {
    setAlertType("confirm");
    setShowAlertExit(true);
  };

  const components = {
    agenda: {
      component: (
        <QsgAgenda
          step={step}
          setStep={setStep}
          setIsStepCompleted={setIsStepCompleted}
        />
      ),
      header: "Agenda <span>rápida</span>",
      steps: ["Profissionais", "Especialidades", "Locais", "Serviços", "Resumo"],
      concluir: () => {
        criarAgenda();
      },
      sair: () => {
        handleSair();
      },
    },
  };

  useEffect(() => {
    const headerHeight = document.querySelector(".qsg-header")?.offsetHeight;
    setHeaderHeightState(headerHeight);
  }, []);

  return (
    <>
      {showAlertExit && exitType[alertType]}
      <div className="qsg-container">
        <div className="qsg-header">
          <div className="qsg-header-content">
            <img
              src="/imgs/v_logo_vox.svg"
              alt="logo"
            />
            <h2 dangerouslySetInnerHTML={{ __html: components[qsg].header }} />
          </div>
          <button
            className="qsg-button quit"
            onClick={components[qsg].sair}>
            Sair
          </button>
        </div>
        <div
          className="qsg-wrapper"
          style={{ maxHeight: `calc(100% - ${headerHeightState}px)` }}>
          <div className="qsg-steps-container">
            {components[qsg].steps.map((stepText, index) => (
              <React.Fragment key={index}>
                <p className={index < step ? "qsg-step-text text-completed" : index === step ? "qsg-step-text text-current" : "qsg-step-text"}>
                  <span className={index < step ? "qsg-step completed" : index === step ? "qsg-step current" : "qsg-step"}>{index < step ? <BsCheck2 size={28} /> : index + 1}</span>
                  {stepText}
                </p>
                {index !== components[qsg].steps.length - 1 && <hr className="qsg-step-separator" />}
              </React.Fragment>
            ))}
          </div>
          <div className="qsg-component-wrapper">
            <div className="qsg-component-container">{components[qsg].component}</div>
            <div className="qsg-button-container">
              {step === 0 ? (
                <button
                  className="qsg-button quit"
                  onClick={components[qsg].sair}>
                  Cancelar
                </button>
              ) : (
                <button
                  className={"qsg-button"}
                  onClick={() => setStep(step - 1)}>
                  Voltar
                </button>
              )}
              <div className="qsg-button-group">
                {step === components[qsg].steps.length - 1 ? (
                  <button
                    onClick={components[qsg].concluir}
                    className="qsg-button">
                    Concluir
                  </button>
                ) : (
                  <>
                    <button
                      onClick={components[qsg].concluir}
                      className="qsg-button">
                      Concluir
                    </button>
                    <button
                      className={isStepCompleted ? "qsg-button" : "qsg-button-disabled"}
                      onClick={isStepCompleted ? () => setStep(step + 1) : () => null}>
                      Próximo
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
