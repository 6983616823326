import React from 'react';

export default function ButtonsCrud(props) {
    return <>
            {props.buttons.map((button, index) => {
                return (
                    <button key={index} disabled={button.disabled} title={button.title} name={button.action} className={button.class} onClick={(e) => props.handle[button.action](e)} >
                        {button.icon}
                        <span> {button.title} </span>
                    </button>
                )
            })}
    </>
}