import { createContext, useContext, useEffect, useState } from "react";
import { PrefContext } from "./ChatBotProvider/PrefProvider";

export const ProfessionalContext = createContext();

export const ProfessionalProvider = ({ children }) => {
  const prefContext = useContext(PrefContext);
  const [profissionais, setProfissionais] = useState([]);

  useEffect(() => {
    const novosProfissionais = prefContext.users.filter(
      (prof) => prof.profissional
    );
    setProfissionais([...novosProfissionais]);
  }, [prefContext.users]);

  return (
    <ProfessionalContext.Provider value={{ profissionais }}>
      {children}
    </ProfessionalContext.Provider>
  );
};
