import { Route, Routes } from "react-router";
import Login from "../components/Login/loginRefatored";
import Forgot from "../components/Login/ForgotRefatored";
import ChangeForgotPassword from "../components/Login/ChangeForgotPasswordRefatored";
import Authenticator from "./Authenticator";
import Chat from "../components/Chats/Chat";
import Chatgroup from "../components/Chats/ChatGroup";
import Config from "../components/Configurações/Config";
import ChatAtts from "../components/Chats/ChatAtts";
import VoxTool from "../components/WhatsAppTool/VoxTool";
import VoxRedirect from "./VoxRedirect";
import FirstAccess from "../components/Login/FirstAccessRefatored";
import Registers from "../components/Login/Registers";
import VideoConferencia from "../components/videoConferencia/index";
import Home from "../components/Home/index";
import Profile from "../components/Profile/index";
import { Agenda } from "../components/Agenda/Agenda";
import { Profissional } from "../components/Profissional/Profissional";
import { QSG } from "../components/QSG";

export default function Routeror(props) {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/voxcity:id" element={<VoxRedirect />} />
      <Route path="/" element={<Authenticator />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/firstAccess/:id" element={<FirstAccess />} />
      <Route path="/changeforgot/:id" element={<ChangeForgotPassword />} />
      <Route path="/register/:param1/:param2" element={<Registers />} />

      <Route
        path="/home"
        element={
          <Authenticator>
            {" "}
            <Home />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/profile"
        element={
          <Authenticator>
            {" "}
            <Profile />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/config"
        element={
          <Authenticator>
            {" "}
            <Config />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/atendimentos"
        element={
          <Authenticator>
            {" "}
            <ChatAtts />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/voxtool"
        element={
          <Authenticator>
            {" "}
            <VoxTool />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/grupos"
        element={
          <Authenticator>
            {" "}
            <Chatgroup />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/chat"
        element={
          <Authenticator>
            {" "}
            <Chat />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/agenda"
        element={
          <Authenticator>
            <Agenda />
          </Authenticator>
        }
      />
      <Route
        path="/profissional"
        element={
          <Authenticator>
            <Profissional />
          </Authenticator>
        }
      />
      <Route
        path="/videoConferencia"
        element={
          <Authenticator>
            {" "}
            <VideoConferencia />{" "}
          </Authenticator>
        }
      />
      <Route
        path="/qsg/*"
        element={
          <Authenticator>
            {" "}
            <QSG />{" "}
          </Authenticator>
        }
      />
    </Routes>
  );
}
