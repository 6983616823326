import React from "react";

const SelectView = ({ view, setView }) => {
  return (
    <div className="container-space-select">
      <select
        className="select-contact"
        value={view}
        onChange={(e) => setView(e.target.value)}
      >
        <option value="contatos">Contatos</option>
        <option value="grupos">Grupos</option>
      </select>
    </div>
  );
};

export default SelectView;
