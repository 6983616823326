import React from "react";
import "./buttonAtendentes.css";

export default function ButtonAtendentes({ onClick, attandancesCount }) {
  return (
    <div className="container-button-adicionar-atendentes">
      <div className="container-align-button">
        <button className="button-adicionar-atendentes" onClick={onClick}>
          Adicionar Atendentes
        </button>
        <p> {`${attandancesCount ? attandancesCount : 0} atendentes anexados`}</p>
      </div>
    </div>
  );
}
