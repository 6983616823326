import { FaTrash, FaEdit, FaInfo } from "react-icons/fa";

export const accordionSections = {
  locais: {
    label: "Locais",
    key: "locais",
    actions: [
      {
        label: "Editar",
        action: (item, callback) => {
          callback(item, "editar", "local");
        },
        icon: <FaEdit />,
      },
      {
        label: "Excluir",
        action: (item, callback) => {
          callback(item, "excluir", "local");
        },
        icon: <FaTrash />,
      },
      {
        label: "Informações",
        action: (item, callback) => {
          callback(item, "informacoes", "local");
        },
        icon: <FaInfo />,
      },
    ],
    keys: [
      {
        label: "Nome",
        key: "nome",
      },
    ],
  },
  especialidades: {
    label: "Especialidades",
    key: "especialidades",
    actions: [
      {
        label: "Editar",
        action: (item, callback) => {
          callback(item, "editar", "config.especialidade");
        },
        icon: <FaEdit />,
      },
      {
        label: "Excluir",
        action: (item, callback) => {
          callback(item, "excluir", "config.especialidade");
        },
        icon: <FaTrash />,
      },
      {
        label: "Informações",
        action: (item, callback) => {
          callback(item, "informacoes", "config.especialidade");
        },
        icon: <FaInfo />,
      },
    ],
    keys: [
      {
        label: "Nome",
        key: "nome",
      },
    ],
  },
  profissionais: {
    label: "Profissionais",
    key: "profissionais",
    actions: [
      {
        label: "Editar",
        action: (item, callback) => {
          callback(item, "editar", "config.profissional");
        },
        icon: <FaEdit />,
      },
      {
        label: "Excluir",
        action: (item, callback) => {
          callback(item, "excluir", "config.profissional");
        },
        icon: <FaTrash />,
      },
      {
        label: "Informações",
        action: (item, callback) => {
          callback(item, "informacoes", "config.profissional");
        },
        icon: <FaInfo />,
      },
    ],
    keys: [
      {
        label: "Nome",
        key: "nome",
      },
    ],
    //     items: [
    //   {
    //     label: "Espaço de Agendamento",
    //     key: "espacoAgendamento",
    //   },
    //   {
    //     label: "Horário de Intervalo",
    //     key: "horarioIntervalo",
    //     subKeys: [
    //       {
    //         label: "Início",
    //         key: "inicio",
    //       },
    //       {
    //         label: "Fim",
    //         key: "fim",
    //       },
    //       {
    //         label: "Dias",
    //         key: "dias",
    //       },
    //     ],
    //   },
    // ],
  },
};
