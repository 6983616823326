import React from "react";

const ButtonNewBot = ({ loading, showModal }) => {
  return (
    <div className="container-button-bots">
      {!loading && (
        <button
          className="bot-button bot-create-btn"
          title="Criar Bot"
          disabled={loading}
          onClick={() => showModal("bot", "create")}
        >
          Criar Bot
        </button>
      )}

      {loading && <div className="load" />}
    </div>
  );
};

export default ButtonNewBot;
