import { useEffect, useState, useContext } from "react";
import UsersModal from "./usersModal";
import { BsEyeFill } from "react-icons/bs";
import { LoadingSpinner } from "../../../miscelaneous/Loader/load";

export default function TableShow(props) {
  const { colsName, data, loading, grupos } = props;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataShow, setDataShow] = useState([]);
  const [grupoSelecionado, setGrupoSelecionado] = useState({});

  useEffect(() => {
    if (data) {
      setDataShow(data);
    }
  }, [data]);

  useEffect(() => {
    let usersTotal = dataShow.length;
    let pages = Math.ceil(usersTotal / limit);
    if (page > pages) {
      setPage(1);
    }
  }, [dataShow]);

  const showUsers = (id) => {
    let newGrupo = grupos.find((dep) => dep._id === id);
    setGrupoSelecionado(newGrupo);
  };

  const closeUsersModal = () => {
    setGrupoSelecionado({});
  };

  return (
    <div className="table-container">
      {loading ? (
        <div className="container-loader">
          <LoadingSpinner showModal="none" />
        </div>
      ) : (
        <>
          {grupoSelecionado?.usuarios && (
            <UsersModal grupo={grupoSelecionado} close={closeUsersModal} />
          )}
          <table className="table-show">
            <thead>
              <tr>
                {colsName.map((col, index) => {
                  return <th key={index}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {sliceArrayObjts(data, page, limit).map((row, index) => {
                return (
                  <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                    {colsName.map((col, index) => {
                      if (col === "Usuários")
                        return (
                          <td key={index} onClick={() => showUsers(row._id)}>
                            <div className="green-box">
                              <BsEyeFill size={20} />
                            </div>
                          </td>
                        );

                      return <td key={index}>{row[colsName[index]]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            users={dataShow}
            page={page}
            limit={limit}
            setLimit={setLimit}
            setPage={setPage}
          />
        </>
      )}
    </div>
  );
}

const sliceArrayObjts = (users, page, limit) => {
  let start = (page - 1) * limit;
  let end = start + limit;
  return users.slice(start, end);
};

const Pagination = (props) => {
  const { users, page, limit, setLimit, setPage } = props;
  const pages = Math.ceil(users.length / limit);
  let pageArray = [];
  for (let i = 1; i <= pages; i++) {
    pageArray.push(i);
  }
  return (
    <div className="pagi-btns">
      {/* <button onClick={() => setPage(1)} disabled={page === 1}>Primeira</button>
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>Anterior</button> */}
      {pageArray.map((p, index) => {
        return (
          <button key={index} onClick={() => setPage(p)} disabled={page === p}>
            {p}
          </button>
        );
      })}
      {/* <button onClick={() => setPage(page + 1)} disabled={page === pages}>Próxima</button>
            <button onClick={() => setPage(pages)} disabled={page === pages}>Última</button> */}
    </div>
  );
};
