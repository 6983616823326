import Routeror from "./router/Routeror";
import { UserProvider, UserContext } from "./contexts/UserProvider";
import { useEffect, useState, useContext } from "react";
import env from "react-dotenv";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

export default function App() {
  function redirectHttps() {
    let dev = env ? (env.DEV === "true" ? true : false) : true;
    if (window.location.protocol !== "https:" && !dev) {
      window.location.href = window.location.href.replace("http", "https");
    }
  }

  return (
    <div className="App">
      <UserProvider>
        {redirectHttps() &&
          console.log(
            new Date().toLocaleString() + " - " + "Redirected to https"
          )}
        <audio
          id="noti-sound"
          src="https://chat.voxcity.com.br/sounds/switch-2.mp3"
        />
        <audio
          id="new-att-sound"
          src="https://chat.voxcity.com.br/sounds/notify.mp4"
        />

        <Routeror />
      </UserProvider>
    </div>
  );
}
