import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { FaBuilding, FaWarehouse } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoIosRemoveCircleOutline } from "react-icons/io";

export default function CheckboxList(props) {
  const { itsUsers, users, selectedUsers } = props;

  return (
    <>
      <div className="checkbox-list">
        <button
          className="checkbox-list-button"
          style={{ backgroundColor: `${itsUsers ? "#c23838" : "#008000"}` }}
          onClick={() => props.save()}
        >
          <div className="checkbox-list-button-content">
            {itsUsers ? (
              <>
                <IoIosRemoveCircleOutline />
                <span> Remover </span>
              </>
            ) : (
              <>
                <IoAddCircleOutline />
                <span> Adicionar </span>
              </>
            )}
          </div>
        </button>

        <div className="checkbox-list-details">
          <span className="checkbox-list-details-span">
            Total: {users.length}{" "}
          </span>
          <span className="checkbox-list-details-span">
            Total Selecionados: {selectedUsers.length}{" "}
          </span>
          <span className="checkbox-list-details-span">
            Selecionar Todos:{" "}
            <input
              type="checkbox"
              className="checkbox"
              checked={selectedUsers.length === 0 ? false : null}
              onChange={(e) => props.selectAll(e.target.checked)}
            />{" "}
          </span>
        </div>

        <div className="checkbox-list-users">
          {users.length > 0 ? (
            <>
              {users.map((u, i) => {
                let userCol = {
                  nome: u.nome,
                  email: u.email,
                  departamento: u.departamentoNome,
                  setor: u.setorNome,
                };
                return (
                  <div
                    key={i}
                    className="checkbox-list-users-items"
                    style={{
                      background: `${
                        selectedUsers.some((user) => user.nome === u.nome)
                          ? `${
                              itsUsers ? "rgb(255 195 195)" : "rgb(183 227 189)"
                            }`
                          : "white"
                      }`,
                    }}
                  >
                    <div classNAme="checkbox-users-avatar">
                      <img
                        className="contact-avatar"
                        src={u.foto ? u.foto : "/imgs/avatar2.png"}
                        alt="avatar"
                        style={{ margin: 5 }}
                      />
                    </div>
                    <div className="checkbox-users-info">
                      {Object.entries(userCol).map(([k, v]) => {
                        const Icon = {
                          nome: (
                            <FaUserAlt className="admin-checkbox-users-icon" />
                          ),
                          email: (
                            <MdAlternateEmail className="admin-checkbox-users-icon" />
                          ),
                          setor: (
                            <FaWarehouse className="admin-checkbox-users-icon" />
                          ),
                          departamento: (
                            <FaBuilding className="admin-checkbox-users-icon" />
                          ),
                        };
                        return (
                          <span key={k} className="admin-checkbox-users-span">
                            {" "}
                            {Icon[k]} {v !== "" ? v : `Sem ${k}`}{" "}
                          </span>
                        );
                      })}
                    </div>
                    <div className="checkbox-users-checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedUsers.some(
                          (user) => user.nome === u.nome
                        )}
                        onChange={(e) => props.selectUser(e.target.checked, u)}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 100 }}
                  src="/imgs/emptyusers.png"
                  alt="empty"
                />
                <span style={{ padding: 10 }}> Sem usuários </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
