import { useState, useEffect } from "react";
import "./style.css";

export const ProfissionalCard = (props) => {
  const [profissional, setProfissional] = useState({});

  useEffect(() => {
    const novoProfissional = { ...props.profissional };
    setProfissional(novoProfissional);
  }, [props.profissional]);

  let userColA = {
    Nome: profissional.nome,
    Email: profissional.email,
    Telefone: profissional.telefone,
    Departamento: profissional.departamentoNome,
    Setor: profissional.setorNome,
  };

  return (
    <div
      className="prof-card"
      key={props.index}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          className="contact-avatar"
          style={{ width: 80, height: 80 }}
          src={profissional.foto ? profissional.foto : "/imgs/avatar2.png"}
          alt="avatar"
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: 3,
          flexDirection: "column",
          padding: 10,
          alignItems: "flex-start",
        }}
      >
        {Object.entries(userColA).map(([k, v]) => {
          return (
            <span key={k}>
              <b>{k}: </b>
              {v !== "" ? v : `Sem ${k}`}
            </span>
          );
        })}
      </div>
    </div>
  );
};
