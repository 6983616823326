import React, { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserProvider";
import ApiUsers from "../ApiUsers";

function VoxRedirect() {
  const context = useContext(UserContext);

  useEffect(() => {
    let address = window.location.pathname;
    let conta = address.slice(8);
    if (conta.trim() === "") return;
    ApiUsers.post("/getUserByConfigConta", { conta })
      .then((resp) => {
        if (resp.data.error) {
          console.log(resp.data.error);
          return;
        }
        context.logIn(resp.data);
      })
      .catch((err) => {
        console.log(err, err.response);
      });
  }, []);

  return <div>Aguarde...</div>;
}

export default VoxRedirect;
