import { useEffect, useState, useContext } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import DeleteAlert from "../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import { LocationContext } from "../../../../contexts/LocationProvider";
import "./style.css";

export const ServicoCard = (props) => {
  const [servico, setServico] = useState({
    _id: "",
    nome: "",
    locais: [],
  });

  const locationContext = useContext(LocationContext);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    delete: (
      <DeleteAlert
        id={alert.props.id}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };

  const locaisFiltrados = locationContext.locais.filter((local) =>
    servico.locais.includes(local._id)
  );

  useEffect(() => {
    let novoServico = { ...props.servico };
    setServico(novoServico);
  }, [props.servico]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  function handleDelete() {
    props.deleteServico(servico);
    quitAlert();
  }

  return (
    <div className="card-servico">
      {alert.visible && alertType[alert.type]}
      <div className="card-servico-content">
        <div className="card-servico-content-left">
          <h3>{servico.nome}</h3>
          <h6>Locais:</h6>
          <ul>
            {locaisFiltrados.map((locais, index) => (
              <li key={index}>{locais.nome}</li>
            ))}
          </ul>
        </div>
        <div className="card-content-right">
          <FaEdit
            onClick={() => props.editServico(servico)}
            className="edit-servico"
            title="Editar Local"
            size={"2rem"}
            style={{ color: "#2a9d8f" }}
          />

          <FaTrash
            className="delete-servico"
            size={"2rem"}
            title="Deletar Local"
            style={{ color: "rgb(255, 19, 19)" }}
            onClick={() =>
              createAlert("delete", {
                nome: servico.nome,
                action: () => handleDelete(),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
