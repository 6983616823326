import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/UserProvider";
import { useNavigate } from "react-router-dom";

const LinkNormal = ({
  titulo,
  icon,
  link,
  label,
  sideBarOpen,
  activeItem,
  onItemClick,
}) => {
  const { logout } = useContext(UserContext);
  const isActive = activeItem === label;
  let ativo = window.location.pathname === link;
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === link) {
      onItemClick(label);
    }
  }, []);

  const handleClick = () => {
    onItemClick(label);
    navigate(link);
  };


  return (
    <>
      {label === "Sair" ? (
        <div
          onClick={() => logout()}
          className={sideBarOpen ? "logout-div" : "logout-div-close"}
          title={titulo}
        >
          {icon}
          <p>{sideBarOpen && label}</p>
        </div>
      ) : (
        <div className="link-wrapper">
          <li
            onClick={handleClick}
            className={
              isActive && ativo ? `link-ativo link-before-wrapper` : null
            }
          >
            <Link to={link} title={titulo}>
              {icon}
              {sideBarOpen ? label : null}
            </Link>
          </li>
        </div>
      )}
    </>
  );
};

export default LinkNormal;
