import React from "react";

export default function InputSearch({ onChange }) {
  return (
    <div className="container-search-table">
      <input
        type="text"
        placeholder="Pesquisar"
        style={{ width: "100%" }}
        onChange={onChange}
      />
    </div>
  );
}
