import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

const SelectUserForm = (props) => {
  const prefContext = useContext(PrefContext);
  const departamentos = prefContext.departamentos;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.field === "setor") {
      if (props.dep.length > 0) {
        let departamento = departamentos.find((dep) => dep._id === props.dep);
        setOptions(departamento ? departamento.setores : []);
      }
    }
    if (props.field === "departamento") setOptions(departamentos);
  }, [props.dep]);

  return (
    <Row style={{ marginTop: "25px", padding: 0 }}>
      <Col md={3} style={{ display: "flex" }}>
        <Form.Label
          className={props.field === "departamento" ? "required" : ""}
        >
          {props.field}:
        </Form.Label>
      </Col>

      <Col md={9}>
        <Form.Select
          value={props.value}
          name={props.field}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.handleChange}
        >
          <option value={""}>Selecione</option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option._id}>
                {" "}
                {option.nome}
              </option>
            );
          })}
        </Form.Select>
      </Col>
    </Row>
  );
};

export default SelectUserForm;
