import React, { useEffect, useState } from "react";
import TableContact from "./TableContact";
import TableRight from "./TableRight";
import ButtonsTransfer from "./ButtonsTransfer";
import TableGroup from "./TableGroup";
import "./table.css";

export default function Table({
  setContatos,
  attendancesList,
  myContatosList,
  groupList,
  fidelizadosList,
  view,
  importedNumbers,
}) {
  const [attendancesOnShow, setAttendancesOnShow] = useState([]);
  const [MyContatosOnShow, setMyContatosOnShow] = useState([]);
  const [search, setSearch] = useState("");
  const [searchRigth, setSearchRigth] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [selectedFromLeft, setSelectedFromLeft] = useState([]);
  const [selectedFromRight, setSelectedFromRight] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleSelectAllLeft = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelectedFromLeft = attendancesOnShow;
      setSelectedFromLeft(newSelectedFromLeft);
    } else {
      setSelectedFromLeft([]);
    }
  };

  const handleSelectAllRight = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelectedFromRight = MyContatosOnShow;
      setSelectedFromRight(newSelectedFromRight);
    } else {
      setSelectedFromRight([]);
    }
  };

  const handleSelectLeft = (contato) => {
    const newSelectedFromLeft = contato;
    if (selectedFromLeft.includes(newSelectedFromLeft)) {
      setSelectedFromLeft(
        selectedFromLeft.filter((item) => item !== newSelectedFromLeft)
      );
    } else {
      setSelectedFromLeft([...selectedFromLeft, newSelectedFromLeft]);
    }
  };

  const handleSelectRight = (contato) => {
    const newSelectedFromRight = contato;
    if (selectedFromRight.includes(newSelectedFromRight)) {
      setSelectedFromRight(
        selectedFromRight.filter((item) => item !== newSelectedFromRight)
      );
    } else {
      setSelectedFromRight([...selectedFromRight, newSelectedFromRight]);
    }
  };

  const handleLeftToRight = () => {
    const newMyContatosOnShow = [...selectedFromLeft, ...MyContatosOnShow];
    console.log(newMyContatosOnShow);
    setMyContatosOnShow(newMyContatosOnShow);
    setAttendancesOnShow(
      attendancesOnShow.filter((item) => !selectedFromLeft.includes(item))
    );
    setSelectedFromLeft([]);
  };

  const handleRightToLeft = () => {
    if (view === "grupos") {
      const newGroupContacts = MyContatosOnShow.filter((contact) => {
        return !selectedFromRight.some(
          (item) => item.telefone === contact.telefone
        );
      });
      setMyContatosOnShow(newGroupContacts);
    } else {
      const newAttendanceOnShow = [...selectedFromRight, ...attendancesOnShow];
      setAttendancesOnShow(newAttendanceOnShow);
      setMyContatosOnShow(
        MyContatosOnShow.filter((item) => !selectedFromRight.includes(item))
      );
    }
    setSelectedFromRight([]);
  };

  //// FUNCOES PARA GRUPOS /////
  const handleSelectGroup = (groupId) => {
    const newGroupId = groupId;
    if (selectedGroups.includes(newGroupId)) {
      setSelectedGroups(selectedGroups.filter((item) => item !== newGroupId));
    } else {
      setSelectedGroups([newGroupId, ...selectedGroups]);
    }
  };

  const handleGroupsToRight = () => {
    let contacts = [];
    selectedGroups.map((groupId) => {
      const group = groupList.find((group) => group._id === groupId);
      const contactsGroup = group.contatos.map((contact) => ({
        nome: contact.nome,
        telefone: contact.telefone,
        pushname: contact.pushname,
      }));
      contacts.push(...contactsGroup);
    });
    const contactsFilters = contacts.filter((contact) => {
      return !MyContatosOnShow.some(
        (item) => item.telefone === contact.telefone
      );
    });
    setMyContatosOnShow([...contactsFilters, ...MyContatosOnShow]);
    setSelectedGroups([]);
  };

  const handleSelectAllGroup = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelectedGroupIds = groupList.map((group) => group._id);
      setSelectedGroups(newSelectedGroupIds);
    } else {
      setSelectedGroups([]);
    }
  };

  useEffect(() => {
    setContatos(MyContatosOnShow);
  }, [MyContatosOnShow]);

  const table = {
    contatos: (
      <TableContact
        attendancesOnShow={attendancesOnShow}
        setAttendancesOnShow={setAttendancesOnShow}
        search={search}
        setSearch={setSearch}
        handleSelectLeft={handleSelectLeft}
        selectedFromLeft={selectedFromLeft}
        handleSelectAllLeft={handleSelectAllLeft}
      />
    ),
    grupos: (
      <TableGroup
        groupList={groupList}
        selectedGroups={selectedGroups}
        searchGroup={searchGroup}
        setSearchGroup={setSearchGroup}
        handleSelectGroup={handleSelectGroup}
        handleSelectAllGroup={handleSelectAllGroup}
      />
    ),
    fidelizados:(
      <TableContact
        attendancesOnShow={attendancesOnShow}
        setAttendancesOnShow={setAttendancesOnShow}
        search={search}
        setSearch={setSearch}
        handleSelectLeft={handleSelectLeft}
        selectedFromLeft={selectedFromLeft}
        handleSelectAllLeft={handleSelectAllLeft}
      />
    )
  };

  useEffect(() => {
    if (attendancesList) {
      const filteredList = attendancesList.filter((contact) => {
        return !myContatosList.some(
          (item) => item.telefone === contact.telefone
        );
      });
      const newAttendance = filteredList.map((attendance) => ({
        nome: attendance.nome,
        telefone: attendance.telefone,
        pushname: attendance.pushname,
      }));
      const unique = newAttendance.filter((v, i, a) => a.findIndex(t => (t.telefone === v.telefone)) === i);
      setAttendancesOnShow(unique);
    }
  }, [attendancesList]);

  useEffect(() => {
    if (myContatosList) {
      const newMyContatosOnShow = myContatosList.map((contato) => ({
        nome: contato.nome,
        telefone: contato.telefone,
        pushname: contato.pushname,
      }));
      const unique = newMyContatosOnShow.filter((v, i, a) => a.findIndex(t => (t.telefone === v.telefone)) === i);
      setMyContatosOnShow(unique);
    }
  }, [myContatosList]);

  useEffect(() => {
    if (importedNumbers) {
      const newImportedNumbers = importedNumbers.map((contato) => {
        const { Numero, ...rest } = contato;
        return {
          ...rest,
          telefone: Numero,
          pushname: ''
        };
      });
      const newMyContatosOnShow = [...newImportedNumbers, ...MyContatosOnShow];
      const unique = newMyContatosOnShow.filter((v, i, a) => a.findIndex(t => (t.telefone === v.telefone)) === i);
      setMyContatosOnShow(unique);
    }
  }, [importedNumbers]);

  useEffect(() => {
    if (fidelizadosList) {
      const newFidelizadosList = fidelizadosList.map((contato) => ({
        nome: contato.nome,
        telefone: contato.telefone,
        pushname: contato.pushname,
      }));
      const unique = newFidelizadosList.filter((v, i, a) => a.findIndex(t => (t.telefone === v.telefone)) === i);
      setAttendancesOnShow(unique);
    }
  }, [fidelizadosList]);

  return (
    <div className="container">
      <div className="container-row">
        {table[view]}
        <ButtonsTransfer
          handleLeftToRight={
            view === "contatos" || view === "fidelizados" ? handleLeftToRight : handleGroupsToRight
          }
          handleRightToLeft={handleRightToLeft}
        />
        <TableRight
          MyContatosOnShow={MyContatosOnShow}
          setMyContatosOnShow={setMyContatosOnShow}
          searchRigth={searchRigth}
          setSearchRigth={setSearchRigth}
          handleSelectRight={handleSelectRight}
          selectedFromRight={selectedFromRight}
          handleSelectAllRight={handleSelectAllRight}
        />
      </div>
    </div>
  );
}
