import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import { Link } from "react-router-dom";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import LinkGroup from "./Types/groups";
import LinkNormal from "./Types/normal";
import getSidebarItems from "./data";
import "./sidebar.css";

const Sidebar = () => {
  const { user, pref } = useContext(UserContext);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [usuario, setUsuario] = useState();
  const [activeItem, setActiveItem] = useState("");
  const sidebarItems = getSidebarItems(user, pref);

  const handleItemClick = (label) => {
    setActiveItem(label);
  };

  useEffect(() => {
    let newUser = user ? { ...user } : {};
    setUsuario(newUser);
  }, [user]);

  return (
    <div className={`sidebar${sideBarOpen ? "" : "-close"}`}>
      <div
        className="sidebar-toggle"
        onClick={() => setSideBarOpen(!sideBarOpen)}
        style={
          sideBarOpen
            ? { justifyContent: "flex-end" }
            : { justifyContent: "center" }
        }
      >
        {sideBarOpen ? <BsArrowLeftSquare /> : <BsArrowRightSquare />}
      </div>
      <div className={`sidebar-header${sideBarOpen ? "" : "-close"}`}>
        <Link to="/profile">
          <img
            src={usuario?.foto ? usuario?.foto : "/imgs/avatar2.png"}
            alt="user"
          />
        </Link>
        {sideBarOpen && (
          <>
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <h3>{usuario?.nome}</h3>
            </Link>
            <span>{usuario?.admin ? "Adm" : ""}</span>
          </>
        )}
      </div>

      <ul>
        {sidebarItems.map(({ id, label, links, link, icon, titulo }) =>
          links ? (
            <LinkGroup
              key={id}
              label={label}
              links={links}
              icon={icon}
              titulo={titulo}
              sideBarOpen={sideBarOpen}
              activeItem={activeItem}
              onItemClick={handleItemClick}
            />
          ) : (
            <LinkNormal
              key={id}
              label={label}
              link={link}
              icon={icon}
              titulo={titulo}
              sideBarOpen={sideBarOpen}
              activeItem={activeItem}
              onItemClick={handleItemClick}
            />
          )
        )}
        <div className="container-img">
          <a
            href="https://api.whatsapp.com/send/?phone=%2B554834782300&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/imgs/chip.png"
              alt="chip"
              className="img-banner-chip"
              style={sideBarOpen ? { height: "100px" } : { height: "70px" }}
            />
          </a>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
