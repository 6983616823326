export const Avisar = (props) => {
  const {
    onChangeAviso,
    AvisosCheckboxRow,
    view,
    setView,
    avisos,
    salvarServicoConfig,
    handleCancelServicoConfig,
    onChangeMensagem,
    mensagemWarning,
  } = props;
  return (
    <>
      <legend>Mensagem Aviso</legend>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="aviso">Avisar Agendamento:</label>
          <AvisosCheckboxRow onChangeAviso={onChangeAviso} avisos={avisos} />
        </div>
      </div>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="mensagemWarning">Mensagem Aviso</label>
          <textarea
            className="servico-form-textarea"
            name="mensagemWarning"
            id="mensagemWarning"
            cols="100"
            rows="5"
            value={mensagemWarning}
            onChange={onChangeMensagem}
          />
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="prev" onClick={() => setView(view - 1)}>
          Anterior
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
};
