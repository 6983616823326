import { useState, useEffect } from 'react';
import PrAtt from '../../../../WhatsAppTool/Protocolos/PrAtt';

const UserProtocolosList = (props) => {
    const { protocolosUser } = props;
    const [search, setSearch] = useState('');
    const [filteredProtocols, setFilteredProtocols] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [itemsPerPage] = useState(5);

    useEffect(() => {
        if (protocolosUser) {
            setFilteredProtocols([...protocolosUser]);
        }
    }, [protocolosUser]);

    useEffect(() => {
        if (protocolosUser) {
            let atts = protocolosUser.filter((att) => {
                if (
                    (att?.contato?.nome?.toLowerCase().includes(search.toLowerCase()) ||
                        att?.contato?.telefone?.toLowerCase().includes(search.toLowerCase()) ||
                        att?.numero?.toLowerCase().includes(search.toLowerCase()))
                ) {
                    return att;
                }
            });
            setFilteredProtocols([...atts]);
        }
    }, [search, protocolosUser]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProtocols.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredProtocols.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const generatePageNumbers = () => {
        const maxPagesToShow = 5;
        const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    
        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    
        if (startPage > 1) pageNumbers.unshift('...');
        if (endPage < totalPages) pageNumbers.push('...');
    
        return pageNumbers;
    };
    
    return (
        <>
            {
                currentItems.length > 0 && (
                    <div className="list-user-container-body-header" style={props.day ? {marginTop: 0, width:'100%'}: {marginTop:'20px'}}>
                        <input
                            type="text"
                            placeholder="Pesquisar"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            style={props.day ? {width: '100%'} : null}
                        />
                    </div>
                )
            }
            <div className="list-user-container-body-list">
                <div className="list-user-container-body-list-header">
                    <p style={{textAlign:'left'}}>Nº protocolo</p>
                    <p>Nome</p>
                    <p style={{textAlign:'right'}}>Telefone</p>
                </div>
                {currentItems.length > 0 ? currentItems.map((ptr, index) => {
                    return (
                        <div key={index} className="list-user-container-body-list-item" onClick={() => setSelectedProtocol(ptr)}>
                            <p>{ptr.numero}</p>
                            <p>{ptr?.contato?.nome}</p>
                            <p>{ptr?.contato?.telefone}</p>
                        </div>
                    );
                }) : <p>{props.day ? 'Nenhum protocolo iniciado no dia':'Nenhum protocolo encerrado'}</p>}
            </div>

            {filteredProtocols.length > itemsPerPage && (
                <div className="pagination-list">
                    <div className="page-item-list" onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}>
                        <button className="page-item-link" style={{ width: '120px', padding: '5px', marginRight: '20px' }}>Anterior</button>
                    </div>
                    {generatePageNumbers().map((number, index) => (
                        <div key={index} className="page-item-list">
                            <button
                                className={`page-item-link ${number === currentPage ? 'active' : ''}`}
                                onClick={() => paginate(number)}>
                                {number}
                            </button>
                        </div>
                    ))}
                    <div className="page-item-list" onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}>
                        <button className="page-item-link" style={{ width: '120px', padding: '5px', marginLeft: '20px' }}>Próxima</button>
                    </div>
                </div>
            )}

            {selectedProtocol && (
                <PrAtt pr={selectedProtocol} onClose={() => setSelectedProtocol(null)} />
            )}
        </>
    );
};

export default UserProtocolosList;
