import { useContext } from 'react'
import { FlowBotContext } from '../../../../../../contexts/ChatBotProvider/FlowBotProvider'

const CampanhaBoxOFSelects = (props) => {
    const { nome, setNome, bot, setBot, criador } = props
    const { bots } = useContext(FlowBotContext);
    return (
        <div className="container-space">
            <div className="campanha-form-body-left-item">
                <label className="required">Nome</label>
                <input
                    type="text"
                    placeholder="ex: sorteios"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                />
            </div>

            <div className="campanha-form-body-left-item">
                <label className="required">Bot</label>
                <select value={bot} onChange={(e) => setBot(e.target.value)}>
                    <option value="">Selecione um bot</option>
                    {bots.map((b, index) => {
                        return (
                            <option key={index} value={b._id}>
                                {b.nome}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="campanha-form-body-left-item">
                <label>Criador</label>
                <input type="text" value={criador} disabled />
            </div>
        </div>
    )
}

export default CampanhaBoxOFSelects
