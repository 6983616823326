import React from "react";

const BotStatus = ({ status }) => {
  const statusMap = {
    ativo: <span className="bot-status-active">Ativo</span>,
    inativo: <span className="bot-status-inactive">Inativo</span>,
    logando: <span className="bot-status-loggingIn">Logando</span>,
    esperando: <span className="bot-status-waiting">Carregando</span>,
  };

  const statusText = statusMap[status] || null;

  return (
    <div className="bot-content-details-item">
      <span>
        <b className="b-subtitle">Status:</b> {statusText}
      </span>
    </div>
  );
};

export default BotStatus;
