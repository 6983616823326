import React, { useState, useEffect, useContext } from "react";
import CheckboxList from "../../inputs/CheckboxUsers/CheckboxList";
import { Form } from "react-bootstrap";
import { PrefContext } from "../../../../../contexts/ChatBotProvider/PrefProvider";
import ApiUsers from "../../../../../ApiUsers";
import "../Departament.css";

export default function DepartamentAddUser(props) {
  const { departamento } = props;
  const prefContext = useContext(PrefContext);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosOnShow, setUsuariosOnShow] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let users = prefContext.users.filter((u) => {
      if (u.departamento === "") {
        if (u.nome === "Voxcity" || u.ativo === false) {
          return false;
        } else {
          return u;
        }
      }
    });
    setUsuarios(users);
    setUsuariosOnShow(users);
  }, [prefContext.departamentos]);

  function searchUser(e) {
    let newUsers = [];
    if (e.target.value.trim() !== "") {
      newUsers = usuarios.filter((u) => {
        return u.nome.toLowerCase().includes(e.target.value.toLowerCase());
      });
    } else {
      newUsers = usuarios;
    }
    setUsuariosOnShow(newUsers);
  }

  function selectUser(checked, user) {
    let users = [];
    if (checked) {
      users = [...selectedUsers, user];
    } else {
      users = selectedUsers.filter((u) => u._id !== user._id);
    }
    setSelectedUsers(users);
  }

  function selectAll(checked) {
    let users = [];
    if (checked) {
      users = [...usuariosOnShow];
    } else {
      users = [];
    }
    setSelectedUsers(users);
  }

  function addUsers() {
    if (selectedUsers.length > 0) {
      setLoading(true);
      let users = selectedUsers.map((u) => u._id);
      ApiUsers.post("/departamentos/adicionarVarios", { users, departamento })
        .then((res) => {
          setSelectedUsers([]);
          setLoading(false);
          props.alert("normal", {
            title: "Sucesso!",
            placeholder: "Usuários adicionados ao departamento com sucesso!",
          });
        })
        .catch((err) => console.log(err));
    } else {
      props.alert("normal", {
        title: "Ops!",
        placeholder:
          "Selecione ao menos um usuário para adicioná-lo ao departamento!",
      });
    }
  }

  return (
    <>
      <div className="add-user-dep-container">
        <h2 className="add-user-dep-title">
          {" "}
          Adicionar usuários no Departamento {departamento.nome}{" "}
        </h2>

        <Form.Control
          placeholder={"Digite aqui para pesquisar um usuário"}
          onChange={(e) => searchUser(e)}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <CheckboxList
          usuarios={usuariosOnShow}
          selectedUsers={selectedUsers}
          action="add"
          addUsers={() => addUsers()}
          selectUser={(checked, user) => selectUser(checked, user)}
          selectAll={(checked) => selectAll(checked)}
          loading={loading}
        />

        <div className="goback-adduser-btn-container">
          <button
            className="goback-adduser-btn"
            onClick={() => props.goBack()}
            title="Voltar"
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  );
}
