import "./styles.css";
import { BsThreeDotsVertical, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Item } from "../Item";
import { Menu } from "../Menu";

export const Section = ({ item, itemConfig, isActive, isMenuOpen, onToggle, onToggleMenu, children, callback, hasSubItems, sectionName, index, itemFunction }) => {
  return (
    <div className={`accordion-section-container ${isActive ? "active-section-container" : ""}`}>
      <div className="accordion-section-header">{index === 0 && sectionName && <h4>{sectionName}</h4>}</div>
      <Menu
        item={item}
        itemConfig={itemConfig}
        isMenuOpen={isMenuOpen}
        onToggleMenu={onToggleMenu}
        callback={callback}
      />
      <div
        className={`accordion-section ${isActive ? "active-section" : ""}${!hasSubItems ? "no-sub-items" : ""}`}
        onClick={onToggle}>
        {hasSubItems &&
          (isActive ? (
            <BsChevronUp
              className="accordion-section-arrow"
              size={15}
              strokeWidth={1}
            />
          ) : (
            <BsChevronDown
              className="accordion-section-arrow"
              size={15}
              strokeWidth={1}
            />
          ))}
        {itemConfig.keys && itemConfig.keys.map((keyItem) => <p key={keyItem.key}>{item[keyItem.key]}</p>)}
        <BsThreeDotsVertical
          className="accordion-section-menu-button"
          onClick={(e) => {
            e.stopPropagation();
            onToggleMenu();
          }}
        />
      </div>
      {isActive && (
        <div className={`section-content ${isActive ? "active-content" : ""}`}>
          {itemConfig.items &&
            itemConfig.items.map((itemKey) => (
              <Item
                key={itemKey.key}
                itemKey={itemKey}
                item={item}
                itemFunction={itemFunction}
              />
            ))}
          {children}
        </div>
      )}
    </div>
  );
};
