import React from 'react';

export default function Checkbox(props) {

    return <>

        <div className="checkbox-container">

            {props.children}

        </div>

    </>
}