import React, { useState } from "react";
import SuportShow from "./SuportShow";
import AboutShow from "./AboutShow";

function SuportConfig(props) {
  const [onShow, setOnShow] = useState("suporte");
  const components = {
    suporte: <SuportShow />,
    informações: <AboutShow />,
  };

  return (
    <div className="config-list-header">
      <button
        title="Suporte"
        onClick={(e) => setOnShow("suporte")}
        id="visualize-button-view"
        style={
          onShow === "suporte"
            ? {
              fontWeight: "bold",
            }
            : null
        }
      >
        Suporte
      </button>
      <button
        title="Criar novo grupo"
        onClick={(e) => {
          setOnShow("informações");
        }}
        id="create-button-view"
        style={
          onShow === "informações"
            ? {
              fontWeight: "bold",
            }
            : null
        }
      >
        Mais Informações
      </button>
      {onShow ? components[onShow] : ""}
    </div>
  );
}

export default SuportConfig;
