import React, { useState, useEffect } from "react";
import RenderVariable from "./RenderVariable";

const VariableList = ({ variables, contact, setContact, createAlert }) => {
  const [variableValues, setVariableValues] = useState({});

  useEffect(() => {
    let newVariableValues = {};
    variables.forEach((variable) => {
      newVariableValues[variable.nome] = contact[variable.nome];
    });
    setVariableValues(newVariableValues);
  }, [contact]);

  return (
    <div className="variable-grid">
      {variables.map((variable) => (
        <RenderVariable
          key={variable.nome}
          variable={variable}
          variableValues={variableValues[variable.nome]}
          setVariableValues={setVariableValues}
          contact={contact}
          setContact={setContact}
          createAlert={createAlert}
        />
      ))}
    </div>
  );
};

export default VariableList;
