import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../contexts/UserProvider";
import Email from "./Inputs/email";
import Conta from "./Inputs/conta";
import Senha from "./Inputs/senha";
import "./LoginRefatored.css";
import { BiErrorCircle } from "react-icons/bi";
import MiniLoading from "../Configurações/OptionsScreen/inputs/MiniLoading";

const LoginRefatored = () => {
  const context = useContext(UserContext);
  const [login, setLogin] = useState({
    email: "",
    senha: "",
    conta: "",
  });

  function setInputs(campo, valor) {
    setLogin({ ...login, [campo]: valor });
    context.setError("");
  }

  function logIn() {
    let newLogin = { ...login };
    context.logIn(newLogin);
  }

  return (
    <>
      <div className="login-wrapper">
        <Form className="login-container">
          <div className="login-header">
            <img
              src="./imgs/VOXLOGOHorizintal.png"
              className="form-header-img"
              alt="logo"
            />
          </div>

          <div className="login-form">
            <Conta setInputs={setInputs} />
            <Email setInputs={setInputs} />
            <Senha setInputs={setInputs} />

            {context.error !== "" ? (
              <div className="error-container-new">
                <BiErrorCircle />{" "}
                <span className="error-msg-new"> {context.error} </span>
              </div>
            ) : null}

            <Button className="login-button" onClick={logIn} style={{ backgroundColor: "#142a4c" }}>{context.loading ? <MiniLoading /> : "Entrar"}</Button>
            <div className="login-links">
              <a href="/forgot" className="login-esqueci">Esqueci minha senha </a>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default LoginRefatored;
