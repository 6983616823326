import { useState, useContext, useEffect } from "react";
import ApiUsers from "../../../../../ApiUsers";
import { FlowBotContext } from "../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { AttendanceContext } from "../../../../../contexts/ChatBotProvider/AttendanceProvider";
import { GoXCircleFill } from "react-icons/go";

import * as XLSX from "xlsx";
import "./disparador.css";

//vou enviar um array de numeros para o backend
// e uma mensagem para ser enviada

//vou mostrar uma lista de numeros
// e uma mensagem para ser enviada

const Disparator = () => {
  const [mensagem, setMensagem] = useState("");

  const [numeroToAdd, setNumeroToAdd] = useState("");
  const [loading, setLoading] = useState(false);

  const [numeros, setNumeros] = useState([]);
  const context = useContext(FlowBotContext);
  const [bots, setBots] = useState([]);
  const [bot, setBot] = useState("");

  const [sugetoes, setSugetoes] = useState([]);

  const contextAtt = useContext(AttendanceContext);

  useEffect(() => {});

  function addWhatsNumber() {
    let possible = numeroToAdd.length >= 10 && numeroToAdd.length <= 11;
    let newNumber = "55" + numeroToAdd;
    if (possible) {
      setNumeros([...numeros, newNumber]);
      setNumeroToAdd("");
    } else {
      alert("Numero invalido");
    }
  }

  async function sendMessage(e) {
    e.preventDefault();
    console.log(numeros);
    setLoading(true);
    let resp = await ApiUsers.post("/whats/disparator", {
      numeros,
      mensagem,
      bot,
    });
    console.log(resp);
    setLoading(false);
    alert("requisitado");
  }

  async function importXLSFile(e) {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let numeros = data.map((item) => {
        return "55" + item["Número"];
      });
      setNumeros([...numeros]);
    };
    reader.readAsBinaryString(file);
  }

  useEffect(() => {
    let newBots = context.bots.filter((bot) => bot.status === "ativo");
    setBots(newBots);
  }, [context.bots]);

  const handleNumero = (e) => {
    setNumeroToAdd(e.target.value);
  };

  useEffect(() => {
    if (numeroToAdd.length > 0) {
      let newSugetoes = contextAtt.attendances.filter((contact) => {
        return contact.telefone.includes(numeroToAdd);
      });
      setSugetoes(newSugetoes);
    } else {
      setSugetoes([]);
    }
  }, [numeroToAdd, contextAtt.attendances]);

  return (
    <div className="disparador-screen">
      <div className="container-space-disparador">
        <div className="bot-select-div">
          <label className="label required">Bot</label>
          <select
            value={bot}
            onChange={(e) => setBot(e.target.value)}
            className="select-bot"
          >
            <option value="">Selecione um bot</option>
            {bots.map((bot, index) => {
              return (
                <option key={index} value={bot._id}>
                  {bot.nome}
                </option>
              );
            })}
          </select>
        </div>

        <div className="number-select-div">
          <label className="text-identity-numero">Número para adicionar</label>
          <input
            className="input-number-view"
            type="text"
            placeholder="XXXX - XXXX"
            value={numeroToAdd}
            onChange={handleNumero}
          />
        </div>
        <div className="container-button-add">
          <button
            className="create-button-view-numero"
            onClick={addWhatsNumber}
          >
            Adicionar
          </button>
        </div>
      </div>

      {sugetoes.length > 0 && (
        <div className="sugestoes">
          <h3>Sugestões</h3>
          {sugetoes.map((sugetao, index) => {
            return (
              <div
                key={index}
                className="sugetao"
                onClick={() => {
                  setNumeros([...numeros, sugetao.telefone]);
                  setSugetoes([]);
                  setNumeroToAdd("");
                }}
              >
                <div>
                  <p>{sugetao.telefone}</p>
                  <p>
                    {sugetao.nome
                      ? sugetao.nome
                      : sugetao.pushname
                      ? sugetao.pushname
                      : ""}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="container-xls">
        <button
          className="import-btn"
          onClick={(e) => {
            document.getElementById("file").click();
          }}
        >
          Importar XLS
        </button>
        <input
          type="file"
          name="file"
          id="file"
          className="inputfile"
          style={{ display: "none" }}
          onChange={importXLSFile}
          onClick={(e) => (e.target.value = null)}
        />
      </div>

      <div id="lista-numeros" className="lista-numeros">
        <div className="container-list-title">
          <label className="text-identity">Lista de Números</label>
        </div>
        {numeros.length === 0 ? (
          <div className="numeros-empty">
            <p>Nenhum número anexado.</p>
          </div>
        ) : (
          numeros.map((numero, index) => (
            <div key={index} className="numero">
              <p>{numero}</p>
              <GoXCircleFill
                className="icon"
                onClick={() => {
                  let newNumeros = numeros.filter((item, i) => i !== index);
                  setNumeros(newNumeros);
                }}
              />
            </div>
          ))
        )}
      </div>
      <div className="text-area">
        <div className="container-list-title">
          <label className="text-identity">Mensagem</label>
        </div>
        <textarea
          value={mensagem}
          onChange={(e) => setMensagem(e.target.value)}
          className="textarea-message"
          id="exampleFormControlTextarea1"
          placeholder="Digite a mensagem aqui..."
          rows="6"
          cols="100"
        />
      </div>

      <div className="container-button-enviar">
        <button
          className="import-btn-enviar"
          disabled={loading}
          onClick={sendMessage}
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

export default Disparator;
