import { useState, useContext } from "react";
import { CidadaoContext } from "../../../contexts/CidadaoProvider";
import { cpf } from "cpf-cnpj-validator";
import "./style.css";
export const Cadastro = () => {
  const [cidadao, setCidadao] = useState({
    nome: "",
    telefone: "",
    cpf: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { addCidadao, alert, alertType } = useContext(CidadaoContext);

  const validateForm = () => {
    const { nome, telefone, email } = cidadao;
    let newErrors = {};
    if (nome.length === 0) {
      newErrors.nome = "Nome é obrigatório";
    }
    if (nome.length < 3) {
      newErrors.nome = "Nome deve ter no mínimo 3 caracteres";
    }
    if (nome.length > 50) {
      newErrors.nome = "Nome deve ter no máximo 50 caracteres";
    }
    if (!cpf.isValid(cidadao.cpf)) {
      newErrors.cpf = "CPF inválido";
    }
    if (telefone.length === 0 || telefone.length < 10) {
      newErrors.telefone = "Telefone inválido";
    }
    if (email && !emailRegex.test(email)) {
      newErrors.email = "E-mail inválido";
    }
    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const onChangeNomeCidadao = (e) => {
    setCidadao({ ...cidadao, nome: e.target.value });
    setErrors({ ...errors, nome: "" });
  };

  const onChangeCpfCidadao = (e) => {
    const inputCpf = e.target.value;
    const numericCpf = inputCpf.replace(/\D/g, "");
    if (numericCpf.length <= 11) {
      setCidadao({ ...cidadao, cpf: cpf.format(numericCpf) });
      setErrors({ ...errors, cpf: "" });
    }
  };
  const onChangeEmailCidadao = (e) => {
    setCidadao({ ...cidadao, email: e.target.value });
    setErrors({ ...errors, email: "" });
  };

  const onChangeTelefoneCidadao = (e) => {
    setCidadao({ ...cidadao, telefone: e.target.value });
    setErrors({ ...errors, telefone: "" });
  };

  const onSubmitCidadao = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    addCidadao(cidadao);
    setCidadao({ nome: "", telefone: "", cpf: "", email: "" });
  };

  return (
    <div className="cadastro-container">
      {alert.visible ? alertType[alert.type] : null}
      <div className="cadastro-title">CADASTRO</div>
      <form className="cadastro-form">
        <div className="cadastro-form-content">
          <div className="cadastro-form-input-container">
            <label htmlFor="nome" className="required-prof">
              Nome
            </label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={cidadao.nome}
              onChange={onChangeNomeCidadao}
            />
            {errors.nome ? (
              <span className="cadastro-cidadao-error">{errors.nome}</span>
            ) : null}
          </div>
          <div className="cadastro-form-input-wrapper">
            <div className="cadastro-form-input-container">
              <label htmlFor="cpf" className="required-prof">
                CPF
              </label>
              <input
                type="text"
                name="cpf"
                id="cpf"
                value={cidadao.cpf}
                onChange={onChangeCpfCidadao}
              />
              {errors.cpf ? (
                <span className="cadastro-cidadao-error">{errors.cpf}</span>
              ) : null}
            </div>
            <div className="cadastro-form-input-container">
              <label htmlFor="telefone" className="required-prof">
                Telefone
              </label>
              <input
                type="text"
                name="telefone"
                id="telefone"
                value={cidadao.telefone}
                onChange={onChangeTelefoneCidadao}
              />
              {errors.telefone ? (
                <span className="cadastro-cidadao-error">
                  {errors.telefone}
                </span>
              ) : null}
            </div>
          </div>
          <div className="cadastro-form-input-container">
            <label htmlFor="email">E-mail</label>
            <input
              type="text"
              name="email"
              id="email"
              value={cidadao.email}
              onChange={onChangeEmailCidadao}
            />
            {errors.email ? (
              <span className="cadastro-cidadao-error">{errors.email}</span>
            ) : null}
          </div>
          <div className="cadastro-form-button-container">
            <button type="" id="add" onClick={onSubmitCidadao}>
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
