import React from 'react'

const ListUsers = ({ myContatosOnShow, setMyContatosOnShow, contato, onChange, checked, showIcon }) => {

    // Função para definir se um contato é administrador ou não
    const handleSetContactAdmin = (telefone) => {
        let newContactsAdmin = [];
        myContatosOnShow.forEach((contact) => {
            if (contact.telefone === telefone) {
                contact.admin = !contact.admin;
                newContactsAdmin.push(contact);
            } else {
                newContactsAdmin.push(contact);
            }
        });
        setMyContatosOnShow(newContactsAdmin);
    };

    return (
        <div className="table-list-item">
            <input type="checkbox" style={{ width: "18px", height: "18px" }} checked={checked} onChange={onChange} />
            <p style={{ width: "100%" }}>
                {contato.nome || contato.pushname || ""} - {contato.telefone}
            </p>
            {showIcon && (
                <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => handleSetContactAdmin(contato.telefone)}>
                    <img
                        src={contato.admin ? "imgs/iconCoroa.svg" : "imgs/iconCoroaVazio.svg"}
                        alt="coroa"
                        style={{ width: "25px", height: "25px", marginRight: "10px" }}
                    />
                </button>
            )}
        </div>
    )
}

export default ListUsers

