import React, { useState, useEffect, useContext } from 'react';
import ApiUsers from '../../../../ApiUsers';
import UserCreate from './UserCreateNEW';
import UserList from './UserShow/UserList';
import DeleteAlert from '../inputs/DeleteAlert';
import Alert from '../inputs/Alerts/Alert';
import { PrefContext } from '../../../../contexts/ChatBotProvider/PrefProvider';
import './UserConfig.css';

export default function UserShow() {

    const prefContext = useContext(PrefContext);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosOnShow, setUsuariosOnShow] = useState([]);
    const [usuarioSelected, setUsuarioSelected] = useState({});
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
        delete: <DeleteAlert data={alert.props.data} nome={alert.props.nome} action={alert.props.action} quit={() => quitAlert()} />
    }

    useEffect(() => {
        let users = prefContext.users.filter(u => u.nome !== "Voxcity");
        setUsuarios(users);
        setUsuariosOnShow(users);
    }, [prefContext.users]);

    function createAlert(type, props) {
        setAlert({ visible: true, type, props});
    }

    function quitAlert() {
        setAlert({ visible: false, type: "", props: {} });
    }

    function inactiveConfirm(user) {
        createAlert("delete", { data: "usuário", nome: user.nome, action: () => inactiveUser(user) });
    }

    function inactiveUser(user) {
        setLoading(true);
        ApiUsers.post("/usuarios/desativar", { user }).then((res) => {
            setLoading(false);
            createAlert("normal", { title: "Sucesso!", placeholder: "Usuário inativado com sucesso!" });
        }).catch((err) => {
            console.log(err);
        })
    }

    function activeUser(user) {
        setLoading(true);
        let newUser = { ...user };
        let date = new Date();
        newUser.ativo = true;
        newUser.config.reativado = true;
        newUser.config.dataReativado = date;
        ApiUsers.post(`/usuarios/reativar`, { user: newUser, lastUser: user }).then((res) => {
            setLoading(false);
            createAlert("normal", { title: "Sucesso!", placeholder: "Usuário reativado com sucesso!" });
        }).catch(err => {
            console.log(err)
        })
    }

    function searchUsers(e) {
        let search = e.target.value;
        let newUsers = [];
        if (search.trim() !== "") {
            newUsers = usuarios.filter((u) => u.nome.toLowerCase().includes(search.toLowerCase()));
        } else {
            newUsers = usuarios;
        }
        setUsuariosOnShow([...newUsers]);
    }

    return (

        <div style={{ height: '100%' }}>

            {alert.visible && (alertType[alert.type])}

            {edit ? <>
                <UserCreate edit={true} user={usuarioSelected} goBack={() => setEdit(false)} />
            </> : <>
                    <UserList
                        usuarios={usuariosOnShow}
                        loading={loading}
                        search={(e) => searchUsers(e)}
                        editUser={user => { setEdit(true); setUsuarioSelected(user) }}
                        inactiveUser={user => inactiveConfirm(user)}
                        activeUser={user => activeUser(user)}
                    />
            </>}

        </div>

    );
}


