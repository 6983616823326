import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../../contexts/UserProvider';
import { BsPlusSquareFill, BsTrash3, BsPen } from "react-icons/bs";
import MensagemRapida from './Types/msgRapidasAcordeon';
import MsgRapidaModal from './Types/msgRapidaModal';
import './msgRapidas.css';

const MensagensRapidas = ({ createAlert, quitAlert }) => {
    const { msgsRapidasUsuario, salvarMsgRapida, apagarMsgRapida, editarMsgRapida } = useContext(UserContext);
    const [msgsRapidas, setMsgsRapidas] = useState([]);
    const [isMsgRapidaFormOpen, setIsMsgRapidaFormOpen] = useState(false);
    const [msgRapidaToEdit, setMsgRapidaToEdit] = useState(null);
    const [isEditando, setIsEditando] = useState(false);

    useEffect(() => {
        const novasMsgsRapidas = msgsRapidasUsuario ? [...msgsRapidasUsuario] : [];
        setMsgsRapidas(novasMsgsRapidas);
    }, [msgsRapidasUsuario]);

    const handleOpenMsgRapidaForm = (msg) => {
        if (msg?._id) {
            setIsMsgRapidaFormOpen(true);
            setMsgRapidaToEdit(msg);
            setIsEditando(true);
        } else {
            setIsMsgRapidaFormOpen(true);
            setIsEditando(false);
        }
    }

    const closeMsgRapidaForm = () => {
        setIsMsgRapidaFormOpen(false);
        setMsgRapidaToEdit(null);
    }

    const handleApagarMsgRapida = (msg) => {
        apagarMsgRapida(msg);
        quitAlert();
    }

    const handleSaveMsgRapida = (msg) => {
        if (isEditando && msg.atalho !== '' && msg.mensagem !== '') {
            editarMsgRapida(msg);
            setIsEditando(false);
            setIsMsgRapidaFormOpen(false);
            return;
        } else if (!isEditando && msg.atalho !== '' && msg.mensagem !== '') {
            salvarMsgRapida(msg);
            setIsMsgRapidaFormOpen(false);
        }
    }

    const alertApagar = (m) => {
        if (m?.atalho) {
            createAlert("delete", {
                data: m,
                nome: `/${m.atalho}`,
                action: () => {
                    handleApagarMsgRapida(m);
                },
            });
        }
    };

    return (
        <div className='profile-msgsrapidas-wrapper'>
            {isMsgRapidaFormOpen && <MsgRapidaModal closeMsgRapidaForm={closeMsgRapidaForm} msgRapidaToEdit={msgRapidaToEdit} handleSaveMsgRapida={handleSaveMsgRapida} />}
            <div className="msgs-rapidas-header">
                <h5>Mensagens rápidas</h5>
                <BsPlusSquareFill size={20} onClick={() => handleOpenMsgRapidaForm()} />
            </div>

            <div className="msgs-rapidas">
                {msgsRapidas.map((msg, i) => (
                    <div key={i} className='msg-rapida-box'>
                        <MensagemRapida atalho={msg.atalho} mensagem={msg.mensagem} />
                        <div className="msg-options">
                            <BsPen size={20} onClick={() => handleOpenMsgRapidaForm(msg)} />
                            <BsTrash3 size={20} onClick={() => alertApagar(msg)} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MensagensRapidas;
