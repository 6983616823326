import React, { useState, useEffect, useContext } from "react";
import { Form, Card, ListGroup } from "react-bootstrap";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

export default function HistoryAttendance(props) {
  const context = useContext(AttendanceContext);
  const prefContext = useContext(PrefContext);
  const [history, setHistory] = useState([]);
  const [historyOnShow, setHistoryOnShow] = useState([]);

  function doSearch(e) {
    const search = e.target.value;
    const newHistory = history.filter((attendance) => {
      return (
        attendance.pushname.toLowerCase().includes(search.toLowerCase()) ||
        attendance.telefone.toLowerCase().includes(search.toLowerCase())
      );
    });
    setHistoryOnShow(newHistory);
  }

  useEffect(() => {
    let sortedHistoric = context.myHistoric.sort((a, b) =>
      new Date(a.data).getTime() < new Date(b.data).getTime() ? 1 : -1
    );
    setHistory(sortedHistoric);
    setHistoryOnShow(sortedHistoric);
  }, [context.myHistoric]);

  return (
    <>
      <Card
        style={{
          backgroundColor: "#e1ecf7",
          height: "5px",
          width: "101%",
          marginLeft: "-3px",
        }}
        className="bg-bg"
      />
      <Form.Control
        placeholder={`${String.fromCodePoint("128270")} Buscar Contato...`}
        onChange={(e) => doSearch(e)}
      />
      <Card
        style={{
          backgroundColor: "#e1ecf7",
          height: "5px",
          width: "101%",
          marginLeft: "-3px",
        }}
        className="bg-bg"
      />

      <div className="attendance-list">
        {historyOnShow?.map((histo, i) => {
          let background = "inherit";

          if (histo === props.selected) {
            background = "white";
          }

          let actionDate = new Date(histo.data);
          let formatedDate =
            timeHasZero(actionDate.getDate()) +
            "/" +
            timeHasZero(actionDate.getMonth() + 1) +
            "/" +
            timeHasZero(actionDate.getFullYear());
          let formatedTime =
            timeHasZero(actionDate.getHours()) +
            ":" +
            timeHasZero(actionDate.getMinutes());
          let grupo = prefContext.atendentes.find(
            (grp) => grp._id === histo.grupo
          );
          let atendente = prefContext.users.find(
            (usr) => usr._id === histo.atendente
          );

          return (
            <div key={i + 2 * 969403}>
              <div
                className="contact-wrapper"
                style={{
                  backgroundColor: background,
                  display: "flex",
                  alignItems: "center",
                }}
                key={i}
                onClick={(e) => {
                  props.onSelect(e, histo.contact);
                }}
              >
                <img
                  className="contact-avatar"
                  src={
                    histo.contact?.foto
                      ? histo.contact.foto
                      : "/imgs/avatar2.png"
                  }
                  alt=""
                />

                <ListGroup.Item
                  className="contact-data"
                  style={{
                    backgroundColor: background,
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  key={i}
                >
                  <b>
                    {histo.contact.pushname
                      ? histo.contact.pushname
                      : histo.contact.telefone.replace("@c.us", " ").trim()}
                  </b>
                  <small style={{ textTransform: "none", margin: "4px 0px" }}>
                    {histo.action === "Transferido para espera"
                      ? `${histo.action} grupo ${grupo ? grupo.nome : ""}`
                      : histo.action === "Transferido para outro Atendente"
                      ? `${histo.action} - ${atendente.nome}`
                      : histo.action}
                  </small>
                  <small className="small-title">
                    Ação realizada às {formatedTime} de {formatedDate}{" "}
                  </small>
                </ListGroup.Item>
              </div>

              <Card
                style={{
                  backgroundColor: "#e1ecf7",
                  height: "5px",
                  width: "101%",
                  marginLeft: "-3px",
                }}
                key={(i + 10) * 20000}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
function timeHasZero(time) {
  if (time < 10) return "0" + time;
  return time;
}
