import React from "react";
import HeaderTitle from "../../CreateForm/HeaderTitle";
import BotFormServices from "./components/BotFormServices";
import ServiceHours from "./components/ServiceHours";
import FlowSelectionForm from "./components/FlowSelectionForm";
import ButtonsFormServices from "./components/ButtonsFormServices";
import "./botServices.css";

function Services(props) {
  const { bot, setBot } = props;

  function changeName(e) {
    let newBot = { ...bot };
    newBot.nome = e.target.value;
    setBot(newBot);
  }

  function changeToken(e) {
    let newBot = { ...bot };
    newBot.token = e.target.value;
    setBot(newBot);
  }

  function changePhoneId(e) {
    let newBot = { ...props.bot };
    newBot.phoneId = e.target.value;
    props.setBot(newBot);
  }
  return (
    <>
      <div className="bot-form-sv">
        <div className="cont-align-text-buttons">
          <HeaderTitle title={"Configurações do Bot " + bot.nome} />
          <ButtonsFormServices props={props} />
        </div>
        <BotFormServices
          props={props}
          changeName={changeName}
          changeToken={changeToken}
          changePhoneId={changePhoneId}
        />
        <ServiceHours props={props} />
        <FlowSelectionForm props={props} />
      </div>
    </>
  );
}

export default Services;
