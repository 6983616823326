import React from "react";
import { BiCoffee } from "react-icons/bi";

const Conta = (props) => {
  return (
    <div className="login-label">
      <label htmlFor="conta">Conta</label>
      <div className="login-input-content">
        <input
          name="conta"
          className="login-input"
          type="text"
          onChange={(event) => props.setInputs("conta", event.target.value)}
        />
        <BiCoffee className="login-icon" />
      </div>
    </div>
  );
};

export default Conta;
