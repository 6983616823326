import React from "react";
import { BiPhone } from "react-icons/bi";

const Ramal = (props) => {
  return (
    <div className="login-label">
      <label htmlFor="ramal">Ramal</label>
      <div className="login-input-content">
        <input
          name="ramal"
          className="login-input"
          type="text'"
          onChange={(event) => props.setInputs("ramal", event.target.value)}
        />
        <BiPhone className="login-icon" />
      </div>
    </div>
  );
};

export default Ramal;
