import React, { useContext, useEffect, useState } from 'react'
import File from './File'
import Text from './Text'
import Audio from './Audio'


export default function Forwarding(props) {
    const { data, message, justi, contactColor } = props
    const { files, forwarding } = data
    const [type, setType] = useState('')
    const [fType, setFType] = useState('')
    const types = {
        file: <File data={data} message={data?.message === 'forwarding' || !data?.message ? 'Mensagem encaminhada' : message} justi={justi} contactColor={contactColor} />,
        text: <Text data={data} message={data?.message === 'forwarding' || !data?.message ? 'Arquivo encaminhado' : message} justi={justi} contactColor={contactColor} />,
        audio: <Audio data={data} message={data?.message === 'forwarding' || !data?.message ? 'Arquivo encaminhado' : message} justi={justi} contactColor={contactColor} />,
    }

    const typesForwarding = {
        file: <File data={forwarding} message={forwarding?.message} justi={justi} contactColor={contactColor} />,
        text: <Text data={forwarding} message={forwarding?.message} justi={justi} contactColor={contactColor} />,
        audio: <Audio data={forwarding} message={forwarding?.message} justi={justi} contactColor={contactColor} />,
    }

    useEffect(() => {
        let newType = 'text'
        if (files && files.length > 0) {
            newType = 'file'
        }
        if (data.audio) {
            newType = 'audio'
        }
        setType(newType)


    }, [data])

    useEffect(() => {
        let newType = 'text'

        if (forwarding && forwarding.files && forwarding.files.length > 0) {
            newType = 'file'
        }
        if (forwarding && forwarding.audio) {
            newType = 'audio'
        }
        setFType(newType)
    }, [forwarding])

    return <>
        <>
            <div className="forwarding">
                {/* <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                    Encaminhada
                </div> */}
                <div className="forwarding-content">
                    {typesForwarding[fType]}
                </div>
            </div>
        </>
        {types[type]}
    </>
}
