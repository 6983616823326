import { useEffect, useState, useContext } from "react";
import { CalendarioContext } from "../../../contexts/CalendarioProvider";
import { UserContext } from "../../../contexts/UserProvider";
import "./styles.css";
import { Calendario } from "../../Agenda/Agendas/Calendario";

export const Agenda = () => {
  const calendarioContext = useContext(CalendarioContext);
  const userContext = useContext(UserContext);
  const [profissional, setProfissional] = useState({});
  const [agendas, setAgendas] = useState([]);

  useEffect(() => {
    const novasAgendas = calendarioContext.agendas
      ? [...calendarioContext.agendas]
      : [];
    setAgendas(novasAgendas);
  }, [calendarioContext.agendas]);

  useEffect(() => {
    const novoProfissional = userContext.user ? { ...userContext.user } : {};
    setProfissional(novoProfissional);
  }, [userContext.user]);

  return (
    <>
      <div className="agenda-profissional-container">
        <Calendario
          calendariosFiltrados={agendas.filter(
            (agenda) => agenda.profissional === profissional._id
          )}
          agendas={agendas}
        />
      </div>
    </>
  );
};
