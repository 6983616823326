import React from "react";
import { BsTrash3, BsPencilSquare } from "react-icons/bs";
import "./etapacard.css";

function EtapaCard(props) {
  return (
    <>
      <div className="etapa-card">
        <div className="etapa-card-header">
          <h3>{props.etapa.nome}</h3>
          <div className="etapa-card-footer">
            <div className="etapa-card-header-actions">
              <BsPencilSquare
                size={22}
                className="edit-icon"
                onClick={props.editEtapa}
              />
              <BsTrash3
                size={22}
                className="trash-icon"
                onClick={props.deleteEtapa}
              />
            </div>
          </div>
        </div>
        <div className="etapa-card-body">
          <div className="etapa-card-body-content-left">
            <p>Status:</p>
            <h5>{props.etapa.status ? props.etapa.status : "Aguardando.."}</h5>
            <p>Data:</p>
            <h5>{props.etapa.data}</h5>
            <p>Horário:</p>
            <h5>{props.etapa.hora}</h5>
          </div>
          <div className="etapa-card-body-content-right">
            <p>Mensagem:</p>
            <h5>{props.etapa.mensagem}</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default EtapaCard;
