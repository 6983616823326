import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../../../contexts/UserProvider";
import ListMembers from "./types/listMembers";

const AttGrupo = ({ grupo }) => {
  const { user } = useContext(UserContext);
  const [atendente, setAtendente] = useState(false);
  const [type, setType] = useState("");


  useEffect(() => {
    if (grupo?.atendentes) {
      let atendente = grupo.atendentes.find((atendente) => atendente._id === user._id);
      if (atendente) {
        setAtendente(true);
      } else {
        setAtendente(false);
      }
    }
  }, [grupo]);


  return (
    <div className="contact-info-modal">
      <div className="contact-info-contander-group" >
        <div className="contact-info-group" style={{marginTop:'10px'}}>
          <div className="contact-group-item">
            <span>Atendente</span>
            <p>{atendente ? 'SIM' : 'NÃO'}</p>
          </div>
          <div className="contact-group-item">
            <span>Total</span>
            <p>{grupo?.membros?.length}</p>
          </div>
        </div>
        <ListMembers membersList={grupo.membros} />
      </div>
    </div>
  );
};

export default AttGrupo;
