import React from "react";
import Grafico from "../../graphs/tempoEspera";
import "./card.css";

const transforToDDHHMMSS = (seconds) => {
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  let mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  seconds = Math.floor(seconds);

  return `${days ? days + "D" : ""} ${hrs ? hrs + "h" : ""} ${
    mnts ? mnts + "m" : ""
  } ${seconds ? seconds : ""}s`;
};

export default function CardEspera({
  grp,
  grpI,
  date,
  handCheckSelectId,
  generatePdf,
}) {
  return (
    <div key={grpI + 9878} className="relatorio-espera-card">
      <div className="container-button">
        <button className="button-download-relátorio" onClick={generatePdf}>
          Download relatório
        </button>
      </div>

      <div className="relatorio-espera-card-header">
        <h3>{grp.nome}</h3>
        <input
          type="checkbox"
          className="relatorio-espera-card-header-checkbox"
          onChange={() => handCheckSelectId(grp._id)}
        />
      </div>

      <div className="container-relatorio">
        <h4>Tempo médio de espera: </h4>
        <h4> {grp.espera}</h4>
      </div>

      <div className="container-relatorio">
        <h4>Total de atendimentos: </h4>
        <h4>{grp.data?.length}</h4>
      </div>

      <div className="container-relatorio-graphic">
        <Grafico
          nome={grp.nome}
          data={grp.data}
          date={date}
          id={`graph-${grp._id}`}
          transforToDDHHMMSS={transforToDDHHMMSS}
        />
      </div>
    </div>
  );
}
