import { useState, useEffect } from "react";
import { SwitchToggle } from "../SwitchToggle";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const Confirmacao = (props) => {
  const {
    onChangeConfirmation,
    AvisosCheckboxRow,
    view,
    setView,
    handleCancelServicoConfig,
    confirms,
    tempoConfirm,
    setTempoConfirm,
    salvarServicoConfig,
    onChangeMensagem,
    mensagemConfirmacao,
    msgConfirmacaoEmail,
    setMsgConfirmacaoEmail,
  } = props;
  const [toggleType, setToggleType] = useState(true);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(msgConfirmacaoEmail)
    )
  );
  const [tempoConfirmDias, setTempoConfirmDias] = useState(0);
  const [tempoConfirmHoras, setTempoConfirmHoras] = useState(0);
  const [tempoConfirmMinutos, setTempoConfirmMinutos] = useState(0);

  const calcularValores = (tempo) => {
    const segundos = tempo / 1000;
    const dias = Math.floor(segundos / (24 * 60 * 60));
    const horas = Math.floor((segundos % (24 * 60 * 60)) / (60 * 60));
    const minutos = Math.floor(((segundos % (24 * 60 * 60)) % (60 * 60)) / 60);
    return { dias, horas, minutos };
  };

  useEffect(() => {
    const { dias, horas, minutos } = calcularValores(tempoConfirm);
    setTempoConfirmDias(dias);
    setTempoConfirmHoras(horas);
    setTempoConfirmMinutos(minutos);
  }, [tempoConfirm]);

  const calcularTempoConfirm = (dias, horas, minutos) => {
    return (dias * 24 * 60 * 60 + horas * 60 * 60 + minutos * 60) * 1000;
  };

  const handleDiasChange = (e) => {
    const valor = e.target.value;

    if (isNaN(valor) || parseInt(valor) < 0 || valor === "") {
      setTempoConfirm(
        calcularTempoConfirm(0, tempoConfirmHoras, tempoConfirmMinutos)
      );
      setTempoConfirmDias(0);
    } else {
      const valorSemZerosAEsquerda = valor.replace(/^0+/, "");

      if (
        valorSemZerosAEsquerda === "0" ||
        /^[0-9]+$/.test(valorSemZerosAEsquerda)
      ) {
        setTempoConfirm(
          calcularTempoConfirm(
            valorSemZerosAEsquerda,
            tempoConfirmHoras,
            tempoConfirmMinutos
          )
        );
        setTempoConfirmDias(valorSemZerosAEsquerda);
      } else {
        setTempoConfirm(
          calcularTempoConfirm(0, tempoConfirmHoras, tempoConfirmMinutos)
        );
        setTempoConfirmDias(0);
      }
    }
  };

  const handleHorasChange = (e) => {
    const valor = parseInt(e.target.value);
    if (isNaN(valor) || valor < 0) {
      if (tempoConfirmDias > 0) {
        setTempoConfirm(
          calcularTempoConfirm(tempoConfirmDias - 1, 23, tempoConfirmMinutos)
        );
        setTempoConfirmDias(tempoConfirmDias - 1);
        setTempoConfirmHoras(23);
      } else {
        setTempoConfirm(calcularTempoConfirm(0, 0, tempoConfirmMinutos));
        setTempoConfirmHoras(0);
      }
    } else if (valor >= 24) {
      const novoDias = tempoConfirmDias + Math.floor(valor / 24);
      const novaHora = valor % 24;
      setTempoConfirm(
        calcularTempoConfirm(novoDias, novaHora, tempoConfirmMinutos)
      );
      setTempoConfirmDias(novoDias);
      setTempoConfirmHoras(novaHora);
    } else {
      setTempoConfirm(
        calcularTempoConfirm(tempoConfirmDias, valor, tempoConfirmMinutos)
      );
      setTempoConfirmHoras(valor);
    }
  };

  const handleMinutosChange = (e) => {
    const valor = parseInt(e.target.value);
    if (isNaN(valor) || valor < 0) {
      if (tempoConfirmHoras > 0) {
        setTempoConfirm(
          calcularTempoConfirm(tempoConfirmDias, tempoConfirmHoras - 1, 59)
        );
        setTempoConfirmHoras(tempoConfirmHoras - 1);
        setTempoConfirmMinutos(59);
      } else if (tempoConfirmDias > 0) {
        setTempoConfirm(calcularTempoConfirm(tempoConfirmDias - 1, 23, 59));
        setTempoConfirmDias(tempoConfirmDias - 1);
        setTempoConfirmHoras(23);
        setTempoConfirmMinutos(59);
      } else {
        setTempoConfirm(calcularTempoConfirm(0, 0, 0));
        setTempoConfirmMinutos(0);
      }
    } else if (valor >= 60) {
      const novoHoras = tempoConfirmHoras + Math.floor(valor / 60);
      const novosMinutos = valor % 60;
      if (novoHoras >= 24) {
        const novoDias = tempoConfirmDias + Math.floor(novoHoras / 24);
        const novaHora = novoHoras % 24;
        setTempoConfirm(calcularTempoConfirm(novoDias, novaHora, novosMinutos));
        setTempoConfirmDias(novoDias);
        setTempoConfirmHoras(novaHora);
      } else {
        setTempoConfirm(
          calcularTempoConfirm(tempoConfirmDias, novoHoras, novosMinutos)
        );
        setTempoConfirmHoras(novoHoras);
      }
      setTempoConfirmMinutos(novosMinutos);
    } else {
      setTempoConfirm(
        calcularTempoConfirm(tempoConfirmDias, tempoConfirmHoras, valor)
      );
      setTempoConfirmMinutos(valor);
    }
  };

  const onChange = (state) => {
    setToggleType(!state);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setMsgConfirmacaoEmail(editorState.getCurrentContent());
  };

  const editorStyle = {
    lineHeight: "normal",
  };

  // useEffect(() => {
  //   setMsgConfirmacaoEmail(editorState.getCurrentContent());
  // }, [editorState, setMsgConfirmacaoEmail]);

  return (
    <>
      <legend>Mensagem Confirmação</legend>
      <SwitchToggle
        label={toggleType ? "Bot" : "E-mail"}
        isToggled={toggleType}
        onClick={onChange}
        isRightToLeft={!toggleType}
      />
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="mensagemConfirmacao">Mensagem Confirmação</label>
          {toggleType ? (
            <textarea
              className="servico-form-textarea"
              name="mensagemConfirmacao"
              id="mensagemConfirmacao"
              cols="100"
              rows="10"
              value={mensagemConfirmacao}
              onChange={onChangeMensagem}
            />
          ) : (
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              editorStyle={editorStyle}
            />
          )}
        </div>
      </div>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="aviso">Confirmar Agendamento:</label>
          <AvisosCheckboxRow
            avisos={confirms}
            onChangeAviso={onChangeConfirmation}
          />
        </div>
      </div>
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="aviso">Tempo para Confirmação:</label>
          <label>
            Dias:
            <input
              className="servico-config-form-input-dias"
              type="number"
              value={tempoConfirmDias}
              onChange={handleDiasChange}
            />
          </label>
          <label>
            Horas:
            <input
              className="servico-config-form-input-horas"
              type="number"
              value={tempoConfirmHoras}
              onChange={handleHorasChange}
            />
          </label>
          <label>
            Minutos:
            <input
              className="servico-config-form-input-minutos"
              type="number"
              value={tempoConfirmMinutos}
              onChange={handleMinutosChange}
            />
          </label>
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="prev" onClick={() => setView(view - 1)}>
          Anterior
        </button>
        <button type="button" id="next" onClick={() => setView(view + 1)}>
          Próximo
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
};
