export const ContatosSelecionados = ({
  tipoContatoDados,
  tipoContato,
  contatosSelecionados,
  gruposSelecionados,
}) => {
  return (
    <div className="convite-bot-selecionados">
      <h3>{tipoContatoDados[tipoContato].title}</h3>
      {contatosSelecionados.length === 0 && tipoContato === "contatos" ? (
        <p>Nenhum contato selecionado</p>
      ) : (
        gruposSelecionados.length === 0 &&
        tipoContato === "grupo" && <p>Nenhum grupo selecionado</p>
      )}
      <div className="convite-bot-selecionados-lista">
        {tipoContatoDados[tipoContato].data.map((item, index) =>
          tipoContatoDados[tipoContato].render(item, index)
        )}
      </div>
    </div>
  );
};
