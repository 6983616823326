
import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../../../../contexts/UserProvider'
// import ApiUsers from '../../../../../ApiUsers'
import { PrefContext } from '../../../../../../contexts/ChatBotProvider/PrefProvider'
import UserCreatRestriction from '../../UserCreateNEW/userCreatRestriction'
const LinkForm = (props) => {
    const { setLinkState } = props
    const [link, setLink] = useState('')
    const [limit, setLimit] = useState(100)
    const [dep, setDep] = useState('')
    // const [setor, setSetor] = useState('')
    const context = useContext(UserContext)
    const prefContext = useContext(PrefContext)
    const { user, pref } = context

    const [atendente, setAtendente] = useState(false)
    const [profissional, setProfissional] = useState(false)
    const [atendenteGrupo, setAtendenteGrupo] = useState(false)
    const [restricao, setRestricao] = useState({
        ativo: false,
        intervalos: [],
    })
    const [_id, set_id] = useState('')
    const [users, setUsers] = useState([]);

    const saveLink = () => {
        setLinkState(false)
        let newLink = { link, limit, dep, atendente, profissional, _id, users, atendenteGrupo, restricao }
        props.addNewLink(newLink)
    }

    useEffect(() => {

        if (props.link) {
            setLink(props.link.link)
            setLimit(props.link.limit)
            // setSetor(props.link.setor)
            set_id(props.link._id)
            setUsers(props.link.users)
            setDep(props.link.dep)
            if (props.link.restricao) {
                console.log(props.link.restricao)
                setRestricao(props.link.restricao)
            }
            props.link.atendente ? setAtendente(true) : setAtendente(false)
            props.link.profissional ? setProfissional(true) : setProfissional(false)
        }
    }, [props.link])

    const changeUserIntervalo = (intervalos) => {
        setRestricao(prevRestricao => ({
            ...prevRestricao,
            intervalos: intervalos,
        }));
    };

    const changeUserFuncionamento = (inicio, fim, dias) => {
        setRestricao(prevRestricao => ({
            ...prevRestricao,
            horarioFuncionamento: {
                inicio: inicio,
                fim: fim,
                dias: dias
            }
        }));    
    }


    return (
        <div className='link-form-modal'>
            <div className='links-form '>
                {/* <br /> */}
                {/* RADIO range INPUT */}
                <div className="links-form-input-container">
                    <div className="links-form-input-container-left">
                        {
                            <label htmlFor="limit">Limite de Cadastros: <span>{limit}</span></label>
                        }
                    </div>
                    <div className="links-form-input-container-right">
                        <input type="range" min="0" max="100" step="1" value={limit} onChange={
                            (e) => {
                                setLimit(e.target.value)
                            }
                        } />
                    </div>
                </div>

                {/* SELECT INPUT DEP E SET*/}
                <div className="links-form-input-container">
                    <div className="links-form-input-container-left">
                        <label htmlFor="Departamento">Departamento:</label>
                    </div>
                    <div className="links-form-input-container-right">
                        <select name="Departamento" id="Departamento" value={dep}
                            onChange={(e) => {
                                setDep(e.target.value)
                            }}>
                            <option value="">Selecione</option>
                            {
                                prefContext.departamentos.map((depart, index) => {
                                    return (
                                        <option key={index} value={depart._id} >{depart.nome}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                {/* <label htmlFor="Setor">Setor</label>
                <select name="Setor" id="Setor" onChange={(e) => {
                    setSetor(e.target.value)
                }}>
                    <option value="0">Selecione</option>
                    {
                        dep && prefContext.setores.map((setor, index) => {
                            let selected = false
                            if (setor._id === setor) {
                                selected = true
                            }
                            return (
                                <option selected={selected} key={index} value={setor._id}>{setor.nome}</option>
                            )
                        })
                    }
                </select> */}

                {/* SELECT INPUT CHECKBOX ATENDENTE E PROFISSIONAL */}
                {
                    pref.services.voxbot.ativo &&
                    <div className="links-form-input-container">
                        <div className="links-form-input-container-left">
                            <label htmlFor="Atendente">Atendente:</label>
                        </div>
                        <div className="links-form-input-container-right">
                            <input type="checkbox" name="Atendente" id="Atendente" checked={atendente}
                                onChange={(e) => setAtendente(e.target.checked)} />
                        </div>
                    </div>
                }
                {
                    pref.services.agenda?.ativo &&
                    <div className="links-form-input-container">
                        <div className="links-form-input-container-left">
                            <label htmlFor="Profissional">Profissional:</label>
                        </div>
                        <div className="links-form-input-container-right">
                            <input type="checkbox" name="Profissional" id="Profissional" checked={profissional} onChange={(e) => {
                                console.log(e.target.checked)
                                setProfissional(e.target.checked)
                            }} />
                        </div>
                    </div>

                }
                {
                    pref.services.voxbot.ativo &&
                    <div className="links-form-input-container">
                        <div className="links-form-input-container-left">
                            <label htmlFor="AtendenteGrupo">Atendente Grupo:</label>
                        </div>
                        <div className="links-form-input-container-right">
                            <input type="checkbox" name="AtendenteGrupo" id="AtendenteGrupo" checked={atendenteGrupo} onChange={(e) => setAtendenteGrupo(e.target.checked)} />
                        </div>
                    </div>
                }

                {
                    pref.services.voxbot.ativo &&
                    <div className="links-form-input-container">
                        <div className="links-form-input-container-left" style={{ width: '190px' }}>
                            <label htmlFor="Restricao">Restrição de Horário:</label>
                        </div>
                        <div className="links-form-input-container-right" >
                            <input style={{ marginRight: 40 }} type="checkbox" name="Restricao" id="Restricao" checked={restricao.ativo} onChange={(e) => setRestricao({ ...restricao, ativo: e.target.checked })} />
                        </div>
                    </div>
                }

                {
                    restricao.ativo &&
                    <UserCreatRestriction changeUserIntervalo={changeUserIntervalo} restricao={restricao} changeUserFuncionamento={changeUserFuncionamento} />
                }


                <div className="links-form-button-container">
                    <button className="btn-cancel-link" onClick={props.cancel}>Cancelar</button>
                    <button className="btn-save-link" onClick={saveLink}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default LinkForm