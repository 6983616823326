export const profiFormInputs = [
  {
    label: "Período de atendimento",
    key: "periodoAtendimento",
    type: "object",
    subKeys: [
      {
        label: "Início",
        key: "inicio",
        inputType: "date",
      },
      {
        label: "Fim",
        key: "fim",
        inputType: "date",
      },
    ],
  },
  {
    label: "Espaço de Agendamento",
    key: "espacoAgendamento",
    type: "input",
    inputType: "time",
  },
  {
    label: "Horários de intervalo",
    key: "horarioIntervalo",
    type: "arrayObject",
    subKeys: [
      {
        label: "Início",
        key: "inicio",
        inputType: "time",
      },
      {
        label: "Fim",
        key: "fim",
        inputType: "time",
      },
      {
        label: "Dias da semana",
        key: "dias",
        inputType: "checkbox",
      },
    ],
    button: {
      label: "Adicionar horário de intervalo",
      action: "addToInputList",
    },
  },
];
