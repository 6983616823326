import React, { useContext } from "react";
import { FlowBotContext } from "../../../../../../contexts/ChatBotProvider/FlowBotProvider";
import { UserContext } from "../../../../../../contexts/UserProvider";
import * as XLSX from "xlsx";

const ButtonExport = ({ text, contacts, title }) => {
  const userContext = useContext(UserContext);
  const flowbotContext = useContext(FlowBotContext);
  const { variables } = flowbotContext;

  const xlsExport = () => {
    const exportData = contacts.map((contact) => {
      let flow = flowbotContext.flows.find((f) => {
        return f._id === contact.flow;
      });
      flow = flow ? flow.nome : "Nenhum";
      let bot = flowbotContext.bots.find((b) => {
        return b._id === contact.bot;
      });
      bot = bot ? bot.nome : "Nenhum";
      let atendente = userContext.pref.users.find((u) => {
        return u._id === contact.atendente;
      });
      atendente = atendente ? atendente.nome : "Nenhum";
      let obj = {
        "Nome do Whatsapp": contact.pushname,
        Telefone: contact.telefone,
        Email: contact.email,
        Nome: contact.Nome,
        Flow: flow,
        Estágio: contact.stage,
        Bot: bot,
        Atendente: atendente,
      };
      variables.forEach((v) => {
        obj[v.nome] = contact[v.nome];
      });
      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(exportData);
    ws["!cols"] = [
      {
        wch: 30,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
      {
        wch: 20,
      },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contatos");
    XLSX.writeFile(wb, "Contatos.xlsx");
  };
  return (
    <div className="container-buttons-contact-header">
      <button onClick={xlsExport} title={title}>
        {text}
      </button>
    </div>
  );
};

export default ButtonExport;
