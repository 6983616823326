import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserProvider";

export const SpecialtyContext = createContext();

export const SpecialtyProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const socket = userContext.socket;
  const agenda = userContext.pref.services.agenda;
  const [especialidades, setEspecialidades] = useState(
    agenda ? agenda.especialidades : []
  );

  useEffect(() => {
    const especialidades = agenda
      ? agenda.especialidades
        ? agenda.especialidades
        : []
      : [];
    setEspecialidades([...especialidades]);
  }, [agenda]);

  const addEspecialidade = (especialidade) => {
    setEspecialidades([...especialidades, especialidade]);
  };

  const deleteEspecialidade = (especialidade) => {
    setEspecialidades(
      especialidades.filter(
        (especialidadeSelecionada) =>
          especialidadeSelecionada._id !== especialidade._id
      )
    );
  };

  const updateEspecialidade = (especialidade) => {
    setEspecialidades(
      especialidades.map((especialidadeSelecionada) =>
        especialidade._id === especialidadeSelecionada._id
          ? especialidade
          : especialidadeSelecionada
      )
    );
  };

  function initSocket() {
    socket.on("nova especialidade", (especialidade) => {
      addEspecialidade(especialidade);
    });

    socket.on("delete especialidade", (especialidade) => {
      deleteEspecialidade(especialidade);
    });

    socket.on("update especialidade", (especialidade) => {
      updateEspecialidade(especialidade);
    });
  }

  function socketOff() {
    socket.off("nova especialidade");
  }

  useEffect(() => {
    if (socket) initSocket();

    return () => {
      socketOff();
    };
  }, [socket, especialidades]);

  return (
    <SpecialtyContext.Provider value={{ especialidades }}>
      {children}
    </SpecialtyContext.Provider>
  );
};
