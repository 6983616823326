import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../../../contexts/UserProvider'
import ApiUsers from '../../../../../ApiUsers'
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider'
import LinkForm from './comps/LinkForm'
import List from './comps/List'
import "./style.css"

export default function Index() {
  const context = useContext(UserContext)
  const prefContext = useContext(PrefContext)
  const [links, setLinks] = useState([])
  const [linkState, setLinkState] = useState(false)
  const [link, setLink] = useState(false)

  const [view, setView] = useState('list')

  const addNewLink = async (link) => {
    if (!link._id) {
      await ApiUsers.post('/links/new', { link }).then((res) => {
        //console.log('new', res)
      }).catch((err) => { console.log(err) })
    }
    else {
      await ApiUsers.post('/links/edit', { link }).then((res) => {
        //console.log('edit', res)
      }).catch((err) => { console.log(err) })
    }
    cancel()
  }

  const deleteLink = async (link) => {
    try {
      const response = await ApiUsers.post('/links/delete', {link});
      if (response.data.error) { 
        console.log(response.data.error);
      }
      const updatedLinks = links.filter(l => l._id !== link._id);
      setLinks(updatedLinks);
      prefContext.setLinks(updatedLinks);
      //console.log(updatedLinks);
    } catch (error) {
        console.log(error.response.data.error);
    }
  };

  const cancel = () => {
    setLinkState(false)
    setLink(false)
    setView('list')
  }

  useEffect(() => {
    let newLinks = prefContext.links ? [...prefContext.links] : [];
    setLinks(newLinks)
  }, [prefContext.links])
  const editLink = (link) => {
    setLink(link)
    setLinkState(true)
    setView('form')
  }

  const views = {
    list: <List setLinkState={setLinkState} setLink={setLink} links={links} editLink={editLink} deleteLink={deleteLink} />,
    form: <LinkForm setLinkState={setLinkState} link={link} addNewLink={addNewLink} cancel={cancel} />
  }


  return (
    <>
      <div className='links-header'>
        <h2>Links</h2>
        {view !== 'form' &&
          <button onClick={() => {
            setLinkState(true)
            setLink(false)
            setView('form')
          }} className='btn-add-link'>Novo Link</button>
        }
      </div>
      <div className='links-body'>
        {views[view]}
      </div>
    </>
  )
}
