import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts/UserProvider";
import { CalendarioContext } from "../../../contexts/CalendarioProvider";
import { withZero, getHMtoMs } from "../../miscelaneous/utils";

export const CalendarFunctions = () => {
  const { pref } = useContext(UserContext);
  const calendarioContext = useContext(CalendarioContext);
  const [prefeitura, setPrefeitura] = useState({});
  const [listaAgendas, setListaAgendas] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState("");
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioFuncionamento, setHorarioFuncionamento] = useState({});
  const [horariosIntervalo, setHorariosIntervalo] = useState([]);

  useEffect(() => {
    let newPrefeitura = pref ? pref.services.agenda : {};
    let newAgendas = calendarioContext ? [...calendarioContext.agendas] : [];
    setPrefeitura(newPrefeitura);
    setListaAgendas(newAgendas);
  }, [pref, calendarioContext]);

  const getAgenda = async (data, callback) => {
    let local = prefeitura.locais.find((local) => local._id === data.local);
    let horarioFuncionamento = local?.especialidades?.find(
      (especialidade) => especialidade._id === data.especialidade
    )?.horarioFuncionamento;

    let horarioIntervalo = local?.especialidades
      ?.find((especialidade) => especialidade._id === data.especialidade)
      .profissionais.flatMap((profissional) => profissional.horarioIntervalo);

    let gap;
    let limit = 2000;
    let agendas = [...listaAgendas];

    setHorariosIntervalo(horarioIntervalo);
    setHorarioFuncionamento(horarioFuncionamento);

    for (let i = 0; i < agendas.length; i++) {
      if (agendas[i]._id === data._id) {
        let agenda = agendas[i];
        let profissa = local.especialidades
          .find((especialidade) => especialidade._id === data.especialidade)
          .profissionais.find(
            (profissional) => profissional._id === data.profissional
          );
        let datasMarcadas = agenda.datasMarcadas;
        if (!profissa || !profissa.espacoAgendamento) {
          delete agendas[i];
        } else {
          let periodoAtt = profissa.periodoAtendimento;
          let espacoAgendamento = profissa?.espacoAgendamento;
          agenda.disponibilidade = await getDisponibilidade(
            {
              datasMarcadas,
              horarioFuncionamento,
              horarioIntervalo,
              espacoAgendamento,
              periodoAtt,
            },
            gap,
            limit
          );

          let horariosDisponiveisDia = [];
          agenda.disponibilidade.forEach((e) => {
            if (e.slice(0, 15) === diaSelecionado.toString().slice(0, 15)) {
              horariosDisponiveisDia.push(e);
            }
          });
          setHorariosDisponiveis(horariosDisponiveisDia);
        }
      }
    }
  };

  const getDisponibilidade = async (agenda, gap, limit = 2000) => {
    let disponibilidade = [];
    let dateStart = await regularizeDateStart(agenda);
    let { periodoAtt, horarioFuncionamento } = agenda;
    let { inicio, fim } = periodoAtt;
    let begin = inicio.split("-");
    let finish = fim.split("-");
    dateStart = new Date(begin[0], begin[1] - 1, begin[2]);
    let timeToStart = horarioFuncionamento.inicio.split(":");
    dateStart.setHours(timeToStart[0]);
    dateStart.setMinutes(timeToStart[1]);
    fim = new Date(finish[0], finish[1] - 1, finish[2], 23, 59);

    if (isNaN(gap)) {
      console.log("sem gap");
      while (dateStart < fim) {
        let possibleDate = await recursiveNextPossibleDate(
          dateStart,
          agenda,
          disponibilidade
        );
        dateStart = possibleDate;
        if (possibleDate > fim) break;
        else {
          disponibilidade.push(possibleDate.toString());
          if (disponibilidade.length > limit) break;
        }
      }
      return disponibilidade;
    } else {
      console.log("com gap");
      for (let i = 0; i < gap; i++) {
        let possibleDate = await recursiveNextPossibleDate(
          dateStart,
          agenda,
          disponibilidade
        );
        dateStart = possibleDate;
        if (possibleDate > fim) break;
        else {
          disponibilidade.push(possibleDate.toString());
          if (disponibilidade.length > limit) break;
        }
      }
      return disponibilidade;
    }
  };

  const regularizeDateStart = (agenda) => {
    let { horarioFuncionamento, espacoAgendamento } = agenda;
    let dateNow = new Date();
    let day = dateNow.getDay();
    let hour =
      withZero(dateNow.getHours()) +
      ":" +
      withZero(dateNow.getMinutes()).toString();
    let onTime =
      horarioFuncionamento.dias.includes(day) &&
      hour > horarioFuncionamento.inicio &&
      hour < horarioFuncionamento.fim;
    if (onTime) {
      let minutes = dateNow.getMinutes();
      let minEspace = espacoAgendamento.split(":")[1];
      if (minutes >= 0 && minutes <= parseInt(minEspace)) {
        dateNow.setMinutes(minEspace);
        dateNow.setSeconds(0);
        dateNow.setMilliseconds(0);
      } else {
        dateNow.setMinutes(0);
        dateNow.setHours(dateNow.getHours() + 1);
        dateNow.setSeconds(0);
        dateNow.setMilliseconds(0);
      }
      return dateNow;
    } else {
      let firstHourOn = horarioFuncionamento.inicio;
      dateNow.setHours(firstHourOn.split(":")[0]);
      dateNow.setMinutes(firstHourOn.split(":")[1]);
      dateNow.setSeconds(0);
      return dateNow;
    }
  };

  const recursiveNextPossibleDate = async (date, agenda, disponibilidade) => {
    let {
      datasMarcadas,
      horarioFuncionamento,
      horarioIntervalo,
      espacoAgendamento,
    } = agenda;
    let newSetDate = new Date(date);
    newSetDate.setHours(newSetDate.getHours() - 3);
    newSetDate.setMilliseconds(0);
    let isAlreadyMarked = disponibilidade?.find((d) => d === date.toString());
    let dayMarked = datasMarcadas?.find(
      (d) => d?.data === newSetDate.toISOString()
    );

    if (isAlreadyMarked || dayMarked) {
      let newDate = new Date(date.getTime() + getHMtoMs(espacoAgendamento));
      return recursiveNextPossibleDate(newDate, agenda, disponibilidade);
    }

    console.log(newSetDate)







    if (await isOnInterval(date, horarioFuncionamento, horarioIntervalo)) {
      let day = newSetDate.getDay();
      let hour = (newSetDate.toString().split(" ")[4].split(":")[0] + ":" + newSetDate.toString().split(" ")[4].split(":")[1]).toString();
      let onWorkTime = horarioFuncionamento.dias.includes(day) && hour >= horarioFuncionamento.inicio && hour < horarioFuncionamento.fim; // variavel de verificação do intervalo de atendimento
      if (!onWorkTime) {
        newSetDate.setHours(horarioFuncionamento.inicio.split(':')[0]);
        newSetDate.setMinutes(horarioFuncionamento.inicio.split(':')[1]);
        newSetDate.setSeconds(0);

        newSetDate.setDate(newSetDate.getDate() + 1);
        return recursiveNextPossibleDate(newSetDate, agenda, disponibilidade);
      }
      else {
        let newDate = new Date(date.getTime() + getHMtoMs(espacoAgendamento));
        return recursiveNextPossibleDate(newDate, agenda, disponibilidade);
      }
    } else {
      return date;
    }
  };

  const isOnInterval = (dateNow, horarioFuncionamento, intervalos) => {
    let day = dateNow.getDay();
    let hour = (
      dateNow.toString().split(" ")[4].split(":")[0] +
      ":" +
      dateNow.toString().split(" ")[4].split(":")[1]
    ).toString();
    let onWorkTime =
      horarioFuncionamento.dias.includes(day) &&
      hour >= horarioFuncionamento.inicio &&
      hour < horarioFuncionamento.fim; // variavel de verificação do intervalo de atendimento
    let onInterval = false;
    if (onWorkTime) {
      intervalos.forEach((intervalo) => {
        if (
          intervalo.dias.includes(day) &&
          hour >= intervalo.inicio &&
          hour < intervalo.fim
        ) {
          onInterval = true;
        }
      });
    }
    return onInterval || !onWorkTime;
  };

  return {
    getAgenda,
    horariosDisponiveis,
    horarioFuncionamento,
    horariosIntervalo,
    diaSelecionado,
    setDiaSelecionado,
  };
};
