import { useState, useRef } from "react";
import ApiUsers from "../../../ApiUsers";
import MiniLoading from "../../Configurações/OptionsScreen/inputs/MiniLoading";

const ProfileHeader = ({ usuario, createAlert }) => {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const uploadImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    ApiUsers.post("/upload/user", formData)
      .then((resp) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Sua foto de perfil foi atualizada com sucesso!",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };
  return (
    <div className="profile-header">
      <img
        className="profile-image"
        src={usuario?.foto ? usuario.foto : "/imgs/avatar2.png"}
        alt="Foto de perfil"
      />
      <div className="profile-upload-image">
        {loading ? (
          <MiniLoading />
        ) : (
          <>
            <img
              src="imgs/editAvatar2.svg"
              style={{ width: 30, height: 30, cursor: "pointer" }}
              alt="upload"
              onClick={handleImageClick}
            />
            <input
              ref={inputRef}
              onChange={uploadImage}
              type="file"
              name="myimg"
              id="myimg"
              style={{ display: "none" }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
