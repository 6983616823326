import { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";

export const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const socket = userContext.socket;
  const agenda = userContext.pref.services.agenda;
  const [servicos, setServicos] = useState(agenda ? agenda.serviços : []);

  const addServico = (servico) => {
    setServicos([...servicos, servico]);
  };

  const deleteServico = (servico) => {
    setServicos(
      servicos.filter(
        (servicoSelecionado) => servicoSelecionado._id !== servico._id
      )
    );
  };

  const updateServico = (servico) => {
    setServicos(
      servicos.map((servicoSelecionado) =>
        servico._id === servicoSelecionado._id ? servico : servicoSelecionado
      )
    );
  };

  function initSocket() {
    socket.on("novo service", (servico) => {
      addServico(servico);
    });

    socket.on("delete service", (servico) => {
      deleteServico(servico);
    });

    socket.on("update service", (servico) => {
      updateServico(servico);
    });
  }

  function socketOff() {
    socket.off("novo service");
  }

  useEffect(() => {
    if (socket) initSocket();

    return () => {
      socketOff();
    };
  }, [socket, servicos]);

  useEffect(() => {
    let services = agenda ? (agenda.serviços ? agenda.serviços : []) : [];
    setServicos([...services]);
  }, [agenda]);

  return (
    <ServiceContext.Provider value={{ servicos }}>
      {children}
    </ServiceContext.Provider>
  );
};
