import React, { useState, useEffect, useRef } from "react";
import CampanhaBoxOFSelects from "./components/campanhaBoxOfSelects";
import CampanhasBoxOfIcons from "./components/campanhasBoxOfIcons";
import EtapaForm from "./EtapaForm/EtapaForm";
import EtapaCard from "./EtapaForm/EtapaCard/EtapaCard";
import AddContatos from "./AddContatos/Index";
import ButtonAnimation from "../../../ButtonAnimation/button";
import RemovidosModal from "./components/removidosModal";
import "./campanhaform.css";

function CampanhaForm(props) {
  const [nome, setNome] = useState("");
  const [bot, setBot] = useState("");
  const [criador, setCriador] = useState("");
  const [contatos, setContatos] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [removidos, setRemovidos] = useState([]);
  const [etapa, setEtapa] = useState(null);
  const [indexEtapa, setIndexEtapa] = useState(etapas.length);
  const [openContatosForm, setOpenContatosForm] = useState(false);
  const [removidosModal, setRemovidosModal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    setNome(props.campanha.nome);
    setBot(props.campanha.bot);
    setCriador(props.campanha.criador);
    setContatos(props.campanha.contatos);
    setEtapas(props.campanha.etapas);
    setRemovidos(props.campanha.removidos ? props.campanha.removidos : []);
  }, [props.campanha]);

  function saveCampanha() {
    if (etapas.length === 0) {
      alert("Você precisa adicionar pelo menos uma etapa");
      return;
    }
    let campanha = {
      nome: nome,
      bot: bot,
      criador: criador,
      contatos: [...contatos],
      etapas: [...etapas],
      removidos: [...removidos],
    };
    campanha = Object.assign(props.campanha, campanha);
    props.updateCampanhas(campanha);
    props.setSelected(null);
  }

  function saveEtapa(etapa) {
    let index = indexEtapa;
    let newEtapas = [...etapas];
    if (newEtapas[index]) {
      newEtapas[index] = etapa;
    } else {
      newEtapas.push(etapa);
    }
    setEtapas(newEtapas);
    setEtapa(null);
  }

  const handleAddEtapa = () => {
    setEtapa({
      nome: "",
      data: "",
      hora: "",
      mensagem: "",
      img: "",
      audio: "",
      video: "",
      arquivo: "",
      alternativas: [],
    });
    if (etapas.length === 0) setIndexEtapa(0)
    else setIndexEtapa(etapas.length);
  };

  const handleEditEtapa = (etapa, index) => {
    setEtapa(etapa);
    setIndexEtapa(index);
  }

  const handleDeleteEtapa = (index) => {
    let newEtapas = [...etapas];
    newEtapas.splice(index, 1);
    setEtapas(newEtapas);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.setSelected(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div className="campanha-bc-modal">
      <div className="campanha-form" ref={modalRef}>
        {openContatosForm && (
          <div className="show-form">
            <AddContatos contatos={contatos} setContatos={setContatos} setOpenContatosForm={setOpenContatosForm} />
          </div>
        )}
        {removidosModal && (
          <div className="show-form">
            <RemovidosModal removidos={removidos} setRemovidosModal={setRemovidosModal} />
          </div>
        )}
        <div className="campanha-form-header">
          <h3>Campanha</h3>
        </div>
        {etapa && (
          <div className="show-form">
            <EtapaForm etapa={etapa} setEtapa={setEtapa} etapas={etapas} setEtapas={setEtapas} saveEtapa={saveEtapa} />
          </div>
        )}

        <div className="campanha-form-body">
          <div className="campanha-form-body-left">
            <CampanhaBoxOFSelects nome={nome} setNome={setNome} bot={bot} setBot={setBot} criador={criador} />
          </div>

          <div className="campanha-space-et-cont">
            <CampanhasBoxOfIcons contatos={contatos} removidos={removidos} setOpenContatosForm={setOpenContatosForm} handleAddEtapa={handleAddEtapa} setRemovidosModal={setRemovidosModal}/>
          </div>

          <div className="campanha-form-body-right">
            <div className="campanha-form-body-right-item-etapas">
              {etapas.length > 0 ? (
                etapas.map((etapa, index) => {
                  return <EtapaCard type="text" etapa={etapa} key={index} editEtapa={(e) => handleEditEtapa(etapa, index)} deleteEtapa={(e) => handleDeleteEtapa(index)} />
                })
              ) : (
                <ButtonAnimation title="Adicionar Primeira Etapa" onClick={(e) => handleAddEtapa()} />
              )}
            </div>
          </div>
        </div>

        <div className="campanha-form-footer">
          <button className="cancel-button" disabled={props.loading} style={{marginBottom:0}} onClick={(e) => props.setSelected(null)}>Cancelar</button>
          <button className="save-button" disabled={props.loading}  onClick={saveCampanha}>Salvar</button>
        </div>
      </div>
    </div>
  );
}

export default CampanhaForm;