import React from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';

const Painel = ({ variables, deleteVariable, loading, editVariable }) => {
    return (
        <div className="variables-painel-container">

            <div className="variable-views">
                {variables.length > 0 ? (
                    <>
                        {variables.map((variable, index) => (
                            <div
                                className="variable-item"
                                key={index}
                                style={{
                                    backgroundColor: `${index % 2 === 0 ? "#26bb90" : "#168aad"}`,
                                }}
                            >
                                <div className="variable-details">
                                    <span>
                                        <b>Nome:</b> {variable.nome}
                                    </span>
                                    <span>
                                        <b>Máscara:</b> {variable.mascara}
                                    </span>
                                    <span>
                                        <b>Reatribuição:</b>{" "}
                                        {variable.reatribuir ? "Ativada" : "Desativada"}
                                    </span>
                                </div>
                                <div className="variable-actions">
                                    <FaEdit
                                        className="variable-del-icon"
                                        onClick={loading ? null : () => editVariable(variable)}
                                    />

                                    <FaTrash
                                        className="variable-del-icon"
                                        onClick={loading ? null : () => deleteVariable(variable)}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <img
                            src="/imgs/cloud.png"
                            className="empty-img"
                            alt="cloud"
                            style={{ width: 170, margin: "20px 0px" }}
                        />
                        <h5>Não há variáveis disponíveis</h5>
                    </>
                )}
            </div>
        </div>
    );
};

export default Painel;