import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../../../contexts/UserProvider";

export const ConviteBotContatos = ({
  botSelecionado,
  contatosSelecionados,
  setContatosSelecionados,
  pesquisa,
  setTotalPages,
  contactsPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  formatarTelefone,
}) => {
  const { pref } = useContext(UserContext);
  const [contatos, setContatos] = useState([]);
  const [contatosFiltrados, setContatosFiltrados] = useState([]);

  const handleSelectContact = (idContato) => {
    const contato = contatosFiltrados.find(
      (contato) => contato._id === idContato
    );
    contato.bot = botSelecionado;
    if (contatosSelecionados?.includes(contato)) {
      setContatosSelecionados(
        contatosSelecionados.filter((contato) => contato._id !== idContato)
      );
    } else {
      setContatosSelecionados([...contatosSelecionados, contato]);
    }
  };
  const checkContato = (idContato) => {
    return contatosSelecionados.some((contato) => contato._id === idContato);
  };

  useEffect(() => {
    let novosContatos = [...contatos];
    if (pesquisa.trim() !== "") {
      novosContatos = novosContatos.filter(
        (contato) =>
          contato.pushname?.toLowerCase().includes(pesquisa.toLowerCase()) ||
          contato.telefone?.toLowerCase().includes(pesquisa.toLowerCase())
      );
    }
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const currentContacts = novosContatos.slice(
      indexOfFirstContact,
      indexOfLastContact
    );
    setContatosFiltrados([...currentContacts]);
    setTotalPages(Math.ceil(novosContatos.length / contactsPerPage));
  }, [contactsPerPage, contatos, currentPage, pesquisa, setTotalPages]);

  useEffect(() => {
    const novosContatos = pref.services.voxbot.contatos
      ? pref.services.voxbot.contatos
      : [];
    setContatos([...novosContatos]);
  }, [pref.services.voxbot.contatos]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pesquisa, setCurrentPage]);

  return (
    <ul className="convite-bot-contacts">
      {contatosFiltrados?.map((contato, index) => {
        if (!contato.telefone) return null;
        const isChecked = checkContato(contato._id);
        return (
          <li key={index} onClick={() => handleSelectContact(contato._id)}>
            <input
              type="checkbox"
              onChange={() => handleSelectContact(contato._id)}
              checked={isChecked}
            />
            {contato?.foto ? (
              <img
                src={contato?.foto}
                alt="Foto"
                onError={(e) => {
                  e.target.src = "/imgs/avatar2.png";
                }}
              />
            ) : (
              <img src="/imgs/avatar2.png" alt="foto" />
            )}
            {
              <div className="convite-bot-contacts-info">
                <p>{contato?.pushname}</p>
                <p>{formatarTelefone(contato.telefone)}</p>
              </div>
            }
          </li>
        );
      })}
      <div className="pagination-att">
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span style={{ color: "black" }}>
          {currentPage} ... {totalPages}
        </span>
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage + 1);
          }}
          disabled={currentPage === Math.ceil(totalPages) || totalPages === 0}
        >
          Próximo
        </button>
      </div>
    </ul>
  );
};
