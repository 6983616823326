import { FaUserAlt } from "react-icons/fa";
import { AiFillPhone, AiFillIdcard, AiFillMail } from "react-icons/ai";
import "../../style.css";

export const CidadaoCard = ({
  cidadaos,
  setCidadaoSelecionado,
  cidadaoSelecionado,
  itemsPerPage,
  currentPage,
}) => {

  const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return (
    <div className="cidadao-cards-container">
      {paginate(cidadaos, itemsPerPage, currentPage).map((cidadao, index) => (
        <div
          className={
            cidadaoSelecionado === cidadao ? "cidadao-card-selected" : "cidadao-card"
          }
          key={index}
          onClick={() => setCidadaoSelecionado(cidadao)}
        >
          <div className="cidadao-card-avatar-container">
            <div className="cidadao-card-avatar">
              <img
                src={
                  cidadao.urlImagemPerfil
                    ? cidadao.urlImagemPerfil
                    : "/imgs/avatar2.png"
                }
                className="cidadao-card-avatar-img"
                alt="avatar"
              />
            </div>
            <div className="cidadao-card-avatar-info">
              <span>
                <FaUserAlt />
                {cidadao.nome}
              </span>
              <span>
                <AiFillPhone />
                {cidadao.telefone}
              </span>

              <span>
                <AiFillIdcard />
                {cidadao.cpf}
              </span>
              <span>
                <AiFillMail />
                {cidadao.email}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
