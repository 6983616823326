import React, { useState, useEffect, useContext } from "react";
import { ListGroup } from "react-bootstrap";
import { UserContext } from "../../../../contexts/UserProvider";
import { AttendanceContext } from "../../../../contexts/ChatBotProvider/AttendanceProvider";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";
import { BsInfoCircle, BsSearch } from "react-icons/bs";

export default function WaitingAttendance(props) {
  const userContext = useContext(UserContext);
  const context = useContext(AttendanceContext);
  const prefContext = useContext(PrefContext);
  const [searchInput, setSearchInput] = useState("");

  let mgrp = prefContext.atendentes.filter((grp) =>
    grp.usuarios.includes(userContext.user._id)
  );
  let attndncs = context.attendances.filter(
    (att) => mgrp.some((grp) => grp._id === att.grupo) && att.waiting
  );
  const [attendance, setAttendance] = useState(attndncs);
  const [attendanceOnShow, setAttendanceOnShow] = useState(attndncs);

  const doSearch = (value) => {
    if (value === "") {
      setAttendanceOnShow([...attendance]);
    } else {
      let newAttendance = attendance.filter(
        (attendance) =>
          attendance.pushname.toLowerCase().includes(value.toLowerCase()) ||
          attendance.telefone.toLowerCase().includes(value.toLowerCase())
      );
      setAttendanceOnShow(newAttendance);
    }
  };

  useEffect(() => {
    doSearch(searchInput);
  }, [searchInput, attendance]);

  function countAndSlice(message) {
    if (!message) return "";
    let count = message.length;
    if (count > 20) {
      message = message.slice(0, 20);
      message = message + "...";
    }
    return message;
  }

  useEffect(() => {
    if (!userContext.user.admin) {
      let mygroups = prefContext.atendentes.filter((grp) =>
        grp.usuarios.includes(userContext.user._id)
      );
      let attendances = context.attendances.filter(
        (att) => mygroups.some((grp) => grp._id === att.grupo) && att.waiting
      );
      attendances = attendances.reverse();
      setAttendance(attendances);
      setAttendanceOnShow(attendances);
    } else {
      let attendances = context.attendances.filter(
        (att) => att.waiting && !att.atendente
      );
      attendances = attendances.reverse();
      setAttendance(attendances);
      setAttendanceOnShow(attendances);
    }
  }, [context.attendances, prefContext.atendentes]);

  return (
    <>
      <div className="container-search">
        <input
          type="search"
          placeholder="Buscar Contato..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div className="button-search-box">
          <BsSearch size={20} />
        </div>
      </div>

      <div className="attendance-list">
        {attendanceOnShow.length === 0 && searchInput !== "" && (
          <div className="container-information-nothingfound-att">
            <BsInfoCircle size={50} color="#9ac31c" />
            <p>
              <strong>Nenhum atendimento encontrado !</strong>
            </p>
            <p style={{ color: "#989898", fontSize: "14px", marginTop: "0px" }}>
              Verifique o contato ou crie um novo atendimento.
            </p>
          </div>
        )}

        {attendanceOnShow?.map((contact, i) => {
          let background = "inherit";

          if (contact === props.selected) {
            background = "white";
          }

          let lastHist = contact.historico[contact.historico.length - 1];
          let waitingTime = new Date(lastHist?.data);
          let formatedDate =
            timeHasZero(waitingTime.getDate()) +
            "/" +
            timeHasZero(waitingTime.getMonth() + 1) +
            "/" +
            timeHasZero(waitingTime.getFullYear());
          let formatedTime =
            timeHasZero(waitingTime.getHours()) +
            ":" +
            timeHasZero(waitingTime.getMinutes());
          let redirectedGrp = prefContext.atendentes.find(
            (grp) => grp._id === contact.grupo
          );
          let attend = userContext.pref.users.find(
            (user) => user._id === lastHist?.user
          )?.nome;
          if (!attend) attend = " Pelo Robô";
          else attend = " Por " + attend;
          return (
            <div key={i + 2 * 969403} >
              <div
                className="contact-wrapper"
                style={{
                  backgroundColor: background,
                  display: "flex",
                  alignItems: "center",
                }}
                key={i}
                onClick={(e) => {
                  props.onSelect(e, contact);
                }}
              >
                <img
                  className="contact-avatar"
                  src={contact.foto ? contact.foto : "/imgs/avatar2.png"}
                  alt=""
                  onError={(e) => {
                    e.target.src = "/imgs/avatar2.png";
                  }}
                />

                <ListGroup.Item
                  className="contact-data"
                  style={{ backgroundColor: background, border: "none" }}
                  key={i}
                >
                  <b>
                    {contact.pushname
                      ? contact.pushname
                      : contact.telefone.replace("@c.us", " ").trim()}
                  </b>
                  <p style={{ margin: "4px 0px" }}>
                    {" "}
                    {countAndSlice(contact.lastMessage?.message)}{" "}
                  </p>
                  {waitingTime && redirectedGrp && (
                    <>
                      <small className="small-title">
                        {" "}
                        Redirecionado para {redirectedGrp.nome} às{" "}
                        {formatedTime} de {formatedDate} {attend}{" "}
                      </small>
                    </>
                  )}
                </ListGroup.Item>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function timeHasZero(time) {
  if (time < 10) return "0" + time;
  return time;
}
