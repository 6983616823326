import { useEffect, useState, useContext } from "react";
import "./style.css";
import { CalendarioContext } from "../../../contexts/CalendarioProvider";
import { ProfessionalContext } from "../../../contexts/ProfessionalProvider";
import { AgendaContext } from "../../../contexts/AgendaProvider";
import { SpecialtyContext } from "../../../contexts/SpecialtyProvider";
import { LocationContext } from "../../../contexts/LocationProvider";
import { ServiceContext } from "../../../contexts/ServiceProvider";
import { CidadaoContext } from "../../../contexts/CidadaoProvider";
import { Calendario } from "./Calendario";
import { TabelaAgenda } from "./TabelaAgenda";
import { FiltroAgenda } from "./FiltroAgenda";
import { Paginacao } from "../../miscelaneous/Paginacao";
import { CidadaoModal } from "../Agendas/CidadaoModal";
import { LoadingSpinner } from "../../miscelaneous/Loader/load";

export const Agendas = () => {
  const calendarioContext = useContext(CalendarioContext);
  const agendaContext = useContext(AgendaContext);
  const professionalContext = useContext(ProfessionalContext);
  const specialtyContext = useContext(SpecialtyContext);
  const locationContext = useContext(LocationContext);
  const serviceContext = useContext(ServiceContext);
  const cidadaoContext = useContext(CidadaoContext);
  const [agendasTabela, setAgendasTabela] = useState([]);

  const [agendasCalendario, setAgendasCalendario] = useState([]);

  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [allCidadaos, setAllCidadaos] = useState([]);

  const [filtroProfissional, setFiltroProfissional] = useState("");
  const [filtroEspecialidade, setFiltroEspecialidade] = useState("");
  const [filtroLocal, setFiltroLocal] = useState("");
  const [filtroServico, setFiltroServico] = useState("");

  const [view, setView] = useState("calendario");
  const [showCidadaoModal, setShowCidadaoModal] = useState(false);
  const [cidadaoSelecionado, setCidadaoSelecionado] = useState({});
  const [fraseAtualIndex, setFraseAtualIndex] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const getCidadao = (id) => {
    const cidadao = allCidadaos.find((cidadao) => cidadao._id === id);
    setCidadaoSelecionado(cidadao);
    setShowCidadaoModal(true);
  };

  const views = {
    tabela: (
      <TabelaAgenda
        agendas={agendasTabela}
        filtroProfissional={filtroProfissional}
        filtroEspecialidade={filtroEspecialidade}
        filtroLocal={filtroLocal}
        filtroServico={filtroServico}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        allCidadaos={allCidadaos}
        getCidadao={getCidadao}
      />
    ),
    calendario: <Calendario agendas={agendasCalendario} />,
  };

  const paginations = {
    tabela: (
      <Paginacao
        items={agendasTabela}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    ),
    calendario: (
      <Paginacao
        items={agendasCalendario}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    ),
  };

  const frasesCarregando = [
    "Um momento, estamos carregando as agendas.",
    "Aguarde, carregando informações.",
    "Carregando dados importantes...",
    "Quase lá, aguarde um pouco mais.",
  ];

  useEffect(() => {
    cidadaoContext.getAllCidadaos();
  }, []);

  useEffect(() => {
    const novosAllCidadaos = cidadaoContext.allCidadaos
      ? [...cidadaoContext.allCidadaos]
      : [];
    setAllCidadaos(novosAllCidadaos);
  }, [cidadaoContext.allCidadaos]);

  useEffect(() => {
    let novasAgendasTabela = calendarioContext.agendas
      ? [...calendarioContext.agendas]
      : [];

    novasAgendasTabela = novasAgendasTabela.flatMap((e) =>
      e.datasMarcadas.map((d) => ({ ...e, datasMarcadas: d }))
    );

    if (filtroProfissional) {
      novasAgendasTabela = novasAgendasTabela.filter(
        (agenda) => agenda.profissional === filtroProfissional
      );
    }
    if (filtroEspecialidade) {
      novasAgendasTabela = novasAgendasTabela.filter(
        (agenda) => agenda.especialidade === filtroEspecialidade
      );
    }
    if (filtroLocal) {
      novasAgendasTabela = novasAgendasTabela.filter(
        (agenda) => agenda.local === filtroLocal
      );
    }
    if (filtroServico) {
      novasAgendasTabela = novasAgendasTabela.filter(
        (agenda) => agenda.serviço === filtroServico
      );
    }

    setAgendasTabela(novasAgendasTabela);
  }, [
    calendarioContext.agendas,
    filtroProfissional,
    filtroEspecialidade,
    filtroLocal,
    filtroServico,
  ]);

  useEffect(() => {
    let novasAgendasCalendario = calendarioContext.agendas
      ? [...calendarioContext.agendas]
      : [];

    if (filtroProfissional) {
      novasAgendasCalendario = novasAgendasCalendario.filter(
        (agenda) => agenda.profissional === filtroProfissional
      );
    }

    if (filtroEspecialidade) {
      novasAgendasCalendario = novasAgendasCalendario.filter(
        (agenda) => agenda.especialidade === filtroEspecialidade
      );
    }

    if (filtroLocal) {
      novasAgendasCalendario = novasAgendasCalendario.filter(
        (agenda) => agenda.local === filtroLocal
      );
    }

    if (filtroServico) {
      novasAgendasCalendario = novasAgendasCalendario.filter(
        (agenda) => agenda.serviço === filtroServico
      );
    }

    setAgendasCalendario(novasAgendasCalendario);
    setActivePage(1);
    setCurrentPage(1);
  }, [
    calendarioContext.agendas,
    view,
    filtroProfissional,
    filtroEspecialidade,
    filtroLocal,
    filtroServico,
  ]);

  useEffect(() => {
    const novosProfissionais = professionalContext.profissionais
      ? [...professionalContext.profissionais]
      : [];
    setProfissionais(novosProfissionais);
  }, [professionalContext.profissionais]);

  useEffect(() => {
    const novasEspecialidades = specialtyContext.especialidades
      ? [...specialtyContext.especialidades]
      : [];
    setEspecialidades(novasEspecialidades);
  }, [specialtyContext.especialidades]);

  useEffect(() => {
    const novasLocais = locationContext.locais
      ? [...locationContext.locais]
      : [];
    setLocais(novasLocais);
  }, [locationContext.locais]);

  useEffect(() => {
    const novosServicos = serviceContext.servicos
      ? [...serviceContext.servicos]
      : [];
    setServicos(novosServicos);
  }, [serviceContext.servicos]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFraseAtualIndex(
        (prevIndex) => (prevIndex + 1) % frasesCarregando.length
      );
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {showCidadaoModal && (
        <CidadaoModal
          showCidadaoModal={showCidadaoModal}
          closeModalOnOutsideClick={() => setShowCidadaoModal(false)}
          cidadaoSelecionado={cidadaoSelecionado}
          view={view}
        />
      )}
      <div className="agenda-container">
        <div className="agendas-title">AGENDAS</div>
        <FiltroAgenda
          especialidades={especialidades}
          locais={locais}
          profissionais={profissionais}
          servicos={servicos}
          setFiltroEspecialidade={setFiltroEspecialidade}
          setFiltroLocal={setFiltroLocal}
          setFiltroProfissional={setFiltroProfissional}
          setFiltroServico={setFiltroServico}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          setActivePage={setActivePage}
        />
        <div className="agenda-view-btn-container">
          {Object.keys(views).map((viewName, index) => {
            if (viewName === view) return;
            else {
              return (
                <button
                  className="agenda-view-btn"
                  onClick={() => setView(viewName)}
                  key={index}
                >
                  <span>{viewName}</span>
                </button>
              );
            }
          })}
        </div>

        {agendaContext.isLoadingAgenda ? (
          <div className="spinner-container-agenda">
            <LoadingSpinner showModal={"none"} />
            <p>{frasesCarregando[fraseAtualIndex]}</p>
          </div>
        ) : (
          views[view]
        )}
        {paginations[view]}
      </div>
    </>
  );
};
