import React from 'react'

export default function Videos(props) {
    const { data,message,  justi } = props
    let video = data.video? data.video.url : data.files[0].url
    if(!video) return <div>
        <h6>Video não encontrado</h6>
    </div>
    video = video.replace("public","")
    return(
        <>
            <video controls className="video-message"  style={{ width:"100%" ,maxHeight:"25vw", marginBottom: "10px" }}  >
                <source src={video} type="video/mp4" />
            </video>
            {
                data.message?.length > 0 &&  
                <span style={{ wordBreak: "break-word", alignSelf: justi }}>
                     {message}
                </span>
            }
        </>
    )
}