export const localFormInputs = [
  {
    label: "Nome",
    key: "nome",
    type: "input",
    inputType: "text",
  },
  {
    label: "Endereço",
    key: "endereco",
    type: "object",
    legend: false,
    subKeys: [
      {
        label: "Rua",
        key: "rua",
        inputType: "text",
      },
    ],
  },
  {
    label: "Telefone",
    key: "fone",
    type: "input",
    inputType: "text",
  },
  {
    label: "Exibir horário a partir:",
    key: "timeToStart",
    type: "input",
    inputType: "time",
  },
  {
    label: "Especialidades",
    key: "especialidades",
    type: "reactSelectList",
    config: {
      isMulti: false,
      placeholder: "Selecione",
      noOptionsMessage: () => "Sem especialidades",
    },
  },
];
