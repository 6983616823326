import React from "react";

export default function EmptyMessages(props) {
  const { message, empty } = props;

  return (
    <>
      <div className="welcome-msg-empty-chat-container">
        <img
          style={{
            width: "150px",
            height: "80px",
            marginBottom: "10px",
          }}
          src={"/imgs/frame_icon_chat.svg"}
          alt="icon"
        />

        {!empty && (
          <p style={{ fontSize: "23px" }}>
            {" "}
            Nenhuma conversa selecionada. Clique para visualizar.
          </p>
        )}
        <span
          style={{ fontSize: "18px", color: "#989898", fontWeight: "bold" }}
        >
          {" "}
          {message}{" "}
        </span>
      </div>
    </>
  );
}
