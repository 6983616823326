import { useEffect, useState } from 'react';
import { BsMicFill, BsPauseCircleFill, BsX } from 'react-icons/bs';
const MicRecorder = require('mic-recorder-to-mp3');
const recorder = new MicRecorder({
  bitRate: 128
});

const Message = (props) => {
  const { setData, data, editData, setEditData } = props;
  const [message, setMessage] = useState('');
  const [audio, setAudio] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (audio) {
      if (editData) setEditData({ ...editData, audio });
      else setData({ ...data, audio });
    }
  }, [audio]);

  useEffect(() => {
    if (data.message) {
      setMessage(data.message)
    } else if (data.audio !== '') {
      setAudio(data.audio)
    }
  }, []);

  useEffect(() => {
    if(!editData) return
    if (editData?.message) {
      setMessage(editData.message);
    } else if (editData?.audio !== '') {
      setAudio(editData.audio);
    }
  }, []);

  const handleChange = (e) => {
    setMessage(e.target.value);
    if(editData) setEditData({ ...editData, message: e.target.value });
    else setData({ ...props.data, message: e.target.value });
  };

  const handleStartRecording = async () => {
    try {
      recorder.start().then(() => {
        setIsRecording(true);
      });
    } catch (error) {
      console.error('Erro ao gravar áudio:', error);
    }
  };

  const handleStopRecording = () => {
    try {
      if (recorder) {
        recorder.stop().getMp3().then(([buffer, blob]) => {
          const newAudio = blob;
          setAudio(newAudio);
          setIsRecording(false);
        });
      } else {
        console.error('Nenhum media recorder definido.');
      }
    } catch (error) {
      console.error('Erro ao parar de gravar áudio:', error);
    }
  };

  const handleDeleteAudio = () => {
    setAudio(null);
    if (editData) setEditData({ ...editData, audio: '' });
    else setData({ ...data, audio: '' });
  }


  return (
    <div className="temporizador-message-box">
      {!audio ? (
        <textarea
          className="temporizador-textarea"
          placeholder="Digite a mensagem"
          rows="4"
          cols="50"
          value={message}
          onChange={handleChange}
          disabled={isRecording}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', gap: '3px', minHeight: '110px' }}>
          <audio controls className="audio-message">
            <source src={editData?.audio?.url ? audio.url : URL.createObjectURL(audio)} type="audio/wav" />
          </audio>
          <BsX color={'#9ac31c'} onClick={() => handleDeleteAudio()} size={30} />
        </div>
      )}
      {message === '' && !audio && <BsMicFill onClick={handleStartRecording} size={25} className="temporizador-audio" color={'#9ac31c'} style={isRecording ? { display: 'none' } : null} />}
      {isRecording && message === '' && !audio && <BsPauseCircleFill size={25} onClick={handleStopRecording} className="temporizador-audio" color={'#9ac31c'} />}
    </div>
  );
};

export default Message;
