import { useState, useEffect, useContext } from "react";
import { BotEmail } from "./views/BotEmail";
import { Confirmacao } from "./views/Confirmacao";
import { Avisar } from "./views/Avisar";
import { Marcar } from "./views/Marcar";
import { Cancelar } from "./views/Cancelar";
import { Desmarcar } from "./views/Desmarcar";
import { Confirmado } from "./views/Confirmado";
import { UserContext } from "../../../../../contexts/UserProvider";
import "./style.css";

const tempoAvisos = [
  // { value: "doisMin", label: "2 Minutos Antes" },
  // { value: "tresMin", label: "3 Minutos Antes" },
  { value: "umaH", label: "1 Hora Antes" },
  { value: "tresH", label: "3 Horas Antes" },
  { value: "seisH", label: "6 Horas Antes" },
  { value: "dozeH", label: "12 Horas Antes" },
  { value: "umD", label: "1 Dia Antes" },
  { value: "tresD", label: "3 Dias Antes" },
  { value: "umS", label: "1 Semana Antes" },
  { value: "umM", label: "1 Mês Antes" },
];

export const ConfigForm = (props) => {
  const { pref } = useContext(UserContext);
  const [bots, setBots] = useState([]);
  const [botSelecionado, setBotSelecionado] = useState({});
  // const [confirm, setConfirm] = useState("");
  const [confirms, setConfirms] = useState([]);
  const [tempoConfirm, setTempoConfirm] = useState("");
  const [avisos, setAvisos] = useState([]);
  const [mensagemConfirmacao, setMensagemConfirmacao] = useState("");
  const [msgConfirmacaoEmail, setMsgConfirmacaoEmail] = useState("");
  const [mensagemConfirmado, setMensagemConfirmado] = useState("");
  const [mensagemWarning, setMensagemWarning] = useState("");
  const [mensagemMarcado, setMensagemMarcado] = useState("");
  const [mensagemCancelado, setMensagemCancelado] = useState("");
  const [mensagemDesmarcado, setMensagemDesmarcado] = useState("");
  const [envioMsgEmail, setEnvioMsgEmail] = useState(false);
  const [envioMsgBot, setEnvioMsgBot] = useState(false);

  const [view, setView] = useState(0);

  useEffect(() => {
    const msgPadrao = "MENSAGEM PADRAO DO EMAIL";
    const novaMsgConfirmacaoEmail = props.servico.msgConfirmacao?.email
      ? props.servico.msgConfirmacao?.email
      : msgPadrao;
    setMsgConfirmacaoEmail(novaMsgConfirmacaoEmail);
  }, [props.servico.msgConfirmacao?.email]);

  useEffect(() => {
    const novoEnvioMsgEmail = props.servico.envioMsg.email
      ? props.servico.envioMsg.email
      : false;

    setEnvioMsgEmail(novoEnvioMsgEmail);
  }, [props.servico.envioMsg.email]);

  useEffect(() => {
    const novoEnvioMsgBot = props.servico.envioMsg.bot
      ? props.servico.envioMsg.bot
      : false;

    setEnvioMsgBot(novoEnvioMsgBot);
  }, [props.servico.envioMsg.bot]);

  useEffect(() => {
    let novosBots = props.bots ? [...props.bots] : [];
    novosBots = novosBots.filter((bot) => bot.status === "ativo");
    setBots(novosBots);
  }, [props.bots]);

  useEffect(() => {
    const novoBotSelecionado = props.servico.bot
      ? { ...props.servico.bot }
      : {};
    setBotSelecionado(novoBotSelecionado);
  }, [props.servico.bot]);

  useEffect(() => {
    const novoConfirm = props.servico.confirms
      ? [...props.servico.confirms]
      : [];
    setConfirms(novoConfirm);
  }, [props.servico.confirms]);

  useEffect(() => {
    const novoTempoConfirm = props.servico.tempoConfirm
      ? props.servico.tempoConfirm
      : "";
    setTempoConfirm(novoTempoConfirm);
  }, [props.servico.tempoConfirm]);

  useEffect(() => {
    const novosAvisos = props.servico.avisos ? [...props.servico.avisos] : [];
    setAvisos(novosAvisos);
  }, [props.servico.avisos]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}.\nTelefone: ${local.fone}.\n*Você tem até ${dataConfirm.data} às ${dataConfirm.hora} para confirmar. Caso não confirme, o agendamento será cancelado.*\nAtenciosamente, " +
      pref.nome +
      ".\nPara confirmar o agendamento, digite:\n1 - SIM\n2 - NÃO.";
    const novoMensagemConfirmacao = props.servico.mensagemConfirmacao
      ? props.servico.mensagemConfirmacao
      : mensagemPadrao;
    setMensagemConfirmacao(novoMensagemConfirmacao);
  }, [pref.nome, props.servico.mensagemConfirmacao]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}. Telefone: ${local.fone}.\nO seu agendamento foi confirmado. Atenciosamente, " +
      pref.nome +
      ".";
    const novoMensagemConfirmado = props.servico.mensagemConfirmado
      ? props.servico.mensagemConfirmado
      : mensagemPadrao;
    setMensagemConfirmado(novoMensagemConfirmado);
  }, [pref.nome, props.servico.mensagemConfirmado]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}. Telefone: ${local.fone}.\nAtenciosamente, " +
      pref.nome +
      ".";
    const novoMensagemWarning = props.servico.mensagemWarning
      ? props.servico.mensagemWarning
      : mensagemPadrao;
    setMensagemWarning(novoMensagemWarning);
  }, [pref.nome, props.servico.mensagemWarning]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}. Telefone: ${local.fone}.\nO seu agendamento foi marcado. Atenciosamente, " +
      pref.nome +
      ".";
    const novoMensagemMarcado = props.servico.mensagemMarcado
      ? props.servico.mensagemMarcado
      : mensagemPadrao;
    setMensagemMarcado(novoMensagemMarcado);
  }, [pref.nome, props.servico.mensagemMarcado]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}. Telefone: ${local.fone}.\nO seu agendamento foi cancelado. Atenciosamente, " +
      pref.nome +
      ".";
    const novoMensagemCancelado = props.servico.mensagemCancelado
      ? props.servico.mensagemCancelado
      : mensagemPadrao;
    setMensagemCancelado(novoMensagemCancelado);
  }, [pref.nome, props.servico.mensagemCancelado]);

  useEffect(() => {
    const mensagemPadrao =
      "Olá, ${usuario.nome}!\nVocê agendou um serviço de ${servico.nome} para o dia ${data.data} às ${data.hora}.\nO serviço é ${especialidade.nome}.\nO profissional é ${profissional.nome}.\nO local é ${local.nome}.\nEndereço: ${local.endereco.rua}. Telefone: ${local.fone}.\nO seu agendamento foi desmarcado. Atenciosamente, " +
      pref.nome +
      ".";
    const novoMensagemDesmarcado = props.servico.mensagemDesmarcado
      ? props.servico.mensagemDesmarcado
      : mensagemPadrao;
    setMensagemDesmarcado(novoMensagemDesmarcado);
  }, [pref.nome, props.servico.mensagemDesmarcado]);

  const onChangeEnvioMsg = (e) => {
    if (e.target.name === "email") {
      setEnvioMsgEmail(e.target.checked);
    }

    if (e.target.name === "bot") {
      setEnvioMsgBot(e.target.checked);
    }
  };

  const onChangeConfirmation = (checked, tempo) => {
    let novosConfirms = [...confirms];
    if (checked) {
      if (!novosConfirms) novosConfirms = [];
      novosConfirms.push(tempo);
    } else {
      if (!novosConfirms) {
        novosConfirms = [];
      }
      let index = novosConfirms.indexOf(tempo);
      if (index !== -1) {
        novosConfirms.splice(index, 1);
      }
    }
    setConfirms(novosConfirms);
  };

  const onChangeAviso = (checked, tempo) => {
    let novosAvisos = [...avisos];
    if (checked) {
      novosAvisos.push(tempo);
    } else {
      let index = avisos.indexOf(tempo);
      if (index !== -1) {
        novosAvisos.splice(index, 1);
      }
    }
    setAvisos(novosAvisos);
  };

  const onChangeSelectBot = (botOptions) => {
    setBotSelecionado(botOptions);
  };

  // const onChangeTempoConfirm = (e) => {
  //   setTempoConfirm(e.target.value);
  // };

  const onChangeMensagem = ({ target: { name, value } }) => {
    switch (name) {
      case "mensagemConfirmacao":
        setMensagemConfirmacao(value);
        break;
      case "mensagemConfirmado":
        setMensagemConfirmado(value);
        break;
      case "mensagemWarning":
        setMensagemWarning(value);
        break;
      case "mensagemMarcado":
        setMensagemMarcado(value);
        break;
      case "mensagemCancelado":
        setMensagemCancelado(value);
        break;
      case "mensagemDesmarcado":
        setMensagemDesmarcado(value);
        break;
      default:
        break;
    }
  };

  const handleCancelServicoConfig = () => {
    props.setConfigServico(false);
    props.setServico({
      ...props.servico,
    });
  };

  const salvarServicoConfig = (e) => {
    e.preventDefault();
    const novoBot = { ...botSelecionado };
    props.setServico({
      ...props.servico,
      bot: novoBot,
      tempoConfirm: tempoConfirm,
      // confirm: confirm,
      confirms: confirms,
      avisos: avisos,
      msgConfirmacao: {
        email: msgConfirmacaoEmail,
        bot: mensagemConfirmacao,
      },
      mensagemConfirmacao: mensagemConfirmacao,
      mensagemConfirmado: mensagemConfirmado,
      mensagemWarning: mensagemWarning,
      mensagemMarcado: mensagemMarcado,
      mensagemCancelado: mensagemCancelado,
      mensagemDesmarcado: mensagemDesmarcado,
      envioMsg: {
        email: envioMsgEmail,
        bot: envioMsgBot,
      },
    });
    props.setConfigServico(false);
  };

  const botOptions = bots.map((bot) => ({
    value: bot._id,
    label: bot.nome,
  }));

  const views = [
    <BotEmail
      botOptions={botOptions}
      botSelecionado={botSelecionado}
      onChangeSelectBot={onChangeSelectBot}
      setView={setView}
      view={view}
      handleCancelServicoConfig={handleCancelServicoConfig}
      salvarServicoConfig={salvarServicoConfig}
      onChangeEnvioMsg={onChangeEnvioMsg}
      envioMsgEmail={envioMsgEmail}
      envioMsgBot={envioMsgBot}
    />,
    <Confirmacao
      confirms={confirms}
      tempoConfirm={tempoConfirm}
      setTempoConfirm={setTempoConfirm}
      onChangeConfirmation={onChangeConfirmation}
      AvisosCheckboxRow={AvisosCheckboxRow}
      setView={setView}
      view={view}
      handleCancelServicoConfig={handleCancelServicoConfig}
      salvarServicoConfig={salvarServicoConfig}
      onChangeMensagem={onChangeMensagem}
      mensagemConfirmacao={mensagemConfirmacao}
      msgConfirmacaoEmail={msgConfirmacaoEmail}
      setMsgConfirmacaoEmail={setMsgConfirmacaoEmail}
    />,
    <Confirmado
      setView={setView}
      view={view}
      salvarServicoConfig={salvarServicoConfig}
      handleCancelServicoConfig={handleCancelServicoConfig}
      mensagemConfirmado={mensagemConfirmado}
      onChangeMensagem={onChangeMensagem}
    />,
    <Marcar
      view={view}
      setView={setView}
      salvarServicoConfig={salvarServicoConfig}
      handleCancelServicoConfig={handleCancelServicoConfig}
      mensagemMarcado={mensagemMarcado}
      onChangeMensagem={onChangeMensagem}
    />,
    <Cancelar
      view={view}
      setView={setView}
      salvarServicoConfig={salvarServicoConfig}
      handleCancelServicoConfig={handleCancelServicoConfig}
      mensagemCancelado={mensagemCancelado}
      onChangeMensagem={onChangeMensagem}
    />,
    <Desmarcar
      view={view}
      setView={setView}
      salvarServicoConfig={salvarServicoConfig}
      handleCancelServicoConfig={handleCancelServicoConfig}
      mensagemDesmarcado={mensagemDesmarcado}
      onChangeMensagem={onChangeMensagem}
    />,
    <Avisar
      avisos={avisos}
      onChangeAviso={onChangeAviso}
      AvisosCheckboxRow={AvisosCheckboxRow}
      view={view}
      setView={setView}
      salvarServicoConfig={salvarServicoConfig}
      handleCancelServicoConfig={handleCancelServicoConfig}
      onChangeMensagem={onChangeMensagem}
      mensagemWarning={mensagemWarning}
    />,
  ];

  return (
    <form className="servico-config-form">
      <div className="servico-config-form-title">Configurar Serviço</div>
      {views[view]}
    </form>
  );
};

const AvisosCheckboxRow = (props) => {
  return (
    <>
      {tempoAvisos.map((tempo, index) => (
        <div key={index} className="aviso-container">
          <input
            type="checkbox"
            id={tempo.value}
            name="aviso"
            checked={props.avisos?.includes(tempo.value)}
            value={tempo.value}
            onChange={(e) => {
              props.singleSelection
                ? props.onChangeAviso(tempo.value)
                : props.onChangeAviso(e.target.checked, tempo.value);
            }}
          />
          <label htmlFor={tempo.value} id="tempoAviso">
            {tempo.label}
          </label>
        </div>
      ))}
    </>
  );
};
