import React from "react";
import { BsXLg } from "react-icons/bs";

import "./modalBot.css";

export default function Modal(props) {
  return (
    <>
      <div className="bot-modal-bg">
        <div className="bot-modal-container">
          <button className="bot-close-modal" onClick={props.closeModal}>
            <BsXLg size={26} color="#142a4c" />
          </button>
          {props.children}
        </div>
      </div>
    </>
  );
}
