import React, { useEffect, useState, useContext } from "react";
import ApiUsers from "../../../../ApiUsers";
import DepartmentEdit from "./DepartmentShow/DepartmentEdit";
import DepartmentListUsers from "./DepartmentShow/DepartmentListUsers";
import DepartmentList from "./DepartmentShow/DepartmentList";
import DepartamentAddUser from "./DepartmentShow/DepartmentAddUser";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";
import DeleteAlert from "../inputs/DeleteAlert.jsx";
import Alert from "../inputs/Alerts/Alert";
import Empty from "../inputs/EmptyLength";
import "./Departament.css";

export default function DepartamentShow() {
  const prefContext = useContext(PrefContext);
  const [departamentos, setDepartamentos] = useState(prefContext.departamentos);
  const [departamentosOnShow, setDepartamentosOnShow] = useState(
    prefContext.departamentos
  );
  const [selectedDep, setSelectedDep] = useState({});
  const [listUsers, setListUsers] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  useEffect(() => {
    setDepartamentos(prefContext.departamentos);
    setDepartamentosOnShow(prefContext.departamentos);
    if (selectedDep) {
      let newDep = prefContext.departamentos.find(
        (d) => d._id === selectedDep._id
      );
      setSelectedDep(newDep);
    }
  }, [prefContext.departamentos]);

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={() => alert.props.action()}
        quit={() => quitAlert()}
      />
    ),
  };

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function delConfirm(dept) {
    createAlert("delete", {
      data: "departamento",
      nome: dept.nome,
      action: () => delDepartment(dept),
    });
  }

  function delDepartment(dept) {
    ApiUsers.post("/departamentos/delete", { departamento: dept })
      .then((res) => {
        console.log(res.data);
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Departamento deletado com sucesso!",
        });
      })
      .catch((err) => console.log(err));
  }

  function searchDepartament(e) {
    e.preventDefault();
    let newDepts = [];
    if (e.target.value.trim() !== "") {
      newDepts = departamentos.filter((dep) =>
        dep.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
    } else {
      newDepts = departamentos;
    }
    setDepartamentosOnShow(newDepts);
  }

  return (
    <div className="dept-container">
      {alert.visible && alertType[alert.type]}

      {edit ? (
        <>
          <DepartmentEdit
            departamento={selectedDep}
            alert={(type, props) => createAlert(type, props)}
            goBack={() => setEdit(false)}
          />
        </>
      ) : listUsers ? (
        <>
          <DepartmentListUsers
            departamento={selectedDep}
            alert={(type, props) => createAlert(type, props)}
            goBack={() => setListUsers(false)}
          />
        </>
      ) : addUsers ? (
        <>
          <DepartamentAddUser
            departamento={selectedDep}
            alert={(type, props) => createAlert(type, props)}
            goBack={() => setAddUsers(false)}
          />
        </>
      ) : (
        <>
          <DepartmentList
            departamentos={departamentosOnShow}
            search={(e) => searchDepartament(e)}
            delConfirm={(dept) => delConfirm(dept)}
            addUsers={(dept) => {
              setSelectedDep(dept);
              setAddUsers(true);
            }}
            listUsers={(dept) => {
              setSelectedDep(dept);
              setListUsers(true);
            }}
            edit={(dept) => {
              setSelectedDep(dept);
              setEdit(true);
            }}
          />
        </>
      )}
    </div>
  );
}
