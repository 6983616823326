import { useState, useEffect, useContext } from "react";
import { QSGAgendaContext } from "../../../../../contexts/QSGProvider/QSGAgendaProvider";
import { Accordion } from "../Accordion";
import { Selecao } from "../Selecao";
import { accordionSections } from "./accordionSections";
import { Form } from "../Form";
import { formInputs } from "./formInputs";
import "./styles.css";

export const Especialidades = ({ setIsStepCompleted }) => {
  const { especialidades, profissionais, setEspecialidades, setItensRemovidos } = useContext(QSGAgendaContext);
  const [especialidadesState, setEspecialidadesState] = useState([]);
  const [editingStates, setEditingStates] = useState({ especialidade: false });
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null);
  const [options, setOptions] = useState({});

  const editEspecialidade = (especialidade) => {
    setEspecialidadeSelecionada(especialidade);
    setEditingStates({ especialidade: true });
  };

  const deleteEspecialidade = (especialidade) => {
    const novasEspecialidades = [...especialidades];
    const index = novasEspecialidades.indexOf(especialidade);
    if (index > -1) {
      setItensRemovidos((prevItensRemovidos) => [...prevItensRemovidos, especialidade._id]);
      novasEspecialidades.splice(index, 1);
    }
    setEspecialidades(novasEspecialidades);
  };

  const callback = (especialidade, action) => {
    if (action === "editar") {
      editEspecialidade(especialidade);
    }
    if (action === "excluir") {
      deleteEspecialidade(especialidade);
    }
  };

  const acharProfissional = (id) => {
    const profissional = profissionais.find((profissional) => profissional._id === id);
    return profissional;
  };

  const itemFunction = (item) => {
    const profissionais = item.profissionais.map((profissional) => {
      const resultado = acharProfissional(profissional);
      if (!resultado) return null;
      const formatarTelefone = (telefone) => {
        telefone = telefone.replace(/\D/g, "");
        const codigoDDD = telefone.slice(0, 2);
        const primeiraParte = telefone.slice(2, telefone.length - 4);
        const segundaParte = telefone.slice(telefone.length - 4);
        return `(${codigoDDD}) ${primeiraParte}-${segundaParte}`;
      };
      return (
        <p
          className="qsg-agenda-accordion-item-function"
          key={resultado._id}>
          {resultado.nome}
          <span>{formatarTelefone(resultado.telefone)}</span>
        </p>
      );
    });
    return profissionais;
  };

  useEffect(() => {
    const novasEspecialidades = especialidades ? [...especialidades] : [];
    setEspecialidadesState(novasEspecialidades);
    console.log("novasEspecialidades: ", novasEspecialidades);
  }, [especialidades]);

  useEffect(() => {
    if (especialidadesState.length > 0) {
      setIsStepCompleted(true);
    } else {
      setIsStepCompleted(false);
    }
  }, [especialidadesState.length, setIsStepCompleted]);

  useEffect(() => {
    const optionsProfissionais = profissionais.map((profissional) => ({
      value: profissional._id,
      label: profissional.nome,
    }));

    setOptions((prevOptions) => ({
      ...prevOptions,
      profissionais: optionsProfissionais,
    }));
  }, [profissionais]);

  return (
    <div className="qsg-agenda-especialidades-container">
      <Selecao headerLabel={`${editingStates.especialidade ? "Editar" : "Nova"} especialidade`}>
        <Form
          formInputs={formInputs}
          options={options}
          data={especialidadesState}
          setData={setEspecialidades}
          editingStates={editingStates.especialidade}
          setEditingStates={setEditingStates}
          selected={especialidadeSelecionada}
          setSelected={setEspecialidadeSelecionada}
        />
      </Selecao>
      <Selecao
        headerLabel="Especialidades"
        content={especialidadesState}>
        {especialidadesState.length > 0 ? (
          <Accordion
            data={especialidadesState}
            accordionSections={accordionSections}
            firstSectionKey={"especialidades"}
            callback={callback}
            itemFunction={(item) => itemFunction(item)}
          />
        ) : (
          <p className="qsg-agenda-especialidades-accordion-empty">NENHUMA ESPECIALIDADE CADASTRADA</p>
        )}
      </Selecao>
    </div>
  );
};
