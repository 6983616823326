import React, { useState, useEffect } from "react";
import TdActions from "./components/TdActions/TdActions";
import Pagination from "./components/Pagination/Pagination";
import Header from "./components/Header/Header";
import TdTable from "./components/TdTable/TdTable";
import "./table.css";

export default function Table(props) {
  const { selectContact, createAlert, quitAlert, changeBlockAtt } = props;
  const [contacts, setContacts] = useState([]);
  const [contactsOnShow, setContactsOnShow] = useState([]);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    setContacts(props.contacts);
    setContactsOnShow(props.contacts);
  }, [props.contacts]);

  useEffect(() => {
    setNoResults(contactsOnShow.length === 0);
  }, [contactsOnShow]);

  return (
    <>
      <div className="total-contacts">
        Total: <strong>{contacts?.length}</strong>
      </div>
      <table className="table-contato">
        <Header />
        <tbody>
          {noResults ? (
            <tr>
              <td colSpan="5">Nada encontrado.</td>
            </tr>
          ) : (
            contactsOnShow.map((contact, index) => {
              const isBlockd = !!contact?.bloqueado;
              return (
                <tr key={index} className="tr-key">
                  <TdTable contact={contact} />
                  <TdActions
                    contact={contact}
                    selectContact={selectContact}
                    createAlert={createAlert}
                    quitAlert={quitAlert}
                    isBlocked={isBlockd}
                    changeBlockAtt={changeBlockAtt}
                  />
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <Pagination contacts={contacts} setContactsOnShow={setContactsOnShow} />
    </>
  );
}
