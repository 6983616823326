
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

const MensagemRapida = ({ atalho, mensagem }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="msg-rapida" onClick={toggleAccordion}>
            <div className="msg-rapida-header">
                <p>/{atalho}</p><BsChevronDown color='#9ac31c' size={20} />
            </div>
            {isOpen && <span>{mensagem}</span>}
        </div>
    );
};

export default MensagemRapida;