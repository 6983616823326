import React, { useContext } from "react";
import ButtonsCrud from "../../inputs/ButtonsCrud";
import { FaUserEdit, FaUserTimes, FaUserCheck } from "react-icons/fa";
import "../UserConfig.css";
import { Form } from "react-bootstrap";
import Empty from "../../inputs/EmptyLength";
import { PrefContext } from "../../../../../contexts/ChatBotProvider/PrefProvider";
import "../index.css";

export default function UserList(props) {
  const { usuarios, loading } = props;
  const pref = useContext(PrefContext);

  return (
    <>
      <div className="users-list-container">
        <div className="users-list-div">
          <div className="users-list-input">
            <label htmlFor="search-user">Procurar usuário:</label>
            <Form.Control
              name="search-user"
              placeholder="Digite aqui para pesquisar um usuário"
              type="text"
              onChange={(e) => {
                props.search(e);
              }}
            />
          </div>
          <h5>
            {usuarios.length > 0
              ? `Total de usuários: ${usuarios.length}`
              : "Nenhum usuário encontrado"}
          </h5>
        </div>

        <br />

        {usuarios.length > 0 ? (
          <>
            {usuarios.map((user, index) => {
              let dataAtivado = "";
              let dataInativado = "";

              function formatDate(date) {
                if (date < 10) return `0${date}`;
                else return date;
              }

              if (user.config.dataReativado) {
                let dataPure = new Date(user.config.dataReativado);
                dataAtivado = `${formatDate(dataPure.getDate())}/${formatDate(
                  dataPure.getMonth() + 1
                )}/${dataPure.getFullYear()}`;
              }
              if (user.config.dataInativado) {
                let dataPure = new Date(user.config.dataInativado);
                dataInativado = `${formatDate(dataPure.getDate())}/${formatDate(
                  dataPure.getMonth() + 1
                )}/${dataPure.getFullYear()}`;
              }

              let userColA = {
                Nome: user.nome,
                Email: user.email,
                Telefone: user.telefone,
                Departamento: user.departamentoNome,
                Setor: user.setorNome,
              };
              let userColB = {
                Ramal: user.ramal,
                Admin: user.admin ? "Sim" : "Não",
                ...(pref.voxBot && {
                  Atendente: user.atendente ? "Sim" : "Não",
                }),
                ...(pref.agenda && {
                  Profissional: user.profissional ? "Sim" : "Não",
                }),
                Status: user.ativo ? "Ativo" : "Inativo",
                Reativado: dataAtivado,
                Inativado: dataInativado,
              };
              delete user.password;

              return (
                <div className="user-card" key={index}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      className="contact-avatar"
                      style={{ width: 80, height: 80 }}
                      src={user.foto ? user.foto : "/imgs/avatar2.png"}
                      alt="avatar"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 3,
                      flexDirection: "column",
                      padding: 10,
                    }}
                  >
                    {Object.entries(userColA).map(([k, v]) => {
                      return (
                        <span key={k}>
                          <b>{k}: </b>
                          {v !== "" ? v : `Sem ${k}`}
                        </span>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 2,
                      flexDirection: "column",
                      padding: 10,
                    }}
                  >
                    {Object.entries(userColB).map(([k, v]) => {
                      if (
                        (user.ativo && k === "Inativado") ||
                        (!user.ativo && k === "Reativado")
                      )
                        return;
                      if (
                        (user.ativo &&
                          !user.config.reativado &&
                          k === "Inativado") ||
                        (user.ativo &&
                          !user.config.reativado &&
                          k === "Reativado")
                      )
                        return;
                      return (
                        <span
                          key={k}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <b>{k}: </b>
                          {k === "Status" ? (
                            <p
                              style={
                                user.ativo === true
                                  ? {
                                      margin: "0px 0px 0px 5px",
                                      backgroundColor: "green",
                                      color: "white",
                                      padding: "0px 10px 0px 10px",
                                      borderRadius: 5,
                                      fontSize: 13,
                                    }
                                  : {
                                      margin: "0px 0px 0px 5px",
                                      fontSize: 13,
                                      backgroundColor: "#c23838",
                                      color: "white",
                                      padding: "0px 10px 0px 10px",
                                      borderRadius: 5,
                                    }
                              }
                            >
                              {v !== "" ? v : `Sem ${k}`}
                            </p>
                          ) : (
                            <p style={{ margin: "0px 0px 0px 5px" }}>
                              {v !== "" ? v : `Sem ${k}`}
                            </p>
                          )}
                        </span>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <ButtonsCrud
                      buttons={[
                        {
                          action: "editar",
                          title: "Editar Usuário",
                          class: "editUserButton",
                          icon: (
                            <FaUserEdit
                              style={{ width: 30 }}
                              className="button-icon"
                            />
                          ),
                          disabled: !user.ativo,
                        },
                        {
                          action: "inativar",
                          title: `${
                            user.ativo ? "Inativar Usuário" : "Ativar Usuário"
                          }`,
                          class: user.ativo
                            ? "delUserButton"
                            : "activeUserButton",
                          icon: user.ativo ? (
                            <FaUserTimes
                              style={{ width: 30 }}
                              className="button-icon"
                            />
                          ) : (
                            <FaUserCheck
                              style={{ width: 30 }}
                              className="button-icon"
                            />
                          ),
                          disabled: loading,
                        },
                      ]}
                      handle={{
                        editar: (e) => props.editUser(user),
                        inativar: user.ativo
                          ? (e) => props.inactiveUser(user)
                          : (e) => props.activeUser(user),
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <Empty data="usuários" />
          </>
        )}
      </div>
    </>
  );
}
