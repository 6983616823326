import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../../../../../contexts/UserProvider";
import { ConviteBotContatos } from "./conviteBotContatos";
import { ConviteBotGrupos } from "./conviteBotGrupos";
import { ModalConviteBotHeader } from "./modalConviteBotHeader";
import { ContatosSelecionados } from "./contatosSelecionados";
import { TiDelete } from "react-icons/ti";

export const ModalConviteBot = ({ setIsModalOpen, conviteReuniaoBot }) => {
  const { socket } = useContext(UserContext);
  const modalRef = useRef(null);
  const [pesquisa, setPesquisa] = useState("");
  const [botSelecionado, setBotSelecionado] = useState("");
  const [contatosSelecionados, setContatosSelecionados] = useState([]);
  const [gruposSelecionados, setGruposSelecionados] = useState([]);
  const [msgConviteReuniaoBot, setMsgConviteReuniaoBot] = useState("");
  const [tipoContato, setTipoContato] = useState("contatos");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const contactsPerPage = 10;

  const tipoContatoDados = {
    contatos: {
      title: "Contatos Selecionados",
      data: contatosSelecionados,
      render: (contato, index) => (
        <p key={index} className="convite-bot-modal-contato-selecionado">
          {contato.pushname}
          <span onClick={() => handleSelectContato(contato)}>
            <TiDelete
              onMouseEnter={handleMouseEnter}
              title={`Remover ${contato.pushname}`}
              onMouseLeave={handleMouseLeave}
            />
          </span>
        </p>
      ),
      component: (
        <ConviteBotContatos
          pesquisa={pesquisa}
          botSelecionado={botSelecionado}
          contatosSelecionados={contatosSelecionados}
          setContatosSelecionados={setContatosSelecionados}
          contactsPerPage={contactsPerPage}
          setTotalPages={setTotalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          formatarTelefone={formatarTelefone}
        />
      ),
    },
    grupo: {
      title: "Grupos Selecionados",
      data: gruposSelecionados,
      render: (grupo, index) => (
        <p key={index} className="convite-bot-modal-contato-selecionado">
          {grupo.nome}
          <span onClick={() => handleSelectGrupo(grupo)}>
            <TiDelete
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              title={`Remover ${grupo.nome}`}
            />
          </span>
        </p>
      ),
      component: (
        <ConviteBotGrupos
          pesquisa={pesquisa}
          gruposSelecionados={gruposSelecionados}
          setGruposSelecionados={setGruposSelecionados}
          contactsPerPage={contactsPerPage}
          setTotalPages={setTotalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      ),
    },
  };

  function handleSelectGrupo(grupo) {
    setGruposSelecionados(
      gruposSelecionados.filter(
        (grupoSelecionado) => grupoSelecionado._id !== grupo._id
      )
    );
  }

  function handleSelectContato(contato) {
    setContatosSelecionados(
      contatosSelecionados.filter(
        (contatoSelecionado) => contatoSelecionado._id !== contato._id
      )
    );
  }

  function handleMouseEnter(e) {
    const parentNode = e.target.parentNode;
    const grandparentNode = parentNode.parentNode;
    grandparentNode.classList.add(
      "convite-bot-modal-contato-selecionado-hover"
    );
  }

  function handleMouseLeave(e) {
    const parentNode = e.target.parentNode;
    const grandparentNode = parentNode.parentNode;
    grandparentNode.classList.remove(
      "convite-bot-modal-contato-selecionado-hover"
    );
  }

  function formatarTelefone(telefone) {
    const codigoPais = telefone.slice(0, 2);
    const codigoDDD = telefone.slice(2, 4);
    const primeiraParte = telefone.slice(4, telefone.length - 4);
    const segundaParte = telefone.slice(telefone.length - 4);
    return `+${codigoPais} (${codigoDDD}) ${primeiraParte}-${segundaParte}`;
  }

  const handleSelectBot = (e) => {
    const botId = e.target.value;
    setBotSelecionado(botId);
  };

  const handleConviteBot = (e) => {
    e.preventDefault();
    const novoConviteReuniaoBot = { ...msgConviteReuniaoBot };
    const msgConviteBot = novoConviteReuniaoBot.mensagem;
    if (contatosSelecionados.length > 0) {
      for (let i = 0; i < contatosSelecionados.length; i++) {
        const { pushname, telefone, bot, _id } = contatosSelecionados[i];
        const data = {
          message: msgConviteBot,
          to: { pushname, telefone, bot, _id },
          type: "att",
          audio: null,
          files: {},
          reply: null,
          forwarding: null,
        };
        socket.emit("send att", data);
      }
      setContatosSelecionados([]);
    }
    if (gruposSelecionados.length > 0) {
      for (let i = 0; i < gruposSelecionados.length; i++) {
        for (let j = 0; j < gruposSelecionados[i].contatos.length; j++) {
          const { pushname, telefone, bot, _id } =
            gruposSelecionados[i].contatos[j];
          const data = {
            message: msgConviteBot,
            to: { pushname, telefone, bot, _id },
            type: "att",
            audio: null,
            files: {},
            reply: null,
            forwarding: null,
          };
          socket.emit("send att", data);
        }
      }
      setGruposSelecionados([]);
    }
    setIsModalOpen(false);
  };

  const isContatosBotFilled = () => {
    if (botSelecionado) {
      return contatosSelecionados?.length || gruposSelecionados?.length;
    }
  };

  useEffect(() => {
    const novaMsgConviteBot = conviteReuniaoBot ? conviteReuniaoBot : "";
    setMsgConviteReuniaoBot(novaMsgConviteBot);
  }, [conviteReuniaoBot]);

  useEffect(() => {
    const newSelects = [...contatosSelecionados];
    for (let i = 0; i < newSelects.length; i++) {
      let contato = newSelects[i];
      contato.bot = botSelecionado;
    }
    setContatosSelecionados(newSelects);
  }, [botSelecionado]);

  return (
    <div className="convite-bot-modal" ref={modalRef}>
      <ModalConviteBotHeader
        setPesquisa={setPesquisa}
        handleSelectBot={handleSelectBot}
        tipoContato={tipoContato}
        setTipoContato={setTipoContato}
        botSelecionado={botSelecionado}
      />
      <ContatosSelecionados
        tipoContatoDados={tipoContatoDados}
        tipoContato={tipoContato}
        contatosSelecionados={contatosSelecionados}
        gruposSelecionados={gruposSelecionados}
      />
      {tipoContatoDados[tipoContato].component}
      <div className="convite-bot-button-container">
        <button
          onClick={handleConviteBot}
          disabled={!isContatosBotFilled()}
          className="convite-sala-form-button"
        >
          Enviar convite
        </button>
        <button
          className="convite-sala-form-button-cancel"
          onClick={() => setIsModalOpen(false)}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};
