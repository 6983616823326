import React, { useState, useEffect } from "react";

const Espacamento = (props) => {
  const [horarioEspacamento, setHorarioEspacamento] = useState("");
  const [profissional, setProfissional] = useState({
    ...props.profissional,
  });

  useEffect(() => {
    let novoProfissional = { ...props.profissional };
    setProfissional(novoProfissional);
    let novoHorarioEspacamento = novoProfissional.espacoAgendamento;
    if (novoHorarioEspacamento) {
      setHorarioEspacamento(novoHorarioEspacamento);
    }
  }, [props.profissional]);

  const onChangeTempoEspacamento = (e) => {
    let novoProfissional = { ...profissional };
    novoProfissional.espacoAgendamento = e.target.value;
    setHorarioEspacamento(e.target.value);
    props.setProfissional(novoProfissional);
  };

  return (
    <div className="input-container">
      <label className="required-prof">Espaço de agendamento:</label>
      <input
        type="time"
        id="nome"
        min="00:00"
        max="12:00"
        placeholder="Tempo de espaçamento agenda"
        value={horarioEspacamento}
        onChange={(e) => {
          onChangeTempoEspacamento(e);
        }}
      />
    </div>
  );
};

export default Espacamento;
