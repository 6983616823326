import { useState, useEffect, useContext, useRef } from "react";
import { QSGAgendaContext } from "../../../../../contexts/QSGProvider/QSGAgendaProvider";
import "./styles.css";

export const useForm = ({ formInputs, data, setData, selected, setSelected, editingStates, setEditingStates, options, selectedOptions, setSelectedOptions, currentForm, multForms, setMultForms, prevForm, listKey, inputList, setInputList, inputListKey, optionsList, editingSelected, setEditingSelected, selectedItem, setSelectedItem, prevKey, prevSelectedOptions, setPrevSelectedOptions }) => {
  const { accordionParentSection, accordionSubSection } = useContext(QSGAgendaContext);
  const [formValues, setFormValues] = useState({});
  const [formStates, setFormStates] = useState({});
  const [currentListKey, setCurrentListKey] = useState("");
  const [prevListKey, setPrevListKey] = useState("");
  const [listKeys, setListKeys] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [inputListKeys, setInputListKeys] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});

  const actions = {
    addToInputList: (key, subKeys) => {
      const newObject = {};
      subKeys.forEach((subKey) => {
        if (subKey.inputType === "checkbox") {
          newObject[subKey.key] = [];
        } else {
          newObject[subKey.key] = "";
        }
      });
      setInputList([...inputList, newObject]);
      setInputListKeys([...inputListKeys, { key, subKeys }]);
    },
  };

  const getInitialState = () => {
    const initialState = {};
    formInputs.forEach((input) => {
      if (input.type === "object") {
        initialState[input.key] = {};
        input.subKeys.forEach((subKey) => {
          if (subKey.inputType === "checkbox") {
            initialState[input.key][subKey.key] = [];
          } else {
            initialState[input.key][subKey.key] = "";
          }
        });
      } else if (inputList && inputListKey) {
        initialState[inputListKey] = [];
      } else if (input.type === "arrayObject" || input.type === "reactSelectListArray" || input.type === "reactSelectList" || input.type === "reactSelect" || input.type === "checkbox") {
        initialState[input.key] = [];
      } else {
        initialState[input.key] = "";
      }
    });
    return initialState;
  };

  const setFormType = (newFormType) => {
    setMultForms((prevStack) => [...prevStack, newFormType]);
  };

  const handleBack = () => {
    const previousForm = multForms.length > 1 ? multForms[multForms.length - 2] : null;
    if (previousForm && editingSelected) setEditingStates((prev) => ({ ...prev, [previousForm]: true }));

    if (prevForm && currentForm && formStates[prevForm] && formStates[currentForm]) {
      setFormStates((prevState) => {
        const newState = { ...prevState };
        delete newState[currentForm];
        return newState;
      });
    }

    if (selectedOptions) {
      const isSelectedOptionOnTheList = formStates[prevForm][prevListKey]?.some((item) => item[listKey]?.some((i) => i._id === selectedOption._id));
      if (!isSelectedOptionOnTheList) {
        setSelectedOptions((prevState) => prevState.filter((option) => option._id !== selectedOption._id));
      }
    }

    setMultForms((prevStack) => prevStack.slice(0, -1));

    setCurrentListKey(prevListKey);

    setListKeys((prevState) => prevState.slice(0, -1));
  };

  const handleSave = (listKey) => {
    setMultForms((prevStack) => prevStack.slice(0, -1));
    let newFormValues = { ...formValues };
    for (let key in newFormValues) {
      if (formInputs.find((input) => input.key === key && input.type === "reactSelect")) {
        newFormValues[key] = newFormValues[key].value;
      } else if (inputList && inputListKey) {
        newFormValues[inputListKey] = inputList;
      }
    }

    const index = formStates[prevForm][listKey]?.findIndex((item) => item === selected);
    const currentKey = listKeys[listKeys.length - 1];
    const prevListIndex = formStates[prevForm][currentKey]?.findIndex((prevListItem) => prevListItem._id === formValues._id);

    if (index !== -1) {
      setFormStates({ ...formStates, [prevForm]: { ...formStates[prevForm], [currentKey]: formStates[prevForm][currentKey].map((item, i) => (i === prevListIndex ? { ...item, ...newFormValues } : item)) } });

      setCurrentListKey(prevListKey);
      setListKeys((prevState) => prevState.slice(0, -1));
      setFormValues(getInitialState());
      const previousForm = multForms.length > 1 ? multForms[multForms.length - 2] : currentForm;
      if (previousForm && editingSelected) setEditingStates((prev) => ({ ...prev, [previousForm]: true }));
    }
  };

  const handleCreate = () => {
    const values = {};
    for (let key in formValues) {
      if (Array.isArray(formValues[key])) {
        values[key] = formValues[key].map((option) => option.value);
        if (listKey) values[listKey] = formValues[key];
      } else {
        const input = formInputs.find((input) => input.key === key || (input.subKeys && input.subKeys.find((subKey) => subKey.key === key)));
        if (input.type === "object") {
          if (!values[input.key]) {
            values[input.key] = {};
          }
          if (key === input.key) {
            values[key] = formValues[key];
          } else {
            values[input.key][key] = formValues[key];
          }
        } else {
          values[key] = formValues[key];
        }
      }
    }
    setData([...data, values]);
    const initialState = {};
    formInputs.forEach((input) => {
      if (input.type === "object") {
        input.subKeys.forEach((subKey) => {
          initialState[subKey.key] = "";
        });
      } else {
        initialState[input.key] = "";
        if (input.type === "reactSelect") {
          initialState[input.key] = [];
        }
      }
    });
    setFormValues(initialState);
    setSelectedOptions([]);
  };

  // useEffect(() => {
  //   console.log("prevForm", prevForm);
  //   console.log("currentForm", currentForm);
  //   // console.log("editingStates", editingStates);
  //   console.log("formStates", formStates);
  //   console.log("formValues", formValues);
  //   // console.log("currentListKey", currentListKey);
  //   // console.log("prevListKey", prevListKey);
  //   // console.log("multForms", multForms);
  //   // console.log("initialFormValues", initialFormValues);
  //   // console.log("inputList", inputList);
  //   // console.log("inputListKeys", inputListKeys);
  // }, [multForms, initialFormValues, formValues, prevForm, currentForm, inputList, inputListKeys, formStates, currentListKey, prevListKey, editingStates]);

  const handleCancel = () => {
    if (multForms && multForms.length > 1) {
      setFormType(multForms[0]);
      setMultForms([multForms[0]]);
      setFormValues({ ...initialFormValues });
    } else {
      setFormValues(getInitialState());
    }
    setFormStates({});
    setEditingStates({ [currentForm]: false });
    setSelected(null);
    setInputList([]);
    setInputListKeys([]);
    if (selectedItem) setSelectedItem(null);
    if (selectedOptions) setSelectedOptions([]);
    if (prevSelectedOptions) setPrevSelectedOptions([]);
    if (editingSelected) setEditingSelected(false);
  };

  const updateValues = (values, formValues, formInputs) => {
    for (let key in formValues) {
      if (Array.isArray(formValues[key])) {
        values[key] = formValues[key].map((option) => option.value);
        if (listKey) values[listKey] = formValues[key];
      } else {
        const input = formInputs.find((input) => input.key === key || (input.subKeys && input.subKeys.find((subKey) => subKey.key === key)));
        if (input.type === "object") {
          if (!values[input.key]) {
            values[input.key] = {};
          }
          for (let subKey in formValues[key]) {
            values[input.key][subKey] = formValues[key][subKey];
          }
        } else if (input.type === "reactSelect") {
          values[key] = formValues[key].value;
        } else {
          values[key] = formValues[key];
        }
      }
    }
    return values;
  };

  const handleEdit = () => {
    const updatedData = [...data];
    if (accordionSubSection && multForms) {
      updatedData.forEach((item) => {
        if (item[prevKey] && item[prevKey].length > 0) {
          item[prevKey].forEach((i) => {
            if (i._id === accordionSubSection._id) {
              const listKeyItemIndex = i[listKey].findIndex((l) => l._id === selected._id);
              if (listKeyItemIndex !== -1) {
                const values = { ...i[listKey][listKeyItemIndex] };
                values["_id"] = i[listKey][listKeyItemIndex]["_id"];
                i[listKey][listKeyItemIndex] = updateValues(values, formValues, formInputs);
              }
            }
          });
        }
      });
    }

    if (accordionParentSection && multForms) {
      const parentIndex = updatedData.findIndex((item) => item === accordionParentSection);
      if (parentIndex !== -1 && updatedData[parentIndex][prevKey] && updatedData[parentIndex][prevKey].length > 0) {
        const itemIndex = updatedData[parentIndex][prevKey].findIndex((item) => item === selected);
        if (itemIndex !== -1) {
          const values = { ...updatedData[parentIndex][prevKey][itemIndex] };
          values["_id"] = updatedData[parentIndex][prevKey][itemIndex]["_id"];
          updatedData[parentIndex][prevKey][itemIndex] = updateValues(values, formValues, formInputs);
        }
      }
    }

    const index = updatedData.findIndex((item) => item === selected);
    if (index !== -1) {
      const values = { ...updatedData[index] };
      values["_id"] = updatedData[index]["_id"];
      updatedData[index] = updateValues(values, formValues, formInputs);
    }
    setData(updatedData);
    setFormValues(getInitialState());
    setSelected(null);
    setEditingStates((prev) => ({ ...prev, [prevForm]: false }));
    if (editingSelected) setEditingSelected(false);
    if (selectedOptions) setSelectedOptions([]);
    if (multForms?.length > 1 && multForms) setFormType(multForms[0]);
  };

  useEffect(() => {
    const initialState = getInitialState();
    setFormValues(initialState);
  }, []);

  /* useEffect para inicializar e atualizar o formValues quando a propriedade 'selected' muda.
 Se 'selected' for verdadeiro, o estado inicial é definido com base nos valores selecionados.
 Para cada input no formInputs:
 - Se o tipo de input for 'reactSelect' e o valor selecionado for uma matriz, o estado inicial é atualizado com as opções correspondentes.
 - Se o tipo de input for 'reactSelect' e o valor selecionado não for uma matriz, o estado inicial é atualizado com o valor e o rótulo correspondentes.
 - Se o tipo de input for 'reactSelectList', o estado inicial é atualizado com os valores selecionados e as opções selecionadas são atualizadas.
 - Para outros tipos de input, o estado inicial é atualizado com o valor selecionado.
 Finalmente, formValues é definido para o estado inicial.
*/

const isSelectedKeysEqualFormStatesCurrentFormKeysRef = useRef(selected && formStates[currentForm] && Object.keys(selected).filter(key => key !== '_id').every((key) => key in formStates[currentForm]));
const isSelectedKeysEqualFormStatesCurrentFormKeys = selected && formStates[currentForm] && Object.keys(selected).filter(key => key !== '_id').every((key) => key in formStates[currentForm]);

console.log("isSelectedKeysEqualFormStatesCurrentFormKeys", isSelectedKeysEqualFormStatesCurrentFormKeys);
useEffect(() => {
  if (selected && Object.keys(selected).length > 0) {
      // isSelectedKeysEqualFormStatesCurrentFormKeysRef.current = false;
      
      // if (selectedItem) setSelectedItem({});
      const initialState = getInitialState();
      let newStateSet = false;
      formInputs.forEach((input) => {
        if (input.type === "reactSelect") {
          if (Array.isArray(selected[input.key])) {
            initialState[input.key] = options[input.key].filter((option) => selected[input.key].includes(option.value));
            if (selectedOptions) {
              const newList = selected[input.key].map((id) => id._id);
              setSelectedOptions((prevState) => [...prevState, ...newList]);
            }
          } else if (formInputs.find((input) => input.key && input.type === "reactSelect")) {
            const option = options[input.key].find((option) => option.value === selected[input.key]);
            if (option) {
              initialState[input.key] = { value: selected[input.key], label: option.label };
              newStateSet = true;
            }
          }
        } else if (input.type === "reactSelectList") {
          if (selected[input.key] && selected[input.key].length > 0) {
            initialState[listKey] = selected[input.key];
            const selectedIds = selected[input.key]?.map((item) => item._id);
            const selectedOptions = optionsList.filter((option) => selectedIds?.includes(option._id));
            if (selectedOptions && selectedOptions.length > 0) {
              setSelectedOptions((prevState) => [...prevState, ...selectedOptions]);
              newStateSet = true;
            }
          }
        } else if (input.type === "arrayObject") {
          if (selected[input.key]) {
            const selectedValues = selected[input.key];
            setInputList(selectedValues);
            setInputListKeys([{ key: input.key, subKeys: input.subKeys }]);
            newStateSet = true;
          }
        } else if (input.type === "reactSelectListArray") {
          initialState[input.key] = selected[input.key];
          const selectedIds = selected[input.key]?.map((item) => item._id);
          const selectedOptions = optionsList.filter((option) => selectedIds?.includes(option._id));
          setSelectedOptions(selectedOptions);
        } else {
          if (selected[input.key] !== undefined) {
            initialState[input.key] = selected[input.key];
            newStateSet = true;
          }
        }
      });

      if (newStateSet) {
        setFormValues({ ...initialState });
      }
    }
  }, [selected, editingStates, formInputs]);

  /*
   useEffect para inicializar e atualizar o formValues quando o currentForm muda.
   Se formStates[currentForm] existir, formValues é atualizado para formStates[currentForm].
   Caso contrário, se formValues[currentListKey] existir e tiver comprimento maior que 0,
   formValues é atualizado para o estado anterior correspondente, se existir.
   Além disso, formValues[currentListKey] é uma matriz que atualiza as opções do componente React Select com base no conteúdo da lista.
*/
console.log("isSelectedKeysEqualFormStatesCurrentFormKeysRef", isSelectedKeysEqualFormStatesCurrentFormKeysRef.current);
  useEffect(() => {
    const initialState = getInitialState();


    
    if (formStates[currentForm]
      //  && isSelectedKeysEqualFormStatesCurrentFormKeysRef === false 
       ) {
      console.log('aqui')
      setFormValues(formStates[currentForm]);
    } else if (formValues[currentListKey] && formValues[currentListKey].length > 0) {
      let index = -1;
      if (formStates[prevForm] && formStates[prevForm][currentListKey]) {
        index = formStates[prevForm][currentListKey].findIndex((item) => item === selected);
      }
      if (index !== -1) {
        let newformValues = { ...formStates[prevForm][currentListKey][index] };
        for (let key in newformValues) {
          if (formInputs.find((input) => input.key === key && input.type === "reactSelect")) {
            const option = options[key].find((option) => option.value === newformValues[key]);
            newformValues[key] = { value: newformValues[key], label: option ? option.label : "" };
          } else if (formInputs.find((input) => input.key === key && input.type === "arrayObject")) {
            setInputList(newformValues[key]);
            setInputListKeys([{ key, subKeys: formInputs.find((input) => input.key === key).subKeys }]);
          } else if (formInputs.find((input) => input.key === key && input.type === "reactSelectListArray")) {
            const selectedIds = newformValues[key]?.map((item) => item._id);
            const selectedOptions = optionsList.filter((option) => selectedIds?.includes(option._id));
            setSelectedOptions(selectedOptions);
          }
        }
        formInputs.forEach((input) => {
          if (input.type === "object") {
            input.subKeys.forEach((subKey) => {
              if (subKey.inputType === "checkBox") {
                initialState[input.key][subKey.key] = [];
              }
            });
          }
        });
        setFormValues({ ...initialState, ...newformValues });
      }
    }
  }, [currentForm]);

  useEffect(() => {
    if (multForms && multForms[0] === currentForm) setInitialFormValues(getInitialState());
  }, []);

  return {
    formValues,
    setFormValues,
    setFormStates,
    handleCreate,
    handleEdit,
    handleCancel,
    setFormType,
    handleBack,
    handleSave,
    currentListKey,
    setCurrentListKey,
    formStates,
    setPrevListKey,
    selectedOption,
    setSelectedOption,
    actions,
    inputListKeys,
    listKeys,
    setListKeys,
  };
};
