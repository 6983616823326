import "../styles.css";
import { useState, useContext } from "react";
import { VideoContext } from "../../../contexts/VideoProvider";
import Alert from "../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import ProfileForm from "../../Profile/components/profileForm";

export const PersonalizarSala = () => {
  const { sala, editarSala, alert, setAlert } = useContext(VideoContext);
  const [alterado, setAlterado] = useState(false);
  const [minhaSala, setMinhaSala] = useState({
    nome: sala.nome,
    link: sala.link,
    administrador: sala.administrador,
    _id: sala._id,
  });

  const alertType = {
    sucess: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    error: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  const onChange = ({ target: { name, value } }) => {
    setMinhaSala((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAlterado(sala.link !== value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editarSala(minhaSala);
    setAlterado(false);
  };

  return (
    <>
      {alert.visible && alertType[alert.type]}
      <div className="sala-container">
        <form className="personalizar-sala-form">
          <h2>Personalizar sala</h2>
          <p>
            Personalize do jeito que quiser! Sua sala, suas regras na Voxcity.
          </p>

          <div className="container-align-personalizar-sala">
            <div className="personalizar-sala-form-input-container">
              <label htmlFor="">Nome da sala</label>
              <input
                type="text"
                value={minhaSala.nome}
                onChange={onChange}
                name="nome"
              />
            </div>
            <div className="personalizar-sala-form-input-container">
              <label htmlFor="">Link da sala</label>
              <input
                type="text"
                value={minhaSala.link}
                onChange={onChange}
                name="link"
              />
            </div>
          </div>
          <div className="personalizar-sala-form-button-container">
            <button
              onClick={handleSubmit}
              className={`personalizar-sala-form-button ${
                alterado ? "" : "personalizar-sala-form-button-disabled"
              }`}
              disabled={!alterado}
              id="salvar"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
