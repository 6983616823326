import React, { Component } from 'react';
import SimplyForm from '../inputs/SimplyForm';
import ApiUsers from '../../../../ApiUsers';
import SelectUserForm from '../inputs/SelectUserForm';
import Alert from '../inputs/Alerts/Alert';

export default class SectionCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setor: {
                nome: "",
                departamento: "",
                usuarios: [],
                sala_de_conferencia: ""
            },
            alert: { visible: false, type: "", props: {} },
            loading: false,
        }
        this.createForm = this.createForm.bind(this)
        this.changeInput = this.changeInput.bind(this)
    }

    createForm(e) {
        e.preventDefault();
        const { nome, departamento, sala_de_conferencia } = this.state.setor;
        if (nome.trim() !== "" && departamento !== "" && sala_de_conferencia.trim() !== "") {
            this.setState({ loading: true });
            let NewSetor = this.state.setor;
            NewSetor.nome = nome.trim();
            NewSetor.departamento = departamento.trim();
            NewSetor.sala_de_conferencia = sala_de_conferencia.trim();
            ApiUsers.post('/setores/novo', { setor: NewSetor, departamento: NewSetor.departamento }).then(res => {
                let newState = this.state;
                newState.setor.nome = "";
                newState.setor.departamento = "";
                newState.setor.sala_de_conferencia = "";
                newState.loading = false;
                this.setState(newState);
                this.createAlert("normal", { title: "Sucesso!", placeholder: "Setor criado com sucesso!" });
                document.querySelector("#section-form").reset();
            });
        } else {
            this.createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencher os campos vazios!" });
            this.setState({ loading: false });
        }
    }

    changeInput(e) {
        let newState = this.state;
        newState.setor[e.target.name] = e.target.value;
        this.setState(newState);
    }

    quitAlert() {
        this.setState({ alert: { visible: false, type: "", props: {} } });
    }

    createAlert(type, props) {
        this.setState({ alert: { visible: true, type, props } });
    }

    render() {

        const alertType = {
            normal: <Alert title={this.state.alert.props.title} placeholder={this.state.alert.props.placeholder} quit={() => this.quitAlert()} />,
        }

        return <>

            <div className="create-section-container">

                {this.state.alert.visible && (alertType[this.state.alert.type])}

                <SimplyForm
                    title="Criação de Setores"
                    id="section-form"
                    field="nome"
                    field2="sala_de_conferencia"
                    placeholder="Digite aqui o nome do setor"
                    placeholder2="Digite aqui a sala de conferência"
                    handleSubmit={this.createForm}
                    handleChange={this.changeInput}
                    value={this.state.setor.nome}
                    value2={this.state.setor.sala_de_conferencia}
                    loading={this.state.loading}
                >

                    <SelectUserForm
                        id="section-form"
                        field="departamento"
                        value={this.state.setor.departamento}
                        handleChange={this.changeInput}
                    />

                </SimplyForm>

            </div>

        </>

    }
}

