import { createContext, useContext, useState, useEffect } from "react";
import { UserContext } from "./UserProvider";
import ApiUsers from "../ApiUsers";
import ApiCidadao from "../ApiCidadao";

export const CalendarioContext = createContext();

export const CalendarioProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const prefeitura = userContext.pref._id;
  const [agendas, setAgendas] = useState([]);
  const [prefeituraId, setPrefeituraId] = useState("");
  const [isLoadingCalendario, setIsLoadingCalendario] = useState(false);

  useEffect(() => {
    const novaPrefeituraId = userContext.pref._id ? userContext.pref._id : "";
    setPrefeituraId(novaPrefeituraId);
  }, [userContext.pref]);

  const marcarHorario = async (agenda) => {
    return new Promise((resolve, reject) => {
      ApiCidadao.post("/agendamento/marcar", agenda)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const desmarcarHorario = async (agenda) => {
    return new Promise((resolve, reject) => {
      ApiCidadao.post("/agendamento/desmarcar", agenda)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const bloquearHorario = async (agenda) => {
    return new Promise((resolve, reject) => {
      ApiCidadao.post("/agendamento/bloquear", agenda)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const desbloquearHorario = async (agenda) => {
    return new Promise((resolve, reject) => {
      ApiCidadao.post("/agendamento/desbloquear", agenda)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const confirmarAgendamento = async (hash) => {
    return new Promise((resolve, reject) => {
      ApiCidadao.get(`/accept?hash=${hash}&_id=${prefeituraId}&userAgent=chat`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const setMarked = (agenda) => {
    let newAgendas = [...agendas];
    newAgendas = newAgendas.map((a) => {
      if (
        a.profissional === agenda.profissional &&
        a.especialidade === agenda.especialidade &&
        a.local === agenda.local &&
        a.serviço === agenda.servico
      ) {
        a.datasMarcadas.push({
          data: agenda.data,
          usuario: agenda.usuario,
          status: agenda.status,
          hash: agenda.hash,
        });
      }
      return a;
    });
    setAgendas(newAgendas);
  };

  const setUnMarked = (agenda) => {
    let newAgendas = [...agendas];
    newAgendas = newAgendas.map((a) => {
      if (
        a.profissional === agenda.profissional &&
        a.especialidade === agenda.especialidade &&
        a.local === agenda.local &&
        a.serviço === agenda.servico
      ) {
        a.datasMarcadas = a.datasMarcadas.filter(
          (data) => data.data !== agenda.data
        );
      }
      return a;
    });
    setAgendas(newAgendas);
  };

  const receberAgendas = async () => {
    setIsLoadingCalendario(true);
    console.log("montando calendario");
    await ApiUsers.post("/agendas/getAgendas", { prefeitura })
      .then((response) => {
        setAgendas(response.data.agendas);
        setIsLoadingCalendario(false);
        console.log("calendario montado");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function naoCompareceu(hashID) {
    ApiCidadao.post("/noshow", {
      _id: prefeituraId,
      hash: hashID,
    })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <CalendarioContext.Provider
      value={{
        agendas,
        receberAgendas,
        setMarked,
        setUnMarked,
        prefeituraId,
        marcarHorario,
        desmarcarHorario,
        isLoadingCalendario,
        bloquearHorario,
        desbloquearHorario,
        confirmarAgendamento,
        naoCompareceu,
      }}
    >
      {children}
    </CalendarioContext.Provider>
  );
};
