import { useState, useEffect } from "react";
import ApiUsers from "../../../../ApiUsers";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import "./style.css";
import Select from "react-select";
import { EspecialidadeCard } from "./EspecialidadeCard";
import { EspecialidadeForm } from "./EspecialidadeForm";

export const LocalForm = (props) => {
  const [local, setLocal] = useState({
    _id: "",
    nome: "",
    endereco: { rua: "" },
    fone: "",
    especialidades: [],
    timeToStart: "",
  });

  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState(
    []
  );
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState({});
  const [especialidades, setEspecialidades] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [editandoEspecialidade, setEditandoEspecialidade] = useState(false);
  const [optionsProfissionais, setOptionsProfissionais] = useState([]);
  const [profissionaisEspecialidade, setProfissionaisEspecialidade] = useState(
    []
  );

  useEffect(() => {
    let novoLocal = { ...props.local };
    setLocal(novoLocal);
    const novasEspecialidades = [...props.especialidades];
    setEspecialidades(novasEspecialidades);
    const novosProfissionais = [...props.profissionais];
    setProfissionais(novosProfissionais);
    const novasEspecialidadesSelecionadas = [...props.local.especialidades];
    setEspecialidadesSelecionadas(novasEspecialidadesSelecionadas);
  }, [
    props.especialidades,
    props.local,
    props.profissionais,
    props.local.especialidades,
  ]);

  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    const novaEspecialidadeSelecionada = { ...props.especialidadeSelecionada };
    setEspecialidadeSelecionada(novaEspecialidadeSelecionada);
  }, [props.especialidadeSelecionada]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  const validateLocal = () => {
    if (!local.nome || !local.endereco.rua || !local.fone) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, preencha todos os campos.",
      });
      return false;
    }
    return true;
  };

  const validateEspecialidade = () => {
    let qtdEspecialidade = especialidadesSelecionadas.map(
      (especialidade) => especialidade
    );

    if (!qtdEspecialidade.length) {
      createAlert("normal", {
        title: "Erro",
        placeholder: "Por favor, adicione pelo menos uma especialidade.",
      });
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateLocal()) {
      return;
    }
    if (!validateEspecialidade()) {
      return;
    }

    ApiUsers.post("/locais/novo", {
      local: {
        ...local,
        especialidades: [...especialidadesSelecionadas],
      },
    }).catch((err) => console.log(err));
    setEspecialidadeSelecionada(null);
    props.setSelecionado(null);
  };

  const onSubmitUpdate = (e) => {
    e.preventDefault();
    if (!validateLocal()) {
      return;
    }
    if (!validateEspecialidade()) {
      return;
    }
    local.especialidades = [...especialidadesSelecionadas];
    props.updateLocal(local);
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "rua") {
      setLocal((prevState) => ({
        ...prevState,
        endereco: {
          ...prevState.endereco,
          rua: value,
        },
      }));
    } else {
      setLocal((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onChangeEspecialidade = (selectedOption) => {
    setEspecialidadeSelecionada(selectedOption);
  };

  const handleBack = () => {
    props.setSelecionado(null);
    props.setEditando(false);
  };

  const optionsEspecialidades = especialidades
    .filter(
      (especialidade) =>
        !especialidadesSelecionadas.find((es) => es._id === especialidade._id)
    )
    .map((especialidade) => ({
      value: especialidade._id,
      label: especialidade.nome,
    }));

  const addEspecialidade = () => {
    if (
      especialidadeSelecionada == null ||
      Object.keys(especialidadeSelecionada).length === 0
    )
      return;
    const especialidade = especialidades.find(
      (esp) => esp._id === especialidadeSelecionada.value
    );

    console.log(especialidade);

    const profiEspecialidade = especialidade.profissionais.map(
      (profissional) => ({
        value: profissional._id,
        label: profissional.nome,
      })
    );
    setOptionsProfissionais([...profiEspecialidade]);

    const novaEspecialidade = {
      ...especialidade,
      horarioFuncionamento: {
        inicio: "",
        fim: "",
        dias: [],
      },
      profissionais: [],
      limiteVagas: "",
    };

    setEspecialidadesSelecionadas([
      ...especialidadesSelecionadas,
      novaEspecialidade,
    ]);

    setEspecialidadeSelecionada(null);
  };
  const atualizarEspecialidade = (novoProfissional) =>
    setEspecialidadesSelecionadas((prevProfissionais) =>
      prevProfissionais.map((profissionalMap) =>
        profissionalMap._id === novoProfissional._id
          ? novoProfissional
          : profissionalMap
      )
    );

  return (
    <>
      {editandoEspecialidade && <div className="bg-modal-local"></div>}
      <form
        className="local-form"
        onSubmit={props.editando ? onSubmitUpdate : onSubmit}

        // style={
        //   especialidadesSelecionadas.length > 0
        //     ? { height: "100%" }
        //     : { height: "55%" }
        // }
      >
        {alert.visible && alertType[alert.type]}
        <div className="local-form-title">LOCAL</div>
        <div className="local-form-content">
          <div className="local-form-input-container">
            <label htmlFor="nome" className="required-prof">
              Nome
            </label>
            <input
              type="text"
              id="nome"
              value={local.nome}
              onChange={onChange}
              name="nome"
            />
          </div>
          <div className="local-form-input-wrapper">
            <div className="local-form-input-container">
              <label htmlFor="rua" className="required-prof">
                Endereço
              </label>
              <input
                type="text"
                value={local.endereco.rua}
                onChange={onChange}
                name="rua"
                id="rua"
              />
            </div>
            <div className="local-form-input-container">
              <label htmlFor="fone" className="required-prof">
                Telefone
              </label>
              <input
                type="text"
                value={local.fone}
                onChange={onChange}
                name="fone"
                id="fone"
              />
            </div>
          </div>
          <div className="local-form-input-container">
            <label className="required-prof">Exibir horário a partir:</label>
            <input
              type="time"
              id="timeToStart"
              name="timeToStart"
              min="00:00"
              max="12:00"
              placeholder="Exibir horário a partir..."
              value={local.timeToStart}
              onChange={onChange}
            />
          </div>
          <div className="local-form-input-container">
            <label className="required-prof">Especialidades</label>
            <div className="local-form-input-container select">
              <Select
                className={"especialidade-react-select"}
                options={optionsEspecialidades}
                value={
                  especialidadeSelecionada
                    ? optionsEspecialidades.find(
                        (option) => option.value === especialidadeSelecionada
                      )
                    : null
                }
                onChange={onChangeEspecialidade}
                noOptionsMessage={() => null}
                placeholder={
                  optionsEspecialidades.length === 0
                    ? "Sem Especialidades"
                    : "Selecione..."
                }
              />
              <button
                className={
                  especialidadeSelecionada == null ||
                  Object.keys(especialidadeSelecionada).length == 0
                    ? "add-especialidade-btn-disabled"
                    : "add-especialidade-btn"
                }
                onClick={addEspecialidade}
                type="button"
              >
                <span>Adicionar</span>
              </button>
            </div>
          </div>

          <div className="button-container">
            <button id="back" type="button" onClick={handleBack}>
              Voltar
            </button>
            <button type="submit" id="add">
              Salvar
            </button>
          </div>
        </div>
        {especialidadesSelecionadas.length > 0 && (
          <>
            <div className="especialidades-adicionadas-title">
              Especialidades Adicionadas
            </div>
            <div className="cards-especialidades-container">
              <EspecialidadeCard
                especialidadesSelecionadas={especialidadesSelecionadas}
                setEspecialidadesSelecionadas={setEspecialidadesSelecionadas}
                setEditandoEspecialidade={setEditandoEspecialidade}
                setEspecialidadeSelecionada={setEspecialidadeSelecionada}
                setProfissionaisEspecialidade={setProfissionaisEspecialidade}
                especialidades={especialidades}
              />
            </div>
          </>
        )}
      </form>

      {editandoEspecialidade && (
        <EspecialidadeForm
          especialidadeSelecionada={especialidadeSelecionada}
          setEspecialidadeSelecionada={setEspecialidadeSelecionada}
          optionsProfissionais={optionsProfissionais}
          setOptionsProfissionais={setOptionsProfissionais}
          profissionais={profissionais}
          setEditandoEspecialidade={setEditandoEspecialidade}
          atualizarEspecialidade={atualizarEspecialidade}
          profissionaisEspecialidade={profissionaisEspecialidade}
          setProfissionaisEspecialidade={setProfissionaisEspecialidade}
        />
      )}
    </>
  );
};
