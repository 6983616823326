import { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";

const Header = (props) => {
  const { pref } = useContext(UserContext);
  const { title, little } = props;
  // if (little)
  return (
    <div className="header-view">
      <div className="chat-pref-pic-container">
        <img
          className="chat-pic-prefs"
          src={pref?.foto ? pref?.foto : "/imgs/PREFAS3.jpg"}
          alt="Logo do cliente"
        />
      </div>
      <h4
        style={{
          marginLeft: 10,
          marginTop: 5,
          color: "#142a4c",
          textShadow: "none",
          fontFamily: "SourceCodePro-Regular",
          fontSize: "1.2rem",
        }}
      >
        {title}
      </h4>
    </div>
  );
};

export default Header;
