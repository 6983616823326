import React, { useState } from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';

export default function FlowArea(props) {

    const { flows, selectedFlow, actionColors } = props;
    const [oldIndex, setOldIndex] = useState(0);
    const [allow, setAllow] = useState(false);

    function handleDrag(index) {
        setOldIndex(index);
        setAllow(true);
        props.setIgnore(true);
    }

    function onDragOver(e) {
        e.preventDefault();
    }

    function onDragEnd(e) {
        e.preventDefault();
        setAllow(false);
        props.setIgnore(false);
    }

    function onDrop(e, index) {
        e.preventDefault();
        if(allow) props.changeCmdIndex(oldIndex, index);
    }

    return <>

        <div className="flow-area">

            <div className="my-flows-list">

                {flows.length > 0 ? <>

                    {flows.map((flow, index) => {
                        return (
                            <div className="flows-list-item" key={index} title={flow.nome} onClick={() => props.selectFlow(flow.nome)} style={selectedFlow?.nome === flow.nome ? { backgroundColor: "rgb(45 142 235 / 33%)" } : null}>
                                <span className="flows-list-item-title"> {flow.nome} </span>
                                <div> 
                                    <AiFillEdit title={"Editar Nome Flow"} onClick={(e) => props.editModal(e, flow)} className="del-flow-icon"  />
                                    <FaTrash title={"Excluir Flow"} onClick={(e) => props.deleteModal(e, flow)} className="del-flow-icon" /> 
                                </div>
                            </div>
                        )
                    })}

                </> : <>

                    <div className="empty-command-list">
                        <h5 style={{ margin: 10 }}> Não há nenhum flow criado</h5>
                        <img src="imgs/cloud.png" alt="" style={{ width: 100, margin: 10 }} />
                    </div>

                </>}

            </div>

            <div className="flow-comands" onDrop={e => props.onDrop(e)} onDragOver={(e => props.onDragOver(e))}>

                {selectedFlow.nome ? <>

                    {selectedFlow.comandos.length > 0 ? <>

                        {selectedFlow.comandos.map((command, index) => {

                            let color;

                            actionColors.forEach((item) => {
                                if (item.name === command.tipo) color = item.color;
                            })

                            return (
                                <div className='flow-comands-item' title={command.tipo} key={index} draggable onDrag={() => handleDrag(index)} onDrop={e => onDrop(e, index)} onDragEnd={(e) => onDragEnd(e)} onDragOver={(e => onDragOver(e))} style={{ background: `${color}`, color: "white" }}>
                                    <span> <b> {index+1}º {command.tipo} - </b> {command.nome} </span>
                                    <div>
                                        <FaEdit title={"Editar Comando"} onClick={() => { props.selectCommand(index); props.showModal() }} className="edit-flow-icon" />
                                        <FaTrash title={"Excluir comando"} onClick={() => { props.deleteCmdModal(index, command) }} className="del-flow-icon" />
                                    </div>
                                </div>
                            )
                        })}

                    </> : <>

                        <div className="empty-command-list">
                            <img src="imgs/work.png" alt="" style={{ width: 130, margin: 10 }} />
                            <h5 style={{ margin: 20 }}>Arraste uma ação acima para criar novos comandos</h5>
                        </div>

                    </>}

                </> : <>

                    <div className="empty-command-list">
                        <img src="imgs/work.png" alt="" style={{ width: 130, margin: 10 }} />
                        <h5 style={{ margin: 20 }}>Não há flow selecionado</h5>
                    </div>

                </>}

            </div>

        </div>

    </>
}