import React, { useState, useEffect, useContext } from "react";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";
import ApiUsers from "../../../../ApiUsers";
import SectionListUsers from "./SectionsShow/SectionListUsers";
import SectionEdit from "./SectionsShow/SectionEdit";
import SectionList from "./SectionsShow/SectionList";
import DeleteAlert from "../inputs/DeleteAlert";
import SectionAddUser from "./SectionsShow/SectionAddUser";
import Alert from "../inputs/Alerts/Alert";
import "./Section.css";

export default function Sectionsshow() {
  const prefContext = useContext(PrefContext);
  const [setores, setSetores] = useState([]);
  const { departamentos } = prefContext;
  const [setoresOnShow, setSetoresOnShow] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState({});
  const [listUsers, setListUsers] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [filter, setFilter] = useState("nome");

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={() => alert.props.action()}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    setSetores(prefContext.setores);
    setSetoresOnShow(prefContext.setores);
    if (selectedSetor) {
      let newSector = prefContext.setores.find(
        (set) => set._id === selectedSetor._id
      );
      setSelectedSetor(newSector);
    }
  }, [prefContext.setores]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function delConfirm(section) {
    createAlert("delete", {
      data: "setor",
      nome: section.nome,
      action: () => delSection(section),
    });
  }

  function searchSetores(e) {
    const searchTerm = e.target.value.trim().toLowerCase();
    let list = [];

    if (searchTerm !== "") {
      if (filter === "nome") {
        list = setores.filter(
          (i) => i.nome.toLowerCase().indexOf(searchTerm) > -1
        );
      } else {
        list = setores.filter((setor) => {
          const departamento = departamentos.find(
            (departamento) => departamento._id === setor.depId
          );
          return (
            departamento && departamento.nome.toLowerCase().includes(searchTerm)
          );
        });
      }
    } else {
      list = [...setores];
    }
    setSetoresOnShow(list);
  }

  function changeFilter(e) {
    setFilter(e.target.value);
  }

  function delSection(section) {
    ApiUsers.post("/setores/delete", { setor: section })
      .then((res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Setor deletado com sucesso!",
        });
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {alert.visible && alertType[alert.type]}

      {listUsers ? (
        <>
          <SectionListUsers
            setor={selectedSetor}
            goBack={() => setListUsers(false)}
            alert={(type, props) => createAlert(type, props)}
          />
        </>
      ) : edit ? (
        <>
          <SectionEdit
            setor={selectedSetor}
            goBack={() => setEdit(false)}
            alert={(type, props) => createAlert(type, props)}
          />
        </>
      ) : addUsers ? (
        <>
          <SectionAddUser
            setor={selectedSetor}
            goBack={() => setAddUsers(false)}
            alert={(type, props) => createAlert(type, props)}
          />
        </>
      ) : (
        <>
          <SectionList
            setores={setoresOnShow}
            search={(e) => searchSetores(e)}
            setFilter={(e) => changeFilter(e)}
            delConfirm={(setor) => delConfirm(setor)}
            listUsers={(setor) => {
              setSelectedSetor(setor);
              setListUsers(true);
            }}
            addUsers={(setor) => {
              setSelectedSetor(setor);
              setAddUsers(true);
            }}
            edit={(setor) => {
              setSelectedSetor(setor);
              setEdit(true);
            }}
          />
        </>
      )}
    </>
  );
}
