import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { withZero } from "../../utils/index";

export default function detalhes(title, data, mensagem, pref, user) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let todayDate = new Date();
  let today = `${withZero(todayDate.getDate())}/${withZero(
    todayDate.getMonth() + 1
  )}/${withZero(todayDate.getFullYear())}`;
  let totalVotes = 0;
  data.forEach((row) => {
    totalVotes += parseInt(row.votos.length);
  });
  const table = {
    headerRows: 1,
    widths: ["*", "*", "*"],
    body: [
      [
        {
          text: "Opção",
          style: "tableHeader",
        },
        {
          text: "Quantidade",
          style: "tableHeader",
        },
        {
          text: "Percentual",
          style: "tableHeader",
        },
      ],

      ...data.map((row) => {
        console.log(row);
        return [
          { text: row.valor, style: "tableExample" },
          { text: row.votos.length, style: "tableExample" },
          {
            text: `${((row.votos.length / totalVotes) * 100).toFixed(2)}%`,
            style: "tableExample",
          },
        ];
      }),
    ],
  };

  const docDefinition = {
    content: [
      {
        text: [
          {
            text: "Organização: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: pref.nome,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          {
            text: "Data do Relatorio: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: today,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          {
            text: "Pesquisa: ",
            style: "header",
            alignment: "justify",
          },
          {
            text: title,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          {
            text: "Total de votos: ",
            style: "header",
            alignment: "justify",
          },

          {
            text: totalVotes,
            style: "headerMessage",
            alignment: "justify",
          },
        ],
        margin: [0, 0, 0, 20],
      },

      {
        text: "Mensagem da Pesquisa",
        style: "header",
        margin: [0, 0, 0, 20],
        alignment: "center",
        decoration: "underline",
      },
      {
        text: mensagem,
        style: "headerMessage",
        margin: [0, 0, 0, 20],
        alignment: "center",
      },
      {
        text: "Resumo da Pesquisa",
        style: "header",
        margin: [0, 0, 0, 20],
        alignment: "center",
        decoration: "underline",
      },

      {
        style: "tableExample",
        table,
      },
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      headerMessage: {
        fontSize: 16,
      },
      tableExample: {
        margin: [0, 0, 0, 0],
        fontSize: 13,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
        color: "black",
      },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}
