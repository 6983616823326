import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { modalInfoContent } from "./modalInfoContent";
import "./styles.css";

export const ModalInfo = ({ item, onClose, modalType }) => {
  const [modalContent, setModalContent] = useState({});

  useEffect(() => {
    console.log("item", item);
  }, [item]);

  const convertDayNumberToName = (dayNumber) => {
    const daysOfWeek = {
      1: "Segunda",
      2: "Terça",
      3: "Quarta",
      4: "Quinta",
      5: "Sexta",
      6: "Sábado",
      7: "Domingo",
    };
    return daysOfWeek[dayNumber];
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  useEffect(() => {
    setModalContent(modalInfoContent[modalType]);
  }, [modalType]);

  return (
    <div className="qsg-agenda-local-modal-container">
      <div className="qsg-agenda-local-modal-content">
        <div className="qsg-agenda-local-modal-header">
          <h3>{item.nome} </h3>
          <button
            className="qsg-agenda-local-modal-btn-close"
            onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="qsg-agenda-local-modal-body">
          <div className="qsg-agenda-local-modal-item-container">
            {modalContent.keys &&
              modalContent.keys.map((key, index) => {
                if (Array.isArray(item[key.key])) {
                  return item[key.key].map((interval, subIndex) => {
                    return (
                      <div
                        key={`key-${index}-${subIndex}`}
                        className="qsg-agenda-local-modal-item obj-item">
                        <span className="qsg-agenda-local-modal-item-label">
                          {key.label} {subIndex + 1}
                        </span>
                        {Object.keys(interval).map((subKey, subIndex) => {
                          if (Array.isArray(interval[subKey])) {
                            return (
                              <span key={`key-${index}-${subIndex}`}>
                                <span className="qsg-agenda-local-modal-item-subkey-label">{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:&nbsp;</span>
                                {subKey === "dias" ? interval[subKey].map(convertDayNumberToName).join(", ") : interval[subKey].join(", ")}
                              </span>
                            );
                          } else {
                            return (
                              <span key={`key-${index}-${subIndex}`}>
                                <span className="qsg-agenda-local-modal-item-subkey-label">{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:&nbsp;</span>
                                {interval[subKey]}
                              </span>
                            );
                          }
                        })}
                      </div>
                    );
                  });
                } else if (typeof item[key.key] === "object" && item[key.key] !== null) {
                  return (
                    <div
                      key={`key-${index}`}
                      className="qsg-agenda-local-modal-item obj-item">
                      <span className="qsg-agenda-local-modal-item-label">{key.label}</span>
                      {Object.keys(item[key.key]).map((subKey, subIndex) => {
                        if (Array.isArray(item[key.key][subKey])) {
                          return (
                            <span key={`key-${index}-${subIndex}`}>
                              <span className="qsg-agenda-local-modal-item-subkey-label">{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:&nbsp;</span>

                              {subKey === "dias" ? item[key.key][subKey].map(convertDayNumberToName).join(", ") : item[key.key][subKey].join(", ")}
                            </span>
                          );
                        } else {
                          return (
                            <span key={`key-${index}-${subIndex}`}>
                              <span className="qsg-agenda-local-modal-item-subkey-label">{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:&nbsp;</span>
                              {item[key.key][subKey]}
                            </span>
                          );
                        }
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={`key-${index}`}
                      className="qsg-agenda-local-modal-item">
                      <span className="qsg-agenda-local-modal-item-label">{key.label}:&nbsp;</span>
                      {item[key.key]}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
