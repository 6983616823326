import Calendar from "react-calendar";
import { useState, useEffect, useContext } from "react";
import { AgendaDia } from "../AgendaDia";
import { AgendaInfo } from "./AgendaInfo";
import { CalendarFunctions } from "../../../miscelaneous/utils/CalendarFunctions";
import { CalendarioContext } from "../../../../contexts/CalendarioProvider";
import { LoadingSpinner } from "../../../miscelaneous/Loader/load";

export const Calendario = (props) => {
  const calendarioContext = useContext(CalendarioContext);
  const { isLoadingCalendario } = calendarioContext;
  const [data, setData] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [markedDates, setMarkedDates] = useState([]);
  const [agendaSelecionada, setAgendaSelecionada] = useState({});
  const [agendas, setAgendas] = useState([]);
  const {
    getAgenda,
    horariosDisponiveis,
    horarioFuncionamento,
    horariosIntervalo,
    diaSelecionado,
    setDiaSelecionado,
  } = CalendarFunctions();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const novasAgendas = props.calendariosFiltrados
      ? [...props.calendariosFiltrados]
      : [...props.agendas];
    setAgendas(novasAgendas);
  }, [props.calendariosFiltrados, props.agendas]);

  const fetchData = async () => {
    if (diaSelecionado) {
      await getAgenda(agendaSelecionada);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [diaSelecionado, agendaSelecionada]);

  const tileClassName = ({ date }, agenda) => {
    const inputDate = date;
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const isMarked = agenda.datasMarcadas?.some(
      (data) => data.data.slice(0, 10) === formattedDate
    );

    return isMarked ? "marked" : "";
  };

  const handleTileClick = (date, agenda) => {
    setIsLoading(true);
    setShowModal(true);
    setDiaSelecionado(date);
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const markedDates = agenda.datasMarcadas.filter(
      (data) => data.data.slice(0, 10) === formattedDate
    );
    setMarkedDates(markedDates);
    setAgendaSelecionada(agenda);
  };

  return (
    <>
      {isLoading && <LoadingSpinner showModal={showModal} />}
      {isLoadingCalendario ? (
        <div className="spinner-container-agenda">
          CARREGANDO CALENDARIO
          <LoadingSpinner showModal={"none"} />
        </div>
      ) : (
        agendas.map((agenda, index) => (
          <div className="calendario-container" key={index}>
            <Calendar
              onChange={setData}
              value={data}
              locale={"pt-BR"}
              tileClassName={({ date }) => tileClassName({ date }, agenda)}
              onClickDay={(date) => {
                setIsLoading(true);
                setTimeout(() => {
                  handleTileClick(date, agenda);
                }, 250);
              }}
            />
            <AgendaInfo agendaSelecionada={agenda} />
          </div>
        ))
      )}

      {showModal && (
        <AgendaDia
          markedDates={markedDates}
          horariosDisponiveis={horariosDisponiveis}
          setShowModal={setShowModal}
          diaSelecionado={diaSelecionado}
          horarioFuncionamento={horarioFuncionamento}
          horariosIntervalo={horariosIntervalo}
          agendaSelecionada={agendaSelecionada}
          showModal={showModal}
          handleTileClick={(date) => handleTileClick(date, agendaSelecionada)}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
