export const formInputs = [
  {
    label: "Nome",
    key: "nome",
    type: "input",
    inputType: "text",
  },
  {
    label: "Profissionais",
    key: "profissionais",
    type: "reactSelect",
    config: {
      isMulti: true,
      placeholder: "Selecione",
      noOptionsMessage: () => "Sem profissionais",
    },
  },
];
