import React, { useEffect, useState, useContext } from "react";
import GaugeChart from "react-gauge-chart";
import { AttendanceContext } from "../../../../../contexts/ChatBotProvider/AttendanceProvider";
import Calendario from "../../FiltrosGraficos/Types/calendario";
import { LoadingSpinner } from "../../../../miscelaneous/Loader/load";

export default function GaugeMessage(props) {
  const { pref } = props;
  const { attendances, loadingAttsMessages } = useContext(AttendanceContext);
  const [percent, setPercent] = useState(0);
  const [messages, setMessages] = useState(0);
  const [atts, setAtts] = useState([]);
  const [totalUsers, setTotalUsers] = useState(1);
  let firstDate = { inicio: new Date(), fim: new Date() };
  firstDate.inicio.setHours(0, 0, 0, 0);
  firstDate.fim.setHours(23, 59, 59, 999);
  firstDate.inicio.setDate(firstDate.inicio.getDate() - 7);
  firstDate.fim.setDate(firstDate.fim.getDate() - 1);

  const [searchDate, setSearchDate] = useState(firstDate);

  const Views = {
    minuto: {
      title: "Por minuto",
      abrv: "MPM",
      divider: (days) => {
        return days / (24 / 60 / 60);
      },
      maxMessages: 25,
    },
    hora: {
      title: "Por hora",
      abrv: "MPH",
      divider: (days) => {
        return days / (24 / 60);
      },
      maxMessages: 500,
    },
    dia: {
      title: "Por dia",
      abrv: "MPD",
      divider: (days) => {
        return days;
      },
      maxMessages: 1000,
    },
  };

  const [view, setView] = useState("dia");

  useEffect(() => {
    if (pref.users && pref.users.length > 0) {
      let totalU = pref.users.filter(
        (user) => user.atendente || user.admin
      ).length;
      setTotalUsers(totalU);
    }
  }, [pref]);
  useEffect(() => {
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    start.setDate(start.getDate() - 7);
    let end = new Date();
    end.setHours(23, 59, 59, 999);
    end.setDate(end.getDate() - 1);
    setSearchDate({ inicio: start, fim: end });
  }, []);

  useEffect(() => {
    setAtts(JSON.parse(JSON.stringify(attendances)));
  }, [attendances]);

  useEffect(() => {
    filterAttsMessages();
  }, [atts, searchDate]);

  const filterAttsMessages = () => {
    let msgs = [];
    atts.forEach((att) => {
      if (att.allMessages && att.allMessages.length > 0) {
        att.allMessages.forEach((msg) => {
          if (msg.from === att.telefone) return;
          if (!msg.createdAt) return;
          let date = new Date(msg.createdAt);
          if (date >= searchDate.inicio && date <= searchDate.fim) {
            msgs.push(msg);
          }
        });
      }
    });
    let daysDiference = Math.fround(
      (searchDate.fim - searchDate.inicio) / (1000 * 60 * 60 * 24)
    );
    let media = msgs.length / Views[view].divider(daysDiference).toFixed(0);
    setMessages(media);
    let newPercent = media / Views[view].maxMessages.toFixed(0);
    if (newPercent > 1) newPercent = 1;
    setPercent(newPercent);
  };
  useEffect(() => {
    filterAttsMessages();
  }, [view]);

  return (
    <div className="gauge-div">
      <div className="gauge-title">
        <h3>Mensagens Enviadas</h3>
      </div>
      {loadingAttsMessages ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
            minHeight: "300px",
          }}
        >
          <LoadingSpinner showModal="none" />
        </div>
      ) : (
        <>
          <div className="gauge-options">
            <Calendario
              searchDate={searchDate}
              setSearchDate={setSearchDate}
              horizontal={true}
            />
          </div>

          <div className="gauge-titles">
            {Object.keys(Views).map((key, index) => {
              return (
                <div
                  key={index}
                  className={`gauge-option ${view === key ? "active-blue" : ""
                    }`}
                  onClick={() => setView(key)}
                >
                  {Views[key].title}
                </div>
              );
            })}
          </div>
          <div className="gauge-wrapper">
            <GaugeChart
              id="gauge-chart1"
              className="gauge"
              nrOfLevels={7}
              percent={percent}
              textColor={"#222325"}
              formatTextValue={(value) =>
                messages.toFixed(0) + " " + Views[view].abrv
              }
              needleColor={"#222325"}
              needleBaseColor={"#142a4c"}
              colors={["#142a4c", "#9ac31c", "#9ac31c"]}
              arcWidth={0.2}
              arcPadding={0.02}
            />

            <div className="gauge-legend">
              Max: {Views[view].maxMessages} {Views[view].abrv}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
