import React from 'react'
import { AiFillPlusSquare } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";

const CampanhasBoxOfIcons = (props) => {
    const { contatos, removidos, setOpenContatosForm, handleAddEtapa, setRemovidosModal } = props
    return (
        <>
            <label>
                <AiFillPlusSquare color="#9ac31c" onClick={(e) => handleAddEtapa()} style={{ cursor: "pointer" }} size={30} />Etapas</label>
            <div className="campanha-alignContainer-contatos">
                <label>
                    <AiFillPlusSquare color="#9ac31c" onClick={(e) => { setOpenContatosForm(true) }} style={{ cursor: "pointer" }} size={30} />
                    Contatos {""} :
                </label>
                <p style={{ margin: 0 }}>
                    {contatos.length === 0
                        ? " 0 "
                        : contatos.length + " contatos adicionados"}
                </p>
            </div>

            <div className="campanha-alignContainer-contatos" onClick={(e) => { setRemovidosModal(true) }} style={{ cursor: "pointer" }}>
                <label className='hover-label-effect'>
                    <BsEyeFill color="#9ac31c" size={30} style={{marginRight:'4px'}} />
                    Removidos da Campanha {""} :
                </label>
                <p style={{ margin: 0 }}>
                    {removidos?.length === 0
                        ? " 0 "
                        : removidos?.length + " contatos removidos da campanha"}
                </p>
            </div>
        </>
    )
}

export default CampanhasBoxOfIcons
