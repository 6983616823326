import React from "react";
import "./createForm.css";
import { BsCheckCircle } from "react-icons/bs";

const CreateFormButtons = () => {
  return (
    <div className="cont-buttons-bot-actions">
      <button type="submit" title="Clique para salvar" value="Salvar">
        <BsCheckCircle color="#142a4c" size={20} />
      </button>
    </div>
  );
};

export default CreateFormButtons;
