import React, { useState, useEffect } from 'react';
import ApiUsers from '../../../../ApiUsers'
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import './UserConfig.css';
import { AiFillFileAdd, AiOutlineCheckCircle } from 'react-icons/ai';
import { FaFileDownload, FaSave, FaUserAlt, FaBuilding, FaPhone } from 'react-icons/fa';
import { BiErrorCircle } from "react-icons/bi";
import { MdAlternateEmail } from "react-icons/md";
import ButtonsCrud from '../inputs/ButtonsCrud';

const normiUser = {
    email: "", // simple
    senha: "", // simple
    nome: "", // simple
    semPermissão: [], //talvez contatos com permissão
    pedidos: [], //inativo
    config: {
        firstAccess: true, //inativo
    }, //inativo
    telefone: "", //simple
    foto: "", //sem input
    status: "offline",//sem input
    ramal: "", //simple
    departamento: "", //select
    setor: "", //select
    atendente: false, //bool
    admin: false, //bool
    organização: "",
    ativo: true
}

export default function UserMany() {

    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState("");
    const [success, setSuccess] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);

    const headers = [
        'nome',
        'email',
        'departamento',
        'ramal',
    ]

    function importXLS(f) {

        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            //console.log(ws)           
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            // console.log("Data>>>"+data);
            Papa.parse(data, {
                complete: function (results) {
                    let arrays = results.data
                    let headers = arrays[0]
                    let users = arrays.slice(1)
                    arraysToUsersJSON(headers, users)
                }
            }
            )
        };
        reader.readAsBinaryString(f);
    }

    function validEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function userJsonHasValidEmails(users) {
        let hasValidEmail = true
        let errors = []
        users.forEach((user, i) => {
            let email = user.email.trim()
            if (!validEmail(email)) {
                hasValidEmail = false
                errors.push(`${i + 1}º usuário: ${email} não é um email válido  linha ${i + 2}.\n `)
            }
        })
        if (!hasValidEmail) setErrors(errors)
        return hasValidEmail
    }

    async function verify(usersJSON) {
        let deps = getDepsByUsers(usersJSON)
        if (userJsonHasValidEmails(usersJSON)) {
            await ApiUsers.post('/departamentos/verify', { deps })
                .then(resp => {
              
                    if (!resp.data) {
                        setErrors('Departamentos não encontrados')
                        setLoaded(false)
                        return
                    }
                    setUsers(usersJSON)
                    setLoaded(true)
                    setDepartamentos(resp.data)
                })
                .catch(err => {
                    setErrors(err.response.data)
                })
        }
    }

    function arraysToUsersJSON(headers, users) {
        let usersJSON = []
        console.log(headers, users)
        users.forEach(user => {
            let userJSON = {}
            headers.forEach((header, index) => {
                userJSON[header] = user[index]
            })
            usersJSON.push(userJSON)
        })
        usersJSON = removeOnlyCompleteEmpty(usersJSON)
        if (hasNoEmpty(usersJSON) && hasHeaders(usersJSON)) {
            usersJSON = usersJSON.map(user => {
                //object assign
                console.log(user.ramal)
                let userJSON = Object.assign({}, normiUser)
                userJSON.email = user.email
                userJSON.nome = user.nome
                userJSON.departamento = user.departamento
                userJSON.ramal = user.ramal
                return userJSON
            })
            verify(usersJSON)
        } else {
            findAndSetErrors(usersJSON)
        }
    }

    function findAndSetErrors(usersJSON) {
        let errors = []
        console.log(usersJSON)
        Object.entries(usersJSON).forEach(([index, user]) => {
            if (!hasHeaders(user)) {
                errors.push(`Linha ${index + 1} sem cabeçalho`)
            }
            if (!hasNoEmpty(user)) {
                errors.push(`Linha ${index + 1} sem dados corretos`)
            }
        })
        setErrors(errors)
    }

    function handleChange(e) {
        setErrors("")
        importXLS(e.target.files[0])
    }

    function removeOnlyCompleteEmpty(usersJSON) {
        return usersJSON.filter(user => {
            let isEmpty = true
            for (let key in user) {
                if (user[key] !== "") {
                    isEmpty = false
                }
            }
            return !isEmpty
        })
    }

    function hasNoEmpty(usersJSON) {
        let result = true
        usersJSON.forEach(user => {
            Object.entries(user).forEach(([key, value]) => {
                if (!value) result = false
            })
        })
        return result
    }

    function hasHeaders(usersJSON) {
        let result = true
        if (!usersJSON[0]) setErrors("Colunas Com Problemas de Formatação, por favor verifique a formatação do arquivo")
        usersJSON.forEach(user => {
            Object.entries(user).forEach(([key, value]) => {
                if (!headers.includes(key)) result = false
            })
        })
        return result
    }

    function getDepsByUsers(usersJSON) {
        let deps = []
        usersJSON.forEach(user => {
            if (!deps.includes(user.departamento)) {
                deps.push(user.departamento)
            }
        })
        return deps
    }

    function downloadExample() {
        const link = document.createElement('a');
        link.href = 'exemplo/exemplo.xlsx';
        link.download = 'exemplo/exemplo.xlsx';
        link.click();
    }

    async function espera(s) {
        return new Promise(resolve => setTimeout(resolve, s * 1000));
    }

    async function sendUsers() {
        setLoading(true)
        //insert users by dep groups
        let error = []
        for (let depIx in departamentos) {
            let dep = departamentos[depIx]
            let usersByDep = users.filter(user => user.departamento === dep.nome)
            await ApiUsers.post('/usuarios/insertManyByDep', { users: usersByDep, dep })
                .then(async function (resp) {
                    if (resp.data.error) {
                        setErrors('Erro ao inserir usuários no departamento: ' + dep.nome + resp.data.error.toString())
                        await espera(2)
                        error.push(false)
                    }
                    else {
                        setSuccess("Usuários do departamento " + dep.nome + " inseridos com sucesso")
                        await espera(1)

                    }
                }).catch(err => {
                    setErrors(err.response.data)
                })
            if (depIx === departamentos.length - 1) {
                setLoading(false)
            }
        }
        if (!error.length > 0) {
            setSuccess("Todos Usuários importados com sucesso")
            setErrors("");
        }

    }

    return (

        <div>

            <div className='users-many-container' style={{ display: 'flex', padding: "10px 0px" }}>

                <input type="file" accept=".csv,.xlsx,.xls" id="many-user-xls-btn" style={{ display: "none" }} onClick={(e) => e.target.value = ""} onChange={e => { handleChange(e) }} />

                <ButtonsCrud
                    buttons={[
                        { action: "importar", title: "Importar XLS", class: "dept-list-users-button", icon: <AiFillFileAdd className="button-icon" /> },
                        { action: "download", title: "Download Exemplo", class: "dept-add-user-button", icon: <FaFileDownload className="button-icon" /> },
                        { action: "salvar", title: "Salvar Usuários", class: "dept-edit-button", icon: <FaSave className="button-icon" /> },
                    ]}
                    handle={{
                        importar: () => document.getElementById("many-user-xls-btn").click(),
                        download: () => downloadExample(),
                        salvar: () => sendUsers(),
                    }}
                />

            </div>

            {errors &&

                <div>

                    <div className="upload-error">
                        <BiErrorCircle />
                        <span className="upload-msg">{errors}</span>
                    </div>

                </div>}

            {success &&

                <div>

                    <div className="upload-success">
                        <AiOutlineCheckCircle />
                        <span className="upload-msg"> {success} </span>
                    </div>

                </div>}

            {loading && <div>
                <h2>Carregando...</h2>
            </div>}

            {loaded &&

                <div className="upload-list-users-container">

                    <h2 className='upload-list-users-title-head'>Usuários</h2>

                    <ul className="upload-list-users"> {users.map((user, index) => {

                        let userColA = { Nome: user.nome, Email: user.email, }
                        let userColB = { Departamento: user.departamento, Ramal: user.ramal }

                        return (

                            <li key={index} className="upload-list-users-item">

                                <div className='upload-list-users-item-div'>

                                    <div className="upload-list-users-item-column-title">
                                        <h5> Usuário {index + 1}  </h5>
                                    </div>

                                    <div className="upload-list-users-item-column" >


                                        {
                                            Object.entries(userColA).map(([k, v]) => {

                                                const Icon = {
                                                    Nome: <FaUserAlt className='upload-list-users-item-icon' />,
                                                    Email: <MdAlternateEmail className='upload-list-users-item-icon' />,
                                                }

                                                return (
                                                    <span key={k} className="upload-list-users-item-span">
                                                        {Icon[k]}
                                                        <b className='upload-list-users-item-bold'>{k}: </b>
                                                        {v !== "" ? v : `Sem ${k}`}
                                                    </span>
                                                )
                                            })
                                        }

                                    </div>

                                    <div className="upload-list-users-item-column">

                                        {
                                            Object.entries(userColB).map(([k, v]) => {

                                                const Icon = {
                                                    Departamento: <FaBuilding className="upload-list-users-item-icon" />,
                                                    Ramal: <FaPhone className="upload-list-users-item-icon" />,
                                                }


                                                return (
                                                    <span key={k} className="upload-list-users-item-span">
                                                        {Icon[k]}
                                                        <b className='upload-list-users-item-bold'>{k}: </b>
                                                        {v !== "" ? v : `Sem ${k}`}
                                                    </span>
                                                )
                                            })
                                        }

                                    </div>

                                </div>

                            </li>

                        )
                    })}</ul>

                </div>}

        </div>

    );
}

