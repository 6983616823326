import React, { useContext } from 'react';
import '../Section.css';
import { Form } from "react-bootstrap";
import ButtonsCrud from '../../inputs/ButtonsCrud';
import { ImBlocked } from 'react-icons/im';
import { MdModeEdit } from 'react-icons/md';
import { IoPersonAdd } from 'react-icons/io5';
import { HiUsers } from 'react-icons/hi';
import Empty from '../../inputs/EmptyLength';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';

export default function SectionListUsers(props) {

    const { setores, loading } = props;
    const context = useContext(PrefContext);

    return <>

        <div className='section-container'>
            <div style={{ display: "flex", height: "38px", justifyContent: "center" }}>

                <Form.Control type="text" placeholder="Digite aqui para pesquisar um setor" onChange={e => { props.search(e) }} style={{ width: "70%" }} />

                <div style={{ marginTop: "-32px", textAlign: "left" }}>
                    <Form.Label >Filtrar por:</Form.Label>
                    <Form.Select onChange={e => { props.setFilter(e) }}>
                        <option value="nome">Nome</option>
                        <option value="departamento">Departamento</option>
                    </Form.Select>
                </div>

            </div>

            <br />

            {setores.length > 0 ? <>

                {setores.map((setor, index) => {

                    let departamento = context.findDepFromSet(setor._id);
                    let userCol = { Nome: setor.nome, Departamento: departamento.nome, Usuarios: setor.usuarios.length }

                    return (

                        <div key={index} className="section-card">

                            <div className="section-info">

                                {Object.entries(userCol).map(([k, v]) => {
                                    return (
                                        <label key={k}>
                                            <b>{k}: </b>
                                            {v}
                                        </label>
                                    )
                                })}

                            </div>

                            <div className="section-buttons">

                                <ButtonsCrud
                                    buttons={[
                                        { action: "listarUsuario", title: "Listar Usuários", class: "section-list-user-button", icon: <HiUsers className="button-icon" /> },
                                        { action: "adicionarUsuario", title: "Adicionar Usuário", class: "section-add-user-button", icon: <IoPersonAdd className="button-icon" /> },
                                        { action: "editar", title: "Editar Setor", class: "section-edit-button", icon: <MdModeEdit className="button-icon" /> },
                                        { action: "excluir", title: "Excluir Setor", class: "section-del-button", icon: <ImBlocked className="button-icon" /> }
                                    ]}
                                    handle={{
                                        listarUsuario: (e) => props.listUsers(setor),
                                        adicionarUsuario: (e) => props.addUsers(setor),
                                        editar: (e) => props.edit(setor),
                                        excluir: (e) => props.delConfirm(setor),
                                    }}
                                />

                            </div>

                        </div>

                    )

                })}

            </> : <>
                <Empty data="setores" />
            </>}

        </div>

    </>
}