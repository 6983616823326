import React, { useState, useContext, createContext, useCallback } from "react";
import { useEffect } from "react";
import { UserContext } from "../UserProvider";
const PrefContext = createContext();

function PrefProvider({ children }) {
  const userContext = useContext(UserContext);
  const [departamentos, setDepartamentos] = useState(
    userContext.pref.departamentos ? userContext.pref.departamentos : []
  );
  const [grupos, setGrupos] = useState(
    userContext.pref.services.voxchat.grupos
  );
  const [atendentes, setAtendentes] = useState(
    userContext.pref.services.voxbot.atendentes
  );
  const [users, setUsers] = useState(userContext.pref.users);
  const [contactWGroup, setContactWGroup] = useState(
    userContext.pref.services.voxbot.grupos
  );
  const [links, setLinks] = useState([]);
  const [setores, setSetores] = useState([]);
  const [update, setUpdate] = useState(false);
  const voxBot = userContext.pref.services.voxbot.ativo;
  const agenda = userContext.pref.services.agenda?.ativo;
  const socketIo = userContext.socket;

  useEffect(() => {
    let newLinks = [];
    if (userContext.pref.services.voxchat.links) {
      newLinks = [...userContext.pref.services.voxchat.links];
    }
    setLinks(newLinks);
  }, []);

  useEffect(() => {
    let departments = [...departamentos];
    departments.forEach((dep) => {
      dep.setores.forEach((set) => (set.depId = dep._id));
    });
    setDepartamentos(departments);
    if (update) setUpdate(false);
  }, [update]);

  useEffect(() => {
    let sectors = [];
    let newUsers = addDepAndSet();
    departamentos.forEach((dep) => {
      sectors = [...sectors, ...dep.setores];
    });
    setUsers(newUsers);
    setSetores(sectors);
  }, [departamentos]);

  useEffect(() => {
    console.log("socketOn");
    socketIo.on("new user", (user) => {
      if (user) addUser(user);
    });
    socketIo.on("update user", ({ user, oldSet, oldDep }) => {
      if (user) editUser(user, oldDep, oldSet);
    });
    socketIo.on("inactive user", (user) => {
      if (user) inactiveUser(user);
    });
    socketIo.on("active user", (user) => {
      if (user) activeUser(user);
    });

    socketIo.on("new dep", (dep) => {
      if (dep) addDep(dep);
    });
    socketIo.on("update dep", (dep) => {
      if (dep) editDep(dep);
    });
    socketIo.on("delete dep", (dep) => {
      if (dep) deleteDep(dep);
    });
    socketIo.on("add users to dep", ({ idDep, users }) => {
      if (users) addUsersToDep(idDep, users);
    });
    socketIo.on("remove users from dep", ({ idDep, users }) => {
      if (idDep && users) removeUsersFromDep(idDep, users);
    });

    socketIo.on("new set", (set) => {
      if (set) addSetor(set);
    });
    socketIo.on("update set", (set) => {
      if (set) editSetor(set);
    });
    socketIo.on("delete set", (set) => {
      if (set) deleteSetor(set);
    });
    socketIo.on("add users to set", ({ setorId, users }) => {
      if (setorId && users) addUsersToSetor(setorId, users);
    });
    socketIo.on("remove users from set", ({ setorId, users }) => {
      if (setorId && users) removeUsersFromSetor(setorId, users);
    });

    socketIo.on("new groups", (group) => {
      if (group) addGrupo(group);
    });
    socketIo.on("update groups", (group) => {
      if (group) editGrupo(group);
    });
    socketIo.on("delete groups", (group) => {
      if (group) deleteGrupo(group);
    });
    socketIo.on("add users to group", ({ groupId, users }) => {
      if (groupId && users) addUsersToGroup(groupId, users);
    });
    socketIo.on("remove users from group", ({ groupId, users }) => {
      if (groupId && users) removeUsersFromGroup(groupId, users);
    });

    socketIo.on("new att groups", (att) => {
      if (att) addAtendente(att);
    });
    socketIo.on("update att groups", (att) => {
      if (att) editAtendente(att);
    });
    socketIo.on("delete att groups", (att) => {
      if (att) deleteAtendente(att);
    });

    socketIo.on("new grupo de contato", (group) => {
      if (group) addContactWGroup(group);
    });
    socketIo.on("update grupo de contato", (group) => {
      if (group) editContactWGroup(group);
    });
    socketIo.on("delete grupo de contato", (group) => {
      if (group) deleteContactWGroup(group);
    });

    socketIo.on("new link", (link) => {
      if (link) updateLink(link);
    });

    return () => {
      socketIo.off("new user");
      socketIo.off("update user");
      socketIo.off("inactive user");
      socketIo.off("active user");

      socketIo.off("new dep");
      socketIo.off("update dep");
      socketIo.off("delete dep");
      socketIo.off("add users to dep");
      socketIo.off("remove users to dep");

      socketIo.off("new set");
      socketIo.off("update set");
      socketIo.off("delete set");
      socketIo.off("add users to set");
      socketIo.off("remove users from set");

      socketIo.off("new groups");
      socketIo.off("update groups");
      socketIo.off("delete groups");
      socketIo.off("add users to group");
      socketIo.off("remove users from group");

      socketIo.off("new att groups");
      socketIo.off("update att groups");
      socketIo.off("delete att groups");

      socketIo.off("new grupo de contato");
      socketIo.off("update grupo de contato");
      socketIo.off("delete grupo de contato");

      socketIo.off("new link");
    };
  }, [users, departamentos, grupos, atendentes, setores, contactWGroup, links]);

  useEffect(() => {
    let noDuplicates = false;
    let newGroups = grupos.map((grupo) => {
      let duplicate = [];
      grupo.usuarios = grupo.usuarios.filter((user) => {
        if (!duplicate.includes(user)) {
          duplicate.push(user);
          return true;
        }
        return false;
      });
      if (grupo.usuarios.length !== duplicate.length) noDuplicates = true;
      return grupo;
    });
    if (noDuplicates) setGrupos(newGroups);
  }, [grupos]);

  ///links

  const updateLink = useCallback(
    (link) => {
      let newLinks = [...links];
      let index = newLinks.findIndex((l) => l._id === link._id);
      if (index !== -1) {
        newLinks[index] = Object.assign(newLinks[index], link);
        setLinks(newLinks);
      } else {
        newLinks.push(link);
        setLinks(newLinks);
      }
    },
    [links]
  );

  // function updateLink(link) {
  //     let newLinks = [...links]
  //     let index = newLinks.findIndex((l) => l._id === link._id);
  //     if (index !== -1) {
  //         newLinks[index] = Object.assign(newLinks[index], link);
  //         setLinks(newLinks);
  //     }
  //     else {
  //         newLinks.push(link);
  //         setLinks(newLinks);
  //     }
  // }

  // USERS FUNCTIONS -----------------------------------------------------------------------------------

  function addUser(user) {
    user.lastMessage = "";
    let list = [...users, user];
    let departments = [...departamentos];
    let groups = [...grupos];

    departments.forEach((dep) => {
      if (dep._id === user.departamento)
        dep.usuarios = [...dep.usuarios, user._id];
      if (user.setor) {
        dep.setores.forEach((set) => {
          if (set._id === user.setor)
            set.usuarios = [...set.usuarios, user._id];
        });
      }
    });

    groups.forEach((grp) => {
      if (grp._id === user.departamento)
        grp.usuarios = [...grp.usuarios, user._id];
      if (user.setor) {
        if (grp._id === user.setor) grp.usuarios = [...grp.usuarios, user._id];
      }
    });

    setUsers(list);
    setGrupos(groups);
    setDepartamentos(departments);
  }

  function inactiveUser(user) {
    let list = [...users];
    let departments = [...departamentos];
    let groups = [...grupos];
    let attendances = [...atendentes];

    list.forEach((usr) => {
      if (usr._id === user._id) usr = Object.assign(usr, user);
    });
    departments.forEach((dep) => {
      if (dep._id === user.departamento) {
        dep.usuarios = dep.usuarios.filter((uid) => uid !== user._id);
        if (user.setor) {
          dep.setores.forEach((set) => {
            if (set._id === user.setor)
              set.usuarios = set.usuarios.filter((uid) => uid !== user._id);
          });
        }
      }
    });
    groups.forEach((grp) => {
      if (grp.usuarios.some((uid) => uid === user._id)) {
        grp.usuarios = grp.usuarios.filter((uid) => uid !== user._id);
        grp.master = grp.master.filter((uid) => uid !== user._id);
      }
    });
    attendances.forEach((att) => {
      if (att.usuarios.some((uid) => uid === user._id)) {
        att.usuarios = att.usuarios.filter((uid) => uid !== user._id);
      }
    });

    setUsers(list);
    setDepartamentos(departments);
    setGrupos(groups);
    setAtendentes(attendances);
  }

  function activeUser(user) {
    let list = [...users];
    let departments = [...departamentos];
    let groups = [...grupos];

    list.forEach((usr) => {
      if (usr._id === user._id) usr = Object.assign(usr, user);
    });
    departments.forEach((dep) => {
      if (dep._id === user.departamento) {
        dep.usuarios = [...dep.usuarios, user._id];
        if (user.setor) {
          dep.setores.forEach((set) => {
            if (set._id === user.setor) {
              set.usuarios = [...set.usuarios, user._id];
            }
          });
        }
      }
    });
    groups.forEach((grp) => {
      if (grp._id === user.departamento) {
        grp.usuarios = [...grp.usuarios, user._id];
      }
      if (grp._id === user.setor && user.setor) {
        grp.usuarios = [...grp.usuarios, user._id];
      }
    });

    setUsers(list);
    setDepartamentos(departments);
    setGrupos(groups);
  }

  function editUser(user, oldDep, oldSet) {
    let list = [...users];
    let changed = {
      dep: oldDep !== user.departamento,
      set: oldSet !== user.setor,
    };

    list.forEach((usr) => {
      if (usr._id === user._id) usr = Object.assign(usr, user);
    });

    if (changed.dep || changed.set) {
      let departments = [...departamentos];
      let groups = [...grupos];

      if (changed.dep) {
        departments.forEach((dep) => {
          if (dep._id === oldDep)
            dep.usuarios = dep.usuarios.filter((uid) => uid !== user._id);
          if (dep._id === user.departamento)
            dep.usuarios = [...dep.usuarios, user._id];
        });
        groups.forEach((grp) => {
          if (grp._id === oldDep)
            grp.usuarios = grp.usuarios.filter((uid) => uid !== user._id);
          if (grp._id === user.departamento)
            grp.usuarios = [...grp.usuarios, user._id];
        });
      }

      if (changed.set) {
        departments.forEach((dep) => {
          if (dep._id === oldDep) {
            if (oldSet) {
              dep.setores.forEach((set) => {
                if (set._id === oldSet)
                  set.usuarios = set.usuarios.filter((uid) => uid !== user._id);
              });
            }
          }
          if (dep._id === user.departamento) {
            if (user.setor) {
              dep.setores.forEach((set) => {
                if (set._id === user.setor)
                  set.usuarios = [...set.usuarios, user._id];
              });
            }
          }
        });

        groups.forEach((grp) => {
          if (oldSet) {
            if (grp._id === oldSet)
              grp.usuarios = grp.usuarios.filter((uid) => uid !== user._id);
          }
          if (user.setor) {
            if (grp._id === user.setor) {
              grp.usuarios = !grp.usuarios.includes(user._id)
                ? [...grp.usuarios, user._id]
                : grp.usuarios;
            }
          }
        });
      }

      setDepartamentos(departments);
      setGrupos(groups);
    }
    setUsers(list);
  }

  // DEPARTAMENTOS FUNCTIONS ============================================================

  function addDep(department) {
    let list = [...departamentos, department];
    let newGroup = {
      _id: department._id,
      nome: department.nome,
      usuarios: [],
      master: [],
      sala_de_conferencia: department.sala_de_conferencia,
      lastMessage: "",
    };
    let groups = [...grupos, newGroup];
    setDepartamentos(list);
    setGrupos(groups);
  }

  function deleteDep(department) {
    let list = departamentos.filter((dep) => dep._id !== department._id);
    let groups = grupos.filter(
      (grp) =>
        grp._id !== department._id &&
        !department.setores.some((set) => set._id === grp._id)
    );
    let usersList = [...users];

    usersList.forEach((user) => {
      if (user.departamento === department._id) {
        user.departamento = "";
        user.setor = "";
      }
    });

    setUsers(usersList);
    setDepartamentos(list);
    setGrupos(groups);
  }

  function editDep(department) {
    let list = [...departamentos];
    let groups = [...grupos];

    list.forEach((dep) => {
      if (dep._id === department._id) dep = Object.assign(dep, department);
    });
    groups.forEach((grp) => {
      if (grp._id === department._id) {
        grp.nome = department.nome;
        grp.sala_de_conferencia = department.sala_de_conferencia;
      }
    });

    setDepartamentos(list);
    setGrupos(groups);
  }

  function addUsersToDep(depId, newUsers) {
    let list = [...departamentos];
    let groups = [...grupos];
    let listUsers = [...users];

    list.forEach((dep) => {
      if (dep._id === depId) {
        dep.usuarios = dep.usuarios.some((uid) => newUsers.includes(uid))
          ? [...dep.usuarios]
          : [...dep.usuarios, ...newUsers];
      }
    });
    groups.forEach((grp) => {
      if (grp._id === depId) {
        grp.usuarios = grp.usuarios.some((uid) => newUsers.includes(uid))
          ? [...grp.usuarios]
          : [...grp.usuarios, ...newUsers];
      }
    });
    listUsers.forEach((user) => {
      if (newUsers.some((uid) => uid === user._id)) {
        user.departamento = depId;
      }
    });

    setUsers(listUsers);
    setGrupos(groups);
    setDepartamentos(list);
  }

  function removeUsersFromDep(depId, oldUsers) {
    let list = [...departamentos];
    let department = list.find((dep) => dep._id === depId);
    let groups = [...grupos];
    let listUsers = [...users];

    list.forEach((dep) => {
      if (dep._id === depId) {
        dep.usuarios = dep.usuarios.filter((uid) => !oldUsers.includes(uid));

        dep.setores.forEach((set) => {
          set.usuarios = set.usuarios.filter((uid) => !oldUsers.includes(uid));
        });
      }
    });
    groups.forEach((grp) => {
      if (grp._id === depId) {
        grp.usuarios = grp.usuarios.filter((uid) => !oldUsers.includes(uid));
      }
      if (department.setores.some((set) => set._id === grp._id)) {
        grp.usuarios = grp.usuarios.filter((uid) => !oldUsers.includes(uid));
      }
    });
    listUsers.forEach((user) => {
      if (oldUsers.some((uid) => uid === user._id)) {
        user.departamento = "";
        user.setor = "";
      }
    });

    setDepartamentos(list);
    setGrupos(groups);
    setUsers(listUsers);
  }

  // SETORES FUNCTIONS ============================================================

  function addSetor(setor) {
    let list = [...departamentos];
    let newGroup = {
      _id: setor._id,
      nome: setor.nome,
      usuarios: [],
      master: [],
      sala_de_conferencia: setor.sala_de_conferencia,
      lastMessage: "",
    };
    let groups = [...grupos, newGroup];

    list.forEach((dep) => {
      if (dep._id === setor.depId) dep.setores = [...dep.setores, setor];
    });

    setDepartamentos(list);
    setGrupos(groups);
  }

  function deleteSetor(setor) {
    let list = [...departamentos];
    let listUsers = [...users];
    let groups = grupos.filter((grp) => grp._id !== setor._id);

    list.forEach((dep) => {
      if (dep._id === setor.depId)
        dep.setores = dep.setores.filter((set) => set._id !== setor._id);
    });
    listUsers.forEach((user) => {
      if (user.setor === setor._id) user.setor = "";
    });

    setDepartamentos(list);
    setGrupos(groups);
    setUsers(listUsers);
  }

  function editSetor(setor) {
    let list = [...departamentos];
    let groups = [...grupos];
    let listUsers = [...users];
    let changedDep = setor.depId !== setor.departamento;

    list.forEach((dep) => {
      if (changedDep) {
        if (dep._id === setor.depId) {
          dep.setores = dep.setores.filter((set) => set._id !== setor._id);
          dep.usuarios = dep.usuarios.filter(
            (uid) => !setor.usuarios.includes(uid)
          );
        }
        if (dep._id === setor.departamento) {
          dep.setores = [...dep.setores, setor];
          dep.usuarios = [...dep.usuarios, ...setor.usuarios];
        }
      } else {
        if (dep._id === setor.departamento) {
          dep.setores.forEach((set) => {
            if (set._id === setor._id) set = Object.assign(set, setor);
          });
        }
      }
    });
    groups.forEach((grp) => {
      if (changedDep) {
        if (grp._id === setor.depId) {
          grp.usuarios = grp.usuarios.filter(
            (uid) => !setor.usuarios.includes(uid)
          );
        }
        if (grp._id === setor.departamento) {
          grp.usuarios = [...grp.usuarios, ...setor.usuarios];
        }
      }
      if (grp._id === setor._id) {
        grp.nome = setor.nome;
        grp.sala_de_conferencia = setor.sala_de_conferencia;
      }
    });
    listUsers.forEach((user) => {
      if (setor.usuarios.some((uid) => uid === user._id)) {
        user.setor = setor._id;
        user.departamento = setor.departamento;
      }
    });

    setUsers(listUsers);
    setGrupos(groups);
    setDepartamentos(list);
    setUpdate(true);
  }

  function addUsersToSetor(setId, newUsers) {
    let list = [...departamentos];
    let groups = [...grupos];
    let listUsers = [...users];

    list.forEach((dep) => {
      dep.setores.forEach((set) => {
        if (set._id === setId) {
          set.usuarios = set.usuarios.some((uid) => newUsers.includes(uid))
            ? [...set.usuarios]
            : [...set.usuarios, ...newUsers];
        }
      });
    });
    groups.forEach((grp) => {
      if (grp._id === setId) {
        grp.usuarios = grp.usuarios.some((uid) => newUsers.includes(uid))
          ? [...grp.usuarios]
          : [...grp.usuarios, ...newUsers];
      }
    });
    listUsers.forEach((user) => {
      if (newUsers.some((uid) => uid === user._id)) {
        user.setor = setId;
      }
    });

    setUsers(listUsers);
    setGrupos(groups);
    setDepartamentos(list);
  }

  function removeUsersFromSetor(setId, oldUsers) {
    let list = [...departamentos];
    let groups = [...grupos];
    let listUsers = [...users];

    list.forEach((dep) => {
      dep.setores.forEach((set) => {
        if (set._id === setId)
          set.usuarios = set.usuarios.filter((uid) => !oldUsers.includes(uid));
      });
    });
    groups.forEach((grp) => {
      if (grp._id === setId) {
        grp.usuarios = grp.usuarios.filter((uid) => !oldUsers.includes(uid));
      }
    });
    listUsers.forEach((user) => {
      if (oldUsers.some((uid) => uid === user._id)) {
        user.setor = "";
      }
    });

    setGrupos(groups);
    setDepartamentos(list);
    setUsers(listUsers);
  }

  // GRUPOS FUNCTIONS ============================================================

  function addGrupo(grupo) {
    grupo.lastMessage = "";
    let list = [...grupos, grupo];
    setGrupos(list);
  }

  function deleteGrupo(grupo) {
    let list = grupos.filter((group) => group._id !== grupo._id);
    setGrupos(list);
  }

  function editGrupo(grupo) {
    let list = [...grupos];
    list.forEach((group) => {
      if (group._id === grupo._id) group = Object.assign(group, grupo);
    });
    setGrupos(list);
  }

  function addUsersToGroup(grupoId, newUsers) {
    let list = [...grupos];

    list.forEach((group) => {
      if (group._id === grupoId) {
        newUsers.forEach((uid) => {
          group.usuarios = group.usuarios.includes(uid)
            ? group.usuarios
            : [...group.usuarios, uid];
        });
      }
    });

    setGrupos(list);
  }

  function removeUsersFromGroup(grupoId, oldUsers) {
    let list = [...grupos];
    list.forEach((group) => {
      if (group._id === grupoId) {
        group.usuarios = group.usuarios.filter(
          (uid) => !oldUsers.includes(uid)
        );
      }
    });
    setGrupos(list);
  }

  // ATENDENTES FUNCTIONS ============================================================

  function addAtendente(atendente) {
    let list = [...atendentes, atendente];
    setAtendentes(list);
  }

  function deleteAtendente(atendente) {
    let list = atendentes.filter((att) => att._id !== atendente._id);
    setAtendentes(list);
  }

  function editAtendente(atendente) {
    let list = [...atendentes];
    list.forEach((att) => {
      if (att._id === atendente._id) att = Object.assign(att, atendente);
    });
    setAtendentes(list);
  }

  // OTHER FUNCTIONS ============================================================

  function addDepAndSet() {
    let newUsers = [...users];
    newUsers.forEach((user) => {
      let dep = departamentos.find((dep) => dep._id === user.departamento);
      let set = dep?.setores?.find((set) => set._id === user.setor);
      user.departamentoNome = dep ? dep.nome : "";
      user.setorNome = set ? set.nome : "";
    });
    return newUsers;
  }

  function findDepFromSet(setorId) {
    let department = {};
    departamentos.forEach((dep) => {
      if (dep.setores.some((set) => set._id === setorId)) department = dep;
    });
    return department;
  }

  ////GRUPO DE CONTATOS
  function addContactWGroup(group) {
    let list = [...contactWGroup, group];
    setContactWGroup(list);
  }

  function deleteContactWGroup(group) {
    let list = contactWGroup.filter((grp) => grp._id !== group._id);
    setContactWGroup(list);
  }

  function editContactWGroup(group) {
    let list = [...contactWGroup];
    list.forEach((grp) => {
      if (grp._id === group._id) grp = Object.assign(grp, group);
    });
    setContactWGroup(list);
  }

  return (
    <PrefContext.Provider
      value={{
        departamentos,
        grupos,
        atendentes,
        users,
        setores,
        voxBot,
        findDepFromSet,
        setDepartamentos,
        setGrupos,
        setAtendentes,
        setUsers,
        contactWGroup,
        agenda,
        links,
        setLinks
      }}
    >
      {children}
    </PrefContext.Provider>
  );
}

export { PrefProvider, PrefContext };
