import React, { useState, useEffect, useContext } from "react";
import { AttendanceContext } from "../../../contexts/ChatBotProvider/AttendanceProvider";
import { PrefContext } from "../../../contexts/ChatBotProvider/PrefProvider";
import Table from "./Table/Table";
import GrupoIndex from "./grupos/GrupoIndex";
import ModalEdit from "./ModalEdit/ModalEdit";
import HeaderContact from "./HeaderContact/headerContact";
import Alert from "../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../../Configurações/OptionsScreen/inputs/DeleteAlert";
import "./contatos.css";

export default function Contatos() {
  const context = useContext(AttendanceContext);
  const prefContext = useContext(PrefContext);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [contacts, setContacts] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [contactsOnShow, setContactsOnShow] = useState([]);
  const [gruposOnShow, setGruposOnShow] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [view, setView] = useState("contatos");

  const alertType = {
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  useEffect(() => {
    if (context.attendances) {
      let newContacts = [...context.attendances];
      setContacts(newContacts);
      setContactsOnShow(newContacts);
    }
  }, [context.attendances]);

  useEffect(() => {
    if (prefContext.contactWGroup) {
      setGrupos(prefContext.contactWGroup);
    }
  }, []);

  const selectContact = (contact) => {
    let newContact = JSON.parse(JSON.stringify(contact));
    delete newContact.allMessages;
    delete newContact.historico;
    delete newContact.unseen;
    setSelectedContact(newContact);
  };

  const components = {
    contatos: (
      <Table
        contacts={contactsOnShow}
        selectContact={selectContact}
        createAlert={createAlert}
        quitAlert={quitAlert}
      />
    ),
    grupos: (
      <GrupoIndex
        grupos={gruposOnShow}
        selectContact={selectContact}
        setGrupos={setGrupos}
        contacts={contactsOnShow}
      />
    ),
  };

  return (
    <div>
      <div className="container-header-contacts">
        <p>Contatos</p>
        <HeaderContact
          view={view}
          setView={setView}
          createAlert={createAlert}
          contacts={contacts}
          setContacts={setContacts}
          grupos={grupos}
          setGruposOnShow={setGruposOnShow}
          setContactsOnShow={setContactsOnShow}
        />
      </div>
      <div className={view + "-container"}>
        {components[view]}
        {alert.visible && alertType[alert.type]}
        {selectedContact ? (
          <ModalEdit
            contact={selectedContact}
            setContact={setSelectedContact}
            createAlert={createAlert}
            close={() => setSelectedContact(false)}
          />
        ) : null}
      </div>
    </div>
  );
}
