import React from "react";
import "./styles.css";

export default function DatePickerModal({ date, onChange }) {
  return (
    <div className="date-panel">
      <div className="date">
        <div className="date-title">{"Inicio:"}&nbsp; &nbsp;</div>
        <div className="date-input">
          <input
            type="date"
            value={date.start}
            onChange={(e) => {
              onChange("start", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="date">
        <div className="date-title">{"Fim:"}&nbsp; &nbsp;</div>
        <div className="date-input">
          <input
            type="date"
            value={date.end}
            onChange={(e) => onChange("end", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
