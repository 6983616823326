import { useContext } from "react";
import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { QSGAgendaContext } from "../../../../../../contexts/QSGProvider/QSGAgendaProvider";

export const BotoesTroca = ({ profissionaisSelecionados, setProfissionaisSelecionados, usuariosSelecionados, setUsuariosSelecionados }) => {
  const { usuarios, profissionais, setUsuarios } = useContext(QSGAgendaContext);

  const unsetUsersProfissionais = () => {
    const novosUsuarios = usuarios.map((user) => {
      const updatedUser = profissionaisSelecionados.some((selectedUser) => selectedUser._id === user._id) ? { ...user, profissional: false } : user;
      return updatedUser;
    });
    setProfissionaisSelecionados([]);
    setUsuariosSelecionados([]);
    setUsuarios(novosUsuarios);
  };

  const unsetAllUsersProfissionais = () => {
    const novosUsuarios = usuarios.map((user) => {
      const updatedUser = { ...user, profissional: false };
      return updatedUser;
    });
    setProfissionaisSelecionados([]);
    setUsuariosSelecionados([]);
    setUsuarios(novosUsuarios);
  };

  const setUsersProfissionais = () => {
    const novosUsuarios = usuarios.map((user) => {
      const updatedUser = usuariosSelecionados.some((selectedUser) => selectedUser._id === user._id) ? { ...user, profissional: true } : user;
      return updatedUser;
    });
    setProfissionaisSelecionados([]);
    setUsuariosSelecionados([]);
    setUsuarios(novosUsuarios);
  };

  const setAllUsersProfissionais = () => {
    const novosUsuarios = usuarios.map((user) => {
      const updatedUser = { ...user, profissional: true };
      return updatedUser;
    });
    setProfissionaisSelecionados([]);
    setUsuariosSelecionados([]);
    setUsuarios(novosUsuarios);
  };

  return (
    <div className="qsg-agenda-profissionais-buttons-container">
      <button
        onClick={usuarios.length > 0 ? () => setAllUsersProfissionais() : () => null}
        className={usuarios.length > 0 ? "qsg-agenda-profissionais-swap-button" : "qsg-agenda-profissionais-swap-button swap-button-disabled"}>
        <BsChevronDoubleRight title="Adicionar todos os usuários como profissionais" />
      </button>
      <button
        onClick={
          usuariosSelecionados.length > 0
            ? () => {
                setUsersProfissionais();
              }
            : () => null
        }
        className={usuariosSelecionados.length > 0 ? "qsg-agenda-profissionais-swap-button" : "qsg-agenda-profissionais-swap-button swap-button-disabled"}>
        <BsChevronRight title="Adicionar os usuários selecionados como profissionais" />
      </button>
      <button
        onClick={
          profissionaisSelecionados.length > 0
            ? () => {
                unsetUsersProfissionais();
              }
            : () => null
        }
        className={profissionaisSelecionados.length > 0 ? "qsg-agenda-profissionais-swap-button" : "qsg-agenda-profissionais-swap-button swap-button-disabled"}>
        <BsChevronLeft title="Remover os usuários selecionados como profissionais" />
      </button>
      <button
        onClick={profissionais.length > 0 ? () => unsetAllUsersProfissionais() : () => null}
        className={profissionais.length > 0 ? "qsg-agenda-profissionais-swap-button" : "qsg-agenda-profissionais-swap-button swap-button-disabled"}>
        <BsChevronDoubleLeft title="Remover todos os usuários como profissionais" />
      </button>
    </div>
  );
};
