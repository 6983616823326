import React, { useState, useEffect, useContext } from "react";
import { BsCheck2All, BsCheck2 } from "react-icons/bs";
import { BiTransferAlt, BiSearch } from "react-icons/bi";
import ContactInfoNew from "./contactInfoNew";
import ContactBoxIcons from "./contactInfoNew/contactBoxIcons";
import AsideTransfer from "./attendances/AsideTransfer";
import ApiUsers from "../../../ApiUsers";
import Alert from "../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../../Configurações/OptionsScreen/inputs/DeleteAlert";
import { BiMobileAlt, BiPhoneOutgoing, BiMailSend } from "react-icons/bi";
import { MessageContext } from "../../../contexts/ChatBotProvider/MessageProvider";

const MessageTitle = (props) => {
  const mContext = useContext(MessageContext);

  const { onSearch, setOnSearch } = mContext;

  const [state, setState] = useState({
    contact: {},
    isTyping: false,
    showContact: false,
    showAside: false,
    alert: {
      visible: false,
      type: "",
      props: {
        title: "",
        placeholder: "",
      },
      action: () => {},
      title: "",
    },
    loading: false,
  });

  const titleBtn = {
    private: {
      btns: [
        //search message icons
        {
          icon: <BiSearch className="contact-title-icons" size={30} />,
          title: "Pesquisar Mensagens",
          action: "search",
        },
        {
          icon: <BiPhoneOutgoing className="contact-title-icons" size={30} />,
          title: "Ligar",
          action: "call",
        },
        {
          icon: <BiMobileAlt className="contact-title-icons" size={30} />,
          title: "Celular",
          action: "cellphone",
        },
        {
          icon: <BiMailSend className="contact-title-icons" size={30} />,
          title: "Email",
          action: "sendmail",
        },
      ],

      handles: {
        call: () => call(state.contact.ramal),
        cellphone: () => call(state.contact.telefone),
        sendmail: () => sendMail(),
        search: () => {
          setOnSearch(JSON.parse(JSON.stringify(state.contact.allMessages)));
        },
      },
    },
    att: {
      btns: {
        atendimentos: [
          {
            action: "search",
            title: "Pesquisar Mensagens",
            icon: <BiSearch className="contact-title-icons" size={30} />,
          },
          {
            action: "call",
            title: "Liga para essa pessoa",
            icon: <BiMobileAlt className="contact-title-icons" size={30} />,
          },
          {
            action: "finishattendance",
            title: "Finalizar Atendimento",
            icon: <BsCheck2All className="contact-title-icons" size={30} />,
          },
          {
            action: "transferattendance",
            title: "Transferir Atendimento",
            icon: <BiTransferAlt className="contact-title-icons" size={30} />,
          },
        ],
        espera: [
          {
            action: "takeattendance",
            title: "Aceitar Atendimento",
            icon: <BsCheck2 className="contact-title-icons" />,
          },
          {
            action: "transferattendance",
            title: "Transferir Atendimento",
            icon: <BiTransferAlt className="contact-title-icons" />,
          },
        ],
        historico: [
          // { action: "conference", title: "Sala de Conferência", icon: <FcCallTransfer className='contact-title-icons' /> }
        ],
        potenciais: [
          {
            action: "takeattendance",
            title: "Recuperar Atendimento",
            icon: <BsCheck2 className="contact-title-icons" />,
          },
          {
            action: "transferattendance",
            title: "Transferir Atendimento",
            icon: <BiTransferAlt className="contact-title-icons" />,
          },
        ],
      },

      handles: {
        call: () => call(state.contact.telefone),
        transferattendance: () =>
          setState((prevState) => ({
            ...prevState,
            showAside: !state.showAside,
          })),
        takeattendance: () => (state.loading ? null : pickUpAttendance()),
        finishattendance: () =>
          state.loading ? null : handleFinishAttendance(),
        search: () =>
          setOnSearch(JSON.parse(JSON.stringify(state.contact.allMessages))),
      },
    },
    group: {
      btns: [
        {
          action: "search",
          title: "Pesquisar Mensagens",
          icon: <BiSearch className="contact-title-icons" size={30} />,
        },
        {
          action: "conference",
          title: "Sala de Conferência",
          icon: <BiPhoneOutgoing className="contact-title-icons" size={30} />,
        },
      ],
      handles: {
        conference: () => conference(),
        search: () =>
          setOnSearch(JSON.parse(JSON.stringify(state.contact.allMessages))),
      },
    },
    'whats-group': {
      btns: [
        {
          action: "search",
          title: "Pesquisar Mensagens",
          icon: <BiSearch className="contact-title-icons" size={30} />,
        },
      ],
      handles: {
        search: () =>
          setOnSearch(JSON.parse(JSON.stringify(state.contact.allMessages))),
      },
    },
  };

  const sendMail = () => {};

  useEffect(() => {
    // componentDidMount
    // selectContact(props.contact);

    if (props.msgType === "att") return;
    if(props.msgType === "whats-group") return;

    props.socket.on("typing", (data) => {
      if (data.from === props.contact._id) {
        setState((prevState) => ({ ...prevState, isTyping: true }));

        setTimeout(() => {
          setState((prevState) => ({ ...prevState, isTyping: false }));
        }, 4000);
      }
    });

    props.socket.on("status", (data) => {
      if (state.contact && data.user === state.contact._id) {
        let newContact = { ...state.contact };
        newContact.status = data.status;
        setState((prevState) => ({ ...prevState, contact: newContact }));
      }
    });
    // componentWillUnmount
    return () => {
      if (props.msgType === "att") return;
      if(props.msgType === "whats-group") return;
      props.socket.off("typing");
      props.socket.off("status");
    };
  }, [props]);

  useEffect(() => {
    // componentDidUpdate
    if (props.contact !== state.contact) {
      selectContact(props.contact);
      setState((prevState) => ({ ...prevState, showAside: false }));
    }
    if (props.navType !== state.navType) {
      setState((prevState) => ({ ...prevState, showAside: false }));
    }
    setOnSearch(false);
  }, [props.contact, props.navType]);

  const selectContact = (contact) => {
    if (contact) {
      setState((prevState) => ({ ...prevState, contact }));
    }
  };

  const pickUpAttendance = () => {
    if (!state.contact.atendente) {
      let history = state.contact.historico;
      let newContact = JSON.parse(JSON.stringify(state.contact));
      delete newContact.allMessages;
      newContact.historico = history;
      setState((prevState) => ({ ...prevState, loading: true }));
      ApiUsers.post("/atendimentos/pickup", { contact: newContact })
        .then((res) => {
          createAlert("normal", {
            title: "Sucesso!",
            placeholder:
              "O atendimento foi transferido com sucesso para os seus atendimentos.",
          });
          setState((prevState) => ({ ...prevState, loading: false }));
          props.cleanContact();
        })
        .catch((err) => console.log(err));
    } else {
      createAlert("normal", {
        title: "Ops!",
        placeholder: "Este contato já se encontra em atendimento!",
      });
    }
  };

  const handleFinishAttendance = () => {
    createAlert("delete", {
      data: "Finalizar",
      nome: state.contact.telefone,
      action: () => finishAttendance(),
    });
  };

  const finishAttendance = () => {
    let newContact = JSON.parse(JSON.stringify(state.contact));
    delete newContact.allMessages;
    setState((prevState) => ({ ...prevState, loading: true }));
    ApiUsers.post("/atendimentos/finish", { contact: newContact }).then(
      (res) => {
        createAlert("normal", {
          title: "Sucesso!",
          placeholder: "Atendimento finalizado com sucesso!",
        });
        setState((prevState) => ({ ...prevState, loading: false }));
        props.cleanContact();
      }
    );
  };

  const call = (phone) => {
    phone = phone.replace(/[^\d]/g, "");
    phone = removeFirst55(phone);
    if (phone.length >= 7) phone = 0 + phone;
    window.open("tel:" + phone);
  };

  const removeFirst55 = (phone) => {
    if (phone.length === 13) {
      phone = phone.substring(3);
    }
    return phone;
  };

  const conference = () => {
    let sala = state.contact.sala_de_conferencia;
    if (sala) {
      call(sala);
    }
  };

  const handleShowContact = () => {
    let newState = { ...state };
    newState.showContact = !state.showContact;
    setState(newState);
  };

  const quitAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: { visible: false, type: "", props: {} },
    }));
  };

  const createAlert = (type, props) => {
    setState((prevState) => ({
      ...prevState,
      alert: { visible: true, type, props: { ...props }, action: props.action },
    }));
  };

  const alertType = {
    normal: (
      <Alert
        title={state.alert.props.title}
        placeholder={state.alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data="Finalizar"
        nome={state.contact?.telefone}
        loading={state.loading}
        action={() => state.alert.action()}
        quit={() => quitAlert()}
      />
    ),
  };

  return (
    <div>
      {state.alert.visible && alertType[state.alert.type]}

      <div className="title-contact-chat">
        {state.showAside && state.contact && (
          <>
            <AsideTransfer
              back={() =>
                setState((prevState) => ({
                  ...prevState,
                  showAside: !state.showAside,
                }))
              }
              contact={state.contact}
              cleanContact={() => props.cleanContact()}
            />
          </>
        )}

        {state.contact ? (
          <>
            <div className="title-contact">
              <ContactInfoNew
                showContact={state.showContact}
                setShowContact={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showContact: false,
                  }))
                }
                contact={state.contact}
                msgType={props.msgType}
                click={() => handleShowContact()}
              />

              {state.isTyping ? (
                <div className="message-title-right-typing">
                  Digitando
                  <img
                    style={{
                      marginLeft: 4,
                      width: 30,
                      height: 20,
                      padding: "2px",
                      border: " 1px solid #9ac31c",
                    }}
                    src="./imgs/typing.gif"
                    alt="typing"
                  />
                </div>
              ) : null}
              <div className="contact-title-icon-box">
                <ContactBoxIcons
                  buttons={
                    props.msgType === "att"
                      ? titleBtn[props.msgType].btns[props.navType]
                      : titleBtn[props.msgType].btns
                  }
                  handle={titleBtn[props.msgType].handles}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MessageTitle;
