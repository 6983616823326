import React from "react";
import "./banner.css";

export default function Banner() {
  return (
    <div className="container-banner">
      <div className="container-banner-align">
        <div className="container-banner-img">
          <a
            href="https://www.voxcity.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-href"
          >
            <img
              className="banner-img-vox"
              src="/imgs/voxcidade.png"
              alt="voxcidade"
            />
          </a>
        </div>

        <div className="container-banner-text">
          <div className="container-icon-title">
            <img
              className="banner-icon"
              src="/imgs/love.png"
              alt="voxcity-icon"
            />

            <p className="banner-text-title">Inovar é nossa essência!</p>
          </div>
          <div className="social-icons">
            <a
              href="https://www.instagram.com/voxcity_?igshid=YTQwZjQ0NmI0OA%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
              className="social-href"
            >
              <SocialIcon imgSrc="/imgs/insta.png" text="@voxcity" />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B554834782300&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              className="social-href"
            >
              <SocialIcon imgSrc="/imgs/wpp.png" text=" +55 48 3478-2300" />
            </a>
            <a
              href="mailto:suporte@voxcity.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-href"
            >
              <SocialIcon imgSrc="/imgs/email.png" text="suporte@voxcity.com" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function SocialIcon({ imgSrc, text }) {
  return (
    <div className="social-icon">
      <img className="icon-rede" src={imgSrc} alt="voxcity-icon" />
      <p className="social-icon-text">{text}</p>
    </div>
  );
}
