import React, { useState, useRef, useEffect } from 'react';
import { BsX, BsFileEarmarkPlus } from "react-icons/bs";

const File = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (props.data?.files?.length > 0) {
      setSelectedFiles(props.data.files);
    }
  }, []);

  useEffect(() => {
    if(props.editData?.files?.length > 0){
      let newFiles = props.editData.files
      newFiles?.forEach((file) => {
        if(file === null) return
        if(file.url){
          file.updated = true
        }
      })
      setSelectedFiles(props.editData.files)
    }
  }, []);

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    // Concatenando os arquivos selecionados com os arquivos existentes
    const updatedFiles = [...selectedFiles, ...filesArray];
    setSelectedFiles(updatedFiles);
    if(props.editData) props.setEditData({ ...props.editData, files: updatedFiles });
    else props.setData({ ...props.data, files: updatedFiles });
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const updatedFiles = [...selectedFiles, ...droppedFiles];
    setSelectedFiles(updatedFiles);
    if(props.editData) props.setEditData({ ...props.editData, files: updatedFiles });
    else props.setData({ ...props.data, files: updatedFiles });
  };
  
  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(updatedFiles);
    if(props.editData) props.setEditData({ ...props.editData, files: updatedFiles });
    else props.setData({ ...props.data, files: updatedFiles });
  };

  return (
    <div>
      {selectedFiles.length > 0 ? (
        <div style={{ padding: '10px', border: '1px solid #ccc', marginBottom: '10px' }}>
          <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Arquivos Selecionados:</p>
          {selectedFiles.map((file, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ margin: 0 }}>{file.name}</p>
              <BsX color={'#9ac31c'} onClick={() => handleRemoveFile(index)} size={20} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </div>
          ))}
        </div>
      ) : null}

      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
        className='file-drop-area'
      >
        <span>Arraste e solte o arquivo aqui, ou</span>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
          multiple
        />
        <div onClick={() => fileInputRef.current.click()}>
          <BsFileEarmarkPlus color={'#9ac31c'} size={30} />
          <span>Clique para selecionar arquivos</span>
        </div>
      </div>
    </div>
  );
};

export default File;
