import React, { useState } from "react";
import ApiUsers from "../../../../../ApiUsers";
import MiniLoading from "../../inputs/MiniLoading";
import "../Departament.css";

export default function EditDepartment(props) {
  const [departamento, setDepartamento] = useState(
    structuredClone(props.departamento)
  );
  const [loading, setLoading] = useState(false);

  function changeName(e) {
    let newDep = { ...departamento };
    newDep.nome = e.target.value;
    setDepartamento(newDep);
  }

  function changeConfRoom(e) {
    let newDep = { ...departamento };
    newDep.sala_de_conferencia = e.target.value;
    setDepartamento(newDep);
  }

  function editDepartment(e) {
    e.preventDefault();
    setLoading(true);
    if (!hasEmptyFields()) {
      if (hasModified()) {
        ApiUsers.put(`/departamentos/${departamento._id}`, { departamento })
          .then((res) => {
            setLoading(false);
            props.alert("normal", {
              title: "Sucesso!",
              placeholder: "Departamento alterado com sucesso!",
            });
            props.goBack();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setLoading(false);
        props.alert("normal", {
          title: "Ops!",
          placeholder: "Por favor, realize alterações nos dados para salvar!",
        });
      }
    } else {
      setLoading(false);
      props.alert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencher os campos vazios!",
      });
    }
  }

  function hasEmptyFields() {
    let empty = false;
    if (
      departamento.nome.trim() === "" ||
      departamento.sala_de_conferencia.trim() === ""
    )
      empty = true;
    return empty;
  }

  function hasModified() {
    let modified = false;
    if (
      departamento.nome.trim() !== props.departamento.nome ||
      departamento.sala_de_conferencia.trim() !==
        props.departamento.sala_de_conferencia
    )
      modified = true;
    return modified;
  }

  return (
    <>
      <div className="dept-edit-container">
        <h2 style={{ textAlign: "center", margin: "20px 0px" }}>
          {" "}
          Editar Departamento{" "}
        </h2>

        <form className="dept-edit-form">
          <label className="dept-edit-label">
            <span className="dept-edit-span"> Nome: </span>
            <input
              className="dept-edit-input"
              type="text"
              value={departamento.nome}
              onChange={(e) => changeName(e)}
              placeholder="Digite aqui o nome do departamento"
            />
          </label>

          <label className="dept-edit-label">
            <span className="dept-edit-span"> Sala de conferência: </span>
            <input
              className="dept-edit-input"
              type="text"
              value={departamento.sala_de_conferencia}
              onChange={(e) => changeConfRoom(e)}
              placeholder="Digite aqui a sala de conferência"
            />
          </label>

          <div className="dept-edit-buttons">
            <button
              className="dept-edit-back-button"
              onClick={() => props.goBack()}
              title="Voltar"
            >
              Voltar
            </button>
            <button
              className="dept-edit-save-button"
              title="Salvar"
              style={{
                minWidth: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => editDepartment(e)}
              disabled={loading}
            >
              {loading ? <MiniLoading /> : "Salvar"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
