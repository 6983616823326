import React from "react";
import Grafico from "../../graphs/tempoAtendimento";
import "./card.css";

const transforToDDHHMMSS = (seconds) => {
  if (!seconds) {
    return "0";
  }
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  let mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  seconds = Math.floor(seconds);

  return `${days ? days + "D" : ""} ${hrs ? hrs + "h" : ""} ${
    mnts ? mnts + "m" : ""
  } ${seconds ? seconds : ""}s`;
};

export default function CardAtendimento({
  atendente,
  date,
  generatePdf,
  handCheckSelectId,
}) {
  return (
    <div className="attendance-card" key={atendente._id}>
      <div className="container-button">
        <button className="button-download-relátorio" onClick={generatePdf}>
          Download relatório
        </button>
      </div>
      <div className="attendance-card-header">
        <h3>{atendente.nome}</h3>
        <input
          type="checkbox"
          className="attendance-espera-card-header-checkbox"
          onChange={() => handCheckSelectId(atendente?._id)}
        />
      </div>
      <div>
        <h4>
          Tempo Médio de Atendimento:{transforToDDHHMMSS(atendente?.tempo)}
        </h4>
      </div>
      <div>
        <h4>Total de Atendimentos: {atendente?.data?.length}</h4>
      </div>
      <div className="container-relatorio-graphic">
        <Grafico
          date={date}
          data={atendente?.data}
          id={`graph-${atendente._id}`}
        />
      </div>
    </div>
  );
}
