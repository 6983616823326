import React, { useEffect, useState } from "react";
import SectionConfig from "./OptionsScreen/Section/SectionConfig";
import DepartmentConfig from "./OptionsScreen/Department/DepartmentConfig";
import UserConfig from "./OptionsScreen/Users/UserConfig";
import GroupsConfig from "./OptionsScreen/Groups/GroupsConfig";
import SuportConfig from "./OptionsScreen/Suport/SuportConfig";
import AttendanceConfig from "./OptionsScreen/Attendance/AttendanceConfig";
import { InicioRapido } from "./OptionsScreen/InicioRapido";
// import Disparator from './OptionsScreen/Disparator/Disp';

export default function OptionMenu(props) {
  const [option, setOption] = useState("Gerenciamento de Usuários");

  useEffect(() => {
    setOption(props.opt);
  }, [props.opt]);

  const components = {
    "Gerenciamento de Usuários": <UserConfig />,
    "Gerenciamento de Departamentos": <DepartmentConfig />,
    "Gerenciamento de Setores": <SectionConfig />,
    "Gerenciamento de Grupos de Conversa": <GroupsConfig />,
    "Gerenciamento de Grupos de Atendimento": <AttendanceConfig />,
    "Inicialização Rápida": <InicioRapido />,
    Suporte: <SuportConfig />,
  };

  return (
    <div style={{ height: "104%" }} className="right-opt-screen">
      {components[option]}
    </div>
  );
}
