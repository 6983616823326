// import React, { Component } from 'react';
// import ApiUsers from '../../../../ApiUsers';
// import SimplyForm from '../inputs/SimplyForm';
// import { AiFillPlusSquare, AiFillMinusSquare } from 'react-icons/ai';
// import Alert from '../inputs/Alerts/Alert';
// import MiniLoading from '../inputs/MiniLoading';
// import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

// export default class DepartamentCreate extends Component {

//     static contextType = PrefContext;

//     constructor(props) {
//         super(props);
//         this.state = {
//             departamento: {
//                 nome: "",
//                 usuarios: [],
//                 setores: [],
//                 sala_de_conferencia: ""
//             },
//             departamentos: [
//                 {
//                     nome: "",
//                     usuarios: [],
//                     setores: [],
//                     sala_de_conferencia: ""
//                 }
//             ],
//             multi: false,
//             alert: { visible: false, type: "", props: {} },
//             loading: false,
//         }
//         this.createForm = this.createForm.bind(this)
//         this.createMultiForm = this.createMultiForm.bind(this)
//     }

//     createForm(e) {
//         e.preventDefault();
//         if (this.state.departamento.nome.trim() !== "" && this.state.departamento.sala_de_conferencia.trim() !== "") {
//             this.setState({ loading: true });
//             let newDep = this.state.departamento;
//             newDep.nome = this.state.departamento.nome.trim();
//             newDep.sala_de_conferencia = this.state.departamento.sala_de_conferencia.trim();
//             ApiUsers.post('/departamentos/novo', { departamento: newDep }).then(res => {
//                 console.log(res.data);
//                 let newState = this.state
//                 newState.departamento = { nome: "", usuarios: [], setores: [], sala_de_conferencia: "" };
//                 newState.loading = false;
//                 this.setState(newState)
//                 this.createAlert("normal", { title: "Sucesso!", placeholder: "Departamento criado com sucesso!" });
//             })
//         } else {
//             this.createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
//         }
//     }

//     createMultiForm(e) {
//         e.preventDefault();
//         this.setState({ loading: true, sending: true })
//         let depts = this.state.departamentos;
//         let emptyFields = 0;
//         depts.forEach((dept) => {
//             if (dept.nome.trim() === "") {
//                 emptyFields = emptyFields + 1;
//             } else {
//                 dept.nome = dept.nome.trim();
//                 dept.sala_de_conferencia = dept.sala_de_conferencia.trim();
//             }
//         });
//         if (emptyFields === 0) {
//             ApiUsers.post("departamentos/many", { deps: depts }).then((res) => {
//                 let newState = this.state;
//                 newState.departamentos = [{ nome: "", usuarios: [], setores: [] }];
//                 newState.loading = false;
//                 this.setState(newState);
//                 this.createAlert("normal", { title: "Sucesso!", placeholder: "Departamentos criados com sucesso!" });
//             }).catch(err => console.log(err));
//         } else {
//             this.createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
//         }
//     }

//     changeInput(e, index) {
//         let newState = this.state
//         if (this.state.multi) {
//             newState.departamentos[index][e.target.name] = e.target.value;
//         } else {
//             newState.departamento[e.target.name] = e.target.value
//         }
//         this.setState(newState)
//     }

//     increaseDep() {
//         if (this.state.multi === false) this.setState({ multi: true });
//         if (this.state.departamentos.length < 50) {
//             let depts = this.state.departamentos;
//             let dep = { nome: "", usuarios: [], setores: [], sala_de_conferencia: "" };
//             depts.push(dep);
//             this.setState({ departamentos: depts });
//         } else {
//             this.setState({ alertType: "maxCapacity", showWarningAlert: true });
//         }
//     }

//     decreaseDep() {
//         let depts = this.state.departamentos;
//         depts.pop();
//         this.setState({ departamentos: depts });
//         if (this.state.departamentos.length < 2) this.setState({ multi: false });
//     }

//     removeSingleDept(index) {
//         let depts = this.state.departamentos;
//         depts.splice(index, 1);
//         this.setState({ departmentos: depts });
//         if (this.state.departamentos.length < 2) this.setState({ multi: false });
//     }

//     createAlert(type, props) {
//         this.setState({ alert: { visible: true, type, props } });
//     }

//     quitAlert() {
//         this.setState({ alert: { visible: false, type: "", props: {} } });
//     }

//     render() {

//         const alertType = {
//             normal: <Alert title={this.state.alert.props.title} placeholder={this.state.alert.props.placeholder} quit={() => this.quitAlert()} />,
//         }

//         return (

//             <div className="create-dept-container">

//                 {this.state.alert.visible && (alertType[this.state.alert.type])}

//                 {!this.state.multi ? <>

//                     <SimplyForm
//                         handleSubmit={this.createForm}
//                         field="nome"
//                         field2="sala_de_conferencia"
//                         id="dep-form"
//                         title="Criação de Departamentos"
//                         placeholder="Digite aqui o nome do departamento"
//                         placeholder2="Digite aqui a sala de conferência"
//                         handleChange={e => { this.changeInput(e) }}
//                         value={this.state.departamento.nome}
//                         value2={this.state.departamento.sala_de_conferencia}
//                         increaseDep={() => this.increaseDep()}
//                         loading={this.state.loading}
//                     />

//                 </> : <>

//                     <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', margin: "20px 10px" }}>

//                         <span style={{ color: 'black' }}> Total de departamentos: {this.state.departamentos.length} </span>

//                         <div style={{ display: 'flex', alignItems: 'center' }}>
//                             <AiFillMinusSquare
//                                 className="inc-dec-icons"
//                                 style={{ fontSize: 40, cursor: 'pointer', marginRight: 5, color: "#e73a3a" }}
//                                 onClick={() => this.decreaseDep()}
//                                 title="Diminuir quantidade de departamentos"
//                             />
//                             <AiFillPlusSquare
//                                 className="inc-dec-icons"
//                                 style={{ fontSize: 40, cursor: 'pointer', marginRight: 8, color: "#0d6efd" }}
//                                 onClick={() => this.increaseDep()} title="Aumentar quantidade de departamentos"
//                             />
//                         </div>

//                         <button
//                             onClick={this.createMultiForm}
//                             className="save-all-multiform-btn"
//                             title="Salvar todos"
//                             disabled={this.state.sending}
//                             style={{ minWidth: 120, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
//                             {this.state.loading ? <MiniLoading /> : "Salvar Todos"}
//                         </button>

//                     </div>

//                     {this.state.departamentos?.map((dep, index) => {
//                         return (
//                             <div key={index}>
//                                 <SimplyForm
//                                     handleSubmit={this.createMultiForm}
//                                     field="nome"
//                                     field2="sala_de_conferencia"
//                                     id="dep-form"
//                                     placeholder="Digite aqui o nome do departamento"
//                                     placeholder2="Digite aqui a sala de conferência"
//                                     indexDep={index + 1}
//                                     multi={true}
//                                     handleChange={e => { this.changeInput(e, index) }}
//                                     value={this.state.departamentos[index].nome}
//                                     value2={this.state.departamentos[index].sala_de_conferencia}
//                                     removeSingleDept={() => this.removeSingleDept(index)}
//                                 />
//                             </div>
//                         )
//                     })}

//                     {this.state.departamentos?.length >= 30 ? <>

//                         <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', padding: "10px 15px" }}>
//                             <button
//                                 className="save-all-multiform-btn"
//                                 style={{ minWidth: 120, display: 'flex', justifyContent: 'center', alignItems: "center" }}
//                                 onClick={this.createMultiForm}
//                                 disabled={this.state.loading}
//                                 title="Salvar Todos">
//                                 {this.state.loading ? <MiniLoading /> : "Salvar Todos"}
//                             </button>
//                         </div>

//                     </> : null}

//                 </>}

//             </div>

//         );
//     }
// }
import React, { useState, useContext } from 'react';
import ApiUsers from '../../../../ApiUsers';
import SimplyForm from '../inputs/SimplyForm';
import { AiFillPlusSquare, AiFillMinusSquare } from 'react-icons/ai';
import Alert from '../inputs/Alerts/Alert';
import MiniLoading from '../inputs/MiniLoading';
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

const DepartamentCreate = () => {
    const prefContext = useContext(PrefContext);

    const [departamento, setDepartamento] = useState({
        nome: "",
        usuarios: [],
        setores: [],
        sala_de_conferencia: ""
    });
    const [departamentos, setDepartamentos] = useState([
        {
            nome: "",
            usuarios: [],
            setores: [],
            sala_de_conferencia: ""
        }
    ]);
    const [multi, setMulti] = useState(false);
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
    const [loading, setLoading] = useState(false);

    const createForm = (e) => {
        e.preventDefault();
        if (departamento.nome.trim() !== "" && departamento.sala_de_conferencia.trim() !== "") {
            setLoading(true);
            let newDep = { ...departamento };
            newDep.nome = departamento.nome.trim();
            newDep.sala_de_conferencia = departamento.sala_de_conferencia.trim();
            ApiUsers.post('/departamentos/novo', { departamento: newDep }).then(res => {
                console.log(res.data);
                setDepartamento({ nome: "", usuarios: [], setores: [], sala_de_conferencia: "" });
                setLoading(false);
                createAlert("normal", { title: "Sucesso!", placeholder: "Departamento criado com sucesso!" });
            })
        } else {
            createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
        }
    }

    const createMultiForm = (e) => {
        e.preventDefault();
        setLoading(true);
        let depts = [...departamentos];
        let emptyFields = 0;
        depts.forEach((dept) => {
            if (dept.nome.trim() === "") {
                emptyFields = emptyFields + 1;
            } else {
                dept.nome = dept.nome.trim();
                dept.sala_de_conferencia = dept.sala_de_conferencia.trim();
            }
        });
        if (emptyFields === 0) {
            ApiUsers.post("departamentos/many", { deps: depts }).then((res) => {
                setDepartamentos([{ nome: "", usuarios: [], setores: [] }]);
                setLoading(false);
                createAlert("normal", { title: "Sucesso!", placeholder: "Departamentos criados com sucesso!" });
            }).catch(err => console.log(err));
        } else {
            createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos necessários!" });
        }
    }

    const changeInput = (e, index) => {
        if (multi) {
            let newDepartamentos = [...departamentos];
            newDepartamentos[index][e.target.name] = e.target.value;
            setDepartamentos(newDepartamentos);
        } else {
            setDepartamento({ ...departamento, [e.target.name]: e.target.value });
        }
    }

    const increaseDep = () => {
        if (!multi) setMulti(true);
        if (departamentos.length < 50) {
            let depts = [...departamentos];
            let dep = { nome: "", usuarios: [], setores: [], sala_de_conferencia: "" };
            depts.push(dep);
            setDepartamentos(depts);
        } else {
            createAlert("maxCapacity", { title: "Ops!", placeholder: "Limite máximo de departamentos alcançado!" });
        }
    }

    const decreaseDep = () => {
        let depts = [...departamentos];
        depts.pop();
        setDepartamentos(depts);
        if (departamentos.length < 2) setMulti(false);
    }

    const removeSingleDept = (index) => {
        let depts = [...departamentos];
        depts.splice(index, 1);
        setDepartamentos(depts);
        if (departamentos.length < 2) setMulti(false);
    }

    const createAlert = (type, props) => {
        setAlert({ visible: true, type, props });
    }

    const quitAlert = () => {
        setAlert({ visible: false, type: "", props: {} });
    }

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />,
    }

    return (
        <div className="create-dept-container">
            {alert.visible && (alertType[alert.type])}
            {
                !multi ? <>
                    <SimplyForm
                        handleSubmit={createForm}
                        field="nome"
                        field2="sala_de_conferencia"
                        id="dep-form"
                        title="Criação de Departamentos"
                        placeholder="Digite aqui o nome do departamento"
                        placeholder2="Digite aqui a sala de conferência"
                        handleChange={e => changeInput(e)}
                        value={departamento.nome}
                        value2={departamento.sala_de_conferencia}
                        increaseDep={() => increaseDep()}
                        loading={loading}
                    />
                </>
                    :
                    <>
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', margin: "20px 10px" }}>
                            <span style={{ color: 'black' }}> Total de departamentos: {departamentos.length} </span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiFillMinusSquare
                                    className="inc-dec-icons"
                                    style={{ fontSize: 40, cursor: 'pointer', marginRight: 5, color: "#e73a3a" }}
                                    onClick={() => decreaseDep()}
                                    title="Diminuir quantidade de departamentos"
                                />
                                <AiFillPlusSquare
                                    className="inc-dec-icons"
                                    style={{ fontSize: 40, cursor: 'pointer', marginRight: 8, color: "#0d6efd" }}
                                    onClick={() => increaseDep()} title="Aumentar quantidade de departamentos"
                                />
                            </div>
                            <button
                                onClick={createMultiForm}
                                className="save-all-multiform-btn"
                                title="Salvar todos"
                                disabled={loading}
                                style={{ minWidth: 120, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                {loading ? <MiniLoading /> : "Salvar Todos"}
                            </button>
                        </div>
                        {departamentos?.map((dep, index) => {
                            return (
                                <div key={index}>
                                    <SimplyForm
                                        handleSubmit={createMultiForm}
                                        field="nome"
                                        field2="sala_de_conferencia"
                                        id={`dep-form-${index}`}
                                        placeholder="Digite aqui o nome do departamento"
                                        placeholder2="Digite aqui a sala de conferência"
                                        indexDep={index + 1}
                                        multi={true}
                                        handleChange={e => changeInput(e, index)}
                                        value={departamentos[index].nome}
                                        value2={departamentos[index].sala_de_conferencia}
                                        removeSingleDept={() => removeSingleDept(index)}
                                    />
                                </div>
                            )
                        })}
                        {departamentos?.length >= 30 ? <>
                            <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', padding: "10px 15px" }}>
                                <button
                                    className="save-all-multiform-btn"
                                    style={{ minWidth: 120, display: 'flex', justifyContent: 'center', alignItems: "center" }}
                                    onClick={createMultiForm}
                                    disabled={loading}
                                    title="Salvar Todos">
                                    {loading ? <MiniLoading /> : "Salvar Todos"}
                                </button>
                            </div>
                        </> : null}
                    </>}
        </div>
    );
}

export default DepartamentCreate;
