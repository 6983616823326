import React from "react";
import { BsPlus } from "react-icons/bs";
import "./buttonNew.css";

export default function ButtonNewAction({ onClick, title }) {
  return (
    <div className="container-align-button-add-comands">
      <button title={title} className="comando-button" onClick={onClick}>
        <BsPlus size={25} />
      </button>
    </div>
  );
}
