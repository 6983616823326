import { Text } from "./Types/text.jsx";
import { Time } from "./Types/time.jsx";
import { CheckBox } from "./Types/checkbox.jsx";
import { Date } from "./Types/date.jsx";

export const Inputs = ({ label, value, setValue, inputKey, inputType, type, subKeys, checkBoxes, legend }) => {
  const inputTypes = {
    text: (
      <Text
        label={label}
        value={value}
        setValue={setValue}
        inputKey={inputKey}
        type={type}
        subKeys={subKeys}
        legend={legend}
      />
    ),
    time: (
      <Time
        label={label}
        value={value}
        setValue={setValue}
        inputKey={inputKey}
        type={type}
        subKeys={subKeys}
      />
    ),
    date: (
      <Date
        label={label}
        value={value}
        setValue={setValue}
        inputKey={inputKey}
        type={type}
        subKeys={subKeys}
      />
    ),
    checkbox: (
      <CheckBox
        label={label}
        value={value}
        setValue={setValue}
        inputKey={inputKey}
        type={type}
        subKeys={subKeys}
        checkBoxes={checkBoxes}
      />
    ),
  };

  const renderedTypes = {};

  return (
    <>
      {type === "object"
        ? subKeys.map((inputType, index) => {
            if (!renderedTypes[inputType.inputType]) {
              renderedTypes[inputType.inputType] = true;
              return <div key={index}>{inputTypes[inputType.inputType]}</div>;
            }
            return null;
          })
        : inputTypes[inputType]}
    </>
  );
};
