import React from 'react'
import Picker from 'emoji-picker-react';

let groupNames = {
    smileys_people: 'Mais Comuns',
    animals_nature: 'Animais e a Natureza',
    food_drink: 'Comilança e afins',
    travel_places: 'Viagens e Lugares',
    activities: 'Atividades',
    objects: 'Aleatorios',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    recently_used: 'Recentes',
}
export default function EmjPick(props) {
    const { onEmoji,setOnEmoji, message, setMessage, mensagemRapida, setMensagemRapida, isMsgRapidaFormOpen } = props

    function addEmoji(event, emojiObject) {
      if (isMsgRapidaFormOpen) {
        let newMessage = mensagemRapida + emojiObject.emoji;
        setMensagemRapida(newMessage);
        setOnEmoji(false);
        return;
      }

      setOnEmoji(false);
      let newMessage = message + emojiObject.emoji;
      setMessage(newMessage);
    }
    return (
        <div>
            {
                onEmoji ?
                    <Picker groupNames={groupNames} pickerStyle={{ position: 'absolute', bottom: "4%", right: 0, zIndex: 3, border: "1px solid #939393" }} onEmojiClick={addEmoji} />
                    :
                    null
            }
        </div>
    )
}
