import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../../../contexts/UserProvider";

export const ConviteBotGrupos = ({
  pesquisa,
  gruposSelecionados,
  setGruposSelecionados,
  contactsPerPage,
  setTotalPages,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const { pref } = useContext(UserContext);
  const [grupos, setGrupos] = useState([]);
  const [gruposFiltrados, setGruposFiltrados] = useState([]);

  const handleSelectGrupo = (idGrupo) => {
    const grupo = gruposFiltrados.find((grupo) => grupo._id === idGrupo);
    if (gruposSelecionados?.includes(grupo)) {
      setGruposSelecionados(
        gruposSelecionados.filter((grupo) => grupo._id !== idGrupo)
      );
    } else {
      setGruposSelecionados([...gruposSelecionados, grupo]);
    }
  };

  const checkGrupo = (idGrupo) => {
    return gruposSelecionados.some((grupo) => grupo._id === idGrupo);
  };

  useEffect(() => {
    const novosGrupos = pref.services.voxbot.grupos
      ? pref.services.voxbot.grupos
      : [];
    setGrupos([...novosGrupos]);
  }, [pref.services.voxbot.grupos]);

  useEffect(() => {
    let novosContatos = [...grupos];
    if (pesquisa.trim() !== "") {
      novosContatos = novosContatos.filter((contato) =>
        contato.nome?.toLowerCase().includes(pesquisa.toLowerCase())
      );
    }
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const currentContacts = novosContatos.slice(
      indexOfFirstContact,
      indexOfLastContact
    );
    setGruposFiltrados([...currentContacts]);
    setTotalPages(Math.ceil(novosContatos.length / contactsPerPage));
  }, [contactsPerPage, grupos, currentPage, pesquisa, setTotalPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pesquisa, setCurrentPage]);

  return (
    <ul className="convite-bot-contacts">
      {gruposFiltrados?.map((grupo, index) => {
        const isChecked = checkGrupo(grupo._id);
        return (
          <li key={index} onClick={() => handleSelectGrupo(grupo._id)}>
            <input
              type="checkbox"
              onChange={() => handleSelectGrupo(grupo._id)}
              checked={isChecked}
            />
            <img
              src="/imgs/groupavatar.png"
              alt="Foto"
              onError={(e) => {
                e.target.src = "/imgs/groupavatar.png";
              }}
            />
            <div className="convite-bot-contacts-info">{grupo?.nome}</div>
          </li>
        );
      })}
      <div className="pagination-att">
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span style={{ color: "black" }}>
          {currentPage} ... {totalPages}
        </span>
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(currentPage + 1);
          }}
          disabled={currentPage === Math.ceil(totalPages) || totalPages === 0}
        >
          Próximo
        </button>
      </div>
    </ul>
  );
};
