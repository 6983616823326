import React, { useState, useEffect, useRef } from 'react';
import { FaMinus, FaPlus, FaImage, FaTrash, FaFile } from 'react-icons/fa';
import Picker from 'emoji-picker-react';
import { AiOutlineSmile } from 'react-icons/ai';
import { Form } from 'react-bootstrap';

let groupNames = {
    smileys_people: 'Mais Comuns',
    animals_nature: 'Animais e a Natureza',
    food_drink: 'Comilança e afins',
    travel_places: 'Viagens e Lugares',
    activities: 'Atividades',
    objects: 'Aleatorios',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    recently_used: 'Recentes',
};

function MytextArea(props) {
    const [onEmoji, setOnEmoji] = useState(false);
    const [turnIntoList, seTurnIntoList] = useState(props.turnIntoList);
    const [list, setList] = useState(props.list ? props.list : { ativo: false, titulo: "", seçoes: [] });
    const [file, setFile] = useState(null);
    const fileInput = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
        seTurnIntoList(props.turnIntoList);
        if (props.command.list) {
            setList(props.command.list);
        }
        if (props.command.file) {
            setFile(props.command.file);
        }
    }, [props.turnIntoList, props.command.list, props.command.file]);

    const handlePaste = (e) => {
        if (e.clipboardData.files.length > 0) {
            const pastedFile = e.clipboardData.files[0];
            if (pastedFile.type.startsWith('image/')) {
                setFile(pastedFile);
                props.changeFile(pastedFile);
            }
        }
    };

    return (
        <div style={{ margin: "15px 0px", display: "flex", flexDirection: "column" }}>
            {turnIntoList && (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "30%" }}>
                    <h6>Lista</h6>
                    <input
                        type="checkbox"
                        checked={list.ativo}
                        onChange={() => props.setList({ ...list, ativo: !list.ativo })}
                        style={{ marginLeft: "10px", marginBottom: "3px", width: "20px", height: "16px" }}
                    />
                </div>
            )}
            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                {list?.ativo && (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ marginBottom: 10 }}>Lista</h3>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <h6>Título</h6>
                                <input
                                    className="flow-form-input"
                                    type="text"
                                    value={list.titulo}
                                    onChange={(e) => props.setList({ ...list, titulo: e.target.value })}
                                    style={{ marginLeft: "10px", marginBottom: "3px", width: "45%" }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <h6>Seções</h6>
                            <FaPlus
                                style={{ marginLeft: "10px", marginBottom: "3px" }}
                                onClick={() => {
                                    props.setList({ ...list, seçoes: [...list.seçoes, { nome: "", to: "" }] });
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            {list?.seçoes.map((seção, index) => (
                                <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <input
                                        type="text"
                                        value={seção.nome}
                                        onChange={(e) => {
                                            let seçoes = list.seçoes;
                                            seçoes[index].nome = e.target.value;
                                            props.setList({ ...list, seçoes: seçoes });
                                        }}
                                        className="flow-form-input"
                                        style={{ width: "30%", padding: "10px" }}
                                    />
                                    <select
                                        value={seção.to}
                                        onChange={(e) => {
                                            let seçoes = list.seçoes;
                                            seçoes[index].to = e.target.value;
                                            props.setList({ ...list, seçoes: seçoes });
                                        }}
                                        className="flow-form-select"
                                    >
                                        <option value="">Dá última Seção até:</option>
                                        {props.command.alternativas.map((alt, i) => (
                                            <option key={i} value={i}>
                                                {i + 1} - {alt.valor}
                                            </option>
                                        ))}
                                    </select>
                                    <FaMinus
                                        style={{ marginLeft: "10px", marginBottom: "3px" }}
                                        onClick={() => {
                                            let seçoes = list.seçoes;
                                            seçoes.splice(index, 1);
                                            props.setList({ ...list, seçoes: seçoes });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <h3 style={{ marginBottom: 10 }} className="required">
                    {list?.ativo ? "Descrição" : "Mensagem"}
                </h3>
                <Form.Control
                    as="textarea"
                    ref={textAreaRef}
                    className="flow-form-input"
                    value={props.command.mensagem}
                    onChange={(e) => props.changeMessage(e)}
                    onKeyPress={props.onkeypress}
                    onPaste={handlePaste}
                    style={{ width: "100%", height: "20vh", padding: "10px" }}
                />
                {props.command.file ? (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {props.command.file?.type.includes("image") ? (
                            <img src={props.command.file.url} style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "10px" }} />
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100px", height: "100px", borderRadius: "10px", border: "1px solid rgb(0, 129, 167)" }}>
                                <FaFile style={{ fontSize: "1.9rem", color: "rgb(0, 129, 167)" }} />
                                <p style={{ fontSize: "0.8rem", color: "rgb(0, 129, 167)" }}>{props.command.file.name}</p>
                            </div>
                        )}
                        <FaMinus style={{ marginLeft: "10px", marginBottom: "3px" }} onClick={() => props.changeFile(null)} />
                    </div>
                ) : (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <FaImage style={{ fontSize: "1.9rem", color: "rgb(0, 129, 167)", marginTop: "10px" }} onClick={() => fileInput.current.click()} />
                        <input type="file" ref={fileInput} onChange={(e) => props.changeFile(e.target.files[0])} style={{ display: "none" }} onClick={(e) => (e.target.value = null)} />
                        <AiOutlineSmile style={{ fontSize: "1.9rem", color: "rgb(0, 129, 167)", marginTop: "10px" }} onClick={() => setOnEmoji(!onEmoji)} />
                        {onEmoji ? <Picker onEmojiClick={props.addFileToMessageEmoji} groupNames={groupNames} pickerStyle={{ position: "fixed", right: "18%", top: "49%" }} /> : null}
                    </div>
                )}
            </div>
        </div>
    );
}

export default MytextArea;
