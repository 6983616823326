import React, { useState, useEffect, useContext } from "react";
import MainView from "../main/MainView";
import Header from "../main/HeaderView";
import BigView from "../main/BigView";
import FlowCreation from "./FlowConstructor/FlowCreation";
import BotCreation from "./Bots/BotCreation/BotCreation";
import Variables from "./Variables/Variables";
import Pesquisas from "./Pesquisas/PesquisasPanel";
import { ContactContext } from "../../contexts/ChatBotProvider/ContactProvider";
import { UserContext } from "../../contexts/UserProvider";
import Protocolos from "./Protocolos/Protocolos";
import Campanhas from "./Campanhas/index";
import Contatos from "./Contatos";
import Relatorios from "./Relatorios/index";
import Grupos from "./Grupos/index";
import "./VoxTool.css";

export default function VoxTool() {
  const [onShow, setOnShow] = useState("");
  const { user } = useContext(UserContext);
  const context = useContext(ContactContext);
  const mySocket = context.userContext.socket;

  const components = {
    Bots: <BotCreation />,
    "Fluxos de diálogo": <FlowCreation />,
    Variaveis: <Variables />,
    Pesquisa: <Pesquisas />,
    Protocolos: <Protocolos />,
    Campanhas: <Campanhas />,
    Contatos: <Contatos />,
    Relatórios: <Relatorios />,
    Grupos: <Grupos />,
  };
  useEffect(() => {
    localStorage.setItem("type", "whats");
    mySocket.on("lastMsg", (data) => {
      context.userContext.lastMsgCont(data);
      context.userContext.pushNewMessageTo(data.msg, data.from);
      let counter = context.pvtNotRead + 1;
      context.setPvtNotRead(counter);
    });
    return () => {
      mySocket.off("lastMsg");
    };
  }, [context.pvtNotRead]);

  useEffect(() => {
    if (user.disparador) {
      setOnShow("Campanhas");
    } else {
      setOnShow("Bots");
    }
  }, []);
  return (
    <>
      <MainView title="Ferramenta de Whatsapp">
        <BigView>
          <div className="button-view-container">
            <div>
              <Header title="Ferramenta de Whatsapp" little={true} />
            </div>
            <div>
              {Object.keys(components).map((key, index) => {
                if (user && user.disparador) {
                  if (key !== "Campanhas") {
                    return null;
                  }
                }
                return (
                  <button
                    key={index}
                    className="button-view tools-zap-button"
                    style={
                      onShow === key
                        ? {
                            color: "#9ac31c",
                            fontWeight: "bold",
                          }
                        : null
                    }
                    onClick={() => setOnShow(key)}
                  >
                    {key}
                  </button>
                );
              })}
            </div>
          </div>
          {components[onShow]}
        </BigView>
      </MainView>
    </>
  );
}
