import React from 'react';
import { useEffect,useState } from 'react';

function Simples(props) {
    const [command, setCommand] = useState({...props.command})
    const [selectedFlow, setSelectedFlow] = useState({...props.selectedFlow})
    
    useEffect(() => {
        setCommand({...props.command})
        setSelectedFlow({...props.selectedFlow})
    }, [props.command, props.selectedFlow])

    function setMySimpleAction(event) {
        let newCommand = { ...command };
        newCommand.action = event.target.value;
        setCommand(newCommand);
        props.setCommand(newCommand);
    }

    function setMySimpleNext(event) {
        let newCommand = { ...command };
        newCommand.next = event.target.value;
        setCommand(newCommand);
        props.setCommand(newCommand);
    }

    return (
        <div>
        <div className="flow-form-select-item">
            <select className="flow-form-select-actions" style={{ flex: 1, marginLeft: 0 }} value={command.action} onChange={(e) => setMySimpleAction(e)} >
                <option value={""}>Selecione uma ação</option>
                {props.selectActions.map((action, index3) => {
                return <option key={index3} value={action.value}> {action.name} </option>
                })}
            </select>

            <select className="flow-form-select-actions" style={{ flex: 1, marginRight: 0 }} value={command.next} onChange={(e) => setMySimpleNext(e)} >
                {command.action === "keep" ? <>

                <option value={""}> Escolha uma ação </option>

                <option value={"keep"}> Seguir p/ Próximo comando </option>
                {selectedFlow.comandos.map((c, i) => {
                return <option key={i} value={i}> {i + 1}º {c.nome} </option>
                })}
                </> : <>
                <option value={""}> Selecione uma ação </option>
                {command.action === "send-to-flow" ? <>
                {props.flows.map((f, i) => {
                return <option key={i} value={f._id}> {f.nome} </option>
                })}
                </> : command.action === "send-to-chat" ? <>
                {props.groups.map((g, i) => {
                return <option key={i} value={g._id}> {g.nome} </option>
                })}
                </> : null}
                </>}
            </select>
        </div>
    
        </div> 
    );
};

export default Simples;