import React, { useState } from "react";
import UserShow from "./UserShow";
import UserCreate from "./UserCreateNEW";
import UserMany from "./UserMany";
import LinkedList from "./LinkedList";
import "./UserConfig.css";

export default function UserConfig(props) {
  const [onShow, setOnShow] = useState("Visualizar");
  const components = {
    Visualizar: <UserShow />,
    Criar: <UserCreate />,
    "Adicionar Múltiplos": <UserMany />,
    "Link de criação": <LinkedList />,
  };

  return (
    <div className="config-list-header">
      {Object.keys(components).map((key, index) => {
        return (
          <button
            key={index}
            title={key}
            onClick={(e) => setOnShow(key)}
            className="button-view"
            style={
              onShow === key
                ? {
                    color: "#9ac31c",
                    fontWeight: "bold",
                  }
                : null
            }
          >
            {key}
          </button>
        );
      })}
      {onShow ? components[onShow] : ""}
    </div>
  );
}
