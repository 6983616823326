import React, { useContext, useState, useEffect } from 'react';
import { Form, Container } from "react-bootstrap";
import GroupCheckBox from './GroupCheckbox/GroupCheckbox';
import ApiUsers from '../../../../../ApiUsers';
import MiniLoading from '../../inputs/MiniLoading';
import { GroupContext } from '../../../../../contexts/ChatBotProvider/GroupProvider';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';
import '../Group.css';

export default function GroupsEdit(props) {

    const context = useContext(GroupContext);
    const prefContext = useContext(PrefContext);
    const [grupo, setGrupo] = useState({...props.grupo});
    const [departamentos, setDepartamentos] = useState([]);
    const [setores, setSetores] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosOnShow, setUsuariosOnShow] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [unselectedUsers, setUnselectedUsers] = useState([]);
    const [filters, setFilters] = useState({ nome: "", departamento: "", setor: "" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let newDepartments = prefContext.departamentos;
        let newSectors = [];
        newDepartments.forEach((d) => newSectors = [...newSectors, ...d.setores]);
        let allUsers = prefContext.users.filter((u) => u.nome !== "Voxcity" && u.ativo);
        let newUsers = [];
        allUsers.forEach(userObj => {
            if (!grupo.usuarios.some(userId => userId === userObj._id)) newUsers.push(userObj);
        });
        let groupUsers = allUsers.filter((userObj) => grupo.usuarios.some(userId => userId === userObj._id));
        let groupAdms = groupUsers.filter((userObj) => grupo.master.some(userId => userId === userObj._id));
        let newGroup = {...grupo};
        newGroup.usuarios = groupUsers;
        newGroup.master = groupAdms;
        setDepartamentos(newDepartments);
        setSetores(newSectors);
        setUsuarios(newUsers);
        setUsuariosOnShow(newUsers);
        setGrupo(newGroup);
    }, []);

    function addUser(e) {
        e.preventDefault();
        let selectUsers = [...selectedUsers]
        let usersList = [...grupo.usuarios];
        if (selectUsers.length > 0) {
            let newAllUsers = usuarios.filter((u) => {
                if (!selectUsers.some(user => user._id === u._id)) return u;
            });
            let newListUsers = usuariosOnShow.filter((u) => {
                if (!selectUsers.some(user => user._id === u._id)) return u;
            });
            selectUsers.forEach((u) => usersList.push(u));
            let newGrupo = { ...grupo };
            newGrupo.usuarios = usersList;
            setUsuarios(newAllUsers);
            setUsuariosOnShow(newListUsers);
            setGrupo(newGrupo);
            setSelectedUsers([]);
        }
    }

    function delUser(e) {
        e.preventDefault();
        let unselectUsers = [...unselectedUsers];
        let noUsersList = [...usuariosOnShow];
        if (unselectUsers.length > 0) {
            let newUsersList = grupo.usuarios.filter(u => {
                if (!unselectUsers.some(user => user._id === u._id)) return u;
            });
            let newAdminList = grupo.master.filter(u => {
                if (!unselectUsers.some(user => user._id === u._id)) return u;
            });
            unselectUsers.forEach((u) => noUsersList.push(u));
            let newGrupo = { ...grupo }
            newGrupo.usuarios = newUsersList;
            newGrupo.master = newAdminList;
            setUsuarios(noUsersList);
            setUsuariosOnShow(noUsersList);
            setGrupo(newGrupo);
            setUnselectedUsers([]);
        }
    }

    function addAdmin(checked, user) {
        if (checked) {
            let adminList = [...grupo.master];
            if (!adminList.some(u => u._id === user._id)) {
                adminList.push(user);
                let newGrupo = { ...grupo };
                newGrupo.master = adminList;
                setGrupo(newGrupo)
            }
        } else {
            let adminList = [...grupo.master];
            let newAdminList = adminList.filter(u => u._id !== user._id);
            let newGrupo = { ...grupo };
            newGrupo.master = newAdminList;
            setGrupo(newGrupo);
        }
    }

    function unselectUser(checked, user) {
        if (checked) {
            let unselectedList = [...unselectedUsers];
            if (!unselectedUsers.some((u) => u._id === user._id)) {
                unselectedList.push(user);
                setUnselectedUsers(unselectedList);
            }
        } else {
            let unselectedList = unselectedUsers.filter((u) => {
                if (u._id !== user._id) {
                    return u;
                }
            })
            setUnselectedUsers(unselectedList);
        }
    }

    function selectUser(checked, user) {
        if (checked) {
            let selectedList = [...selectedUsers];
            if (!selectedList.some(u => u._id === user._id)) {
                selectedList.push(user);
                setSelectedUsers(selectedList);
            }
        } else {
            let selectedList = selectedUsers.filter((u) => {
                if (u._id !== user._id) {
                    return u;
                }
            });
            setSelectedUsers(selectedList);
        }
    }

    function selectAll(checked) {
        if (checked) {
            let selectAll = [...usuariosOnShow];
            setSelectedUsers(selectAll);
        } else {
            setSelectedUsers([]);
        }
    }

    function unselectAll(checked) {
        if (checked) {
            let unselectAll = [...grupo.usuarios]
            setUnselectedUsers(unselectAll);
        } else {
            setUnselectedUsers([]);
        }
    }

    function addFilter(step, filter) {
        let newFilter = filters;
        if (step === "departamento") {
            newFilter.departamento = filter;
            newFilter.setor = "";
        }
        else if (step === "setor") {
            newFilter.setor = filter;
        }
        else {
            newFilter.nome = filter;
        }
        setFilters(newFilter);
        doSearch();
    }

    function doSearch() {
        let AllList = [...usuarios];
        let novoUsers = [...AllList];
        const { nome, departamento, setor } = filters;

        if (nome.trim() !== "" && !departamento && !setor) {
            novoUsers = AllList.filter(user => {
                if (user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
            })

        }
        if (nome.trim() === "" && departamento) {
            novoUsers = AllList.filter(user => {
                if (user.departamentoNome === departamento) return user;
            })
        }
        if (nome.trim() === "" && departamento && setor) {
            novoUsers = AllList.filter(user => {
                if (user.departamentoNome === departamento && user.setorNome === setor) return user;
            })
        }
        if (nome.trim() !== "" && departamento) {
            novoUsers = AllList.filter(user => {
                if (user.departamentoNome === departamento && user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
            })
        }
        if (nome.trim() !== "" && departamento && setor) {
            novoUsers = AllList.filter(user => {
                if (user.departamentoNome === departamento && user.setorNome === setor && user.nome.toLowerCase().includes(nome.toLowerCase())) return user;
            })
        }
        setUsuariosOnShow(novoUsers);
    }

    function changeName(e) {
        let newGroup = { ...grupo };
        newGroup.nome = e;
        setGrupo(newGroup);
    }

    function changeConfRoom(e) {
        let newGroup = { ...grupo };
        newGroup.sala_de_conferencia = e;
        setGrupo(newGroup);
    }

    function editGroup() {
        if (grupo.nome.trim() !== "" && grupo.sala_de_conferencia.trim() !== "") {
            let hasModified = false;
            if (grupo.nome.trim() !== props.grupo.nome ||
                grupo.sala_de_conferencia.trim() !== props.grupo.sala_de_conferencia ||
                grupo.usuarios.length !== props.grupo.usuarios.length ||
                grupo.master.length !== props.grupo.master.length) {
                hasModified = true;
            }
            if (hasModified) {
                setLoading(true);
                let editedGroup = { ...grupo };
                let lastName = `${editedGroup.nome}`
                let usersId = [];
                let adminId = [];
                for (let user in editedGroup.usuarios) {
                    usersId.push(editedGroup.usuarios[user]._id)
                }
                for (let admin in editedGroup.master) {
                    adminId.push(editedGroup.master[admin]._id)
                }
                editedGroup.nome = grupo.nome.trim();
                editedGroup.usuarios = usersId;
                editedGroup.master = adminId;
                editedGroup.sala_de_conferencia = grupo.sala_de_conferencia.trim();
                ApiUsers.put(`/grupos/${grupo._id}`, { group: editedGroup, lastGroup: props.grupo }).then((res) => {
                    setLoading(false);
                    props.alert("normal", { title: "Sucesso!", placeholder: "Grupo editado com sucesso!" });
                    props.goBack();
                }).catch(err => console.log(err));
            } else {
                props.alert("normal", { title: "Ops!", placeholder: "Não foram detectadas alterações!" });
            }
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Por favor preencha os campos necessários!" });
        }
    }


    return <>

        <div className="group-edit-container">

            <Container style={{ padding: 0 }}>

                <Form className='group-gen-form'>

                    <h2 style={{ width: "100%", textAlign: 'center', margin: "20px 0px 20px 0px" }}> Editar Grupo {props.grupo.nome} </h2>

                    <Form.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        <Form.Label style={{ fontSize: 20, width: '30%' }}>Nome do grupo: </Form.Label>
                        <Form.Control type="text" placeholder="Nome do grupo" value={grupo.nome} onChange={(e) => changeName(e.target.value)} />

                    </Form.Group>

                    <Form.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>

                        <Form.Label style={{ fontSize: 20, width: '30%' }}>Sala de Conferência: </Form.Label>
                        <Form.Control type="text" placeholder="Sala de Conferência" value={grupo.sala_de_conferencia} onChange={(e) => changeConfRoom(e.target.value)} />

                    </Form.Group>

                    <Form.Group>

                        <GroupCheckBox
                            departamentos={departamentos}
                            setores={setores}
                            filters={filters}
                            usersOnShow={usuariosOnShow}
                            selectedUsers={selectedUsers}
                            unselectedUsers={unselectedUsers}
                            grupo={grupo}
                            addFilter={(step, filter) => addFilter(step, filter)}
                            selectAll={(e) => selectAll(e)}
                            unselectAll={(checked) => unselectAll(checked)}
                            selectUser={(checked, user) => selectUser(checked, user)}
                            unselectUser={(checked, user) => unselectUser(checked, user)}
                            addUser={(e) => addUser(e)}
                            delUser={(e) => delUser(e)}
                            addAdmin={(e, user) => addAdmin(e, user)}
                            loading={loading}
                        />

                    </Form.Group>

                </Form>

                <div style={{ marginRight: "20px", paddingBottom: '20px', display: 'flex', justifyContent: 'end' }}>

                    <button className="go-back-btn" onClick={() => props.goBack()} title="Voltar">
                        Voltar
                    </button>

                    <button className="save-edit-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 70 }} disabled={loading} onClick={() => editGroup()} title="Salvar">
                        {loading ? <MiniLoading /> : "Salvar"}
                    </button>
                </div>

            </Container>

        </div>

    </>

}