import { useState, useContext, useEffect } from "react";
import { VideoContext } from "../../../../../contexts/VideoProvider";
import CreatableSelect from "react-select/creatable";
import { MdInfoOutline } from "react-icons/md";

export const ConviteEmail = ({ conviteReuniao, isEditMsg }) => {
  const { enviarConviteEmail } = useContext(VideoContext);
  const [inputValue, setInputValue] = useState("");
  const [conviteReuniaoEmail, setConviteReuniaoEmail] = useState({});
  const [contatosEmailSelected, setContatosEmailSelected] = useState([]);

  const components = {
    DropdownIndicator: null,
    MultiValueRemove: (data) => {
      const removeConvidado = () => {
        setContatosEmailSelected(
          contatosEmailSelected.filter(
            (convidado) => convidado.value !== data.data.value
          )
        );
      };
      return (
        <button
          className="css-12a83d4-MultiValueRemove"
          type="button"
          onClick={removeConvidado}
        >
          ×
        </button>
      );
    },
  };

  const createOption = (label) => ({
    value: label,
    label: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (isValidEmail(inputValue)) {
          setContatosEmailSelected([
            ...contatosEmailSelected,
            createOption(inputValue),
          ]);
          setInputValue("");
          event.preventDefault();
        }
        break;
      default: {
        break;
      }
    }
  };

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "none",
      border: "none",
      height: "3rem",
      backgroundColor: "white",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isConviteSalaFilled = () => {
    return contatosEmailSelected?.length > 0 && conviteReuniaoEmail.mensagem;
  };

  const handleConviteEmail = () => {
    if (contatosEmailSelected.length > 0) {
      const novoConviteReuniaoEmail = { ...conviteReuniaoEmail };
      novoConviteReuniaoEmail.contatos = contatosEmailSelected.map(
        (convidado) => convidado.value
      );
      const conviteEmail = {
        contatos: novoConviteReuniaoEmail.contatos,
        mensagem: novoConviteReuniaoEmail.mensagem,
      };
      enviarConviteEmail(conviteEmail);
    }
    setContatosEmailSelected([]);
  };

  useEffect(() => {
    const novoConviteReuniaoEmail = conviteReuniao ? { ...conviteReuniao } : {};
    setConviteReuniaoEmail(novoConviteReuniaoEmail);
  }, [conviteReuniao]);

  return (
    <>
      <div className="convite-sala-form-input-container-space">
        <div className="convite-sala-form-input-label-wrapper">
          <label>Convidados:</label>
          <MdInfoOutline
            className="convite-sala-form-convidados-info-icon"
            title="Digite um e-mail válido. Para adicionar tecle Enter ou Tab. Para remover um e-mail, clique no X ao lado do e-mail. Para remover todos os e-mails, clique no X à direita do campo."
          />
        </div>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(newValue) => setContatosEmailSelected(newValue)}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Digite um e-mail válido"
          value={contatosEmailSelected}
          className="convite-sala-form-react-select"
          styles={selectStyles}
        />
      </div>
      <div className="convite-sala-form-button-container">
        <button
          type="button"
          className="convite-sala-form-button"
          onClick={handleConviteEmail}
          disabled={!isConviteSalaFilled() || isEditMsg}
          id="salvar"
        >
          Enviar
        </button>
      </div>
    </>
  );
};
