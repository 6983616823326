import React, { useState, useContext } from 'react';
import { AttendanceContext } from '../../../../../contexts/ChatBotProvider/AttendanceProvider';
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import MyAttandances from './componentes/myattandances';
import MyGroupsAttendances from './componentes/myGroupsAttendances';
import Modal from '../Modal';
import NewAttendance from '../NewAttendance';
import './style.css';

const AttendancesNew = (props) => {
    const { onSelect, selected } = props;
    const attendanceContext = useContext(AttendanceContext);
    const [modal, setModal] = useState(false);
    const [expandList, setExpandList] = useState(true);
    const [expandListGroup, setExpandListGroup] = useState(false);

    function selectAtt(contact) {
        let attendance = [...attendanceContext.attendances].find(
            (att) => att.telefone === contact.telefone
        );
        onSelect(null, attendance);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding:'0 10px' }}>
            {modal && (
                <>
                    <Modal close={() => setModal(false)}>
                        <NewAttendance close={() => setModal(false)} selectContact={(contact) => selectAtt(contact)}/>
                    </Modal>
                </>
            )}
            <div className="my-acordeon">
                <div className="my-acordeon-header" onClick={() => setExpandList(!expandList)} style={expandList ? { borderBottom: 'none' } : null}>
                    <h5>Atendimentos</h5>
                    {expandList ? <BsChevronDown size={20} color={'#9ac31c'} /> : <BsChevronRight size={20} color={'#9ac31c'} />}
                </div>
                <div className="my-acordeon-content" style={expandList ? { borderTop: 'none' } : { maxHeight: 0, boder: 'none', visibility: 'hidden' }}>
                    <MyAttandances setModal={setModal} onSelect={onSelect} selected={selected} />
                </div>
            </div>

            <div className="my-acordeon">
                <div className="my-acordeon-header" onClick={() => setExpandListGroup(!expandListGroup)} style={expandListGroup ? { borderBottom: 'none' } : null}>
                    <h5>Grupos</h5>
                    {expandListGroup ? <BsChevronDown size={20} color={'#9ac31c'} /> : <BsChevronRight size={20} color={'#9ac31c'} />}
                </div>
                <div className="my-acordeon-content" style={expandListGroup ? { borderTop: 'none' } : { maxHeight: 0, boder: 'none', visibility: 'hidden' }}>
                    <MyGroupsAttendances selected={selected} onSelect={onSelect}/>
                </div>
            </div>
        </div >
    )
}

export default AttendancesNew;
