import { GroupProvider } from "./GroupProvider";
import { ContactProvider } from "./ContactProvider";
import { FlowBotProvider } from "./FlowBotProvider";
import { AttendanceProvider } from "./AttendanceProvider";
import { PrefProvider } from "./PrefProvider";
import { GrupoWhatsProvider } from "./GrupoWhatsProvider";
import { PesqPrProvider } from "./PesqPrProvider";
import { MessageProvider } from "./MessageProvider";
import { TemporizadorProvider } from "./TemporizadorProvider";

export function ChatBotProvider({ children }) {
  return (
    <GroupProvider>
      <PrefProvider>
        <GrupoWhatsProvider>
          <FlowBotProvider>
            <ContactProvider>
              <AttendanceProvider>
                <MessageProvider>
                  <PesqPrProvider>
                    <TemporizadorProvider>
                      {children}
                    </TemporizadorProvider>
                  </PesqPrProvider>
                </MessageProvider>
              </AttendanceProvider>
            </ContactProvider>
          </FlowBotProvider>
        </GrupoWhatsProvider>
      </PrefProvider>
    </GroupProvider>
  );
}
