import React, {useState} from 'react';

export default function EditForm(props) {

    const [flow, setFlow] = useState(structuredClone(props.flow)) 

    function setName(e) {
        let newFlow = {...flow};
        newFlow.nome = e;
        setFlow(newFlow);
    }

    return <>
        <h4 style={{ marginBottom: 20 }}> Editar Flow {props.flow.nome} </h4>
        <input type="text" className="flow-form-input" placeholder="Digite aqui o nome do flow..." value={flow.nome} onChange={(e) => setName(e.target.value)} />
        <button className="flow-button" disabled={props.loading} title="Editar Flow" style={{ background: "rgb(0, 129, 167)", margin: "10px 0px 0px 0px" }} onClick={() => props.editFlow(flow)}> Salvar </button>
    </>
}