import React, { useState, useEffect, useContext } from 'react';
import { PrefContext } from '../../../contexts/ChatBotProvider/PrefProvider';
import { FlowBotContext } from '../../../contexts/ChatBotProvider/FlowBotProvider';
import ApiUsers from '../../../ApiUsers';
import MytextArea from './Actions/myTextArea';
import Simples from './Actions/simples';
import Alternativa from './Actions/alternativa';
import Descritiva from './Actions/descritiva';
import Variavel from './Actions/variavel';
import Agendamento from './Actions/agendamento';
import Listagem from './Actions/listagem';
import "./Flow.css";
import { FaTimes } from 'react-icons/fa';

export default function ActionForm(props) {

    const prefContext = useContext(PrefContext);
    const { selectedCommand, selectedFlow, flowList, typeModal } = props;

    const flows = flowList.filter((flow) => flow._id !== selectedFlow._id);
    const [command, setCommand] = useState(structuredClone(selectedCommand));
    const [groups, setGroups] = useState([]);
    const [isOnEmoji, setIsOnEmoji] = useState([]);
    //const [savingImage, setSavingImage] = useState(false);
    const variables = useContext(FlowBotContext).variables;

    const selectActions = [
        { name: "Seguir Flow", value: "keep" },
        { name: "Redirecionar p/ Grupo de Atendentes", value: "send-to-chat" },
        { name: "Redirecionar p/ outro Flow", value: "send-to-flow" },
    ]

    const alternativas = {
        "Pergunta Alternativa": { valor: "", action: "", next: "", button: false },
        "Pergunta Descritiva": { valor: [], action: "", next: "", word: "" },
        "Pergunta Simples": { action: "", next: "" },
        "Pergunta Variavel": { action: "", next: "" },
        "Agendamento": { action: "", next: "" },
        // "Listagem": {  action: "", next: "" },
    }


    useEffect(() => {
        setGroups(prefContext.atendentes);
    }, [prefContext.atendentes])



    function changeName(e) {
        let newCommand = { ...command };
        newCommand.nome = e.target.value;
        setCommand(newCommand);
    }
    function onkeypress(e) {
        if (e.key === "Enter" && e.shiftKey) {
            return
        }

    }

    function changeMessage(e) {
        let newCommand = { ...command };
        newCommand.mensagem = e.target.value;
        setCommand(newCommand);
    }
    async function changeFileMesage(img) {
        // let newCommand = { ...command };
        // newCommand.img = img;
        // setCommand(newCommand);
        if (!img) {
            let newCommand = { ...command };
            newCommand.file = "";
            setCommand(newCommand);
            return;
        }
        let formData = new FormData();
        formData.append('file', img);
        let resp = await ApiUsers.post('/upload', formData).catch((err) => {
            alert("Erro ao enviar imagem");
            console.log(err);
        });
        let newCommand = { ...command };
        newCommand.file = resp.data
        console.log(resp.data);
        setCommand(newCommand);
    }

    function insertOption(type) {
        let newCommand = { ...command };
        newCommand.alternativas = [...newCommand.alternativas, alternativas[type]];
        setCommand(newCommand);
    }

    function addWord(index) {
        let newCommand = { ...command };
        if (!hasRepeatedWord(newCommand, index)) {
            newCommand.alternativas[index].valor = [...command.alternativas[index].valor, command.alternativas[index].word];
            newCommand.alternativas[index].word = "";
            setCommand(newCommand);
        } else {
            props.createAlert("normal", { title: "Ops!", placeholder: "Palavras Repetidas não são permitidas!" })
        }
    }

    function hasRepeatedWord(command, index) {
        let alternativas = command.alternativas;
        let word = command.alternativas[index].word;
        let repeat = false;
        alternativas.forEach((a) => {
            if (a.valor.some((w) => w === word)) repeat = true;
        })
        return repeat;
    }

    function hasEmptyFields(cmd) {
        let empty = false;
        let msg = cmd.mensagem.trim();
        let nome = cmd.nome.trim();
        let alt = cmd.alternativas;
        const emptyFields = {
            "Resposta Simples": () => {
                if (msg === "" || nome === "") empty = true;
                if (empty) return empty;
            },
            "Pergunta Alternativa": () => {
                if (msg === "" || nome === "") empty = true;
                alt.forEach((a) => Object.values(a).forEach((i) => {
                    if (typeof i === "string" && i.trim() === "") empty = true;
                }));
                if (empty) return empty;
            },
            "Pergunta Descritiva": () => {
                if (msg === "" || nome === "") empty = true;
                alt.forEach((a) => {
                    Object.values(a).forEach((i) => {
                        if (typeof i === "string" && i.trim() === "") empty = true;
                        if (Array.isArray(i) && i.length === 0) empty = true;
                    });
                });
                if (empty) return empty;
            },
            "Pergunta com Variável": () => {
                if (msg === "" || nome === "" || alt.length < 1) empty = true;
                if (empty) return empty;
            },
            "Enquete": () => {
                if (msg === "" || nome === "") empty = true;
                alt.forEach((a) => {
                    Object.values(a).forEach((i) => {
                        if (i.trim() === "") empty = true;
                    });
                })
                if (empty) return empty;
            },
            "Agendamento": () => {
                if (nome === "") empty = true;
                if (empty) return empty;
            },
            // "Listagem": () => {
            //     if (nome === "") empty = true;
            //     if (empty) return empty;
            // },
        }
        return emptyFields[cmd.tipo]();
    }

    function hasRepeatedAlternative(command) {
        let repeat = false;
        let alternatives = [];
        let cmd = command.tipo;
        if (cmd === "Pergunta Alternativa") {
            command.alternativas.forEach((a) => {
                alternatives = cmd === "Enquete" ? [...alternatives, a.valor] : [...alternatives, ...a.valor.split(" ")];
            });
            let filteredAlternatives = alternatives.filter((v, i) => {
                return alternatives.indexOf(v) === i;
            });
            if (filteredAlternatives.length !== alternatives.length) repeat = true;
        }
        return repeat;
    }

    function hasEmptyAlternatives(command) {
        let empty = false;
        let types = ["Pergunta Alternativa", "Pergunta Descritiva"]
        if (types.includes(command.tipo) && command.alternativas.length === 0) {
            empty = true;
        }
        return empty;
    }

    function validateCommand(command) {
        let approved = false;
        if (!hasEmptyFields(command)) {
            if (!hasEmptyAlternatives(command)) {
                if (!hasRepeatedAlternative(command)) {
                    approved = true;
                } else {
                    props.createAlert("normal", { title: "Ops!", placeholder: "Não é possível salvar alternativas com palavras repetidas!" });
                }
            } else {
                props.createAlert("normal", { title: "Ops!", placeholder: "Você deve inserir ao menos uma alternativa para salvar!" });
            }
        } else {
            props.createAlert("normal", { title: "Ops!", placeholder: "Por favor, preencha os campos vazios!" });
        }
        return approved;
    }

    function saveCommand() {
        let flow = { ...selectedFlow };
        let cmd = { ...command };
        if (cmd.tipo === "Pergunta Descritiva") cmd.alternativas.forEach((a, index) => delete cmd.alternativas[index].word);
        delete cmd.index;
        if (validateCommand(cmd)) {
            flow.comandos[command.index] = cmd;
            props.saveCommand(flow);
        }
    }

    function addFileToMessageEmoji(event, emojiObject) {
        let newCommand = { ...command };
        newCommand.mensagem = newCommand.mensagem + emojiObject.emoji;
        setCommand(newCommand);
    }

    function setListOnCommand(list) {
        let newCommand = { ...command };
        newCommand.list = list;
        setCommand(newCommand);
    }


    const actions = {
        "Pergunta com Variável": <>

            <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji} />
            <Variavel command={command} setCommand={setCommand} selectedFlow={selectedFlow} isOnEmoji={isOnEmoji} flows={flows} groups={groups} selectActions={selectActions} variables={variables} saveCommand={saveCommand} />
        </>,
        "Pergunta Descritiva": <>

            <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji} />
            <Descritiva command={command} setCommand={setCommand} selectedFlow={selectedFlow} flows={flows} groups={groups} selectActions={selectActions} insertOption={insertOption} addWord={addWord} saveCommand={saveCommand} />

        </>,
        "Resposta Simples": <>
            <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji} />

            <Simples command={command} setCommand={setCommand}
                flows={flows} groups={groups} selectedFlow={selectedFlow}
                selectActions={selectActions} saveCommand={saveCommand} />
        </>,
        "Pergunta Alternativa": <>
            <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji} turnIntoList={true} setList={setListOnCommand} />
            <Alternativa command={command} setCommand={setCommand} selectedFlow={selectedFlow} isOnEmoji={isOnEmoji} flows={flows} groups={groups} setIsOnEmoji={setIsOnEmoji} selectActions={selectActions} insertOption={insertOption} saveCommand={saveCommand} />
        </>,
        "Agendamento": <>
            <Agendamento command={command} setCommand={setCommand} selectedFlow={selectedFlow} flows={flows} groups={groups} selectActions={selectActions} saveCommand={saveCommand} />
        </>,
        // "Listagem": <>
        //     <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji} />
        //     <Listagem command={command} setCommand={setCommand} selectedFlow={selectedFlow} flows={flows} groups={groups} selectActions={selectActions} saveCommand={saveCommand} />
        // </>,
    }

    let newCommand = typeModal === "newCommand";

    return <div>
        <div>

            <h4 style={{ marginBottom: 20 }}> {selectedCommand.index + 1}º {selectedCommand.tipo} - {selectedCommand.nome} </h4>

            <div onClick={() => props.closeModal()} style={{
                cursor: "pointer",
                position: "absolute",
                right: 22,
                top: 12,
                fontSize: 23,
                color: "white",
                backgroundColor: 'rgb(181, 52, 52)',
                height: 38,
                width: 38,
                borderRadius: 5,

            }} >
                <FaTimes />
            </div>
        </div>
        <div name="row" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }}>
            <h5 style={{ margin: 5 }} className="required">Nome do Comando:</h5>
            <input type="text" className="bot-form-input-flow" style={{ margin: "0px 0px 0px 0px", width: "50%" }} value={command.nome} placeholder={"Digite aqui o nome do comando"} onChange={(e) => changeName(e)} />

        </div >
        {

            actions[command.tipo] ? actions[command.tipo] : null
        }
        <button className="flow-button" title="Clique para salvar o comando" style={{ margin: '15px 0px 0px 0px', background: "rgb(0, 129, 167)" }} onClick={() => saveCommand()}> Salvar </button>
        <button
            className="flow-button flow-close-btn"
            title={"Clique para voltar"}
            style={{ margin: "10px 0px 0px 0px", background: "#b53434" }}
            onClick={newCommand ? () => { props.undoCommand(); props.closeModal(); } : () => { props.closeModal() }}>
            Voltar
        </button>
    </div>

}




// : command.tipo === "Enquete" ? <>

// <MytextArea changeFile={changeFileMesage} onkeypress={onkeypress} changeMessage={changeMessage} command={command} addFileToMessageEmoji={addFileToMessageEmoji}/>

// <button className="flow-button insert-btn" title="Clique para adicionar uma alternativa" style={{ margin: "10px 0px", background: "#2a9d8f" }} onClick={() => insertOption(command.tipo)}> Inserir Opções de Voto </button>

// <div className='flow-form-alternative-container'>
//     {command.alternativas.map((option, index) => {
//         return (
//             <div className="flow-form-vote-opt" key={index} >
//                 <input type="text" style={{ flex: 1 }} className="flow-form-input" value={command.alternativas[index].valor} onChange={(e) => { changeAlternative(e, index, "valor"); changeAlternative(e, index, "next") }} />
//                 <FaTrash className="del-flow-icon" title="Deletar Alternativa" style={{ margin: 5, fontSize: 25 }} onClick={() => deleteAlternative(index)} />
//             </div>
//         )
//     })}
// </div>

// <button className="flow-button flow-save-btn" disabled={props.loading} title="Clique para salvar o comando" style={{ margin: 0, background: "rgb(0, 129, 167)" }} onClick={() => saveCommand()}> Salvar </button>

// </> 