import "../../../chat.css";
import { UserContext } from "../../../../../contexts/UserProvider";
import { useContext, useEffect, useState } from "react";

export const MenuMsgsRapidas = ({ msgBoxIconsWidth, message, setMessage, isMenuMsgsRapidasOpen, setIsMenuMsgsRapidasOpen, checkEnter }) => {
  const { msgsRapidasUsuario } = useContext(UserContext);
  const [msgsRapidas, setMsgsRapidas] = useState([]);
  const [filteredMsgsRapidas, setFilteredMsgsRapidas] = useState([]);

  useEffect(() => {
    const novasMsgsRapidas = msgsRapidasUsuario ? msgsRapidasUsuario : [];
    setMsgsRapidas(novasMsgsRapidas);
  }, [msgsRapidasUsuario]);

  useEffect(() => {
    let msg;
    const firstChar = message.charAt(0);
    if (firstChar === "/") {
      msg = message.slice(1);
      const novasMsgsRapidas = msgsRapidas.filter((m) => m.atalho.includes(msg));
      setFilteredMsgsRapidas(novasMsgsRapidas);
    }
  }, [message, msgsRapidas]);

  const setMsgToSend = (m) => {
    setMessage(m.mensagem);
    setIsMenuMsgsRapidasOpen(false);
  };

  return (
    <div
      className={`menu-msgs-rapidas${!isMenuMsgsRapidasOpen ? "" : "-open"}`}>
      {filteredMsgsRapidas.length === 0 ? <p className="menu-msgs-rapidas-no-item">Nenhuma mensagem encontrada</p> : null}
      {filteredMsgsRapidas.map((m, i) => {
        return (
          <div
            key={i + 1}
            className="menu-msgs-rapidas-item"
            onClick={() => setMsgToSend(m)}
            onKeyDown={(e) => checkEnter(e, m)}
            onKeyUp={(e) => checkEnter(e, m)}>
            <p className="menu-msgs-rapidas-item-atalho">/{m.atalho}</p>
            <p className="menu-msgs-rapidas-item-text" title={m.mensagem}>{m.mensagem}</p>
          </div>
        );
      })}
    </div>
  );
};
