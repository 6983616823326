import React from "react";
// import { BiPhone } from "react-icons/bi";
import { BiUser } from "react-icons/bi";

const Nome = (props) => {
  return (
    <div className="login-label">
      <label htmlFor="nome">Nome</label>
      <div className="login-input-content">
        <input
          name="nome"
          className="login-input"
          type="text'"
          onChange={(event) => props.setInputs("nome", event.target.value)}
        />
        {/* <BiPhone className="login-icon" /> */}
        <BiUser className="login-icon" />
      </div>
    </div>
  );
};

export default Nome;
