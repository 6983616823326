import React, { useState } from "react";
import { useContext } from "react";
import { PesqPrContext } from "../../../../contexts/ChatBotProvider/PesqPrProvider";
import Services from "../Forms/BotServices/botservices";
import Messages from "../Forms/BotMessages/botmessages";
import Comands from "../Forms/BotComands/botcomands";
import Agenda from "../Forms/BotAgendas/botAgendas";
import "./botForm.css";

export default function BotForm(props) {
  const [bot, setBot] = useState(structuredClone(props.bot));
  const [defaultMsg, setDefaultMsg] = useState({ title: "", message: "" });
  const pesqPrContext = useContext(PesqPrContext);
  const [pesquisas, setPesquisas] = useState(
    pesqPrContext.pesquisas ? [...pesqPrContext.pesquisas] : []
  );
  const [flows, setFlows] = useState(structuredClone(props.flows));
  const [currentComponent, setCurrentComponent] = useState("Services");

  const component = {
    Services: (
      <Services
        bot={bot}
        setBot={setBot}
        defaultMsg={defaultMsg}
        setDefaultMsg={setDefaultMsg}
        flows={flows}
        setFlow={setFlows}
        pesquisas={pesquisas}
        setPesquisas={setPesquisas}
        pesqPrContext={pesqPrContext}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        changeComponent={changeComponent}
        saveBot={saveBot}
        closeModal={props.closeModal}
      />
    ),

    Messages: (
      <Messages
        bot={bot}
        setBot={setBot}
        pesquisas={pesquisas}
        setpesquisas={setPesquisas}
        defaultMsg={defaultMsg}
        setDefaultMsg={setDefaultMsg}
        loading={props.loading}
        saveBot={saveBot}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        changeComponent={changeComponent}
        closeModal={props.closeModal}
        alert={props.alert}
      />
    ),

    Comands: (
      <Comands
        bot={bot}
        setBot={setBot}
        flows={flows}
        setFlow={setFlows}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        changeComponent={changeComponent}
        saveBot={saveBot}
        closeModal={props.closeModal}
      />
    ),

    Agenda: (
      <Agenda
        bot={bot}
        setBot={setBot}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        changeComponent={changeComponent}
        saveBot={saveBot}
        closeModal={props.closeModal}
      />
    ),
  };

  function changeComponent(componentName) {
    setCurrentComponent(componentName);
  }

  function itsRepeatedName(bot) {
    let bots = [...props.bots];
    let repeated = false;
    bots.forEach((i) => {
      if (i.nome === bot.nome.trim() && bot.nome !== props.bot.nome)
        repeated = true;
    });
    return repeated;
  }

  function saveBot(e) {
    e.preventDefault();
    if (props.hasEmptyField(bot) === 0) {
      if (!itsRepeatedName(bot)) {
        props.saveBot(bot);
      } else {
        props.alert("normal", {
          title: "Ops!",
          placeholder: "Nome já existente! Por favor, escolha outro.",
        });
      }
    } else if (props.hasEmptyField(bot) <= 2 && !bot.oficial) {
      props.saveBot(bot);
    } else {
      props.alert("normal", {
        title: "Ops!",
        placeholder: "Por favor, preencha os campos vazios!",
      });
    }
  }

  return (
    <>
      <div className="bot-form">{component[currentComponent]}</div>
    </>
  );
}
