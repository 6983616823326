import React from "react";

export default function ImageUser() {
  return (
    <div className="header-edit-image">
      <img
        src={"https://www.w3schools.com/howto/img_avatar.png"}
        alt="foto usuario"
        className="contact-pic-img-contato"
      />
      <p>Foto do contato</p>
    </div>
  );
}
