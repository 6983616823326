import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
const MessageContext = createContext();

const MessageProvider = ({ children }) => {

    const [onSearch, setOnSearch] = useState(false);
    const [lookingMessage, setLookingMessage] = useState(null);

    const location = useLocation();

    useEffect(() => {
        setOnSearch(false);
        setLookingMessage(null)
    }, [location]);

    return (
        <MessageContext.Provider value={{ onSearch, setOnSearch, lookingMessage, setLookingMessage }}>
            {children}
        </MessageContext.Provider>
    );
};

export { MessageProvider, MessageContext };