import React from "react";
import { BsX } from "react-icons/bs";
import "./modalDeleteGroup.css";

export default function ModalDeleteGroup({
  TitleModal,
  SubtitleModal,
  onClick,
  onClose,
}) {
  return (
    <div className="overlay">
      <div className="modal-content-group-delete">
        <button className="fechar" onClick={onClose}>
          <BsX size={40} color="#9AC31C" />
        </button>
        <div className="container-header-modal">
          <div className="container-align-text-modal-delete">
            <img src="imgs/iconDeleteGroup.svg" alt="icon" />
            <h3>{TitleModal}</h3>
          </div>
          <div className="container-align-text-modal-delete-subtitle">
            <p>{SubtitleModal}</p>
          </div>
          <div className="container-buttons-delete-modal">
            <div className="add-footer-wtz">
              <button className="cancel-button" onClick={onClose}>
                Cancelar
              </button>
              <button className="save-button" onClick={onClick}>
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
