import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


const Graph = ({ data }) => {
    const { attsPendentes, attsFinalizados, attsOn } = data;

    const chartData = {
        labels: ['Pendente', 'Em atendimento', 'Finalizado'],
        datasets: [
            {
                data: [attsPendentes, attsOn, attsFinalizados],
                backgroundColor: ['#F2B71F', '#142a4c', '#9ac31c'],
                hoverBackgroundColor: ['#F2B71F', '#142a4c', '#9ac31c'],
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                    font: {
                        size: 16,
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        maxHeigth: 200,
        
    }
    return (
        <div className='grafic-box'>
            <Pie data={chartData} options={options} />
        </div>
    )
}

export default Graph
