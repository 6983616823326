import React from 'react'
import { BsCheck2Circle, BsAlarm, BsArrowRepeat } from 'react-icons/bs'

const Cards = ({data}) => {
    const cardsData = [
        {
            icon: <BsCheck2Circle size={30} />,
            title: "Atendimentos finalizados",
            count: data.attsFinalizados,
        },
        {
            icon: <BsAlarm size={30} />,
            title: "Atendimentos pendentes",
            count: data.attsPendentes,
        },
        {
            icon: <BsArrowRepeat size={30} />,
            title: "Atendimentos em andamento",
            count: data.attsOn,
        },
    ];
  return (
    <div className='cards-box'>
       {cardsData.map((card, index) => (
                <div key={index} className='dash-header-card' style={{width:'100%', justifyContent:'center'}}>
                    <div className="header-card-wrapper" style={{border:'1px solid #f5f5f5',padding:'10px'}}>
                        <div className='header-icon' style={{ backgroundColor: index === 1 || index === 3 ? '#9ac31c' : null }}>
                            {card.icon}
                        </div>
                        <div className="header-info">
                            <h4>{card.count}</h4>
                            <span>{card.title}</span>
                        </div>
                    </div>
                </div>
            ))}
    </div>
  )
}

export default Cards
