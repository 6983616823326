import { useState } from "react";
import { SwitchToggle } from "../SwitchToggle";

export const Confirmado = (props) => {
  const [toggleType, setToggleType] = useState(true);
  const onChange = (state) => {
    setToggleType(!state);
  };

  const {
    view,
    setView,
    handleCancelServicoConfig,
    salvarServicoConfig,
    onChangeMensagem,
    mensagemConfirmado,
  } = props;
  return (
    <>
      <legend>Mensagem Confirmado</legend>
      <SwitchToggle
        label={toggleType ? "Bot" : "E-mail"}
        isToggled={toggleType}
        onClick={onChange}
        toggleType="mensagemConfirmado"
      />
      <div className="servico-form-content">
        <div className="servico-form-input-container-avisos">
          <label htmlFor="mensagemConfirmado">Mensagem Confirmado</label>
          {toggleType ? (
            <textarea
              className="servico-form-textarea"
              name="mensagemConfirmado"
              id="mensagemConfirmado"
              cols="100"
              rows="5"
              value={mensagemConfirmado}
              onChange={onChangeMensagem}
            />
          ) : (
            "editor de text email confirmado"
          )}
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="prev" onClick={() => setView(view - 1)}>
          Anterior
        </button>
        <button type="button" id="next" onClick={() => setView(view + 1)}>
          Próximo
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
};
