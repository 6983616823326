import React, { useState } from "react";
import TempoDeEspera from "./Tempo de Espera";
import TempoDeAtendimento from "./Tempo de Atendimento";
import DatePicker from "./general/DatePicker";
import "./style.css";

export default function Relatorios() {
  const [view, setView] = useState("tempo de espera");
  const [date, setDate] = useState({});
  const views = {
    "tempo de espera": <TempoDeEspera date={date} />,
    "tempo de atendimento": <TempoDeAtendimento date={date} />,
  };
  const labels = ["Grupos", "Individual"];
  return (
    <div>
      <div style={{ margin: 8, height: "100%" }}>
        {Object.keys(views).map((key, index) => {
          return (
            <button
              key={index}
              className={
                view === key
                  ? "second-button-view btn-normal"
                  : "second-button-view btn-selected"
              }
              onClick={() => setView(key)}
            >
              {key}
              <br />
              {labels[index] ? "( " + labels[index] + " )" : ""}
            </button>
          );
        })}
        <DatePicker date={date} setDate={setDate} />
        {views[view]}
      </div>
    </div>
  );
}
