import React from "react";

const InfoItemSelect = ({ label, value, options, onChange }) => {
  return (
    <div className="contacts-unique">
      <p>{label}</p>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="">Nenhum</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InfoItemSelect;
