import "./styles.css";

export const Item = ({ itemKey, item, itemFunction }) => {
  if (!item[itemKey.key] || Object.keys(item[itemKey.key]).length === 0 || item[itemKey.key].length === 0) {
    return null;
  }

  return (
    <div className="accordion-item-container">
      {Array.isArray(item[itemKey.key]) ? (
        itemKey.splitArray === true || itemKey.splitArray === undefined || itemKey.splitArray === null ? (
          item[itemKey.key].map((subItem, subIndex) => (
            <p key={subIndex}>
              <strong>
                {itemKey.label} {subIndex + 1}:&nbsp;
              </strong>
              {Object.entries(subItem)
                .map(([key, value]) => {
                  const subKey = itemKey.subKeys?.find((subKey) => subKey.key === key);
                  return `${subKey ? subKey.label : key}: ${Array.isArray(value) ? value.join(", ") : value}`;
                })
                .join(" | ")}
            </p>
          ))
        ) : itemKey.function ? (
          itemFunction(item)
        ) : (
          <p>
            <strong>{itemKey.label}:&nbsp;</strong>
            {item[itemKey.key].join(", ")}
          </p>
        )
      ) : typeof item[itemKey.key] === "object" && item[itemKey.key] !== null ? (
        <p>
          <strong>{itemKey.label} - </strong>
          {Object.entries(item[itemKey.key])
            .map(([key, value]) => {
              const subKey = itemKey.subKeys?.find((subKey) => subKey.key === key);
              return `${subKey ? subKey.label : key}: ${value}`;
            })
            .join(" | ")}
        </p>
      ) : (
        <p>
          <strong>{itemKey.label}: </strong> {item[itemKey.key]}
        </p>
      )}
    </div>
  );
};
