import { useState } from "react";
import MainView from "../main/MainView";
import BigView from "../main/BigView";

import { Local } from "./Local";
import { Servico } from "./Servico";
import { Especialidade } from "./Especialidade";
import { Profissional } from "./Profissional";
import { Agendas } from "./Agendas";
import { Cadastro } from "./Cadastro";
// import Relatórios from "./Relatórios";

export const Agenda = () => {
  const [onShow, setOnShow] = useState("Profissionais");

  const components = {
    Profissionais: <Profissional />,
    Especialidades: <Especialidade />,
    Locais: <Local />,
    Serviços: <Servico />,
    Agendas: <Agendas />,
    Cadastro: <Cadastro />,
    // Relatórios: <Relatórios />,
  };

  return (
    <MainView title="Ferramenta de Agendamento">
      <BigView>
        <div className="button-view-container">
          {Object.keys(components).map((key, index) => {
            return (
              <button
                key={index}
                className={"button-view"}
                style={
                  onShow === key
                    ? {
                        borderBottom: "1px solid #9ac31c",
                        color: "#9ac31c",
                        fontWeight: "bold",
                      }
                    : null
                }
                onClick={() => setOnShow(key)}
              >
                {key}
              </button>
            );
          })}
        </div>
        {components[onShow]}
      </BigView>
    </MainView>
  );
};
