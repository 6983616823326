import React from "react";
import { BsX } from "react-icons/bs";
import HeaderXLS from "./components/HeaderXLS/HeaderXLS";
import RenderBody from "./components/RenderBody/RenderBody";
import Button from "../HeaderContact/components/Button/Button";
import ApiUsers from "../../../../ApiUsers";
import "./modalXLS.css";

export default function ModalXLS({ importedNumbers, setShowModal }) {
  // Função para remover números duplicados da lista
  function removeDuplicateNumbers(numbers) {
    const uniqueNumbers = [];
    const encounteredNumbers = [];
    numbers.forEach((number) => {
      const phoneNumber = number.telefone;
      if (!encounteredNumbers.includes(phoneNumber)) {
        encounteredNumbers.push(phoneNumber);
        uniqueNumbers.push(number);
      }
    });
    return uniqueNumbers;
  }

  async function saveImportedNumbers() {
    const uniqueNumbers = removeDuplicateNumbers(importedNumbers);
    ApiUsers.post("contatos/adicionarVarios", {
      contatos: uniqueNumbers,
    })
      .then((res) => {
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="show-form">
      <div className="contact-card-xls">
        <HeaderXLS />
        <RenderBody importedNumbers={importedNumbers} />
        <BsX className="close-icon" onClick={() => setShowModal(false)} />
        <div className="container-button-xls">
          <Button text="Salvar" onClick={saveImportedNumbers} />
        </div>
      </div>
    </div>
  );
}
