import "./style.css";

export const FiltroAgenda = ({
  profissionais,
  especialidades,
  locais,
  servicos,
  setFiltroEspecialidade,
  setFiltroLocal,
  setFiltroProfissional,
  setFiltroServico,
  setItemsPerPage,
  itemsPerPage,
  setCurrentPage,
  setActivePage,
}) => {
  const handleFiltroProfissional = (e) => setFiltroProfissional(e.target.value);

  const handleFiltroEspecialidade = (e) =>
    setFiltroEspecialidade(e.target.value);

  const handleFiltroLocal = (e) => setFiltroLocal(e.target.value);

  const handleFiltroServico = (e) => setFiltroServico(e.target.value);

  const handleFiltroPorPagina = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
    setActivePage(1);
  };

  return (
    <>
      <fieldset className="agendas-fieldset">
        <div className="agendas-legend-container">
          <legend className="agendas-legend">
            <span>Opções de Filtro</span>
          </legend>
          <div className="filter">
            <label className="filter-label" htmlFor="items-filter">
              Agendamentos por página:{" "}
            </label>
            <select
              className="filter-select"
              id="items-filter"
              onChange={handleFiltroPorPagina}
              value={itemsPerPage}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
        <div className="filters-container">
          <div className="filter">
            <label className="filter-label" htmlFor="professional-filter">
              Profissional:{" "}
            </label>
            <select
              className="filter-select"
              id="professional-filter"
              onChange={handleFiltroProfissional}
            >
              <option value="">Todos</option>
              {profissionais.map((professional) => (
                <option key={professional._id} value={professional._id}>
                  {professional.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label className="filter-label" htmlFor="specialty-filter">
              Especialidade:{" "}
            </label>
            <select
              className="filter-select"
              id="specialty-filter"
              onChange={handleFiltroEspecialidade}
            >
              <option value="">Todas</option>
              {especialidades.map((especialidade) => (
                <option key={especialidade._id} value={especialidade._id}>
                  {especialidade.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label className="filter-label" htmlFor="location-filter">
              Local:{" "}
            </label>
            <select
              className="filter-select"
              id="location-filter"
              onChange={handleFiltroLocal}
            >
              <option value="">Todos</option>
              {locais.map((local) => (
                <option key={local._id} value={local._id}>
                  {local.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label className="filter-label" htmlFor="service-filter">
              Serviço:{" "}
            </label>
            <select
              className="filter-select"
              id="service-filter"
              onChange={handleFiltroServico}
            >
              <option value="">Todos</option>
              {servicos.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.nome}
                </option>
              ))}
            </select>
          </div>
        </div>
      </fieldset>
    </>
  );
};
