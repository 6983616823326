import React from "react";

export const CheckBox = ({ label, value, setValue, inputKey, type, subKeys, checkBoxes }) => {
  const onChange = (checked, checkBox) => {
    let newValue = [...value];
    if (checked) {
      newValue.push(checkBox);
    } else {
      newValue = newValue.filter((d) => d !== checkBox);
    }
    setValue((prev) => ({ ...prev, [inputKey]: newValue }));
  };

  const onChangeObject = (checked, checkBox) => {
    const checkboxKey = type === "object" && subKeys.find((subKey) => subKey.inputType === "checkbox").key;
    let newValue = { ...value };
    let newCheckedValues = [...value[checkboxKey]];
    if (checked) {
      newCheckedValues = [...newCheckedValues, checkBox];
    } else {
      newCheckedValues = newCheckedValues.filter((d) => d !== checkBox);
    }
    newValue[checkboxKey] = newCheckedValues;
    setValue((prev) => ({ ...prev, [inputKey]: { ...prev[inputKey], [checkboxKey]: newCheckedValues } }));
  };

  return (
    <>
      {type === "object" ? (
        <div className="qsg-agenda-form-input-container obj">
          {subKeys.map(
            (subKey, index) =>
              subKey.inputType === "checkbox" && (
                <div key={index}>
                  <label htmlFor={subKey.key}>{subKey.label}</label>
                  <div className="checkbox-container">
                    {checkBoxes.map((checkBox, index) => (
                      <React.Fragment key={index}>
                        <input
                          type="checkbox"
                          checked={(value && value[subKey.key] && value[subKey.key].some((e) => e === checkBox.index)) || false}
                          value={checkBox}
                          onChange={(e) => onChangeObject(e.target.checked, checkBox.index)}
                          id={subKey.key}
                          name={checkBox.index}
                        />
                        <label htmlFor={subKey.key}>{checkBox.nome}</label>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <div className="qsg-agenda-form-input-container">
          <label htmlFor={inputKey}>{label}</label>
          {checkBoxes.map((checkBox, index) => (
            <div key={index}>
              <input
                type="checkbox"
                checked={(value && value.some((e) => e === checkBox.index)) || false}
                value={checkBox}
                onChange={(e) => onChange(e.target.checked, checkBox.index)}
                id={inputKey}
                name={inputKey}
              />
              <label htmlFor={inputKey}>{checkBox.nome}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
