import { useState, useEffect } from 'react'
import { BsPlusSquareFill, BsPen, BsEmojiSmileFill } from "react-icons/bs";
import EmjPick from "../../../../Chats/general/Messagersender/Pieces/EmjPick";

const MsgRapidaModal = ({ msgRapidaToEdit, closeMsgRapidaForm, handleSaveMsgRapida }) => {
    const [msgRapida, setMsgRapida] = useState({})
    const [onEmoji, setOnEmoji] = useState(false);
    const isSameMsgRapida = msgRapida?.atalho === msgRapidaToEdit?.atalho && msgRapida?.mensagem === msgRapidaToEdit?.mensagem;

    useEffect(() => {
        let newMsgRapida = msgRapidaToEdit ? { ...msgRapidaToEdit } : {atalho: '', mensagem: ''}
        setMsgRapida(newMsgRapida)
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        const firstChar = value.charAt(0);
        if (firstChar === "/") {
            return;
        }
        setMsgRapida({ ...msgRapida, [name]: value });
    };

    return (
        <div className='msgrapida-modal'>
            <div className="msgrapida-modal-container">
                {onEmoji && <EmjPick onEmoji={onEmoji} mensagemRapida={msgRapida.mensagem ? msgRapida.mensagem : ''} isMsgRapidaFormOpen={true} setMensagemRapida={(m) => { setMsgRapida({ ...msgRapida, mensagem: m }) }} setOnEmoji={setOnEmoji} />}
                <div className="msgrapida-modal-header">
                    <div className="msgrapida-modal-header-title">
                        {msgRapidaToEdit?.atalho ? <BsPen color={'#9ac31c'} size={20} /> : <BsPlusSquareFill color={'#9ac31c'} size={20} />}
                        <h3>{msgRapidaToEdit?.atalho ? 'Editar' : 'Adicionar'} mensagem rápida</h3>
                    </div>
                    <span onClick={closeMsgRapidaForm}>X</span>
                </div>
                <div className="msgrapida-modal-body">
                    <h4>Crie atalhos para as mensagens enviadas com frequência. Para usá-las nas conversas, digite "/" e selecione o atalho da lista.</h4>
                    <div className="msgrapida-modal-form">
                        <div className="msgrapida-modal-form-item">
                            <label htmlFor="atalho">Atalho</label>
                            <input type="text" name="atalho" value={msgRapida.atalho ? msgRapida.atalho : ''} onChange={handleChange} />
                        </div>
                        <div className="msgrapida-modal-form-item">
                            <label htmlFor="mensagem">Mensagem</label>
                            <div className="msgrapida-modal-form-item-box">
                                <textarea name="mensagem" value={msgRapida.mensagem ? msgRapida.mensagem : ''} onChange={handleChange} />
                                <BsEmojiSmileFill
                                    title="Emoji"
                                    className="msgrapida-emoji-icon"
                                    onClick={() => {
                                        setOnEmoji(!onEmoji);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="msgrapida-modal-form-buttons">
                        <button className='msgrapida-button-cancel' onClick={closeMsgRapidaForm}>Cancelar</button>
                        <button className='msgrapida-button-save' onClick={()=>handleSaveMsgRapida(msgRapida)} disabled={isSameMsgRapida || Object.values(msgRapida).some((v) => v === "")}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MsgRapidaModal
