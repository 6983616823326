import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./UserProvider";
import ApiUsers from "../ApiUsers";
import ApiCronos from "../ApiCronos";

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const { pref, user } = useContext(UserContext);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const [sala, setSala] = useState({});
  // const linkMinhaSala = `http://localhost:3001/${sala?.link}`;
  const linkMinhaSala = `https://tele.voxcity.com.br/${sala?.link}`;

  const criarSala = async (sala) => {
    await ApiUsers.post("/videoConferencia/criarSala", { sala })
      .then((res) => {
        if(res.data.error) return console.log(res.data.error)
        setSala(res.data.sala);
        getSala();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editarSala = async (sala) => {
    await ApiUsers.post("/videoConferencia/editarSala", { sala })
      .then(() => {
        setSala(sala);
        setAlert({
          visible: true,
          type: "sucess",
          props: {
            title: "Sucesso",
            placeholder: "Sala editada com sucesso!",
          },
        });
        getSala();
      })
      .catch((err) => {
        setAlert({
          visible: true,
          type: "error",
          props: {
            title: "Erro!",
            placeholder: err.response.data.error,
          },
        });
      });
  };

  const enviarConviteEmail = async (convite) => {
    await ApiCronos.post("/enviarConviteReuniao", { convite })
      .then(() => {
        setAlert({
          visible: true,
          type: "sucess",
          props: {
            title: "Sucesso",
            placeholder: "Convite enviado com sucesso!",
          },
        });
      })
      .catch((err) => {
        console.log(err.data);
        setAlert({
          visible: true,
          type: "error",
          props: {
            title: "Erro!",
            placeholder: err.response.data.error,
          },
        });
      });
  };

  const getSala = async () => {
    await ApiUsers.post("/videoConferencia/getSala", {
      prefeitura: pref.dbname,
      administrador: user._id,
    })
      .then((res) => {
        if (!res.data) {
          const linkSala = user.nome.toLowerCase().replace(/ /g, "-");
          console.log(res.data);
          criarSala({
            link: linkSala,
            administrador: user._id,
            nome: user.nome,
          });
          return;
        }
        setSala(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUrl = async (sala) => {
    await ApiUsers.post("/videoConferencia/editUrl", { sala })
      .then((res) => {
        setSala(res.data.sala);
        getSala();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const criarReuniao = async (reuniao) => {
    await ApiUsers.post("/videoConferencia/novaReuniao", {
      reuniao,
      salaId: sala._id,
    })
      .then((res) => {
        // const roomId = res.data.sala._id;
        // setConferenceRooms([...conferenceRooms, res.data.sala]);
        // socket.emit("create-room", roomId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRoom = async (sala) => {
    await ApiUsers.post("/videoConferencia/delete", { sala })
      .then(() => {
        setConferenceRooms(
          conferenceRooms.filter(
            (salaSelecionada) => sala._id !== salaSelecionada._id
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getSalas = async () => {
  //   await ApiUsers.post("/videoConferencia/getSalas", {
  //     prefeitura: pref.dbname,
  //     administrador: user._id
  //   })
  //     .then((res) => {
  //       setConferenceRoom(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getSalas();
  // }, []);

  useEffect(() => {
    getSala();
  }, []);

  return (
    <VideoContext.Provider
      value={{
        criarReuniao,
        editarSala,
        deleteRoom,
        conferenceRooms,
        setConferenceRooms,
        alert,
        setAlert,
        criarSala,
        editUrl,
        sala,
        enviarConviteEmail,
        linkMinhaSala,
        getSala,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
