import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const transforToDDHHMMSS = (seconds) => {
  if (!seconds) {
    return "00:00:00";
  }
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  let mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  seconds = Math.floor(seconds);

  return `${days ? days + "D" : ""} ${hrs ? hrs + "h" : ""} ${
    mnts ? mnts + "m" : ""
  } ${seconds ? seconds + "s" : ""}`;
};
const imageToDataUrl = (id) => {
  let image = document.getElementById(id);
  let data = image?.toDataURL();
  return data;
};
const formatDate = (date) => date.split("-").reverse().join("/");

// Tempo de atendimento
export const generatePDFsingle = async (id, atendentes, pref, date) => {
  async function imageUrlToBase64(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  const document = atendentes
    .filter((obj) => obj._id === id)
    .map((obj) => {
      return obj;
    });
  const body = [
    {
      text: "Relatório de tempo de atendimento",
      style: "titleHeader",
    },

    {
      image: image,
      width: 80,
      height: 80,
    },

    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },

    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        formatDate(date.start) + " - " + formatDate(date.end),
      ],
      margin: [0, 15, 0, 30],
    },

    document.map((obj) => {
      return [
        {
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  text: obj.nome,
                  style: "title",
                  border: [false, false, false, false],
                  margin: [10, 10, 0, 10],
                },
              ],
            ],
          },
        },

        {
          fillColor: "#F9F9F9",
          table: {
            margin: [0, 20, 20, 20],
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Email",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: obj.email,
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
              [
                {
                  text: "Departamento",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: obj.departamentoNome || "Sem departamento",
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
              [
                {
                  text: "Tempo Médio",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: transforToDDHHMMSS(obj.tempo),
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
              [
                {
                  text: "Total de atendimentos",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: obj.data?.length,
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
            ],
          },
          layout: "noBorders",
        },

        {
          fillColor: "#F9F9F9",
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  text: "Gráfico",
                  style: "titleGraphic",
                  margin: [17, 20, 0, 15],
                },
              ],
              [
                {
                  image: imageToDataUrl(`graph-${obj._id}`),
                  style: "imageGraphic",
                  width: 390,
                  margin: [0, 0, 0, 50],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];
    }),
  ];

  const docDefinition = {
    content: [...body],
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      title: {
        alignment: "left",
        fillColor: "#9AC31D",
        bold: true,
      },
      titleGraphic: { bold: true, alignment: "left" },
      imageGraphic: {
        alignment: "center",
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoUnico.pdf");
};
export const generatePDFtable = async (atendentes, pref, checkedIds, date) => {
  async function imageUrlToBase64(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  const document = atendentes
    .filter((obj) => checkedIds.includes(obj._id))
    .map((obj) => {
      console.log(atendentes);
      return obj;
    });

  const body = [
    {
      text: "Relatório de tempo de atendimento",
      style: "titleHeader",
    },

    {
      image: image,
      width: 80,
      height: 80,
    },

    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },

    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        formatDate(date.start) + " - " + formatDate(date.end),
      ],
      margin: [0, 15, 0, 30],
    },

    {
      table: {
        widths: [100, "auto", 130, 150],
        body: [
          [
            {
              text: "Nome",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [10, 10, 10, 10],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Departamento",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [10, 10, 10, 10],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Tempo Médio",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [10, 10, 10, 10],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Total de atendimentos",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, false, false],
              margin: [10, 10, 10, 10],
            },
          ],
          ...document.map((obj) => {
            return [
              {
                text: obj.nome,
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
              {
                text: obj.departamentoNome || "Sem departamento",
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },

              {
                text: transforToDDHHMMSS(obj.tempo),
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
              {
                text: obj.data?.length,
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
            ];
          }),
        ],
      },
    },
  ];

  const docDefinition = {
    content: [...body],
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      subtitleHeader: {
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        fillColor: "#9AC31D",
        color: "#212121",
        alignment: "center",
        margin: [5, 5, 5, 5],
      },
      tableBody: {
        fontSize: 12,
        fillColor: "#F9F9F9",
        color: "#212121",
        alignment: "center",
        margin: [12, 12, 12, 12],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoTabela.pdf");
};

//Tempo de espera
export const generatePDFsingleTE = async (
  id,
  gruposDeAtt,
  pref,
  date,
  contacts
) => {
  async function imageUrlToBase64(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  const getContactsName = (ids) => {
    let users = [];
    ids.map((user) => {
      const contact = contacts.filter((contact) => user === contact._id);
      if (contact.length) users.push(contact[0].nome);
    });
    return users;
  };

  const document = gruposDeAtt
    .filter((obj) => obj._id === id)
    .map((obj) => {
      console.log(obj);
      return obj;
    });

  const body = [
    {
      text: "Relatório de tempo de espera",
      style: "titleHeader",
    },

    {
      image: image,
      width: 80,
      height: 80,
    },

    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },

    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        formatDate(date.start) + " - " + formatDate(date.end),
      ],
      margin: [0, 15, 0, 50],
    },

    document.map((obj) => {
      return [
        {
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  text: obj.nome,
                  style: "title",
                  border: [false, false, false, false],
                  margin: [10, 10, 0, 10],
                },
              ],
            ],
          },
        },

        {
          fillColor: "#F9F9F9",
          table: {
            margin: [0, 20, 20, 20],
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Usuários",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: getContactsName(obj.usuarios).join(", "),
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
              [
                {
                  text: "Tempo médio de espera",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: obj.espera,
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
              [
                {
                  text: "Total de atendimentos",
                  bold: true,
                  alignment: "left",
                  margin: [17, 20, 0, 15],
                },
                {
                  text: obj.data?.length,
                  alignment: "right",
                  margin: [0, 20, 30, 15],
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  fillColor: "#f0f0f0",
                },
              ],
            ],
          },
          layout: "noBorders",
        },

        {
          fillColor: "#F9F9F9",
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  text: "Gráfico",
                  style: "titleGraphic",
                  margin: [17, 20, 0, 15],
                },
              ],
              [
                {
                  image: imageToDataUrl(`graph-${obj._id}`),
                  style: "imageGraphic",
                  width: 390,
                  margin: [0, 0, 0, 50],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];
    }),
  ];

  const docDefinition = {
    content: [...body],
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      title: {
        alignment: "left",
        fillColor: "#9AC31D",
        bold: true,
      },
      titleGraphic: { bold: true, alignment: "left" },
      imageGraphic: {
        alignment: "center",
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoUnico.pdf");
};
export const generatePDFtableTE = async (
  gruposDeAtt,
  checkedIds,
  pref,
  date,
  contacts
) => {
  async function imageUrlToBase64(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const image = await imageUrlToBase64(
    "https://chat.voxcity.com.br/imgs/VOXLOGOHorizintal.png"
  );

  const getContactsName = (ids) => {
    let users = [];
    ids.map((user) => {
      const contact = contacts.filter((contact) => user === contact._id);
      if (contact.length) users.push(contact[0].nome);
    });
    return users;
  };

  const document = gruposDeAtt
    .filter((obj) => checkedIds.includes(obj._id))
    .map((obj) => {
      return obj;
    });

  const body = [
    {
      text: "Relatório de tempo de espera",
      style: "titleHeader",
    },

    {
      image: image,
      width: 80,
      height: 80,
    },

    {
      text: [{ text: "Organização : ", style: "subtitleHeader" }, pref.nome],
      margin: [0, 15, 0, 0],
    },

    {
      text: [
        { text: "Período : ", style: "subtitleHeader" },
        formatDate(date.start) + " - " + formatDate(date.end),
      ],
      margin: [0, 15, 0, 30],
    },

    {
      table: {
        widths: ["auto", "auto", 100, 100],
        body: [
          [
            {
              text: "Nome",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [0, 15, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Usuários",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [0, 15, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },
            {
              text: "Tempo Médio ",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, true, false],
              margin: [0, 15, 0, 0],
              borderColor: [null, null, "#f1f1f1", null],
            },

            {
              text: "Total ",
              style: "tableHeader",
              alignment: "center",
              border: [false, false, false, false],
              margin: [0, 15, 0, 10],
            },
          ],
          ...document.map((obj) => {
            return [
              {
                text: obj.nome,
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
              {
                text: getContactsName(obj.usuarios).join(", "),
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
              {
                text: obj.espera,
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
              {
                text: obj.data?.length,
                alignment: "center",
                style: "tableBody",
                border: [false, false, true, true],
                borderColor: [null, null, "#f1f1f1", "#f1f1f1"],
              },
            ];
          }),
        ],
      },
    },
  ];

  const docDefinition = {
    content: [...body],
    styles: {
      titleHeader: {
        color: "#212121",
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      subtitleHeader: {
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        fillColor: "#9AC31D",
        color: "#212121",
        alignment: "center",
        margin: [5, 5, 5, 5],
      },
      tableBody: {
        fontSize: 12,
        fillColor: "#F9F9F9",
        color: "#212121",
        alignment: "center",
        margin: [10, 15, 10, 10],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("ResumoTabela.pdf");
};
