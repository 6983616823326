import React from "react";
import "./styles.css";
import DatePickerModal from "./DatePickerModal";

export default function Modal({
  isOpen,
  onClose,
  onConfirm,
  date,
  onChangeDate,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="container">
      <div className="container-masc">
        <div className="container-modal">
          <div className="container-modal-title">DATAS</div>
          <h5 className="text-h5">
            Selecione a data de inicio e fim para baixar a agenda.
          </h5>

          <DatePickerModal date={date} onChange={onChangeDate} />

          <div className="button-container">
            <button id="back" type="button" onClick={onClose}>
              Voltar
            </button>
            <button type="submit" id="add" onClick={onConfirm}>
              Gerar relatório
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
