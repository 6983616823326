import React from "react";

const SaveContainer = ({ onClick }) => {
  return (
    <div className="container-save-button">
      <button className="save-button" onClick={onClick}>
        Salvar
      </button>
    </div>
  );
};

export default SaveContainer;
