import React from 'react'

const index = ({ contato }) => {
  return (
    <div className='contact-info-modal'>
      <div className="contact-info-contander">
        <div className="contact-info-private-item">
          <span>Departamento</span>
          <p>{contato.departamentoNome ? contato.departamentoNome : 'Nenhum'}</p>
        </div>

        <div className="contact-info-private-item">
          <span>Telefone</span>
          <p>{contato.telefone ? contato.telefone : 'Nenhum'}</p>
        </div>

        <div className="contact-info-private-item">
          <span>Setor</span>
          <p>{contato.setorNome ? contato.setorNome : 'Nenhum'}</p>
        </div>

        <div className="contact-info-private-item">
          <span>Ramal</span>
          <p>{contato.ramal ? contato.ramal : 'Nenhum'}</p>
        </div>

      </div>

    </div>
  )
}

export default index
