import React from 'react'

export default function Audio(props) {
    const { data,message,  justi, contactColor, } = props
    // const { 
    let audio = data.audio ? data.audio.url : data.files[0].url
    if (audio && audio.indexOf(".") === 0) audio = audio.replace(".", "")

    return (

        <>
            <audio controls className="audio-message"
                id={data._id} style={{ marginBottom: "10px" }}  >
                <source src={audio} type="audio/mpeg" />
            </audio>
      
                <span style={{ wordBreak: "break-word", alignSelf: justi }}>
                   <a href={audio} download={audio} style={{ color: contactColor, textDecoration: "none" } } target="_blank" rel="noopener noreferrer">
                    Abrir
                    </a>
                </span>
        </>
    )
}
