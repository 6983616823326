import React from "react";
import { BsSearch } from "react-icons/bs";
import "./Inputsearch.css";

export default function InputSearch({ title, onChange }) {
  return (
    <div className="container-search-grupo">
      <input
        type="text"
        placeholder={title}
        style={{ width: "50%" }}
        onChange={onChange}
      />
      <div className="button-search-box-grupo">
        <BsSearch size={20} />
      </div>
    </div>
  );
}
