import { useState, useEffect, useContext } from 'react';
import { PesqPrContext } from '../../../contexts/ChatBotProvider/PesqPrProvider';
import ProtocolosDia from './ProtocolosDia';
import { BsAndroid2, BsPeopleFill, BsHeadset, BsClipboard2PulseFill } from "react-icons/bs";

const Header = ({ pref }) => {
    const { protocolos } = useContext(PesqPrContext);
    const [prefeitura, setPrefeitura] = useState({});
    const [quantidadeProtocolos, setQuantidadeProtocolos] = useState({});
    const [protocolsDayModal, setProtocolsDayModal] = useState(false);
    const [protocolosDia, setProtocolosDia] = useState([]);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const currentDate = getCurrentDate();
        let newProtocolos = protocolos ? [...protocolos] : [];
        let quantidadeProtocolos = newProtocolos.length;
        let protocolosFiltrados = newProtocolos.filter((protocolo) => {
            return protocolo?.entrada?.date?.split('T')[0] === currentDate;
        });
        let quantidadeProtocolosDia = protocolosFiltrados.length;

        setQuantidadeProtocolos({
            quantidadeProtocolos,
            quantidadeProtocolosDia
        });
        setProtocolosDia(protocolosFiltrados);
    }, [protocolos]);

    useEffect(() => {
        let newPref = pref ? { ...pref } : {};
        setPrefeitura(newPref);
    }, [pref]);

    const quantidadeBotsAtivos = prefeitura.services?.voxbot.bots.filter(bot => bot.status === 'ativo').length || 0;
    const quantidadeUsuariosAtendentes = prefeitura.users?.filter(user => user.atendente === true).length || 0;
    const quantidadeGruposdeAtendimento = prefeitura.services?.voxbot.atendentes.length || 0;

    const cardsData = [
        {
            icon: <BsAndroid2 size={30} />,
            title: "Bot",
            count: prefeitura.services?.voxbot.bots.length || 0,
            quantity: quantidadeBotsAtivos,
            plural: quantidadeBotsAtivos !== 1 ? 'Bots ativos' : 'Bot ativo'
        },
        {
            icon: <BsPeopleFill size={30} />,
            title: "Usuários",
            count: prefeitura.users?.length || 0,
            quantity: quantidadeUsuariosAtendentes,
            plural: quantidadeUsuariosAtendentes !== 1 ? 'Usuários atendentes' : 'Usuário atendente'
        },
        {
            icon: <BsHeadset size={30} />,
            title: "Grupos de atendimento",
            count: quantidadeGruposdeAtendimento,
            quantity: quantidadeGruposdeAtendimento,
            plural: quantidadeGruposdeAtendimento !== 1 ? 'Grupos disponíveis' : 'Grupo disponível'
        },
        {
            icon: <BsClipboard2PulseFill size={30} />,
            title: "Protocolos",
            count: quantidadeProtocolos.quantidadeProtocolos || 0,
            quantity: quantidadeProtocolos.quantidadeProtocolosDia || 0,
            link: 'Protocolos do dia',
        }
    ];

    const handleModal = () => {
        setProtocolsDayModal(!protocolsDayModal);
    };

    return (
        <div className='dash-header-wrapper'>
            {cardsData.map((card, index) => (
                <div key={index} className='dash-header-card' onClick={card.title === 'Protocolos' ? handleModal : null}>
                    <div className="header-card-wrapper">
                        <div className='header-icon' style={{ backgroundColor: index === 1 || index === 3 ? '#9ac31c' : null }}>
                            {card.icon}
                        </div>
                        <div className="header-info">
                            <h4>{card.count}</h4>
                            <span>{card.title}</span>
                        </div>
                    </div>
                    <p><span style={{ color: '#9ac31c' }}>{card.quantity}</span> {card.plural || card.link}</p>
                </div>
            ))}
            {protocolsDayModal && <ProtocolosDia protocolos={protocolosDia} handleModal={handleModal} />}
        </div>
    );
};

export default Header;
