import React from "react";

export default function TdTable({ contact }) {
  return (
    <>
      <td>
        <img
          className="img-avatar-contato"
          src={"https://www.w3schools.com/howto/img_avatar.png"}
          alt="Foto"
        />
      </td>

      <td className="td-small">{contact.pushname || "--"}</td>
      <td className="td-min">{contact.telefone || "--"}</td>
      <td className="td-min">{contact.email || "--"}</td>
    </>
  );
}
