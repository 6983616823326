import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../../contexts/UserProvider";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import Privado from "./privado";
import Grupo from "./grupo";
import Atendimento from "./Atendimento";
import AttGrupo from "./AttGrupo";
import "./contactInfo.css";

const ContactInfoNew = ({ showContact, msgType, contact, click, setShowContact}) => {
  const { pref } = useContext(UserContext);
  const [type, setType] = useState("");
  const [contactInfo, setContactInfo] = useState({});
  const isGroup = contact?.usuarios ? true : false;

  const closeContactInfo = () => {
    setType("");
    click();
  };

  const types = {
    privado: <Privado contato={contactInfo} />,
    grupo: <Grupo grupo={contactInfo} />,
    atendimento: <Atendimento atendimento={contactInfo} closeContactInfo={closeContactInfo} setShowContact={setShowContact} />,
    atendimentoGrupo: <AttGrupo grupo={contactInfo} />,
  };

  useEffect(() => {
    if (showContact && contact) {
      let newContact = contact ? { ...contact } : {};
      setContactInfo(newContact);
    }
  }, [contact, showContact]);

  useEffect(() => {
    if (showContact) {
      if (isGroup) {
        setType("grupo");
      } else if (msgType === "att" && pref.services.voxbot.ativo) {
        setType("atendimento");
      } else if (msgType === "private") {
        setType("privado");
      } else if (msgType === "whats-group") {
        setType("atendimentoGrupo");
      }
    } else {
      setType("");
    }
  }, [showContact]);


  return (
    <>
      <div
        className="title-contact-info"
        onClick={() => closeContactInfo()}
        title={`Visualizar informações do ${isGroup ? "grupo" : "usuário"}`}
      >
        <div className="box-img-status">
          <img
            className="title-contact-img"
            src={
              contact.foto
                ? contact.foto
                : isGroup
                  ? "/imgs/groupavatar.png"
                  : "/imgs/avatar2.png"
            }
            alt=""
            onError={(e) => (e.target.src = "/imgs/avatar2.png")}
          />
          {!isGroup && msgType !== "att" && msgType !== 'whats-group' && (
            <div className="contact-info-status">
              <div
                style={{
                  backgroundColor: `${contact.status === "online" ? "#9ac31c" : "rgb(191 191 191)"
                    }`,
                  paddingBottom: 2,
                  borderRadius: 3,
                  width: 50,
                }}
              >
                <span style={{ color: "white" }}> {contact.status} </span>
              </div>
            </div>
          )}
        </div>
        <div
          className="title-contact-name"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <b>
            {Object.keys(contact).length > 0 && (
              <>
                {msgType === "att"
                  ? contact.pushname
                    ? contact.pushname
                    : contact?.telefone?.replace("@c.us", " ").trim()
                  : contact.nome}
              </>
            )}
          </b>
          {contact.email && (
            <span style={{ fontSize: 12, color: "gray" }}>
              {" "}
              {contact.email}{" "}
            </span>
          )}
          {msgType === "att" && (
            <span style={{ fontSize: 12, color: "gray" }}>
              {" "}
              {contact.telefone}{" "}
            </span>
          )}
        </div>

        <div className="arrow-icon-title">
          {type === "" ? (
            <BsChevronRight
              style={{ color: "#9ac31c", cursor: "pointer" }}
              size={30}
            />
          ) : (
            <BsChevronDown
              style={{ color: "#9ac31c", cursor: "pointer" }}
              size={30}
            />
          )}
        </div>
      </div>
      {type !== "" && types[type]}
    </>
  );
};

export default ContactInfoNew;
