import React, { useState, useEffect, useContext } from 'react';
import CheckboxList from '../../inputs/CheckboxUsers/CheckboxList';
import { Form } from 'react-bootstrap';
import { PrefContext } from '../../../../../contexts/ChatBotProvider/PrefProvider';
import ApiUsers from '../../../../../ApiUsers';
import '../Departament.css';

export default function DepartmentListUsers(props) {

    const { departamento } = props;
    const prefContext = useContext(PrefContext);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosOnShow, setUsuariosOnShow] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let users = prefContext.users.filter((u) => {
            if (departamento.usuarios.some(id => id === u._id) && (u.nome !== "Voxcity" && u.ativo)) {
                return u;
            } else {
                return false;
            }
        });
        setUsuarios(users);
        setUsuariosOnShow(users);
    }, [prefContext.departamentos])

    function searchUser(e) {
        let newUsers = [];
        if (e.target.value.trim() !== "") {
            newUsers = usuarios.filter((u) => {
                return u.nome.toLowerCase().includes(e.target.value.toLowerCase());
            })
        } else {
            newUsers = usuarios;
        }
        setUsuariosOnShow(newUsers);
    }

    function selectUser(checked, user) {
        let users = [];
        if (checked) {
            users = [...selectedUsers, user];
        } else {
            users = selectedUsers.filter(u => u._id !== user._id);
        }
        setSelectedUsers(users);
    }

    function selectAll(checked) {
        let users = [];
        if (checked) {
            users = [...usuariosOnShow];
        } else {
            users = []
        }
        setSelectedUsers(users);
    }

    function delUsers() {
        if (selectedUsers.length > 0) {
            setLoading(true);
            console.log(selectedUsers);
            let users = selectedUsers.map((u) => u._id);
            ApiUsers.post("/departamentos/removerVarios", { users, departamento }).then((res) => {
                setSelectedUsers([]);
                setLoading(false);
                props.alert("normal", { title: "Sucesso!", placeholder: "Usuários removidos do departamento com sucesso!" });
            }).catch(err => console.log(err));
        } else {
            props.alert("normal", { title: "Ops!", placeholder: "Selecione ao menos um usuário para remover do departamento!" });
        }
    }

    return <>

        <div className="dept-list-users-container">

            <h2 style={{ textAlign: 'center', margin: "20px 0px" }}> Usuários Departamento {departamento.nome} </h2>

            <Form.Control placeholder={"Digite aqui para pesquisar um usuário do departamento " + departamento.nome} onChange={(e) => searchUser(e)} style={{ width: '70%' }} />

            <br />

            <CheckboxList
                usuarios={usuariosOnShow}
                selectedUsers={selectedUsers}
                action="del"
                delUsers={() => delUsers()}
                selectUser={(checked, user) => selectUser(checked, user)}
                selectAll={(checked) => selectAll(checked)}
                loading={loading}
            />

            <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: 30, width: '100%' }}>

                <button className="dept-edit-back-button" onClick={() => props.goBack()} title="Voltar">
                    Voltar
                </button>

            </div>

        </div>

    </>
}
