import React from 'react'
import Select, { components } from 'react-select';
import { BsCaretDownFill } from "react-icons/bs";

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <BsCaretDownFill color='#9ac31c' />
      </components.DropdownIndicator>
    );
  };
  

const ListSelect = ({data, name, handleInputChange}) => {
    return (
        <div style={{ display: 'inline-block', position: 'relative', width: '47%' }} className='select-filtros-graficos'>
            <Select
                name={name}
                options={data}
                onChange={(e) => handleInputChange(e, name)}
                defaultValue={data[0]}
                closeMenuOnSelect={false}
                components={{ DropdownIndicator }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        border:'1px solid #E0E1E3',
                        borderRadius: '3px',
                        
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: 'white',
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: state.value === 'all' ? 'black' : state.value === 'online' ? '#9ac31c' : '#e74c3c',
                        backgroundColor: state.isFocused ? '#f2f2f2' : 'white',
                        cursor: 'pointer'
                    }),
                }}
            />
        </div>
    )
}

export default ListSelect



