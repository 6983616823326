import { useState, useEffect, useContext } from "react";
import { HorarioMarcado } from "./HorarioMarcado";
import { HorarioDisponivel } from "./HorarioDisponivel";
import { HorarioConfirmado } from "./HorarioConfirmado";
import { CalendarioContext } from "../../../../../contexts/CalendarioProvider";
import { UserContext } from "../../../../../contexts/UserProvider";
import { Alert } from "../../../../miscelaneous/Alert";

export const Content = (props) => {
  const { naoCompareceu,setMarked } = useContext(CalendarioContext);
  const { cidadaoSocket } = useContext(UserContext);
  const [hash, setHash] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [agendaDia, setAgendaDia] = useState({});
  const [allCidadaos, setAllCidadaos] = useState([]);

  useEffect(() => {
    if (cidadaoSocket) {
      initSocket();
    }
    return () => {
      if (cidadaoSocket) {
        socketOff();
      }
    };
  }, [props.agendaDia]);

  useEffect(() => {
    const novaAgendaDia = props.agendaDia ? { ...props.agendaDia } : {};
    setAgendaDia(novaAgendaDia);
  }, [props.agendaDia]);

  useEffect(() => {
    const novaAllCidadaos = props.allCidadaos ? [...props.allCidadaos] : [];
    setAllCidadaos(novaAllCidadaos);
  }, [props.allCidadaos]);

  function initSocket() {
    cidadaoSocket.on("nao comparecer", (data) => {
      setMarked(data);
      const novaDataMarcada = new Date(data.data);
      novaDataMarcada.setHours(novaDataMarcada.getHours() + 3);
      props.updateMarkedDate({
        data: novaDataMarcada.toString(),
        usuario: data.usuario,
        status: data.status,
        hash: data.hash,
        absente: data.absente
        });
    });
  }

  function socketOff() {
    cidadaoSocket.off("nao comparecer");
  }

  const naoCompareceuType = {
    confirm: (
      <Alert
        title={`Não compareceu!`}
        message="Deseja realmente mudar o status para não compareceu?"
        confirmLabel="Confirmar"
        alertType={alertType}
        action={() => naoCompareceu(hash)}
        quit={() => setShowAlert(false)}
      />
    ),
  };


  function submitNaoCompareceu(hash) {
    setHash(hash);
    setShowAlert(true);
    setAlertType("confirm");
  }


  const handleCheckboxChange = (e, dataSelecionada) => {
    const checked = e.target.checked;
    const id = e.target.id;

    if (id === "inputDisponivel") {
      const checkboxes = document.querySelectorAll("input[type='checkbox']");
      checkboxes.forEach((checkbox) => {
        if (checkbox.id !== "inputDisponivel") {
          checkbox.checked = false;
        }
      });
    } else if (id === "inputBloqueado") {
      const checkboxes = document.querySelectorAll("input[type='checkbox']");
      checkboxes.forEach((checkbox) => {
        if (checkbox.id !== "inputBloqueado") {
          checkbox.checked = false;
        }
      });
    } else if (id === "inputMarcado") {
      const checkboxes = document.querySelectorAll("input[type='checkbox']");
      checkboxes.forEach((checkbox) => {
        if (checkbox.id !== "inputMarcado") {
          checkbox.checked = false;
        }
      });
    }
    props.setDatasSelecionadas((prevSelected) =>
      checked
        ? [...prevSelected, dataSelecionada]
        : prevSelected.filter((selected) => selected !== dataSelecionada)
    );
  };

  return (
    <>
      {showAlert && naoCompareceuType[alertType]}
      {agendaDia.funcionamento?.length === 0 &&
        agendaDia.marcadas?.length === 0 && (
          <div className="modal-calendario-content-empty">
            <h2>FORA DO HORÁRIO DE FUNCIONAMENTO</h2>
          </div>
        )}
      {agendaDia.funcionamento?.map((dataAgendada, index) => {
        const dataMarcada = agendaDia.marcadas?.find(
          (dataMarcada) => dataMarcada.data === dataAgendada.data
        );

        if (dataMarcada) {
          if (dataMarcada.status === "Confirmado" || dataMarcada?.absente === true) {
            return (
              <HorarioConfirmado
                key={index}
                dataAgendada={dataAgendada}
                allCidadaos={allCidadaos}
                getCidadao={props.getCidadao}
                agendaDia={agendaDia}
                setAgendaDia={setAgendaDia}
                updateMarkedDate={props.updateMarkedDate}
                submitNaoCompareceu={submitNaoCompareceu}
              />
            );
          } else {
            return (
              <HorarioMarcado
                key={index}
                dataAgendada={dataAgendada}
                allCidadaos={allCidadaos}
                getCidadao={props.getCidadao}
                handleCheckboxChange={handleCheckboxChange}
                submitConfirmarAgendamento={props.submitConfirmarAgendamento}
                submitNaoCompareceu={submitNaoCompareceu}
              />
            );
          }
        } else {
          return (
            <HorarioDisponivel
              key={index}
              dataAgendada={dataAgendada}
              submitMarcarAgendamento={props.submitMarcarAgendamento}
              handleCheckboxChange={handleCheckboxChange}
            />
          );
        }
      })}
    </>
  );
};
