import { useState, useEffect } from "react";
import GroupCard from "./GrupoCard";
import GroupForm from "./GrupoForm";
import ApiUsers from "../../../../ApiUsers";

export default function GrupoIndex(props) {
  const [contacts, setContacts] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  function saveGroup(group) {
    let isNew = group._id ? false : true;
    if (!isNew) {
      ApiUsers.post("/contatos/editarGrupo", { grupo: group }).then((res) => {
        alert("Grupo atualizado com sucesso");
      });
    } else {
      ApiUsers.post("/contatos/novoGrupo", { grupo: group }).then((res) => {
        alert("Grupo criado com sucesso");
      });
    }
  }
  function deleteGroup(group) {
    ApiUsers.post("/contatos/excluirGrupo", { grupo: group }).then((res) => {
      alert("Grupo deletado com sucesso");
    });
  }

  useEffect(() => {
    setContacts(props.contacts);
  }, [props.contacts]);

  useEffect(() => {
    setGrupos(props.grupos);
  }, [props.grupos]);

  return (
    <div className="groups-crud">
      <div className="groups-crud-header">
        <h3>Grupos</h3>
        <button
          className="new-group-btn"
          onClick={(e) => setSelectedGroup({ nome: "", contatos: [] })}
        >
          Novo grupo
        </button>
      </div>
      <div className="groups-card-panel">
        {grupos.map((g) => {
          return (
            <GroupCard
              key={g._id}
              grupo={g}
              deleteGroup={deleteGroup}
              setSelectedGroup={setSelectedGroup}
            />
          );
        })}
      </div>

      {selectedGroup ? (
        <div className="show-form">
          <GroupForm
            grupo={selectedGroup}
            saveGroup={(grup) => {
              saveGroup(grup);
              setSelectedGroup(null);
            }}
            setGrupos={setGrupos}
            grupos={grupos}
            setSelectedGroup={setSelectedGroup}
            contacts={contacts}
            setContacts={setContacts}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
