import React, { useRef } from "react";
import ApiUsers from "../../../../../ApiUsers";
import "./imageGroup.css";

export default function ImageGroup({ form, onChangeForm }) {
  const inputRef = useRef(null);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    ApiUsers.post("/upload", formData)
      .then((resp) => {
        onChangeForm({ target: { name: "image", value: resp.data.url } });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="image-header">
      <img
        className="group-image"
        src={form?.image || "https://www.w3schools.com/howto/img_avatar.png"}
        alt="Foto do grupo"
      />
      <div className="group-upload-image">
        <>
          <img
            src="imgs/editAvatar2.svg"
            alt="upload"
            onClick={handleImageClick}
          />
          <input
            ref={inputRef}
            onChange={handleImageUpload}
            type="file"
            name="myimg"
            id="myimg"
            style={{ display: "none" }}
          />
        </>
      </div>
    </div>
  );
}
