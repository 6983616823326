import React from "react";
import "../../Group.css";
import CheckboxUsersList from "./CheckboxUsersList";
import CheckboxUsersSearch from "./CheckboxUsersSearch";

export default function GroupCheckBox(props) {
  return (
    <>
      <div className="admin-checkbox-container">
        <label className="admin-checkbox-title"> Selecionar usuários: </label>

        <div className="admin-checkbox-header">
          <CheckboxUsersSearch
            departamentos={props.departamentos}
            setores={props.setores}
            filters={props.filters}
            addFilter={(step, filter) => props.addFilter(step, filter)}
          />
        </div>

        <div className="admin-checkbox-users-container">
          <div className="admin-checkbox-users-container-column">
            <CheckboxUsersList
              noUsers={true}
              addUser={(e) => props.addUser(e)}
              delUser={(e) => props.delUser(e)}
              usersOnShow={props.usersOnShow}
              grupo={props.grupo}
              selectedUsers={props.selectedUsers}
              unselectedUsers={props.unselectedUsers}
              selectAll={(e) => props.selectAll(e)}
              unselectAll={(e) => props.unselectAll(e)}
              selectUser={(e, user) => props.selectUser(e, user)}
              unselectUser={(e, user) => props.unselectUser(e, user)}
              addAdmin={(e, user) => props.addAdmin(e, user)}
              loading={props.loading}
            />
          </div>

          <div className="admin-checkbox-users-container-column">
            <CheckboxUsersList
              noUsers={false}
              addUser={(e) => props.addUser(e)}
              delUser={(e) => props.delUser(e)}
              usersOnShow={props.usersOnShow}
              grupo={props.grupo}
              selectedUsers={props.selectedUsers}
              unselectedUsers={props.unselectedUsers}
              selectAll={(e) => props.selectAll(e)}
              unselectAll={(e) => props.unselectAll(e)}
              selectUser={(checked, user) => props.selectUser(checked, user)}
              unselectUser={(checked, user) =>
                props.unselectUser(checked, user)
              }
              addAdmin={(e, user) => props.addAdmin(e, user)}
              loading={props.loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
