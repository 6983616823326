import React from "react";
import HeaderTitle from "../../CreateForm/HeaderTitle";
import MessageDefault from "./components/MessageDefault";
import MessageCard from "./components/MessageCard";
import ButtonsFormMessages from "./components/ButtonsFormMessages";
import "./botMessages.css";

function Messages(props) {
  let defaultSchema = [
    { title: "não entendido", text: "Mensagens de Não Entendido." },
    { title: "fora de hora", text: "Mensagens de Fora de Horário." },
    { title: "novo protocolo", text: "Mensagens de Novo Protocolo" },
    { title: "encaminhamento", text: "Mensagens de Encaminhamento." },
    { title: "pickup", text: "Mensagens de Acolhimento." },
    { title: "pós voto", text: "Mensagens de Pós-voto" },
    { title: "finalização", text: "Mensagens de Finalização." },
    { title: "variaveis", text: "Mensagens de Variáveis." },
    { title: "midía", text: "Mensagens de Midías" },
  ];

  function deleteDefaultMessage(delMsg, title) {
    let newBot = { ...props.bot };
    newBot.config.defaultMessage.forEach((msg) => {
      if (msg.title === title) {
        msg.messages = msg.messages.filter((message) => message !== delMsg);
        props.setBot(newBot);
      }
    });
  }

  function addDefaultMessage(e) {
    e.stopPropagation();
    let newBot = { ...props.bot };

    if (
      props.defaultMsg.title.trim() !== "" &&
      props.defaultMsg.message.trim() !== ""
    ) {
      newBot.config.defaultMessage.forEach((msg) => {
        if (msg.title === props.defaultMsg.title) {
          if (
            msg.messages.some((message) => message === props.defaultMsg.message)
          ) {
            props.alert("normal", {
              title: "Ops!",
              placeholder: "Mensagem já existe, por favor escolha outra",
            });
          } else {
            msg.messages = [...msg.messages, props.defaultMsg.message];
            props.setBot(newBot);
            props.setDefaultMsg({ title: "", message: "" });
          }
        }
      });
    } else {
      props.alert("normal", {
        title: "Ops!",
        placeholder:
          "Por favor, preencha os dados da mensagem padrão antes de salvar!",
      });
    }
  }

  return (
    <>
      <div className="bot-form-five-msg">
        <div className="cont-align-text-buttons">
          <HeaderTitle title="Mensagens Padrão" />
          <ButtonsFormMessages props={props} />
        </div>
        <MessageDefault
          props={props}
          defaultSchema={defaultSchema}
          addDefaultMessage={addDefaultMessage}
        />
        <hr
          style={{
            color: "#d9d9d9",
          }}
        />

        <div className="bot-form-message-container-msg">
          {defaultSchema.map((schema, index) => {
            return (
              <MessageCard
                key={index}
                schema={schema}
                bot={props.bot}
                deleteDefaultMessage={deleteDefaultMessage}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Messages;
