import React, { useEffect,useState } from 'react';
import Picker from 'emoji-picker-react';
import { AiOutlineSmile } from 'react-icons/ai';
import {  FaTrash } from 'react-icons/fa';


function Alternativa(props) {
    const [command, setCommand] = useState(props.command)
    const [selectedFlow, setSelectedFlow] = useState(props.selectedFlow)
    const [isOnEmoji, setIsOnEmoji] = useState([]);
    
    useEffect(() => {
        setCommand(props.command)
        setSelectedFlow(props.selectedFlow)
        setIsOnEmoji(props.isOnEmoji)
    }, [props.command, props.selectedFlow,props.isOnEmoji])

    function deleteAlternative(index) {
        let newCommand = { ...command };
        newCommand.alternativas.splice(index, 1);
        setCommand(newCommand);
    }

    function changeAlternative(e, index, op) {
        let newCommand = { ...command };
        newCommand.alternativas[index][op] = e.target.value;
        if (op === "action") newCommand.alternativas[index].next = "";
        setCommand(newCommand);
    }
    
    function changeButton(e, index, op) {
        let newCommand = { ...command };
        newCommand.alternativas[index][op] = e.target.checked;
        setCommand(newCommand);
    }
    
    return (
        <div>
            <button className='flow-button insert-btn' title="Clique para adicionar uma alternativa" style={{ margin: "10px 0px", background: "#2a9d8f" }} onClick={() => props.insertOption(command.tipo)}> Inserir Resposta </button>

            <div className='flow-form-alternative-container'>
                {command.alternativas.map((alternative, index1) => {
                    return (
                        <div key={index1} className="flow-form-select-item" style={{ display: "flex", alignItems: "center" }}>
                            <input type="text" className='flow-form-input' value={command.alternativas[index1].valor} onChange={(e) => changeAlternative(e, index1, "valor")} style={{ margin: "0px 10px 0px 0px" }} />
                            {
                                isOnEmoji[index1] ?
                                    <Picker groupNames={props.groupNames} onEmojiClick={ (event, emojiObject)=> {
                                        let newCommand = { ...command };
                                        newCommand.alternativas[index1].valor += emojiObject.emoji;
                                        setCommand(newCommand);
                                    }}
                                    pickerStyle={{ width: "100%" }} /> 
                                    : <></>
                            }
                            
                            <AiOutlineSmile  onClick={()=> {
                                let newEmojisScreen =[...isOnEmoji];
                                newEmojisScreen[index1] = !newEmojisScreen[index1];
                                props.setIsOnEmoji(newEmojisScreen);
                            }} style={{ margin: "0px 10px 0px 0px", cursor: "pointer" ,fontSize: "5rem"}} />                            
                            <select className="flow-form-select" style={{ width: "55%" }} value={command.alternativas[index1].action} onChange={(e) => changeAlternative(e, index1, "action")}>
                                <option value={""}>Selecione uma ação</option>
                                {
                                    props.selectActions.map((action, index2) => {
                                        return <option key={index2} value={action.value}> {action.name} </option>
                                    })
                                }
                            </select>
                            <select className="flow-form-select" style={{ width: "55%" }} value={command.alternativas[index1].next} onChange={(e) => changeAlternative(e, index1, "next")} >
                                {
                                    alternative.action === "keep" ? <>
                                        <option value={""}> Selecione uma ação </option>
                                        <option value={"keep"}> Seguir p/ próximo comando </option>
                                        {selectedFlow.comandos.map((c, i) => {
                                            return <option key={i} value={i}> {i + 1}º {c.nome} </option>
                                        })}
                                    </> : <>
                                        <option value={""}> Selecione uma ação </option>
                                        {
                                            alternative.action === "send-to-flow" ? <>
                                                {props.flows.map((f, i) => {
                                                    return <option key={i} value={f._id}> {f.nome} </option>
                                                })}
                                            </> : alternative.action === "send-to-chat" ? <>
                                                {
                                                    props.groups.map((g, i) => {
                                                        return <option key={i} value={g._id}> {g.nome} </option>
                                                    })
                                                }
                                            </> : null
                                        }
                                    </>
                                }
                            </select>
                            <div className="flow-form-buttons">
                                <span> Botão: </span>
                                <input type="checkbox" className="flow-form-checkbox" checked={alternative.button} onChange={(e) => changeButton(e, index1, "button")} />
                            </div>
                            <div>
                                <FaTrash className="del-flow-icon" title="Deletar Alternativa" style={{ margin: "0px 0px 0px 5px" }} onClick={() => deleteAlternative(index1)} />
                            </div>
                        </div>
                    )
                })}
            </div>

        
        </div>
    );
};

export default Alternativa;