import React from "react";
import Select from "react-select";

export function BotEmail(props) {
  const {
    botOptions,
    botSelecionado,
    onChangeSelectBot,
    view,
    setView,
    handleCancelServicoConfig,
    salvarServicoConfig,
    onChangeEnvioMsg,
    envioMsgEmail,
    envioMsgBot,
  } = props;
  return (
    <>
      <legend>Bot e Email</legend>
      <div className="servico-config-form-content">
        <div className="servico-form-input-container">
          <label htmlFor="bots">Bot</label>
          <Select
            name="bots"
            options={botOptions}
            noOptionsMessage={() => "Sem Bots"}
            value={
              botSelecionado
                ? botOptions.find((bot) => bot.value === botSelecionado.value)
                : null
            }
            onChange={onChangeSelectBot}
            placeholder={"Selecione..."}
            isClearable
          />
        </div>
      </div>
      <div className="servico-config-form-content">
        <div className="servico-form-input-container-botemail">
          <div className="servico-form-input-left">
            <label htmlFor="bot">Enviar Mensagens pelo Bot</label>
          </div>
          <div className="servico-form-input-right">
            <input
              type="checkbox"
              name="bot"
              id="bot"
              checked={envioMsgBot}
              value={envioMsgBot}
              onChange={onChangeEnvioMsg}
            />
          </div>
        </div>
        <div className="servico-form-input-container-botemail">
          <div className="servico-form-input-left">
            <label htmlFor="email">Enviar Mensagens pelo Email</label>
          </div>
          <div className="servico-form-input-right">
            <input
              type="checkbox"
              name="email"
              id="email"
              checked={envioMsgEmail}
              value={envioMsgEmail}
              onChange={onChangeEnvioMsg}
            />
          </div>
        </div>
      </div>
      <div className="button-container">
        <button type="button" id="back" onClick={handleCancelServicoConfig}>
          Cancelar
        </button>
        <button type="button" id="next" onClick={() => setView(view + 1)}>
          Próximo
        </button>
        <button type="button" id="add" onClick={salvarServicoConfig}>
          Salvar
        </button>
      </div>
    </>
  );
}
