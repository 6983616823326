import { useEffect, useState, useContext } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import DeleteAlert from "../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import "./style.css";
import { ProfessionalContext } from "../../../../contexts/ProfessionalProvider";

export const EspecialidadeCard = (props) => {
  // const diasSemana = [
  //   { index: 0, nome: "Domingo" },
  //   { index: 1, nome: "Segunda" },
  //   { index: 2, nome: "Terça" },
  //   { index: 3, nome: "Quarta" },
  //   { index: 4, nome: "Quinta" },
  //   { index: 5, nome: "Sexta" },
  //   { index: 6, nome: "Sábado" },
  // ];

  const [especialidade, setEspecialidade] = useState({
    ...props.especialidade,
  });
  const professionalContext = useContext(ProfessionalContext);
  const profissionaisFiltrados = professionalContext.profissionais.filter(
    (profissional) => especialidade.profissionais.includes(profissional._id)
  );
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alertType = {
    delete: (
      <DeleteAlert
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    setEspecialidade({ ...props.especialidade });
  }, [props.especialidade]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props: { ...props } });
  }

  function handleDelete() {
    props.deleteEspecialidade(especialidade);
    quitAlert();
  }

  return (
    <div className="card-especialidade">
      {alert.visible && alertType[alert.type]}
      <div className="card-especialidade-content">
        <div className="card-especialidade-content-left">
          <h3>{especialidade.nome}</h3>
          <p>
            <b>Profissionais:</b>
          </p>
          <ol>
            {profissionaisFiltrados.map((profissional, index) => (
              <li key={index}>{profissional.nome}</li>
            ))}
          </ol>
        </div>
        <div className="card-content-right">
          <FaEdit
            onClick={() => props.editEspecialidade(especialidade)}
            className="edit-especialidade"
            title="Editar Especialidade"
            size={"2rem"}
            style={{ color: "#2a9d8f" }}
          />
          <FaTrash
            className="delete-especialidade"
            size={"2rem"}
            title="Deletar Especialidade"
            style={{ color: "rgb(255, 19, 19)" }}
            onClick={() =>
              createAlert("delete", {
                id: especialidade._id,
                nome: especialidade.nome,
                action: () => handleDelete(),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
