import { useState, useEffect } from "react";
import "./styles.css";

export const Tabela = ({ tableBody, tableHeader, selecteds, setSelected, content }) => {
  const [selectAll, setSelectAll] = useState(false);
  const isListEmpty = tableBody.length === 0;

  const handleCheckboxChange = (item) => {
    if (selecteds.includes(item)) {
      setSelected(selecteds.filter((i) => i._id !== item._id));
    } else {
      setSelected([...selecteds, item]);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (tableHeader[0] === "input") {
      setSelected(selectAll ? [] : tableBody);
    }
  };

  useEffect(() => {
    if (tableHeader[0] === "input") {
      setSelectAll(selecteds.length === tableBody.length);
    }
  }, [selecteds.length, tableBody.length, tableHeader]);

  return (
    <div className="qsg-agenda-profissionais-select-table-list">
      <table className="qsg-agenda-profissionais-select-table">
        <thead>
          <tr>
            {tableHeader.map((header, index) => (
              <th key={index}>
                {header === "input" ? (
                  <input
                    type="checkbox"
                    checked={isListEmpty ? false : selectAll}
                    onChange={handleSelectAllChange}
                  />
                ) : (
                  header
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBody.length > 0 ? (
            tableBody.map((body, rowIndex) => (
              <tr key={rowIndex}>
                {tableHeader.map((header, colIndex) => (
                  <td key={colIndex}>
                    {colIndex === 0 ? (
                      <input
                        type="checkbox"
                        checked={selecteds.includes(body)}
                        onChange={() => handleCheckboxChange(body)}
                      />
                    ) : (
                      body[header]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>{content.length === 0 ? <td colSpan={tableHeader.length}>Nenhum cadastro</td> : <td colSpan={tableHeader.length}>Nome não encontrado</td>}</tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
