import { useState, useEffect, useContext } from "react";
import ApiCidadao from "../../../../ApiCidadao";
import { AgendaFunctions } from "../../../miscelaneous/utils/AgendaFunctions";
import { FaTrash } from "react-icons/fa";
import Alert from "../../../Configurações/OptionsScreen/inputs/Alerts/Alert";
import DeleteAlert from "../../../Configurações/OptionsScreen/inputs/DeleteAlert";
import { UserContext } from "../../../../contexts/UserProvider";
import { CidadaoContext } from "../../../../contexts/CidadaoProvider";
import { AgendaContext } from "../../../../contexts/AgendaProvider";
import ButtonRelatorioCompleto from "../components/ButtonRelatorioCompleto";
import ButtonRelatorioAgenda from "../components/ButtonRelatorioAgenda";
import * as XLSX from "xlsx";

export const AgendaInfo = ({ agendaSelecionada }) => {
  const { acharProfissional, acharEspecialidade, acharLocal, acharServico } =
    AgendaFunctions();
  const { pref } = useContext(UserContext);
  const { allCidadaos } = useContext(CidadaoContext);
  const [profissional, setProfissional] = useState({});
  const [especialidade, setEspecialidade] = useState({});
  const [local, setLocal] = useState({});
  const [servico, setServico] = useState({});
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });
  const { selecionados, setSelecionados } = useContext(AgendaContext);

  const handleSelectedAgendas = (agendaInfo) => {
    const isSelected = selecionados.some((item) => item.id === agendaInfo.id);
    let newSelected = [...selecionados];
    if (isSelected) {
      newSelected = newSelected.filter(
        (selectedItem) => selectedItem.id !== agendaInfo.id
      );
    } else {
      newSelected = [...selecionados, agendaInfo];
    }
    setSelecionados(newSelected);
  };
  const alertType = {
    sucess: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
  };
  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }
  useEffect(() => {
    const novoProfissional = acharProfissional(agendaSelecionada.profissional);
    setProfissional(novoProfissional);
  }, [acharProfissional, agendaSelecionada.profissional]);

  useEffect(() => {
    const novaEspecialidade = acharEspecialidade(
      agendaSelecionada.especialidade
    );
    setEspecialidade(novaEspecialidade);
  }, [acharEspecialidade, agendaSelecionada.especialidade]);

  useEffect(() => {
    const novoLocal = acharLocal(agendaSelecionada.local);
    setLocal(novoLocal);
  }, [acharLocal, agendaSelecionada.local]);

  useEffect(() => {
    const novoServico = acharServico(agendaSelecionada.serviço);
    setServico(novoServico);
  }, [acharServico, agendaSelecionada.serviço]);

  function deleteAgenda() {
    setAlert({
      visible: true,
      type: "delete",
      props: {
        data: "agenda",
        nome: "agenda",
        action: () => deleteAgendaApi(),
      },
    });
  }

  function deleteAgendaApi() {
    ApiCidadao.post("/agendamento/deletarAgenda", {
      agendaId: agendaSelecionada._id,
      dbname: pref.dbname,
    })
      .then((response) => {
        if (response?.data.message) {
          setAlert({
            visible: true,
            type: "sucess",
            props: {
              title: "Sucesso!",
              placeholder: "Agenda deletada com sucesso!",
            },
          });
        } else {
          setAlert({
            visible: true,
            type: "normal",
            props: {
              title: "Erro!",
              placeholder: "Não foi possível deletar a agenda!",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const withZero = (number) => {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };
  const dowlonad = () => {
    let data = [];

    agendaSelecionada.datasMarcadas.forEach((element) => {
      let cidadao = allCidadaos.find(
        (cidadao) => cidadao._id === element.usuario
      );
      let date = new Date(element.data);

      data.push({
        Data: `${withZero(date.getDate())}/${withZero(
          date.getMonth() + 1
        )}/${date.getFullYear()}`,
        Hora: ` ${withZero(date.getHours())}:${withZero(date.getMinutes())}`,
        Contribuinte: cidadao?.nome,
        CPF: cidadao?.cpf,
        Telefone: cidadao?.telefone,
        Email: cidadao?.email,
        Status: element.status,
      });
    });

    const FileSaver = require("file-saver");
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data2 = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(
      data2,
      `${agendaSelecionada.profissional}-${agendaSelecionada.especialidade}-${agendaSelecionada.local}-${agendaSelecionada.serviço}.xlsx`
    );
  };
  return (
    <div className="agenda-info">
      <div className="container-check-delete ">
        <input
          className="checkbox"
          type="checkbox"
          onChange={() =>
            handleSelectedAgendas({
              profissional: agendaSelecionada.profissional,
              especialidade: agendaSelecionada.especialidade,
              local: agendaSelecionada.local,
              serviço: agendaSelecionada.serviço,
              id: agendaSelecionada._id,
            })
          }
        />
        {alert.visible ? alertType[alert.type] : null}
        <div className="delete-agenda">
          <FaTrash onClick={deleteAgenda} />
        </div>
      </div>
      <p>
        <b>Profissional: </b>
        {profissional?.nome}
      </p>
      <p>
        <b>Especialidade: </b>
        {especialidade?.nome}
      </p>
      <p>
        <b>Local: </b>
        {local?.nome}
      </p>
      <p>
        <b>Serviço: </b>
        {servico?.nome}
      </p>
      <button onClick={dowlonad}>Baixar Datas Marcadas</button>
      <ButtonRelatorioCompleto agendaSelecionada={agendaSelecionada} />
      {selecionados.length > 0 && (
        <ButtonRelatorioAgenda agendaSelecionada={selecionados} />
      )}
    </div>
  );
};
