import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const SelectDate = ({ onChange, isEditMsg }) => {
  const [dataConvite, setDataConvite] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "dd/MM/yyyy",
      time: () => "HH:mm",
      dateTime: () => "dd/MM/yyyy   -   HH:mm",
    },
  };

  useEffect(() => {
    const novaDataConvite = selectedDate ? new Date(selectedDate) : new Date();
    setDataConvite(novaDataConvite);
  }, [selectedDate]);

  useEffect(() => {
    return () => {
      setSelectedDate(new Date());
    };
  }, [setSelectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange({ target: { name: "data", value: date } });
  };

  return (
    <DatePicker
      disabled={isEditMsg}
      selected={dataConvite}
      onChange={handleDateChange}
      showTimeSelect
      minDate={new Date()}
      timeIntervals={5}
      timeCaption="Horário"
      dateFormat={locale.formatLong.dateTime()}
      className="convite-sala-form-date"
      locale={locale}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
};
