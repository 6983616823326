import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";

export default function Intervalo(props) {
  const diasSemana = [
    { index: 0, nome: "Domingo" },
    { index: 1, nome: "Segunda" },
    { index: 2, nome: "Terça" },
    { index: 3, nome: "Quarta" },
    { index: 4, nome: "Quinta" },
    { index: 5, nome: "Sexta" },
    { index: 6, nome: "Sábado" },
  ];

  const [profissional, setProfissional] = useState({
    ...props.profissional,
  });
  const [horarioIntervalo, setHorarioIntervalo] = useState([]);

  useEffect(() => {
    let novoProfissional = { ...props.profissional };
    setProfissional(novoProfissional);
    const novoHorarioIntervalo = [...novoProfissional.horarioIntervalo];
    setHorarioIntervalo(novoHorarioIntervalo);
  }, [props.profissional]);

  const onChangeHorarioIntervalo = (e, periodo, index) => {
    let novoProfissional = { ...profissional };
    let novoHorarioIntervalo = [...horarioIntervalo];
    if (periodo === "inicio")
      novoHorarioIntervalo[index].inicio = e.target.value;
    if (periodo === "fim") novoHorarioIntervalo[index].fim = e.target.value;
    novoProfissional.horarioIntervalo = novoHorarioIntervalo;
    props.setProfissional(novoProfissional);
  };

  const onChangeDias = (checked, dia, index) => {
    let novoProfissional = { ...profissional };
    let novoHorarioIntervalo = [...horarioIntervalo];
    if (checked) {
      novoHorarioIntervalo[index].dias.push(dia);
    } else {
      novoHorarioIntervalo[index].dias = novoHorarioIntervalo[
        index
      ].dias.filter((d) => d !== dia);
    }
    novoHorarioIntervalo[index].dias.sort();
    novoProfissional.horarioIntervalo = novoHorarioIntervalo;
    props.setProfissional(novoProfissional);
  };

  const deleteInterval = (index) => {
    let novoProfissional = { ...profissional };
    let novoHorarioIntervalo = [...horarioIntervalo];
    novoHorarioIntervalo = novoHorarioIntervalo.filter((h, i) => i !== index);
    novoProfissional.horarioIntervalo = novoHorarioIntervalo;
    props.setProfissional(novoProfissional);
  };

  return (
    <>
      <label
        className="horarioIntervalo-adicionados-title"
        htmlFor="horarioFuncionamento"
      >
        Horários de Intervalo
      </label>
      <div
        className="profissional-especialidade-local-form-input-container-horario-intervalo"
        style={
          horarioIntervalo.length === 1 ? { height: "auto" } : { height: "45%" }
        }
      >
        <div className="profissional-especialidade-local-form-input-horario-intervalo-wrapper">
          {horarioIntervalo?.map((horario, index) => {
            return (
              <div
                className="profissional-especialidade-local-form-input-horario-intervalo"
                key={index}
              >
                <div className="profissional-especialidade-local-form-input-horario-intervalo-container">
                  <label className="required-prof">Horário:</label>
                  <input
                    type="time"
                    id="nome"
                    placeholder="Horário de Início"
                    value={horario?.inicio}
                    onChange={(e) =>
                      onChangeHorarioIntervalo(e, "inicio", index)
                    }
                  />
                  <h5 style={{ margin: "1rem 0.5rem" }}> às </h5>
                  <input
                    type="time"
                    id="nome"
                    placeholder="Horário de Término"
                    value={horario?.fim}
                    onChange={(e) => onChangeHorarioIntervalo(e, "fim", index)}
                  />
                  <FaTrash
                    className="delete-horario"
                    size={"2rem"}
                    title="Deletar horario"
                    style={{ color: "rgb(255, 19, 19)" }}
                    onClick={() => {
                      deleteInterval(index);
                    }}
                  />
                </div>

                <div className="profissional-especialidade-local-form-input-horario-intervalo-dias-container">
                  <label className="required-prof">Dias da semana:</label>
                  {diasSemana.map((dia, indexdia) => {
                    return (
                      <div className="input-container-dias" key={indexdia}>
                        <input
                          type="checkbox"
                          checked={horario?.dias.some((d) => d === dia.index)}
                          value={dia.index}
                          onChange={(e) =>
                            onChangeDias(e.target.checked, dia.index, index)
                          }
                        />
                        <span>{dia.nome}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
