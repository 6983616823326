import React from "react";

const BotDetails = ({ bot, flow, primeiroFlow }) => {
  return (
    <div className="bot-content-details">
      {bot.portId && (
        <div className="bot-content-details-item">
          <span>
            <b className="b-subtitle">ID:</b>{" "}
            <b className="b-result">{bot.portId || "--"}</b>
          </span>
        </div>
      )}

      <div className="bot-content-details-item">
        <span>
          <b className="b-subtitle">Flow Principal: </b>
          <b className="b-result">{flow ? flow : "--"}</b>
        </span>
      </div>
      <div className="bot-content-details-item">
        <span>
          <b className="b-subtitle">Flow Primeiro Contato: </b>
          <b className="b-result">{primeiroFlow ? primeiroFlow : "--"}</b>
        </span>
      </div>

      {bot.status !== "logando" && bot.status !== "esperando" && (
        <>
          <div className="bot-content-details-item">
            <span>
              <b className="b-subtitle">Horário:</b>{" "}
              <b className="b-result">
                {bot.config.horario.inicio ? bot.config.horario.inicio : "--"}{" "}
                às {bot.config.horario.fim ? bot.config.horario.fim : "--"}
              </b>
            </span>
          </div>

          <div className="bot-content-details-item">
            <span>
              <b className="b-subtitle">Dias: </b>
              {bot.config.dias && bot.config.dias.length > 0 ? (
                bot.config.dias.map((d, index) => {
                  const days = [
                    "Domingo",
                    "Segunda",
                    "Terça",
                    "Quarta",
                    "Quinta",
                    "Sexta",
                    "Sábado",
                  ];
                  return (
                    <span key={index}>
                      <b className="b-result">
                        {index + 1 === bot.config.dias.length
                          ? `${days[d]}`
                          : `${days[d]}, `}
                      </b>
                    </span>
                  );
                })
              ) : (
                <b className="b-result">--</b>
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default BotDetails;
