import React from "react";
import { BsCheckCircle, BsChevronRight } from "react-icons/bs";

const ButtonsFormServices = ({ props }) => {
  const { currentComponent, changeComponent, loading, saveBot } = props;
  return (
    <div className="cont-buttons-bot-actions">
      <button
        title="Clique para avançar"
        disabled={currentComponent === "Messages"}
        onClick={() => changeComponent("Messages")}
      >
        <BsChevronRight color="#142a4c" size={20} />
      </button>

      <button
        title="Clique para salvar"
        disabled={loading}
        onClick={(e) => saveBot(e)}
      >
        <BsCheckCircle color="#142a4c" size={20} />
      </button>
    </div>
  );
};

export default ButtonsFormServices;
