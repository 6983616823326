import React from "react";
import Attendances from "./attendances/AttendancesOLD";
import WaitingAttendances from "./attendances/WaitingAttendances";
import HistoryAttendances from "./attendances/HistoryAttendances";
import PottencialAttendance from "./attendances/PotencialAttendances";
import AttendancesNew from "./attendances/myAttendaceNew";

export default function Atendimentos(props) {
 
  const navigation = {
    atendimentos: props.user?.atendenteGrupo ? <AttendancesNew {...props} /> : <Attendances {...props} />,
    espera: <WaitingAttendances {...props} />,
    historico: <HistoryAttendances {...props} />,
    potenciais: <PottencialAttendance {...props} />,
  };

  return (
    <div className="talk-list">
      {props.onShow && navigation[props.onShow]}
    </div>

  );

}
