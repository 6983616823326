import React, { useState, useEffect, useContext } from "react";
import AttendanceList from "./AttendanceShow/AttendanceList";
import AttendanceEdit from "./AttendanceShow/AttendanceEdit";
import ApiUsers from "../../../../ApiUsers";
import DeleteAlert from "../inputs/DeleteAlert";
import Alert from "../inputs/Alerts/Alert";
import "./Attendance.css";
import { PrefContext } from "../../../../contexts/ChatBotProvider/PrefProvider";

export default function AttendanceShow() {
  const prefContext = useContext(PrefContext);
  const [grupos, setGrupos] = useState([]);
  const [gruposOnShow, setGruposOnShow] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

  const alerts = {
    delete: (
      <DeleteAlert
        data={alert.props.data}
        nome={alert.props.nome}
        action={alert.props.action}
        quit={() => quitAlert()}
      />
    ),
    normal: (
      <Alert
        title={alert.props.title}
        placeholder={alert.props.placeholder}
        quit={() => quitAlert()}
      />
    ),
  };

  useEffect(() => {
    setGrupos(prefContext.atendentes);
    setGruposOnShow(prefContext.atendentes);
  }, [prefContext.atendentes]);

  function quitAlert() {
    setAlert({ visible: false, type: "", props: {} });
  }

  function createAlert(type, props) {
    setAlert({ visible: true, type, props });
  }

  function searchGroup(e) {
    let list = [];
    if (e.trim() !== "")
      list = grupos.filter(
        (i) => i.nome.toLowerCase().indexOf(e.toLowerCase()) > -1
      );
    else list = [...grupos];
    setGruposOnShow(list);
  }

  function delConfirm(e, grupo) {
    let newAlert = {
      visible: true,
      type: "delete",
      props: {
        data: "grupo",
        nome: grupo.nome,
        action: () => delGroup(grupo),
      },
    };
    setAlert(newAlert);
  }

  function delGroup(grupo) {
    ApiUsers.post("/atendimentos/delete", { grupo })
      .then((res) => {
        let newAlert = {
          visible: true,
          type: "normal",
          props: {
            title: "Sucesso!",
            placeholder: `O grupo ${grupo.nome} foi deletado com sucesso!`,
          },
        };
        setAlert(newAlert);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {alert.visible && alerts[alert.type]}
      <div className="attendance-container">
        {edit ? (
          <>
            <h2 style={{ textAlign: "center", margin: "20px 0px" }}>
              {" "}
              Editar Grupo{" "}
            </h2>
            <AttendanceEdit
              group={selectedGroup}
              back={() => setEdit(false)}
              alert={(type, props) => createAlert(type, props)}
            />
          </>
        ) : (
          <>
            <AttendanceList
              groups={gruposOnShow}
              delConfirm={(e, grupo) => delConfirm(e, grupo)}
              edit={(e, grupo) => {
                setSelectedGroup(grupo);
                setEdit(true);
              }}
              search={(e) => searchGroup(e)}
            />
          </>
        )}
      </div>
    </>
  );
}
