import { useContext, useState, useEffect } from 'react'
import { AttendanceContext } from '../../../../contexts/ChatBotProvider/AttendanceProvider'
import { PrefContext } from '../../../../contexts/ChatBotProvider/PrefProvider'
import { UserContext } from '../../../../contexts/UserProvider'


const AttendanceNavBar = (props) => {
    const { unreadMessages, attendances, waitingAttendances } = useContext(AttendanceContext);
    const { atendentes } = useContext(PrefContext);
    const userContext = useContext(UserContext);
    const [onShow, setOnShow] = useState("atendimentos");
    const [waitingAtt, setWaitingAtt] = useState([]);
    const [potencialAtt, setPotencialAtt] = useState([]);
    const [myAtts, setMyAtts] = useState([]);

    const changeNavigation = (e, navigation) => {
        setOnShow(navigation);
        props.changeNav(navigation);
        props.onSelect(e, false);
    }

    useEffect(() => {
        let myGroups = atendentes.filter((grp) =>
            grp.usuarios.includes(userContext.user._id)
        );
        let waitingList = waitingAttendances.filter((att) =>
            myGroups.some((grp) => grp._id === att.grupo)
        );
        setWaitingAtt(waitingList);
        const twentyFourHoursAgo = new Date(Date.now() - (48 * 60 * 60 * 1000));
        let potencialList = attendances.filter((att) => {
            let lastMessageDate = new Date(att.lastMessageDate);
            if (!lastMessageDate) return false;
            if (att.lastMessage && att.lastMessage.createdAt) lastMessageDate = new Date(att.lastMessage.createdAt);
            else return false;
            return (
                !att.waiting &&
                !att.atendente &&
                !att.onCampaign &&
                att.historico[att.historico?.length - 1]?.action !== "Finalizado" &&
                lastMessageDate > twentyFourHoursAgo
            );
        });
        setPotencialAtt(potencialList);

        let myAttsList = attendances.filter((att) =>
            att.atendente === userContext.user._id
        );
        setMyAtts(myAttsList);

    }, [waitingAttendances]);

    return (
        <>
            <div
                className='attendance-list-nav-item-one'
                onClick={(e) => changeNavigation(e, "atendimentos")}
                style={{ width: "95%", margin: "0 auto" }}
            >
                <button className={`attendance-list-nav-button ${onShow === "atendimentos" ? "marked" : ""}`} style={{ width: '100%' }}>
                    Meus Atendimentos
                </button>
                {
                    unreadMessages > 0 && (
                        <span className="my-atts-notification"> <span>{unreadMessages}</span>
                        </span>
                    )
                }
            </div>
            <div className="attendance-list-nav">

                <div
                    className='attendance-list-nav-item'
                    onClick={(e) => changeNavigation(e, "espera")}
                >
                    <button className={`attendance-list-nav-button ${onShow === "espera" ? "marked" : ""}`} style={{ width: "145px" }}>
                        Em Espera
                    </button>
                    {waitingAtt.length > 0 && (
                        <span className="waiting-atts-notification">
                            {" "}
                            {waitingAtt.length}{" "}
                        </span>
                    )}
                </div>

                <div
                    className='attendance-list-nav-item'
                    onClick={(e) => changeNavigation(e, "potenciais")}
                >
                    <button className={`attendance-list-nav-button ${onShow === "potenciais" ? "marked" : ""}`} style={{ width: "145px" }}>
                        Em Potencial
                    </button>

                    {
                        potencialAtt.length > 0 && (
                            <span className="potencial-atts-notification">
                                {" "}
                                {potencialAtt.length}{" "}
                            </span>
                        )
                    }

                </div>

            </div>
        </>
    )
}

export default AttendanceNavBar
