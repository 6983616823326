import React, { useState } from "react";
import DepartamentCreate from "./DepartamentCreate";
import DepartamentShow from "./DepartamentShow";

export default function DepartmentConfig(props) {
  const [onShow, setOnShow] = useState("visualizar");
  const components = {
    criar: <DepartamentCreate />,
    visualizar: <DepartamentShow />,
  };

  return (
    <div className="config-list-header">
      <button
        title="Visualizar departamentos"
        onClick={(e) => setOnShow("visualizar")}
        className="button-view"
        style={
          onShow === "visualizar"
            ? {
              color: "#9ac31c",
              fontWeight: "bold",
            }
            : null
        }
      >
        Visualizar
      </button>
      <button
        title="Criar novo departamento"
        onClick={(e) => {
          setOnShow("criar");
        }}
        className="button-view"
        style={
          onShow === "criar"
            ? {
              color: "#9ac31c",
              fontWeight: "bold",
            }
            : {}
        }
      >
        Criar
      </button>
      {onShow ? components[onShow] : ""}
    </div>
  );
}
