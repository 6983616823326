import React from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FaBuilding, FaWarehouse } from "react-icons/fa";
import "./CheckboxList.css";

export default function CheckboxList(props) {
  const { usuarios, action, selectedUsers, loading } = props;

  return (
    <>
      <div className="checkbox-users-container">
        <button
          title={`${
            action === "add" ? "Adicionar usuários" : "Remover usuários"
          }`}
          disabled={loading}
          className={
            action === "add"
              ? "checkbox-users-button"
              : "checkbox-users-button-del"
          }
          onClick={
            action === "add" ? () => props.addUsers() : () => props.delUsers()
          }
        >
          {action === "add" ? (
            <>
              <IoAddCircleOutline style={{ fontSize: 30, marginRight: 10 }} />
              Adicionar Selecionados
            </>
          ) : (
            <>
              <IoIosRemoveCircleOutline
                style={{ fontSize: 30, marginRight: 10 }}
              />
              Remover Selecionados
            </>
          )}
        </button>

        <div className="checkbox-users-sel-all-container">
          <span> Total: {usuarios.length} </span>

          <span> Total Selecionados: {selectedUsers.length} </span>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              style={{ width: 20, height: 20, marginRight: 10 }}
              checked={selectedUsers.length === 0 ? false : null}
              onChange={(e) => props.selectAll(e.target.checked)}
            />
            <span> Selecionar Todos </span>
          </div>
        </div>

        <div
          className="checkbox-users-list"
          style={props.height ? { height: props.height } : { height: 450 }}
        >
          {usuarios.length > 0 ? (
            <>
              {usuarios.map((u, index) => {
                let Col = {
                  nome: u.nome,
                  email: u.email,
                  setor: u.setorNome,
                  departamento: u.departamentoNome,
                };

                if (u.ativo === false || u.nome === "Voxcity") return;

                return (
                  <div
                    key={index}
                    className="checkbox-users-card"
                    style={
                      selectedUsers.some((user) => user._id === u._id)
                        ? {
                            transition: "all 0.2s linear",
                            backgroundColor: `${
                              action === "add"
                                ? "rgb(183 227 189)"
                                : "rgb(255 195 195)"
                            }`,
                          }
                        : null
                    }
                  >
                    <div className="avatar-col">
                      <img
                        className="contact-avatar"
                        style={{ width: 70, height: 70 }}
                        src={u.foto ? u.foto : "/imgs/avatar2.png"}
                        alt="avatar"
                      />
                    </div>

                    <div className="info-col">
                      {Object.entries(Col).map(([k, v]) => {
                        const Icon = {
                          nome: <FaUserAlt style={{ marginRight: 5 }} />,
                          email: (
                            <MdAlternateEmail style={{ marginRight: 5 }} />
                          ),
                          setor: <FaWarehouse style={{ marginRight: 5 }} />,
                          departamento: (
                            <FaBuilding style={{ marginRight: 5 }} />
                          ),
                        };

                        return (
                          <span key={k} className="span-col">
                            {Icon[k]}
                            {v !== "" ? v : `Sem ${k}`}
                          </span>
                        );
                      })}
                    </div>

                    <div className="input-col">
                      <input
                        type="checkbox"
                        className="input-col-input"
                        checked={selectedUsers.some(
                          (user) => user._id === u._id
                        )}
                        onChange={(e) => props.selectUser(e.target.checked, u)}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src="/imgs/emptyusers.png"
                  style={{ width: 150, margin: 20 }}
                  alt="empty"
                />
                <h4> Não há usuários disponíveis</h4>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
