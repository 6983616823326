import "./styles.css";
import { FaTrash } from "react-icons/fa";

export const InputList = ({ inputList, inputListKeys, setInputList, checkBoxes }) => (
  <>
    {inputList.map((item, index) => {
      const { subKeys } = inputListKeys[index];
      return (
        <div
          className="qsg-agenda-inputList-wrapper"
          key={index}>
          <div className="qsg-agenda-input-group">
            {subKeys.map((subKey) => {
              if (subKey.inputType !== "checkbox") {
                return (
                  <div
                    key={subKey.key}
                    className="qsg-agenda-inputList-group">
                    <label>{subKey.label}</label>
                    <input
                      type={subKey.inputType}
                      value={item[subKey.key]}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setInputList((prevState) => {
                          const newArrayObjectList = [...prevState];
                          newArrayObjectList[index][subKey.key] = newValue;
                          return newArrayObjectList;
                        });
                      }}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="checkbox-group">
            {subKeys.map((subKey) => {
              if (subKey.inputType === "checkbox") {
                return checkBoxes.map((dia) => (
                  <div
                    key={dia.index}
                    className="qsg-agenda-inputList-group">
                    <label>{dia.nome}</label>
                    <input
                      type="checkbox"
                      checked={item[subKey.key].includes(dia.index)}
                      onChange={(e) => {
                        const newValue = e.target.checked ? [...item[subKey.key], dia.index] : item[subKey.key].filter((index) => index !== dia.index);
                        setInputList((prevState) => {
                          const newArrayObjectList = [...prevState];
                          newArrayObjectList[index][subKey.key] = newValue;
                          return newArrayObjectList;
                        });
                      }}
                    />
                  </div>
                ));
              }
              return null;
            })}
          </div>
          <FaTrash
            type="button"
            title="Remover"
            style={{ color: "rgb(255, 19, 19)" }}
            onClick={() => {
              setInputList((prevState) => prevState.filter((_e, i) => i !== index));
            }}
          />
        </div>
      );
    })}
  </>
);
