import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../../../contexts/UserProvider'
import Alert from '../../../../Configurações/OptionsScreen/inputs/Alerts/Alert'
import ListUsers from './types/listUsers'
import AddListUsers from './types/addListUsers'
import './grupo.css'

const Grupo = ({ grupo }) => {
    const { user } = useContext(UserContext)
    const [type, setType] = useState('lista')
    const [imAdm, setImAdm] = useState({})
    const [grupoInfo, setGrupoInfo] = useState({})
    const [alert, setAlert] = useState({ visible: false, type: "", props: {} });

    function createAlert(type, props) {
        setAlert({ visible: true, type, props });
    }

    const quitAlert = () => {
        setAlert({ visible: false, type: "", props: {} });
    }

    const alertType = {
        normal: <Alert title={alert.props.title} placeholder={alert.props.placeholder} quit={() => quitAlert()} />
    }

    const types = {
        lista: <ListUsers usuarios={grupoInfo?.usuarios} imAdm={imAdm} grupo={grupoInfo} setGrupo={setGrupoInfo} createAlert={createAlert} />,
        add: <AddListUsers setType={setType} usuarios={grupoInfo?.usuarios} grupo={grupoInfo} setGrupo={setGrupoInfo} createAlert={createAlert} />
    }

    useEffect(() => {
        let newGrupo = grupo ? { ...grupo } : {}
        setGrupoInfo(newGrupo)
    }, [grupo])

    useEffect(() => {
        if (grupoInfo?.master) {
            let adm = grupoInfo.master.find((adm) => adm === user._id)
            if (adm) {
                setImAdm({
                    _id: adm,
                    ativo: true
                })
            } else {
                setImAdm({
                    ativo: false
                })
            }
        }
    }, [grupoInfo])


    return (
        <div className='contact-info-modal'>
            <div className="contact-info-contander-group">
                <div className="contact-info-group">
                    <div className="contact-group-item">
                        <span>Administrador</span>
                        <p>{imAdm.ativo ? 'SIM' : 'NÃO'}</p>
                    </div>

                    <div className="contact-group-item">
                        <span>Total</span>
                        <p>{grupoInfo?.usuarios?.length}</p>
                    </div>
                    {imAdm?.ativo && type === 'lista' && <div className="contact-info-group-btn">
                        <button onClick={() => setType('add')}>Adicionar Membros</button>
                    </div>}
                    {imAdm?.ativo && type === 'add' && <div className="contact-info-group-btn">
                        <button onClick={() => setType('lista')}>Voltar</button>
                    </div>}
                </div>
                {alert.visible && alertType[alert.type]}
                {types[type]}
            </div>
        </div>
    )
}

export default Grupo
