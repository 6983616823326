import React from 'react';

export default function CreateForm(props) {

    const {newFlow, loading} = props;

    return <>
        <h4 style={{ marginBottom: 20 }}> Criar Flow </h4>

        <div className="flow-form-creat">
            <h5 style={{marginTop:"0.5rem", display:"flex"}} className="required">Nome:</h5>
            <input type="text" className="flow-form-input" placeholder="Digite aqui o nome do flow..." value={newFlow.nome} onChange={(e) => props.changeFlowName(e)} />
        </div>
       
        <button className="flow-button" title="Criar Flow" disabled={loading} style={{ background: "rgb(0, 129, 167)", margin: "10px 0px 0px 0px" }} onClick={() => props.addNewFlow()}> Salvar </button>
    </>
}